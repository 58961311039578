import React from "react";
import Autocomplete from "../../sfPlugins/autoComplete.tsx";
import {
  isValidParam,
  getStringParam,
  getArrayParam,
  getObjectParam,
  getBooleanParam,
} from "../../../services/helper/parameterVerifier";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getMultiLingualLabel } from "../../../services/helper/common";
/**
 *
 * @Author  : Pradyut.s
 * @Date:   : 2018-07-20
 */
export default class SFAutoComplete extends React.Component {
  constructor() {
    super();
  }

  onChangeMenu = (event, value) => {
    if (event) {
      const _value = value.value;
      this.props.onChange(event, _value);
    }
  };

  getValues = () => {
    let values = [];
    try {
      let menuItems = getArrayParam(this.props.menuItems);
      menuItems.forEach((e) => {
        values.push({ id: e.props.id, value: e.props.value });
      });

      if (values.length === 0) {
        menuItems = getArrayParam(this.props.itemValues);
        menuItems.forEach((e) => {
            values.push({ id: e.id, value: e.label });
        });
      }
      // if (value !== '') {
      //     let tempValue = value?.toLowerCase();
      //     values = values.filter(f => { return f.value.toLowerCase().indexOf(tempValue) === 0 });
      // }
    } catch (error) {
      console.error("Error in 'sfAutoComplete.js -> getValues()':" + error);
    }
    return values;
  };

  getMenuItems = () => {
    let menuItems = [];
    try {
      let values = this.getValues();
      menuItems = values.map((e, i) => {
        let primaryTextVal = getStringParam(e.value);
        primaryTextVal = getMultiLingualLabel(primaryTextVal);
        if (primaryTextVal === "") {
          primaryTextVal = isValidParam(
            getLocalizedStrings().label.DEFAULT_NOTE_TYPES[e.value]
          )
            ? getLocalizedStrings().label.DEFAULT_NOTE_TYPES[e.value]
            : e.value;
        }
        return { value: primaryTextVal, id: e.id };
      });
    } catch (error) {
      console.error("Error in 'sfAutoComplete.js -> getMenuItems()':" + error);
    }
    return menuItems;
  };

  render() {
    let isFullHeight = getBooleanParam(this.props.isFullHeight);
    const menuItems = this.getMenuItems();
    let disabled = getBooleanParam(this.props.disabled);

    let containerStyle = { ...getObjectParam(this.props.style) };
    delete containerStyle.border;
    let style = { ...getObjectParam(this.props.style), paddingRight: "22px" };
    delete style.marginBottom;
    if (isFullHeight) {
      delete containerStyle.height;
    }
    let isShowMarginBottom = this.props.hasOwnProperty("isShowMarginBottom")
      ? this.props.isShowMarginBottom
      : true;
    let marginBottom = "25px";
    if (!isShowMarginBottom) {
      marginBottom = "0px";
    }

    return (
      <div
        id={"field-container-" + this.props.id}
        key={"field-container-" + this.props.id}
        style={{
          ...containerStyle,
          display: 'Grid',
          alignItems: 'center',
          fontSize: '16px'
        }}
      >
        <Autocomplete
          style={{ fontSize: 16 }}
          disableClearable
          options={isValidParam(menuItems) ? menuItems : []}
          getOptionLabel={(option) => option.value}
          value={
            menuItems.find((option) => (option.value !== this.props.value) ? 
              option.id === this.props.value : option.value === this.props.value) ||
            null
          }
          inputProps={{ style: { fontSize: 16} }}
          disabled={disabled}
          label={getStringParam(this.props.label)}
          onChange={(e, newValue) => this.onChangeMenu(e, newValue)}
        />
      </div>
    );
  }
}
