import React, { useState, useEffect } from "react";
import { Button, FormControl, MenuItem, TextField } from "@mui/material";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { isValidParam, isEmpty } from "../../../services/helper/parameterVerifier";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { useDispatch } from "react-redux";
import { refreshListView } from "../../../services/actions/listViewAcions";
import * as sfDialogs from '../components/sfDialogs';

const BatchEditTemplate = ({ id, object, closeDrawer}) => {
    const dispatch = useDispatch();
    const [categoryObjectArr, setCategoryObjectArr] = useState([]); // Sub Categories list
    const [statusArr] = useState([
        <MenuItem key={"Active"} value={"Active"} id={"Active"} style={styles.popoverMenuItem}>
            {getLocalizedStrings().label.MARKETING_TEMPLATE.ACTIVE}
        </MenuItem>,
        <MenuItem key={"Inactive"} value={"Inactive"} id={"Inactive"} style={styles.popoverMenuItem}>
            {getLocalizedStrings().label.MARKETING_TEMPLATE.INACTIVE}
        </MenuItem>
    ]);
    
    const [selectedField, setSelectedField] = useState('Status');
    const [fieldListValue, setFieldListValue] = useState('');
    
    // Handles changes to the selected field (either Sub Category or Status)
    const handleModifyFields = (value) => {
        setSelectedField(value); // Update selected field

        if (value === "Sub Category") {
            let param = { type: object === constants.SALES_TEMPLATE_OBJECT ? 'Sales' : 'Marketing' };

            let promise = Promise.resolve(HTTPClient.get(endPoints.SALES_TEMPLATE.GET_SUB_CATEGORY_TEMPLATES, param))
                promise.then(response => {
                    const subCategories = response?.sub_categories.map((fieldOption, index) => {
                        return (
                            <MenuItem key={index} value={fieldOption}>
                                {fieldOption}
                            </MenuItem>
                        );
                    });
                    setCategoryObjectArr(subCategories);
                })
                .catch(error => {
                    console.error("Error fetching sub-categories", error);
                });
        }
    };

    const handleUpdate = () => { 
        let isValid = true;  
        if(isEmpty(selectedField))  {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION.EMPTY_MODIFY_FIELD, null);
            isValid = false;
        } else if(isValidParam(selectedField) && isEmpty(fieldListValue))  {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION.BATCH_EDIT_BLANK, null);
            isValid = false;
        }

        if(isValid){
            let params = {};
            let objectLabel = (object === constants.SALES_TEMPLATE_OBJECT) ? 'Sales Template' : 'Marketing Template' 
            params.id = id;
            params.field_name = selectedField === 'Status' ? "t_status" : "t_subcategory";
            params.field_value = fieldListValue;

            let msg = "'" + selectedField + "' of " + id.length + " " + getLocalizedStrings().message.BATCH_ACTION.RECORD_WILL_BE_UPDATED + " '" + fieldListValue + "'. " + getLocalizedStrings().message.BATCH_ACTION.CONFIRM_MSG;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => handleUpdateConfirm(params,objectLabel), null);
        }
    }

    const handleUpdateConfirm = (params,objectLabel) =>{
        let promise = Promise.resolve(HTTPClient.post(endPoints.SALES_TEMPLATE.BATCH_UPDATE_TEMPLATES, params));
            promise.then((response) => {
                if (isValidParam(response.status) && response.status === 0) {
                    let msg = id.length + " " + objectLabel + " " + getLocalizedStrings().message.COMMON.HAVE_BEEN + " " + getLocalizedStrings().message.COMMON.UPDATE;
                    closeDrawer();
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    refreshListView(object);
                }
            });
    }
                    

    // Field list options
    const getFieldlistmenu = () => [
        <MenuItem key={"status"} value={"Status"} style={styles.popoverMenuItem}>
            Status
        </MenuItem>,
        <MenuItem key={"subCategory"} value={"Sub Category"} style={styles.popoverMenuItem}>
            Sub Category
        </MenuItem>
    ];

    const fieldlistmenu = getFieldlistmenu(); // Retrieve the field list menu items

    return (
        <div style={{ padding: '30px' }}>
            {/* Field selector */}
            <FormControl variant="outlined" style={{ width: '100%' }}>
                <TextField
                    key="Modify_field"
                    name="Modify_field"
                    label={getLocalizedStrings().label.LIST_VIEW.MODIFY_FIELD}
                    labelId="SF-form-Modify_field"
                    value={selectedField}
                    style={{ height: '40px' }}
                    className="sf-fields-bg"
                    onChange={(e) => handleModifyFields(e.target.value)} // Update selectedField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                >
                    {fieldlistmenu}
                </TextField>
            </FormControl>

            {/* Sub Category or Status selection based on the selected field */}
            <div style={{ width: '100%', marginTop: '10px' }}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                    <TextField
                        key="SubCategory"
                        name="SubCategory"
                        label={selectedField} // Dynamically set label based on selectedField
                        value={fieldListValue}
                        style={{ height: '40px' }}
                        className="sf-fields-bg"
                        onChange={(e) => setFieldListValue(e.target.value)} // Update fieldListValue
                        select
                        fullWidth
                        size="small"
                        variant="outlined"
                    >
                        {selectedField === 'Status' ? statusArr : categoryObjectArr}
                    </TextField>
                </FormControl>
            </div>

            {/* Update button */}
            <div style={{ textAlign: 'right', marginTop: '10px', marginBottom: '20px'}}>
                <Button key="update" style={{ ...styles.primaryButton, verticalAlign: 'top' }} onClick={()=> handleUpdate()}>
                    {getLocalizedStrings().label.COMMON.UPDATE}
                </Button>
            </div>
        </div>
    );
};

export default BatchEditTemplate;
