import { actions } from "../constants/actions";


const setPipeLineData = (payload) => ({ type: actions.SET_PIPE_LINE_DATA, payload });
const setWorkFlows = (payload) => ({ type: actions.SET_WORKFLOWS, payload });
const setWorkflowValue = (payload) => ({ type: actions.SET_WORKFLOW_VALUE, payload });
const setStages = (payload) => ({ type: actions.SET_STAGES, payload });
const setStageValue = (payload) => ({ type: actions.SET_STAGE_VALUE, payload });
const setTableData = (payload) => ({ type: actions.SET_TABLE_DATA, payload });
const setSortable = (payload) => ({ type: actions.SET_SORTABLE, payload });
const setSortedData = (payload) => ({ type: actions.SORTED_DATA, payload });
const setStagesValueError = (payload) => ({ type: actions.SET_STAGES_VALUES_ERROR, payload });
const setInitialMsg = (payload) => ({ type: actions.SET_INITIAL_MSG, payload });
const setPeriod = (payload) => ({type: "SET_PERIOD", payload});
const setStartDate = (payload) => ({type: "SET_STARTDATE", payload});
const setEndDate = (payload) => ({type: "SET_ENDDATE", payload});
const setIsLoading = (payload) => {
    return {type: actions.SET_SALES_PIPELINE_REPORT_LOADING, payload}}
    ;



export { setPipeLineData, setWorkFlows, setWorkflowValue, setStages, setStageValue, setTableData, setSortable, setSortedData, setStagesValueError, setInitialMsg, setPeriod, setStartDate, setEndDate, setIsLoading }