const label = {
    MODULE: {
        Sales: 'Vendite',
        Marketing: 'Marketing',
        Service: 'Servizio',
        Operations: 'Operazioni',
        HUB: 'MOZZO',
    },
    MODULE_ITEMS: {
        Reports: 'Rapporti',
        Dashboard: 'Cruscotto',
        'Marketing Templates': 'Modelli di e-mail di marketing',
        'Broadcast Campaigns': 'Campagne di trasmissione',
        'Automation Designer': 'Progettazione dell automazione',
        'Email Marketing': 'Marketing via e-mail',
        'Lead Capture': 'Acquisizione di lead',
        'Landing Pages': 'Pagine di destinazione',
        'Web Forms': 'Moduli Web',
        'Automated Campaigns': 'Campagne automatizzate',
        'Social Marketing': 'Marketing Sociale',
        'Website Tracking': 'Website Tracking',
        'Website Analytics Setup': 'Monitoraggio del sito web',
        'Ab Campaigns': 'Campagne Ab',
        'Opportunities': 'Opportunità',
        'Marketing Reports': 'Rapporti',
        'Touch Campaigns': 'Campagne touch',
        'A/B Campaigns': 'Campagne A/B',
        'Email Blast': 'Esplosione di e-mail',
        'Recent Campaigns': 'Campagne recenti',
        Activities: 'Attività',
        Conversion: 'Conversione',
        Campaigns: 'Campagne',
        Custom: 'Costume',
        Sales: 'Vendite',
        Marketing: 'Marketing',
        Offers: 'Offre',
        Coupons: 'Coupon',
        'Coupons Claim Report': 'Report', // Report
        ASSETS_DISTRIBUTION: 'Distribuzione delle risorse',
        USEFUL_LINKS: 'Collegamenti utili',
        Royalties: 'Diritti d\'autore - Eredità',
        COMPLIANCE_REPORT: "Conformità",
        AUDIT_REPORT: 'Rapporto di audit',
        ROYALTIES_V2: 'Royalties',
    },
    COMMON: {
        SEND_THE_REST: 'Invia il resto usando il vincitore',
        UNIT_CRM_UPDATE_STAGES:'Fasi di aggiornamento',
        UNIT_CRM_UPDATE_TASKS:'Aggiorna attività',
        FRENCHISEE_COMPANY_NAME:'Seleziona il nome di una società in franchising',
        SELECT_AN_ACCOUNT:'Seleziona un\'unità',
        ADD_TO_PLAN:'Aggiungi al piano',
        LOGIN_TO_BASE:'Accedi alla Base',
        PLUS_BRANCH: '+Ramo',
        WINNER_BY : 'Seleziona il vincitore per',
        PUSH_NO_CONFIRM: 'Push senza conferma',
        ADD: 'Aggiungere',
        ADD_NEW_APPOINTMENT: 'Aggiungi un nuovo',
        SHOW: 'Mostrare',
        HIDE: 'Nascondere',
        REFRESH: 'Aggiornare',
        EDIT: 'Redigere',
        SAVE: 'Salvare',
        TEST: 'Test',
        CANCEL: 'Annulla',
        ENTER_TEXT: 'Inserisci testo',
        APPLY: 'Applicare',
        AND: 'e',
        EMPTY: 'Empty',
        EXPAND: 'Vuota',
        DELETE: 'Espandere',
        REQUIRED: ' (obbligatorio)',
        AUTO_SEARCH_TEXT: 'Stesso testo, valori diversi',
        NOTE: 'Nota',
        SEARCH: 'Ricerca',
        OK: 'Ok',
        SEND_MAIL: 'Invia e-mail',
        ASSIGN: 'Assegnare',
        SELECT_A: `Seleziona un'icona`,
        SELECT:'Selezionare',
        SELECT_MORE: 'Seleziona uno o più ',
        CLOSE: 'Chiudere',
        CREATE: 'Creare',
        WITH: 'con',
        CONVERT: 'Convertire',
        GREENLIGHT: 'Semaforo verde',
        SEARCH_PLACEHOLDER: 'Digita e premi invio per cercare',
        COPY: 'Copiare',
        MORE: 'Più',
        CLICK_TO_SEARCH: 'Clicca qui per cercare',
        PREVIEW: 'Anteprima',
        BACK: 'Indietro',
        CONFIRM_REQUEST: 'Conferma richiesta',
        ADVANCED: 'Avanzata/Avanzato',
        ADVANCED_SEARCH: 'Ricerca avanzata',
        RESULTS_FOR: 'Risultati per',
        SETTINGS: 'Impostazioni',
        SIGN_OUT: 'Esci',
        BACK_TO_YOUR_ACCOUNT: 'Torna al tuo account',
        BACK_TO_LIST: `Torna all'elenco.`,
        BACK_TO_COLUMN_VIEW: 'Torna alla vista a colonne.',
        MERGE: 'Fondersi',
        DISPLAY_DIFFERENT_FIELDS: '*Vengono visualizzati solo i campi diversi.',
        WORKFLOW: 'Flusso di lavoro',
        STATUS: 'Stato',
        RECORD: 'Registrazione',
        MERGED_RECORD: 'Record unito',
        EXPORT: 'Esportazione',
        CTR_DETAILS_FOR: 'Dettagli CTR per',
        URL: 'URL',
        COUNT: 'Contare',
        CLICK_FOR_KANBAN_VIEW: 'Colonna',
        CLICK_FOR_LIST_VIEW: 'Lista',
        INSERT: 'Inserire',
        CATEGORIES: 'Categorie',
        REMOVE: 'Togliere',
        REPLY: 'Risposta',
        REPLY_ALL: 'Rispondi a tutti',
        FORWARD: 'Inoltrare',
        WORKFLOW_NAME: 'Nome flusso di lavoro',
        CONVERTING: 'Conversione',
        TO: 'A',
        CURRENT_WORKFLOW: 'Flusso di lavoro corrente',
        CHANGE_WORKFLOW: 'Modificare il flusso di lavoro',
        UPDATE: 'Aggiornare',
        STATUS_TO_OPTIONAL: 'stato su (facoltativo)',
        MANAGE: 'Amministrare',
        ADD_PERSONAL_NOTE_OPTIONAL: 'Aggiungi una nota personale alla tua richiesta (facoltativo)',
        CONVERTED: 'Convertita/Convertito',
        SELECT_USER: 'Seleziona un utente',
        COMING_SOON: 'Prossimamente',
        IMPORT: 'Importazione',
        NO: 'No',
        FOUND: 'fondare',
        NOTHING_FOUND: 'Niente trovato per',
        DOT: '.',
        UNDER_PROCESSING: 'In fase di elaborazione,  Prossimamente ',
        FIND: 'Trovare',
        LOADING: 'Caricamento',
        NAME: 'Nome',
        CREATE:'Creare',
        CLEAR_ALL: 'Cancella tutto',
        CLEAR: 'Chiara/Chiaro',
        SHARE: 'Condividi',
        ADD_TO_GROUP: 'Aggiungi al gruppo',
        MAP: 'Mappa',
        AUDIT: 'Vedi la cronologia del campo',
        OPEN_AUDIT_FORM: 'Revisione',
        TRAIL: 'Sentiero',
        ADD_NOTES: 'Aggiunta di note',
        TO_EXCEL: 'A Excel',
        PUSH: 'Spingere',
        RESCHEDULE: 'Riprogrammare',
        PRINT: 'Stampare',
        COMPLETE: 'Completa/Completo',
        COMPLETE_New: 'Completo e nuovo',
        SEND_EMAIL: 'Invia e-mail',
        SEND_EMAIL_ATTACHMENT: 'Inviare un allegato tramite e-mail',
        SEND_SMS: 'Invia SMS',
        CONVERT_TO_ACCOUNTS: 'Converti in account',
        CONNECT: 'Connettersi',
        DISCONNECT: 'Disconnect',
        DOWNLOAD: 'Scaricare',
        BATCH_EDIT: 'Modifica batch',
        BATCH_CONVERT: 'Conversione batch',
        INACTIVE: 'Inattiva/Inattivo',
        ACTIVE: 'Attiva/Attivo',
        RESTORE: 'Restaurare',
        PROFILE: 'Profilo',
        TYPE_TO_SEARCH: 'Digita qui per cercare',
        CONVERT_AND_ADD_OPP: 'Converti e aggiungi opportunità',
        SEND_VERIFICATION_EMAIL: 'Invia e-mail di verifica',
        THESE: 'queste/questi',
        THIS: 'questa/questo',
        ALL_APPOINTMENTS: 'Tutti gli appuntamenti',
        ALL_TASKS: 'Attività',
        CONTINUE: 'Continuare',
        ALL_OPPORTUNITIES: 'Tutte le opportunità',
        EDIT_NOTES: 'Modifica note',
        RUN: 'Correre',
        INTEGRATION_TITLE: 'Integrazione',
        UNSUBSCRIBE: 'Disiscrizione',
        MY_INTEGRATIONS: 'Integrazioni',
        NOTIFY: 'Notificare ',
        HELP: 'Guida',
        PUBLISH: 'Pubblicare',
        UNPUBLISH: 'Annulla pubblicazione',
        CALL_SCRIPT: 'Script di chiamata',
        DOCUSIGN: 'Invia DocuSign',
        DOCUSIGN_INTEGRATION: 'Invia DocuSign',
        ALL: 'Tutta/Tutto',
        USER: 'Utente',
        SYSTEM: 'Sistema',
        MY: 'Mia/Mio',
        TODAYS: "L'odierno",
        YES: 'Sì',
        FUTURE: 'Futura/Futuro',
        OVERDUE: 'Scaduto',
        TOMORROWS: "Domani",
        TOMORROW: "Domani",
        TODAY: 'Oggi',
        CONNECTED_AS: 'Collegato come',
        SALESREP: 'Rappresentante',
        EVERYONE: 'Ciascuna/Ciascuno',
        NOTE_TYPE: 'Tipo di nota',
        TEXT: 'Testo',
        FOR: 'per',
        MODULE_SETTING: 'Impostazioni',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'Modello di libreria',
            USER_TEMPLATE: 'Modello utente',
        },
        OF: 'di',
        'System Fields': 'Campi di sistema',
        'Custom Fields': 'Campi personalizzati',
        'Additional Information': 'Informazioni aggiuntive',
        'Contact Details': 'Dettagli di contatto',
        'Phone and Email Information': 'Informazioni telefoniche ed e-mail',
        'Sales rep': 'Rappresentante',
        DONE: 'Fatto',
        OPEN: 'Aperto',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'Mapperei le mie fonti dal file CSV',
        ADD_NEW_SOURCE: 'Aggiungere una nuova fonte',
        ADD_TO_THIS_LIST: 'Aggiungi a questa lista',
        TYPE_SOMETHING: 'Digita qualcosa',
        'Opportunity Details': "Dettagli dell'opportunità",
        'Company Details': 'Company Details',
        'Contact Information': 'Informazioni di contatto',
        'Case Information': 'Informazioni sul caso',
        'Solution Details': 'Dettagli della soluzione',
        'Project Details': 'Dettagli del progetto',
        'Issue Details': 'Dettagli del problema',
        'Assigned to': 'Assegnato a',
        'Sales person': 'Addetto alle vendite',
        EMAIL_TO: "Inviare un'e-mail a",
        RECENT_SEARCHES: 'Ricerche recenti',
        RECENT_RECORDS: 'Record recenti',
        ENABLE_MAILING: "Abilita l'invio di e-mail",
        ASSETS: 'Biblioteca',
        DISABLE_MAILING: "Disabilita l'invio di e-mail",
        CLICK_TO_REMOVE: 'Clicca qui per rimuovere',
        PIN: 'Spilla',
        UNPIN: 'Rimuovere',
        RENAME: 'Ribattezzare',
        DEACTIVATE: 'Disattivare',
        ACTIVATE: 'Attivare',
        COUPONS: 'Coupon',
        ADD_FOLDER: 'Aggiungi cartella',
        ADD_FILE: 'Aggiungi file',
        SELECT_CONTACTS: 'Seleziona Contatti',
        NOTIFICATION_REMINDERS: 'Notifiche e promemoria',
        PREFERENCES: 'Preferenze',
        LOCK_STAGES: 'Blocca fasi',
        LOCK_TASKS: 'Blocca attività',
        SEND_BATCH_SMS: 'Send Batch Text Message',
        SEND_TEXT_MESSAGE: 'Invia messaggio di testo',
        VERIFY_EMAIL: 'Verifica e-mail',
        SELECT_UNIT_NAME: "Seleziona il nome dell'unità",
        NEWS: 'Notizie',
        CLOSE_CASE: 'Chiudi caso',
        CUSTOMIZE: 'Personalizza',
        VIEW_MODE: 'MODALITÀ DI VISUALIZZAZIONE',
        VIEW_MODE1: 'Modalità di visualizzazione',
        LIST: 'Lista',
        COLUMN: 'Colonna',
        SETUP_SCHEDULING_PAGES: 'Impostazione delle pagine di pianificazione',
        SCHEDULER_LINK: "Collegamento all'utilità di pianificazione",
        SAVE_RUN: "SALVA ED ESEGUI",
        BATCH_CONVERT_TO_ACCOUNTS: 'Conversione batch in account',
        NEXT: 'Prossima/Prossimo',
        ADD_QUICKBOOKS: 'Aggiungi a Quickbooks',
        ITEM_FROM_QB: 'DA Quickbooks',
        PUSH_TO_QB: 'Aggiungi a Quickbooks',
        PUSH_TO_XERO: 'Add to Xero',
        ADD_NOTE: 'Aggiungi nota',
        ADD_NOTE_CONVERT: 'Aggiungi nota e converti',
        OBJECT_NAME: "Nome dell'oggetto",
        GREENLIGHT_EXPORT_DOWNLOAD: 'Processo & Download',
        PREVIOUS: 'Precedente',
        VIEW: 'Vista',
        MENU: 'Menù',
        NO_DUPLICATE: 'Nessun duplicato',
        ONGOING: 'Continuo',
        ONGOING_ONBOARDING: 'Onboarding in corso',
        SYNC_NOW: 'Sincronizza ora',
        'Generate Invoice': 'Genera fattura',
        'Add Payment': 'Aggiungi pagamento',
        'Print': 'Stampare',
        MOBILE_MENU: 'Menu per dispositivi mobili',
        PORTAL_MENU: 'Menu del portale',
        TO_QUICKBOOKS: 'Vai a Quickbooks',
        TO_XERO: 'A Xero',
        CONVERT_INVOICE: 'Converti in fattura',
        RECEIVE: 'Ricevere',
        ACCEPT: 'Accettare',
        CONFIRM: 'Confermare',
        FULFILL: 'Adempiere',
        SHIP: 'Nave',
        ADD_JOBS: 'Aggiunta di processi',
        ROYALTY_SETUP: 'Impostazione delle royalty',
        ROYALTY_REPORT_SETUP: 'Impostazione del report sulle royalty',
        WEBSITE_REVIEW: 'Sito web delle recensioni',
        UNIT_CRM: "CRM dell'unità",
        SAVE_AND_SCHEDULE: 'Salva e pianifica',
        UNLINK: 'Scollegare',
        TO_XMS: 'Vai a XMS',
        REFUND: 'Rimborso',
        ADD_TASK: 'Aggiungi attività',
        SEQUENTIAL:"Sequenziale",
        AMPERSAND: '&',
        INVOICE: 'Fattura',
        COLLECT: 'Raccogliere',
        EMAIL: 'E-mail',
        POST: 'Inserisci',
        PLAN_NAME: 'Nome del piano',
        CALCULATE: 'Calcolare',
        STAR: 'Stella',
        PIN :'Spillo',
        CONVERT: 'Convertire',
        SHARE_WITH : 'condividi con',
        FRANCHISOR: 'Franchisor',
        FRANCHISE: 'Franchising',
        ALL_PHONE_NO: 'Tutti i numeri di telefono',
        BRING_YOUR_OWN:'Numero BYO (Porta il tuo)',
        LEASE_NUMBER:'Numero di locazione',
        SURVEY_SETUP : 'Impostazione del sondaggio',
        THANKS_SURVEY:'Sondaggio Pollice in su: modulo di ringraziamento',
        REVIEW_WEBSITE_REQUIRED:'Controlla l\'URL del sito web (richiesto)',
        THUMBS_UP_TITLE:'Titolo pollice in su',
        THUMBS_UP_TITLE:'Descrizione pollice in su',
        YES_BUTTON:'Pulsante Sì',
        NO_BUTTON:'Pulsante Sì',
        DAYS: 'Giorno(i)',
        LOCATION_ALERT:'La posizione esiste già!',
        TASK_NAME:'Nome dell\'attività',
        DESC:'Descrizione',
        UNIT_CRM: 'Configurazione CRM dell\'unità',
        UNIT_CRM_LABEL: 'Gli amministratori dell\'Unità CRM possono:',
        UNIT_CRM_CUSTOMIZE_FORMS: 'Personalizza i moduli' ,
        UNIT_CRM_CUSTOMIZE_WORKFLOW: 'Personalizza i flussi di lavoro',
        ADD_NUMBERS:'AGGIUNGI NUMERI',
        ADD_CREDITS:'AGGIUNGI CREDITI',
        CALL_MINUTES:'minuti di chiamata e',
        TEXT_CREDIT:'credito residuo del testo',
        LEASE_A_NO:'Affitta un numero',
        TOLL_FREE:'Numero verde',
        LOCAL:'Locale',
        COUNTRY:'Paese',
        ACTION: 'Azioni:',
        INVOICE_ALL : 'Fattura tutti',
        EMAIL_ALL:'Invia un\'e-mail a tutti',
        POST_ALL: 'Pubblica tutto',
        REPORT_FIELDS: 'Campi del rapporto:',
        COMPLIANCE_NOTIFICATION:'Notifica di conformità',
        COMPLIANCE_LABEL:'Inserisci l\'indirizzo email per avvisare FR quando un affiliato non è conforme',
        ONBOARDING_NOTIFICATION:'Notifica di onboarding',
        EMAIL:'E-mail',
        HELPDESK_NOTIFICATION:'Notifica dell\'Helpdesk',
        LMS_SETUP:"'Configurazione dell'LMS'",
        LMS_LABEL:'Account LMS (richiesto)',
        PLUS_BASE: '+Base',
        UNITBASE:'Base dell\'unità'

    },
    NOTE:{
        ALL_NOTES:'Tutte le note',
        ALL_TYPES:'Tutti i tipi',        
    },
    HOME: {
        MY_FILTERS: 'I miei filtri	',
        GROUPS: 'Gruppi',
        ADD_NEW: 'Aggiungi nuovo',
        RECENT: 'Recente',
        HOME: 'Casa',
        CLICK_TO_REFRESH: 'Aggiornare',
        RECENTLY_VIEWED: 'Visti di recente',
        FILTERS: 'Filtri',
        ADD_GROUP: 'Aggiungi gruppo',
        NOTIFICATIONS: 'Notifiche',
        WHATS_NEW: "Cosa c'è di nuovo",
        COLLAPSE: 'Collasso',
        EXPAND: 'Espandere',
        ADD_FILTER: 'Aggiungi filtro',
        CLICK_TO_FIND_GROUP: 'Clicca qui per trovare un gruppo',
        CLICK_TO_FILTER_GROUP: 'Filtra i tuoi gruppi',
        CLICK_TO_FIND_FILTER: 'Clicca qui per trovare un filtro',
        FORECASTS: 'Previsioni',
        SALES_REPORT: 'Rapporti sulle vendite',

        //not found in doc
        ADD_FIELD: 'Fare clic qui per aggiungere un campo al filtro',
        FITER_SETUP: 'Impostazione del filtro',
        ENTER_BY_HAND: 'Entra a mano',
        IMPORT: 'Importazione',
        VIEW: 'Vista',
        DELETE: 'Cancellare',
        CLICK_TO_FIND_REPORT: 'Clicca qui per trovare un report',

    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'Nome della campagna (obbligatorio)',
        GROUP: 'Gruppo',
        CONTACT_FILTER: 'Filtro contatti',
        ACCOUNT_FILTER: 'Filtro account',
        FROM_NAME_REQUIRED: 'Nome mittente (obbligatorio)',
        VIEW_AS_WEB_PAGE: 'Visualizza come pagina Web',
        SUBJECT_REQUIRED: 'Oggetto (richiesto)',
        DAYS: 'Giorno(i)',
        EXECUTE_NOW: 'Esegui ora',
        TEST: 'Test',
        SCHEDULED_FOR: 'In programma per',
        EXECUTED_ON: 'Eseguito il',
        EXECUTING: 'Realizzazione',
        ABORTED: 'Interrotta',
        SCHEDULE: 'Programma',
        CLICK_URL: 'Fai clic su URL',
        SELECT_GROUP: 'Seleziona fino a tre gruppi',
        SELECT_CONTACT_FILTER: 'Seleziona un filtro dal contatto',
        SELECT_ACCOUNT_FILTER: "Seleziona un filtro dall'account",
        CHANGE_TEMPLATE: 'Cambia modello',
        WITHOUT_AB_TEST: 'Senza test a/b',
        WITH_AB_TEST: 'Con test a/b',
        WITH_AUTOMATION: 'Campagne automatizzate',
        WITHOUT_AUTOMATION: 'Campagne touch (legacy)',
        // SEND_ON_WEEK_DAY: 'Send on weekdays only (Mon-Fri)',
        SEND_ON_WEEK_DAY:  "Invia solo nei giorni feriali (lun-ven)",
        RESEND_AFTER: 'Invia di nuovo dopo',
        RESEND_ALL: 'Invia di nuovo tutto',
        FROM_EMAIL_REQUIRED: 'Da e-mail (richiesto)',
        CAMPAIGN_TYPE_OPTIONAL: 'Tipo di campagna (facoltativo)',
        CAMPAIGN_TYPE: 'Tipo di campagna',
        START_DATE_REQUIRED: 'Data di inizio (obbligatorio)',
        SENT: 'Inviato',
        OPENED: 'Aperto',
        UNOPENED: 'Chiusi',
        CLICKED: 'Cliccata/Cliccato',
        BOUNCED: 'Sbalzato',
        UNSUBSCRIBED:"Annullata l'iscrizione",
        SPAM: 'Spam',
        DROPPED: 'Caduto',
        TYPE_HERE: 'Digita qui.',
        ADD_SIGNATURE: 'Aggiungi firma.',
        INSERT_IMAGE: 'Inserisci immagine.',
        PERSONALIZE: 'Personalizzare.',
        TEMPLATE_DIALOG_TITLE: 'Seleziona un modello',

        // Campaign Status
        'All': 'Tutta/Tutto',
        'Active': 'Attiva/Attivo',
        'Running': 'Corsa',
        'Stopped': 'Fermata',

        'New': 'Nuova/Nuovo',
        'Queued': '	Accodati',
        'Executed': 'Eseguita/Eseguito',
        OPEN: 'Aperta/Aperto',
        BOUNCE: 'Rimbalzare',
        SPAMREPORT: 'Rapporto spam',
        'Halted': 'Interrotto',
        'Auto Response': 'Risposta automatica',
        'Marketing Template': 'Modello di marketing',
        'Newsletter': 'Newsletter',
        'Greetings': 'Saluti',
        'Inactive': 'Inattiva/Inattivo',
        'Executed - No Winner': 'Eseguito - Nessun vincitore',
        'Sent to Winner': 'Inviato al vincitore',
        ADD_CAMPAIGN_TYPE: 'Aggiungere un nuovo tipo di campagna',
        CAMPAIGN_NAME: 'Nome della campagna',
        SUBJECT: 'Soggetta/Soggetto',
        MANAGE: 'Amministrare',
        FROM_EMAIL: 'Da e-mail',
        SELECT_A_FILTER: 'Seleziona un filtro da ',
        BETWEEN: 'Tra',
        WEEKDAYS_ONLY: 'Solo nei giorni feriali'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: 'Personalizza oggetto',
        FROM_EMAIL_REQUIRED: 'Da e-mail (richiesto)',
        EMAIL_CAMPAIGN_REPORT: 'Rapporto sulla campagna e-mail',
        SCHEDULE_BROADCAST: 'Pianifica la trasmissione',
        RESCHEDULE_BROADCAST: 'Riprogramma la trasmissione',
        EXECUTE_BROADCAST: 'Esegui trasmissione',
        ADD_GROUP: 'Aggiungi gruppo',
        CATEGORY: 'Categoria',
        TEMPLATE: 'Sagoma',
        PERFORM_AB_TESTING: 'Eseguire test A/B',
        VERIFY_EMAIL: 'Verifica il tuo indirizzo e-mail',
        VERIFY_EMAIL_ADDRESS: 'Inserisci qui il tuo indirizzo e-mail',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verifica un altro indirizzo e-mail',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Modifica campagna di trasmissione',
        BATCH_EDIT: 'Modifica batch',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Copia campagna di trasmissione',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Crea campagna broadcast',
        All_BROADCASTS: 'Tutte le trasmissioni',
        BROADCASTS: 'Trasmissioni',
        ADD_SIGNATURE: 'Aggiungi firma',
        BROADCAST_INFO: 'Informazioni sulla trasmissione',
        DESIGN: 'Disegno',
        DRAG_AND_DROP: 'Trascina e rilascia',
        LEGACY: 'Eredità',
        EMAIL_BUILDER: 'Generatore di e-mail',
        QUALIFIED_CONTACTS: " Visualizzare l'elenco dei destinatarit",
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'Target per',
        AB_TEST_DISTRIBUTION: 'Distribuzione dei test A/B',
        MIN_MARGIN_PERCENT: 'Margine di vincita minimo %',
        A_PERCENT: 'A%',
        B_PERCENT: 'B%',
        AB_CAMPAIGN_REPORT: 'Rapporto campagna A/B',
        //not found in doc
        AB_SEND_REST: 'Invia il resto usando la vincitrice/Invia il resto usando il vincitore',
        AB_SELECT_WINNER_BY: 'Seleziona il vincitore per',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verifica un altro indirizzo e-mail',
        EMAIL_VIEWS: 'Visualizzazioni e-mail',
        EMAIL_CLICKS: 'Clic e-mail',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'Modifica campagna A/B',
        BATCH_EDIT: 'Modifica batch',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'Copia campagna A/B',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'Crea una campagna A/B',
        ALL_AB_CAMPAIGNS: 'Tutte le campagne AB',
        AB_TESTS: 'Test A/B	',
        MESSAGE_A: 'Messaggio A',
        MESSAGE_B: 'Messaggio B',
        AFTER: 'dopo',
        SCHEDULE_AB_CAMPAIGN: 'Pianifica campagna A/B',
        WINNER_BASEDON_A: 'Vincitrice (in base al messaggio A)/Vincitore (in base al messaggio A)',
        WINNER_BASEDON_B: 'Vincitrice (in base al messaggio B)/Vincitore (in base al messaggio B)'
    },
    CALENDAR: {
        CALENDAR_TASKS: 'Calendario/Attività',
        CALENDAR_ONLY: 'Calendario',
        CALENDARS: 'Calendario per',
        SHOW_OPTION_1: 'Annullato',
        SHOW_OPTION_2: 'Finito',
        SHOW_OPTION_3: 'Programmata/Programmato',
        TODAY: 'Oggi',
        BACK: 'Indietro',
        NEXT: 'Prossima/Prossimo',
        DAY: 'Giorno',
        WEEK: 'Settimana',
        MONTH: 'Mese',
        AGENDA: 'Agenda',
        DATE: 'Dattero',
        TIME: 'Ore',
        EVENT: 'Evento',
        ALL_DAY: 'tutto il giorno',
        WORK_WEEK: 'settimana lavorativa',
        YESTERDAY: 'Ieri',
        TOMMOROW: 'domani',
        SHOW_MORE: 'Mostra di più',
        TYPE: 'Tipa/Tipo',
        WORKING_HOUERS: 'Orario di lavoro',
        DEFAULT_lOCATION: '	Percorso predefinito',
        DEFAULT_DESCRIPTION: 'Descrizione predefinita',
        MY_CALENDAR_SETTING: 'Impostazioni del mio calendario',

        MY_TASK_SETTING: 'Impostazioni delle mie attività',
        DISPLAY: 'Esporre',
        ACCOUNT_NAME: "Nome dell'account",
        JOB_NAME: 'Nome del lavoro',
        COLOR_CODE: 'Codice colore',
    },
    GROUP: {
        GROUP_NAME: 'Nome del gruppo',
        DELETE_RECORD: 'Elimina record',
        USED_IN: 'Utilizzato in',
        REMOVE_FROM: 'Rimuovi da',
        SELECT_RECORDS: 'Record selezionati',
        ALL_RECORDS: 'Tutti i record',
        SELECT_OPTIONS: 'Seleziona le opzioni',
        GROUP_FILTER: '	Seleziona il filtro di gruppo',
        ALL: 'Tutta/Tutto',
        ACTIVE: 'Attiva/Attivo',
        INACTIVE: 'Inattiva/Inattivo',
        DETAILS: 'Dettagli',
        'Active': '	Attiva/Attivo',
        'Inactive': 'Inattiva/Inattivo',
        'New': 'Nuova/Nuovo',
        'scheduled': 'programmata/programmato',
        'Queued': '	Accodati',
        'Executed': 'Eseguita/Eseguito',
        'Running': 'Corsa',
        'Halted': 'Interrotto'
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'Attività',
        PERSONALIZE_OPTION_1: 'Scaduto',
        PERSONALIZE_OPTION_2: 'Oggi',
        PERSONALIZE_OPTION_3: 'Futura/Futuro',
        PERSONALIZE_OPTION_4: 'Domani',
        PERSONALIZE_SORT_1: 'Dattero',
        PERSONALIZE_SORT_2: 'Priorità',
        TASKS_FOR: 'Compiti per',
        SORT_BY: 'Ordina per',
        COMPLETE_THIS_APPOINTMENT: 'Completa questo appuntamento',
        COMPLETE_THIS_TASK: 'Completa questa attività',
        NEXT_DAY: 'Indomani',
        NEXT_WEEK: 'La settimana prossima',
        MONTH_WEEK: 'Il mese prossimo',
        MINI_EDIT: 'Redigere',
        COMPLETE: 'Completa/Completo',
        DELETE: 'Cancellare',
        TASKS: 'Attività',
        APPOINTMENTS: 'Appuntamenti',
        ADD_NOTE: 'Aggiungi una nota (facoltativo):',
        NEXT_MONTH: 'Il mese prossimo',
        DAYS: 'Giorni',
        SPECIFIC_DATE: 'Data specifica',
        RESCHEDULE: 'Riprogrammare',
        RESCHEDULE_FOR: 'Riprogramma per:',
        REPEAT: 'Ripetere',
        DAY_OF_THE_MONTH: 'giorno del mese',
        DAY_OF_THE_WEEK: 'giorno della settimana',
        REPEAT_TYPE: 'Tipo di ripetizione',
        REPEAT_ON: 'Ripeti su',
        REPEAT_BY: 'Ripeti entro',
        START_DATE: 'Data di inizio',
        END_DATE: 'Data di fine',
        REPEAT_EVERY: 'Ripeti ogni',
        REMOVE_REMINDER: 'Rimuovi promemoria',
        ONLY_THIS: 'Solo questo',
        THIS: 'Questa/Questo',
        THIS_AND_FOLLOWING: 'Questo e seguenti',
        FOLLOWING: 'Seguente',
        ALL: 'Tutta/Tutto',
        ONLY_THIS_RECORD: 'Solo questo record',
        FOLLOWING_RECORDS: 'Record seguenti',
        ALL_RECORDS: 'Tutti i record',
        ADD_A_NOTE: 'Aggiungi una nota',
        COMPLETE_TASK_OPTIONAL: 'Completare le attività in sospeso',
        COMPLETE_TASK_OPTIONAL_OBJ: 'Completa attività/e-mail/messaggi di testo in sospeso',
        CREATE_NEXT_TASKS: 'Creare le attività successive',
        CREATE_NEXT_TASKS_OBJ: 'Creare attività/e-mail/messaggi di testo successivi	',
        PENDING_TASKS: 'Attività in sospeso',
        SEARCH_BY_NAME: 'Ricerca per nome',
        INVITE_GUEST: 'Invita gli ospiti',
        ATTENDEES: 'Partecipanti',
        SALES_REP: 'Rappresentante',

        //not found in doc
        ADD_REMINDER: 'Aggiungi un promemoria',
        'Email': 'Email',
        'Pop-up': 'Popup',
        'minutes': 'verbale',
        'hours': 'orario',
        'days': 'Giorni',
        'weeks': 'settimane',
        DAILY: 'Quotidiana/Quotidiano',
        WEEKLY: 'Settimanalmente',
        MONTHLY: 'Mensile',
        YEARLY: 'Annuale',
        DAYS_S: 'Giorni',
        WEEKS: 'settimane',
        MONTHS: 'Mesi',
        YEARS: 'anni',
        APPOINTMENT: 'Appuntamento',
        TASK: 'Compito',
        SUBJECT: 'Soggetta/Soggetto',
        ASSIGN_TO: 'Assegna a',
        COMPANY: 'Società',
        CONTACT_NAME: 'Nome del contatto',
        OUTLOOK_SYNC: 'Sincronizzazione di Outlook',
        GOOGLE_SYNC: 'Sincronizzazione con Google',
        'Scheduled': 'Programmata/Programmato',
        'Completed': 'Finito',
        'Cancelled': 'Annullato',
        'Pending': 'In sospeso',
        TASK_NAME: 'Nome attività',
        DUE_BY: 'Scadenza entro',
        DESCRIPTION: 'Descrizione',
        OWNER: 'Proprietaria/Proprietario',
        FOLLOWUP: 'Seguito',
        'All': 'Tutta/Tutto',
        'Empty': 'Vuota/Vuoto',
        'No': 'No',
        'Yes': 'Si',
        'Everyone': 'Ciascuna/Ciascuno',
        'Just me': 'Solo io',
        ALL_TASK_COMPLETED: 'Questa attività è già stata completata.',
        'duration': 'Durata',
        'days_after': 'Giorni dopo',
        DEP_TASK_NAME: 'Nome attività',
        TASK_COMPLETED: 'Attività completata (%)',
        APPOINTMENTS_AND_TASKS: 'Appuntamenti e attività',
        APPOINTMENTS_ONLY: 'Solo su appuntamento',
        TASKS_ONLY: 'Solo attività',
        EDIT: 'Redigere',
        TASKS_CANCEL: 'Queste attività verranno annullate',
        SENDNOW: 'Invia ora',
        OPPORTUNITY_NAME: "Nome dell'opportunità",
        SELECT_USER: 'Seleziona utente',
        SELECT_STATUS: 'Seleziona Stato',
        VISITS: 'Visite',
        UNIT_OWNER_TASKS: "Compiti dell'unità",
        COMPLETE_UNIT_TASK_OPTIONAL: "Completare le attività in sospeso del proprietario dell'unità",
        UNIT_TASKS: "Compito per l'unità",
        DOCUMENTS: 'Documenti',
        LINKS: 'Campo da golf',
        TASK_SETTINGS: 'Impostazioni attività',
        APPOINTMENT_SETTINGS: 'Impostazioni degli appuntamenti',
    },
    ATTACHMENT: {
        UPLOAD: 'Caricare',
        MY_COMPUTER: 'Risorse del computer',
        BROWSE: 'Sfogliare',
        DOWNLOAD: 'Scarica il file',
        ATTACH_FILE: 'Allega un file',
        UPLOADING: 'Caricamento',
        NO_IMAGE_FOUND: 'Nessuna immagine trovata',
        DOWNLOAD_RECORDING: 'Scarica la registrazione',
        ATTACHMENT: 'Allegato',
        ADD_AN_ATTACHMENT: 'Aggiunta di un allegato'

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'Clicca qui per chiudere',
        CLICK_TO_EXPAND: 'Clicca qui per ingrandire',
        SHOW_TOTAL: 'Mostra totale',
        SHOW_PICTURE: "Mostra l'immagine",
        DESCENDING: 'Discendente',
        SORT_BY: 'Ordina per',
        SELECT_COLUMN_FOR_VIEW: 'Selezionare i campi per questa vista',
        SELECT_COLUMN_FOR_FILTER_VIEW: 'Select columns for the filter resultsSelezionare le colonne per i risultati del filtro',
        ADD_AND_REORDER: 'Aggiungi e riordina',
        FIELDS: 'Campi',
        CLICK_TO_ADD_MORE_FIELDS: 'Fare clic su questa casella per aggiungere altri campi. Trascina e riordina i campi per modificarne la posizione',
        SELECT_FIELDS: 'Seleziona i campi',
        MODIFY_FIELD: 'Modifica campo',
        EXPORT_SELECTED_RECORDS: 'Esporta i record selezionati',
        EXPORT_ALL_RECORDS: 'Esporta tutti i record',
        LINK_EXISTING: 'Collegamento esistente',
        SET_UP: 'Apparecchio',
        GROUP_BY: 'Raggruppa per',
        FIND_BY_FIELD: 'Trova per',
        FILTER_TYPE: {
            DEFAULT_FILTERS: 'Filtri predefiniti',
            SHARED_FILTERS: 'Filtri condivisi'
        },
        COLUMN_VIEW_SETUP_INFO: 'Questa visualizzazione a colonne mostrerà solo i primi cinque campi. La visualizzazione elenco mostra tutti i campi.',
        COUNT: "Contare",
        ALPHABETICAL: "Alfabetico",
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'Mostra etichette',
        SELECT_OPTIONS: 'Seleziona le opzioni',
        CLICK_TO_ADD_MORE_FIELDS: "Fare clic su questa casella per aggiungere altri campi. Trascina e riordina i campi per modificarne l'ordine. È possibile selezionare un massimo di 50 campi.",
        SETUP_FIELDS: 'Campi di impostazione',
        ADD_OR_REMOVE_FROM_LIST: "Aggiungi/Rimuovi campi dall'elenco",
        CLICK_TO_KNOW_MORE: 'Clicca per saperne di più',
        CLICK_TO_ADD_CHILD_OBJECTS: 'Fare clic su questa casella per aggiungere altri oggetti figlio. Trascina e riordina gli oggetti per modificarne la posizione.',
        SETUP_CHILD_TABS: 'Imposta schede secondarie',
        SHOW_LABELS_AGAINST_FIELD: 'Selezionare questa opzione per visualizzare le etichette nei campi.',
        CUSTOMIZE_CHILD: 'Personalizza bambino',
        REFUND_CHILD_LABEL: 'Rimborso',
        //not found in doc 
        GROUPS: 'Gruppi',
        MANAGE: 'Amministrare',
        ADD_TO_GROUP: 'Aggiungi al gruppo',
        DATA_TIP_1: 'In questo modo si creerebbero i compiti per il ',
        DATA_TIP_2: 'In questo modo verranno create le attività/e-mail/messaggi di testo per il ',
        DATA_TIP_3: ' Palco',

        //opportunity info 
        HIGHLIGHT_LABEL_OPPORTUNITY: 'Opportunità $',
        HIGHLIGHT_LABEL_FORECAST: 'Previsione $',
        HIGHLIGHT_LABEL_CLOSING_ON: 'Chiusura su',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: "Età dell'opportunità",
        HIGHLIGHT_LABEL_DAYS: "Giorni",

        CHILD_SETUP_SELECT_CHILD_LABEL: "Fare clic qui per aggiungere un oggetto figlia all'installazione/Fare clic qui per aggiungere un oggetto figlio all'installazione.",
        NO_SETUP_OPTION: 'Nessuna/Nessuno'
    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'Definisci relazione',
        RELATE: 'Rapportare',
        COMPANY: 'Società',
        FULL_NAME: 'Nome e Cognome',
        EMAIL: 'Email'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: ' Rapporto campagna Touch',
        INTERVAL: 'Dopo',
        NOTE_TYPE: 'Tipo di nota',
        TEXT_REQUIRED: 'Testo della nota (obbligatorio)',
        TASK_NAME_REQUIRED: "Nome dell'attività (obbligatorio)",
        OWNER_REQUIRED: 'Proprietario (richiesto)',
        DUE_BY_REQUIRED: 'Scadenza entro (obbligatorio)',
        DAY_AFTER_REQUIRED: 'Giorni dopo (obbligatorio)',
        EMAIL: 'Email',
        POP_UP: 'Popup',
        MINUTES: 'verbale',
        HOURS: 'orario',
        DAYS: 'Giorni',
        WEEKS: 'settimane',
        REMINDER: '	Promemoria',
        GROUP_REQUIRED: 'Seleziona gruppo (obbligatorio)',
        ADD_TO_GROUP: 'Aggiungi al gruppo',
        MOVE_TO_GROUP: 'Sposta nel gruppo',
        REMOVE_FROM_GROUP: 'Rimuovi dal gruppo',
        STATUS_REQUIRED: 'Stato (obbligatorio)',
        WORKFLOW_REQUIRED: 'Flusso di lavoro (obbligatorio)',
        CHOOSE_ANOTHER_FIELD: 'Scegli un altro campo',
        CHOOSE: 'Scegliere',
        FIELD_FOR: 'un campo per',
        CAMPAIGN_NAME: 'Nome della campagna (obbligatorio)',
        START: 'Inizio',
        STOP: 'Fermarsi',
        REPORT: 'Rapporto',
        RESUME: 'Riassumere',
        SELECT_PAGE: 'Seleziona una pagina',
        ENTER_WEB_PAGE_LINK_OPTIONAL: "Inserisci l'URL del link (facoltativo).",
        ENTER_WEB_PAGE_LINK: 'Immettere il collegamento alla pagina Web.',
        CLICK_TO_VIEW: 'Clicca qui per visualizzare',
        CLICK_TO_EDIT: 'Clicca qui per modificare',
        DRAG_TO_MOVE: 'Trascina per spostarti',
        DRAG_TO_CONNECT: 'Trascina per connetterti',
        CLICK_TO_REMOVE: 'Clicca qui per rimuovere',
        CLICK_TO_SEE_REPORT: 'Clicca qui per vedere il report',
        CLICK_TO_ADD_MSG: 'Fai clic per aggiungere un nuovo messaggio',
        NO: 'No',
        YES: 'Si',
        CLICK_TO_SELECT_USERS: 'Clicca qui per selezionare gli utenti',
        SALES_REP: 'Rappresentante',
        USERS: 'Gli utenti',
        CAMPAIGN_SENDER: 'Mittente della campagna',
        SCORE: 'Punteggio',
        START_WITH: 'Inizia con',//'Start with'
        DECISIONS: 'Decisioni',
        ACTIONS: 'Azioni',
        LINK_CLICKED: 'Link cliccato?',
        SCORE_REACHED: 'Punteggio raggiunto?',
        TOUCH_CAMPAIGN: 'Campagna Touch',
        WEB_FORM: 'Modulo Web',
        LANDING_PAGE: 'Pagina di destinazione',
        WEBSITE_VISITED: 'Sito web visitato',
        CHANGE_GROUP: 'Cambia gruppo',
        ADD_NOTE: 'Aggiorna campi',
        NOTIFY: 'Notificare',
        ADD_TASK: 'Aggiungi attività',
        FIELD_UPDATED: 'Aggiorna campi',
        CHOOSE_TEMPLATE: 'Scegli un modello',
        USE_THIS_TEMPLATE: 'Usa questo modello',
        WAIT: 'Aspettare',
        NAME: "Nome",
        TARGET_GROUPS: 'Gruppo/i target(i)',
        TARGET_FILTER: 'Target Filter',
        RESEND_AFTER: 'Invia di nuovo dopo',
        START_DATE: 'Start Date',
        SUBJECT: 'Soggetta/Soggetto',
        TEXT: 'Testo',
        TASK_NAME: 'Nome attività',
        ASSIGN_TO: 'Assegna a',
        FIELD_LABEL: 'Etichetta campo',
        FIELD_VALUE: 'Valore del campo',
        LINK: 'Collegamento',
        SEND_ON_ANY_TIME: 'Invia su: In qualsiasi momento',
        SEND_ON_BUSINESS_DAY: 'Invia il: Solo nei giorni lavorativi',
        CONVERT_TO: 'Converti in',
        QUALIFIER: 'Qualificatore',
        OBJECT: 'Oggetto',
        NOTIFY_SENDER: 'Notifica al mittente',
        NOTIFY_SALES_REP: 'Avvisa il rappresentante di vendita',
        NOTIFY_SENDER_SALESREP: 'Notifica al mittente, notifica al rappresentante di vendita',
        NOTIFY_USERS: 'Notifica agli utenti',
        TEST: 'Test',
        VIEW_AS_WEBPAGE_INFO: "Questa opzione aggiungerà un collegamento nella parte superiore dell'e-mail per aprire l'e-mail come pagina Web",
        LINK_CLICK_INFORMATION: "Se lasci vuoto questo campo, tutti i link nell'e-mail verranno tracciati.",
        GROUP_NAME: 'Nuovo nome del gruppo.',
        SELECT_USER: 'Assegna a',
        REMIND_BEFORE: 'Ricorda prima',
        INTERVAL_REQUIRED: 'Intervallo (obbligatorio)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verifica un altro indirizzo e-mail',
        'Touch Campaign': 'Campagna Touch',
        'Link Clicked': 'Link cliccato',
        'Link Clicked?': 'Link cliccato?',
        'Email Opened': 'E-mail aperta',
        'Email Opened?': 'E-mail aperta',
        'Unsubscribed': "Annullata l'iscrizione",
        'Score Reached': 'Punteggio raggiunto',
        //'Send One-off Email': 'Invia un'e-mail una tantum',
        'Send Email': 'Invia e-mail',
        'Change Group': 'Cambia gruppo',
        'Add Note': 'Aggiungi nota',
        'Notify': 'Notificare',
        'Add Task': 'Aggiungi attività',
        'Update Fields': 'Aggiorna campi',
        'Assign': 'Assegnare',
        'Convert': 'Convertire',
        'Start': 'Inizio',
        'Add Touch Message': 'Aggiungi messaggio touch',
        'Web Form': 'Modulo Web',
        'Website Visit': 'Visita al sito web',
        'Landing Page': 'Pagina di destinazione',
        'Wait': 'Aspettare',
        WAIT_FOR_DAYS: 'giorno(i)',
        'Simple email nurturing': 'Semplice nurturing delle e-mail',
        'Capture leads using a web form and nurture': 'Acquisisci lead utilizzando un modulo web e coltiva',
        'Track web page visit and nurture': 'Monitora la visita e il consolidamento della pagina web',
        'Capture leads from a landing page and nurture': 'Acquisisci lead da una landing page e coltivali',
        'Start with tracking website visit': 'Inizia con il monitoraggio delle visite al sito web',

        COPY_OF: 'Copia di  ',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Nuova campagna automatizzata',
        TARGET_BY: 'Target per',
        'Hours': 'Orario',
        'Hour(s)': 'Orario',
        'day(s)': 'day(s)',
        'hour(s)': 'hour(s)',
        ALL_AUTOMATED_CAMPAIGNS: 'Tutte le campagne automatizzate',
        MESSAGE: 'Messaggio',
        SELECT_A_CAMPAIGN: 'Seleziona una campagna',
        ADD_SIGNATURE: 'Aggiungi firma',
        BIRTHDAY_CAMPAIGN: 'Campagna di compleanno',
        'Add Birthday Message': 'Aggiungi messaggio di compleanno',
        'Start with Birthday Campaign': 'Inizia con la campagna di compleanno',
        'SMS Replied': 'SMS Risposta',
        'Send SMS': 'Invia SMS',
        PET_BIRTHDAY_CAMPAIGN: 'Campagna di compleanno per animali domestici',

        'Touch Campaign Automation': 'Automazione delle campagne touch',
        'Birthday Campaign Automation': 'Automazione delle campagne touch',
        'Web Form Automation': 'Automazione dei moduli Web',
        'Landing Page Automation': 'Automazione della pagina di destinazione',
        'Pet Birthday Greetings': 'Auguri di compleanno per animali domestici',


        SEND_SMS: 'Invia SMS',
        SMS_REPLY: 'SMS ha risposto?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Rapporto sulla campagna di compleanno',
        START_IMMEDIATELY: 'Immediatamente',
        START_SCHEDULE: 'Programma',
        SCHEDULE_ON_TEXT: 'Inizio programmato il',
        SCHEDULE_DIALOG_TITLE: "Seleziona la data e l'ora del programma",
        FROM_NUMBER: 'Da numero',
        REPLY: 'Risposta',
        NOT_CONVERTED: 'Non convertito',
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'Tutte le campagne Touch',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalizza oggetto',
        NAME_REQUIRED: 'Nome (richiesto)',
        STATUS: 'Stato',
        SUBJECT_REQUIRED: 'Oggetto (richiesto)',
        ACTIVE: 'Attiva/Attivo',
        INACTIVE: 'Inattiva/Inattivo',
        TEST: 'Test',
        SELECT_TEMPLATE: 'Inizia a digitare per selezionare un modello',
        TYPE_HERE: 'Digita qui.',
        ADD_SIGNATURE: 'Aggiungi firma',
        INSERT_IMAGE: 'Inserisci immagine',
        PERSONALIZE: 'Personalizzare',
        SELECT_FROM_LIBRARY: 'Seleziona dalla libreria',
        USE_THIS_DESIGN: 'Usa questo design',
        DEAR: 'Cara/Caro',
        EXPAND_EDITOR: 'Schermo intero',
        SHARE: 'Da utilizzare sia per le e-mail di marketing che per quelle di vendita',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'Crea un modello di e-mail di marketing',
        COPY_OF: 'Copia di',
        BATCH_EDIT: 'Modifica batch',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'Modifica il modello di e-mail di marketing',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: 'Copia modello di e-mail di marketing',
        CATEGORY: 'Categoria',
        LAYOUT: 'Impaginazione',
        'Auto Response': 'Risposta automatica',
        'Marketing Template': 'Modello di marketing',
        'Newsletter': 'Notiziario',
        'Greetings': 'Saluti',
        CHOOSE_EMAIL_BUILDER: 'Scegli un generatore di e-mail',
        DRAG_AND_DROP_BUILDER: 'Usa il nuovo builder drag-and-drop',
        LEGACY_BUILDER: 'Usare il generatore legacy',
        TEMPLATE_INFO: 'Informazioni sul modello',
        DESIGN: 'Disegno',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalizza oggetto',
        NAME_REQUIRED: 'Nome (richiesto)',
        STATUS: 'Stato',
        SUBJECT_REQUIRED: 'Oggetto (richiesto)',
        ACTIVE: 'Attiva/Attivo',
        INACTIVE: 'Inattiva/Inattivo',
        TEST: 'Test',
        TYPE_HERE: 'Digita qui.',
        ADD_SIGNATURE: 'Aggiungi firma',
        INSERT_IMAGE: 'Inserisci immagine',
        PERSONALIZE: 'Personalizzare',
        DEAR: 'Cara/Caro',
        SALES_TEMPLATE_CREATE_TAB_LABEL: 'Crea un modello di e-mail di vendita',
        SALES_TEMPLATE_EDIT_TAB_LABEL: 'Modifica il modello di e-mail di vendita',
        SALES_TEMPLATE_COPY_TAB_LABEL: 'Copia modello di e-mail di vendita',
        COPY_OF: 'Copia di',
        SALES_TEMPLATES: 'Modelli di e-mail di vendita'
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalizza oggetto',
        NAME_REQUIRED: 'Nome (richiesto)',
        STATUS: 'Stato',
        // SUBJECT_REQUIRED: 'Oggetto (richiesto)',
        ACTIVE: 'Attiva/Attivo',
        INACTIVE: 'Inattiva/Inattivo',
        TEST: 'Test',
        TYPE_HERE: 'Digita qui.',
        ADD_SIGNATURE: 'Aggiungi firma',
        INSERT_IMAGE: 'Inserisci immagine',
        PERSONALIZE: 'Personalizzare',
        DEAR: 'Cara/Caro',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: 'Crea un modello di accordo',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: 'Modifica modello di accordo',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: 'Copia modello di accordo',
        AGREEMENT_TEMPLATE_FDD_CREATE_TAB_LABEL: 'Crea modello FDD',
        AGREEMENT_TEMPLATE_FRANCHISE_AGREEMENT_CREATE_TAB_LABEL: 'Crea un modello di contratto di franchising',
        COPY_OF: 'Copia di',
        AGREEMENT_TEMPLATES: 'Modelli di accordo',
        ADD_SIGNATURE_1: 'Firma 1',
        ADD_SIGNATURE_2: 'Firma 2',
        ADD_SIGNATURE_3: 'Firma 3',
        ADD_SIGNATURE_4: 'Firma 4',
        ADD_SIGNATURE_5: 'Firma 5',
        COVER_LETTER: 'Lettera di presentazione',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'Modifica modello SMS',
        SMS_TEMPLATE_COPY_TAB_LABEL: 'Copia modelli SMS',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'Inserisci il nome del tuo dominio (escludi http o www)',
        GENERATE_TRACKING_CODE: 'Genera codice di monitoraggio',
        TRACKING_CODE: 'Il tuo codice di monitoraggio',
        COPY_TO_CLIPBOARD: 'Copia negli appunti',
        DONE: 'Fatto'

    },
    WEB_FORM: {
        SELECT_FORM: 'Seleziona un modulo',
        WEB_FORM_CREATE_TAB_LABEL: 'Crea moduli Web',
    },
    MAIL: {
        EMAIL_FOR_TESTING: 'Inserisci l\'e-mail per il test',
        SEND_A_TEST_EMAIL: 'Invia una mail di prova',
        TYPE_HERE: 'Digitare qui',
        INSERT_IMAGE: 'Inserisci immagine',
        ADD_SIGNATURE: 'Aggiungi firma',
        PERSONALIZE: 'Personalizzare',
        FROM: 'Da',
        SEND_MAIL: 'Invia una email',
        PERSONALIZE_SUBJECT: 'Personalizza il soggetto',
        SUBJECT: 'Soggetto',
        COMPUTER: 'Dal computer',
        LIBRARY: 'Dalla biblioteca',
        RESCHEDULE: 'Riprogrammare',
        TO: 'A',
        ATTACH: 'Allega un file',
        CC: 'Cc',
        WHEN: 'Quando',
        SENDING_TEST_MAIL: 'Invio di email di prova...',
        SCHEDULE: 'Programma',
        SCHEDULE_DIALOG_TITLE: 'Pianifica una data e un\'ora',
        SEND_LATER: 'INVIA PIÙ TARDI',
        SEND: 'Inviare',
        RECIPIENTS: 'Destinatari',
        SENDING: 'Invio...'

    },
    SMS: {
        TO: 'A',
        WHEN: 'Quando',
        FROM: 'Da',
        FROM_NUMBER: 'Dal numero',
        TO_NUMBER: 'Al numero',
        SEND_A_TEST_SMS: 'Invia un SMS di prova',
        STATUS: 'Stato',
        ENTER_NUMBER: 'Immettere il numero per il test'
    },
    AUDIT_LEADSCORE:{
        LEAD_SCORE_HISTORY: 'Cronologia del punteggio principale',
        SCORE: 'Punto',
        DATE: 'Data',
        ACTION: 'Azione',
        USER: 'Utente',
        DETAILS: 'Dettagli',
        ACTION_NAME: 'Nome dell\'azione',
        SCORE_DETAILS: 'Dettagli del punteggio',
        BY: 'di',
        FROM: 'da',
        LINK: 'Collegamento',
        CLICKED: 'Clicato',
        EMAIL: 'E-mail',
        OPENED: 'ha aperto',
        UNSUBSCRIBED: 'Iscrizione annullata',
        CONVERTED_FROM: 'Convertito da',
        TO: 'A',
        IN_OPPORTUNITIES: 'nelle Opportunità',
        ADDED_TO: 'Aggiunto a',
        REMOVED_FROM: 'Rimosso da',
        UPDATED_TO: 'aggiornato a',
        WEBFORM: 'Modulo web',
        SUBMITTED: 'Presentato',
        VISITED: 'Visitato',
        EXPIRED: 'Scaduto',
        'Imported': 'Importato',
        'Created': 'Creato',
        'Converted to Account': 'Convertito in conto',
        'Created from Web form': 'Creato da modulo Web',
        'Created from Landing page': 'Creato dalla pagina di destinazione',
        'Created from Zapier': 'Creato da Zapier',
        'Created from Outlook': 'Creato da Outlook',
        'Created from Outlook (Client)': 'Creato da Outlook (client)',
        'Created from G Suite': 'Creato da G Suite',
        'Created from Office 365': 'Creato da Office 365',
        'Created from Facebook/twitter etc': 'Creato da Facebook/Twitter ecc',
        'Pushed': 'Spinto',
        'Created from API': 'Creato dall\'API',
        'Inline/Quick updated': 'In linea/Aggiornamento rapido',
        'Updated': 'Aggiornato',
        'Batch Updated': 'Lotto aggiornato',
        'Workflow Applied': 'Flusso di lavoro applicato',
        'Converted': 'Convertito',
        'Assigned': 'Assegnato',
        'Deleted': 'Eliminato',
        'Restored': 'Restaurato',
        'Merged': 'Uniti',
        'Main Contact updated': 'Contatto principale aggiornato',
        'Shared': 'Condiviso',
        'Link Clicked': 'Collegamento cliccato',
        'Email Opened': 'Email aperta',
        'Unsubscribed': 'Iscrizione annullata',
        'Web Form Submitted': 'Modulo Web inviato',
        'Removed from Group': 'Rimosso dal gruppo',
        'Field Updated': 'Campo aggiornato',
        'Added to Group': 'Aggiunto al gruppo',
        'Website Visited': 'Sito Web visitato',
        'Has Business Email': 'Ha un\'e-mail aziendale',
        'Clicked Link': 'Collegamento cliccato',
        'Opened Email': 'Email aperta',
        ACCOUNT_SCORE: 'Il tuo punteggio perfetto per Conti è  ',
        CONTACT_SCORE: 'Il tuo punteggio perfetto per Contatti è  '
    },
    DASHBOARD: {
        TODAY: 'Oggi',
        YESTERDAY: 'Ieri',
        THIS_WEEK: 'Questa settimana',
        THIS_MONTH: 'Questo mese',
        THIS_QUARTER: 'Questo quarto',
        THIS_YEAR: 'Quest\'anno',
        LAST_WEEK: 'La settimana scorsa',
        LAST_MONTH: 'Lo scorso mese',
        LAST_QUARTER: 'Ultimo quarto',
        LAST_YEAR: 'L\'anno scorso',
        CUSTOM: 'Costume',

        //Kanban View
        NEXT_MONTH: 'Il prossimo mese',
        NEXT_QUARTER: 'Prossimo trimestre',
        NEXT_THREE_MONTHS: 'I prossimi tre mesi',
        NEXT_TWO_MONTHS: 'I prossimi due mesi',

        SALES_DASHBOARD: 'Cruscotto di vendita',
        MARKETING_DASHBOARD: 'Cruscotto di marketing',
        // SALES_MARKETING_DASHBOARD: 'Cruscotto vendite e marketing',
        REPOSITION_GADGET: 'Fai clic e trascina per riposizionare questo gadget',
        'Top Sources By Lead Volume': 'Principali fonti per volume di lead',
        'Top Sources By Lead Conversion': 'Principali fonti per conversione dei lead',
        'Marketing Email Summary': 'Riepilogo e-mail di marketing',
        'Top Marketing Emails by CTR': 'Principali email di marketing per CTR',
        'Top Web Pages': 'Principali pagine Web',
        'Lead Pipeline': 'Conduttura principale',
        'Opportunity Pipeline': 'Pipeline di opportunità',
        'Sales Leaderboard': 'Classifica delle vendite',

        'Status': 'Stato',
        'Count': 'Contare',
        'Subject': 'Soggetto',
        'Click-Through Rate(CTR)': 'Percentuale di clic (CTR)',
        'Website Name': 'Nome del sito web',
        'Forecast': 'Previsione',
        'Sales person': 'Venditore',
        'Closed': 'Chiuso',

        'Activities': 'Attività',
        'Accounts': 'Matrice dei conti',
        'Opportunities': 'Matrice delle opportunità',
        'Users': 'Utenti',
        'Appointment': 'Appuntamento',
        'Dialed': 'Chiamato',
        'Email': 'E-mail',
        'LVM': 'LVM',
        'Note': 'Nota',
        'Spoke': 'Parlato',
        'Task': 'Compito',
        'Transferred': 'Trasferito',

        'Qualified': 'Qualificato',
        'Presentation': 'Presentazione',
        'Proposal': 'Proposta',
        'Negotiation': 'Negoziazione',
        'Close': 'Vicino',

        'Lead': 'Guida',
        'Prospect': 'Prospettiva',
        'Customer': 'Cliente',
    },
    LOGIN: {
        LOG_IN: 'Login',
        EMAIL_ADDRESS: 'Indirizzo e-mail',
        PASSWORD: 'Parola d\'ordine',
        REMEMBER_ME: 'Ricordati di me'
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: 'Salva come bozza',
        POST_NOW: 'Pubblica ora',
        CONNECT: 'Collegare'

    },
    DOCUMENT_LIBRARY: {
        COMPUTER: 'Computer',
        LIBRARY: 'Biblioteca',
        IMAGES: 'Immagini',
        STOCK_IMAGES: 'Immagini di riserva',
        DOCUMENTS: 'Documenti',
        SEARCH: 'Ricerca...',
        UPLOAD_DOCUMENT: 'Carica documento',
        NAME: 'Nome',
        TITLE: 'Titolo',
        TITLE_REQUIRED: 'Titolo (richiesto)',
        LINK_REQUIRED: 'Collegamento (richiesto)',
        TYPE: 'Tipo',
        OWNER: 'Proprietario',
        ALL: 'Tutto',
        NO_IMAGE_FOUND: 'Nessuna immagine trovata',
        UPLOAD_DIALOG_TITLE: 'Seleziona un documento da caricare',
        SHARED_WITH: 'spinto con',
        UNITS: 'unità',
        UNIT: 'Unità',
        UNIT_PUSH: 'Unità:',
        RENAME_FOLDER: 'Rinomina cartella',
        RENAME_FILE: 'Rinomina il file',
        CREATE_FOLDER: 'Crea una cartella',
        NEW_FOLDER: 'Nuova cartella',
        ASSETS_NOT_FOUND: 'Nessuna cartella e file trovato.',
        Folder: 'Cartella',
        'File': 'File',
        'IMAGE': 'Immagine',
        DOCUMENT_AND_FILE: 'Documenti e file',


    },
    HEADER: {
        SALES_TEMPLATE: 'Modello di email di vendita',
        CALENDAR_TASKS: 'Calendario/Attività',
        ACCOUNT: 'Account',
        SALES_REPORT: 'Rapporto delle vendite',
        ACCOUNTS: 'Conti',
        ACCOUNTS_WITHOUT_GROUP: 'Conti senza gruppi',
        MY_ACCOUNT: 'I miei conti',
        TODAYS_ACCCOUNTS: 'I conti di oggi',
        CONTACTS: 'Contatti',
        CONTACTS_WITHOUT_ACCOUNT: 'Contatti senza account',
        CONTACTS_WITHOUT_ACCOUNTS: 'Contatti senza account',
        CONTACTS_WITHOUT_GROUPS: 'Contatti senza gruppi',
        MY_CONTACTS: 'I miei contatti',
        ACCOUNTS_WITHOUT_CONTACT: 'Conti senza contatto',
        TODAYS_CONTACTS: 'I contatti di oggi',

        ALL_APPOINMENTS: 'Tutti gli appuntamenti',
        MY_APPOINMENTS: 'I miei appuntamenti',
        MY_COMPLETED_APPOINMENTS: 'I miei appuntamenti completati',
        MY_MISSED_APPOINMENTS: 'I miei appuntamenti mancati',
        TODAYS_APPOINMENTS: 'Gli appuntamenti di oggi',

        ALL_TASKS: 'Tutti i compiti',
        MY_COMPLETED_TASKS: 'Le mie attività completate',
        MY_FUTURE_TASKS: 'I miei compiti futuri',
        MY_PENDING_TASKS: 'I miei compiti in sospeso',
        MY_TODAYS_TASKS: 'I miei compiti di oggi',
        ONBOARDING: 'Onboarding',
        SALES_PIPELINE:'Rapporto sulla pipeline di vendita',
        COUPONS_CLAIM_REPORTS: 'Rapporti sulle richieste dei coupon',
        'Cases': 'Casi',
        'Projects': 'Progetti',
        'Issues': 'Problemi',
        'Solutions': 'Soluzioni',
        'Contacts': 'Contatti',
        'Accounts': 'Conti',
        'Opportunities': 'Opportunità',
        'Appointments': 'Appuntamenti',
        'Tasks': 'Compiti',
        'Onboarding': 'Onboarding',
        'Units': 'Unità',
        'Assets': 'Biblioteca',
        'Activities': 'Attività',
        'Conversions': 'Conversioni',
        'Campaigns': 'Campagne',
        'Reports': 'Rapporti',
        'Sales_templates ': 'Modelli di vendita',
        'Dashboard': 'Pannello di controllo',
        'Sales Leads': 'Lead di vendita',
        'Recycle Bin': 'Cestino',
        'News': 'Notizia',
        REPORTS: 'Rapporti',
        REPORT: 'Rapporto',
        TASKS_PAGE: 'Compiti',
        'Products': 'Prodotti',
        'Landing Pages': 'Pagine di destinazione',

    },
    ADVANCE_SEARCH: {
        ADVANCE_SEARCH: 'Ricerca',
        ADD_FIELD: 'Cerca in un altro campo'
    },
    IMPORT: {
        GROUP: 'Gruppo (Richiesto)',
        WORKFLOW: 'Flusso di lavoro (Richiesto)',
        STATUS: 'Stato (Richiesto)',
        SOURCE: 'Fonte (Richiesta)',
        PRIMARY_SOURCE:'Fonte primaria',
        SECONDARY_SOURCE:'Fonte secondaria',
        IMPORT: 'Importa',
        LINK_USING: 'Collegamento Utilizzando',
        LINK_WITH: 'Collegamento con',
        APPLY_WORKFLOW: 'Applica Flusso di lavoro',
        AUTO_MAP: 'Mappa Automatica',
        USE_SAVED_MAPPING: 'Utilizza Mappatura Salvata',
        SAVE_MAPPING: 'Salva Mappatura',
        DELETE_MAPPING: 'Elimina Mappatura',
        DONOT_CHECK_DUPLICATE: 'Non Controllare i Duplicati',
        CHECK_DUPLICATE: 'Controlla i Duplicati',
        CHECK_DUPLICATE_BY: 'Controlla i Duplicati per',
        DONOT_IMPORT: 'Non Importare',
        MARK_DUPLICATE: 'Importa e Contrassegna come Duplicato',
        UPDATE: 'Aggiorna',
        //not found in doc
        STEP_1: 'Seleziona Sorgente',
        STEP_2: 'Mappa Campi e Importa',
        FINISH: 'Fine',
        NEXT: 'Prossimo',
        MAP_CSV_COLUMNS: 'Mappa Colonne CSV su CRM',
        MAP_XLS_COLUMNS: 'Mappa Colonne XLS su CRM',
        MAP_XLSX_COLUMNS: 'Mappa Colonne XLSX su CRM',
        MAP_TO_CRM_FIELD: 'Mappa su Campo CRM',
        COLUMN: 'Colonna',
        ADD_NEW_GROUP: 'Aggiungi un Nuovo Gruppo',
        WORKFLOW_OPTIONAL: 'Flusso di lavoro (Facoltativo)',
        FILE_MUST_CSV: 'Il file deve essere in formato CSV.',
        SKIP: 'Salta',
        MERGE: 'Unisci',
        CHECK_DUPLICATE_USING: 'Controlla Duplicati Utilizzando',
    },
    REPORT: {
        START_DATE: 'Data d\'inizio',
        END_DATE: 'Data di fine',
        REPORT_ON: 'Riferire in merito',
        REFRESH: 'Ricaricare',
        VIEW: 'Visualizzazione',
        PRINT: 'Stampa',
        SALES_REP: 'Rappresentante delle vendite',
        DATE_RANGE: 'Intervallo di date',
        SALES_REPORT: 'Rapporti sulle vendite',
        SERVICE_REPORT: 'Rapporti di servizio',
        'Activity Report': 'Rapporto sull\'attività',
        'Conversion Report': 'Rapporto di conversione',
        'Call Report': 'Rapporto sulle chiamate',
        'Pipeline Report': 'Rapporto sulla pipeline',
        'Customer Activation Report': 'Rapporto sull\'attivazione del cliente',
        'Customer Service Type Report': 'Rapporto sul tipo di servizio clienti',
        'Customer Assessment Report': 'Rapporto di valutazione del cliente',
        'Customer Leads Assessment Report': 'Rapporto sulla valutazione dei lead del cliente',
        'Customer Status Report': 'Rapporto sullo stato del cliente',
        'Sales Pipeline Report': 'Rapporto sulla pipeline di vendita',
        'Days in Franchise Workflow Report': 'Rapporto sul flusso di lavoro del franchising',
        'Franchise Workflow Report': 'Rapporto sul flusso di lavoro del franchising',
        'Franchise Sales Pipeline Report': 'Rapporto sulla pipeline di vendita in franchising',
        'Franchise Employees Report': 'Rapporto sui dipendenti in franchising',
        'Franchise Last Contact Report': 'Rapporto sull\'ultimo contatto del franchising',
        'InXpress User Baseline Report': 'Rapporto di riferimento utente InXpress',
        'Franchise User Report': 'Rapporto utente in franchising',
        'Sales Summary Report': 'Rapporto riepilogativo sulle vendite',
        ACTIVITY_MATRIX: 'Matrice di attività',
        'ACTIVITY_TYPE': 'Tipo di attività',
        'Service Reports': 'Rapporti di servizio',
        'Project Reports': 'Rapporti di progetto',
        'CRM Activity by Account': 'Attività CRM per account',
        'Activity by Franchise': 'Attività di franchising',
        'Franchise Activity Report': 'Rapporto sull\'attività del franchising',
        'Issue Reports': 'Rapporti sui problemi',
        'Customer Service and Sales Rep Listing': 'Elenco del servizio clienti e dei rappresentanti di vendita',
        GO: 'Andare',
        'CRM Account Details': 'Dettagli dell\'account CRM',
        'Activity by Coach': 'Attività dell\'allenatore',
        'Franchise Conversion Report': 'Rapporto sulla conversione del franchising',
        'Task Report': 'Rapporto sulle attività',
        'Email Report': 'Rapporto via e-mail',
        'SMS Report': 'Rapporto SMS',
        'Orthotic Report': 'Rapporto ortotico',
        'Greenlight Export': 'Esportazione verde',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'Conti',
            OPPORTUNITIES: 'Opportunità'
        },
        'Count': 'Contare',
        'Grand Total': 'Somma totale',
        ACTIVITIES_BY_TYPE: 'Attività per tipo',
        ACTIVITIES_BY_SALES_REP: 'Attività del rappresentante vendite',
        ALL_CONVERSIONS_BY_STATUS: 'Tutte le conversioni per stato',
        CUSTOMERS_BY_SOURCE: 'Clienti per fonte',
        DEALS_BY_SALES_PER: 'Pipeline da parte del rappresentante delle vendite',
        CONVERSION_BY_SALES_REP: 'Conversione per rappresentante vendite',
        CONVERSION_MATRIX: 'Matrice di conversione',
        DEALS_BY_STAGES: 'Offerte per fasi',
        TOTAL_CALLS_BY_SALES_REP: 'Chiamate totali per rappresentante vendite',
        TOTAL_CALL_DURATION_BY_SALES_REP: 'Durata totale della chiamata per rappresentante di vendita',
        EXCEL: 'Eccellere',
        MATRIX_BY_STATUS: 'Matrix per stato',
        MATRIX_BY_SOURCE: 'Matrix per fonte',
        'Status': 'Stato',
        'Source': 'Fonte',
        '5 Ways Report': 'Rapporto in 5 modi',
        'Forecast Report': 'Rapporto di previsione',
        CASES_BY_STATUS: 'Casi per stato',
        CASES_BY_PRIORITY: 'Casi per priorità',
        PROJECTS_BY_STATUS: 'Progetti per status',
        PROJECTS_BY_TYPE: 'Progetti per tipo',
        ISSUES_BY_STATUS: 'Problemi per stato',
        ISSUES_BY_PRIORITY: 'Problemi per priorità',
        SUPPORT_MATRIX: 'Matrix di supporto',
        CUSTOMER_BY_CASES: 'Clienti per casi',
        PROJECT_MATRIX: 'Matrice del progetto',
        ISSUE_MATRIX: 'Matrice dei problemi',
        SUB_TOTAL: 'Totale parziale',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'Assegnato a',
            'Total count': 'Conteggio totale',
            'Company': 'Azienda',
            'Assigned To': 'Assegnato a',
        },
        WORKFLOW_SELECT: 'Seleziona Flusso di lavoro',
        COUPONS_NAME: 'Seleziona Buoni',
        PRODUCT_SELECT: 'Seleziona prodotto',
        AVAILABLE_REPORTS: 'Rapporti disponibili',
        WORKFLOW_STAGES: 'Fasi',
        CHART_TYPE: 'Tipo di grafico',
        SHOW: 'Spettacolo',
        VALUE: 'Valore',
        COUNT: 'Contare',
        DEALS_BY_SALES_REP: 'Pipeline da parte del rappresentante delle vendite',
        PIPELINE: 'Tubatura',
    },
    FILTER: {
        NAME_REQUIRED: 'Nome filtro (richiesto)',
        REMOVE_CONDITION: 'Rimuovi condizione',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'Criteri per gli oggetti associati',
        //not found in doc
        NEXT_BUTTON_TEXT: 'Prossimo',
        PREVIOUS_BUTTON_TEXT: 'Precedente',
        AND: 'E',
        OR: 'O',
        ADD_ANOTHER: 'AAggiungi un altro',
        ADD_CONDITION: 'Aggiungi condizione',
        COPY_FILTER: 'Copia di ',
        FROM_DATE: 'Dalla data',
        TO_DATE: 'Ad oggi',

        CONTAINS: 'Contiene',
        DOES_NOT_CONTAINS: 'Non contiene',
        IS_EQUAL_TO: 'È uguale a',
        IS_NOT_EQUAL_TO: 'Non è uguale a',
        IS_EMPTY: 'È vuoto',
        IS_NOT_EMPTY: 'Non è vuoto',

        IS_GREATER_THAN: 'È più grande di',
        IS_GREATER_THAN_OR_EQUAL_TO: 'È più grande di O uguale a',
        IS_LESS_THAN: 'È meno di',
        IS_LESS_THAN_OR_EQUAL_TO: 'È inferiore o uguale a',
        'Contacts': 'Contatti',
        'Contacts without Groups': 'Contatti senza gruppi',
        'My Contacts': 'I miei contatti',
        'Contacts without Account': 'Contatti senza account',
        'Todays Contacts': 'Contatti di oggi',
        'Contacts without Accounts': 'Contatti senza account',
        'All records': 'Tutti i record',
        'Added this Month': 'Aggiunto questo mese',
        'Added this Week': 'Aggiunto questa settimana',
        'My Opportunities': 'Le mie opportunità',
        'Opportunities': 'Opportunità',
        'Accounts': 'Conti',
        'Accounts without Contact': 'Conti senza contatto',
        'Accounts without Groups': 'Conti senza gruppi',
        'My Accounts': 'I miei conti',
        'Todays Accounts': 'Conti di oggi',
        'Cases': 'Casi',
        'Projects': 'Progetti',
        'Issues': 'Problemi',
        'Accounts without Contacts': 'Conti senza contatti',
    },
    SOURCE: {
        SOURCE_NAME: 'Nome della fonte',
        PRIMARY_SOURCE:'Fonte primaria',
        SECONDARY_SOURCE:'Fonte secondaria',
    },
    INXPRESS_REPORT: {
        COUNTRY_SELECT: 'Seleziona il paese',
        FRANCHISE_SELECT: 'Seleziona Franchising',
        WORKFLOW_SELECT: 'Seleziona Flusso di lavoro',
        START_DATE: 'Data d\'inizio',
        NO_RECORDS_FOUND: 'Nessun record trovato.',
        'Account': 'Account',
        // 'Company Name': 'Nome della ditta',
        'Sales Rep': 'Rappresentante delle vendite',
        'Workflow': 'Flusso di lavoro',
        'Status': 'Stato',
        REGION_SELECT: 'Seleziona Regione'
    },
    KANBAN_REPORT: {
        WORKFLOW: 'Flusso di lavoro',
        USER: 'Utente',
        DURATION: 'Durata',
        SORT_BY: 'Ordina per',
        REPORT_BY: 'Segnala da',
        'Forecast amount': 'Importo previsto',
        'Close date': 'Data di chiusura',
        'Sales rep': 'Rappresentante delle vendite',
        'Status': 'Stato',
        'Convert': 'Convertire',
        'Edit': 'Modificare',
        'Delete': 'Eliminare',
        'opportunities': 'Opportunità',
        'Probability': 'Probabilità',
    },
    APP: {
        CURRENT: 'Utilizzerei la nuova versione',
        LEGACY: 'Utilizzerei la versione legacy',
        HEADING: 'Scegli una versione di Soffron',
        SUB_TITLE: 'La tua organizzazione ha accesso alla nostra versione precedente. Desideri utilizzarlo al posto della nuova versione?'
    },
    OTHER_VIEW: {
        SUBSCRIBE_LABEL: 'sottoscrivi'
    },
    MY_INTEGRATION: {
        CONNECTING: 'Connessione',
        DISCONNECTING: 'Disconnessione',
        SYNC_EMAIL: 'Sincronizzare le e-mail',
        SYNC_CONTACT: 'Sincronizzare i contatti',
        SYNC_CALENDAR: 'Sincronizza calendario',
        APPEND_COMPANY: 'Aggiungi il nome dell\'azienda',
        APPEND_CONTACT: 'Aggiungi il nome del contatto',
        CALENDAR: 'Calendario',
        SERVICE: 'Servizio',
        INTEGRATION_HEADER: 'Integrazione di posta elettronica e calendario',
        PHONE_INTEGRATION: 'Integrazione del telefono',
        MY_INTEGRATIONS: 'Integrazioni',
        CONTACT_FILTER: 'Filtro contatti',
        RINGCENTRAL_HEADER: 'RingCentral',
        SMS_INTEGRATION: 'Integrazione SMS',
        QUICKBOOKS: 'QuickBooks',
        XERO: 'Xero',
        INTEGRATE: 'Integrare',
        SELECT_INTEGRATION_ITEMS: 'Seleziona l\'elemento da integrare',
        INVOICES: 'Fatture',
        PAYMENTS: 'Pagamenti',
        MANNUALLY: 'Manualmente (CRM > Xero)',
        AUTOMATICALLY: 'Automaticamente (Xero > CRM)',
        ACCOUNTING_INTEGRATION_LABEL: 'Integrare con',
        ACCOUNTING_INTEGRATION: 'Integrazione contabile',
        QUICKBOOKS_INTEGRATION: 'Integrazione QuickBooks',
        PAYMENT_INTEGRATION: 'Integrazione dei pagamenti',
        EVENTBRITE_INTEGRATION: 'Integrazione Eventbrite',
        SELECT_A: 'Seleziona un',
        CRM_CONTACT_FILTER_SELECT: 'Seleziona un filtro contatti CRM',
        CONTACT_GROUP_NOT_FIND: 'Impossibile trovare il gruppo di contatti. Fare clic',
        HERE: 'qui',
        TO_TRY_AGAIN: 'per riprovare.',
        CONTACT_FILTER_NOT_FIND: 'Impossibile trovare alcun filtro di contatto.',
        SEND_SALES_EMAILS_USING: 'Invia e-mail di vendita utilizzando',
        SYNCHRONIZE: 'Sincronizzare',
        QB_MANNUALLY: 'CRM > QuickBooks (manualmente)',
        QB_AUTOMATICALLY: 'QuickBooks > CRM (automaticamente)',
        REFUNDS: 'Rimborsi'
    },
    PROFILE_DETAILS: {
        PROFILE_DETAILS_LABEL: 'Profilo',
        PERSONAL_INFORMATION: 'Informazione personale',
        USERNAME_LABEL: 'Questo verrà utilizzato come nome utente.',
        CHANGE_PASSWORD: 'Cambiare la password',
        MANAGE_NOTIFICATIONS: 'Gestisci notifiche',
        EMAIL_SIGNATURE: 'Firma e-mail',
        TASKS: 'Compiti',
        APPOINTMENTS: 'Appuntamenti',
        TASK_EMAIL: 'E-mail dell\'attività',
        TASK_POPUP: 'Pop-up attività',
        APPOINTMENT_EMAIL: 'E-mail per appuntamenti',
        APPOINTMENT_POPUP: 'Appuntamenti pop-up',
        ON: 'Su',
        OFF: 'Spento',
        CHANGE_PHOTO: 'Cambia foto',
        NEW_PASSWORD: 'Nuova password',
        CONFIRM_PASSWORD: 'Conferma la nuova password',
        NAME_REQUIRED: 'Nome (obbligatorio)',
        EMAIL_REQUIRED: 'Email (obbligatorio)',
        PHONE: 'Telefono',
        TIME_ZONE_REQUIRED: 'Fuso orario (richiesto)',
        DATE_FORMAT: 'Formato data',
        LANGUAGE: 'Lingua',
        USER_COLOR: 'Colore utente'
    },
    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'Tutto',
        USER: 'Utente',
        SYSTEM: 'Sistema',
        MY: 'Mio',
        ATTACHMENT_INFO: 'Informazioni sull\'allegato'
    },
    DOCUSIGN: {
        CONNECT_BUTTON: 'Collegare',
        SAVE_BUTTON: 'Salva',
        CANCEL_BUTTON: 'Annulla',
        USERNAME: 'Nome utente (richiesto)',
        PASSWORD: 'Password (richiesta)',
        INTREGATOR_KEY: 'Chiave Integratore (richiesta)',
        SELECT_TEMPLATE: 'Seleziona modello',
        SEND: 'Inviare',
        DISCONNECT_BUTTON: 'Disconnetti',
        DOCUSIGN_SETUP_HEADER: 'Integrazione di DocuSign',
        DOCUSIGN_CONNECT_TITLE: 'Connettiti al tuo account DocuSign',
        ADD_NOTE: 'Aggiungi un commento (facoltativo)',
    },
    TWILIO: {
        CALL: 'Chiamata',
        VERIFY: 'Verificare',
        DISCONNECT: 'Disconnetti',
        SHOW_SCRIPTS: 'Mostra script',
        HIDE_SCRIPTS: 'Nascondi script',
        CALL_SETUP: 'Configurazione chiamata',
        CALL_SCRIPT: 'Script di chiamata',
        CALL_SCRIPT_COPY_TAB_LABEL: 'Copia script di chiamata',
        COPY_OF: 'Copia di',
        ADD_NUMBERS: 'Aggiungi i tuoi numeri di telefono in uscita',
        OUTBOUND_PHONE_NUMBERS: 'Numeri di telefono in uscita',
        ADD_ANOTHER_NUMBER: 'Aggiungi un altro numero',
        CALL_RECORDING: 'Registrazione delle chiamate',
        PHONE_CREDITS: 'I tuoi crediti telefonici',
        PURCHASE_CREDITS_YOU_HAVE: 'Hai',
        PURCHASE_CREDITS_MINUTES_LEFT: 'minuti rimasti.',
        PURCHASE_CREDITS_MORE: 'Acquista più crediti.',
        CALLING: 'Chiamando',
        RECORD_ALL_CALL: 'Registra tutte le chiamate',
        VERIFIED: 'Verificato',
        CLICK_TO_CALL: 'Sono pronto a rispondere alla chiamata',
        SCRIPTS: 'Script',
        VOICE_MAILS: 'VM',
        END_CALL: 'Fine chiamata',
        ADDITIONAL_INFO: 'Mostra informazioni aggiuntive',
        ADDITIONAL_INFO_TITLE: 'Informazioni aggiuntive',
        SELECT_FIELD: 'Seleziona campi (massimo 3 campi)'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'Nome script (richiesto)',
        ADD_DETAILS: 'Dettagli script (richiesto)',
        WORKFLOW_NAME: 'Nome del flusso di lavoro',
        CALL_SCRIPTS_EDIT_TAB_LABEL: 'Modifica script di chiamata',
    },
    TWILIO_SMS: {
        ZIP_CODE: 'Cap',
        AREA_CODE: 'Prefisso',
        ADD_TO_ACCOUNT: 'Aggiungere',
        SMS_CREDITS: 'I tuoi crediti SMS',
        SMS_CREDITS_LEFT: 'Crediti SMS rimasti.',
        SEND: 'Inviare',
        NO_NUMBER_PURCHASED: 'Non hai ancora nessun numero di telefono. Utilizza l\'opzione Trova per acquistare numeri di telefono. Dopo l\'acquisto potrai inviare messaggi utilizzando i numeri.',
        FIND_PHONE_NUMBER: 'Trova i numeri di telefono in base al codice postale o al prefisso telefonico',
        FIND_PHONE_NUMBER_BY_AREA_CODE: 'Trova i numeri di telefono per prefisso',
        FIND_TOLL_FREE_NUMBERS: 'Trova numeri verdi',
        SEND_SMS: 'Invia un messaggio di testo',
        YOUR_MESSAGE: 'Il tuo messaggio',
        OR: 'O',
        PHONE_NUMBER: 'Numero',
        PHONE: 'Telefono',
        AVAILABLE_TO: 'Disponibile a',
        USER_NAME: 'Nome utente',
        USED_BY: 'Usato da',
        UNIT_NAME: 'Nome dell\'unità',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: 'Collegare',
        LABEL: 'Connect to your QuickBooks Online accountConnettiti al tuo account QuickBooks Online'
    },
    PAYMENT: {
        CONNECT_BUTTON: 'Collegare',
        DISCONNECT_BUTTON: 'Disconnetti',
        LABEL: 'Imposta i pagamenti utilizzando Stripe'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'Tutti i layout',
        LEFT_SIDEBAR_LAYOUT: 'Layout della barra laterale sinistra',
        RIGHT_SIDEBAR_LAYOUT: 'Layout della barra laterale destra',
        HORIZONTAL_LAYOUT: 'Disposizione orizzontale',
        MULTIPLE_COLUMN_LAYOUT: 'Layout a colonne multiple'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: 'Collegare',
        SELECT_ORGANISATIONS: 'Sincronizza gli eventi di questa organizzazione',
        EVENTBRITE_CONNECT_TITLE: 'Connettiti al tuo account Eventbrite',
        SELECT: 'Seleziona',
        EVENTBRITE_TOGGLE_TITLE: 'Durante la sincronizzazione, aggiungi nuovi contatti se non trovati nel CRM',
        EVENTBRITE_MAP_FIELDS: 'Campi della mappa',
        CRM_FIELDS_TITLE: 'Campi di contatto CRM',
        EVENTBRITE_FIELDS_TITLE: 'Campi Eventbrite',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'Calendario/Attività',
        DASHBOARD: 'Pannello di controllo',
        REPORTS: 'Rapporti',
        'TEMPLATE': 'Modelli',
        'SALES EMAIL TEMPLATES': 'Modelli di e-mail di vendita',
        'MARKETING EMAIL TEMPLATES':'Modelli di e-mail di marketing',
        "CALL SCRIPTS":"Script di chiamata",
        "AGREEMENT TEMPLATES":"Script di chiamata",
        SOCIAL_MARKETING: 'Social marketing',
        WEBSITE_TRACKING: 'Analisi del sito web',
        SOLUTIONS: 'Soluzioni',
        CONTACTS: 'Contatti',
        ACCOUNTS: 'Conti',
        OPPORTUNITIES: 'Opportunità',
        PRODUCTS: 'Prodotti',
        CASES: 'Casi',
        PROJECTS: 'Progetti',
        ISSUES: 'Problemi',
        ONBOARDING: 'Onboarding',
        UNITS: 'Unità',
        ASSESTS: 'Biblioteca',
        OPERATIONS_ACTIVITIES: 'Attività',
        OPERATIONS_CONVERSIONS: 'Conversioni',
        OPERATIONS_CAMPAIGNS: 'Campagne',
        OPERATIONS_REPORTS: 'Rapporti',
        LEAD_CAPTURE: 'Cattura del piombo',
        EMAIL_MARKETING: 'Marketing via email',
        'CALENDAR/TASKS': 'Calendario/Attività',
        'SALES REPORTS': 'Rapporti',
        'SALES TEMPLATES': 'Modelli di vendita',
        'SOCIAL MARKETING': 'Social marketing',
        'ONLINE REPUTATION': 'Reputazione online',
        DASHBOARDS: 'Cruscotti',
        'SALES LEADS': 'Lead di vendita',
        'USEFUL LINKS': 'link utili',
        NEWS: 'Notizia',
        LIBRARY: 'Biblioteca',
        COMMUNITY: 'Comunità',
        TRAINING: 'Formazione',
        SCROLL_TO_RIGHT: 'Scorri verso destra',
        SCROLL_TO_LEFT: 'Scorri verso sinistra',
        Royalties: 'Diritti d\'autore',
        INBOX: 'Inbox',
    },
    NO_RECORD: {
        'Solutions': 'Soluzioni',
        'Appointments': 'Appuntamenti',
        'Tasks': 'Compiti',
        'Groups': 'Gruppi',
        'Notes': 'Appunti',
        'Attachments': 'Allegati',
        'Marketing-templates': 'Modelli di marketing',
        'Sales-templates': 'Modelli di vendita',
        'Web-forms': 'Moduli Web',
        'Products': 'Prodotti',
        'Broadcast-campaigns': 'Campagne radiotelevisive',
        'Ab-campaigns': 'Campagne Ab',
        'Automation-designer': 'Progettista di automazione',
        'Projects': 'Progetti',
        'Invoices': 'Fatture',
        'Iouch-campaigns': 'Campagne touch',
        'Events': 'Eventi',
        'Sms-templates': 'Modelli SMS',
        'Emails': 'E-mail',
        'Relationships': 'Relazioni',
        'Templates': 'Modelli',
        'Cases': 'Casi',
        'Contacts': 'Contatti',
        'Accounts': 'Conti',
        'Opportunities': 'Opportunità',
        'Issues': 'Problemi',
        'Activities': 'Attività',
        'Conversions': 'Conversioni',
        'Salesleads': 'Lead di vendita',
        'Source': 'Fonte',
        'Users': 'Utenti',
        'Securitylevel': 'Livelli di sicurezza',
        'Workflows': 'Flussi di lavoro',
        'Relations': 'Relazioni',
        'Notetypes': 'Tipi di nota',
        'Appointmenttypes': 'Tipi di appuntamento',
        'Campaigntypes': 'Tipi di campagna',
        'Emailintegration': 'Alias di posta elettronica',
        'Library': 'Biblioteca',
        'Recyclebin': 'Conti',
        'Sms': 'sms',
        'Assignmentrules': 'Regole di assegnazione',
        'Units': 'Unità',
        'Orderitems': 'Ordina articoli',
        'Landing-pages-froala': 'Pagine di destinazione',
        'Claims': 'Affermazioni',
        'Payments': 'Pagamenti',
    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'Impostazione programma rapporti',
        DAY_OF_THE_MONTH: 'giorno del mese',
        DAY_OF_THE_WEEK: 'giorno della settimana',
        REPEAT_TYPE: 'Tipo di ripetizione (Obbligatorio)',
        REPEAT_ON: 'Ripeti il',
        REPEAT_BY: 'Ripeti di',
        START_DATE: 'Data di inizio (Obbligatoria)',
        END_DATE: 'Data di fine',
        REPEAT_EVERY: 'Ripeti ogni (Obbligatorio)',
        SEND_TO: 'Invia a (Obbligatorio)',
        SCHEDULE: 'Programma',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': 'Nome',
            'Status': 'Stato',
            'Created By': 'Creato da',
            'Created On': 'Creato il',
            'Updated By': 'Aggiornato da',
            'Updated On': 'Aggiornato il'
        },
        APPOINTMENT: {

            'Subject': 'Soggetto',
            'Company': 'Azienda',
            'Status': 'Stato',
            'Contact': 'Contatto',
            'Contact Name': 'Nome del contatto',
            'Start date': 'Data d\'inizio',
            'End date': 'Data di fine',
            'Assign to': 'Assegnato a',
            'Visible to': 'Visibile a',
            'Outlook Sync': 'Sincronizzazione di Outlook',
            'Google Sync': 'Google Sincronizzazione',
            'Text': 'Testo',
            'Creator': 'Creatore',
            'Created on': 'Creato il',
            'Updated by': 'Aggiornato da',
            'Updated on': 'Aggiornato il'
        },
        TASKS: {
            'Task Name': 'Nome dell\'attività',
            'Company': 'Azienda',
            'Status': 'Stato',
            'Previous Task': 'Compito precedente',
            'Complete Date': 'Data completa',
            'Owner': 'Proprietario',
            'Due By': 'Dovuto a',
            'Contact Name': 'Nome del contatto',
            'Description': 'Descrizione',
            'Updated on': 'Aggiornato il',
            'Contact': 'Contatto',
            'Start Time': 'Ora di inizio',
            'End Time': 'Tempo scaduto',
            'Item': 'Articolo',
            'Billable': 'Fatturabile'
        },
        BROADCAST: {
            'Broadcast Name': 'Nome della trasmissione',
            'Status': 'Stato',
            'Run Date': 'Data di esecuzione',
            'Owner': 'Proprietario',
            'Created On': 'Creato il',
            'Updated On': 'Aggiornato il',
            'Target': 'Bersaglio',
            'From Email': 'Dall\'email',
            'From Name': 'Dal nome',
            'Updated By': 'Aggiornato da',
            'Created By': 'Creato da',
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'Nome della campagna',
            'Created By': 'Creato da',
            'Created On': 'Creato il',
            'Run Date': 'Data di esecuzione',
            'Status': 'Stato',
            'Target Filter': 'Filtro obiettivo',
            'Updated By': 'Aggiornato da',
            'Updated On': 'Aggiornato il',
        },
        SALES_TEMPLATE: {
            'Name': 'Nome',
            'Category': 'Categoria',
            'Subject': 'Oggetto',
            'Status': 'Stato',
            'Owner': 'Proprietario',
            'Created On': 'Creato il',
            'Updated By': 'Aggiornato da',
            'Updated On': 'Aggiornato il',
        },
        MARKETING_TEMPLATE: {
            'Name': 'Nome',
            'Category': 'Categoria',
            'Subject': 'Oggetto',
            'Status': 'Stato',
            'Owner': 'Proprietario',
            'Created On': 'Creato il',
            'Updated By': 'Aggiornato da',
            'Updated On': 'Aggiornato il',
        },
        WEB_FORM: {
            'Name': 'Nome',
            'Table Name': 'Nome della tabella',
            'Group': 'Gruppo',
            'Source': 'Fonte',
            'Status': 'Stato',
            'Assign To': 'Assegnato a',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'Nome della campagna',
            'Status': 'Stato',
            'Owner': 'Proprietario',
            'Target': 'Obiettivo',
            'From email': 'Dall\'email',
            'Created On': 'Creato il',
            'Created By': 'Creato da',
            'From name': 'Dal nome',
            'Updated On': 'Aggiornato il',
            'Updated By': 'Aggiornato da',
            'Resend All': 'Rispedisci tutto',
            'Resend After': 'Rispedisci dopo',
        },
        ACCOUNTS: {
            'Status': 'Stato',
        },
        CONTACTS: {
            'Status': 'Stato',
        },
        OPPORTUNITIES: {
            'Status': 'Stato',
        },
        PROJECTS: {
            'Status': 'Stato',
        },
        ISSUES: {
            'Status': 'Stato',
        },
        CASES: {
            'Status': 'Stato',
        },
        SOLUTIONS: {
            'Status': 'Stato',
        },
        SALES_LEADS: {
            'Status': 'Stato',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': 'Appuntamento',
        'Dialed': 'Chiamato',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'Nota',
        'Spoke': 'Parlato',
        'Task': 'Compito',
        'Transferred': 'Trasferito',
    },
    VIEW_TOGGLE: {
        'LIST': 'Elenco',
        'COLUMN': 'Colonne',
        'LIST_TITLE': 'Passa alla visualizzazione elenco',
        'COLUMN_TITLE': 'Passa alla visualizzazione a colonne',
        'TIMELINE': 'Sequenza temporale',
        'TIMELINE_TITLE': 'Passa alla visualizzazione sequenza temporale',
        'DETAILS': 'Dettagli',
        'DETAILS_TITLE': 'Passa alla visualizzazione dettagli',
    },
    EMAIL_MARKRTING_REPORT: {
        'REPORT_HEADER': 'Rapporto sull\'e-mail marketing',
        'MY_CAMPAIGNS': 'Le mie campagne',
        'MY_LOCATION_CAMPAIGNS': 'Le campagne della mia unità',
        'RECENT_CAMPAIGNS': 'Campagne recenti',
        'BROADCAST_REPORT': 'Rapporto sulla campagna di trasmissione',
        'TOUCH_REPORT': 'Tocca Rapporto campagna'
    },
    DOCUMENT_PUSH: {
        'DIALOG_TITLE': 'Spingere a',
        'DOCUMENT_NOT_FOUND': 'Nessun documento trovato.'
    },
    OPERATIONS_REPORT: {
        'REQUEST_NEW_REPORT': 'Richiedi un nuovo rapporto'
    },
    OPERATIONS_CAMPAIGNS: {
        'REPORT_HEADER': 'Campagne e-mail',
        'BROADCAST_REPORT': 'Campagne trasmesse',
        'TOUCH_REPORT': 'Campagne touch'
    },
    OPERATIONS_RECENT_CAMPAIGNS: {
        'REPORT_HEADER': 'Campagne e-mail recenti',
        'BROADCAST_REPORT': 'Campagne trasmesse',
        'TOUCH_REPORT': 'Campagne touch'
    },
    TASKS_REPORT: {
        'TASKS_REPORT_LABEL': 'Rapporto sulle attività'
    },
    EMAIL_REPORT: {
        'EMAIL_REPORT_LABEL': 'Rapporto via e-mail',
        'ERROR_REASON': 'Motivo'
    },
    SMS_REPORT: {
        'SMS_REPORT_LABEL': 'Rapporto SMS'
    },
    ORTHOTIC_REPORT: {
        'ORTHOTIC_REPORT_LABEL': 'Rapporto ortotico'
    },
    BATCH_SMS: {
        'MESSAGE': 'Il tuo messaggio',
        'SEND': 'Inviare',
        'CHARS': 'caratteri',
        'SCHEDULE': 'Programma',
        'SCHEDULE_SMS': 'Pianifica messaggio di testo',
        'SEND_LATER': 'Invia più tardi'
    },
    SETTING_MENU: {
        'MARKETING': 'Marketing',
        'SECURITY': 'Utenti e sicurezza',
        'DATA': 'Dati',
        'CUSTOMIZATION': 'Personalizzazione',
        'INTEGRATION': 'Integrazione',
        'COMPANY': 'Azienda',
        'WORKFLOW_RULES': 'Flussi di lavoro e regole',
        'LISTS': 'Elenchi',
        'FRANCHISE': 'Franchising',
        'COMPANY_SCHEDULER': 'Pianificatore aziendale'
    },
    MARKETING_SETTING: {
        'SOURCE': 'Fonti',
        'LIBRARY': 'Libreria',
        'LEAD_SCORE': 'Punteggi lead',
        'SCORES': 'Punteggi'
    },
    SECURITY_SETTING: {
        'USERS': 'Utenti',
        'SECURITY_LEVELS': 'Livelli di sicurezza'
    },
    DATA_SETTING: {
        'EXPORT': 'Esporta',
        'RECYCLE_BIN': 'Cestino',
        'FIND_DUPLICATES': 'Trova duplicati'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'Cambia logo',
        'DATA_BASE': 'Database',
        'WORKFLOWS': 'Flussi di lavoro',
        'RELATIONS': 'Relazioni',
        'AUTO_FILL_VALUES': 'Valori di auto-compilazione',
        'NOTE_TYPES': 'Tipi di attività e note',
        'CAMPAIGN_TYPES': 'Tipi di campagna',
        'CORPORATE_CURRENCY': 'Valuta aziendale',
        'HUB_SETTING': 'HUB',
        'COMPANY_PROFILE': 'Profilo aziendale',
        'REVIEW_WEBSITE': 'Sondaggi',
        'WHITE_LABEL': 'Etichetta bianca',
        'API_KEY': 'Chiave API',
        'RE_API_KEY': 'Rigenera chiave API',
        'GENERATED_ON': 'Generato il',
        'UPDATED_ON': 'Aggiornato il',
        'MENU': 'Menu',
        'DESKTOP_MENU': 'Menu desktop',
        'MOBILE_MENU': 'Menu mobile',
        'PHONE_NUMBERS': 'Numeri di telefono',
        'DESIGN_MENUS': 'Menus di design',
        'MENU_NAME': 'Nome del menu',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook (Desktop)',
        'GENERATE_API': 'Genera API',
        'EMAIL_INTEGRATION': 'Integrazione e-mail'
    },
    SETTINGS: {
        ADDED: 'aggiunto',
        EDIT: 'aggiornato',
        SUCCESSFULL: 'Con successo.',
        'SOURCE': 'Fonte',
        'NOTETYPES': 'Tipi di attività e note',
        'CAMPAIGNTYPES': 'Tipo di campagna',
        'CORPORATECURRENCY': 'Valuta aziendale',
        'RELATIONS': 'Relazione',
        'LIBRARY': 'Libreria',
        'USERS': 'Utenti',
        'SECURITYLEVEL': 'Livelli di sicurezza',
        'WORKFLOWS': 'Flussi di lavoro',
        'EMAILINTEGRATION': 'Alias e-mail',
        'RECYCLEBIN': 'Cestino',
        'ASSIGNMENTRULES': 'Regole di assegnazione',
        'AVAILABILITY': 'Disponibilità',
        'APPOINTMENT_TYPES': 'Tipi di appuntamento',
        'LOCATION': 'Posizione',
        'TASKINFO': 'Attività',
        'INTAKE_FORM': 'Modulo di ammissione',
        'SCHEDULER_LINK': 'Link al programmatore',
        'ROLLUP': 'Regole di roll-up',
        'SUBSCRIPTION': 'Abbonamento',
        'UPGRADE': 'Aggiorna abbonamento',
        'BUYPRODUCT': 'Acquista prodotti e formazione',
        'BUYPROSERVICE': 'Acquista servizi professionali',
        'BILLING': 'Dettagli di fatturazione e fattura',
        'CANCELSUB': 'Annulla abbonamento',
        'SUBDETAIL': 'Dettagli abbonamento',
        'PURCHESEMAILCREDIT': 'Acquista crediti e-mail',
        'PURCHESPHONECREDIT': 'Acquista crediti telefonici',
        'PURCHESSMSCREDIT': 'Acquista crediti SMS',
        'PURCHESCARDCREDIT': 'Acquista crediti per la scansione delle carte',
        'PURCHESCONTACTCREDIT': 'Acquista più contatti',
        'PURCHESTRANINGCREDIT': 'Acquista formazione',
        'PURCHESMERKETINGSERVICE': 'Acquista servizi di marketing',
        'PURCHESPROFESSONALSERVICE': 'Acquista servizi professionali',
        'SETUP': 'Impostazioni personali',
        'APPOINTMENTTYPES': 'Tipi di appuntamento',
        SHOW_LOGO: 'Mostra logo',
        SHOW_SIGNOUT_LINK: 'Mostra il link di disconnessione',
        THEME_COLOR: 'Colore del tema',
        LOGO_COLOR: 'Sfondo del logo',
        REPORTED_SALES_SOURCE: 'Fonte',
        AUDIT_QUESTIONS: "Lista di controllo dell'audit",
        LMS_USERS: 'Utenti dell\'LMS',
        AUDIT_CHECKLISTS:'Liste di controllo di audit',
        WEBHOOKS: 'Webhooks',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: 'Seleziona una valuta aziendale'
    },
    UNSUBSCRIBE: {
        EMAILS: 'Email',
        TEXTS: 'Messaggi',
        BOTHS: 'Entrambi'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'Nome HUB',
        USEFUL_LINK: 'Link utili',
        CASES: 'Casi',
        SETUP_NAME: 'Impostazioni HUB',
        NEWS: 'Notizie',
        LIBRARY: 'Libreria',
        COMMUNITY: 'Comunità',
        TRAINING: 'Formazione',
        HYPER_LINK: 'iperlink',
        SET: 'Imposta',
        MANNUAL: 'Manuale'
    },
    UNITS: {
        TASK_DETAILS: 'Dettagli attività',
        'Unit': 'Unità',
        'Contact Name': 'Nome del contatto',
        'Done': 'Fatto',
        'Open': 'Aprire',
        'Tasks Name': 'Nome attività',
        'Due By': 'Dovuto a',
        'Status': 'Stato',
        UNIT_DETAILS_LABEL: "Dettagli dell'unità",
        FIRST_NAME: 'Nome (richiesto)',
        LAST_NAME: 'Cognome (richiesto)',
        PASSWORD: 'Password (richiesta)',
        RETYPE_PASSWORD: 'Ridigita Password (richiesto)',
        EMAIL: 'Email (obbligatorio)',
        PHONE: 'Phone (required)Telefono (richiesto)',
        COMPANY: 'Azienda (richiesto)',
        TIME_ZONE_SELECT: 'Fuso orario',
        COUNTRY_SELECT: 'Paese',
        STATE_SELECT: 'Stato',
        FA_EXPIRATION_DATE: 'Data di scadenza della FA',
        PLAN_NAME: 'Nome del piano',
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: 'Imposta la tua prima regola',
        ASSIGNMENT_RULE: 'Regola di assegnazione',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: 'Crea regola di assegnazione',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: 'Modifica regola di assegnazione',
        NAME_REQUIRED: 'Nome (obbligatorio)',
        ASSIGNMENT_RULES: 'Regole di assegnazione',
        STATUS: 'Stato',
        SETUP_RULE: "Regola di assegnazione dell'impostazione",
        USE_RULE: 'Utilizza la regola di assegnazione',
        TABLE_NAME: 'Nome della tabella',
        SELECT_UNIT_WORKFLOW: "selezionare il flusso di lavoro dell'unità",
        WORKFLOW_NAME: 'Nome del flusso di lavoro',
    },
    COUPONS: {
        PREFIX: 'Prefisso',
        'Coupon Name': 'Nome del buono',
        'Discount Value': 'Valore scontato',
        EXPIRATION_DATE: 'Data di scadenza',
        PODUCT_SERVICE: 'Prodotto/Servizio (facoltativo)'

    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'Rapporto sulle richieste dei coupon',
        COUPONS_REPORT: 'Rapporto'
    },
    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'Avvisi e notifiche',
        NOTIFY_ME: 'Avvisami quando...',
        REMIND_ME: 'Ricordamelo prima...',
        NOTIFY_EMAIL_OPEN: 'La mia email è aperta',
        NOTIFY_EMAIL_CLICK: 'La mia email è stata cliccata',
        NOTIFY_TEXT_REPLY: 'Il mio messaggio ha ricevuto ',
        REMIND_UPCOMING_APPOINTMENT: 'risposta',
        REMIND_UPCOMING_TASK: 'Prossimi compitir',
        ADD_NEW_REMINDER: 'Aggiungi un nuovo promemoria',
        REMIND: 'Ricordare',
        DAY_BEFORE: 'giorno/i prima',
        MY_WEB_FORM_SUBMITTTED: 'Il mio modulo web è stato inviato',
        NO_DATE_FIELD: 'Nessun campo data disponibile',
    },
    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'Rapporto sulla pipeline',
        SALES_SUMMARY_REPORT: 'Rapporto riepilogativo sulle vendite'
    },
    EMAIL_INTEGRATION: {
        EMAIL: 'Alias ​​di posta elettronica',
    },
    NEWS: {
        HEADER: 'Notizia',
        STATUS: {
            DRAFT: 'Bozza',
            PUBLISHED: 'Pubblicato'
        },
        EDIT_TAB_LABEL: 'Modifica notizie',
        ADD_TAB_LABEL: 'Aggiungi notizie',
        CATEGORY_REQUIRED: 'Categoria (richiesto)',
        STATUS_REQUIRED: 'Stato (richiesto)',
        TITLE_REQUIRED: 'Titolo (richiesto)',
        SUMMARY_REQUIRED: 'Riepilogo (richiesto)',
        EXTERNAL_LINK_REQUIRED: "Collegamento esterno (richiesto)",
        UPLOAD_IMAGE: "Carica un'immagine",
        EXTERNAL_LINK: 'link esterno',
        ENTER_DETAILS: 'Inserisci i dettagli'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: 'link utili',
        CATEGORY: 'Categoria',
        LINK: 'Collegamento',
        TITLE: 'Titolo',
        NO_RECORD_FOUND: 'Nessun collegamento utile trovato.',
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'Nessun rapporto trovato.',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: 'Seleziona un genitore',
        SELECT_CHILD: 'Seleziona un bambino',
        PARENT_FIELDS: 'Campi oggetto principale',
        CHILD_FIELDS: 'Campi oggetto figlio',
        SELECT_PARENT_FIELD: 'Seleziona un campo principale',
        SELECT_CHILD_FIELD: 'Seleziona un campo figlio',
    },
    WORKFLOW_AUTOMATION: {
        TOUCH_CAMPAIGN_REPORT: 'Tocca Rapporto campagna',
        INTERVAL: 'Dopo',
        NOTE_TYPE: 'Tipo di nota',
        TEXT_REQUIRED: 'Testo della nota (richiesto)',
        TASK_NAME_REQUIRED: "Nome dell'attività (richiesto)",
        OWNER_REQUIRED: 'Proprietario (richiesto)',
        DUE_BY_REQUIRED: 'Scadenza (richiesto)',
        DAY_AFTER_REQUIRED: 'Giorni dopo (richiesto)',
        EMAIL: 'Email',
        POP_UP: 'Apparire',
        MINUTES: 'minuti',
        HOURS: 'ore',
        DAYS: 'giorni',
        WEEKS: 'settimane',
        REMINDER: 'Promemoria',
        GROUP_REQUIRED: 'Seleziona Gruppo (richiesto)',
        ADD_TO_GROUP: 'Aggiungi al gruppo',
        MOVE_TO_GROUP: 'Passa al gruppo',
        REMOVE_FROM_GROUP: 'Rimuovi dal gruppo',
        STATUS_REQUIRED: 'Stato (richiesto)',
        WORKFLOW_REQUIRED: 'Flusso di lavoro (richiesto)',
        CHOOSE_ANOTHER_FIELD: 'Scegli un altro campo',
        CHOOSE: 'Scegliere',
        FIELD_FOR: 'a field for',
        CAMPAIGN_NAME: 'un campo per',
        START: 'Inizio',
        STOP: 'Fermare',
        REPORT: 'Rapporto',
        RESUME: 'Riprendere',
        SELECT_PAGE: 'Seleziona una pagina',
        ENTER_WEB_PAGE_LINK_OPTIONAL: "Inserisci l'URL del collegamento (facoltativo).",
        CLICK_TO_VIEW: 'Clicca qui per vedere',
        CLICK_TO_EDIT: 'Fare clic qui per modificare',
        DRAG_TO_MOVE: 'Trascina per spostare',
        DRAG_TO_CONNECT: 'Trascina per connetterti',
        CLICK_TO_REMOVE: 'Fare clic qui per rimuovere',
        CLICK_TO_SEE_REPORT: 'Clicca qui per vedere il rapporto',
        CLICK_TO_ADD_MSG: 'Fare clic per aggiungere un nuovo messaggio',
        NO: 'No',
        YES: 'SI',
        CLICK_TO_SELECT_USERS: 'Clicca qui per selezionare gli utenti',
        SALES_REP: 'Rappresentante delle vendite',
        USERS: 'Utenti',
        CAMPAIGN_SENDER: 'Mittente della campagna',
        SCORE: 'Punto',
        START_WITH: 'Aggiungi',//'Inizia con'
        DECISIONS: 'Decisioni',
        ACTIONS: 'Azioni',
        LINK_CLICKED: 'Link cliccato?',
        SCORE_REACHED: 'Punteggio raggiunto?',
        TOUCH_CAMPAIGN: 'Tocca Campagna',
        WEB_FORM: 'Modulo Web',
        LANDING_PAGE: 'Pagina di destinazione',
        WEBSITE_VISITED: 'Sito Web visitato',
        CHANGE_GROUP: 'Cambia gruppo',
        ADD_NOTE: 'Aggiungi nota',
        NOTIFY: 'Notificare',
        ADD_TASK: 'Aggiungi attività',
        FIELD_UPDATED: 'Aggiorna campi',
        CHOOSE_TEMPLATE: 'Scegli un modello',
        USE_THIS_TEMPLATE: 'Usa questo modello',
        WAIT: 'Aspettare',
        NAME: "Nome",
        TARGET_GROUPS: 'Gruppo/i target',
        TARGET_FILTER: 'Filtro obiettivo',
        RESEND_AFTER: 'Invia nuovamente dopo',
        START_DATE: "Data d'inizio",
        SUBJECT: 'Soggetto',
        TEXT: 'Testo',
        TASK_NAME: "Nome dell'attività",
        ASSIGN_TO: 'Assegnato a',
        FIELD_LABEL: 'Etichetta del campo',
        FIELD_VALUE: 'Valore del campo',
        LINK: 'Collegamento',
        SEND_ON_ANY_TIME: 'Invia: in qualsiasi momento',
        SEND_ON_BUSINESS_DAY: 'Invia il: solo nei giorni lavorativi',
        CONVERT_TO: 'Convertire in',
        QUALIFIER: 'Qualificatore',
        OBJECT: 'Oggetto',
        NOTIFY_SENDER: 'Notifica al mittente',
        NOTIFY_SALES_REP: 'Avvisare il rappresentante di vendita',
        NOTIFY_SENDER_SALESREP: 'Notifica al mittente, Notifica al rappresentante di vendita',
        NOTIFY_USERS: 'Avvisa gli utenti',
        TEST: 'Test',
        VIEW_AS_WEBPAGE_INFO: "Questa opzione aggiungerà un collegamento nella parte superiore dell'e-mail per aprire l'e-mail come pagina Web	",
        LINK_CLICK_INFORMATION: "Se lasci vuoto questo campo, tutti i link nell'e-mail verranno tracciati.",
        GROUP_NAME: 'Nuovo nome del gruppo.',
        SELECT_USER: 'Assegna a',
        REMIND_BEFORE: 'Ricorda prima',
        INTERVAL_REQUIRED: 'Intervallo (obbligatorio)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verifica un altro indirizzo e-mail',
        'Touch Campaign': 'Campagna Touch',
        'Required Tasks Done': 'Required Tasks Done',
        'Not Converted': 'Not Converted',
        'Link Clicked': '	Link cliccato',
        'Link Clicked?': '	Link cliccato?',
        'Email Opened': 'E-mail aperta',
        'Email Opened?': 'E-mail aperta',
        'Unsubscribed': "Annullata l'iscrizione",
        'Score Reached': 'Punteggio raggiunto',
        //'Send One-off Email': 'Invia un'e-mail una tantum',
        'Send Email': 'Invia e-mail',
        'Change Group': 'Cambia gruppo',
        'Add Note': 'Aggiungi nota',
        'Notify': 'Notificare',
        'Add Task': 'Aggiungi attività',
        'Update Fields': 'Aggiorna campi',
        'Assign': 'Assegnare',
        'Convert': 'Convertire',
        'Start': 'Inizio',
        'Add Touch Message': 'Aggiungi messaggio touch',
        'Web Form': 'Modulo Web',
        'Website Visit': 'Visita al sito web',
        'Landing Page': 'Pagina di destinazione',
        'Wait': 'Aspettare',
        WAIT_FOR_DAYS: 'giorno(i)',
        'Simple email nurturing': 'Semplice nurturing delle e-mail',
        'Capture leads using a web form and nurture': 'Acquisisci lead utilizzando un modulo web e coltiva',
        'Track web page visit and nurture': 'Monitora la visita e il consolidamento della pagina web',
        'Capture leads from a landing page and nurture': 'Acquisisci lead da una landing page e coltivali',
        'Start with tracking website visit': 'Inizia con il monitoraggio delle visite al sito web',

        COPY_OF: 'Copia di',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Nuova campagna automatizzata',
        TARGET_BY: 'Target per',
        'Hours': 'Orario',
        'Hour(s)': 'Ora(e)',
        'day(s)': 'giorno(i)',
        'hour(s)': 'ora(e)',
        ALL_AUTOMATED_CAMPAIGNS: 'Tutte le campagne automatizzate',
        MESSAGE: 'Messaggio',
        SELECT_A_CAMPAIGN: 'Seleziona una campagna',
        ADD_SIGNATURE: 'Aggiungi firma',
        BIRTHDAY_CAMPAIGN: 'Campagna di compleanno',
        'Add Birthday Message': 'Aggiungi messaggio di compleanno',
        'Start with Birthday Campaign': 'Inizia con la campagna di compleanno',
        'SMS Replied': 'SMS Risposta',
        'Send SMS': 'Invia SMS',
        PET_BIRTHDAY_CAMPAIGN: 'Campagna di compleanno per animali domestici',

        'Touch Campaign Automation': 'Automazione delle campagne touch',
        'Birthday Campaign Automation': 'Automazione della campagna di compleanno	',
        'Web Form Automation': 'Automazione dei moduli Web',
        'Landing Page Automation': 'Automazione della pagina di destinazione',
        'Pet Birthday Greetings': 'Auguri di compleanno per animali domestici',


        SEND_SMS: 'Invia SMS',
        SMS_REPLY: 'SMS  risposto?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Rapporto sulla campagna di compleanno',
        START_IMMEDIATELY: 'Subito',
        START_SCHEDULE: 'Programma',
        SCHEDULE_ON_TEXT: 'Inizio programmato il	',
        SCHEDULE_DIALOG_TITLE: "Seleziona la data e l'ora del programma",
        FROM_NUMBER: 'Dal numero',
        REPLY: 'Rispondere',
        NOT_CONVERTED: 'Se non convenuto con in',
        DAYS_AFTER: 'Giorni dopo'
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'Pagina di destinazione',
        LANDING_PAGES: 'Pagine di destinazione',
        NAME_REQUIRED: 'Nome (obbligatorio)',
        URL_REQUIRED: 'URL (richiesto)',
        LANDING_PAGE_FORMS: 'Generatore di moduli',
        EDIT_LANDING_PAGE: 'Modifica pagina di destinazione',
        COPY_LANDING_PAGE: 'Copia la pagina di destinazione'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: 'Trova duplicati',
        SELECT_OBJECT: 'Seleziona oggetto',
        SELECT_FIELD: 'Seleziona campo',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: 'Il mio programmatore',
        JOB_SCHEDULER: 'Pianificatore di lavori',
        APPOINTMENT_INFO: "AInformazioni sull'appuntamento",
        AVAILABILITY:'Disponibilità',
        APPOINTMENT_TYPES:'Tipi di appuntamento',
        MY_AVAILABILITY: 'La mia disponibilità',
        SCHEDULER_LINK: 'Collegamento al programmatore',
        INTAKE_FORM: 'Modulo di assunzione',
        APPOINTMENT_NAME: 'Nome appuntamento (richiesto)',
        LOCATION: 'Posizione',
        TASKS:'Compiti',
        UNIT_CRM:'Unità Crm',
        NOTIFICATION_SETUP:'Configurazione delle notifiche',
        DURATION: 'Durata',
        MIN_ADVANCE_NOTICE: 'Preavviso minimo ',
        DESCRIPTION: 'Descrizione',
        COPY_TO_CLIPBOARD: 'Copia nei miei appunti',
        SCHEDULER_LINK_TITLE: 'Il mio collegamento al programma degli appuntamenti',
        SCHEDULING_PAGE: 'Programmatore di appuntamenti',
        ADD_BUTTON: 'Aggiungi una nuova pianificazione',
        MY_SCHEDULAR_LINK: 'Il mio collegamento alla pianificazione',
        MY_APPOINTMENT_TYPE: 'Il mio tipo di appuntamento',
        APPOINTMENT_TYPE: 'Tipo di appuntamento',
        DEFINE_YOUR_INTAKE_FORM: 'Definisci il tuo modulo di assunzione',
        REQUIRED: 'Necessario',
        ADD_ANOTHER_FIELD: 'Aggiungi un altro campo',
        SUBMIT_BUTTON_LABEL: 'Invia etichetta pulsante',
        CHOOSE_A_STATUS_FOR_LEAD: 'Scegli uno stato per i lead aggiunti dallo scheduler',
        WORKFLOW_STUP_REULES:'Regole di conversione dei lead',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'Definisci il tuo messaggio di ringraziamento',
        THANK_YOU_MESSAGE: 'Messaggio di ringraziamento',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: 'Matrice delle relazioni',
        REPORT_SAVE_LABEL: 'Salva personalizzazione',
        REPORT_NAME_LABEL: 'Nome del rapporto personalizzato',
        REPORT_NAME_TEXTFIELD_LABEL: 'Nome del rapporto',
        ROW_FIELD_LABEL: 'Campo riga',
        COL_FIELD_LABEL: 'Campo colonna',
        DATE_FIELD_LABEL: 'Campo data',
        PDF_LABEL_DURATION: 'Durata',
        RELATION_FIELD_LABEL: 'Relazione',
        SAVE_CUSTOMIZATION_LABEL: 'Salva personalizzazione',
        RUN_BUTTON_LABEL: 'Correre'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'Carica il tuo logo',
        WEBSITE: 'Sito web',
        BUSINESS_NAME: 'Nome commerciale',
        BUSINESS_ADDRESS: 'Recapito di lavoro',
        BUSINESS_PHONE: 'telefono aziendale',
        BUSINESS_EMAIL: 'Email di lavoro',
        CORPORATE_CURRENCY: 'Valuta aziendale'
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: 'Esportare',
        FILE_NAME_REQUIRED: 'Nome file (richiesto)',
        REVIEW_WEBSITE_REQUIRED: 'Recensione sito web (richiesto)',
        PARENT_OBJECT_REQUIRED: 'Nome oggetto principale (richiesto)',
        FILE_TYPE_REQUIRED: 'Tipo di file (richiesto)',
        QUERY_NAME_REQUIRED: 'Nome della query (richiesto)',
        SELECTED_FIELDS_FOR_PARENT: "Campi selezionati per l'elemento principale",
        AVAILABLE_FIELDS_FOR_PARENT: 'Campi disponibili per il genitore',
        CHILD_OBJECT_NAME: "Nome dell'oggetto figlio",
        AVAILABLE_FIELDS_FOR_CHILD: 'Campi disponibili per bambino',
        SELECTED_FIELDS_FOR_CHILD: 'Campi selezionati per bambino',
        EXPORT_BUTTON: 'Esportare',
        DOWNLOAD_BUTTON: 'Download file',
        ADD: 'Aggiungere',
        ADD_ALL: 'Aggiungi tutto',
        REMOVE: 'Rimuovere',
        REMOVE_ALL: 'Rimuovi tutto'
    },
    QUEUE: {
        QUEUE: 'Coda',
        PREVIOUS: 'Precedente in coda',
        NEXT: 'Il prossimo in coda',
        REMOVE: 'Rimuovi dalla coda',
        CLOSE: 'Esci dalla coda',
    },
    SECURITY_LEVEL: {
        ACCESS: 'Accesso',
        DESCRIPTION: 'Descrizione',
        VIEW: 'Visualizzazione',
        PERMISSIONS: 'Autorizzazioni',
        OPERATIONS: 'Operazioni',
        ADMINISTRATION: 'Amministrazione',
        ASSIGNED_MENU: 'Menu assegnato',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: 'Oggetto figlio',
        PARENT_OBJECT_NAME: 'Oggetto principale',
        CHILD_FIELD_NAME: 'Nome campo figlio',
        PARENT_FIELD_NAME: 'Nome del campo principale',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'Rapporto sulla regalità',
        ROYALTY_PERCENT: 'Reale %',
        SHOW: 'Spettacolo',
        ALL_INVOICES: 'Tutte le fatture',
        PAID_INVOICE_ONLY: 'Solo fatture pagate',
        SETUP_TITLE: 'Impostazione delle royalties',
        PAID_INVOICES: 'Fatture pagate',
        USE: 'Utilizzo',
        FIXED: 'Fisso %',
        VARIABLE: '% variabile',
        TOTAL_INVOICES: 'Fatture totali',
        TOTAL_PAYMENTS: 'Pagamenti totali',
        CALCULATE: 'Calcolare',
        WEEKLY: 'settimanalmente',
        MONTHLY: 'Mensile',
        WEEK_TYPE: 'Tipo di settimana',
        MON_TO_FRI: 'Lun - Ven',
        MON_TO_SUN: 'Lun - dom',
        ADD_INVOICES: 'Aggiungi fattura',
        TOTAL_Reported_Sales: 'Vendite totali riportate',
        YEAR: 'Anno',
        MARKETING_FEE: 'Commissione di marketing',
        TECH_FEE: 'Commissione tecnica',
        PERCENT: 'Percentuale',
        FIXED_VALUE: 'Fisso',
        WITH: 'Con commissioni',
        WITH_OUT: "Senza commissioni",
        Reported_Sales_Franchisee: 'Vendite riportate - Affiliato',
        Reported_Sales_Franchisor: 'Vendite riportate - Franchisor',
        Reported_Sales_ThirdParty: 'Vendite segnalate - Terze parti',
        AVAILABLE_FIELDS: 'Campi disponibili',
        SELECTED_FIELDS: 'Campi selezionati',
    },
    POWER_CALLING: {
        NOTE_AND_CALL_SCRIPTS: 'Script di note e chiamate',
        SEND: 'INVIARE',
        LOG_CALL: 'Registra chiamata',
        CALLER_ID: 'ID chiamante',
        END_CALL: 'Fine chiamata',
        MOBILE: 'Mobile',
        PHONE: 'Telefono',
        HOME_PHONE: "Telefono di casa",
        NEXT: 'Prossimo',
        COMPLETE: 'completare',
        OF: 'Di',
        CHANGE_GROUP: 'Modifica',
        POWER_DIALER: 'Combinatore di potenza'
    },
    LEAD_SCORE_SETUP: {
        SCORES: 'Punteggi',
        NO_SCORES_AVAILABLE: 'Non ci sono azioni disponibili.',
        OBJECT: 'Oggetto',
        GROUPS: 'Gruppi',
        WORKFLOW_NAME: 'Nome del flusso di lavoro',
        CONVERTED_FROM: 'Convertito da',
        CONVERTED_TO: 'Convertito in',
        FIELDS_NAME: 'Nome dei campi',
        POINTS: 'Punti',
        EXPIRES_BY: 'Scade entro',
        APPLY_TO_EXISTING_SCORE: '*La data di scadenza verrà applicata retroattivamente ai tuoi punteggi esistenti.',
        GROUP_ALREADY_REMOVE: 'Gruppo già rimosso. Modificare prima di salvare.',
        FIELD_ALREADY_REMOVE: 'Campo già rimosso. Modificare prima di salvare.',
        FIELD_VALUE_ALREADY_REMOVE: 'Valore del campo già rimosso. Modificare prima di salvare.',
        AND_ABOVE: 'e al di sopra',
        LESS_THAN: 'Meno di',
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: 'Regole duplicate',
        DUPLICATE_RULE_HEAD_LABEL: 'Controlla i duplicati utilizzando',
        SELECT_OBJECT_LABEL: 'Seleziona oggetto',
        ACTIVE: 'Attivo',
        DEFAULT: 'Predefinito',
        ONE_FIELD: 'Un campo',
        TWO_FIELD: 'Due campi',
        THREE_FIELD: 'Tre campi',
        FIELD_ONE: 'Campo 1',
        FIELD_TWO: 'Campo 2',
        FIELD_THREE: 'Campo 3',
        SELECT_FIELD_LABEL: 'Seleziona Campo',
        CHECK_DUPLICATES: 'Controlla i duplicati',
        SELECT_RULES: 'Seleziona una regola',
    },
    JOBS: {
        RESCHEDULE: 'Riprogrammare',
        OMW: 'OMW',
        INVOICE: 'Fattura',
        PAY: 'Paga'
    },
    TASK_REPORT:{
        TITLE:'Rapporto sulle attività',
        UNITS:'Unità',
        NO_TASK_FOUND:'Nessun compito trovato',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'Dettagli del rapporto sulle attività',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'Dovrebbero esserci almeno 2 scelte in questa domanda',
        SINGLE_RECORD: 'È consentito spostare solo 1 record alla volta.',
        AUDIT_QUESTIONS: "Lista di controllo dell'audit",
        GIVE_FEEDBACK:'Dare un feedback',
        NEW_QUESTION: 'Nuova domanda',
        TEXT: 'Testo breve',
        COMMENT: 'Testo lungo',
        LIST: 'Scelta singola (elenco a discesa)',
        CHECKBOX: 'Scelta multipla (caselle di controllo)',
        QUESTION: 'Domanda',
        ADD_NEW: 'Aggiungere nuova',
        ADD_NEW_CATEGORY:'Aggiungi una nuova categoria',
        SINGLE_RECORD_EDIT: 'Seleziona 1 record da modificare.',
        MESSAGE_DELETE: 'Sei sicuro di voler eliminare?',
        SELECTED_RECORDS: 'record selezionati?',
        YOUR_ANSWER: "La tua risposta",
        SUBMITTING: 'Invio...',
        SAVE_CATEGORY:'Salva categoria',
        SUBMIT_AUDIT:'Invia controllo',
        SUBMIT: 'Invia',
        SCORE: 'Punto',
        REQUIRED: 'Necessario',
        CHOICE: 'Scelta',
        FIELD_REQUIRED: 'Questo campo è obbligatorio *',
        NEW_CHOICE: 'Nuova scelta',
        FREE_FORM: 'Forma libera',
        SCORE_HELP_TOOLTIP: "Assegna punti o punteggi alle risposte. I punteggi verranno sommati per calcolare il punteggio totale dell'audit",
        SURVEY_LINK: 'Collegamento audit/indagine',
        REQUIRES_PHOTO: 'Richiede foto',
        ENABLE_NOTE: 'Abilita nota',
        ADD_PHOTO: 'Foto',
        UPDATE_PHOTO: 'Aggiornamento',
        NOTE: 'Nota',
        PHOTO_REQUIRED: 'La foto è richiesta *',
        NEXT_CATEGORY:'Categoria successiva',
        GENERATE:'creare',
        FIELD_AUDIT:'Audit sul campo (verificato dal franchisor)',
        SELF_AUDIT:'Auto-verifica (verificata dal franchisee)',
        GENERATE_AUDIT_LINK:'Scegli una checklist',
        REQUIRES_PHOTO: 'Richiede foto',
        ENABLE_NOTE: 'Abilita Nota',
        ADD_PHOTO: 'Foto',
        UPDATE_PHOTO: 'Aggiornamento',
        NOTE: 'Aggiungi una nota',
        PHOTO_REQUIRED: 'La foto è obbligatoria *',
        
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: "Risposta dell'audit",
        QUESTION: 'Domanda',
        SCORE: 'Punto',
        ANSWER: 'Risposta',
        SUBMIT_CORRECTIVE_ACTIONS:'Invia azioni correttive',
        REQUEST_CORRECTIVE_ACTIONS:'Richiedere azioni correttive',
        INSERT_PHOTO:'Inserisci foto',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'Categoria',
        MIN_FAILURE_COUNT:'Conteggio minimo dei guasti',
    },
    AUDIT_CATEGORY:{
        ADD_NEW_CATEGORY:'Aggiungi una nuova categoria',
        MANAGE_CATEGORY: 'Gestisci categorie',
        CATEGORIES:'Categorie',
        CATEGORY_QUESTIONS_LABLE:' Domande di categoria',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'Rapporto di conformità',
    },
    COMPLIANCE:{
        EDIT_TAB_LABEL: 'Modifica conformità',
    },
    ROYALTY_PLAN:{
        EDIT_TAB_LABEL: 'Modifica piano royalty',
        COPY_TAB_LABEL: 'Copia piano royalty',
        ADD_CALCULATED_FIELD:'Aggiungi campo calcolato'
    },
    ROYALTY_RUN: {
        REPORT_SALES :'Segnala le vendite',
        PROCESS :'Processi',
        SUBMIT:'Invia',
        INVOICE_ALL :'Fattura tutti',
        EMAIL_ALL:"Invia un'e-mail a tutti",
        POST_ALL: 'Pubblica tutto',
    },
    REPORTED_SALES: {
        UPDATE :'Aggiornamento',
        CLOSE :'Vicino',
        EDIT_TAB_LABEL: 'Modifica vendite segnalate'
    },
    ROYALTY_REPORT_V2: {
        PLAN_NAME: 'Nome del piano',
        PERIOD: 'Periodo',
        START_DATE: "Data d'inizio",
        END_DATE: 'Data di fine',
        UNIT: 'Unità',
        GROUP_BY: 'Raggruppa per',
        SORT_BY: 'Ordina per',
    },
    AUDIT_REPORT: {
        AUDIT_SCORE_DETAILS : 'Dettagli del punteggio di audit',
    },
    SALES_AND_REFUND: {
        PERIOD:'Periodo',
        START_DATE:"Data d'inizio",
        END_DATE :'Data di fine',
        BASED_ON :'Basato su',
        SALES_AND_REFUND_TITLE:'Vendite e rimborsi',
        SALES_AND_REFUND_REPORT:'Rapporto sulle vendite e sui rimborsi',
        SALES_AND_REFUND_DETAIL:'Dettagli di vendita e rimborso',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'Dettagli del report sulle vendite e sui rimborsi',
        START_DATE:"Data d'inizio",
        END_DATE:'Data di fine',
        BASED_ON:'Basato su',
        NO_RECORD_EXPORT: 'Nessun record trovato'
    },
    AUDIT_CHECKLISTS:{
        TITLE:'Nuova checklist di controllo',
        EDIT_TITLE:'Modifica checklist di controllo',
    },
    UNIT_CRM:{
        MY_SETTINGS:'Le mie impostazioni',
        COMPANY:'Azienda',
        USER_AND_SECURITY:'Utenti e sicurezza',
        SURVEYS: 'Sondaggi',
        WORKFLOW_RULES_SECTION:'Sezione Flussi di lavoro e regole',
        EMAIL_ALIASES:'Alias ​​di posta elettronica',
        CUSTOMIZATION:'Personalizzazione',
        LISTS:'Elenchi',
        DATA:'Dati',
        COMPANY_SCHEDULER:'Pianificatore aziendale',
        FRANCHISE:'Franchising',
        INTEGRATION:'Integrazione',
        SUBSCRIPTION:'Sottoscrizione',
        CAMPAIGNS:'Campagne',
        BOARDCAST_CAMPAIGNS :'Campagne di trasmissione',
        AUTOMATED_CAMPAIGNS:'Campagne automatizzate',
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'Analisi dettagliata delle vendite segnalate da royalty',
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: 'Utilizzare per la notifica',
        REMOVE_NOTIFICATION_TOOLTIP: 'Rimuovi per notifica',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: 'Gestisci i numeri di telefono dell\'unità, i crediti di testo e i minuti di chiamata',
    },
}
export default label