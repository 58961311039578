import React, { useEffect, useRef, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { styles } from '../../../services/constants/styles';
import { Box, Button, FormHelperText, Icon } from '@mui/material';
import SFDataTable from '../components/sfDataTable';

import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../../services/actions/headerActions';
import * as HTTPClient from '../../../services/helper/httpClient';

import { endPoints } from '../../../services/constants/endPoints';

import { getMultiColumnTitles } from '../../../services/helper/common';
import { getArrayParam, getIntParam, getObjectParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { addTab, getActiveTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { LinkToType } from '../../../services/constants/link';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

import ShowCircularProgress from './circularProgress';
import * as sfDialogs from './sfDialogs';
import { dateFormatFlatPicker, dateFormat, sortArrayObjectByPropertyForCurrency, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { setTableData, setWorkFlows, setWorkflowValue, setStages, setStageValue, setInitialMsg, setSortable, setSortedData, setStagesValueError, setPeriod, setStartDate,setEndDate, setIsLoading } from '../../../services/actions/salesPipelineReportActions';
import DateField from './DateField';
import moment from 'moment';





const reports = '/v3/reports/fe/sales/pipeline'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,


    },
  },
};




const FeSalesPipeLine = ({ object }) => {

  const piplelineRedux = useSelector((state) => state.salesPipelineReport);
  const prevWorkflowValueRef = useRef(piplelineRedux.workflowValue);
  const { period, startDate, endDate, isLoading } = piplelineRedux;

  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const handlePrint = () => {
    const table = tableRef.current;


    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;

    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Sales Pipeline</title>
          <style>
            @media print {
              body {
                font-size: 10pt;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 5px;
                text-align: left;
              }
                #reportName{
                  text-align: center;
                  font-weight: bold;
                }
                #reportTitle{
                  text-align: center;
                }
                  #reportDuration{
                    text-align: center;
                    font-weight: normal;
                  }
            }
          </style>
        </head>
        <body>
          <h4 id="reportTitle">Sales Pipeline</h4>
         <p id="reportName">Workflow Name: ${piplelineRedux.workflowValue}</p>
         <p id="reportDuration">Duration: ${period}<br>Start Date: ${startDate}<br>End Date: ${endDate}</p>
          ${table.outerHTML}
        </body>
      </html>
    `);
    doc.close();


    iframe.contentWindow.focus();
    iframe.contentWindow.print();


    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  async function getWorkFlowStage(object, params) {
    let endPoint = endPoints.ACCOUNTS.STAGE_GET;
    try {
      const response = await HTTPClient.get(endPoint, params);


      dispatch(setStages([...response?.stages]));

      dispatch(setStageValue([...response?.stages.map((stage) => stage.name), 'All']));
      handleRun([...response?.stages.map((stage) => stage.name)]);

    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    dispatch(setWorkflowValue(value));

  };

  const handleChangeChild = (event, checkbox) => {

    const checkboxValue = checkbox.props.value;
    const {
      target: { value },
    } = event;


    dispatch(setStageValue(value));

    const allSelected = value.includes('All');

    if (allSelected && checkboxValue === 'All') {


      dispatch(setStageValue([...piplelineRedux.stages.map((stage) => stage.name), 'All']));
    } else if (allSelected && checkboxValue !== 'All') {


      dispatch(setStageValue([...value.filter((v) => v !== 'All')]));
    } else if (piplelineRedux.stagesValue.length > 0 && checkboxValue === 'All') {

      dispatch(setStageValue([]));

    } else if (!allSelected && value.length === piplelineRedux.stages.length) {

      dispatch(setStageValue([...piplelineRedux.stages.map((stage) => stage.name), 'All']));
    }

  };


  const handleSort = (object) => {

    if (!object) return;

    const name = object.replace(/[\n▲▼]/g, '').trim();
    const match = object.match(/[▲▼]/);

    if (!match || !name) {
      return;
    }

    const isAscending = match[0] === '▲';

    const sortedData = piplelineRedux.tableData.slice().sort((a, b) => {
      if (name === 'Unit') {
        return isAscending ? a.company.localeCompare(b.company) : b.company.localeCompare(a.company);
      } else {
        const valA = Number(a[name]);
        const valB = Number(b[name]);


        if (isNaN(valA) && isNaN(valB)) return 0;
        if (isNaN(valA)) return isAscending ? 1 : -1;
        if (isNaN(valB)) return isAscending ? -1 : 1;

        return isAscending ? valA - valB : valB - valA;
      }
    });


    dispatch(setSortedData(sortedData));
  };

  const downloadExcelReport = (e) => {
    if (e !== null && e !== undefined) {
      let beforeHeaderComponent = excelGenerateBeforeHeaders();
      let headerComponents = excelGenerateHeaders();
      let rowComponents = excelGenerateRows();

      let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
      let htmlData = htmlTable;
      if (isValidParam(htmlData) && htmlData !== '') {
        htmlData = htmlData.replace("%22", "\"");
        var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = 'ResultSet_' + new Date().getTime() + '.xls';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
  }

  const excelGenerateBeforeHeaders = () => {
    let cells = '';
    let reportName = getStringParam(getLocalizedStrings().label.HEADER.SALES_PIPELINE);
    let workflowName = piplelineRedux.workflowValue;
    let tempColumns = getArrayParam(piplelineRedux.tableHeaders);


    let columns = tempColumns.filter(f => !f.is_hidden);
    let columnsLength = getArrayParam(columns).length;


    let combinedText = `${reportName}<br>${workflowName}<br>Duration: ${period}<br>Start Date: ${startDate}<br>End Date: ${endDate}`;


    cells = cells + `<tr><td colspan="${columnsLength}">${combinedText}</td></tr>`;

    return cells;
  }

  const excelGenerateHeaders = () => {
    let cells = '';
    let tempColumns = getArrayParam(piplelineRedux.tableHeaders);
    let columns = tempColumns.filter(f => {
      let temp;
      if (!f.is_hidden) { temp = f; }
      return temp;
    });
    columns.forEach(f => {
      cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
    });
    cells = '<tr>' + cells + '</tr>';
    return cells;
  }

  const excelGenerateRows = () => {
    let rows = getArrayParam([...piplelineRedux.tableData, piplelineRedux.totalData]);
    let columns = getArrayParam(piplelineRedux.tableHeaders);
    let rowData = '';
    if (rows.length !== 0) {
      let cells = '';
      rows.forEach((rowObject, rowIndex) => {
        let innerCells = '';
        columns.forEach((columnObject, colIndex) => {
          if (!columnObject.is_hidden) {
            let value = rowObject.hasOwnProperty(columnObject.name) ? rowObject[columnObject.name] : "";
            innerCells = innerCells + '<td >' + value + ' </td>';
          }
        })
        if (rowIndex % 2 === 0) {
          cells = cells + ('<tr>' + innerCells + ' </tr>');
        } else {
          cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
        }

      });
      rowData = cells;
    } else {
      rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
    }
    return rowData;

  }


  const getWorkflows = async () => {
    try {
      const data = await HTTPClient.get('/v3/workflow/get/baseworkflows', {})



      dispatch(setWorkFlows(data?.records));
      if (!data?.records?.length) {
        dispatch(setIsLoading(false));
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleRun = async (arrValues) => {
    dispatch(setIsLoading(true));

    const stagesValue = arrValues.filter((stage) => stage !== 'All');
    let params={};
    let valid = true;
    if(period && period.length > 0){
        if(period == 'This Month'){
            params.period =  'tm';
        }else if(period == 'Custom'){
            params.period =  'cust';
            if(startDate){
                let currentDate = moment(startDate)
                currentDate = currentDate.format('YYYY-MM-DD')  
                params.from_date = currentDate;
            }else{
                valid = false;
            }
            if(endDate){
                let toDate = moment(endDate)
                toDate = toDate.format('YYYY-MM-DD')
                params.to_date = toDate;
            }else{
                valid = false;
            }
        }else if(period == 'Today'){
          params.period = 'td';
        }else if(period == 'Yesterday'){
          params.period = 'yd'
        }else if(period == 'This Week'){
          params.period = 'tw'
        }else if(period == 'Last Week'){
          params.period = 'lw'
        }else if(period == 'This Quarter'){
            params.period =  'tq';
        }else if(period == 'This Year'){
            params.period =  'ty';
        }else if(period == 'Last Month'){
            params.period =  'lm';
        }else if(period == 'Last Year'){
            params.period =  'ly';
        }else if(period == 'Last Quarter'){
            params.period =  'lq';
        }
    }else{
      valid = false;
    }

    if(!valid) {
      sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Please select all the available filters and try again', null);
      return;
    }

    if (stagesValue.length !== 0) {
      const joinedAndQuoted = stagesValue.map(status => `'${status}'`).join(', ');

      const response = await HTTPClient.get(reports, (JSON.stringify({ status: joinedAndQuoted, workflow_name: piplelineRedux.workflowValue, ...params })))

      const checkingValues = response.results.some((item) => Object.values(item).some((value) => value !== 0))

      const tableRows = response.results.map((item) => Object.keys(item))

      const uniqueValues = [...new Set(tableRows.flat())].filter((item) => item !== 'id')

      const filterTableRows = uniqueValues.map((item) => {
        if (item === 'company') {
          return { name: item, label: 'Unit', width: 160, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} }
        }
        return { name: item, label: item, width: 40, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: { textAlign: 'center' } }
      })
      let obj = filterTableRows.reduce((acc, item) => {
        acc[item.name] = 0;
        return acc;
      }, {});

      response.results.forEach(item => {
        for (let key in obj) {
          if (item.hasOwnProperty(key)) {
            if (typeof item[key] === 'number') {
              obj[key] += item[key];
            } else if (typeof item[key] === 'string') {
              obj[key] = 'Total';
            }
          }
        }
      });



      dispatch(setTableData({ tableData: [...response.results], showTable: checkingValues, totalData: obj, tableHeaders: filterTableRows }));

      dispatch(setStagesValueError(false))
      dispatch(setIsLoading(false));


    } else {

      dispatch(setStagesValueError(true))
      dispatch(setIsLoading(false));
    }

    dispatch(setInitialMsg('OK'))

  }

  const openTab = (label, object, recordId) => {
    let activeTab = getObjectParam(getActiveTab());
    let activeTabIndex = getIntParam(activeTab.index);
    let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
    let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

    addTab(tab, true);
  }

  const customEvent = (eventType, column, row, event) => {
    eventType = getStringParam(eventType);
    column = getObjectParam(column);
    row = getObjectParam(row);
    let colName = getStringParam(column.name);
    let recordId = row.id;

    if (eventType === 'is_detail_link') {
      openTab(getMultiColumnTitles(column.name, row), constants.ACCOUNTS_OBJECT, row.
        id);
    }
  }

  const handleTableSort = (object) => {

    handleSort(object);


    dispatch(setSortable({ sortable: !piplelineRedux.sortable, currentColumn: object }));

  }



  useEffect(() => {
    dispatch(setHeader('Sales-Pipeline-Report', 'Sales Pipeline Report', null, false, true));

    if (piplelineRedux.stages.length === 0) {

      getWorkflows();
    }


  }, []);

  useEffect(() => {

    if (piplelineRedux.workFlows.length > 0 &&
      piplelineRedux.workflowValue &&
      piplelineRedux.workflowValue !== prevWorkflowValueRef.current) {

      const getSelectedWorkflowStages = piplelineRedux.workFlows.find(
        ({ workflow_name }) => workflow_name === piplelineRedux.workflowValue
      );

      if (getSelectedWorkflowStages) {
        getWorkFlowStage(object, { workflow_id: getSelectedWorkflowStages.id });
      }

      prevWorkflowValueRef.current = piplelineRedux.workflowValue;
    }
  }, [piplelineRedux.workflowValue]);




  useEffect(() => {
    const table = document.getElementById('sfCustomTable');

    const footer = document.createElement('tfoot');
    const tr = document.createElement('tr');

    const headers = table?.querySelectorAll('th');
    let timer;

    headers?.forEach((item) => {
      item.addEventListener('click', (e) => { timer = setTimeout(() => handleTableSort(item.innerText), 0) });
    })


    const footerCells = Object.values(piplelineRedux.totalData)?.map((item) => {
      const td = document.createElement('td');
      td.innerText = item;
      td.style.fontWeight = 'bold';
      td.style.padding = '6px';
      if (item !== 'Total') {
        td.style.textAlign = 'center';
      }
      tr.appendChild(td);
    })
    footer?.appendChild(tr);
    table?.appendChild(footer);

    return () => {
      table?.removeChild(footer);
      headers?.forEach((item) => {
        item?.removeEventListener('click', (e) => handleTableSort(item.innerText));
      });

      clearTimeout(timer);
    }

  }, [piplelineRedux.totalData]);





  return (
    <section style={{ width: '94%', margin: '0 auto' }}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>

          <FormControl sx={{ m: 1, width: 230 }} size='small'>
            <InputLabel id="select-workflow-label">Select Workflow</InputLabel>
            <Select
              labelId="select-workflow-label"
              id="select-workflow"
              value={piplelineRedux.workflowValue}
              style={{padding:4}}
              label="Select Workflow"
              onChange={handleChange}
            >
              {piplelineRedux.workFlows?.map(({ workflow_name }) => (
                <MenuItem key={workflow_name} value={workflow_name}>
                  {workflow_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* child selector */}


          <FormControl sx={{ m: 1, width: 230 }} size='small' error={piplelineRedux.stagesValueError && Boolean(piplelineRedux.stagesValue.length === 0)}>
            <InputLabel id="multiple-stages-checkbox-label">Stages</InputLabel>
            <Select
              labelId="multiple-stages-checkbox-label"
              id="multiple-stages-checkbox"
              multiple
              value={piplelineRedux.stagesValue}
              style={{padding:4}}
              onChange={handleChangeChild}
              input={<OutlinedInput label="Stages" />}
              renderValue={(selected) => selected.filter((i) => i !== 'All').join(', ')}
              MenuProps={MenuProps}
              error={piplelineRedux.stagesValueError && Boolean(piplelineRedux.stagesValue.length === 0)}
              helperText={piplelineRedux.stagesValueError && 'Please select at least one stage'}

            >
              <MenuItem value={'All'}>
                <Checkbox checked={piplelineRedux.stagesValue.indexOf('All') > -1} />
                <ListItemText primary={'All'} />
              </MenuItem>

              {piplelineRedux.stages?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={piplelineRedux.stagesValue.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ position: 'absolute', top: '100%' }} sx={{ color: 'red' }}>{piplelineRedux.stagesValueError && Boolean(piplelineRedux.stagesValue.length === 0) && 'Please select at least one stage'}</FormHelperText>
          </FormControl>

          <DateField />
    
          <div id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></div>

        </Box>
        <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '15%', height: '47px', alignItems: 'center', marginLeft: '30px' }}>
            <Button
              onClick={() => handleRun(piplelineRedux.stagesValue)}
              style={{
                ...styles.primaryButton,
                minWidth: '56px',
                width: '19%'
              }}
              title={'Run'}
            > Run</Button>
        {
          piplelineRedux.showTable > 0 && <Box>

            <Button
              style={{
                minWidth: 'unset',
              }}
            >
              <Icon
                onClick={() => handlePrint()}
                title={getLocalizedStrings().label.REPORT.PRINT}
                style={{ cursor: 'pointer', color: '#717171' }}
              >
                print
              </Icon>
            </Button>

            <Button
              startIcon={<i className="fas fa-file-excel" style={{ fontSize: '20px', color: 'green' }} ></i>}
              onClick={(e) => downloadExcelReport(e)}
              style={{
                ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                color: '#717171',
                border: '0px',
                minWidth: 'unset'

              }}
              title="Excel"
            />
          </Box>
        }
        </div>
      </Box>


      {
        piplelineRedux.tableData.length > 0 ? <div ref={tableRef} style={{ marginTop: '1rem' }}>
          <SFDataTable columns={piplelineRedux.tableHeaders} rows={piplelineRedux.tableData} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
        </div> : (isLoading ? <ShowCircularProgress size={30} style={{ height: '50vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          : <p style={{ color: 'red', fontWeight: 500 }}>No Records Found</p>)
      }

      <div>

      </div>

    </section>
  )
}

export default FeSalesPipeLine


