import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Select,InputLabel,MenuItem,Button,FormControl} from "@mui/material";
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction.js';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';

const AuditSelectChecklistDialog = ({data,callFrom}) => {
    const dispatch = useDispatch();
    const app = useSelector((state)=>state.app);
    const [isMounted,setisMounted] = useState(false);
    const [checklist,setchecklist] = useState([]);
    const [selectedChecklistId,setsSelectedChecklistId] = useState('');
    const [isAuditCheclistEmpty,setIsAuditCheclistEmpty] = useState(false);
    useEffect(()=>{
        setisMounted(true);
        let params = {};
        params.group_by_condition = "active";
        params.group_by_field_name = 't_status';
        params.query_id = 0;
        params.query_name ="All child records";
        params.query_type = "all";
        params.page_size = 500;
        var promise = Promise.resolve(HTTPClient.get(endPoints.AUDIT_CHECKLIST.LIST_VIEW, params));
            promise.then((response) => {
                if (response) {
                    if(response?.records){
                        let _tempRecords = response?.records;
                        _tempRecords?.map((ele)=>{
                            ele.form_name = ele.form_name + " (" + ele.t_type + ')';
                        });
                        setchecklist(_tempRecords);
                        setisMounted(false);
                    }
                } else {
                    setchecklist([]);
                    setisMounted(false);
                }
            });
    },[])

    const handleSelectChecklistName = (event) =>{
        let value  = event.target.value;
        setsSelectedChecklistId(value);
        setIsAuditCheclistEmpty(false);
    }

    const getChecklistNameList = () =>{
        let List = checklist;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.id} style={styles.popoverMenuItem} >{item.form_name}</MenuItem>;
            })
        }
    }

    const handleSelectAuditChecklist = () =>{
        if(selectedChecklistId){
            let _auditChecklist = checklist; 
            let tempSelectedChecklist = _auditChecklist.filter((ele)=> {return ele.id === selectedChecklistId});
            tempSelectedChecklist = tempSelectedChecklist[0];
			var response = Promise.resolve(HTTPClient.get(endPoints.AUDIT_QUESTIONS.GET_FORM_ID, {
				recordId: data?.recordId,
				is_field_audit: tempSelectedChecklist?.t_type === "Field" ? 1 : 0,
                form_id : selectedChecklistId,
			}));
			response.then((response) => {
				if (response.length > 0) {
					let url = `${window.location.protocol}//${window.location.hostname + (window.location.port ? ':' + window.location.port : '')}/#/audit-questions?`
					let params = 'p=' + response[0].encodedParams;
					params += '&c=' + app.me.background.replace('#', '');
					// params += '&recordId=' + this.props.detailView.record.id;
					// params += '&formId=' + response[0].form_id;
					window.open(url + params);
                    dispatch(getAppDialog(false, null, null, null, null))
				} else {
					dispatch(showCustomSnackBar("Please create questions to open the audit form", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
				}
			});
        }else{
            setIsAuditCheclistEmpty(true);
            let inputElement = null;
            inputElement = document.getElementById('Checklist_Name');
                if (isValidParam(inputElement) && inputElement != null) {
                    if (inputElement != null) {
                        inputElement.focus();
                    }
                }
            dispatch(showCustomSnackBar("Please select audit Checklist to open the audit form", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const handleClose = () =>{
        dispatch(getAppDialog(false, null, null, null, null))
    }

    const getReportView = () =>{
        let element  = <div>
            <div style={{width: '100%', height: '6rem',marginLeft:'1rem'}}>
                <div className="field_and_self_audit" style={{display:'inline'}}>
                    <FormControl style={{ width: '100%', height: '38px'}} variant="outlined">
                        <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ lineHeight: '0.9' }}>Checklist Name</InputLabel>
                        <Select
                            key={"Checklist_Name"}
                            id={"Checklist_Name"}
                            label="Checklist Name"
                            error = {isAuditCheclistEmpty}
                            value={selectedChecklistId}
                            onChange={(e) => handleSelectChecklistName(e)}
                            style={{ height: "40px", marginTop: "2px" }}
                            className={"sf-fields-bg"}
                            variant="outlined"
                        >
                            {getChecklistNameList()}
                        </Select>
                    </FormControl>

                </div>
                <div className="audit_buttons" style={{ display: 'flex',justifyContent:'right',alignItems:'right',marginTop:'40px'}}>
                    <Button 
                        onClick={() => handleSelectAuditChecklist()}
                        style={styles.primaryButton}>{getLocalizedStrings().label.AUDIT_QUESTIONS.GENERATE}</Button>
                    <Button
                        onClick={() => handleClose()}
                        style={{ ...styles.secondaryButton, fontSize: 16,marginLeft:'10px' }} >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>
            </div>
        </div>
        return element;
    }


  return (
    <div style={{ width: '29rem', height: '8rem'}}>
            {!isMounted ?
                <div style={{ marginTop:'30px'}}>
                    {
                        getReportView()
                    }
                </div>
                :
                <div style={{ width: '100%', height: '7rem',alignItems:'center',justifyContent:'center',paddingLeft:'12rem',marginTop:'4rem'}}>
                    <div className="asset-loaderh" >
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>  )
}

export default AuditSelectChecklistDialog