import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Navigate } from "react-router-dom";
import isArray from "lodash/isArray";
import toNumber from "lodash/toNumber";
import find from "lodash/find";
import filter from "lodash/filter";
import map from "lodash/map";
import {
	getArrayParam,
	getBooleanParam,
	getFloatParam,
	getIntParam,
	getObjectParam,
	getStringParam,
	isInvalidParam,
	isPositiveInteger,
	isValidParam,
} from "../../../services/helper/parameterVerifier";
import * as SFFormActions from "../../../services/actions/sfFormActions";
import { updateSfRecentData, updateSFFormReminderFunc } from "../../../services/actions/sfFormActions";
import { refreshDetailViewHeader } from '../../../services/actions/detailViewActions';
import {
	checkDuplicateFranchise,
	checkDuplicateProductName,
	getContactInfoForLookup,
	getEndPointObject,
	getFranchiseInfo,
	getLookupAutocompleteInfo,
	getProjectDepTasksList
} from "../../../services/actions/sfFormActions";
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP, } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import {
	Button,
	Checkbox,
	Chip,
	FormControl,
	FormControlLabel,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { setHeader } from "../../../services/actions/headerActions";
import {
	getAppCustomDrawer,
	getAppDialog,
	getAppDrawer,
	minimizeAppCustomDrawer,
} from "../../../services/actions/appContainerActions";
import Tooltip from '@mui/material/Tooltip';
import ReminderBlock from "./reminderBlock";
import * as link from "../../../services/constants/link";
import { isValidateFormField } from "../../../services/helper/formValidator";
import Switch from "@mui/material/Switch";
import {
	dateFormat,
	dateFormatFlatPicker,
	dateTimeFormat,
	dateTimeFormatFlatPicker,
	openWindow,
	phoneFormat,
	reverseFormatNumber,
	sortArrayObjectByProperty,
	sortArrayObjectByIntegerProperty,
	groupByObjectArrayByProperty,
	timeFormat,
	timeZone,
} from "../../../services/helper/utils";
import moment from "moment";
import {
	addGroupIntoReduxStore,
	getCampaignTypeListValues,
	getCampaignTypeNames,
	getChildObjectInfo,
	getMultiLingualLabel,
	getObjectLabelByObject,
	getSelectedObjectLabel,
	hasAccessPermission,
	isFranchiseRecord,
	isLinkedChildObject,
} from "../../../services/helper/common";
import {
	getCalendarRefresh,
	getJobCalendarRefresh,
	removejobById,
	setSelectedDate,
} from "../../../services/actions/calendarActions";
import * as HTTPClient from "../../../services/helper/httpClient";
import {
	getDetailViewPinnedNote,
	refreshDetailViewData,
	refreshDetailViewListViewData,
	refreshQueueDetailViewData,
	refreshQueueDetailViewNote,
	refreshQuotation, detailViewIsMounted,
	getDetailviewData,
	isDetailViewSet,
	getDetailViewTaskAppointment, getUnitOwnerTasks
} from "../../../services/actions/detailViewActions";
import { getTasksRefresh, getUnitTasksRefresh, getTasksPortal } from "../../../services/actions/taskActions";
import {
	addTab,
	closeTab,
	getActiveTab,
	getActiveTab as tabManagerActiveTab,
	getActiveTabInfo,
	TYPE_COLUMN_VIEW,
	TYPE_DETAIL_VIEW,
	TYPE_HOME,
	TYPE_LIST_VIEW,
	TYPE_REPORT,
	updateActiveTab, updateTabInfo
} from "../../../services/helper/sfTabManager";
import * as sfDialogs from "../components/sfDialogs";
import {
	getLinkRecords,
	getListData,
	getLookupRecordInfo,
	getParentAccountContacts,
	isRefreshListView,
	refreshColumnView,
	refreshListView,
	setListViewMounted,
} from "../../../services/actions/listViewAcions";

import { refreshColumnView as listRefreshColumnView, setKanbanMounted } from "../../../services/actions/listsActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { updateNotificationInfo } from "../../../services/actions/notificationActions";
import SFAutoComplete from "../components/sfAutoComplete";
import { getMyGroups, setGroups, } from "../../../services/actions/filterMenuAction";
import { endPoints } from "../../../services/constants/endPoints";
import { isAssignRuleExists } from "../../../services/actions/assignmentRuleActions";
import { deleteCookie, getCookie, setCookie, } from "../../../services/helper/sfCookies";
import CustomForm from "../containers/CustomForm";
import AppTaskCalendar from "../components/appTaskCalendar";
import { completeAction, saveGroup, } from "../../../services/actions/batchActions";
import ShowCircularProgress from "../components/circularProgress";
import AppTaskQueueChild from "../components/AppTaskQueueChild";
import { openDetailviewQueueTab, removeRecordFromRedux, removeRecurringRecordFromRedux, removeFollowingRecRecordFromRedux } from "../../../services/actions/queueActions";
import Autocomplete from "@mui/material/Autocomplete";
import RequiredForms from "../components/requiredForms";
import PopOver from "./PopOver";
import Axios from 'axios';
import _ from "lodash";
import { getUnitsWithMyClientId } from "../../../services/actions/unitsAction.js";
import { useDispatch, useSelector } from "react-redux";
import { isObjectChildExists, } from "../../../services/helper/common";
import { Menu } from '@mui/material';
import { refreshDetailViewGroup } from '../../../services/actions/detailViewActions';
import { alpha, styled } from '@mui/material/styles';
import { param } from 'jquery';
import { actions } from '../../../services/constants/actions.js';
import MonerisCheckout from './MonerisCheckout';
import { setMonerisCheckout, setMonerisCheckoutQuoteId } from '../../../services/actions/paymentActions.js';

const ToggleSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: "#424242",
		'&:hover': {
			backgroundColor: '#2c2c2c1f',
		},
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: "#424242",
	},
}));


const SFForm = ({
	object, data, label, recordId, attendees, isPreview, note_data, parentDetails, isDragNDrop,
	isPowerCalling, columnViewTaskApp, reminderBlock, appointmentSub, isDetailView, parentLinkInfo,
	recordDetails, callFrom, hideCancel, isEdited, openMode, paymentType, tasksObjParam,
	parentPaymentId, isCompleAndNew, isFromJobScheduler, callType, type, isUnitask, refreshView
}) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const sfForm = useSelector((state) => state.sfForm);
	const tab = useSelector((state) => state.tab);
	const detailView = useSelector((state) => state.detailView);
	const notification = useSelector((state) => state.notification);
	const header = useSelector((state) => state.header);
	const userlist = useSelector((state) => state.userlist);
	const groups = useSelector((state) => state.filters.groups);
	const filters = useSelector((state) => state.filters);
	const calendar = useSelector((state) => state.calendar);
	const queueList = useSelector((state) => state.queueList);
	const listView = useSelector((state) => state.listView);
	const customDrawer = useSelector((state) => state.appContainer.customDrawerProps);
	const childListview = useSelector((state) => state.childListView);
	const reduxDetailView = useSelector((state) => state.detailView, _.isEqual);
	const { my_clientid } = useSelector((state) => state.childListView.data);


	// const record = useSelector((state) => {});

	const [mounted, setMounted] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [fields, setFields] = useState([]);
	const [fieldProps, setFieldProps] = useState({});
	const [lookupInfoState, setLookupInfoState] = useState([]);
	const [reminder, setReminder] = useState([{
		t_reminder_type: "Email",
		t_time_value: "10",
		t_time_type: "minutes",
	}]);
	const [recurring_fields, setRecurring_fields] = useState({});
	const [recurring_enable, setRecurring_enable] = useState(false);
	const [recurring_edit, setRecurring_edit] = useState(false);
	const [noteDataObj, setNoteDataObj] = useState({});
	const [start_date, setStart_date] = useState(null);
	const [listValueMenuItems, setListValueMenuItemsValue] = useState({});
	const [isWorkflowShow, setIsWorkflowShow] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [linkInfo, setLinkInfoVal] = useState(null);

	const [parentRecurId, setParentRecurId] = useState(0);
	const [showReminder, setShowReminder] = useState(false);
	const [recurringEditConfirmDialog, setRecurringEditConfirmDialog] = useState(false);
	const [save_action_type, setSave_action_type] = useState("");
	const [isSetActionType, setIsSetActionType] = useState(false);
	const [opportunityStatusList, setOpportunityStatusListValue] = useState([]);

	const [forCastVal, setForCastVal] = useState(0.0);
	const [forCastValAcc, setForCastValAcc] = useState(0.0);
	const [probability, setProbability] = useState(0.0);
	const [isNotifySalesRep, setIsNotifySalesRep] = useState(false);
	const [isPinNoteChecked, setIsPinNoteChecked] = useState(false);
	const [isImportantNote, setIsImportantNote] = useState(false);

	const [notifySalesRepLabel, setNotifySalesRepLabel] = useState("");
	const [isSavedDisabled, setIsSavedDisabled] = useState(false);
	const [isConvert, setIsConvert] = useState(false);
	const [attendeesDataArr, setAttendeesDataArr] = useState([]);
	const [linkInfoObject, setLinkInfoObject] = useState({});
	const [isNoteObjectLink, setIsNoteObjectLink] = useState(false);
	const [isShowCustomTaskType, setIsShowCustomTaskType] = useState(false);

	const [flyingNoteText, setFlyingNoteText] = useState("");
	const [record, setRecord] = useState(null);
	const [companySearchName, setCompanySearchName] = useState(null);
	const [autoCompleteDataSource, setAutoCompleteDataSource] = useState({});
	const [autoFocusFieldName, setAutoFocusFieldName] = useState("");
	const [isWorkflowTask, setIsWorkflowTask] = useState(0);

	const [stateGroups, setStateGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState("");
	const [isDomainExist, setIsDomainExist] = useState(false);
	const [accountId, setAccountId] = useState(0);
	const [isAssignRule, setIsAssignRule] = useState(false);
	const [franchiseId, setFranchiseId] = useState(0);

	const [isAddToQuickbook, setIsAddToQuickbook] = useState(false);
	const [attendeeRecordId, setAttendeeRecordId] = useState("");
	const [isAppTaskCalendarRefresh, setIsAppTaskCalendarRefresh] = useState(false);
	const [appTaskCustomFields, setAppTaskCustomFields] = useState([]);
	const [appTaskFieldProps, setAppTaskFieldProps] = useState({});
	const [isAddToGroupList, setIsAddToGroupList] = useState(false);

	const [parent_link_info, setParent_link_info] = useState(null);
	const [invoiceList, setInvoiceList] = useState(null);
	const [defaultRule, setDefaultRule] = useState(null);
	const [isPaymentConnected, setIsPaymentConnected] = useState(false);
	const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
	const [isPreviewStateValue, setIsPreviewStateValue] = useState(true);

	const [isUnitOwner, setIsUnitOwner] = useState(false);
	const [isOnboardingTypeTask, setIsOnboardingTypeTask] = useState(false);
	const [requiredRecord, setRequiredRecord] = useState(null);
	const [lookupInput, setLookupInput] = useState("");
	const [isPinNoteAlreadyChecked, setIsPinNoteAlreadyChecked] = useState(false);
	const [paidAmount, setPaidAmount] = useState(0);
	const [quoationId, setQuoationId] = useState(0);
	const [refundedAmount, setRefundedAmount] = useState(0);
	const [applinkInfo, setApplinkInfo] = useState(null);
	const [preFieldProps, setPreFieldProps] = useState({});
	const [tempFieldForProjectTask, setTempFieldForProjectTask] = useState({});
	const [tempCustomField, setTempCustomField] = useState({});
	const [taskStartDateFieldName, setTaskStartDateFieldName] = useState(null);
	const [allRequiredFields, setAllRequiredFields] = useState(null);
	const [locationState, setLocationState] = useState(null);
	const [tasksList, setTasksList] = useState([]);
	const [isFranchiseRecordState, setisFranchiseRecordState] = useState([]);
	const [showRequiredFormFields, setShowRequiredFormFields] = useState(false);
	const [units, setunits] = useState([]);
	const [changeData, setChangeData] = useState({ field: '', value: '' });
	const [attchedTaskFiles, setAttchedTaskFiles] = useState([]);
	const [changeLog, setchangeLog] = useState({ isStateCompleteAndNew: false,isEditFormValid : true , allRequiredFields: null, autoFocusFieldName: "", isSetActionType: false, autoCompleteDataSource: {}, selectedGroup: "", lookupInfoState: [], isSavedDisabled: false, isConvert: false, isAssignRule: false, probability: 0.0, forCastVal: 0.0, taskStartDateFieldName: null, isProjectTasks: false, isShowCustomTaskType: false, contactRecords: [] });
	const [loaded, setLoaded] = useState(false);
	const requiredFormsRefs = useRef();
	const formContainer = useRef();
	const [, updateNState] = useState();
	const handleForceupdateMethod = useCallback(() => updateNState({}), []);
	const [taskNameList, setTaskNameList] = useState([]);
	const [isProjectTasks, setisProjectTasks] = useState(false);
	const [recentTasks, setRecentTasks] = useState('');
	const [isOpenAttachmentPopover, setIsOpenAttachmentPopover] = useState(false);
	const [attachFiles, setAttachFiles] = useState([]);
	const [attachmentDetails, setAttachmentDetails] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [existingFiles, setExistingFiles] = useState([]);
	const [duplicateFiles, setDuplicateFiles] = useState([]);
	const [unitTaskType, setUnitTaskType] = useState({
		t_unit_owner: '',
		task_type: '',
		unit_owner: '',
	})

	const [textNotifySalesRepLabel, setTextNotifySalesRepLabel] = useState("");
	const [isTextNotifySalesRep, setIsTextNotifySalesRep] = useState(false);
	const [contactChildRecordArr, setContactChildRecordArr] = useState([])
	const detailViewTaskAppintmentReducer = useSelector(
		(state) => state.detailViewTaskAppintmentReducer
	);
	const [comapnyNameFromRequiredFrom, setComapnyNameFromRequiredFrom] = useState('')

	const [paymentProvider, setPaymentProvider] = useState('');
	// componentWillMount
	useEffect(() => {
		let dataValue = appContainer.customDrawerProps;
		let minimizeList = dataValue?.minimizeList;
		if (object === constants.NOTE_OBJECT && (minimizeList.hasOwnProperty("new") || minimizeList.hasOwnProperty("New")) &&
			(minimizeList.new || minimizeList.New)) {
				setIsImportantNote(sfForm.sfFromData.isImportantNote);

		} else {
			sfForm.sfFromData = { ...sfForm.sfFromData, isPinNoteChecked: false, isImportantNote: true };
		}
		setIsImportantNote(true);
	}, []);


	useEffect(() => {
		let _fieldProps = fieldProps;
		if (object === constants.TASKS) {
			if (fieldProps && Object.keys(fieldProps).length > 0) {
				let _sfRecentField = sfForm.sfRecentField;
				let _recentformData = sfForm.sfRecentData[object];
				_sfRecentField.map((field) => {
					if (_fieldProps[field].value == null) {
						_fieldProps[field].value = "";
					}
					else if (_fieldProps[field].value != '') {
						_fieldProps[field].value = _fieldProps[field].value;
					}
					else if (_fieldProps[field] && _recentformData && Object.keys(_recentformData).length > 0) {
						if (_recentformData) {
							_fieldProps[field].value = _recentformData;
							_fieldProps['t_description'].value = _fieldProps['t_description'].value
						} else {
							_fieldProps[field].value = _fieldProps[field].list_values[0]?.value;
						}
					} else {
						_fieldProps[field].value = _fieldProps[field].list_values[0]?.value;

					}

				})
				setFieldProps(_fieldProps);
			}
		}

	}, [fieldProps, taskNameList])


	useEffect(() => {
		let _fieldProps = fieldProps;
		if (object === constants.TASKS) {
			if (fieldProps && Object.keys(fieldProps).length > 0) {
				let _sfRecentField = sfForm.sfRecentField;
				_sfRecentField.map((field) => {
					let params = {
						"t_name": _fieldProps[field].value,
					};

					let drawerPropsData = getObjectParam(
						appContainer.drawerProps.data
					);
					let mode = getStringParam(drawerPropsData.mode);

					var response = Promise.resolve(
						HTTPClient.post(endPoints.TASKS_INFO.GET_DESCRIPTION, params)
					);
					if (_fieldProps['t_description'].value === "") {
						response.then((response) => {
							if (_fieldProps['t_description'].value === "" && mode == 'EXPANDED') {
								_fieldProps['t_description'].value = "";
							}
							else if (response.data != null && response.data.hasOwnProperty('t_description')) {
								_fieldProps['t_description'].value = response.data.t_description;
							} else {
								_fieldProps['t_description'].value = "";
							}
							handleForceupdateMethod();

						});
					} else {
						_fieldProps['t_description'].value = _fieldProps['t_description'].value
						handleForceupdateMethod();

					}

				})
			}
		}

	}, [taskNameList])

	useEffect(() => {
		if (record?.id > 0 && recordId > 0) {
			let tempFieldProps = fieldProps;
			if (tempFieldProps['assign']) {
				tempFieldProps['assign'].value = record?.assign;
			}
			setFieldProps({ ...tempFieldProps })
		}
	}, [record])
	
	useEffect(() => {
		if (object === constants.NOTE_OBJECT && getIntParam(recordId) == 0 && Object.keys(fieldProps).length == 0 &&
			!customDrawer.minimizeList['new'] && !customDrawer.minimizeList['New'] && customDrawer.dialogName == "Add Notes"
		) {

			if (sfForm.sfFromData.hasOwnProperty("note_text") && fieldProps["note_text"]?.value != "") {
				sfForm.sfFromData["note_text"].value = "";
			}

		} else if (object === constants.NOTE_OBJECT && getIntParam(recordId) == 0 && fieldProps.hasOwnProperty("note_text") && fieldProps["note_text"]?.value != "" && customDrawer.dialogName == "Add Notes") {
			
			sfForm.sfFromData["note_text"].value = fieldProps["note_text"]?.value;

		}else if (object === constants.NOTE_OBJECT && getIntParam(recordId) > 0 && 
		!customDrawer.minimizeList['new'] && customDrawer.dialogName == "Edit Notes" && sfForm.sfFromData.hasOwnProperty("note_text") && fieldProps["note_text"]?.value !== '' && fieldProps["note_text"]?.value !== undefined){
			sfForm.sfFromData["note_text"].value = fieldProps["note_text"]?.value;
		}
		else if (object === constants.NOTE_OBJECT && getIntParam(recordId) > 0 && sfForm.sfFromData.hasOwnProperty("tempFieldProps")  &&
		!customDrawer.minimizeList['new'] && customDrawer.dialogName == "Edit Notes"){
			sfForm.sfFromData["note_text"].value = sfForm.sfFromData?.tempFieldProps["note_text"]?.value;
		}
	}, [fieldProps, sfForm.sfFromData])

	useEffect(() => {
		let _isProjectTasks = callFrom != "queueView" && object == constants.TASKS && parentDetails?.parent_object == constants.PROJECTS_OBJECT ? true : false;
		setisProjectTasks(_isProjectTasks);
		let log = changeLog;
		log.isProjectTasks = _isProjectTasks;
		setchangeLog({ ...log });
		if (callFrom === "queueView") {
			isEdited(false);
		}
	}, [])
	useEffect(() => {
		if (object === constants.ACCOUNTS_OBJECT && getIntParam(recordId) > 0) {
			processIfFranchiseRecord(recordId);
		}
		let pinNoteChecked = false;
		let importantNote = false;
		let dataValue = appContainer.customDrawerProps;
		let minimizeList = dataValue?.minimizeList;
		if (object === constants.NOTE_OBJECT && recordId > 0 && minimizeList?.new == false) {
			pinNoteChecked = getBooleanParam(appContainer.customDrawerProps.data.isPinnedNote);
			importantNote = getBooleanParam(appContainer.customDrawerProps.data.isImportantNote);
			sfForm.sfFromData = { ...sfForm.sfFromData, isPinNoteChecked: pinNoteChecked, isImportantNote: importantNote };
			setIsPinNoteChecked(pinNoteChecked);
			setIsImportantNote(importantNote);
			setIsPinNoteAlreadyChecked(pinNoteChecked);
		}
	}, []);

	useEffect(() => {
		if (object === constants.APPOINTMENTS || object === constants.TASKS) {
			if (note_data) {
				setNoteDataObj(JSON.parse(note_data));
			}
		}
	}, [note_data, object]);

	useEffect(() => {
		if (object === constants.APPOINTMENTS) {
			setAttendeesDataArr(attendees);
		}
	}, [attendees, object]);

	useEffect(() => {
		if (getBooleanParam(detailView.isQueueRefresh)) {
			dispatch(refreshQueueDetailViewData(false));
		}
		getDataAndProcess();
	}, [object, openMode, detailView.isQueueRefresh]);

	useEffect(() => {
		getMountedFromData();
		if (object !== 'note') {
			let reminderVal = [
				{
					t_reminder_type: "Email",
					t_time_value: "10",
					t_time_type: "minutes",
				},
			];

			sfForm.reminder = reminderVal;
		}
	}, []);

	useEffect(() => {
		addFloatingLabelEvent();
		checkFieldProps();
	}, [fieldProps]);

	useEffect(() => {
		if (app.me.is_inxpress && fieldProps !== null && fieldProps !== undefined) {
			//greenlightValidation(fieldProps);
		} else if (!app.me.is_inxpress) {
			//saveSFForm();
		}
	}, [fieldProps, changeLog.lookupInfoState]);

	useEffect(() => {
		attachedFiles();
	}, [])
	useEffect(() => {
		let _files = attachFiles;

		if (isValidParam(attachmentDetails)) {
			if (isValidParam(attachmentDetails.attached_files) && getArrayParam(attachmentDetails.attached_files).length > 0) {
				var uniqueResultArray = attachmentDetails.attached_files.filter(function (objOne) {
					return !_files.some(function (objTwo) {
						return objOne.name == objTwo.name;
					});
				});
				_files = [..._files, ...uniqueResultArray];
			}

		}
		if (isValidParam(_files)) {
			attachedFiles(_files);

		}
	}, [attachmentDetails, attachFiles])

	function processIfFranchiseRecord(id) {
		const param = { record_id: id };
		const promise = isFranchiseRecord(param);
		promise.then((response) => {
			if (getBooleanParam(response)) {
				setisFranchiseRecordState(response);
				// getDataAndProcess();
			}
		})
	}

	const getMountedFromData = () => {
		if (object === constants.PAYMENTS_OBJECT) {
			checkIsPaymentConnected();
		}
		// getDataAndProcess();
		setIsPreviewStateValue(isPreview);
		const tempParentDetails = getObjectParam(parentDetails);
		let tempLinkInfoObject = linkInfoObject;
		if (object === constants.TASKS || object === constants.APPOINTMENTS) {
			if (tempParentDetails.hasOwnProperty("parent_object") && tempParentDetails.hasOwnProperty("parent_record_id")) {
				let parentObject = getStringParam(tempParentDetails.parent_object).trim();
				let parentRecordId = getIntParam(tempParentDetails.parent_record_id);
				if (
					parentObject !== "" && parentRecordId > 0 && (
						parentObject === constants.ACCOUNTS_OBJECT ||
						parentObject === constants.OPPORTUNITIES_OBJECT ||
						parentObject === constants.CONTACTS_OBJECT ||
						parentObject === constants.CASES_OBJECT ||
						parentObject === constants.ISSUES_OBJECT ||
						parentObject === constants.PROJECTS_OBJECT ||
						parentObject === constants.CUSTOM_TABLE1_OBJECT ||
						parentObject === constants.CUSTOM_TABLE2_OBJECT ||
						parentObject === constants.CUSTOM_TABLE3_OBJECT ||
						parentObject === constants.CUSTOM_TABLE4_OBJECT ||
						parentObject === constants.JOBS_OBJECT
					)
				) {
					let parentTableId = OBJECT_TABLEID_MAP[parentObject];
					tempLinkInfoObject[parentTableId] = parentRecordId;
					setLinkInfo(tempLinkInfoObject);
				}
				if (object === constants.TASKS && parentObject === constants.PROJECTS_OBJECT) {
					getProjectDepTasksListFn(parentObject);
				}
			}

			if (isValidParam(parentLinkInfo) && getBooleanParam(isCompleAndNew)) {
				let tempParentLinkInfo = parentLinkInfo;
				setLinkInfoVal(tempParentLinkInfo);
				if (isValidParam(tempParentLinkInfo)) {
					let linkInfoArr = tempParentLinkInfo.split(",");
					for (let i = 0; i < linkInfoArr.length; i++) {
						let objectInfo = linkInfoArr[i].split("_");
						tempLinkInfoObject[objectInfo[0]] = objectInfo[1];
					}
				}
				updateState(true, fields, recordDetails);
				setRecord(recordDetails);
			}

			if (callFrom === "queueView") {
				let formData = sfForm.data;
				let isOppField = getBooleanParam(
					formData.hasOwnProperty[constants.OPPORTUNITIES_OBJECT]
				);
				if (!isOppField) {
					let opportunitiesPromise = SFFormActions.getSFFormFieldsPromise(
						constants.OPPORTUNITIES_OBJECT
					);
					opportunitiesPromise.then((response) => {
						if (isValidParam(response)) {
							sfForm.data[constants.OPPORTUNITIES_OBJECT] = response;
						}
					});
				}
			}
		}

		if (
			object === constants.PAYMENTS_OBJECT &&
			// getBooleanParam(isDetailView) &&
			tempParentDetails.hasOwnProperty('parent_object') &&
			getStringParam(tempParentDetails.parent_object) === constants.JOBS_OBJECT
		) {

			getQuoteList({ job_id: parentDetails.parent_record_id });
		}
		setLinkInfoObject({ ...tempLinkInfoObject });

	};

	const checkIsPaymentConnected = () => {
		try {
			const url = endPoints.PAYMENT_INTEGRATION.CHECK_CONNECT;
			HTTPClient.post(url, {}).then((res) => {
				if (res && res.data && res.data.flag === true) {
					setIsPaymentConnected(true);
					setPaymentProvider('Stripe');
				} else if (res && res.data && res.data.flag === false && res.data.paymentMethod === 'MONERIS') {
					setIsPaymentConnected(true);
					setPaymentProvider('Moneris');
				}
				 else {
					setIsPaymentConnected(false);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'quickBooksIntegration.js -> checkIsConnected()':" + error
			);
		}
	};

	const handleCreditCardPayment =  () => {
		if (isPaymentConnected && paymentProvider === 'Moneris') {
			const params = {};
			if (changeLog.lookupInfoState.length > 0) {
				params["quotation_id"] = changeLog.lookupInfoState[0]["record_id"];
			} else {
				let activeTabInfo = getObjectParam(getActiveTabInfo());
				params["quotation_id"] = getIntParam(activeTabInfo.quotation_id);
			}
			try {
				dispatch(setMonerisCheckoutQuoteId(params["quotation_id"]));
				dispatch(setMonerisCheckout(true));

			} catch (error) {
				console.error(error);
			}

			return;
		}
		setPaymentButtonDisabled(true);

		let params = Object();
		let url = endPoints.PAYMENT_INTEGRATION.PREPARE_PAYMENT_DATA;
		try {
			if (changeLog.lookupInfoState.length > 0) {
				params["quotation_id"] = changeLog.lookupInfoState[0]["record_id"];
			} else {
				let activeTabInfo = getObjectParam(getActiveTabInfo());
				params["quotation_id"] = getIntParam(activeTabInfo.quotation_id);
			}
			if (
				detailView.isDetailView &&
				object === constants.PAYMENTS_OBJECT &&
				parentDetails.parent_object === constants.JOBS_OBJECT
			) {
				let quotation_number = fieldProps["quoteno"].value;
				let quoteObj = invoiceList.filter((f) => {
					return f.quoteno === quotation_number;
				});
				params["quotation_id"] =
					quoteObj.length > 0 ? getIntParam(quoteObj[0].id) : 0;
			}
			params.payment_method_type = fieldProps["payment_type"].value;
			let promise = Promise.resolve(HTTPClient.get(url, params));
			promise
				.then((res) => {
					res = getObjectParam(res);
					let balanceDue = getFloatParam(res.balanceDue);

					let tempfieldProps = fieldProps;
					tempfieldProps["payment_value"].value = balanceDue;
					setFieldProps(tempfieldProps);
					if (balanceDue > 0) {
						let url = getStringParam(res.paymentURL);
						let checkPaymentStatusUrl =
							endPoints.PAYMENT_INTEGRATION.GET_PAYMENT_STATUS_QUOTATION;
						let openQBWindow = openWindow({
							location: url,
							spaces: "scrollbars=1, location=no",
							width: 850,
							height: 650,
						});
						let intervalVariable = setInterval(() => {
							if (openQBWindow !== null && !openQBWindow.closed) {
								HTTPClient.get(checkPaymentStatusUrl, params)
									.then((response) => {
										response = getObjectParam(response);
										let flag = getStringParam(response.paymentStatus);
										if (flag === "Paid" || flag === "Processing") {
											clearInterval(intervalVariable);
											openQBWindow.close();
											setPaymentButtonDisabled(false);

											dispatch(refreshDetailViewListViewData(
												true,
												getIntParam(recordId)
											));
											dispatch(refreshQuotation(true));
											closeDrawer("creditcard");
										}
									})
									.catch((exception) => {
										setPaymentButtonDisabled(false);

										console.error(
											`Error while calling paymentStatus API in sfFrom->handleCreditCardPayment`
										);
										console.error(exception);
										clearInterval(intervalVariable);
									});
							} else if (openQBWindow !== null && openQBWindow.closed) {
								clearInterval(intervalVariable);
								setPaymentButtonDisabled(false);

							}
						}, 5000);
					} else {
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							"The payment you are entering exceeds the balance due for this invoice."
						);
						setPaymentButtonDisabled(false);

					}
				})
				.catch((e) => {
					console.error("API error at handleCreditCardPayment");
					console.error(e);
					setPaymentButtonDisabled(false);

				});
		} catch (e) {
			console.error("Problem occured in sfForm -> handleCreditCardPayment");
			console.error(e);
			setPaymentButtonDisabled(false);

		}
	};

	const isAssignmentRuleExists = (field) => {
		let paramObj = { table_id: OBJECT_TABLEID_MAP[object] };
		const response = Promise.resolve(isAssignRuleExists(paramObj));
		response.then((response) => {
			if (response.hasOwnProperty("isExists")) {
				if (getBooleanParam(response.isExists)) {
					//field.list_values.unshift({id: 'Use Assignment Rule', value: 'Use Assignment Rule'})
					let temp = changeLog;
					temp.isAssignRule = true;
					setchangeLog({ ...temp })
					setListValueMenuItems(field);
				}
			}
		});
	};

	const getDataAndProcess = () => {

		let isNoteObjectLinkValue = (app.selectedModuleName === constants.MODULE_HUB) ? false : isLinkedChildObject(object, constants.NOTES);
		setIsNoteObjectLink(isNoteObjectLinkValue);

		let isConvertContact = isValidParam(appContainer.drawerProps.data) && getBooleanParam(appContainer.drawerProps.data.is_convert);
		setIsConvert(isConvertContact);
		let log = changeLog;
		log.isConvert = isConvertContact;
		setchangeLog({ ...log });
		let dataVal = Object.assign({}, sfForm.data[object]);
    

		if (object === constants.TASKS && appContainer.drawerProps.data.isUnitsAccount && app.me.is_franchisor == false) {
			let tab = getActiveTab();
			if (appContainer.drawerProps.data.isUnitsAccount) {
				tab.info = { ...tab.info, isUnitsAccount: appContainer.drawerProps.data.isUnitsAccount, UnitsAccountProjectId: appContainer.drawerProps.data.UnitsAccountProjectId };
				updateActiveTab(tab);
			}
			getFormData('other');

		}
		else {
			if (Object.keys(dataVal).length === 0) {
				getFormData();
			} else if(Object.keys(dataVal)?.length == 1 && (object ==='appointments' || object ==='tasks')){
				let _availableFields = sfForm.data[object]?.fields
				if(_availableFields.length == 0)
				getFormData();
		   }else {
				let fieldsValue = getArrayParam(dataVal.fields);
				fieldsValue.forEach((f) => {
					if ((f.field_type === constants.FIELD_TYPE_DATE_TIME || f.field_type === constants.FIELD_TYPE_DATE)
						&& isValidParam(f.default_value) && f.default_value !== ""
					) {
						let currentTime = moment().tz(timeZone[app.me.timezone]);
						currentTime = currentTime.format(
							dateFormat[app.me.date_format]
						);
						let defaultDate = moment(f.default_value).format(
							dateFormat[app.me.date_format]
						);
						if (defaultDate === currentTime) {
							// This condition used for default value : 'CURRENT_DATE+N'
							currentTime = moment().tz(timeZone[app.me.timezone]);
							defaultDate = currentTime.format(
								dateTimeFormat[app.me.date_format]
							);
							f.default_value = defaultDate === "Invalid date" ? "" : defaultDate;
						}
					}
					if (object == 'cases' && childListview.mounted && app.me.is_franchisor) {
						if (f.field_type == 'text' && f.name == "from_email") {
							f.field_type = 'list';
						}
						if (f.name == "from_email") {
							f.list_type = "regular";

						}
						let _contactRecords = changeLog.contactRecords
						let contactlist = getArrayParam(detailView?.data?.avaliableContacts);
						if (isDetailView) {
							let emailList = [];
							contactlist.map((m) => {
								return emailList.push(m.email)
							})
							if (_contactRecords.length == 0 && contactlist.length > 0) {
								if (emailList.length > 0 && f.name == "from_email") {
									f.list_values = emailList.map(email => ({ id: email, value: email }))
								}
							}
						}
					} else if (object == 'cases' && f.name == "from_email") {
						f.field_type = 'text';
						f.list_type = "";
					}

				});

				//***
				dataVal.fields = fieldsValue;
				sfForm.data[object] = Object.assign({}, dataVal);

				if (object === constants.GROUP_OBJECT) {
					fieldsValue = fieldsValue.filter(
						(f) =>
							f.name !== "created_on" &&
							f.name !== "updated_by" &&
							f.name !== "updated_on"
					);
					dataVal.fields = fieldsValue;
				}
				processFormData(dataVal, openMode);
			}
		}
		if (
			object === constants.ACCOUNTS_OBJECT ||
			object === constants.CONTACTS_OBJECT
		) {
			getGroups();
		}

		
		if (
			defaultRule === null &&
			(object === constants.ACCOUNTS_OBJECT ||
				object === constants.CONTACTS_OBJECT ||
				object === constants.OPPORTUNITIES_OBJECT
			||  object === "customobjectone" ||
			object === "customobjecttwo" ||
			object === "customobjectthree" ||
			object === "customobjectfour" ||
			object === constants.PARTNERS_OBJECT
			)
		) {
			getDublicateDefaultRules(object);
		}

		
	};

	const getGroups = () => {
		let hasPermission = false;
		try {
			let groupsVal = isValidParam(groups) ? Object.assign([], groups) : [];
			hasPermission = hasAccessPermission(
				object,
				constants.SECURITY_LEVEL_TYPE_PERMISSION,
				constants.PERMISSION_NAME_ADD_TO_LIST
			);
			if (groupsVal.length === 0) {
				let promise = Promise.resolve(getMyGroups(object));
				if (isValidParam(promise)) {
					promise.then((response) => {
						if (isValidParam(response)) {
							dispatch(setGroups(response));

							let tempGroups = response.groups.filter((f) => (f.status === "Active"));
							tempGroups = tempGroups.map((m) => ({ ...m, label: m.name }));

							if (hasPermission) {
								tempGroups.push({
									id: -9999,
									name: "Add to This List",
									status: "Active",
									label: "----Add to this List----",
								});
							}
							setStateGroups(tempGroups);
						}
					});
				}
			} else {
				groupsVal = groupsVal.filter((f) => (f.status === "Active"));
				let tempGroups = groupsVal.map((m) => ({ ...m, label: m.name }));
				if (hasPermission) {
					tempGroups.push({
						id: -9999,
						name: "Add to This List",
						status: "Active",
						label: "----Add to this List----",
					});
				}
				setStateGroups(tempGroups);

			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> getGroups()':" + error);
		}
	};

	const updateLinkInfo = (linkInfoValue) => {
		setApplinkInfo(linkInfoValue);
	};

	const setLinkInfo = (linkInfoObject) => {
		let parentObject = getStringParam(parentDetails.parent_object).trim();
		let parentTableId = 10;
		if (parentObject !== "") {
			parentTableId = OBJECT_TABLEID_MAP[parentObject];
		}
		if (linkInfoObject) {
			let tempLinkInfoVal = "";
			let value = linkInfoObject[parentTableId];
			if (isValidParam(value)) {
				tempLinkInfoVal = parentTableId + "_" + value;
			}
			value = null;
			for (const linkInfoKey in linkInfoObject) {
				if (linkInfoKey === (parentTableId + "")) {
					continue;
				}
				value = linkInfoObject[linkInfoKey];
				if (isValidParam(value)) {
					if (tempLinkInfoVal === "") {
						tempLinkInfoVal = linkInfoKey + "_" + value;
					} else {
						tempLinkInfoVal += "," + linkInfoKey + "_" + value;
					}
				}
				value = null;
			}
			if (object === constants.APPOINTMENTS) {
				if (isValidParam(applinkInfo) && applinkInfo !== "") {
					tempLinkInfoVal = applinkInfo;
				}
			}

			if (isValidParam(tempLinkInfoVal)) {
				setLinkInfoVal(tempLinkInfoVal);
			}
		}
	};

	const checkFieldProps = () => {
		try {
			let isEqual = _.isEqual(fieldProps, preFieldProps);

			setPreFieldProps(_.cloneDeep(getObjectParam(fieldProps)));
				setRequiredFields(changeLog.allRequiredFields, fieldProps, allRequiredFields);
			

		} catch (error) {
			console.error("Error in 'sfForm.js -> checkFieldProps()':" + error);
		}
	};

	const addFloatingLabelEvent = () => {
		let fieldsValue = fields.filter((f) => {
			return (
				f.field_type === constants.FIELD_TYPE_DATE_TIME ||
				f.field_type === constants.FIELD_TYPE_DATE
			);
		});
		fieldsValue.forEach((f) => {
			if (isValidParam(fieldProps[f.name])) {
				let value = fieldProps[f.name].value;
				if (value !== "") {
					let dateTimeDiv = document.getElementsByClassName(
						"dateTimeDiv-" + f.name
					);
					if (callFrom === "queueView") {
						dateTimeDiv = document.getElementsByClassName(
							"dateTimeQueueDiv-" + f.name
						);
					}
					if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
						dateTimeDiv[0].classList.add("label-top");
					}
				}
			}
		});
	};

	const setFormHeader = () => {
		let kanbanObject = {};
		kanbanObject.currentView = null;
		kanbanObject.show = false;
		dispatch(setHeader(
			object,
			appContainer.drawerProps.actionDialogName,
			kanbanObject,
			false,
			true
		));
	};

	const getFormData = () => {

		try {
			const promise = SFFormActions.getSFFormFieldsPromise(object);
			promise.then((response) => {
				if (isValidParam(response)) {
					let dataVal = response;
					if (object == 'cases' && app.me.is_franchisor) {
						let fieldsValue = getArrayParam(dataVal.fields);
						
						fieldsValue.forEach((f) => {
							if (isDetailView) {
								let _contactRecords = changeLog.contactRecords
								let contactlist = getArrayParam(detailView?.data?.avaliableContacts);
								if (_contactRecords.length == 0 && contactlist?.length > 0) {
									let emailList = []
									contactlist.map((m) => {
										return emailList.push(m.email)
									})
									if (emailList.length > 0 && f.name == "from_email") {
										f.list_values = emailList.map(email => ({ id: email, value: email }))
									}
								}

							}
                         if(f.list_values.length > 0 && f.name == "from_email"){
							if (f.field_type == 'text') {
								f.field_type = 'list';
							}
							if (f.name == "from_email" ) {
								f.list_type = "regular";
							}
							
						 }else{
							if (f.field_type == 'list' && f.name == "from_email") {
								f.field_type = 'text';
							}
							if (f.name == "from_email" ) {
								f.list_type = "";

							}
						 }
						

						});
						dataVal.fields = fieldsValue;
					}
					sfForm.data[object] = Object.assign({}, dataVal);
					processFormData(response, openMode);
				}
			});
		} catch (error) {
			console.error("Error in 'sfForm.js -> getFormData()':" + error);
		}
	};
	const isProjectTasksFn = () => {
		let isProjectTask = false;
		try {
			if (callFrom != 'queueView' && object == constants.TASKS && parentDetails.hasOwnProperty('parent_object')) {
				if (parentDetails.parent_object == constants.PROJECTS_OBJECT) {
					isProjectTask = true;
				}
			}
		} catch (error) {
			console.error("error in sfForm.js ->'isProjectTasks()'" + error);
		}
		return isProjectTask;
	}
	const processFormData = (dataVal, openModeVal) => {
		let taskFilter = true;
		let moduleAccess = getObjectParam(app.me.module_access);
		let tempfieldArr = fields;
		try {
			if (isValidParam(dataVal)) { 
				if (openModeVal === constants.SF_FORM_OPEN_MODE_QUICK) {
					if (object === constants.OPPORTUNITIES_OBJECT) {
						dataVal.quickadd_fields = rearrangeFields(dataVal.quickadd_fields);
					}
					dataVal.quickadd_fields.forEach((fieldName) => {
						let tempField = find(dataVal.fields, (field) => (fieldName === field.name));
						if (isValidParam(tempField)) {
							if (
								object === constants.PAYMENTS_OBJECT &&
								tempField.name === "parent_paymentno"
							) {
								if (paymentType === "Refund") {
									tempfieldArr.push(tempField);
								}

							} else if (
								object === constants.PAYMENTS_OBJECT &&
								tempField.name === "quoteno"
							) {
								if (paymentType === "Refund") {

								} else {
									tempfieldArr.push(tempField);
								}

							} else {
								if (
									object === constants.PAYMENTS_OBJECT &&
									paymentType === "Refund"
								) { } else {
									tempfieldArr.push(tempField);
								}
							}
						}
					});

					if (object === constants.OPPORTUNITIES_OBJECT) {
						tempfieldArr = addTemporaryFields(tempfieldArr, openModeVal);
					}
				} else if (openModeVal === constants.SF_FORM_OPEN_MODE_EXPANDED) {
					tempfieldArr = filter(dataVal.fields, function (field) {
						if (field.field_type === constants.FIELD_TYPE_HEADER) {
							return field;
						} else {
							return find(dataVal.expanded_fields, function (expandedField) {
								return expandedField === field.name;
							});
						}
					});

					if (
						object === constants.CASES_OBJECT &&
						getStringParam(franchiseId) !== ""
					) {
						tempfieldArr.forEach((field) => {
							if (field.name === "custom_field3") {
								setFranchiseId(field.default_value);
							}
						});
					}
					if (object === constants.CONTACTS_OBJECT) {
						let fullNameField = fields.filter((f) => {
							return f.name === "full_name";
						});
						let fullName = "";
						if (fullNameField.length > 0) {
							let firstName = "";
							let lastName = "";
							if (fieldProps.hasOwnProperty("first_name")) {
								firstName = fieldProps["first_name"].value;
							}
							if (fieldProps.hasOwnProperty("last_name")) {
								lastName = fieldProps["last_name"].value;
							}
							fullName = firstName + " " + lastName;
						}
						tempfieldArr.forEach((field) => {
							if (field.name === "full_name") {
								field.default_value = fullName;
							}
						});

					}

					if (object === constants.OPPORTUNITIES_OBJECT) {
						tempfieldArr = tempfieldArr.filter((field) => (field.name !== "contact_name" && field.name !== "company"));
						tempfieldArr = addTemporaryFields(tempfieldArr, openModeVal);
					}
				} else if (openModeVal === constants.SF_FORM_OPEN_MODE_CUSTOM) {
					let customFields;
					if(dataVal?.custom_fields != undefined){
						 customFields = JSON.parse(JSON.stringify(dataVal?.custom_fields));
					}
					customFields = getArrayParam(customFields);

					// Remove fullname from task and appointment form if contact object is selected as none
					let objectListOne = getArrayParam(app.objectList);
					objectListOne = objectListOne.filter((f) => {
						return f.name === constants.CONTACTS_OBJECT;
					});
					let contactHomeId =
						objectListOne.length > 0 ? objectListOne[0].homeId : -1;
					if (contactHomeId === 0) {
						customFields = customFields.filter((f) => {
							return f !== "fullname";
						});
					}

					// Remove opportunity from task and appointment form if opportunity object is selected as none
					let objectListTwo = getArrayParam(app.objectList);
					objectListTwo = objectListTwo.filter((f) => {
						return f.name === constants.OPPORTUNITIES_OBJECT;
					});
					let opportunityHomeId =
						objectListTwo.length > 0 ? objectListTwo[0].homeId : -1;
					if (opportunityHomeId === 0) {
						customFields = customFields.filter((f) => {
							return f !== "opportunity_name";
						});
					}

					if (
						object === constants.APPOINTMENTS &&
						parentDetails.parent_object === constants.JOBS_OBJECT &&
						isValidParam(customFields)
					) {
						customFields = customFields.filter((f) => {
							return f !== "fullname" && f !== "opportunity_name";
						});
					}

					if (
						object === constants.NOTE_OBJECT &&
						isValidParam(customFields) &&
						(app.selectedModuleName ===
							constants.MODULE_OPERATIONS ||
							app.selectedModuleName === constants.MODULE_HUB)
					) {
						if (parentDetails && parentDetails.hasOwnProperty("parent_object")) {
							if (parentDetails.parent_object === constants.CASES_OBJECT) {
								customFields = customFields.filter((f) => {
									return f !== "note_type";
								});
							}
						}
					}


					if(object === constants.NOTE_OBJECT && isValidParam(customFields)) {
						if (parentDetails && parentDetails.hasOwnProperty("parent_object")) {
							if ((parentDetails.parent_object !== constants.CASES_OBJECT) ||
								 (parentDetails.parent_object === constants.CASES_OBJECT && 
									app.me.is_franchisor == false && app.me.is_franchisee == true)) {
								customFields = customFields.filter((f) => {
									return f !== "t_visibility";
								});
							}
						}
					}

					let tempFields = filter(dataVal.fields, function (field) {
						return find(customFields, function (fld) {
							return fld === field.name;
						});
					});

					if (
						object === constants.TASKS &&
						isValidParam(tempFields) &&
						callFrom !== "queueView"
					) {
						if (isProjectTasksFn()) {
							taskFilter = false;
							setTempFieldForProjectTask(tempFields);
							setTempCustomField(customFields);
							let isOperations = moduleAccess[constants.MODULE_OPERATIONS];
							tempFields = tempFields.filter((f) => {
								if (isOperations === 1) {
									if (f.name === "company") {
										f.label = "Unit";
									}
									return f.name !== "t_dueby" && f.name !== "fullname";
								} else {
									return (
										f.name !== "t_dueby" &&
										f.name !== "company" &&
										f.name !== "fullname"
									);
								}
							});
						}
					}
					if (object === constants.TASKS) {
						let tempTwoData = tempFields.map((e) => {
							if (e.name === "task_type" && e.default_value !== "") {
								e.default_value = "";
							}
							return e;
						});
						tempFields = tempTwoData;
					}
					if (object === constants.TASKS && taskFilter) {
						tempFields = tempFields.filter((f) => {
							if (f.name === "company") {
								f.label = "Company";
							}
							return f.name !== "dep_start_date" && f.name !== "duration";
						});
					}

					if (
						callFrom === "queueView" &&
						(object === constants.TASKS ||
							object === constants.APPOINTMENTS)
					) {
						customFields.forEach((f) => {
							let searchFields = tempFields.filter((fld) => {
								return fld.name === f;
							});
							let searchField =
								searchFields.length > 0 ? searchFields[0] : null;
							if (isValidParam(searchField)) {
								appTaskCustomFields.push(searchField);
							}
						});
						let linkInfoFld = {};
						linkInfoFld.name = "link_info";
						appTaskCustomFields.push(linkInfoFld);
						setAppTaskCustomFields(appTaskCustomFields);
						let appTaskFields = getArrayParam(dataVal.queueFields);
						if (isValidParam(appTaskFields)) {
							customFields = appTaskFields;
						}
					}

					customFields.forEach((f) => {
						let searchFields = tempFields.filter((fld) => {
							return fld.name === f;
						});
						let searchField = searchFields.length > 0 ? searchFields[0] : null;
						if (isValidParam(searchField)) {
							tempfieldArr.push(searchField);

						}
					});
					// setFields(tempfieldArr);

					setShowReminder(dataVal.show_reminder);
				}
				getRecord(tempfieldArr);
				setFields(tempfieldArr)
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> processFormData()':" + error);
		}
	};

	const getRequiredFormFieldsData = (comapnyName)=>{
		setComapnyNameFromRequiredFrom(comapnyName);
	}

	const setWFMenuItemList = (workflowId, name, queryName, listType, fieldPropsValue, tempFields) => {
		try {
			if (workflowId > 0) {
				fieldPropsValue = isValidParam(fieldPropsValue) ? fieldPropsValue : fieldProps;
				let params = {};
				params.object = object;
				params.id = workflowId;
				params.name = name;
				params.query_name = queryName;
				params.query_type = "customQuery";
				params.list_type = listType;

				const promise = SFFormActions.getFieldListValuesPromise(params);
				promise.then((response) => {
					if (isValidParam(response)) {
						setListValueMenuItems({
							name: "t_status",
							list_values: response,
							is_required_field: false,
						});
						if (getIntParam(recordId) === 0) {
							fieldPropsValue["t_status"].value = response.length > 0 ? getStringParam(response[0].value).trim() : "";
						}
						setListValueMenuItemsValue(listValueMenuItems);

						if (object === constants.OPPORTUNITIES_OBJECT) {
							setOpportunityStatusList(workflowId, fieldPropsValue);
						}
						getRequiredFields(fieldPropsValue);
					}
				});
			} else {
				if (object === constants.CONTACTS_OBJECT) {
					let field = getObjectParam(fieldPropsValue["t_status"]);
					setListValueMenuItems(field);
					if (
						getIntParam(recordId) === 0 &&
						fieldPropsValue["t_status"] !== undefined
					) {
						if(fieldPropsValue["t_status"].value == '' || fieldPropsValue["t_status"].value == null ||fieldPropsValue["t_status"].value == undefined)
							fieldPropsValue["t_status"].value = "Lead";
						 }
					
				}
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> setWFMenuItemList()':" + error);
		}
		return fieldPropsValue;
	};

	const setOpportunityStatusList = (workflowId, tempFieldProps) => {
		if (object === constants.OPPORTUNITIES_OBJECT) {
			let params = {};
			params.workflow_id = workflowId;
			let promise = SFFormActions.getWorkflowStatusDetailsList(params);
			if (isValidParam(promise)) {
				promise.then((response) => {
					calculateOpportynities(tempFieldProps, response);
					setOpportunityStatusListValue(response);
				});
			}
		}
	};

	const calculateOpportynities = (fieldPropsValue, opportunityStatusListValue) => {
		try {
			fieldPropsValue = isValidParam(fieldPropsValue) ? fieldPropsValue : fieldProps;
			opportunityStatusListValue = isValidParam(opportunityStatusListValue) ? opportunityStatusListValue : opportunityStatusList;
			if (isValidParam(fieldPropsValue)) {
				if (opportunityStatusListValue.length === 0) {
					let workflowId = getIntParam(fieldPropsValue["workflow_id"].value);
					fieldPropsValue = setWFMenuItemList(
						workflowId,
						fieldPropsValue["t_status"].name,
						fieldPropsValue["t_status"].queryName,
						fieldPropsValue["t_status"].listType,
						fieldPropsValue
					);
					setFieldProps(fieldPropsValue);
				}
				if (opportunityStatusListValue.length > 0) {

					let arrCurrentStatus = opportunityStatusListValue.filter((f) => {
						return f.name === fieldPropsValue["t_status"].value;
					});
					let currentStatus = arrCurrentStatus.length > 0 ? arrCurrentStatus[0] : null;
					let probabilityValue = isValidParam(currentStatus)
						? getFloatParam(currentStatus.probability, 1)
						: 0;
					if (fieldPropsValue.hasOwnProperty("probabilityValue")) {
						fieldPropsValue["probabilityValue"].value = probabilityValue;
					} else {
						let log = changeLog;
						setchangeLog({ ...log, probability: probabilityValue })
						setProbability(probabilityValue);
					}

					let oppAmnt = 0;
					if (fieldPropsValue.hasOwnProperty("opportunity_amount")) {
						oppAmnt = getFloatParam(fieldPropsValue["opportunity_amount"].value);
					}

					let forCastValue = (oppAmnt * probabilityValue) / 100;
					if (fieldPropsValue.hasOwnProperty("forecast_amount")) {
						fieldPropsValue["forecast_amount"].value = getFloatParam(forCastValue);
					} else {

						setForCastVal(getFloatParam(forCastValue));
						let log1 = changeLog;
						setchangeLog({ ...log1, forCastVal: forCastValue })
					}
					setFieldProps(fieldPropsValue);
				}
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> calculateOpportynities()':" + error
			);
		}
	}

	const setStateMenuItemList = (value, fieldPropsValue) => {
		fieldPropsValue = isValidParam(fieldPropsValue) ? fieldPropsValue : fieldProps;
		try {
			const _name = "state"
			let params = {};
			params.object = object;
			params.name = _name;
			params.value = value;
			params.list_type = fieldPropsValue[params.name].listType;
			if (fieldPropsValue[params.name] != null) {
				params.list_type = fieldPropsValue[params.name].listType;
			}
			if (value !== null && value !== undefined && value !== "") {
				const promise = SFFormActions.getFieldListValuesPromise(params);
				promise.then((response) => {
					if (isValidParam(response)) {
						let isRequired = getBooleanParam(
							fieldPropsValue["state"].is_required_field
						);
						response.unshift({id: '', value: ''})
						fieldPropsValue[params.name].list_values = response;
						setListValueMenuItems({
							name: params.name,
							list_values: response,
							is_required_field: isRequired,
						});
						if (getIntParam(recordId) <= 0) {
							let selectedValue = response.filter(
								(f) => f.value === value
							);
							fieldPropsValue[params.name].value =
								selectedValue.length > 0
									? selectedValue[0].value
									: response[1].value;
							if (
								fieldPropsValue["country"].value === "India" &&
								fieldPropsValue.hasOwnProperty("city") &&
								fieldPropsValue["city"].fieldType ===
								constants.FIELD_TYPE_LIST
							) {
								fieldPropsValue["city"].value = "";
								fieldPropsValue = setCityMenuItemList(fieldPropsValue["state"].value, fieldPropsValue);
							}
						}
						setListValueMenuItemsValue(listValueMenuItems)
						handleForceupdateMethod();
					}
				});
			} else {
				let isRequired = getBooleanParam(
					fieldPropsValue["state"].is_required_field
				);

				setListValueMenuItems({
					name: params.name,
					list_values: [],
					is_required_field: isRequired,
				});
				fieldPropsValue[params.name].value = "";
				setListValueMenuItemsValue(listValueMenuItems)

			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> setStateMenuItemList()':" + error);
		}
		return fieldPropsValue;
	};

	const setSecondarySourceMenuItemList = (value, fieldPropsValue,action) =>{
		try{
			fieldPropsValue = isValidParam(fieldPropsValue) ? fieldPropsValue : fieldProps;
			let params = {};
			params.object = object;
			params.name = "sc_source";
			params.value = value;
			params.list_type = 'external';
			params.query_name = "SecSourceByPrimarySource";
			params.query_type = 'customQuery';
			
			if (value !== null && value !== undefined && value !== "") {
					const promise = SFFormActions.getFieldListValuesPromise(params);
					promise.then((response) => {
						if (isValidParam(response)) {
							let isRequired = getBooleanParam(
								fieldPropsValue["sc_source"].is_required_field
							);
							fieldPropsValue["sc_source"].list_values = response ? response : [];
							fieldPropsValue["source"].value = value;
							setFieldProps({ ...fieldPropsValue });
							sfForm.sfFromData = fieldPropsValue;
							setListValueMenuItems({
								name: params.name,
								list_values: response,
								is_required_field: isRequired,
							});
							if (getIntParam(recordId) <= 0) {
								let selectedValue = response.filter(
									(f) => f.value === fieldPropsValue[params.name]?.value
								);
								fieldPropsValue[params.name].value =
									selectedValue.length > 0
										? selectedValue[0].value
										: response[0]?.value;


								// if (
								// 	fieldPropsValue["sc_source"].value &&
								// 	fieldPropsValue.hasOwnProperty("sc_source") && response.length == 0 
								// ) {
								// 	fieldPropsValue["sc_source"].value = "";
								// 	// fieldPropsValue = setCityMenuItemList(fieldPropsValue["state"].value, fieldPropsValue);
								// }

								// setFieldProps(fieldPropsValue);
							}
							if(action === 'onChange'){
								if(response.length > 0){
									fieldPropsValue["sc_source"].value = response[0]?.value;
								}else{
									fieldPropsValue["sc_source"].value = "";
								}

								fieldPropsValue["sc_source"].list_values = response ? [...response] : [];
								fieldPropsValue["source"].value = value;
								setFieldProps({...fieldPropsValue});
							}
							setListValueMenuItemsValue(listValueMenuItems)
	
	
							handleForceupdateMethod();
						}
					});
			} else {
				let isRequired = getBooleanParam(
					fieldPropsValue["sc_source"].is_required_field
				);

				setListValueMenuItems({
					name: params.name,
					list_values: [],
					is_required_field: isRequired,
				});
				fieldPropsValue[params.name].value = "";
				setListValueMenuItemsValue(listValueMenuItems)

			}	
		}catch (error) {
			console.error("Error in 'sfForm.js -> setSecondarySourceMenuItemList()':" + error);
		}
		return fieldPropsValue;
	}
	const setCityMenuItemList = (value, fieldPropsValue) => {
		fieldPropsValue = isValidParam(fieldPropsValue) ? fieldPropsValue : fieldProps;
		try {
			let params = {};
			params.object = object;
			params.name = "city";
			params.value = value;
			params.list_type = fieldPropsValue[params.name].listType;

			const promise = SFFormActions.getFieldListValuesPromise(params);
			promise.then((response) => {
				if (isValidParam(response)) {
					let isRequired = getBooleanParam(
						fieldPropsValue["city"].is_required_field
					);
					setListValueMenuItems({
						name: params.name,
						list_values: response,
						is_required_field: isRequired,
					});
					if (getIntParam(recordId) <= 0 && response.length !== 0) {
						fieldPropsValue[params.name].value = response[0].value;
					}

					setListValueMenuItemsValue(listValueMenuItems)
					handleForceupdateMethod();
				}
			});
		} catch (error) {
			console.error("Error in 'sfForm.js -> setCityMenuItemList()':" + error);
		}
		return fieldPropsValue;
	};

	const getRecord = (_tempFieldArr) => {
		let params = {};
		let parentObject = null;
		let parentRecordId = 0;
		let isColumnView = false;
		let promise = null;
		try {
			params.object = object;
			if (
				isValidParam(parentDetails) &&
				parentDetails.hasOwnProperty("parent_object") &&
				parentDetails.hasOwnProperty("parent_record_id")
			) {
				parentObject = getStringParam(parentDetails.parent_object).trim();
				parentRecordId = getIntParam(parentDetails.parent_record_id);
				if (parentObject !== "" && parentRecordId > 0) {
					params.parent_object = parentObject;
					params.parent_record_id = parentRecordId;
				}
			}

			let tab = getActiveTab();

			if (object === constants.TASKS) {
				if (tasksObjParam.isUnitOwnerTasks) {
					let unitsAccountProjectId = 0;
					let dataValue = getObjectParam(appContainer.drawerProps.data);
					if (dataValue != null && dataValue.hasOwnProperty('callFrom')
						&& dataValue?.callFrom == 'unit_list_view_task') {
						unitsAccountProjectId = dataValue.myClientId;
					} else {
						unitsAccountProjectId = detailView.data?.record?.myclient_Id ? detailView.data?.record?.myclient_Id : my_clientid;
					}
					tab.info = {
						...tab.info, isUnitsAccount: true,
						UnitsAccountProjectId: unitsAccountProjectId
					};
					if (recordId > 0) {
						params.isUnitsAccount = true;
						params.UnitsAccountProjectId = unitsAccountProjectId;
					}
				}
				updateActiveTab(tab);
			}
			if (object === constants.TASKS) {
				let taskParamObj = getObjectParam(tasksObjParam);
			}
			if (paymentType === "Refund") {
				params.call_from = "Refund_Payment";
				params.parentPaymentId = parentPaymentId;
			}
			if (isValidParam(parentDetails) && parentDetails.hasOwnProperty("isColumnView")) {
				isColumnView = getBooleanParam(parentDetails.isColumnView);
			}
			const tempRecordId = (isCompleAndNew || isFromJobScheduler) ? 0 : recordId;
			const tempParentDetails = getObjectParam(parentDetails);
			let _parentObject = getStringParam(tempParentDetails.parent_object).trim();
			if (object === constants.TASKS && _parentObject === constants.TASKS) {
				let dataValue = getObjectParam(appContainer.drawerProps.data);
				if (dataValue?.UnitsAccountProjectId != undefined && dataValue?.UnitsAccountProjectId != null && dataValue?.UnitsAccountProjectId != '') {
					params.UnitsAccountProjectId = dataValue?.UnitsAccountProjectId;
					delete params['object'];
					delete params['parent_object'];
				} else {
					params.UnitsAccountProjectId = my_clientid;
					delete params['object'];
					delete params['parent_object'];
				}

			}
			if (tempRecordId > 0 || isDetailView || isColumnView) {
				promise = SFFormActions.getRecordPromise(
					object,
					tempRecordId,
					params
				);
			} else if (changeLog.isConvert) {
				promise = SFFormActions.getContactConvertRecord(params);
			}
			if ((isDetailView || isColumnView) && tempRecordId === 0) {
				if (object === constants.APPOINTMENTS) {
					if (
						parentObject !== null &&
						parentObject === constants.ACCOUNTS_OBJECT
					) {
						let templinkInfoObject = linkInfoObject;
						templinkInfoObject[OBJECT_TABLEID_MAP[parentObject]] = parentRecordId;
						setLinkInfoObject({ ...templinkInfoObject });

						setAttendeeRecordId(linkInfoObject[OBJECT_TABLEID_MAP[parentObject]]);
					}
				}
			}

			if (promise !== null) {
				promise.then((response) => {
					if (isValidParam(response)) {
						if (isValidParam(response.records)) {
							let tempRecord = response.records;
							setRecord(response.records);
							let _unitTaskType = unitTaskType;
							_unitTaskType.t_unit_owner = response.records.t_unit_owner;
							_unitTaskType.task_type = response.records.task_type;
							_unitTaskType.unit_owner = response.records?.t_owner;
							setUnitTaskType({ ..._unitTaskType });

							if (
								tempRecordId === 0 &&
								parentObject !== null &&
								parentObject === constants.ACCOUNTS_OBJECT &&
								object === constants.APPOINTMENTS
							) {
								if (
									record !== null &&
									record.hasOwnProperty("assign")
								) {
									tempRecord["assign"] = app.me.name;
									setRecord(tempRecord);
								}
							}


							if (
								callType &&
								callType === "jobscheduler" &&
								recordDetails && object === constants.APPOINTMENTS &&
								parentDetails.parent_object === constants.JOBS_OBJECT
							) {
								tempRecord["project_name"] = recordDetails.project_name;
								setRecord(tempRecord);
							}
							if (
								object === constants.PAYMENTS_OBJECT &&
								paymentType === "Refund"
							) {
								let paidAmountVal = Number(response.paymentAmount);
								setPaidAmount(paidAmountVal);
								setQuoationId(response.quoationId);
								let paymentList = getArrayParam(response.paymentList);
								let refundedPaymentList = paymentList.filter((u) => {
									return u.payment_type === "Refund";
								});
								let refundedAmountVal = 0;
								if (refundedPaymentList.length > 0) {
									refundedPaymentList.map((items, Index) => {
										if (Index === 0) {
											refundedAmountVal = Number(items.payment_value);
										} else {
											refundedAmountVal =
												refundedAmountVal + Number(items.payment_value);
										}
									});
									;
								}
								let leftRefundedAmount = getFloatParam(
									paidAmountVal - refundedAmountVal
								);
								setRefundedAmount(leftRefundedAmount);
							}

							if (
								isValidParam(response.records) &&
								(object === constants.ACCOUNTS_OBJECT ||
									object === constants.CONTACTS_OBJECT)
							) {
								if (
									response.records.hasOwnProperty("lead_score") &&
									type === link.LinkToType.TYPE_COPY
								) {
									response.records.lead_score = 0;
								}
							}
							if (
								isValidParam(response.records) &&
								object === constants.ACCOUNTS_OBJECT
							) {
								if (
									response.records.hasOwnProperty("custom_field45") &&
									type === link.LinkToType.TYPE_COPY
								) {
									response.records.custom_field45 = null;
								}
								if (
									(app.me.industry_type ===
										constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE ||
										app.me.industry_type ===
										constants.INDUSTRY_TYPE_INXPRESS_US_FRANCHISE) &&
									(app.me.tenant_country === "USA" ||
										app.me.tenant_country === "United Kingdom")
								) {
									if (
										response.records.hasOwnProperty("custom_field_date6") &&
										type === link.LinkToType.TYPE_COPY
									) {
										response.records.custom_field_date6 = null;
									}
								}
							}
							if (isColumnView) {
								isDetailView = true;
							}
							if (object === constants.TASKS) {
								let ownerList = getArrayParam(_tempFieldArr).filter(
									(f) => f.name === "t_owner"
								);
								if (ownerList.length > 0) {
									let ownerValueList = ownerList[0].list_values;
									ownerValueList = ownerValueList.filter(
										(f) => f.id !== "Unit Owner"
									);
									if (response.records.isUnitOwner === true) {
										setIsUnitOwner(true);
										ownerValueList.push({
											id: "Unit Owner",
											value: "Unit Owner",
										});
									}
									let fieldsVal = _tempFieldArr.map((m) =>
										m.name === "t_owner"
											? { ...m, list_values: ownerValueList }
											: m
									);
								}

								if (response.records?.isOnboardingTypeTask === true) {
									setIsOnboardingTypeTask(true);
									//alert("A");
								}
								
							}
							if (!getBooleanParam(isCompleAndNew)) {
								let _resRecord = response?.records
								updateState(true, _tempFieldArr, _resRecord, isDetailView);
								setRequiredRecord(_resRecord);
							}
							if (
								object === constants.APPOINTMENTS ||
								object === constants.TASKS
							) {
								if (response.is_repeat === true) {
									setRecurringState(response.recurring_fields[0]);
								}

								let localReminder = [];
								if (isValidParam(response.reminder)) {
									localReminder = response.reminder;
								} else {
									localReminder = reminder;
								}
								// let tempReminder = localReminder.concat(propsreminder);
								setReminderData(localReminder);
								if (isValidParam(response) && isValidParam(response.records)) {
									setParentRecurId(response.records.parent_recordid);
									let link_info = response.records.link_info;
									if (isValidParam(link_info)) {
										setParent_link_info(link_info);
										let linkInfoArr = link_info.split(",");
										let templinkInfoObject = linkInfoObject;
										for (let i = 0; i < linkInfoArr.length; i++) {
											let objectInfo = linkInfoArr[i].split("_");
											templinkInfoObject[objectInfo[0]] = objectInfo[1];
										}

										setLinkInfoObject({ ...templinkInfoObject });
										setLinkInfoVal(link_info);
										if (object === constants.APPOINTMENTS) {
											if (
												(isDetailView &&
													parentObject !== null &&
													parentObject === constants.ACCOUNTS_OBJECT) ||
												!isDetailView
											) {
												setAttendeeRecordId(linkInfoObject[
													OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT]
												])
											}
										}
									}

									if (object === constants.TASKS) {
										setIsWorkflowTask(response.records.id > 0 ? response.records.t_workflow_task : 0)
									}
								}
							}
							if (response.records.hasOwnProperty('t_attachment') && isValidParam(response.records.t_attachment)) {
								setAttachmentDetails(JSON.parse(response.records.t_attachment));
								let _files = attachFiles;
								// let attachedInfo = JSON.parse(response.records.t_attachment)
								// 		var uniqueResultArray = attachedInfo.attached_files.filter(function (objOne) {
								// 			return !_files.some(function (objTwo) {
								// 				return objOne.name == objTwo.name;
								// 			});
								// 		});
								// 		_files = [..._files, ...uniqueResultArray];
								// setAttachFiles([..._files])
							}
						} else {
							updateState(true, _tempFieldArr);
						}
						if (
							isValidParam(response.records) &&
							parentObject === constants.PROJECTS_OBJECT
						) {
							if (response.records.hasOwnProperty("dep_start_date")) {
								if (
									isValidParam(
										getStringParam(response.records.dep_start_date).trim()
									) &&
									response.records.dep_start_date !== ""
								) {
									setTaskStartDateFieldName(response.records.dep_start_date);
									let tempLog = changeLog;
									setchangeLog({ ...tempLog, taskStartDateFieldName: response.records.dep_start_date });
									handleForceupdateMethod();
									projectDepTaskFields(response.records.dep_start_date);
								}
							} else if (response.records.hasOwnProperty("t_workflow_task")) {
								if (
									response.records.t_workflow_task === 1 ||
									response.records.t_workflow_task === -1
								) {
									let tempFieldForProjectTaskValue =
										tempFieldForProjectTask.filter(
											(f) =>
												f.name !== "dep_start_date" && f.name !== "duration"
										);
									tempCustomField.forEach((f) => {
										let searchFields = tempFieldForProjectTaskValue.filter((fld) => {
											return fld.name === f;
										});
										let searchField =
											searchFields.length > 0 ? searchFields[0] : null;
										if (isValidParam(searchField)) {
											_tempFieldArr.push(searchField);

										}
									});
								}

							}
						}
					} else {
						updateState(true, _tempFieldArr);
					}
				});
			} else {
				updateState(true, _tempFieldArr);
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> getRecord()':" + error);
		}
	};
	const setQuickAddFormValues = (fieldPropsVal) => {
		let dataValue = getObjectParam(appContainer.drawerProps.data);
		let selectedPrimarySource = dataValue?.formValues?.source?.value ? dataValue.formValues.source.value : fieldPropsVal?.source?.value;
			if(selectedPrimarySource){
				let params = {};
				params.object = object;
				params.name = "sc_source";
				params.value = selectedPrimarySource;
				params.list_type = 'external';
				params.query_name = "SecSourceByPrimarySource";
				params.query_type = 'customQuery';
	
				const promise = SFFormActions.getFieldListValuesPromise(params);
				promise.then((response) => {
					if (isValidParam(response)) {
						let fieldPropsValue = fieldPropsVal;
						let isRequired = getBooleanParam(
								fieldPropsValue["sc_source"]?.is_required_field
							);
						fieldPropsValue["sc_source"].list_values = response ? response : [];
						setFieldProps({ ...fieldPropsValue });
						sfForm.sfFromData = fieldPropsValue;
						setListValueMenuItems({
							name: params.name,
							list_values: response,
							is_required_field: isRequired,
						});
					}
				})
			}

		if (isValidParam(dataValue) && dataValue.hasOwnProperty("formValues")) {
			let formValues = getObjectParam(dataValue.formValues);
			Object.keys(formValues).forEach((fieldName) => {
				let value = getObjectParam(formValues[fieldName].value);
				if (value !== "") {
					let temp = fieldPropsVal;
					temp[fieldName].value = formValues[fieldName].value;
					if(fieldName === "sc_source"){
						temp[fieldName].list_values = formValues[fieldName].list_values;
					}
					setFieldProps({...temp});
				}
			});

			if (object === constants.OPPORTUNITIES_OBJECT) {
				calculateOpportynities(fieldPropsVal);
			}
			if (object === constants.ACCOUNTS_OBJECT) {
				calculateForcastForAccount(fieldPropsVal);
			}
		}
		if (object === constants.OPPORTUNITIES_OBJECT) {
			setOppotunityContactName(fieldPropsVal);
		}
	};

	const calculateForcastForAccount = (fieldPropsVal) => {
		try {
			fieldPropsVal = isValidParam(fieldPropsVal)
				? fieldPropsVal
				: fieldProps;
			if (isValidParam(fieldPropsVal)) {
				let probabilityValue = 0;
				if (fieldPropsVal.hasOwnProperty("probability")) {
					probabilityValue = fieldPropsVal["probability"].value;
				}

				let oppAmnt = 0;
				if (fieldPropsVal.hasOwnProperty("opportunity_amount")) {
					var s = fieldProps["opportunity_amount"].value.toString();
					s = s?.replace(/,/g, "");
					oppAmnt = s;
				}

				let forCastValue = (oppAmnt * probabilityValue) / 100;
				if (fieldPropsVal.hasOwnProperty("forecast_amount")) {
					fieldPropsVal["forecast_amount"].value = getFloatParam(forCastValue);
				} else {
					setForCastValAcc(getFloatParam(forCastValue));
				}
				setFieldProps(fieldPropsVal);
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> calculateForcastForAccount()':" + error
			);
		}
	};

	const getUnitsList = (fieldPropsVal) => {
		fieldPropsVal = isValidParam(fieldPropsVal) ? fieldPropsVal : fieldProps;
		try {
			let parentObject = getStringParam(parentDetails.parent_object).trim();
			let parentRecordId = getIntParam(parentDetails.parent_record_id);

			let allUnitListFromRedux = [];
			let selectedUnit = "";
			if (
				isValidParam(parentObject) &&
				parentObject === constants.ACCOUNTS_OBJECT &&
				parentRecordId > 0
			) {
				allUnitListFromRedux = getArrayParam(
					filters.recentRecords[parentObject]
				);
				selectedUnit = allUnitListFromRedux.filter((f) => {
					return f.id === parentRecordId;
				});
			}

			let params = {};
			params.object = object;
			params.name = fieldPropsVal["unit"].name;
			params.query_name = fieldPropsVal["unit"].queryName;
			params.query_type = "customQuery";
			params.list_type = fieldPropsVal["unit"].listType;
			const promise = SFFormActions.getFieldListValuesPromise(params);
			promise.then((response) => {
				if (isValidParam(response)) {
					setListValueMenuItems({
						name: "unit",
						list_values: response,
						is_required_field: false,
					});

					if (
						isValidParam(selectedUnit) &&
						selectedUnit.length > 0 &&
						response.length > 0
					) {
						let unitArray = response.filter((f) => {
							return f.value === selectedUnit[0].recent;
						});
						fieldProps["unit"].value = unitArray[0].value;
					}
				}
			});
		} catch (error) {
			console.error("Error in 'sfForm.js -> getUnitsList()':" + error);
		}
	};

	const getPlanList = (fieldPropsVal) => {
		var promise = null;
		fieldPropsVal = isValidParam(fieldPropsVal) ? fieldPropsVal : fieldProps;
		let planArr = [];
		try {
			let parentObject = getStringParam(parentDetails.parent_object).trim();
			let parentRecordId = getIntParam(parentDetails.parent_record_id);

			let params = {};
			params.recordId = parentRecordId;
			if (app.me.is_franchisor && app.me.is_franchisee) {
				params.callFrom = 'FE';
			} else {
				params.callFrom = app.me.is_franchisor ? 'FR' : 'FE';
			}

			promise = Promise.resolve(HTTPClient.post(endPoints.REFUND.GET_CATAGORY_LIST, params));
			promise.then((response) => {
				if (isValidParam(response)) {
					response.data.categoryList.map((item, i) => {
						let obj = {};
						obj.id = item;
						obj.value = item;
						planArr.push(obj);
					});
					setListValueMenuItems({
						name: "plan_name",
						list_values: planArr,
						is_required_field: false,
					});
				}
			});
		} catch (error) {
			console.error("Error in 'sfForm.js -> getPlanList()':" + error);
		}
	};
	const handleRequestClose = (fieldName) => {
		let tempfieldProps = fieldProps
		tempfieldProps[fieldName].isOpenPopover = false;
		setFieldProps(tempfieldProps);
	};

	const getRequiredFields = (fieldPropsValue) => {
		fieldPropsValue = isValidParam(fieldPropsValue) ? fieldPropsValue : fieldProps;
		try {
			if (
				fieldPropsValue.workflow_id &&
				fieldPropsValue.t_status &&
				fieldPropsValue.workflow_id.value &&
				fieldPropsValue.t_status.value
			) {
				let args = {
					table_id: OBJECT_TABLEID_MAP[object],
					workflow_id: fieldPropsValue.workflow_id.value,
					stage_name: fieldPropsValue.t_status.value,
				};
				let promise = Promise.resolve(
					HTTPClient.get(endPoints.WORKFLOWS.GET_REQUIRED_FIELDS, args)
				);
				if (isValidParam(promise)) {
					promise.then((response) => {
						let allRequiredFieldsValue = getArrayParam(response);
						setAllRequiredFields(allRequiredFieldsValue);
						let log = changeLog;
						log.allRequiredFields = allRequiredFieldsValue;
						setchangeLog({ ...log });
						setRequiredFields(allRequiredFieldsValue, fieldPropsValue, response);
					});
				}
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> getRequiredFields()':" + error);
		}
	};
	const setTaskNameDescription = (value, tempFieldProps) => {
		let params = {
			"t_name": value,
		};

		var response = Promise.resolve(
			HTTPClient.post(endPoints.TASKS_INFO.GET_DESCRIPTION, params)
		);
		response.then((response) => {
			if (response.data != null && response.data.hasOwnProperty('t_description')) {
				tempFieldProps['t_description'].value = response.data.t_description;
			} else {
				tempFieldProps['t_description'].value = "";
			}
			setFieldProps(tempFieldProps);

			handleForceupdateMethod();
		});
	}


	const handleKeyPress = (event) => {
		let _key = event.key;
		let textarea = event.target;
		let currentTimeZone = new Date();
		let formattedDate = currentTimeZone.toDateString();
		let userName = app.me.name;
		if (object === constants.NOTE_OBJECT) {
			if (document.activeElement.tagName === 'TEXTAREA' && _key === 'F2') {
				event.preventDefault();
				let newText = `${formattedDate} - ${userName} -`;
				if (textarea.selectionStart !== undefined && textarea.selectionEnd !== undefined) {
					let start = textarea.selectionStart;
					let end = textarea.selectionEnd;
					let updatedText = textarea.value.substring(0, start) + newText + textarea.value.substring(end);

					textarea.value = updatedText;
					textarea.setSelectionRange(start + newText.length, start + newText.length);
				} else {
					let cursorPos = textarea.selectionStart;
					let textBeforeCursor = textarea.value.substring(0, cursorPos);
					let textAfterCursor = textarea.value.substring(cursorPos);
					let updatedText = textBeforeCursor + newText + textAfterCursor;

					textarea.value = updatedText;
					textarea.setSelectionRange(cursorPos + newText.length, cursorPos + newText.length);
				}
				let _fieldProps = { ...fieldProps };
				_fieldProps['note_text'].value = textarea.value;

				sfForm.sfFromData = _fieldProps;
				setFieldProps({ ..._fieldProps });
			}
		}
	};

	const setFieldValue = (fieldName, event, value) => {
		let tempFieldProps = fieldProps;
		if (
			tempFieldProps[fieldName].fieldType ===
			constants.FIELD_TYPE_TEXT ||
			tempFieldProps[fieldName].fieldType ===
			constants.FIELD_TYPE_INTEGER ||
			tempFieldProps[fieldName].fieldType ===
			constants.FIELD_TYPE_FLOAT ||
			tempFieldProps[fieldName].fieldType ===
			constants.FIELD_TYPE_MEMO ||
			tempFieldProps[fieldName].fieldType === constants.FIELD_TYPE_URL
		) {
			if (fieldName === "fullname") {
				let items = getArrayParam(listValueMenuItems[fieldName]);
				let newItems = items.filter((f) => {
					return f.key === value;
				});

				if (newItems.length == 0 && contactChildRecordArr.length > 0 && fieldName === "fullname" && (object == 'tasks' || object == 'appointments')) {

					map(contactChildRecordArr, (childObject, index) => {
						if (isValidParam(childObject)) {
							if (childObject?.value == value) {
								let templinkInfoObject = linkInfoObject;
								templinkInfoObject[9] = getIntParam(childObject.id);
								setLinkInfo(templinkInfoObject);
								setLinkInfoObject({ ...templinkInfoObject });
							}

						}
					})

				}

				if (newItems.length > 0) {
					value = getStringParam(newItems[0].props.fullName);
					tempFieldProps["location"].value =
						object !== constants.APPOINTMENTS
							? newItems[0].props.email
							: app.me.calendar_setup.length > 0 &&
							app.me.calendar_setup[0].location;
					setLocationState(object !== constants.APPOINTMENTS ? newItems[0].props.email
						: app.me.calendar_setup.length > 0 && app.me.calendar_setup[0].location
					);
					let templinkInfoObject = linkInfoObject;
					templinkInfoObject[9] = newItems[0].key;
					setLinkInfo(templinkInfoObject);
					setLinkInfoObject({ ...templinkInfoObject });

				}
			} else if (
				fieldName === "first_name" &&
				object === constants.CASES_OBJECT
			) {
				let listValues = getArrayParam(
					tempFieldProps[fieldName].list_values
				);
				let newItems = listValues.filter((f) => {
					return f.id === value;
				});
				if (newItems.length > 0) {
					let contactId = getIntParam(newItems[0].id);
					value = getStringParam(newItems[0].first_name).trim();
					tempFieldProps["last_name"].value = getStringParam(newItems[0].last_name).trim();
					tempFieldProps["from_email"].value = getStringParam(newItems[0].email).trim();
					tempFieldProps["phone"].value = getStringParam(newItems[0].phone).trim();
					let lookupInfoval = {
						lookup_field_name: "",
						lookup_object_name: constants.CONTACTS_OBJECT,
						record_id: contactId,
					};
					setLookupInfo(lookupInfoval, tempFieldProps);
				}
			} else if (
				(fieldName === "first_name" || fieldName === "last_name") &&
				object === constants.CONTACTS_OBJECT &&
				tempFieldProps.hasOwnProperty("full_name")
			) {
				let firstName =
					fieldName === "first_name" ? value : tempFieldProps["first_name"].value;
				let lastName =
					fieldName === "last_name" ? value : tempFieldProps["last_name"].value;
				let fullName = firstName + " " + lastName;
				fullName = fullName.trim();
				tempFieldProps["full_name"].value = fullName;
			} else if (
				fieldName === "quoteno" &&
				object === constants.PAYMENTS_OBJECT
			) {
				tempFieldProps["quoteno"].value = value;
							}
			if (object === constants.GROUP_OBJECT) {
				if (fieldName === "name") {
					tempFieldProps["description"].value = value;
				}
			}
			if (
				object === constants.TASKS &&
				(fieldName === "days_after" || fieldName === "duration")
			) {
				if (
					/(^[\d+]$)|(^\d+,?\d{1,2}$)/.test(value) &&
					getStringParam(value).length <= 3
				) {
					tempFieldProps[fieldName].value = value;
				} else if (value === "") {
					tempFieldProps[fieldName].value = "";
				}
			} else {
				tempFieldProps[fieldName].value = value;
			};
		} else if (
			tempFieldProps[fieldName].fieldType === constants.FIELD_TYPE_LIST
		) {
			if (
				value ===
				"---- " +
				getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST +
				" ----" ||
				value ===
				"---- " +
				getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE +
				" ----" ||
				value === "addto_" + fieldName
			) {
				tempFieldProps[fieldName].isAddToThisList = true;
				tempFieldProps[fieldName].value = "";
				// setAutoFocusFieldName(fieldName);
				let tempChangelog = { ...changeLog };
				tempChangelog.autoFocusFieldName = fieldName;
				setchangeLog(tempChangelog);
			} else if (
				fieldName === "sales_rep" &&
				value === "Setup Assignment Rule"
			) {
				dispatch(getAppDrawer(false, null, null, null, null));

				openAssignmentRuleDrawer();
			} else if (fieldName === "t_name" && object === constants.TASKS) {
				let temp = fieldProps;
				let tempvalue = null;
				if (value !== null) {
					tempvalue = value.inputValue !== undefined ? value.inputValue : value.value;
					temp['t_name'].value = tempvalue;
					setFieldProps(temp);
					if (value.inputValue === undefined) {
						setTaskNameDescription(tempvalue, temp)
					} else {
						temp['t_description'].value = "";
						setFieldProps(temp);
					}
				} else {
					tempvalue = value;
					temp['t_name'].value = tempvalue;
					setFieldProps(temp);
				}

				handleForceupdateMethod();
			}
			else if (
				fieldName === "from_email" &&
				object === constants.CASES_OBJECT
			) {
				let _listValues = []

				_listValues = tempFieldProps["first_name"]?.list_values
				let userListVal = getArrayParam(userlist.data);
				if (userListVal.length > 0 && _listValues == undefined && app.me.is_franchisee) {
					_listValues = userListVal;
				}
				let _newItems = _listValues.filter((f) => {
					return f.email === value;
				});
				if (_newItems.length > 0) {
					let contactId = getIntParam(_newItems[0].id);
					value = getStringParam(_newItems[0].email).trim();
					if (userListVal.length > 0 && app.me.is_franchisee) {
						tempFieldProps["submitter"].value = getStringParam(_newItems[0].value).trim();
						tempFieldProps["from_email"].value = getStringParam(_newItems[0].email).trim();
						tempFieldProps["phone"].value = getStringParam(_newItems[0].phone).trim();
						tempFieldProps["first_name"].value = getStringParam(_newItems[0].first_name).trim();
						tempFieldProps["last_name"].value = getStringParam(_newItems[0].last_name).trim();
					} else {
						tempFieldProps["first_name"].value = getStringParam(_newItems[0].first_name).trim();
						tempFieldProps["last_name"].value = getStringParam(_newItems[0].last_name).trim();
						tempFieldProps["from_email"].value = getStringParam(_newItems[0].email).trim();
						tempFieldProps["phone"].value = getStringParam(_newItems[0].phone).trim();
					}

					let lookupInfoval = {
						lookup_field_name: "",
						lookup_object_name: constants.CONTACTS_OBJECT,
						record_id: contactId,
					};
					setLookupInfo(lookupInfoval, tempFieldProps);
				}
			}
			else {
				tempFieldProps[fieldName].value = value;
				let tempisNotifySalesRep = isNotifySalesRep;
				let tempisTextNotifySalesRep = isTextNotifySalesRep;

				if (fieldName === "workflow_name") {
					let items = getArrayParam(listValueMenuItems[fieldName]);
					items = items.filter((f) => {
						return f.props.value === value;
					});

					let tmpId = items.length > 0 ? getIntParam(items[0].props.id) : 0;
					tempFieldProps["workflow_id"].value = tmpId;
					tempFieldProps = setWFMenuItemList(
						tmpId,
						fieldProps["t_status"].name,
						fieldProps["t_status"].queryName,
						fieldProps["t_status"].listType,
						tempFieldProps
					);
				} else if (fieldName === "country") {
					tempFieldProps = setStateMenuItemList(value, tempFieldProps);
				} else if(fieldName === 'source' && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.SALES_LEADS_OBJECT || object === constants.PARTNERS_OBJECT)){
					tempFieldProps = setSecondarySourceMenuItemList(value, tempFieldProps,'onChange');
				}else if (fieldName === "state") {
					if (
						tempFieldProps.hasOwnProperty("city") &&
						tempFieldProps["city"].fieldType ===
						constants.FIELD_TYPE_LIST &&
						tempFieldProps["country"].value === "India"
					) {
						tempFieldProps["city"].value = "";
						tempFieldProps = setCityMenuItemList(value, tempFieldProps);
					}
				} else if (
					fieldName === "sales_rep" &&
					event !== null &&
					event !== undefined
				) {
					tempisNotifySalesRep = value != app.me.name ? true : false;
					tempisTextNotifySalesRep = tempisNotifySalesRep;
					setIsNotifySalesRep(tempisNotifySalesRep);
					setIsTextNotifySalesRep(tempisTextNotifySalesRep);
				} else if (
					fieldName === "assign" &&
					event !== null &&
					event !== undefined &&
					object === constants.APPOINTMENTS
				) {
					tempisNotifySalesRep = value != app.me.name ? true : false;
					tempisTextNotifySalesRep = tempisNotifySalesRep;
					setIsNotifySalesRep(tempisNotifySalesRep);
					setIsTextNotifySalesRep(tempisTextNotifySalesRep);
				} else if (
					fieldName === "t_owner" &&
					event !== null &&
					event !== undefined &&
					object !== constants.TASKS
				) {
					tempisNotifySalesRep = value != app.me.name ? true : false;
					tempisTextNotifySalesRep = tempisNotifySalesRep;
					setIsNotifySalesRep(tempisNotifySalesRep);
					setIsTextNotifySalesRep(tempisTextNotifySalesRep);
				} else if (
					fieldName === "dep_start_date" &&
					object === constants.TASKS
				) {
					handleTaskStartDateChanges(value);
				} else if (fieldName === "t_status") {
					getRequiredFields(tempFieldProps);
				} /*else if (fieldName === 'duration' && object === constants.REPORTED_SALES_OBJECT) {
					let items = getArrayParam(listValueMenuItems[fieldName]);
					let tempduration = items;
					items = items.filter(f => { return f.props.value == value });
					let durationType = "Week";
					let flag = tempduration.find(f => f.props.value === 'Last Month');
					if (flag) {
						durationType = "Month";
					}
					let tmpId = items.length > 0 ? getIntParam(items[0].props.id) : 0;

					let startDate = '';
					let endDate = '';
					if (durationType === 'Month') {
						startDate = moment().subtract(tmpId, 'months').startOf('month').format(dateTimeFormat[app.me.date_format])
						endDate = moment().subtract(tmpId, 'months').endOf('month').format(dateTimeFormat[app.me.date_format]);

					} else if (durationType === 'Week') {
						startDate = moment().subtract(tmpId, 'week').startOf('isoWeek').format(dateTimeFormat[app.me.date_format])
						endDate = moment().subtract(tmpId, 'week').endOf('isoWeek').format(dateTimeFormat[app.me.date_format]);

					}
					let temp = fieldProps;
					temp['start_date'].value = startDate;
					temp['end_date'].value = endDate;
					setFieldProps(temp);
				} */ else if (fieldName === 'renewal_period' && object === constants.COMPLIANCE_OBJECT) {
					let items = getArrayParam(listValueMenuItems[fieldName]);
					let startDate = fieldProps['start_date'].value
					items = items.filter(f => { return f.props.value == value });
					let renewalPeriod = items.length > 0 ? getStringParam(items[0].props.value) : null;
					let nextRenewalDate = '';
					if (renewalPeriod === 'Every 6 months') {
						nextRenewalDate = moment(startDate).add(6, 'months').format(dateFormat[app.me.date_format]);
					} else if (renewalPeriod === 'Every year') {
						nextRenewalDate = moment(startDate).add(1, 'year').format(dateFormat[app.me.date_format]);
					} else if (renewalPeriod === 'Every 2 years') {
						nextRenewalDate = moment(startDate).add(2, 'year').format(dateFormat[app.me.date_format]);
					}
					let temp = fieldProps;
					temp['next_renewal_date'].value = nextRenewalDate;
					setFieldProps(temp);
				}

			}
		} else if (
			(tempFieldProps[fieldName].fieldType === constants.FIELD_TYPE_LIST_INTEGER) &&
			(tempFieldProps[fieldName].listType === constants.LIST_FIELD_TYPE_REGULAR) &&
			(fieldName === "t_priority")
		) {
			tempFieldProps[fieldName].value = value;
		} else if (
			tempFieldProps[fieldName].fieldType === constants.FIELD_TYPE_LIST && tempFieldProps[fieldName].listType ===
			constants.LIST_FIELD_TYPE_EXTERNAL && fieldName === "task_type"
		) {
			tempFieldProps[fieldName].value = value;
		} else {
			tempFieldProps[fieldName].value = value;
		}
		if (
			object === constants.OPPORTUNITIES_OBJECT &&
			(fieldName === "opportunity_amount" || fieldName === "t_status")
		) {
			calculateOpportynities();
		}
		if (
			object === constants.ACCOUNTS_OBJECT &&
			(fieldName === "opportunity_amount" || fieldName === "probability")
		) {
			calculateForcastForAccount(tempFieldProps);
		}
		if (object === constants.CASES_OBJECT && app.selectedModuleName === constants.MODULE_HUB) {
			setHubCasesSubmitterInfo(tempFieldProps);
		}

		if (callFrom === "queueView") {
			isEdited(true);
			setIsSavedDisabled(false);
			let tempChangeLog = changeLog;
			tempChangeLog.isSavedDisabled = false;
			setchangeLog({ ...tempChangeLog });
		}
		tempFieldProps[fieldName].helperText = "";
		if (sfForm.sfFromData.hasOwnProperty("note_type")) {
			sfForm.sfFromData = { ...sfForm.sfFromData, tempFieldProps };
		} else {
			sfForm.sfFromData = tempFieldProps;

		}

		handleRequestClose(fieldName);
		setChangeData({ field: fieldName, value: value })
		setFieldProps({ ...tempFieldProps });
		if (changeLog.allRequiredFields != null && changeLog.allRequiredFields.length > 0) {
			checkFieldProps();
		}

	};

	const setFieldValueOnBlur = (fieldName, event, value) => {
		let isPhoneField = getBooleanParam(
			fieldProps[fieldName].isPhoneField
		);
		if (isPhoneField) {
			let _fieldProps = fieldProps;
			let data = (_fieldProps.workflow_name.value === 'Greenlight' && _fieldProps[fieldName].value) || phoneFormat(_fieldProps[fieldName].value);
			_fieldProps[fieldName].value = data;
			setFieldProps({ ..._fieldProps });
		}
		if (
			(fieldName === "first_name" || fieldName === "last_name") &&
			object === constants.CONTACTS_OBJECT &&
			fieldProps.hasOwnProperty("full_name")
		) {
			let firstName = fieldProps["first_name"].value;
			let lastName = fieldProps["last_name"].value;
			let fullName = firstName + " " + lastName;
			fullName = fullName.trim();
			let fieldPropsValue = fieldProps;
			fieldPropsValue["full_name"].value = fullName;
			setFieldProps(fieldPropsValue);
		}
		if (object === constants.TASKS && fieldName == 't_name' && fieldProps.hasOwnProperty("t_name")) {

			let temp = fieldProps;
			value = event.target.value;
			if (value !== null && value != undefined) {
				if(temp['t_name'].value !== value){
				temp['t_name'].value = value;
				setFieldProps(temp);
				setTaskNameDescription(value, temp)
				}
			} else {
				temp['t_name'].value = value;
				setFieldProps(temp);
			}

			handleForceupdateMethod();

		}

		if (
			(fieldName === "royalty_percent" || fieldName === "total") &&
			object === constants.ROYALTY_OBJECT
		) {
			if (
				isValidParam(fieldProps["royalty_percent"].value) &&
				(fieldProps["royalty_percent"].value + "").length > 0 &&
				isValidParam(fieldProps["total"].value) &&
				(fieldProps["total"].value + "").length > 0
			) {
				const royaltyPercent = getFloatParam(fieldProps["royalty_percent"].value);
				const totalAmount = getFloatParam(fieldProps["total"].value);
				const royalty_owed = getFloatParam((totalAmount / 100) * royaltyPercent);
				let fieldPropsValue = fieldProps;
				fieldPropsValue["royalty_owed"].value = royalty_owed;
				setFieldProps(fieldPropsValue);
			}
		}

	};

	const setMultiSelectValue = (event, fieldName) => {
		let value = event.target.value;
		let tempValue = "";
		value.forEach((item, Index) => {
			if (tempValue === "") {
				tempValue = item;
			} else {
				tempValue += "," + item;
			}
		});

		let fieldPropsValue = fieldProps;
		fieldPropsValue[fieldName].selectedListValues = value;
		fieldPropsValue[fieldName].value = tempValue;
		fieldPropsValue[fieldName].helperText = "";
		setFieldProps(fieldPropsValue);
		handleForceupdateMethod();
	};

	const getTaskNameList = (listValues) => {
		let tasksListValue = [];
		try {
			if (isValidParam(listValues) && getArrayParam(listValues).length > 0) {
				let tasks = getArrayParam(listValues);
				if (tasks[0].value === "") {
					tasks.shift();
				}

				tasks.forEach((task) => {
					let taskObj = {};
					taskObj.value = task.id;
					taskObj.label = task.value;
					taskObj.title = task.value;
					tasksListValue.push(taskObj);
				});
				setTaskNameList(tasksListValue);
			}

		} catch (error) {
			console.error("error in sfForm.js ->'getTaskNameList()'" + error);
		}
	};

	const updateState = (isMounted, _tempfieldArr, recordValue, isDetail) => {
		let tempfield = null;
		let workflowId = 0;
		let defaultDate = null;
		let tempFields = null;
		let parentObject = null;
		let parentRecordId = 0;
		let tempFieldProps = fieldProps;
		if (object !== 'appointments' && appContainer.drawerProps.data != null && appContainer.drawerProps.data.hasOwnProperty('changeModeFrom') && appContainer.drawerProps.data.changeModeFrom === 'expandButton') {
			tempFieldProps = {};
		}

		let minimizeList = customDrawer.minimizeList;
		let minimizeData = sfForm.sfFromData;
		const tempRecordId = (changeLog.isStateCompleteAndNew) ? 0 : recordId;
		try {
			parentObject = getStringParam(parentDetails.parent_object).trim();
			parentRecordId = getIntParam(parentDetails.parent_record_id);
			if (
				parentObject === constants.JOBS_OBJECT &&
				isCompleAndNew &&
				tempRecordId === 0 &&
				object === constants.APPOINTMENTS &&
				isDetail === false
			) {
				isDetail = true;
			}
			tempFields = _tempfieldArr.filter((f) => {
				return f.field_type !== constants.FIELD_TYPE_HEADER;
			});
			if (Object.keys(tempFieldProps).length == 0) {
				tempFields.forEach((field, index) => {
					if (
						changeLog.autoFocusFieldName === "" &&
						(field.field_type === constants.FIELD_TYPE_FLOAT ||
							field.field_type === constants.FIELD_TYPE_TEXT ||
							field.field_type === constants.FIELD_TYPE_MEMO)
					) {
						// setAutoFocusFieldName(field.name);
						let tempChangeLog = changeLog;
						tempChangeLog.autoFocusFieldName = field.name;
						setchangeLog({ ...tempChangeLog });
					}
					if (field.field_type === constants.FIELD_TYPE_LIST) {
						if (
							(field.name !== "workflow_name" &&
								field.name !== "t_status" &&
								field.name !== "month") ||
							(object === constants.CONTACTS_OBJECT &&
								field.name === "t_status")
						) {
							try {
								sortArrayObjectByProperty(field.list_values, "value");
							} catch (error) {
								console.error(
									"Error in 'sfForm.js -> updateState() -> call on sortArrayObjectByProperty()':" +
									error
								);
							}
						}
						if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {

							if (
								object === constants.CONTACTS_OBJECT &&
								field.name === "campaign_type"
							) {
								if (isValidParam(recordValue)) {
									field.list_values = getCampaignTypeListValues(
										recordValue[field.name]
									);
								} else {
									field.list_values = [];
								}
							} else {
								field.list_values = field.list_values.filter((f) => {
									return f.id !== "" && f.value !== "";
								});
							}
						}
						if (object == constants.TASKS && field.name === 't_name') {
							getTaskNameList(field.list_values);
						} else {
							setListValueMenuItems(field);
						}
					}
					tempfield = {};
					tempfield.id = field.id;
					tempfield.name = field.name;
					tempfield.label = field.label;
					tempfield.field_length = field.field_length;
					tempfield.isPhoneField = field.is_phone_field;
					if (
						tempRecordId === 0 &&
						object === constants.APPOINTMENTS &&
						(field.name === "start_date" ||
							field.name === "end_date" ||
							field.name === "subject" ||
							field.name === "assign" ||
							(appContainer.drawerProps.data.event_type && field.name === "event_type"))
					) {
						if (field.name === "start_date" || field.name === "end_date") {
							if (!!appContainer.drawerProps.data.timeSlot) {
								tempfield.value = appContainer.drawerProps.data.timeSlot[field.name];
							} else {
								let currentTime = moment().tz(timeZone[app.me.timezone]);
								let remainder = currentTime.minute() % constants.CALENDER_DEFAULT_INTERVAL;
								let start = currentTime.subtract(remainder, "m").format(dateTimeFormat[app.me.date_format]);
								let end = currentTime.add(constants.CALENDER_DEFAULT_INTERVAL, "m").format(dateTimeFormat[app.me.date_format]);
								let defaultDateObj = {
									start_date: start,
									end_date: end,
								};
								tempfield.value = defaultDateObj[field.name];
							}
						} else if (
							!!appContainer.drawerProps.data.subject &&
							field.name === "subject"
						) {
							tempfield.value = appContainer.drawerProps.data[field.name];
						} else if (
							!!appContainer.drawerProps.data.assign &&
							field.name === "assign"
						) {
							tempfield.value = appContainer.drawerProps.data[field.name];
						} else if (
							!!appContainer.drawerProps.data.event_type &&
							field.name === "event_type"
						) {
							tempfield.value = appContainer.drawerProps.data[field.name];
						} else {
							if (field.is_required_field) {
								tempfield.value = field.default_value;

							}
						}
					} else {
						if (field.field_type === constants.FIELD_TYPE_DATE_TIME) {

							defaultDate = isValidParam(recordValue) ? getStringParam(recordValue[field.name]).trim() : "";
							defaultDate = defaultDate !== "" ? defaultDate : field.default_value;

							if (object == 'tasks' && field.name == "t_dueby" && recordId == 0) {
								let _currentTime = moment().tz(timeZone[app.me.timezone]);
								_currentTime = _currentTime.format(
									dateFormat[app.me.date_format]
								);
								let _defaultDate = moment(field.default_value).format(
									dateFormat[app.me.date_format]
								);
								// This condition used for default value : 'CURRENT_DATE+N'
								_currentTime = moment().tz(timeZone[app.me.timezone]);
								_defaultDate = _currentTime.format(
									dateTimeFormat[app.me.date_format]
								)
								defaultDate = isValidParam(recordValue) ? getStringParam(recordValue[field.name]).trim() : "";
								defaultDate = _defaultDate !== "" ? _defaultDate : field.default_value;

							}

							if (isValidParam(defaultDate) && defaultDate.trim() !== "") {
								let momentObj = moment(defaultDate);
								defaultDate = momentObj.format(dateTimeFormat[app.me.date_format]);
								tempfield.value = defaultDate === "Invalid date" ? "" : defaultDate;
							} else {
								tempfield.value = defaultDate;
							}
						} else if (field.field_type === constants.FIELD_TYPE_DATE) {
							defaultDate = isValidParam(recordValue) ? getStringParam(recordValue[field.name]).trim() : "";
							defaultDate = defaultDate !== "" ? defaultDate : field.default_value;

							if (isValidParam(defaultDate) && defaultDate.trim() !== "") {
								let momentObj = moment(defaultDate);
								defaultDate = momentObj.format(dateFormat[app.me.date_format]);
								tempfield.value = defaultDate === "Invalid date" ? "" : defaultDate;
							}
						} else if (field.field_type === constants.FIELD_TYPE_INTEGER) {
							if (tempRecordId > 0) {
								tempfield.value = (isValidParam(recordValue) && getStringParam(recordValue[field.name]) !== "") ? recordValue[field.name] : "";
							} else if (tempRecordId === 0 || field.is_required_field) {
								tempfield.value = field.default_value;
							}

							//tempfield.value = isValidParam(recordValue) && getStringParam(recordValue[field.name]) !== '' ? recordValue[field.name] : field.default_value;
						} else if (field.field_type === constants.FIELD_TYPE_LIST) {
							tempfield.value = isValidParam(recordValue) && getStringParam(recordValue[field.name]) !== "" ? recordValue[field.name] : field.default_value;
							if (
								field.name === "t_owner" &&
								object === constants.TASKS
							) {
								let taskObjParam = getObjectParam(tasksObjParam);
								if (((
									getBooleanParam(taskObjParam.isFranchiseRecord) &&
									getStringParam(taskObjParam.selectOpt) === constants.UNIT_TASKS || ((getBooleanParam(isUnitask) && getStringParam(taskObjParam.selectOpt) == constants.TASKS))) && field.name == 't_owner')
								) {
									tempfield.value = "Unit Owner";
								} else if (getStringParam(taskObjParam.selectOpt) === '' && taskObjParam?.isUnitOwnerTasks == true) {
									tempfield.value = field.value;
								}
							}
							if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
								tempfield.selectedListIds = "";
								if (
									object === constants.CONTACTS_OBJECT &&
									field.name === "campaign_type"
								) {
									if (isValidParam(recordValue)) {
										let campaignTypeNames = getCampaignTypeNames(
											getStringParam(recordValue[field.name]).trim()
										);
										tempfield.selectedListValues =
											getStringParam(campaignTypeNames);
										tempfield.selectedListValues =
											tempfield.selectedListValues !== ""
												? tempfield.selectedListValues.split(",")
												: [];
									} else {
										tempfield.selectedListValues = [];
									}
								} else {
									if (appContainer.drawerProps.data != null && appContainer.drawerProps.data.hasOwnProperty('changeModeFrom') && appContainer.drawerProps.data.changeModeFrom === 'expandButton') {
										tempfield.value = isValidParam(recordValue) && getStringParam(recordValue[field.name]) !== "" ? recordValue[field.name] : fieldProps[field.name]?.value;
									}
									tempfield.selectedListValues = getStringParam(tempfield.value);
									tempfield.selectedListValues =
										tempfield.selectedListValues !== ""
											? tempfield.selectedListValues.split(",")
											: [];
								}
							} else if (
								isValidParam(tempfield.value) &&
								tempfield.value === "" &&
								field.list_values.length > 0
							) {
								if (field.name === 't_name' && object === constants.TASKS) {

								} else {
									tempfield.value = field.list_values[0].value;
								}
							}

							if (
								field.name === "note_type" &&
								object === constants.NOTE_OBJECT
							) {
								let minimizeList = customDrawer.minimizeList;
								let minimizeData = sfForm.sfFromData;
								if (
									object === constants.NOTE_OBJECT &&
									(minimizeList.hasOwnProperty("new") || minimizeList.hasOwnProperty("New")) &&
									(minimizeList.new || minimizeList.New) &&
									minimizeData !== undefined &&
									minimizeData !== null &&
									minimizeData[field.name] !== null &&
									minimizeData[field.name] !== undefined &&
									minimizeData[field.name] !== ""
								) {
									tempfield.value = minimizeData[field.name].value;
								} else {
									let rcType = getStringParam(getCookie("rc_" + field.name));
									if (isValidParam(rcType) && rcType !== "") {
										let arr = getArrayParam(field.list_values).filter(
											(f) => f.value === rcType
										);
										if (arr.length > 0) {
											tempfield.value = rcType;
										} else {
											deleteCookie(rcType);
										}
									}
								}
							}
							if (field.name === "event_type" && object === constants.APPOINTMENTS) {
								let rcType = getStringParam(getCookie("rc_" + field.name));
								if (isValidParam(rcType) && rcType !== "") {
									let arr = getArrayParam(field.list_values).filter(
										(f) => f.value === rcType
									);
									if (arr.length > 0) {
										tempfield.value = rcType;
									} else {
										deleteCookie(rcType);
									}
								}
							}
						} else {
							if (tempRecordId > 0) {
								let minimizeList = customDrawer.minimizeList;
								let minimizeData = sfForm.sfFromData;
								if (
									object === constants.NOTE_OBJECT &&
									(minimizeList.hasOwnProperty("new") || minimizeList.hasOwnProperty("New")) &&
									(minimizeList.new || minimizeList.New) &&
									minimizeData !== undefined &&
									minimizeData !== null &&
									minimizeData[field.name] !== null &&
									minimizeData[field.name] !== undefined &&
									minimizeData[field.name] !== ""
								) {
									tempfield.value = minimizeData[field.name].value;
								} else {
									if (object === constants.OPPORTUNITIES_OBJECT && field.name === "product") {
										let value =
											isValidParam(recordValue) &&
												getStringParam(recordValue[field.name]) !== ""
												? recordValue[field.name]
												: "";
										let isJSONValue = testJson(value);
										if (value !== null && value !== "" && isJSONValue === true) {
											var array = JSON.parse(value);
											tempfield.value = array;
											// autoCompleteDataSource[field.name] = array;
										} else {
											tempfield.value =
												isValidParam(recordValue) &&
													getStringParam(recordValue[field.name]) !== ""
													? recordValue[field.name]
													: "";
										}
									} else {
										tempfield.value = isValidParam(recordValue) && getStringParam(recordValue[field.name]) !== "" ? recordValue[field.name] : "";
									}
								}
							} else if (tempRecordId === 0 || field.is_required_field) {
								let minimizeList = customDrawer.minimizeList;
								let minimizeData = sfForm.sfFromData;
								if (
									object === constants.NOTE_OBJECT &&
									(minimizeList.hasOwnProperty("new") || minimizeList.hasOwnProperty("New")) &&
									(minimizeList.new || minimizeList.New) &&
									minimizeData !== undefined &&
									minimizeData !== null &&
									minimizeData[field.name] !== null &&
									minimizeData[field.name] !== undefined &&
									minimizeData[field.name] !== ""
								) {
									tempfield.value = minimizeData[field.name].value;
								} else {
									tempfield.value = field.default_value;
								}
							}
							//tempfield.value = isValidParam(recordValue) && getStringParam(recordValue[field.name]) !== '' ? recordValue[field.name] : field.default_value;
						}
					}
					//Rabinarayan Asha [29-06-2020]
					if (object === constants.PAYMENTS_OBJECT && (tempfield.name == "plan_name" || tempfield.name === "quoteno" || tempfield.name === "paymentno")) {
						if (tempfield.name === "quoteno") {
							let tempActiveTabInfo = getObjectParam(getActiveTabInfo());
							if (
								isValidParam(tempActiveTabInfo) &&
								tempActiveTabInfo.hasOwnProperty("quotation_number")
							) {
								tempfield.value = tempActiveTabInfo.quotation_number;
							}
						} else if (tempfield.name === "paymentno") {
							tempfield.value = new Date().valueOf().toString();
						} else if (tempfield.name === "plan_name") {
							let tempActiveTabInfo = getObjectParam(getActiveTabInfo());
							if (isValidParam(tempActiveTabInfo) && tempActiveTabInfo.hasOwnProperty("quotation_category")) {
								tempfield.value = tempActiveTabInfo.quotation_category;
							}
						}
					}
					// Manjuri Saha [19.02.2021]
					if (
						object === constants.INVENTORY_OBJECT &&
						tempfield.name === "inventory_no"
					) {
						if (tempRecordId === 0 || (tempRecordId > 0 && type === "copy")) {
							tempfield.value = new Date().valueOf().toString();
						}
					}
					//end
					if (object === constants.REFUND_OBJECT && (tempfield.name === 'receipt_no')) {
						if (tempRecordId == 0) {
							tempfield.value = new Date().valueOf().toString();
						}
					}
					// Manjuri Saha [27.04.2021]
					if (
						object === constants.APPOINTMENTS &&
						field.name === "subject" &&
						tempfield.value === "Followup" &&
						parentDetails.parent_object === constants.JOBS_OBJECT &&
						detailView.data.title !== ""
					) {
						tempfield.value = detailView.data.title;
					}
					//end
					if (
						object === constants.APPOINTMENTS &&
						app.me.calendar_setup &&
						app.me.calendar_setup.length > 0
					) {
						if (
							object === constants.APPOINTMENTS &&
							field.name === "location" &&
							tempRecordId === 0 &&
							(!callType || callType !== constants.JOB_SCHEDULER)
						) {
							tempfield.value = app.me.calendar_setup[0].location;
						}

						if (
							object === constants.APPOINTMENTS &&
							field.name === "text" &&
							tempRecordId === 0
						) {
							tempfield.value = app.me.calendar_setup[0].description;
						}
					}

					/*if (field.name === 'source' && object === constants.REPORTED_SALES_OBJECT) {
						let isFranchisor = app.me.is_franchisor;
						let isFranchisee = app.me.is_franchisee;
						let sourceValue = "";
						if (isFranchisor) {
							sourceValue = "Franchisor";
						} else if (isFranchisee) {
							sourceValue = "Franchisee";
						}
						tempfield.value = sourceValue;
					}*/

					/*if ((field.name == 'start_date' || field.name == 'end_date') && object === constants.REPORTED_SALES_OBJECT) {
						let items = getArrayParam(listValueMenuItems.duration);
						let durationType = "Week";
						let flag = items.find(f => f.props.value === 'Last Month');
						if (flag) {
							durationType = "Month";
						}
						let tmpId = items.length > 0 ? getIntParam(items[0].props.id) : 0;
						let startDate = '';
						let endDate = '';
						if (durationType === 'Month') {
							startDate = moment().subtract(tmpId, 'months').startOf('month').format(dateTimeFormat[app.me.date_format])
							endDate = moment().subtract(tmpId, 'months').endOf('month').format(dateTimeFormat[app.me.date_format]);
						} else if (durationType === 'Week') {
							startDate = moment().subtract(tmpId, 'week').startOf('isoWeek').format(dateTimeFormat[app.me.date_format])
							endDate = moment().subtract(tmpId, 'week').endOf('isoWeek').format(dateTimeFormat[app.me.date_format]);
						}
						if (field.name == 'start_date') {
							tempfield.value = startDate
						} else {
							tempfield.value = endDate
						}
					}*/

					tempfield.list_values = field.list_values; //getMultilingualNoteTypes(object, field.name, field.list_values);
					tempfield.isReadonly = field.is_readonly;
					tempfield.fieldType = field.field_type;
					tempfield.listType = field.list_type;
					tempfield.queryName = field.query_name;
					tempfield.isOpenPopover = false;
					tempfield.popoverTarget = null;
					tempfield.helperText = "";

					if (field.name === 'duration' && object === constants.REPORTED_SALES_OBJECT) {
						let tempDurationList = field.list_values;
						sortArrayObjectByIntegerProperty(tempDurationList, 'id', 'asc');
						tempfield.list_values = tempDurationList;
					}

					if (tempfield.name === "workflow_name" && tempfield.fieldType === "list" && field.list_values.length > 0) {

						let tempmenuItems = field.list_values;
						if (app.me.is_franchisor|| app.me.is_franchisee && object === constants.ACCOUNTS_OBJECT) {
							tempmenuItems = field.list_values.filter(e => (e.value !== 'Onboarding' && e.value !== 'Franchisee'));
						}
						if (
							app.me.is_franchisee && object === constants.ACCOUNTS_OBJECT && appContainer.drawerProps?.actionType === constants.CONVERT_TO_ACCOUNTS
						) {
							tempmenuItems = field.list_values.filter(e => (e.value !== 'Onboarding' && e.value !== 'Franchisee'));

						}
						if(object == constants.CONTACTS_OBJECT  && tempmenuItems.length > 0){
							tempmenuItems = tempmenuItems.filter(e => (e.value !== ''));
						}
						tempfield.list_values = tempmenuItems;
						workflowId = isValidParam(recordValue) ? getIntParam(recordValue["workflow_id"]) : 0;
						workflowId = workflowId <= 0 ? tempmenuItems[0].id : workflowId;

						if (tempmenuItems.length > 0) {
							setIsWorkflowShow(true);
						}

						let workflow = isValidParam(recordValue) ? getStringParam(recordValue["workflow_name"]) : "";
						
						workflow = workflow === "" ? tempmenuItems[0].value : workflow;
						tempfield.value = workflow;
						if ((object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT )&& tempRecordId === 0) {
							let userId = (app.me.id * 19000) + 7;
							let projectId = (app.me.projectId * 19000) + 7;
							let wfCookieName = "last-selected-wf-" + projectId + '-' + userId;
							let cWfNmae = JSON.parse(getCookie(wfCookieName));
							if (cWfNmae === "" || cWfNmae === undefined || cWfNmae === null) {
								tempfield.value = workflow;
								workflowId = workflowId;
							} else {
								let tempCwf = tempmenuItems.filter((f) => {
									return f.value === cWfNmae;
								})
								if (tempCwf.length > 0) {
									tempfield.value = cWfNmae;
									workflowId = tempCwf[0].id;
								} else {
									tempfield.value = workflow;
									workflowId = workflowId;
								}

							}

						}
					}

					if (
						object === constants.GROUP_OBJECT &&
						type === link.LinkToType.TYPE_COPY &&
						field.name === "name" &&
						isValidParam(recordValue)
					) {
						recordValue[field.name] = "Copyof_" + getStringParam(recordValue[field.name]);
					}

					if ((isDetail && type !== link.LinkToType.TYPE_COPY) || changeLog.isConvert) {
						let detailViewData = getObjectParam(detailView.data);

						if (
							tempRecordId === 0 &&
							field.is_lookup_field &&
							isDetail === true &&
							field.name !== "fullname"
						) {
							let lookupObject = getStringParam(field.lookup_object);
							let lookupFieldName = getStringParam(field.lookup_field_name);

							let titleFieldName = getStringParam(detailViewData.title_field_name);
							let title = getStringParam(detailViewData.title);

							if (parentObject === lookupObject) {
								if (parentObject === constants.CONTACTS_OBJECT) {
									let contactList = getArrayParam(detailViewData.avaliableContacts);
									title = contactList.length > 0 ? getStringParam(contactList[0].company) : "";
								}
								if (
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
								} else {
									if (
										object === constants.TASKS ||
										(object === constants.APPOINTMENTS &&
											getStringParam(titleFieldName) ===
											getStringParam(lookupFieldName))
									) {
										tempfield.value = title;
									}
								}

								let taskObjParam = getObjectParam(tasksObjParam)
								if (getBooleanParam(taskObjParam.isFranchiseRecord) && getStringParam(taskObjParam.selectOpt) == constants.UNIT_TASKS && field.name == 'company') {
									tempfield.value = app.me.tenant_company_name;
								}
							} else if (
								(object === constants.TASKS ||
									object === constants.APPOINTMENTS) &&
								isDetail === true &&
								(parentObject === constants.CONTACTS_OBJECT ||
									parentObject === constants.OPPORTUNITIES_OBJECT ||
									parentObject === constants.JOBS_OBJECT) &&
								field.name === "company"
							) {
								let list = [];
								if (parentObject === constants.CONTACTS_OBJECT) {
									if (
										detailViewData.hasOwnProperty("avaliableContacts") &&
										getArrayParam(detailViewData.avaliableContacts).length > 0
									) {
										list = getArrayParam(detailViewData.avaliableContacts);
									}
								} else if (parentObject === constants.OPPORTUNITIES_OBJECT) {
									if (
										detailViewData.hasOwnProperty("avaliableAccounts") &&
										getArrayParam(detailViewData.avaliableAccounts).length > 0
										&& appContainer.drawerProps.data.isSingleRecord != true
									) {
										list = getArrayParam(detailViewData.avaliableAccounts);
										let accountTableId =
											OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT];
										let accountid = getIntParam(list[0].id);
										if (accountTableId > 0 && accountid > 0) {
											let temp = linkInfoObject;
											temp[accountTableId] = accountid;
											setLinkInfoObject({ ...temp });
											setLinkInfo(temp);
										}
									}
								} else if (parentObject === constants.JOBS_OBJECT) {
									if (
										detailViewData.hasOwnProperty("avaliableAccounts") &&
										getArrayParam(detailViewData.avaliableAccounts).length > 0
									) {
										list = getArrayParam(detailViewData.avaliableAccounts);
										let accountTableId =
											OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT];
										let primarycompany = recordValue[field.name];
										let selectedAccount =
											primarycompany !== ""
												? list.filter((f) => {
													return f.company === primarycompany;
												})
												: list;
										let company =
											selectedAccount.length > 0
												? getStringParam(selectedAccount[0].company)
												: "";
										let accountid =
											selectedAccount.length > 0
												? getIntParam(selectedAccount[0].id)
												: 0;
										// let accountid = getIntParam(list[0].id);
										if (accountTableId > 0 && accountid > 0) {
											let temp = linkInfoObject;
											temp[accountTableId] = accountid;
											setLinkInfoObject(temp);

											setLinkInfo(temp);
										}
										tempfield.value = company;
										if (tempfield.value === "" && isDragNDrop) {
											tempfield.value = recordValue[field.name];
										}
									} else if (isDragNDrop) {
										tempfield.value = recordValue[field.name];
									}
								}
								if (
									getArrayParam(list).length > 0 &&
									parentObject !== constants.JOBS_OBJECT
								) {
									tempfield.value = getStringParam(list[0].company);
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									appointmentSub &&
									appointmentSub !== "" &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data === null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									appointmentSub &&
									appointmentSub !== "" &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data !== null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									isPowerCalling &&
									isPowerCalling === true &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data === null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									isPowerCalling &&
									isPowerCalling === true &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data !== null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									columnViewTaskApp &&
									columnViewTaskApp === true &&
									detailViewData.data === null &&
									recordValue !== null &&
									(object === constants.APPOINTMENTS ||
										object === constants.TASKS)
								) {
									tempfield.value = recordValue[field.name];
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									columnViewTaskApp &&
									columnViewTaskApp === true &&
									detailViewData.data !== null &&
									recordValue !== null &&
									(object === constants.APPOINTMENTS || object === constants.TASKS)
								) {
									tempfield.value = recordValue[field.name];
								}
							} else if (
								lookupFieldName !== "" &&
								titleFieldName !== "" &&
								lookupFieldName === titleFieldName
							) {
							} else {

								if (
									isValidParam(recordValue) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									var fieldValue = recordValue[field.name];
									tempfield.value = fieldValue;
								}
								if (fieldValue === "") {
									let recordValue = getObjectParam(detailViewData.record);
									tempfield.value = getStringParam(recordValue[lookupFieldName]);
								}
							}
						} else if (
							isDetail === true &&
							field.name === "fullname" &&
							(parentObject === constants.ACCOUNTS_OBJECT ||
								parentObject === constants.CONTACTS_OBJECT ||
								parentObject === constants.OPPORTUNITIES_OBJECT ||
								parentObject === constants.JOBS_OBJECT
							)
							&& appContainer.drawerProps.data.isSingleRecord != true

						) {
							if (getStringParam(tempfield.value).length === 0) {
								let selectedContacts = [];
								let primaryContact = null;
								let primaryEmail = getStringParam(detailViewData.email);
								let contactList = getArrayParam(detailViewData.avaliableContacts);
								if (parentObject === constants.JOBS_OBJECT) {
									primaryContact = recordValue[field.name];
								}

								if (parentObject !== constants.JOBS_OBJECT) {
									selectedContacts = primaryEmail !== "" ? contactList.filter((f) => f.email === primaryEmail) : contactList;
								} else {
									selectedContacts = primaryContact !== "" ? contactList.filter((f) => f.full_name === primaryContact) : contactList;
								}

								let fullname = selectedContacts.length > 0 ? getStringParam(selectedContacts[0].full_name) : "";
								let contactId = selectedContacts.length > 0 ? getIntParam(selectedContacts[0].id) : 0;
								if (
									parentObject === constants.ACCOUNTS_OBJECT ||
									parentObject === constants.CONTACTS_OBJECT ||
									parentObject === constants.OPPORTUNITIES_OBJECT ||
									parentObject === constants.JOBS_OBJECT
								) {
									tempfield.value = fullname;
									let taskObjParam = getObjectParam(tasksObjParam)
									if (getBooleanParam(taskObjParam.isFranchiseRecord) && getStringParam(taskObjParam.selectOpt) == constants.UNIT_TASKS && field.name == 'fullname') {
										tempfield.value = app.me.name;
									}
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									appointmentSub &&
									appointmentSub !== "" &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data === null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
									contactId = parentDetails.parent_record_id;
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									appointmentSub &&
									appointmentSub !== "" &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data !== null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
									contactId = parentDetails.parent_record_id;
								}

								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									isPowerCalling &&
									isPowerCalling === true &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data === null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
									contactId = parentDetails.parent_record_id;
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									isPowerCalling &&
									isPowerCalling === true &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data !== null &&
									recordValue !== null &&
									recordValue.hasOwnProperty(field.name) &&
									getStringParam(recordValue[field.name]) !== ""
								) {
									tempfield.value = recordValue[field.name];
									contactId = parentDetails.parent_record_id;
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									columnViewTaskApp &&
									columnViewTaskApp === true &&
									detailViewData.data === null &&
									recordValue !== null &&
									(object === constants.APPOINTMENTS ||
										object === constants.TASKS)
								) {
									tempfield.value = recordValue[field.name];
									contactId = parentDetails.parent_record_id;
								}
								if (
									parentObject === constants.CONTACTS_OBJECT &&
									columnViewTaskApp &&
									columnViewTaskApp === true &&
									detailViewData.data !== null &&
									recordValue !== null &&
									(object === constants.APPOINTMENTS ||
										object === constants.TASKS)
								) {
									tempfield.value = recordValue[field.name];
									contactId = parentDetails.parent_record_id;
								}
								if (
									parentObject === constants.ACCOUNTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									appointmentSub &&
									appointmentSub !== "" &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data !== null &&
									recordValue !== null &&
									getStringParam(recordValue[field.name]) === ""
								) {
									tempfield.value = "";
								}
								if (
									parentObject === constants.ACCOUNTS_OBJECT &&
									object === constants.APPOINTMENTS &&
									isPowerCalling &&
									isPowerCalling === true &&
									callType &&
									callType === "TYPE_LIST_VIEW" &&
									detailViewData.data !== null &&
									recordValue !== null &&
									getStringParam(recordValue[field.name]) === ""
								) {
									tempfield.value = "";
								}
								if (
									parentObject === constants.ACCOUNTS_OBJECT &&
									columnViewTaskApp &&
									columnViewTaskApp === true &&
									detailViewData.data !== null &&
									recordValue !== null &&
									getStringParam(recordValue[field.name]) === "" &&
									(object === constants.APPOINTMENTS ||
										object === constants.TASKS)
								) {
									tempfield.value = "";
								}

								if (contactId > 0) {
									//let linkInfo = "9_" + contactId;
									let temp = linkInfoObject;
									temp[9] = contactId;
									setLinkInfoObject(temp)

									setLinkInfo(temp);
								}
							}
						} else if (
							tempRecordId === 0 &&
							(field.name === "first_name" ||
								field.name === "last_name" ||
								field.name === "from_email" ||
								field.name === "phone") &&
							object === constants.CASES_OBJECT &&
							app.me.projectId !== constants.FARMINPEX_PROJECT_ID
						) {
							tempfield.value = "";
						} else if (
							tempRecordId > 0 &&
							field.name === "t_priority" &&
							object === constants.TASKS
						) {
							if (tempfield.value === "0") {
								tempfield.value = null;
							}
						} else if (
							tempRecordId > 0 &&
							field.name === "task_type" &&
							object === constants.TASKS
						) {
							if (tempfield.value === null || tempfield.value === "") {
								tempfield.value = null;
							}
						} else {

							/**START:   This section used for Auto-fill value mapping. Date: 2017-12-27 */
							if (
								isValidParam(recordValue) &&
								getStringParam(recordValue[field.name]) !== ""
							) {
								let minimizeList = customDrawer.minimizeList;
								let minimizeData = sfForm.sfFromData;
								if (
									object === constants.NOTE_OBJECT &&
									(minimizeList.hasOwnProperty("new") || minimizeList.hasOwnProperty("New")) &&
									(minimizeList.new || minimizeList.New) &&
									minimizeData !== undefined &&
									minimizeData !== null &&
									minimizeData[field.name] !== null &&
									minimizeData[field.name] !== undefined &&
									minimizeData[field.name] !== ""
								) {
									tempfield.value = minimizeData[field.name].value;
								} else {
									if (
										object === constants.OPPORTUNITIES_OBJECT &&
										field.name === "product"
									) {
										let value =
											isValidParam(recordValue) &&
												getStringParam(recordValue[field.name]) !== ""
												? recordValue[field.name]
												: "";
										if (value !== null && value !== "") {
											var array = JSON.parse(value);
											tempfield.value = array;
											// autoCompleteDataSource[field.name] = array;
										} else {
											tempfield.value =
												isValidParam(recordValue) &&
													getStringParam(recordValue[field.name]) !== ""
													? recordValue[field.name]
													: "";
										}
									}
									else if ((parentObject === constants.ACCOUNTS_OBJECT || parentObject === constants.CONTACTS_OBJECT) && object === "appointments" &&
										field.name === "assign" && isDetailView) {
										tempfield.value = field.default_value;
									}
									else {
										tempfield.value = recordValue[field.name];
									}
								}
							}
							/**END:   This section used for Auto-fill value mapping. Date: 2017-12-27 */
						}
						if (
							tempRecordId === 0 &&
							object === constants.APPOINTMENTS &&
							field.name === "start_date" &&
							parentDetails.parent_object === constants.JOBS_OBJECT &&
							!callType &&
							detailViewData.record.start_date !== ""
						) {
							let currentDateTime = moment().tz(
								timeZone[app.me.timezone]
							);
							let remainder1 =
								currentDateTime.minute() % constants.CALENDER_DEFAULT_INTERVAL;
							let currentTime = currentDateTime
								.subtract(remainder1, "m")
								.format(timeFormat[app.me.date_format]);

							let jobStartDateTime = detailViewData.record.start_date + " " + currentTime;
							let momentObj = moment(jobStartDateTime);
							let remainder = momentObj.minute() % constants.CALENDER_DEFAULT_INTERVAL;
							let jobstartDate = momentObj.subtract(remainder, "m").format(dateTimeFormat[app.me.date_format]);

							tempfield.value = jobstartDate;
						}
						if (
							tempRecordId === 0 &&
							object === constants.APPOINTMENTS &&
							field.name === "end_date" &&
							parentDetails.parent_object === constants.JOBS_OBJECT &&
							!callType &&
							detailViewData.record.end_date !== ""
						) {
							let currentDateTime = moment().tz(
								timeZone[app.me.timezone]
							);
							let remainder1 =
								currentDateTime.minute() % constants.CALENDER_DEFAULT_INTERVAL;
							let currentTime = currentDateTime
								.subtract(remainder1, "m")
								.format(timeFormat[app.me.date_format]);

							let jobEndTime =
								detailViewData.record.start_date + " " + currentTime;
							let jobEndDateTime = moment(jobEndTime);
							let endDate = jobEndDateTime
								.add(constants.CALENDER_DEFAULT_INTERVAL, "m")
								.format(dateTimeFormat[app.me.date_format]);
							tempfield.value = endDate;
						}
					}
					let fldName = field.name;
					if (
						getBooleanParam(isCompleAndNew) &&
						isValidParam(recordValue) &&
						recordValue.hasOwnProperty(fldName)
					) {
						tempfield.value = recordValue[field.name];
					}
					if (field.name === "sales_rep") {
						let tempLabel = getStringParam(field.label);
						tempLabel = getLocalizedStrings().label.COMMON.hasOwnProperty(tempLabel)
							? getLocalizedStrings().label.COMMON[tempLabel]
							: tempLabel;
						setNotifySalesRepLabel(getLocalizedStrings().label.COMMON.EMAIL + " " + tempLabel);
						setTextNotifySalesRepLabel(getLocalizedStrings().label.COMMON.TEXT + " " + tempLabel);
						if (isDetailView === true) {
							let tempisNotifySalesRep = recordValue?.sales_rep != app.me.name ? true : false;
							setIsNotifySalesRep(tempisNotifySalesRep);
						}
					}

					if (object === constants.CONTACTS_OBJECT && field.name === "t_status") {
						tempfield.is_modify_list = field.is_modify_list;
					}

					if (object === constants.REPORTED_SALES_OBJECT && field.name === 'unit' && app.me.is_franchisee && !app.me.is_franchisor) {
						tempfield.value = app.me.tenant_company_name;
					}
					if (object === constants.REPORTED_SALES_OBJECT && field.name === 'unit'
						&& app.me.is_franchisee && app.me.is_franchisor) {
						let rowIdx;
						let unitValue;

						if (listView.mounted == true) {
							rowIdx = listView.data.records?.findIndex((row) => row.id === tempRecordId);
							if (rowIdx == -1) {
								rowIdx = childListview.data.records?.findIndex((row) => row.id === tempRecordId);
								unitValue = childListview.data.records[rowIdx].unit;
							} else {
								unitValue = listView.data.records[rowIdx].unit;
							}
						} else {
							rowIdx = childListview.data.records?.findIndex((row) => row.id === tempRecordId);
							unitValue = childListview.data.records[rowIdx].unit;
						}
						tempfield.value = unitValue;
					}

					if (
						field.field_type === constants.FIELD_TYPE_TEXT &&
						((field.is_lookup_field && object !== constants.PAYMENTS_OBJECT) || (field.name === "first_name" && object === constants.CASES_OBJECT)) &&
						tempfield.helperText &&
						tempfield.helperText !== ""
					) {
						tempfield.helperText = "";
					}
					let menuItems = [];
					if (listValueMenuItems.hasOwnProperty(field.name)) {
						menuItems = getArrayParam(listValueMenuItems[field.name]);
						if (object !== constants.PAYMENTS_OBJECT) {
							let tempVal = tempfield.value === "" ? field.label : tempfield.value;
							menuItems = menuItems.filter((f) => f.props.fullName === tempVal);
						}
					}
					if ((field.field_type === constants.FIELD_TYPE_TEXT && field.is_lookup_field && object === constants.PAYMENTS_OBJECT && parentObject === constants.JOBS_OBJECT) || field.name === 'fullname' && listValueMenuItems[field.name] !== null) {
						let defaultValue = (tempfield.value && tempfield.value === "") ? (field.default_value === "" && field.list_values.length > 0) ? field.list_values[0].value : "" : tempfield.value;
						defaultValue = (object === constants.PAYMENTS_OBJECT && tempfield.value === "") ? menuItems.length > 0 ? menuItems[0].props.id : "" : defaultValue;
						tempfield.value = defaultValue;
					}

					if((field.field_type === constants.FIELD_TYPE_LIST && (field.list_type === constants.LIST_FIELD_TYPE_REGULAR || field.list_type === constants.LIST_FIELD_TYPE_USER 
						|| field.list_type === constants.LIST_FIELD_TYPE_EXTERNAL) && recordId > 0 )){
						if(recordValue[field.name] === null){
							tempfield.value = recordValue[field.name];
						}
					}
					tempFieldProps[field.name] = tempfield;
				});
			}
			if (tempFieldProps.hasOwnProperty("workflow_id")) {
				tempFieldProps["workflow_id"].value = workflowId;
			}
			if (
				tempRecordId === 0 &&
				isValidParam(recordValue) &&
				recordValue.hasOwnProperty("link_info") &&
				recordValue["link_info"]
			) {
				let link_info = recordValue["link_info"];
				if (isValidParam(link_info)) {
					let linkInfoArr = link_info.split(",");
					let templinkInfoObject = linkInfoObject;
					for (let i = 0; i < linkInfoArr.length; i++) {
						let objectInfo = linkInfoArr[i].split("_");
						templinkInfoObject[objectInfo[0]] = objectInfo[1];
					}
					setLinkInfo(templinkInfoObject);
					setLinkInfoObject({ ...templinkInfoObject });

				}
			}
			setQuickAddFormValues(tempFieldProps);

			let appTaskField = {};
			if (
				callFrom === "queueView" &&
				(object === constants.TASKS ||
					object === constants.APPOINTMENTS)
			) {
				if (isValidParam(appTaskCustomFields)) {
					appTaskCustomFields.forEach((fld, i) => {
						let fldName = fld.name;
						fld.value = recordValue[fldName];
						appTaskField[fldName] = fld;
					});
				}
			}
			let pinNoteChecked = false;
			let _importantNote = true;
			if (object === constants.NOTE_OBJECT) {

				if (minimizeList.hasOwnProperty("new") || minimizeList.hasOwnProperty("New")) {
					if (minimizeData['isImportantNote'] !== undefined) {
						_importantNote = minimizeData['isImportantNote']
					} else if(appContainer.customDrawerProps?.data?.isImportantNote !== undefined){
						_importantNote = getBooleanParam(
							appContainer.customDrawerProps?.data?.isImportantNote
						);
					}
					if (minimizeData['isPinNoteChecked'] !== undefined) {
						pinNoteChecked = minimizeData['isPinNoteChecked']
					}
				} else {
					if (tempRecordId > 0) {
						pinNoteChecked = getBooleanParam(
							appContainer.customDrawerProps.data.isPinnedNote
						);
					}
					if (tempRecordId > 0) {
						_importantNote = getBooleanParam(
							appContainer.customDrawerProps.data.isImportantNote
						);
					}
				}

			}
			if (
				constants.APPOINTMENTS !== object &&
				constants.TASKS !== object &&
				openMode === constants.SF_FORM_OPEN_MODE_QUICK
			) {
				let drawerPropsData = getObjectParam(
					appContainer.drawerProps.data
				);
				let mode = getStringParam(drawerPropsData.mode);
				if (mode !== constants.SF_FORM_OPEN_MODE_EXPANDED && object != constants.TIME_OBJECT) {
					tempFieldProps = setWFMenuItemList(
						workflowId,
						tempFieldProps["t_status"].name,
						tempFieldProps["t_status"].queryName,
						tempFieldProps["t_status"].listType,
						tempFieldProps,
						tempFields
					);
				}
			}

			if (
				getIntParam(tempRecordId) > 0 &&
				(object === constants.ACCOUNTS_OBJECT ||
					object === constants.CONTACTS_OBJECT) &&
				tempFieldProps.hasOwnProperty("country") &&
				isValidParam(tempFieldProps["country"]) &&
				getStringParam(tempFieldProps["country"].value) !== ""
			) {
				// if (tempFieldProps['state'].value === '') {
				tempFieldProps = setStateMenuItemList(getStringParam(tempFieldProps["country"].value), tempFieldProps);
				// }
			}

			if (
				getIntParam(tempRecordId) > 0 &&
				(object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.SALES_LEADS_OBJECT || object === constants.PARTNERS_OBJECT) &&
				tempFieldProps.hasOwnProperty("source") &&
				isValidParam(tempFieldProps["source"]) &&
				getStringParam(tempFieldProps["source"].value) !== ""
			) {
				// if (tempFieldProps['state'].value === '') {
				tempFieldProps = setSecondarySourceMenuItemList(getStringParam(tempFieldProps["source"].value), tempFieldProps);
				// }
			}

			if (
				getIntParam(tempRecordId) > 0 &&
				(object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) &&
				tempFieldProps.hasOwnProperty("state") &&
				isValidParam(tempFieldProps["state"]) &&
				getStringParam(tempFieldProps["state"]?.value) !== ""
			) {
				if (tempFieldProps.hasOwnProperty("city") && isValidParam(tempFieldProps["city"]) && tempFieldProps["city"]?.value === "") {
					tempFieldProps = setCityMenuItemList(getStringParam(tempFieldProps["state"].value), tempFieldProps);
				}
			}

			if (
				isDetail &&
				object === constants.CASES_OBJECT &&
				openMode === constants.SF_FORM_OPEN_MODE_QUICK
			) {
				getContactDetailsForCases(tempFieldProps);
			}

			if (getIntParam(tempRecordId) === 0) {
				if (
					isDetail &&
					(object === constants.APPOINTMENTS || object === constants.TASKS) &&
					parentObject !== null &&
					parentObject === constants.ACCOUNTS_OBJECT
				) {
					let _taskObjParam = getObjectParam(tasksObjParam)
					if (getBooleanParam(_taskObjParam.isFranchiseRecord) && getStringParam(_taskObjParam.selectOpt) === constants.UNIT_TASKS) {

					} else {
						getContactInfoForSelectedRecord(getIntParam(parentRecordId));
					}
				}
			}

			if (
				object === constants.ACCOUNTS_OBJECT ||
				object === constants.CASES_OBJECT ||
				object === constants.ISSUES_OBJECT 
			) {
				isAssignmentRuleExists(tempFieldProps["sales_rep"]);
			}

			if (
				object === constants.CASES_OBJECT &&
				app.selectedModuleName === constants.MODULE_HUB
			) {
				let submitterField = null;
				if(isValidParam(tempFieldProps["submitter"])){
					let firstSpaceIndex =  app.me.name.indexOf(' ');
					let firstName = '';
					let lastName = ''
					if (firstSpaceIndex === -1) {
						return { firstName:  app.me.name, lastName: '' };
					}
					firstName= app.me.name.substring(0, firstSpaceIndex);
					lastName = app.me.name.substring(firstSpaceIndex + 1).trim()
					let userListVal = getArrayParam(userlist.data);
					tempFieldProps["submitter"].list_values = userListVal?.map((m) => ({ id: m.value, value: m.value }));
					tempFieldProps["submitter"].default_value = app.me.name;
					tempFieldProps["submitter"].value = app.me.name;
					tempFieldProps["company"].value = app.me.tenant_company_name;
					tempFieldProps["first_name"].value = firstName;
					tempFieldProps["last_name"].value = lastName;
					if(isValidParam(tempFieldProps["sales_rep"])){
						tempFieldProps["sales_rep"].default_value = "Unassigned"
						tempFieldProps["sales_rep"].value =  "Unassigned"
						
					}
					submitterField = tempFieldProps["submitter"];
					setListValueMenuItems(submitterField);
					setHubCasesSubmitterInfo(tempFieldProps);
				}
			}


			if (object === constants.PROJECTS_OBJECT) {
				getUnitsList(tempFieldProps);
			}
			if (object === constants.REFUND_OBJECT && parentDetails.parent_object === constants.ACCOUNTS_OBJECT &&
				detailView.data.title !== "") {
				getPlanList(tempFieldProps);
			}
			if (object === constants.REPORTED_SALES_OBJECT) {
				const fieldIndex = tempFields?.findIndex(f => f.name === "unit");
				if (fieldIndex > -1) {
					if (app.me.is_franchisee && app.me.is_franchisor == false) {
						let temp = tempFields;
						temp[fieldIndex].is_readonly = true;
						temp[fieldIndex].default_value = app.me.tenant_company_name;
						setFields(temp);

					} else {
						let params = {};
						let promise = getUnitsWithMyClientId(params);
						if (isValidParam(promise)) {
							promise.then(response => {
								if (isValidParam(response)) {
									setListValueMenuItems({
										name: 'unit',
										list_values: response.map(r => ({ id: getIntParam(r.id), value: r.company })),
										is_required_field: false
									});
									setunits(response);
								}
							});
						}
					}
				}
			}

			if (recordValue) {
				getRequiredFields(tempFieldProps);
			}

			//priyanka 1.12.20, get task/appointment lookup info details
			if (
				isDetail &&
				isValidParam(parentDetails) &&
				parentDetails.parent_object === constants.CONTACTS_OBJECT &&
				(object === constants.APPOINTMENTS ||
					object === constants.TASKS)
			) {
				setChildAccountLookupInfo();
			}
			if (
				object === constants.TASKS &&
				tempFieldProps.hasOwnProperty('dep_start_date') &&
				tempFieldProps['dep_start_date'].name === "" &&
				tempFieldProps['dep_start_date'].value === "Task Dependent"
			) {
				setIsShowCustomTaskType(true);
				let log1 = changeLog;
				setchangeLog({ ...log1, isShowCustomTaskType: true });
			}
			if (changeLog.isStateCompleteAndNew && object == 'appointments' && appContainer.drawerProps.data != null) {
				tempFieldProps['subject'].value = "Followup";
				if (
					object === constants.APPOINTMENTS &&
					app.me.calendar_setup &&
					app.me.calendar_setup.length > 0
				) {
					tempFieldProps['location'].value = app.me.calendar_setup[0].location;
					tempFieldProps['text'].value = app.me.calendar_setup[0].description;
				}
			}
			if (
				object === constants.APPOINTMENTS &&
				tempFieldProps.hasOwnProperty('subject') &&
				tempFieldProps['subject'].value === "Followup" && appointmentSub === ""
			) {
				tempFieldProps['subject'].value = getLocalizedStrings().label.APPOINTMENTS_TASKS.FOLLOWUP;
			} else if (
				object === constants.APPOINTMENTS &&
				tempFieldProps.hasOwnProperty('subject') &&
				tempFieldProps['subject'].value === "Followup" &&
				(appointmentSub && appointmentSub !== "")
			) {
				tempFieldProps['subject'].value = appointmentSub;
			}
			if (changeLog.isStateCompleteAndNew) {
				setMounted(true);
			} else {
				setMounted(isMounted);
			}
			setFieldProps(tempFieldProps);
			handleForceupdateMethod();
			setAppTaskFieldProps(appTaskField);
			setIsPinNoteAlreadyChecked(pinNoteChecked);
			setIsPinNoteChecked(pinNoteChecked);
			setIsImportantNote(_importantNote);

		} catch (error) {
			console.error("Error in 'sfForm.js -> updateState()':" + error);
		}
	};

	const testJson = (text) => {
		if (typeof text !== "string") {
			return false;
		}
		try {
			var json = JSON.parse(text);
			return (typeof json === 'object');
		}
		catch (error) {
			return false;
		}
	}


	const getContactDetailsForCases = (tempFieldProps) => {
		let promise = null;
		let parentObject = null;
		let parentRecordId = 0;
		try {
			parentObject = getStringParam(parentDetails.parent_object).trim();
			parentRecordId = getIntParam(parentDetails.parent_record_id);

			let params = {};
			params.fields = ["company", "first_name", "last_name", "email", "phone"];
			if (parentRecordId > 0 && parentObject === constants.CONTACTS_OBJECT) {
				promise = Promise.resolve(
					getParentAccountContacts(parentRecordId, params)
				);
			} else if (
				parentRecordId > 0 &&
				parentObject === constants.ACCOUNTS_OBJECT
			) {
				params.parent_object = parentObject;
				params.parent_record_id = parentRecordId;
				promise = Promise.resolve(
					getListData(constants.CONTACTS_OBJECT, params)
				);
			}
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response) && isValidParam(response.records)) {
						let lookupInfo = [];

						let items = [];
						let records = null;
						records = getArrayParam(response.records);
						if (records.length > 0) {
							let recordValue = null;
							let contactlist = response.records;
							if (contactlist.length > 0) {
								let emailList = []
								contactlist.map((m) => {
									return emailList.push(m.email)
								})
								if (emailList.length > 0) {
									tempFieldProps["from_email"].list_values = emailList.map(email => ({ id: email, value: email }))
								}
							}
							setListValueMenuItems({
								name: "from_email",
								list_values: tempFieldProps["from_email"].list_values,
								is_required_field: true,
							});
							//***
							if (parentObject === constants.ACCOUNTS_OBJECT) {
								let email = detailView.mounted
									? getStringParam(detailView.data.email)
									: "";
								let tempArr = records.filter((f) => {
									return f.email === email;
								});
								recordValue = tempArr.length > 0 ? tempArr[0] : null;
							} else if (parentObject === constants.CONTACTS_OBJECT) {
								let tempArr = records.filter((f) => {
									return f.id === parentRecordId;
								});
								recordValue = tempArr.length > 0 ? tempArr[0] : null;
							}

							if (isValidParam(recordValue)) {
								let id = getIntParam(recordValue.id);
								let firstName = getStringParam(recordValue.first_name);
								let lastName = getStringParam(recordValue.last_name);
								let fullName = (firstName + " " + lastName).trim();
								fullName = fullName === "" ? "Empty" : fullName;
								let email = getStringParam(recordValue.email);
								let phone = getStringParam(recordValue.phone);
								tempFieldProps["first_name"].value = firstName;
								tempFieldProps["last_name"].value = lastName;
								tempFieldProps["from_email"].value = email;
								tempFieldProps["phone"].value = phone;
								let f1 = {
									lookup_field_name: "",
									lookup_object_name: constants.CONTACTS_OBJECT,
									record_id: id,
									title: ""
								};

								let f2 = {
									lookup_field_name: 'company',
									lookup_object_name: 'accounts',
									record_id: getIntParam(parentDetails.parent_record_id),
									title: records[0].company
								};

								if (object == 'cases') {
									lookupInfo.push(f2);
									let companyinfo = changeLog.autoCompleteDataSource['company'];
									let companyId = parentDetails.parent_record_id;
									if (companyinfo !== undefined) {
										companyId = companyinfo[0]?.id;

									}
									let promise = Promise.resolve(getFranchiseInfo(getIntParam(companyId)));
									promise.then((response) => {
										if (isValidParam(response)) {
											let recordVal = getObjectParam(response);
											setFranchiseId(getStringParam(recordVal.franchise_id));

										}
									});
								}
								lookupInfo.push(f1);

								setLookupInfoState(lookupInfo)
								records.forEach((record, index) => {
									record.name = (
										getStringParam(record.first_name) +
										" " +
										getStringParam(record.last_name)
									).trim();
									items.push(record);
								});
							}
						}
						tempFieldProps["first_name"].list_values = items;

						if (lookupInfo !== null) {
							setLookupInfo(lookupInfo, tempFieldProps);
						}
					}
				});
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> getContactDetails()':" + error);
		}
	};

	const setHubCasesSubmitterInfo = (fieldProps) => {
		try {
			let submitter = getStringParam(fieldProps["submitter"].value);
			let tempUserList = getArrayParam(userlist.data);
			let tempList = tempUserList.filter((f) => {
				return f.value === submitter;
			});
			if (tempList.length > 0) {
				let firstSpaceIndex =  submitter.indexOf(' ');
					let firstName = '';
					let lastName = ''
					if (firstSpaceIndex === -1) {
						return { firstName:  submitter, lastName: '' };
					}else{
						firstName= submitter.substring(0, firstSpaceIndex);
						lastName = submitter.substring(firstSpaceIndex + 1).trim()
					}
				
				let temp = fieldProps;
				temp["from_email"].value = getStringParam(tempList[0].email);
				if(tempList[0].phone !== ''){
					temp["phone"].value = getStringParam(tempList[0].phone);
				}
				temp["first_name"].value = firstName;
			    temp["last_name"].value = lastName;
				setFieldProps(temp);
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> setHubCasesSubmitterInfo()':" + error
			);
		}
	};

	const setRecurringState = (recurringData) => {
		setRecurring_enable(true);
		setRecurring_fields(recurringData);
		setRecurring_edit(true);

	};

	const setReminderData = (reminderData) => {
		sfForm.reminder = reminderData;
		setReminder(reminderData);
	};

	const getRecurringEditConfirmValue = (val) => {
		setIsSetActionType(true);
		let tempChangeLog = { ...changeLog };
		tempChangeLog.isSetActionType = true;
		setchangeLog(tempChangeLog);
		setSave_action_type(val);
		dispatch(getAppDialog(false, constants.RECURRING_EDIT_CONFIRM_DIALOG, null, null, null, null));
		let dialogeOpen = true
		saveSFForm(fieldProps, null, null, dialogeOpen, val);
	};

	const getRecurringDeleteChoice = (val) => {
		dispatch(getAppDialog(
			false,
			constants.RECURRING_DELETE_DIALOG,
			null,
			null,
			null,
			null
		));
		deleteRecord(recordId, val);
	};

	function deleteSFForm() {
		let hasPermission = false;
		// let checkValue = constants.ACCESS_TYPE_DELETE;
		// let securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS;
		let objectName = object;
		if (objectName === "note") {
			objectName = "notes";
		}
		hasPermission = hasAccessPermission(
			objectName,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_DELETE
		);
		if (hasPermission) {
			if (
				recurring_edit &&
				JSON.stringify(recurring_fields) !== "{}"
			) {
				let dataValue = {

					object: objectName,
				};
				dispatch(getAppDialog(
					true,
					constants.RECURRING_DELETE_DIALOG,
					getLocalizedStrings().message.REPEAT_DIALOG.DELETE_RECUR_TITLE,
					getRecurringDeleteChoice,
					dataValue
				));
			} else {
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
					getLocalizedStrings().message.APPOINTMENTS_TASKS.DELETE,
					() => deleteRecord(recordId, null),
					null
				);
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const deleteRecord = (id, delete_action_type) => {
		let params = {};
		params.id = [id];
		if (delete_action_type !== null) {
			params.delete_action_type = delete_action_type;
		}

		if (
			id > 0 &&
			(object === constants.APPOINTMENTS ||
				object === constants.NOTE_OBJECT ||
				object === constants.TASKS)
		) {
			let linkObject = getEndPointObject(object);
			let url = linkObject.DELETE;
			var promise = Promise.resolve(HTTPClient.post(url, params));
			promise.then((response) => {
				if (response.status === 0) {
					if (
						object === constants.APPOINTMENTS ||
						object === constants.TASKS
					) {
						if (object === constants.APPOINTMENTS) {
							// ************************ START: Notification delete.  ************************

							/** ----- */
							// ************************ END: Notification delete.  ************************
							dispatch(getCalendarRefresh(true));
							dispatch(getJobCalendarRefresh(true));
						}

						let activeTab = getObjectParam(getActiveTab());
						if (
							activeTab.object === constants.TASKS &&
							isValidParam(activeTab.info.getTaskPageData)
						) {
							activeTab.info.getTaskPageData(constants.TASKS);
						} else {
							dispatch(getTasksRefresh(true));
							let activeTab = getObjectParam(getActiveTab());
							if (activeTab.type === TYPE_HOME) {
								let tabs = tab.tabs;
								let tempTabs = tabs.filter((t) => {
									return t.type === TYPE_LIST_VIEW;
								});
								if (tempTabs !== null && tempTabs.length > 0) {
									refreshListView(object);
								}
							} else if (activeTab.type === TYPE_LIST_VIEW) {
								refreshListView(object);
							}

							if (isDetailView === true) {
								dispatch(refreshDetailViewListViewData(true, id));
							}
						}
						if (
							callFrom === "queueView" ||
							(activeTab.info.hasOwnProperty("is_callFrom_queue") &&
								getBooleanParam(activeTab.info.is_callFrom_queue))
						) {
							removeFromQueue(id, delete_action_type);
						}
					}

					dispatch(getAppDrawer(false, null, null, null,));
					dispatch(getAppCustomDrawer(
						false,
						null,
						null,
						null,
						null,
						null,
						null
					));
					dispatch(showCustomSnackBar(
						getLocalizedStrings().message.COMMON.DELETE,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					));
					if ((object === constants.APPOINTMENTS || object === constants.TASKS) && callFrom == "DetailView" && isDetailView) {
						dispatch(detailViewIsMounted(false));
						dispatch(getTasksRefresh(true));
						dispatch(refreshDetailViewListViewData(true));
						dispatch(refreshDetailViewData(true));

					}
					let _activeTab = getObjectParam(getActiveTab());
					if (object == 'tasks' && _activeTab.type === "TYPE_DETAIL_VIEW") {
						dispatch(detailViewIsMounted(false));
						dispatch(getTasksRefresh(true));
						dispatch(refreshDetailViewListViewData(true));
						dispatch(refreshDetailViewData(true));

					}
					if (callFrom === "ColumnViewAddTaskApp") {
						let _dataParams = {
							record_id: parentDetails.record_id,
							object: parentDetails.parentObject,
							listIndex: parentDetails.listIndex,
							cardIndex: parentDetails.cardIndex,
							updateCards: parentDetails.updateCards,
						};
						refreshColumnView(_dataParams);
					}
					if (object === constants.NOTE_OBJECT) {
						//Detail View Listview Refresh
						if (isDetailView === true) {
							let info = getActiveTabInfo();
							if (info.selectedPageNo > 1) {
								info.selectedPageNo = 1;
								updateTabInfo(info);
							}
							dispatch(refreshDetailViewListViewData(true, id));
						}
						let dataValue = getObjectParam(appContainer.drawerProps.data);
						if (
							getBooleanParam(dataValue.isPinnedNote) &&
							getBooleanParam(dataValue.isDetailView)
						) {
							getDetailViewPinnedNote();
						}
					}

					if (object == 'tasks' && openMode == 'CUSTOM' && _activeTab.type !== TYPE_COLUMN_VIEW) {
						let userId = app.me.id;
						let projectId = app.me.projectId;
						let _rememberTab = tab.rememberTab;
						let itemName;
						if (tab.tabs[0].item == "contacts") {
							itemName = 'TabInfo_' + 'contacts' + '_' + userId + '_' + projectId;
						} else {
							itemName = 'TabInfo_' + 'calendartask' + '_' + userId + '_' + projectId;

						}
						let tabDetails = getArrayParam(_rememberTab[itemName]);
						let params = {
							module_id: tabDetails[0].info.moduleId,
							start_index: 0,
							fetch_size: 20,
							sales_rep: tabDetails[0].info.userName,
							sort_order: tabDetails[0].info.sortOrder,
						};
						dispatch(getTasksPortal(params));

						//dispatch(listRefreshColumnView(object))

						listRefreshColumnView(object)
					}
					if (object == 'tasks' && openMode == 'CUSTOM' && _activeTab.object == 'tasks' && (_activeTab.info?.is_callFrom_queue == false || _activeTab.info?.is_callFrom_queue == undefined) && callFrom !== "queueView" && (_activeTab?.item == "calendartask" || _activeTab?.item == "accounts")) {
						let activeTab = tabManagerActiveTab();
						if (isValidParam(activeTab)) {
							closeTab(activeTab.item, activeTab.index);
						}
						if (isDetailView) {
							setTimeout(() => {
								dispatch(refreshDetailViewGroup(true));
								dispatch(detailViewIsMounted(false));
								dispatch(refreshDetailViewData(true));
								dispatch(refreshDetailViewListViewData(true));
							}, 600)
						}


					}

				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
				}
			});
		}
	};


	const setGroup = (event, value) => {
		if (value !== null) {
			let tempChangeLog = { ...changeLog };
			if (value === "----Add to this List----") {
				setIsAddToGroupList(true);
				setSelectedGroup("");
				tempChangeLog.selectedGroup = "";
			} else {
				setSelectedGroup(value);
				tempChangeLog.selectedGroup = value;
			}
			setchangeLog(tempChangeLog);
		}
	};

	const changeAddToGroup = (event) => {
		let value = event.target.value;
		setSelectedGroup(value);
		let tempChangeLog = { ...changeLog };
		tempChangeLog.selectedGroup = value;
		setchangeLog(tempChangeLog);
	};
	const addToThisGroupList = (event) => {

		let groupName = event.target.value;
		let params = {
			id: 0,
			name: groupName,
			description: groupName,
			t_status: "Active",
		};
		if (groupName !== "") {
			if (groupName.trim().length <= 128) {
				const response = saveGroup(object, params);
				response.then((response) => {
					if (response.status === 0) {
						if (response.data.id !== -1) {
							let tempStateGroups = stateGroups.filter((f) => {
								return f.label !== "----Add to this List----";
							});
							tempStateGroups.push({
								id: response.data.id,
								name: groupName,
								status: "Active",
								label: groupName,
							});
							sortArrayObjectByProperty(tempStateGroups, "label");
							tempStateGroups.push({
								id: -9999,
								name: "Add to This List",
								status: "Active",
								label: "----Add to this List----",
							});
							setStateGroups(tempStateGroups);
							setSelectedGroup(groupName);
							let tempChangeLog = { ...changeLog };
							tempChangeLog.selectedGroup = groupName;
							setchangeLog(tempChangeLog);
							setIsAddToGroupList(false);

							addGroupIntoReduxStore(response.data.id, groupName, "Active");
						} else {
							if (response.data.id === -1) {
								dispatch(showCustomSnackBar(
									"Value already exists in List",
									styles.snackbarBodyStyleError,
									styles.snackBarStyleTop
								));
								setIsAddToGroupList(false);
								return 0;
							}
						}
					}
				});
			} else {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS
						.GROUP_NAME_SIZE_LIMIT,
					null
				);
			}
		} else {
			setIsAddToGroupList(false);
		}
	};

	function saveForm() {
		try {
			setIsSaved(false)
			let tempChangeLog = { ...changeLog };
			if (!changeLog.isSavedDisabled) {
				setIsSavedDisabled(true);
				tempChangeLog.isSavedDisabled = true;
				// setchangeLog(tempChangeLog);
			}
			if (isValidParam(requiredFormsRefs.current)) {
				let requriredFormInstance = requiredFormsRefs.current;
				if (isValidParam(requriredFormInstance) && app.me) {
					let isValidForm = requriredFormInstance.saveSFForm();
					if (isValidForm) {
						let reqFieldProps = getObjectParam(
							requriredFormInstance.state.fieldProps
						);
						let reqLookupInfo = getArrayParam(
							requriredFormInstance.state.lookupInfo
						);
						let fieldPropsT = { ...fieldProps, ...reqFieldProps };
						let lookupInfoStateT = [...tempChangeLog.lookupInfoState, ...reqLookupInfo];
						if (object !== constants.ISSUES_OBJECT) {
							setFieldProps({ ...fieldPropsT });
							handleForceupdateMethod();
						}
						tempChangeLog.lookupInfoState = lookupInfoStateT;
						if (!app.me.is_inxpress) {
							saveSFForm(fieldPropsT);
						}
						if (app.me.is_inxpress && fieldProps !== null && fieldProps !== undefined) {
							greenlightValidation(fieldPropsT);
						}
						if (object === constants.ISSUES_OBJECT) {
							saveSFForm(fieldPropsT, "", tempChangeLog);
						}

					} else {
						setIsSavedDisabled(false);
						tempChangeLog.isSavedDisabled = false;
					}

				}
			} else {
				if (
					app.me.is_inxpress &&
					object === constants.ACCOUNTS
				) {
					greenlightValidation(fieldProps, "", tempChangeLog);
				} else {
					saveSFForm(fieldProps, "", tempChangeLog);
				}

			}
			setchangeLog(tempChangeLog);
			if (object === constants.TASKS) {
				let userId = app.me.id;
				let projectId = app.me.projectId;
				let _rememberTab = tab.rememberTab;
				let itemName;
				if (tab.tabs[0].item == "contacts") {
					itemName = 'TabInfo_' + 'contacts' + '_' + userId + '_' + projectId;
				} else {
					itemName = 'TabInfo_' + 'calendartask' + '_' + userId + '_' + projectId;

				}
				let tabDetails = getArrayParam(_rememberTab[itemName]);
				let params = {
					module_id: tabDetails[0].info.moduleId,
					start_index: 0,
					fetch_size: 20,
					sales_rep: tabDetails[0].info.userName,
					sort_order: tabDetails[0].info.sortOrder,
				};
				dispatch(getTasksPortal(params));
				let arr = []
				fieldProps["t_name"]?.list_values?.map((tname) => {
					arr.push(tname.value);
				})
				let isManualAddedTask = arr.includes(fieldProps["t_name"].value)
				if (isManualAddedTask == true) {
					updateSfRecentData(object, fieldProps["t_name"].value);
				}
				//dispatch(listRefreshColumnView(object))
			}
			if ((object == 'tasks' || object == 'appointments') && openMode == 'CUSTOM') {
				let _activeTab = getObjectParam(getActiveTab());
				if ((object == 'tasks' || object == 'appointments') && callFrom == "ColumnViewAddTaskApp" && 
				(_activeTab.object === constants.ACCOUNTS_OBJECT || _activeTab.object === constants.CONTACTS_OBJECT)
			&& header?.selectedHeader !== "Forecast") {
					listRefreshColumnView(_activeTab.object)
				}else if ((object == 'tasks' || object == 'appointments') && callFrom == "ColumnViewAddTaskApp" && 
				( _activeTab.object === constants.OPPORTUNITIES_OBJECT)
			&& header?.selectedHeader !== "Forecast") {
					listRefreshColumnView(constants.OPPORTUNITIES_OBJECT)
				}
				 else if ((object == 'tasks' || object == 'appointments') && callFrom == "ColumnViewAddTaskApp" && 
				(_activeTab.object === constants.ACCOUNTS_OBJECT || _activeTab.object === constants.CONTACTS_OBJECT || _activeTab.object === constants.OPPORTUNITIES_OBJECT)
			&& header?.selectedHeader == "Forecast") {
				let _dataParams = {
					record_id: parentDetails.record_id,
					object: parentDetails.parentObject,
					listIndex: parentDetails.listIndex,
					cardIndex: parentDetails.cardIndex,
					updateCards: parentDetails.updateCards,
				};
				refreshColumnView(_dataParams);
				} else if (_activeTab.object !== constants.OPPORTUNITIES_OBJECT) {
					listRefreshColumnView(object)
				}
				if (changeLog.isEditFormValid && detailView.isDetailView && unitTaskType?.task_type !== "Corrective Action") {
					reduxDetailView.mounted = false;
					dispatch(refreshDetailViewData(true));
					closeDrawer('save')
				}
			}
			let _activeTab = getObjectParam(getActiveTab());
			if(openMode === "EXPANDED" && type === "edit" && changeLog.isEditFormValid == true && showRequiredFormFields == false){
				        dispatch(detailViewIsMounted(false));
						dispatch(isDetailViewSet(false));
						refreshView()

			}
			if(_activeTab.object == "jobscheduler" && isFromJobScheduler){
				dispatch(getCalendarRefresh(true));
				dispatch(getJobCalendarRefresh(true));
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> saveForm()':" + error);
		}
	}

	const greenlightValidation = (fieldProp, isNext = "", changeLogValue) => {
		const tempChangeLog = changeLogValue || { ...changeLog };
		let isValidForm = true;
		if (fieldProp.workflow_name.value === constants.GREENLIGHT_WORKFLOW) {
			if (
				fieldProp.hasOwnProperty("company") &&
				(fieldProp.company.value === null || fieldProp.company.value === "")
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.COMPANY_NAME_BLANK,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				fieldProp.hasOwnProperty("company") &&
				fieldProp.company.value.length > 30
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.COMPANY_NAME_REQUIRED,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				fieldProp.hasOwnProperty("address1") &&
				(fieldProp.address1.value === null || fieldProp.address1.value === "")
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.ADDRESS1_REQUIRED,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				fieldProp.hasOwnProperty("address2") &&
				fieldProp.hasOwnProperty("address1") &&
				fieldProp.address1.value.length + fieldProp.address2.value.length > 30
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.ADDRESS1_ADDRESS2_LIMIT,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				fieldProp.hasOwnProperty("phone") &&
				(fieldProp.phone.value.length < 10 || isNaN(fieldProp.phone.value))
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.PHONE_NUMBER_REQUIRED,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				fieldProp.hasOwnProperty("phone") &&
				(fieldProp.phone.value.length !== 10 || isNaN(fieldProp.phone.value))
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.PHONE_NUMBER_LIMIT,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				fieldProp.hasOwnProperty("custom_field_float3") &&
				isNaN(fieldProp.custom_field_float3.value)
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.NET_ANNUAL_INVALID,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				fieldProp.hasOwnProperty("custom_field_float3") &&
				(fieldProp.custom_field_float3.value < 1 ||
					fieldProp.custom_field_float3.value === "")
			) {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.GREENLIGHT.NET_ANNUAL_LIMIT,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
				return isValidForm;
			} else if (
				(fieldProp.hasOwnProperty("state") &&
					fieldProp.state.value.length > 0) ||
				(fieldProp.hasOwnProperty("city") &&
					fieldProp.city.value.length > 0) ||
				(fieldProp.hasOwnProperty("zip_code") &&
					fieldProp.zip_code.value.length > 0)
			) {
				let input_param = {
					state: fieldProp.hasOwnProperty("state")
						? fieldProp.state.value
						: "",
					city: fieldProp.hasOwnProperty("city") ? fieldProp.city.value : "",
					zipcode: fieldProp.hasOwnProperty("zip_code")
						? fieldProp.zip_code.value
						: "",
				};
				var promise = Promise.resolve(
					HTTPClient.post(
						endPoints.ACCOUNTS.INXPRESS_CHECK_LOCATION,
						input_param
					)
				);
				promise.then((response) => {
					if (response.status !== 0) {
						dispatch(showCustomSnackBar(
							"State, City or Zip Code is Invalid.",
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						));
						isValidForm = false;
						setIsSavedDisabled(false)
						tempChangeLog.isSavedDisabled = false;
						setchangeLog(tempChangeLog);
					} else {
						saveSFForm(fieldProp, isNext, tempChangeLog);
					}
					return isValidForm;
				});
			} else {
				saveSFForm(fieldProp, isNext, tempChangeLog);
			}
		} else {
			saveSFForm(fieldProp, isNext, tempChangeLog);
		}
	};

	const saveSFForm = (tempfieldProps, typeValue, changeLogValue, callFrom = false, reccuringType) => {	
		let tempChangeLog = changeLogValue || { ...changeLog };
		if (typeValue !== "Next" && !tempChangeLog.isSavedDisabled) {
			setIsSavedDisabled(false)
			tempChangeLog.isSavedDisabled = false;
		}
		if (
			(object === constants.APPOINTMENTS ||
				object === constants.TASKS) &&
			recurringEditConfirmDialog &&
			!tempChangeLog.isSetActionType && callFrom == false
		) {
			let dataValue = {

				object: object,
			};
			dispatch(getAppDialog(
				true,
				constants.RECURRING_EDIT_CONFIRM_DIALOG,
				getLocalizedStrings().message.REPEAT_DIALOG.EDIT_RECUR_TITLE,
				getRecurringEditConfirmValue,
				dataValue,
				object
			));
			return false;
		}
		let tempFields = null;
		let tempField = null;
		let isValidForm = true;
		let dateValidationRequire = false;
		let end_date = null;
		let note_type = "";
		let note_text = "";
		let taskDateValidationRequire = false;
		let projectDateValidateRequire = false;
		let startDate = null;
		// try {
		let recordid =
			isValidParam(recordId) && toNumber(recordId) > 0
				? toNumber(recordId)
				: 0;
		if (isValidParam(tempfieldProps)) {
			tempFields = fields.filter((f) => {
				return f.field_type !== constants.FIELD_TYPE_HEADER;
			});
			for (let i = 0; i < tempFields.length; i++) {
				let formField = tempFields[i];
				let fieldForSave = tempfieldProps[tempFields[i].name];

				isValidForm = isValidateFormField(object, formField, fieldForSave);
				if (!isValidForm) {
					if (tempChangeLog.isSavedDisabled) {
						setIsSavedDisabled(false);
						tempChangeLog.isSavedDisabled = false;
					}
					dispatch(showCustomSnackBar(
						fieldForSave.helperText,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					focusElementByName(formField.name);
					break;
				}
				if (
					object === constants.APPOINTMENTS &&
					(formField.field_type === constants.FIELD_TYPE_DATE_TIME ||
						formField.field_type === constants.FIELD_TYPE_DATE)
				) {
					dateValidationRequire = true;
					if (formField.name === "start_date") {
						startDate = fieldForSave.value;
					} else if (formField.name === "end_date") {
						end_date = fieldForSave.value;
					}
				} else if (object === constants.NOTE_OBJECT) {
					if (formField.name === "note_type") {
						note_type = fieldForSave.value;
					} else if (formField.name === "note_text") {
						note_text = fieldForSave.value;
					}
				} else if (
					object === constants.TASKS &&
					(formField.field_type === constants.FIELD_TYPE_DATE_TIME ||
						formField.field_type === constants.FIELD_TYPE_DATE)
				) {
					taskDateValidationRequire = true;
				} else if (
					object === constants.PROJECTS_OBJECT &&
					(formField.field_type === constants.FIELD_TYPE_DATE_TIME ||
						formField.field_type === constants.FIELD_TYPE_DATE)
				) {
					projectDateValidateRequire = true;
					if (formField.name === "start_date") {
						startDate = fieldForSave.value;
					}
				}
			}

			if (dateValidationRequire) {
				if (
					dateValidationRequire &&
					startDate !== null &&
					startDate !== "Invalid date" &&
					end_date !== null &&
					end_date !== "Invalid date"
				) {
					let startDateMomentObj = moment(startDate);
					let endDateMomentObj = moment(end_date);
					if (endDateMomentObj.diff(startDateMomentObj) <= 0) {
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.APPOINTMENTS_TASKS
								.INVALID_START_END_DATE,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						));
						isValidForm = false;
					}
				} else {
					if (startDate === "Invalid date" && end_date === "Invalid date") {
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.APPOINTMENTS_TASKS
								.BLANK_START_END_DATE,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						));
					} else if (startDate === "Invalid date") {
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.APPOINTMENTS_TASKS
								.BLANK_START_DATE,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						));
					} else if (end_date === "Invalid date") {
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.APPOINTMENTS_TASKS.BLANK_END_DATE,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						));
					}
					isValidForm = false;
				}
			}
			if (taskDateValidationRequire && recurring_enable) {
				let startDateMomentObj = moment(tempfieldProps["t_dueby"].value);
				let recurObj = getObjectParam(recurring_fields);
				let endDateMomentObj = moment(recurObj.end_date);
				if (endDateMomentObj.diff(startDateMomentObj) <= 0) {
					dispatch(showCustomSnackBar(
						getLocalizedStrings().message.APPOINTMENTS_TASKS
							.INVALID_DUEBY_END_DATE,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					isValidForm = false;
				}
			}

			setStart_date(startDate);
			if (
				projectDateValidateRequire &&
				object === constants.PROJECTS_OBJECT
			) {
				let dueDateMomentObj = moment(tempfieldProps["due_date"].value);
				let startDateMomentObj = moment(startDate);
				if (dueDateMomentObj.diff(startDateMomentObj) <= 0) {
					dispatch(showCustomSnackBar(
						getLocalizedStrings().message.APPOINTMENTS_TASKS
							.INVALID_DUEBY_START_DATE,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					isValidForm = false;
				}
			}

			if (
				object === constants.TIME_OBJECT &&
				tempfieldProps["start_time"] &&
				tempfieldProps["end_time"]
			) {
				let dueDateMomentObj = moment(tempfieldProps["end_time"].value);
				let startDateMomentObj = moment(tempfieldProps["start_time"].value);
				if (
					dueDateMomentObj &&
					startDateMomentObj &&
					dueDateMomentObj.diff(startDateMomentObj) <= 0
				) {
					let message =
						tempfieldProps["end_time"].label +
						" " +
						getLocalizedStrings().message.APPOINTMENTS_TASKS
							.START_END_DATE_VALIDATE +
						" " +
						tempfieldProps["start_time"].label;
					dispatch(showCustomSnackBar(
						message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					isValidForm = false;
				}
			}

			if (note_type === "Note" && note_text.trim() === "") {
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.BATCH_ACTION.NOTE_TXT_CANNOT_BE_BLANK,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
				isValidForm = false;
			}

			if (
				(app.selectedModuleName === constants.MODULE_HUB ||
					app.selectedModuleName ===
					constants.MODULE_OPERATIONS) &&
				parentDetails.parent_object !== constants.CASES_OBJECT &&
				object !== constants.SOLUTIONS_OBJECT
			) {
				if (
					tempfieldProps.hasOwnProperty("note_text") &&
					note_text.trim() === ""
				) {
					dispatch(showCustomSnackBar(
						getLocalizedStrings().message.BATCH_ACTION
							.NOTE_TXT_CANNOT_BE_BLANK,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					isValidForm = false;
				}
			}

			if (
				object === constants.PAYMENTS_OBJECT &&
				tempfieldProps["payment_value"]
			) {
				if (tempfieldProps["payment_value"].value < 0) {
					dispatch(showCustomSnackBar(
						"Payment value cannot be negetive",
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					isValidForm = false;
				}

				tempfieldProps["payment_value"].value = reverseFormatNumber(
					tempfieldProps["payment_value"].value,
					"en"
				);
			}

			if (object === constants.APPOINTMENTS || object === constants.TASKS) {
				let tempRemainderArr = reminder;
				let isVal = true;
				let isValidTime = true;
				for (let r = 0; r < tempRemainderArr.length; r++) {
					let timeValue = tempRemainderArr[r].t_time_value;
					if (
						timeValue === "" ||
						timeValue === 0 ||
						timeValue === undefined
					) {
						isValidForm = false;
						isVal = false;
					}
					if (!isPositiveInteger(timeValue)) {
						isValidForm = false;
						isValidTime = false;
					}
				}
				if (!isVal) {
					dispatch(showCustomSnackBar(
						getLocalizedStrings().message.COMMON.REMINDER_VALUE,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
				}
				if (!isValidTime) {
					dispatch(showCustomSnackBar(
						getLocalizedStrings().message.COMMON.INVALID_TIME_ERROR_MSG,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
				}
			}

			if (isValidForm && object === constants.CONTACTS_OBJECT) {
				if (tempChangeLog.lookupInfoState !== null && (tempChangeLog.lookupInfoState.length > 0 || isDetailView)) {
					let lookupObj = getObjectParam(tempChangeLog.lookupInfoState[0]);

					let companyfieldValue = "";
					if (
						isDetailView &&
						getObjectParam(parentDetails).parent_object ===
						constants.ACCOUNTS_OBJECT
					) {
						companyfieldValue = getObjectParam(
							tempfieldProps["company"]
						).value;
					}

					if (
						lookupObj.lookup_object_name === constants.ACCOUNTS_OBJECT &&
						(lookupObj.lookup_field_name === "company" || companyfieldValue)
					) {
						let workflow = getObjectParam(
							tempfieldProps["workflow_id"]
						);
						let id = getIntParam(workflow.value);


					}
				}
			}

			if (
				object === constants.PRODUCTS_OBJECT &&
				tempfieldProps.hasOwnProperty("product_type") &&
				tempfieldProps["product_type"].value === "Inventory"
			) {
				if (
					tempfieldProps.hasOwnProperty("price") &&
					isValidParam(tempfieldProps["price"].value) &&
					tempfieldProps["price"].value !== ""
				) {
					let unitPrice = getFloatParam(tempfieldProps["price"].value);

					if (unitPrice < 0) {
						isValidForm = false;
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.PRODUCTS.NEGATIVE_PRICE,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						));
					}
				}
			}

			if (
				isValidForm &&
				(object === constants.TASKS || object === constants.APPOINTMENTS)
			) {
				if (reminder.length > 1) {
					let remPos = 0;
					for (let i = 0; i < reminder.length; i++) {
						let rem1 = getObjectParam(reminder[i]);
						let j = i + 1;
						if (j === reminder.length) {
							j = 0;
						}
						let rem2 = getObjectParam(reminder[j]);
						if (
							rem1.t_reminder_type === rem2.t_reminder_type &&
							rem1.t_time_type === rem2.t_time_type &&
							rem1.t_time_value === rem2.t_time_value
						) {
							isValidForm = false;
							remPos = i;
						}
						if (!isValidForm) {
							let remBlk = getObjectParam(reminder[remPos]);
							let remErrorMsg =
								"You can have only one " +
								remBlk.t_reminder_type +
								" reminder in the same time interval.";
							dispatch(showCustomSnackBar(
								remErrorMsg,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							));
						}
					}
				}
			}

			if (isValidForm) {

				let tempDateValue = null;
				let params = {};
				params.id = recordid;
				params.parent_object = "";
				params.parent_record_id = 0;
				params.is_convert = changeLog.isConvert;
				params.is_notify_sales_rep = isNotifySalesRep;
				if (object === constants.ACCOUNTS_OBJECT) {
					params.is_text_notify_sales_rep = isTextNotifySalesRep;
				}
				params.fields = [];
				params.type = typeValue;
				if (object === constants.NOTE_OBJECT) {
					if (
						isPinNoteChecked &&
						isPinNoteAlreadyChecked
					) {
						params.isPined = true;
					} else {
						params.isPined = isPinNoteChecked;
					}
					params.t_important_note = isImportantNote
						? "Yes"
						: "Empty";
				}
				let selectedRows = listView.selectedRows
				if (object === constants.TASKS && appContainer.drawerProps.data.isMultipleRecord === true) {
					let selectedRowId = selectedRows.map((val => {
						return val.id;
					}));
					params.parent_record_ids = selectedRowId;
					params.is_batch = true;
				}
				if (object === constants.TASKS) {
					params.is_unit_owner = record?.t_unit_owner && record?.t_unit_owner == 1 ? true : false;
					params.is_onboarding_type_task = isOnboardingTypeTask;
				}
				if (object === constants.APPOINTMENTS || object === constants.TASKS) {
					setLinkInfo(linkInfoObject);
				}
				if (isValidParam(requiredFormsRefs)) {
					if (object === constants.ACCOUNTS_OBJECT) {
						calculateForcastForAccount(tempfieldProps);
					}
				}
				if (object === constants.PAYMENTS_OBJECT) {
					let activeTabInfo = getObjectParam(getActiveTabInfo());
					params.quotation_id = getIntParam(activeTabInfo.quotation_id);
				}
				if (
					detailView.isDetailView &&
					object === constants.PAYMENTS_OBJECT &&
					parentDetails.parent_object === constants.JOBS_OBJECT
				) {
					let quotation_number = tempfieldProps["quoteno"].value;
					let quoteObj = invoiceList.filter((f) => {
						return f.quoteno === quotation_number;
					});
					params.quotation_id = quoteObj.length > 0 ? quoteObj[0].id : 0;
				}

				// used for balance due in quotation --- Lalu
				if (object === constants.PAYMENTS_OBJECT) {
					if (
						tempChangeLog.lookupInfoState.length > 0 &&
						getStringParam(tempChangeLog.lookupInfoState[0].lookup_object_name) ===
						constants.QUOTATION_OBJECT
					) {
						params.call_from = constants.QUOTATION_OBJECT;
						params.quotation_id = getIntParam(tempChangeLog.lookupInfoState[0].record_id);
					} else {
						params.call_from = constants.QUOTATION_OBJECT;
					}
				}

				for (let key in tempfieldProps) {
					tempField = {};
					tempField.id = tempfieldProps[key].id;
					tempField.name = tempfieldProps[key].name;
					if (tempfieldProps[key].fieldType === constants.FIELD_TYPE_DATE_TIME) {
						if (typeof tempfieldProps[key].value === "string") {
							// for Empty value  -- Lalu
							if (getStringParam(tempfieldProps[key].value) === "") {
								tempDateValue = "";
							} else {
								tempDateValue = moment(tempfieldProps[key].value);
							}
						} else {
							// for Empty value  -- Lalu
							if (getStringParam(tempfieldProps[key].value) === "") {
								tempDateValue = null;
							} else {
								tempDateValue = tempfieldProps[key].value;
							}
						}

						if (isValidParam(tempDateValue) && tempDateValue != "") {
							tempDateValue = tempDateValue.format("YYYY-MM-DD HH:mm:ss");
							tempField.value = tempDateValue;
						} else {
							// for Empty value  -- Lalu
							tempField.value = tempDateValue;
						}
					} else if (
						tempfieldProps[key].fieldType === constants.FIELD_TYPE_DATE
					) {
						if (typeof tempfieldProps[key].value === "string") {
							tempDateValue = moment(tempfieldProps[key].value);
						} else {
							tempDateValue = tempfieldProps[key].value;
						}

						if (isValidParam(tempDateValue)) {
							tempDateValue = tempDateValue.format("YYYY-MM-DD");
							tempField.value = tempDateValue;
						} else {
							tempField.value = "";
						}
					} else if (
						tempfieldProps[key].name === "note_text" &&
						getStringParam(tempfieldProps[key].value) === ""
					) {
						tempField.value = tempfieldProps["note_type"].value;
					} else if (object === constants.TASKS && tempfieldProps[key].name === "location") {
						tempField.value = isValidParam(locationState) ? locationState : tempfieldProps[key].value;
					} else if (object === constants.APPOINTMENTS && tempfieldProps[key].name === "location") {
						tempField.value = tempfieldProps["location"].value;
					} else if (object === constants.OPPORTUNITIES_OBJECT && tempField.name === "product") {
						let tempArr = getArrayParam(tempfieldProps[key].value);
						let recordNames = "";
						if (tempArr.length > 0) {
							tempArr.forEach((record) => {
								if (isValidParam(record)) {
									let recordName = getStringParam(record.name);
									recordNames = (recordNames === "") ? recordName : recordNames + "," + recordName;
								}
							});
						}
						tempField.value = recordNames;
					} else {
						tempField.value = tempfieldProps[key].value;
					}

					if (
						object === constants.ACCOUNTS_OBJECT &&
						tempField.name === "company" &&
						isValidParam(tempField.value) &&
						getStringParam(tempField.value) === ""
					) {
						let firstName = tempfieldProps.hasOwnProperty("first_name")
							? getStringParam(tempfieldProps["first_name"].value)
							: "";
						let lastName = tempfieldProps.hasOwnProperty("last_name")
							? getStringParam(tempfieldProps["last_name"].value)
							: "";
						tempField.value = (firstName + " " + lastName).trim();
						//tempfieldProps['company'].value = fullName;
					}

					if (object === constants.REPORTED_SALES_OBJECT && tempField.name === "unit") {
						if (app.me.is_franchisee && app.me.is_franchisor == false) {
							params.myclient_id = app.me.projectId;
						} else {
							let unitField = units.find(fe => fe.company === tempField.value);
							if (unitField) {
								params.myclient_id = unitField.myclient_id;
							}
						}
					}
					if (object === constants.OPPORTUNITIES_OBJECT) {
						if (
							tempField.name !== "company" &&
							tempField.name !== "contact_name"
						) {
							if (tempField.name === "link_info") {
								tempField.value =
									getStringParam(linkInfo).length > 0
										? getStringParam(linkInfo)
										: getStringParam(
											tempfieldProps[tempField.name].value
										);
							}
							params.fields.push(tempField);
						}
					} else {
						params.fields.push(tempField);
					}
					if ((object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT) && tempField.name === "workflow_name" && tempfieldProps[key].fieldType === "list" && isValidParam(tempField.value) && getStringParam(tempField.value) !== "") {
						let userId = (app.me.id * 19000) + 7;
						let projectId = (app.me.projectId * 19000) + 7;
						let wfCookieName = "last-selected-wf-" + projectId + '-' + userId;
						setCookie(wfCookieName, JSON.stringify(tempField.value));

					}
				}

				if (
					object === constants.OPPORTUNITIES_OBJECT &&
					!tempfieldProps.hasOwnProperty("forecast_amount")
				) {
					params.fields.push({
						id: 0,
						name: "forecast_amount",
						value: getFloatParam(changeLog.forCastVal),
					});
				}
				if (
					object === constants.ACCOUNTS_OBJECT &&
					!tempfieldProps.hasOwnProperty("forecast_amount")
				) {
					params.fields.push({
						id: 0,
						name: "forecast_amount",
						value: getFloatParam(forCastValAcc),
					});
				}

				if (
					object === constants.OPPORTUNITIES_OBJECT &&
					!tempfieldProps.hasOwnProperty("probability")
				) {
					params.fields.push({
						id: 0,
						name: "probability",
						value: getFloatParam(changeLog.probability, 1),
					});
				}

				if (
					object === constants.OPPORTUNITIES_OBJECT &&
					!tempfieldProps.hasOwnProperty("link_info")
				) {
					params.fields.push({ id: 0, name: "link_info", value: linkInfo });
				}
				if (
					paymentType === "Refund" &&
					!tempfieldProps.hasOwnProperty("quoteno")
				) {
					let quoteno = getStringParam(record.quoteno);
					params.fields.push({ id: 0, name: "quoteno", value: quoteno });
					let lookupInfoArr = [
						{
							lookup_field_name: "quoteno",
							lookup_object_name: constants.QUOTATION_OBJECT,
							record_id: quoationId,
							title: quoteno,
						},
					];
					setLookupInfoState(getArrayParam(lookupInfoArr));
					tempChangeLog.lookupInfoState = lookupInfoArr;
				}
				if (
					(object === constants.CASES_OBJECT ||
						object === constants.NOTE_OBJECT) &&
					(app.selectedModuleName === constants.MODULE_HUB ||
						app.selectedModuleName === constants.MODULE_OPERATIONS)
				) {
					params.call_from = app.selectedModuleName;
				}

				//START: Set Parent Info

				let parentObject = getStringParam(parentDetails.parent_object).trim();
				let parentRecordId = getIntParam(parentDetails.parent_record_id);
				params = { ...params, ...parentDetails };
				if (isDetailView) {
					let tempArr = []
					let _lookupInfoState = [...getArrayParam(tempChangeLog?.lookupInfoState)];
					if (_lookupInfoState.length > 0) {
						tempArr = _lookupInfoState.filter((f) => {
							return f.lookup_object_name.trim() === parentObject;
						});
					}


					if (tempArr.length > 0) {
						params.parent_object = getStringParam(
							tempArr[0].lookup_object_name
						);
						params.parent_record_id = getIntParam(tempArr[0].record_id);
						tempArr = tempChangeLog.lookupInfoState.filter((f) => {
							return f.lookup_object_name !== parentObject;
						});
						setLookupInfoState(tempArr);
						tempChangeLog.lookupInfoState = tempArr;
					}


				}

				if (
					object === constants.TASKS ||
					object === constants.APPOINTMENTS
				) {
					let lookupArr = generateLookupInfoList();
					setLookupInfoState(lookupArr);
					tempChangeLog.lookupInfoState = lookupArr;
				}
				//END: Set Parent Info
				setchangeLog({ ...tempChangeLog });
				if (object == constants.CASES_OBJECT && parentObject == "opportunities") {
					params.lookup_info = [tempChangeLog.lookupInfoState];

				}
				else if (object === constants.CASES_OBJECT) {
					params.lookup_info = lookupInfoState;
				}
				else {
					params.lookup_info = tempChangeLog.lookupInfoState;

				}
				if (type === link.LinkToType.TYPE_COPY) {
					params.id = 0;
				}

				if (object === constants.APPOINTMENTS || object === constants.TASKS) {
					if (
						recurring_fields !== null &&
						recurring_fields !== ""
					) {
						params.is_repeat = true;
						params.save_action_type = reccuringType;
					}

					params.reminder = reminder;
					if (recurring_enable) {
						params.recurring_fields = recurring_fields;
						params.is_repeat = true;
					} else {
						params.recurring_fields = {
							start_date: "",
						};
						params.is_repeat = false;
					}

					if (noteDataObj !== null && noteDataObj.note_text !== "" && noteDataObj.note_text !== undefined) {
						params.note_type = getStringParam(noteDataObj.note_type);
						params.note = getStringParam(noteDataObj.note_text);
					}
					if (linkInfo !== null && linkInfo !== "") {
						params.link_info = getStringParam(linkInfo);
						params.location = getStringParam(locationState);
					}
					/*Add link info and location in Fields Params*/
					let tempLinkInfoField = {};
					tempLinkInfoField.id = -99;
					tempLinkInfoField.name = "link_info";
					tempLinkInfoField.value = linkInfo;
					params.fields.push(tempLinkInfoField);

					params.parent_recurId = parentRecurId;

					if (object === constants.APPOINTMENTS) {
						if (
							isValidParam(attendeesDataArr) &&
							Array.isArray(attendeesDataArr) &&
							attendeesDataArr.length > 0
						) {
							params.attendees = attendeesDataArr;
						}
					}
					if (object === constants.TASKS) {

						let tempIsWorkflowTaskField = {};
						tempIsWorkflowTaskField.id = -999;
						tempIsWorkflowTaskField.name = "t_workflow_task";
						tempIsWorkflowTaskField.value = isWorkflowTask;
						params.fields.push(tempIsWorkflowTaskField);
					}
				}
				if (object == constants.TASKS) {
					let _files = getObjectParam(attchedTaskFiles);

					let info = record && isValidParam(record.t_attachment) ? JSON.parse(record.t_attachment) : {};

					info.attached_files = getObjectParam(_files);
					params.fields.push({ id: 0, name: 't_attachment', value: info });

				}

				if (
					openMode === constants.SF_FORM_OPEN_MODE_QUICK ||
					openMode === constants.SF_FORM_OPEN_MODE_CUSTOM
					// && recordId === 0
				) {
					addDefaultValueForQuickAddForm(params.fields);
				}
				if (tempChangeLog.selectedGroup !== "") {
					let tempGroups = [...stateGroups];
					tempGroups = tempGroups.filter((f) => {
						return f.name === tempChangeLog.selectedGroup;
					});
					setGroup(tempGroups);
					if (tempGroups.length > 0) {
						params.groups = [tempGroups[0].id];
						params.is_group_link = true;
					}
				}

				if (isNoteObjectLink && flyingNoteText !== "") {
					params.flying_note_text = flyingNoteText;
				}

				if (object == "reportedsales") {
					let _files = attachFiles;
					_files = [..._files];
					let info = {};
					let isAttachment = params.fields.find((f) => f.name == "t_attachment")
					info.attached_files = getObjectParam(_files);
					if (isAttachment == null) {
						const _value = { "attached_files": [...attachFiles] };
						params.fields.push({ id: 0, name: 't_attachment', value: { ..._value } });
					} else {

						params.fields.map((m) => {
							if (m.name == "t_attachment" && attachFiles.length > 0) {
								const _value = { "attached_files": [...attachFiles] };
								return m.value = _value;
							} else if (m.name == "t_attachment" && record.t_attachment !== undefined) {
								return m.value = JSON.parse(record.t_attachment);
							}
						})
					}
				}
				if (
					object === constants.CASES_OBJECT &&
					recordid === 0 &&
					tempfieldProps.hasOwnProperty("problem_description")
				) {
					let noteText = getStringParam(
						tempfieldProps.problem_description.value
					);
					if (noteText !== "") {
						params.is_note_link = true;
						params.note = {
							note_type: "Note",
							note_text: app.me.is_franchisor == true ? noteText : flyingNoteText,
							author: app.me.name,
							creator: app.me.name,
						};
						params.fields.push({ id: 0, name: 'note', value: flyingNoteText });
					}

				}

				if (
					app.me.projectId ===
					constants.CORPORATE_ACCOUNT_PROJECTID &&
					object === constants.ACCOUNTS_OBJECT
				) {
					let workflowName = getStringParam(
						tempfieldProps.workflow_name.value
					);
					if (workflowName === constants.CORPORATE_ACCOUNT_PARTNER_WORKFLOW) {
						params.is_partner = true;
					}
				}
				if (object === constants.PAYMENTS_OBJECT) {
					if (
						app.me.hasOwnProperty("xero_integration") &&
						app.me.xero_integration.is_xero
					) {
						params.is_addXero = getBooleanParam(isAddToQuickbook);
					} else {
						params.is_addQB = getBooleanParam(isAddToQuickbook);
					}
				}
				if (
					openMode === constants.SF_FORM_OPEN_MODE_QUICK &&
					object === constants.ACCOUNTS_OBJECT
				) {
					let cmpFieldForSave = params.fields.filter((f) => {
						return f.name === "company";
					});
					if (cmpFieldForSave.length === 0) {
						let accFieldDetails = sfForm.data[object];
						let accFields = accFieldDetails.fields;
						let cmpFieldInForm = accFieldDetails.fields.filter((f) => {
							return f.name === "company";
						});
						if (cmpFieldInForm.length > 0) {
							let cmpFieldObj = {
								id: cmpFieldInForm[0].id,
								name: cmpFieldInForm[0].name,
								value:
									tempfieldProps["first_name"].value +
									" " +
									tempfieldProps["last_name"].value,
							};
							params.fields.push(cmpFieldObj);
						}
					}
				}

				//  START:   Duplicate Email Checking.
				let dataCheck = false;
				if (
					object === constants.ACCOUNTS_OBJECT ||
					object === constants.CONTACTS_OBJECT ||
					object === constants.OPPORTUNITIES_OBJECT ||
					 object === "customobjectone" ||
					 object === "customobjecttwo" ||
					 object === "customobjectthree" ||
					 object === "customobjectfour" ||
					 object === constants.PARTNERS_OBJECT

				) {
					defaultRule.field_names.forEach((item, Index) => {
						let dataValue = tempfieldProps.hasOwnProperty(item.field_name)
							? getStringParam(tempfieldProps[item.field_name].value)
							: "";
						let existingdata =
							recordid > 0 && isValidParam(record)
								? getStringParam(record[item.field_name])
								: "";
						if (
							(recordid === 0 && dataValue !== "") ||
							(recordid > 0 &&
								appContainer.drawerProps.actionType !==
								constants.COPY &&
								dataValue !== "" &&
								dataValue !== existingdata) ||
							(recordid > 0 &&
								appContainer.drawerProps.actionType ===
								constants.COPY &&
								dataValue !== "")
						) {
							dataCheck = true;
						}
						if(( object === "customobjectone" ||
							object === "customobjecttwo" ||
							object === "customobjectthree" ||
							object === "customobjectfour" )&& dataValue !== "" ){
							dataCheck = true;
						}
					});
				}
				// params.actual_work_time = this.state.record['actual_work_time']
				let email = tempfieldProps.hasOwnProperty("email")
					? getStringParam(tempfieldProps.email.value)
					: "";
				let existingEmail =
					recordid > 0 && isValidParam(record)
						? getStringParam(record["email"])
						: "";
				let appData = appContainer.drawerProps.data;
				if ((object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === "customobjectone" ||
					object === "customobjecttwo" ||
					object === "customobjectthree" ||
					object === "customobjectfour" ||
					object === constants.PARTNERS_OBJECT) &&
				((recordid >= 0 && dataCheck))) {
				checkDuplicateEmail(tempfieldProps, object, params, recordid);
				if (typeValue === constants.NEXT) {
					setShowRequiredFormFields(true);
				}
			} else if (typeValue === constants.NEXT) {
					setShowRequiredFormFields(true);
				} else if (object === constants.PRODUCTS_OBJECT) {
					checkDuplicateProduct(object, params, recordid);
				} else if (object === constants.PAYMENTS_OBJECT) {
					checkBalanceDue(object, params, recordid);
				}
				else if (object === constants.TASKS && appData.isUnitask) {
					addAddTaskFunc(object, params, recordid);

					let param = {};
					let tempRows = appContainer.drawerProps.data.parent_record_id;
					param.child_record_id = tempRows;
					param.task_app_value = detailViewTaskAppintmentReducer.taskAppValue !== undefined ? detailViewTaskAppintmentReducer.taskAppValue : constants.TASK_APP_OPEN;
					dispatch(getUnitOwnerTasks(param));

				} else {
					checkDuplicateFranchiseId(object, params, recordid);

				}
				//  END:   Duplicate Email Checking.
			} else if (!isValidForm && tempChangeLog.isSavedDisabled) {
				setIsSavedDisabled(false);
				tempChangeLog.isSavedDisabled = false;
			}
		}
		setchangeLog({ ...tempChangeLog });
		/*if (object == "reportedsales" && appContainer.drawerProps.actionType === constants.EDIT && appContainer.drawerProps.actionDialogName == "Edit Reported Sales") {
			dispatch(getAppDrawer(false, null, null, null, null));
			refreshListView(object);
			dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
		}*/
		if (appContainer.drawerProps.actionType === constants.CONVERT_TO_ACCOUNTS) {
			dispatch(refreshDetailViewHeader(false));
			reduxDetailView.mounted = false;
			dispatch(refreshDetailViewData(true));

		}
		let _temp = changeLog;
		_temp.isEditFormValid = isValidForm;
		setchangeLog({..._temp})
		// } catch (error) {
		// 	console.error("Error in 'sfForm.js -> saveSFForm()':" + error);
		// }
		return isValidForm;
	};

	const addAddTaskFunc = (object, params, recordid) => {
		let selectedRows = appContainer.drawerProps.data.parent_record_id;
		let tempChangeLog = changeLog;
		params.parent_object = constants.ACCOUNTS_OBJECT;
		let parentObject = getStringParam(params.parent_object).trim();
		let parentRecordIds = getArrayParam(selectedRows);
		let tempArr = [];
		let temp = [];
		let _lookupInfoState = [...getArrayParam(tempChangeLog?.lookupInfoState)];
		if (_lookupInfoState.length > 0) {
			tempArr = _lookupInfoState.filter((f) => {
				return f.lookup_object_name == parentObject;
			});
		}
		parentRecordIds.forEach((record) => {
			let _unitObj = {};
			if (tempArr[0] && tempArr[0].lookup_object_name && tempArr[0].record_id) {
				_unitObj.lookup_object_name = getStringParam(tempArr[0].lookup_object_name);
				_unitObj.record_id = getIntParam(tempArr[0].record_id);
				_unitObj.parent_record_id = record
				temp.push(_unitObj);
			}
		})

		let lookupArr = generateLookupInfoList();
		setLookupInfoState(lookupArr);
		tempChangeLog.lookupInfoState = lookupArr;
		setchangeLog({ ...tempChangeLog });

		params.link_info = getStringParam(linkInfo);
		params.lookup_info = temp;
		params.parent_record_ids = selectedRows;
		var promise = Promise.resolve(HTTPClient.post(endPoints.TASK.SAVE_BATCH_UNIT_TASK, params));
		if (isValidParam(promise)) {
			promise.then((response) => {
				if (response.status == 0) {
					dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					closeDrawer("save");
				}

			})
		}

	}

	const getDublicateDefaultRules = (objectName) => {
		try {
			let defaultRuleValue = new Object();
			if (objectName !== "") {
				let tableId = OBJECT_TABLEID_MAP[objectName];
				if (tableId > 0) {
					let rulesParams = { table_id: tableId };
					let url = endPoints.DUPLICATE_RULE.GET;
					let promise = Promise.resolve(HTTPClient.get(url, rulesParams));
					promise.then((response) => {
						if (isValidParam(response)) {
							response.forEach((items, Index) => {
								if (items.is_default === 1 && items.is_active === 1) {
									defaultRuleValue = items;
								}
							});
						}
						setDefaultRule(defaultRuleValue);
					});
				}
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> getDublicateDefaultRules()':" + error
			);
		}
	};

	const checkDuplicateEmail = (tempfieldProps, objectName, params, recordid) => {
		try {
			let searchParams = {};
			let searchFld = "";
			let searchtxt = "";
			let msgLabel = "";
			let fieldsVal = sfForm.data[object].fields;
			if (defaultRule !== null && defaultRule.field_names.length > 0) {
				defaultRule.field_names.forEach((item, Index) => {
					if (searchFld === "") {
						searchFld = item.field_name;
					} else {
						searchFld += "," + item.field_name;
					}
					if (
						tempfieldProps.hasOwnProperty(item.field_name) &&
						getStringParam(tempfieldProps[item.field_name].value) !== ""
					) {
						if (searchtxt === "") {
							if (tempfieldProps[item.field_name].fieldType === 'date') {
								searchtxt = moment(getStringParam(tempfieldProps[item.field_name].value), dateTimeFormat[app.me.date_format]).format("YYYY-MM-DD")
							} else if (tempfieldProps[item.field_name].fieldType === 'datetime') {
								searchtxt = moment(getStringParam(tempfieldProps[item.field_name].value), dateTimeFormat[app.me.date_format]).format("YYYY-MM-DD HH:mm:ss")
							} else {
								searchtxt = getStringParam(tempfieldProps[item.field_name].value)
							}
						} else {
							if (tempfieldProps[item.field_name].fieldType === 'date') {
								searchtxt += "," + moment(getStringParam(tempfieldProps[item.field_name].value), dateTimeFormat[app.me.date_format]).format("YYYY-MM-DD");
							} else if (tempfieldProps[item.field_name].fieldType === 'datetime') {
								searchtxt += "," + moment(getStringParam(tempfieldProps[item.field_name].value), dateTimeFormat[app.me.date_format]).format("YYYY-MM-DD HH:mm:ss")
							} else {
								searchtxt +=
									"," +
									getStringParam(tempfieldProps[item.field_name].value);
							}
						}
					}
					let labelFld = fieldsVal.filter((e) => e.name === item.field_name);
					if (labelFld.length > 0) {
						let tempLabel = labelFld[0].label;
						if (msgLabel === "") {
							msgLabel = getStringParam(tempLabel);
						} else if (defaultRule.field_names.length !== Index + 1) {
							msgLabel += ", " + getStringParam(tempLabel);
						} else {
							msgLabel +=
								getLocalizedStrings().message.COMMON.DUPLICATE_MSG_3 +
								getStringParam(tempLabel);
						}
					}
				});
			}
			let activeTabInfo = getObjectParam(getActiveTabInfo());
			if (activeTabInfo.hasOwnProperty("isUnitsListView") && activeTabInfo.isUnitsListView &&
				objectName === constants.ACCOUNTS_OBJECT) {
				let drawerPropsData = appContainer.drawerProps.data;
				if (
					isValidParam(drawerPropsData) &&
					drawerPropsData.hasOwnProperty("isUnitForm") &&
					drawerPropsData.isUnitForm
				) {
					searchParams.isUnitForm = drawerPropsData.isUnitForm;
					searchParams.unitProjectId = drawerPropsData.unitProjectId;
				}
			}
			if (objectName === constants.ACCOUNTS_OBJECT
				|| objectName === constants.CONTACTS_OBJECT
				|| objectName === constants.PARTNERS_OBJECT
			
			) {
				searchParams.fields = ["company"];
			} else if (objectName === constants.OPPORTUNITIES_OBJECT) {
				searchParams.fields = ["name"];
			} else if (  objectName === "customobjectone" ||
				objectName === "customobjecttwo" ||
				objectName === "customobjectthree" ||
				objectName === "customobjectfour") {
				searchParams.fields = ["t_email"];
			}
			searchParams.search_field_name = searchFld;
			searchParams.search_text = searchtxt;
			searchParams.search_type = "exact";
			searchParams.start_index = 0;
			searchParams.page_size = 10;


			let promise = Promise.resolve(getListData(objectName, searchParams));
			promise.then((response) => {
				let records = isValidParam(response)
					? getArrayParam(response.records)
					: [];
				if (records.length > 0) {
					if (objectName === constants.ACCOUNTS_OBJECT || objectName === "customobjectone" ||
						objectName === "customobjecttwo" ||
						objectName === "customobjectthree" ||
						objectName === "customobjectfour" ||
						objectName === constants.PARTNERS_OBJECT) {
						let msg =
							getLocalizedStrings().message.COMMON.DUPLICATE_MSG_1 +
							msgLabel +
							getLocalizedStrings().message.COMMON.DUPLICATE_MSG_2 +
							msgLabel +
							".";
						sfDialogs.confirm(
							getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
							msg,
							() => onConfirmSave(objectName, params, recordid),
							() => onCancel(objectName, true, null)
						);
					} else if (objectName === constants.CONTACTS_OBJECT) {
						let msg =
							getLocalizedStrings().message.COMMON.DUPLICATE_CONTACT_EMAIL_1 +
							msgLabel +
							getLocalizedStrings().message.COMMON.DUPLICATE_CONTACT_EMAIL_2 +
							msgLabel +
							".";
						sfDialogs.confirm(
							getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
							msg,
							() => onConfirmSave(objectName, params, recordid),
							() => onCancel(objectName, true, null)
						);
					} else if (object === constants.OPPORTUNITIES_OBJECT) {
						let msg =
							getLocalizedStrings().message.COMMON.DUPLICATE_MSG_1 +
							msgLabel +
							getLocalizedStrings().message.COMMON.DUPLICATE_MSG_2 +
							msgLabel +
							".";
						sfDialogs.confirm(
							getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
							msg,
							() => onConfirmSave(objectName, params, recordid),
							() => onCancel(objectName, true, null)
						);
					} else if (params.type === constants.NEXT) {
						setShowRequiredFormFields(true);
					}
				} else if (object === constants.PRODUCTS_OBJECT) {
					checkDuplicateProduct(objectName, params, recordid);
				} else if ((object == constants.ACCOUNTS_OBJECT || object == constants.CONTACTS_OBJECT) && params?.type == constants.NEXT){
			          // bypass
				}else{
					checkDuplicateFranchiseId(objectName, params, recordid);
				}
			});
		} catch (error) {
			console.error("Error in 'sfForm.js -> checkDuplicateEmail()':" + error);
		}
	};

	const onCancel = (objectName, isDuplicateMail, callFrom) => {
		try {
			if (changeLog.isSavedDisabled) {
				setIsSavedDisabled(false);
				let tempChangeLog = changeLog;
				tempChangeLog.isSavedDisabled = false;
				setchangeLog({ ...tempChangeLog });
			}
			else if ((objectName == 'accounts' || objectName == 'contacts' || objectName == 'opportunities' ||
				objectName === "customobjectone" ||
				objectName === "customobjecttwo" ||
				objectName === "customobjectthree" ||
				objectName === "customobjectfour" ||
				objectName === constants.PARTNERS_OBJECT
	            ) && isDuplicateMail) {
				setIsSavedDisabled(false);
				let tempChangeLog = changeLog;
				tempChangeLog.isSavedDisabled = false;
				setchangeLog({ ...tempChangeLog });
			} else if (objectName == constants.PAYMENTS_OBJECT && callFrom === "quotations") {
				setIsSavedDisabled(false);
				let tempChangeLog = changeLog;
				tempChangeLog.isSavedDisabled = false;
				setchangeLog({ ...tempChangeLog });
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> onCancel()':" + error);
		}
	};

	const onConfirmSave = (objectName, params, recordid) => {
		if (params.type === constants.NEXT) {
			setShowRequiredFormFields(true);
		} else {
			checkDuplicateFranchiseId(objectName, params, recordid);
		}
	};
	const checkBalanceDue = (objectName, params, recordid) => {

		try {
			let activeTabInfo = getActiveTabInfo();
			let balanceDue = getFloatParam(activeTabInfo.balanceDue);
			let amount = 0;
			let fieldObj = params.fields.filter((f) => {
				return f.name === "payment_value";
			});
			if (fieldObj !== null && fieldObj.length > 0) {
				amount = Number(fieldObj[0].value);
			}
			if (amount < 0) {
				// amount = amount * -1;
				// sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, "Amount entered can't be negetive", this.checkDuplicateFranchiseId.bind(object, params, recordId), null);
			}
			if (
				amount > balanceDue &&
				getStringParam(activeTabInfo.callFrom) === "quotations"
			) {
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
					getLocalizedStrings().message.PAYMENTS.AMOUNT_EXCEED_BALANCE_DUE,
					() => checkDuplicateFranchiseId(objectName, params, recordid),
					() => onCancel(objectName, false, "quotations")
				);
			} else if (
				paymentType === "Refund" &&
				paidAmount > 0 &&
				amount > paidAmount
			) {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.PAYMENTS.PAID_AMOUNT_EXCEED,
					() => onCancel()
				);
			} else if (
				paymentType === "Refund" &&
				refundedAmount > 0 &&
				amount > refundedAmount
			) {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.PAYMENTS.REFUND_AMOUNT_EXCEED,
					() => onCancel()
				);
			} else if (
				paymentType === "Refund" &&
				refundedAmount === "0.00"
			) {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.PAYMENTS.REFUND_COMPLETED,
					() => onCancel()
				);
			} else {
				checkDuplicateFranchiseId(objectName, params, recordid);
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> checkBalanceDue()':" + error);
		}
	};

	const checkDuplicateFranchiseId = (objectName, params, recordid) => {
		try {
			let franchiseid = isValidParam(fieldProps["franchise_id"])
				? getStringParam(fieldProps["franchise_id"].value)
					.trim()
					.toLowerCase()
				: "";
			let existingFranchiseId =
				recordid > 0 && isValidParam(record)
					? getStringParam(record["franchise_id"])
						.trim()
						.toLowerCase()
					: "";
			if (
				franchiseid !== "" &&
				objectName === constants.ACCOUNTS_OBJECT &&
				(recordid === 0 ||
					(recordid > 0 && existingFranchiseId !== franchiseid))
			) {
				let paramObject = {};
				paramObject.franchise_id = franchiseid;
				let promise = checkDuplicateFranchise(paramObject);
				promise.then((response) => {
					if (isValidParam(response) && response.status === 0) {
						let isDuplicateFranchise = getBooleanParam(
							response.data.is_duplicate
						);
						if (isDuplicateFranchise) {
							dispatch(showCustomSnackBar(
								getLocalizedStrings().message.ACCOUNTS.DUPLICATE_FRANCHISE,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							));
							let tempChangeLog = { ...changeLog };
							tempChangeLog.isSavedDisabled = false;
							setchangeLog({ ...tempChangeLog });
							setIsSavedDisabled(false);
							return false;
						} else {
							processSaveData(objectName, params, recordid);
						}
					}
				});
			} else {
				processSaveData(objectName, params, recordid);
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> checkDuplicateFranchiseId()':" + error
			);
		}
	};

	const copyAccountsFieldsToContactsField = (params, companyName) => {
		let copiedContactFields = [];
		try {
			let accountsFields = getArrayParam(params.fields);
			let contactFields = isValidParam(sfForm.data.contacts)
				? getArrayParam(sfForm.data.contacts.fields)
				: [];
			contactFields = contactFields.filter((f) => {
				return f.name !== "header";
			});

			contactFields.forEach((field) => {
				let name = field.name;
				let value = field.default_value;
				let accountField = accountsFields.find((f) => {
					return f.name === name;
				});
				let fieldDetails = { id: field.id, name, value };
				if (
					isValidParam(accountField) &&
					isValidParam(accountField.value) &&
					accountField.value !== "" &&
					name !== "t_status"
				) {
					fieldDetails.value = accountField.value;
				}

				if (name === "company") {
					fieldDetails.value = companyName;
				}

				copiedContactFields.push(fieldDetails);
			});
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> copyAccountsFieldsToContactsField()':" + error
			);
		}
		return copiedContactFields;
	};

	function hasCancelledStatus(arr) {
		return arr.some(obj => obj.name === 't_status' && obj.value === 'Cancelled');
	}

	const processSaveData = (objectName, params, recordid) => {
		let _fields = params?.fields;
		let isCancelled = false;
		if (unitTaskType?.task_type === "Corrective Action" && object === constants.TASKS && app.me.is_franchisee) {
			isCancelled = hasCancelledStatus(_fields);
		}
		if (!isCancelled) {
			saveData(objectName, params, recordid);
		} else {
			let message = getLocalizedStrings().message.TASKS.DELETE_CANCELLED_STATAUS_RESTRICT_MSG;
			let tempChangeLog = { ...changeLog };
			tempChangeLog.isSavedDisabled = false;
			setchangeLog({ ...tempChangeLog });
			setIsSavedDisabled(false);
			dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
		}
	};

	const saveData = (objectName, params, recordid) => {
		let isSolutionAddFromConvert = false;
		let actionType = getStringParam(
			appContainer.drawerProps.actionType
		);
		let drawerPropsData = getObjectParam(
			appContainer.drawerProps.data
		);
		try {
			if (
				(params.parent_object === constants.TASKS ||
					params.parent_object === constants.APPOINTMENTS) &&
				isValidParam(drawerPropsData) &&
				drawerPropsData.hasOwnProperty("isQueueView") &&
				getBooleanParam(drawerPropsData.isQueueView)
			) {
				params.linkInfo = drawerPropsData.linkInfo;
				params.isQueueView = drawerPropsData.isQueueView;
			}
			let tempChangeLog = { ...changeLog };
			if (!tempChangeLog.isSavedDisabled) {
				setIsSavedDisabled(true);
				tempChangeLog.isSavedDisabled = true;
			}

			if (app.me.is_franchisee && record?.t_attachment && object === constants.TASKS) {
				let attachFilesRecord = JSON.parse(record?.t_attachment)?.attached_files
				if (attachFilesRecord.length !== attchedTaskFiles.length) {
					params.is_attachments_updated = true
				}

				if (attachFilesRecord.length < attchedTaskFiles.length) {
					const newlyAddedAttachments = attchedTaskFiles.filter(item => !attachFilesRecord.some(d => d.uid === item.uid));
					if (newlyAddedAttachments.length > 0) {
						let strAtachmentInfo = undefined;
						let strAtachmentNameInfo = undefined;
						if (newlyAddedAttachments.length > 0) {
							newlyAddedAttachments.forEach(f => {
								if (strAtachmentInfo === undefined) {
									strAtachmentInfo = '##' + f.name + '##' + f.urlS3;
								} else {
									strAtachmentInfo += ',@##' + f.name + '##' + f.urlS3;
								}

								if (strAtachmentNameInfo === undefined) {
									strAtachmentNameInfo = f.name
								} else {
									strAtachmentNameInfo += ',' + f.name
								}

							});
						}
						if (strAtachmentInfo && strAtachmentNameInfo) {
							params.mailattachInfo = strAtachmentInfo
							params.mailattachName = strAtachmentNameInfo
						}
					}
				}
			}
			let promise = SFFormActions.saveRecordPromise(objectName, params);
			promise.then((response) => {
				if (
					isValidParam(response) &&
					response.hasOwnProperty("data") &&
					response.data.hasOwnProperty("id")
				) {
					let id =
						isValidParam(response.data.id) &&
							isArray(response.data.id) &&
							response.data.id.length > 0
							? response.data.id[0]
							: 0;

					id = getBooleanParam(isDomainExist)
						? getIntParam(accountId)
						: id;
					if (id > 0) {
						if (
							objectName === constants.APPOINTMENTS ||
							objectName === constants.TASKS ||
							objectName === constants.REPORTED_SALES_OBJECT
						) {
							// dispatch(detailViewIsMounted(false));
							// dispatch(isDetailViewSet(false));
							let userId = (app.me.id * 19000) + 7;
							let projectId = (app.me.projectId * 19000) + 7;
							let taskcookieName = "task-view-mode" + projectId + '-' + userId;
							let _isAllTakView = JSON.parse(getCookie(taskcookieName));

							if (callFrom === 'calendarTaskPage') {
								if (_isAllTakView) {
									let tempactiveTab = getObjectParam(getActiveTab());
									dispatch(getTasksRefresh(true));
									tempactiveTab.info.getTaskPageData(constants.TASKS);
								} else {
									let userId = app.me.id;
									let projectId = app.me.projectId;
									let _rememberTab = tab.rememberTab;
									let itemName = 'TabInfo_' + 'calendartask' + '_' + userId + '_' + projectId;
									let tabDetails = getArrayParam(_rememberTab[itemName]);
									let params = {
										module_id: tabDetails[0].info.moduleId,
										start_index: 0,
										fetch_size: 20,
										sales_rep: tabDetails[0].info.userName,
										sort_order: tabDetails[0].info.sortOrder,
									};
									dispatch(getTasksPortal(params));
								}

							}
							if (
								objectName === constants.APPOINTMENTS &&
								callFrom !== "queueView"
							) {
								updateCalenderDateTimeLine();
							}
							if (
								objectName === constants.TASKS &&
								queueList !== null &&
								queueList !== undefined &&
								queueList.hasOwnProperty("data") &&
								queueList.data !== null
							) {
								let queueObj = queueList.data.records.filter(
									(obj) => {
										return obj.id === id;
									}
								);
								if (queueObj.length > 0) {
									queueObj[0].linkInfo = linkInfo;
									queueList.data.records[queueObj[0].idx] =
										queueObj[0];
								}
							}
							// if (
							// 	parentDetails.hasOwnProperty("callFrom") &&
							// 	parentDetails.callFrom === "ColumnViewAddTaskApp"
							// ) {
							// 	let dataParams = {
							// 		record_id: parentDetails.record_id,
							// 		object: parentDetails.parentObject,
							// 		listIndex: parentDetails.listIndex,
							// 		cardIndex: parentDetails.cardIndex,
							// 		updateCards: parentDetails.updateCards,
							// 	};
							// 	refreshColumnView(dataParams);
							// }
							if (
								appContainer.drawerProps.callFrom === "columnview" &&
								columnViewTaskApp && parentDetails.callFrom !== "ColumnViewAddTaskApp" &&
								columnViewTaskApp === true
							) {
								let dataParams = {
									record_id: parentDetails.parent_record_id,
									object: parentDetails.parent_object,
									listIndex: parentDetails.listIndex,
									cardIndex: parentDetails.cardIndex,
									updateCards: parentDetails.updateCards,
								};
								refreshColumnView(dataParams);

							}
							let activeTab = getObjectParam(getActiveTab());
							if (
								activeTab.object === constants.TASKS &&
								isValidParam(activeTab.info.getTaskPageData)
							) {
								activeTab.info.getTaskPageData(constants.TASKS);
							} else {
								if (objectName === constants.APPOINTMENTS) {
									dispatch(getCalendarRefresh(true));
									if (
										callType &&
										callType === "jobscheduler"
									) {
										removejobById(parentDetails.parent_record_id);
									}
								}

								dispatch(getTasksRefresh(true));
								dispatch(refreshDetailViewListViewData(true));
								dispatch(refreshDetailViewData(true));
								if (objectName === constants.TASKS && isDetailView) {
									if (
										getBooleanParam(
											getObjectParam(tasksObjParam).isFranchiseRecord
										)
									) {
										dispatch(getUnitTasksRefresh(true));
									}
								}
								if (activeTab.type === TYPE_HOME) {
									let tabs = tab.tabs;
									let tempTabs = tabs.filter((t) => {
										return t.type === TYPE_LIST_VIEW;
									});
									if (tempTabs !== null && tempTabs.length > 0) {
										refreshListView(object);
									}
								} else if (activeTab.type === TYPE_LIST_VIEW && object !== constants.TASKS) {
									let formObj = activeTab.object;
									refreshListView(formObj);
								}
							}
						}

						if (
							objectName === constants.SOLUTIONS_OBJECT &&
							parentDetails !== undefined &&
							parentDetails.hasOwnProperty("parent_object") &&
							parentDetails.hasOwnProperty("parent_record_id")
						) {
							let tempParentRecordId =
								parentDetails["parent_record_id"];
							if (tempParentRecordId > 0) {
								isSolutionAddFromConvert = true;
							}
						}

						if (
							(objectName !== constants.NOTE_OBJECT &&
								objectName !== constants.APPOINTMENTS &&
								objectName !== constants.TASKS &&
								objectName !== constants.REPORTED_SALES_OBJECT &&
								objectName !== constants.PETS_OBJECT &&
								objectName !== constants.PAYMENTS_OBJECT &&
								!isSolutionAddFromConvert &&
								!isDetailView &&
								!changeLog.isConvert) ||
							(isDetailView &&
								objectName === constants.PETS_OBJECT &&
								actionType === constants.COPY)
						) {
							let isNewRecord =
								recordid > 0 && objectName !== constants.PETS_OBJECT ? false : true;
							openAsDetailView(id, isNewRecord);
						}
						if (objectName === constants.NOTE_OBJECT) {
							let noteTypeField = getArrayParam(params.fields).filter(
								(f) => f.name === "note_type"
							);
							if (noteTypeField.length > 0) {
								setCookie(
									"rc_" + noteTypeField[0].name,
									noteTypeField[0].value
								);
							}
						}

						if (objectName === constants.OPPORTUNITIES_OBJECT) {
							let tabs = tab.tabs;
							let tempTabs = tabs.filter((t) => {
								return t.type === TYPE_REPORT && t.label === constants.FORECAST;
							});
							if (tempTabs !== null && tempTabs.length > 0) {
								let info = getObjectParam(tempTabs[0].info);
								info.actionType = constants.NEW;
							}
						}

						if (
							objectName === constants.ACCOUNTS_OBJECT &&
							changeLog.isConvert === true
						) {
							dispatch(refreshDetailViewData(true));
							dispatch(getTasksRefresh(true));
						}

						if (
							objectName === constants.PAYMENTS_OBJECT &&
							getStringParam(
								appContainer.drawerProps.data.callFrom
							) === "quotations"
						) {
							dispatch(refreshQuotation(true));
						}

						//Detail View Listview Refresh
						if (isDetailView) {
							let detailViewParentObject = null;
							if (
								appointmentSub &&
								appointmentSub !== "" &&
								detailView.data === null &&
								objectName === constants.APPOINTMENTS
							) {
								detailViewParentObject = parentDetails.parent_object;
							} else if (
								isPowerCalling &&
								isPowerCalling === true &&
								detailView.data === null &&
								(objectName === constants.APPOINTMENTS ||
									objectName === constants.TASKS)
							) {
								detailViewParentObject = parentDetails.parent_object;
							} else if (
								columnViewTaskApp &&
								columnViewTaskApp === true &&
								detailView.data === null &&
								(objectName === constants.APPOINTMENTS ||
									objectName === constants.TASKS)
							) {
								detailViewParentObject = parentDetails.parent_object;
							} else if (
								isFromJobScheduler &&
								isFromJobScheduler === true &&
								detailView.data === null &&
								objectName === constants.APPOINTMENTS
							) {
								detailViewParentObject = parentDetails.parent_object;
							} else {
								detailViewParentObject =
									detailView.data !== null
										? detailView.data.object
										: parentDetails.parent_object;
							}

							if (
								(isValidParam(detailViewParentObject) &&
									objectName === detailViewParentObject.toLowerCase() &&
									callFrom !== "queueView") ||
								(objectName === constants.TASKS &&
									getBooleanParam(response.data.is_converted_to_next_stage))
							) {
								dispatch(refreshDetailViewData(true));

								if (
									objectName === constants.ACCOUNTS_OBJECT ||
									objectName === constants.CASES_OBJECT ||
									objectName === constants.ISSUES_OBJECT
								) {
									const fieldsArrObj = getArrayParam(params.fields);
									const fieldArr = fieldsArrObj.filter(
										(f) =>
											f.name === "sales_rep" &&
											f.value === "Use Assignment Rule"
									);

									if (fieldArr.length > 0) {
										dispatch(getTasksRefresh(true));
									}
								}
							} else {
								if (detailViewParentObject === constants.GROUP_OBJECT) {
									refreshListView(objectName, { isDetailView: true });
									dispatch(refreshDetailViewListViewData(true, recordid));
								} else {
									if (
										detailViewParentObject.toLowerCase() ===
										constants.ACCOUNTS_OBJECT &&
										objectName === constants.CONTACTS_OBJECT
									) {
										dispatch(refreshDetailViewData(true));
									}
									if (
										detailViewParentObject.toLowerCase() ===
										constants.OPPORTUNITIES_OBJECT &&
										(objectName === constants.CONTACTS_OBJECT ||
											objectName === constants.ACCOUNTS_OBJECT)
									) {
										dispatch(refreshDetailViewData(true));
									}

									if (isDetailView == true && openMode === "EXPANDED" && type === "edit") {
										dispatch(detailViewIsMounted(false));
										dispatch(isDetailViewSet(false));
										dispatch(refreshDetailViewData(true));
									}

									dispatch(refreshDetailViewListViewData(true, recordid));
									dispatch(refreshQueueDetailViewNote(true));
									if (callFrom === "queueView") {
										isEdited(false);
										setIsSavedDisabled(false);
										tempChangeLog.isSavedDisabled = false;
									}
									//Priyanka 14.03.19
									//refresh detailViewFields on note save and update
									if (
										objectName === constants.NOTE_OBJECT &&
										isValidParam(detailViewParentObject)
									) {
										dispatch(refreshDetailViewData(true));
										let dataValue = getObjectParam(
											appContainer.drawerProps.data
										);
										if (
											getBooleanParam(dataValue.isPinnedNote) &&
											getBooleanParam(dataValue.isDetailView)
										) {
											getDetailViewPinnedNote();
										}
									}

									if (
										(objectName === constants.NOTE_OBJECT ||
											objectName === constants.TASKS ||
											objectName === constants.APPOINTMENTS) &&
										isValidParam(detailViewParentObject)
									) {
										let activeTab = getActiveTab();
										activeTab = getObjectParam(activeTab);
										let info = getObjectParam(activeTab.info);
										if (info.selectedPageNo > 1) {
											info.selectedPageNo = 1;
										}
									}
								}
							}
						} else if (objectName === constants.PETS_OBJECT) {
							let info = getActiveTab().info;
							info.getAllPets();
						} else {
							let activeTab = getObjectParam(getActiveTab());
							if (activeTab.type === TYPE_DETAIL_VIEW) {
								let tabs = tab.tabs;
								let tempTabs = tabs.filter((t) => {
									return (
										t.type === TYPE_LIST_VIEW || t.type === TYPE_COLUMN_VIEW
									);
								});
								if (tempTabs !== null && tempTabs.length > 0) {
									let tab = tempTabs[0];
									if (
										tab.type === TYPE_LIST_VIEW ||
										tab.type === TYPE_COLUMN_VIEW
									) {
										setListViewMounted(false, objectName);
										if (
											(tab.type === TYPE_LIST_VIEW &&
												objectName !== constants.SOLUTIONS_OBJECT &&
												objectName !== constants.SALES_LEADS_OBJECTb &&
												objectName !== constants.APPOINTMENTS &&
												objectName !== constants.TASKS) ||
											tab.type === TYPE_COLUMN_VIEW
										) {
											setKanbanMounted(false);
										}
									} /* else if (tab.type === TYPE_COLUMN_VIEW) {
                                        setKanbanMounted(false);
                                    } */
								}
							}
						}

						if (objectName === constants.GROUP_OBJECT) {
							addGroupIntoReduxStore(
								id,
								fieldProps["name"].value,
								fieldProps["t_status"].value
							);
						}
						closeDrawer("save");

						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.COMMON.SAVE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						));
						//	dispatch(refreshDetailViewData(true));

						if (isDetailView || objectName == 'tasks') {
							let parentTableIds = "";
							let parentObj = params?.parent_object !== "" ? params?.parent_object : detailView?.data?.object
							let isTaskInChild = isObjectChildExists(parentObj, constants.TASKS);
							let isAppointmentChild = isObjectChildExists(parentObj, constants.APPOINTMENTS);

							if (isTaskInChild) {
								parentTableIds = "349";
							}
							if (isAppointmentChild) {
								parentTableIds += parentTableIds === "" ? "142" : ",142";
							}
							let param = {};
							param.parent_table_id = parentTableIds;
							param.child_table_id = OBJECT_TABLEID_MAP[parentObj];
							param.child_record_id = params?.parent_record_id !== 0 ? params?.parent_record_id : detailView?.record?.id;
							param.task_app_value = detailViewTaskAppintmentReducer.taskAppValue !== undefined ? detailViewTaskAppintmentReducer.taskAppValue : constants.TASK_APP_MY_OPEN;
							dispatch(getDetailViewTaskAppointment(parentObj, param));
						}

						dispatch(getJobCalendarRefresh(true));
					}
				} else {
					if (objectName === constants.GROUP_OBJECT) {
						if (response.data.exists) {
							dispatch(showCustomSnackBar(
								getLocalizedStrings().message.BATCH_ACTION_VALIDATION
									.GROUP_ALREADY_EXIST,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							));
							setIsSaved(true);
							setIsSavedDisabled(false);
							tempChangeLog.isSavedDisabled = false;
						}
					}
				}
				if (tempChangeLog.isSavedDisabled) {
					setIsSavedDisabled(false);
					tempChangeLog.isSavedDisabled = false;

				}
			});
			setchangeLog({ ...tempChangeLog });
		} catch (error) {
			console.error("Error in 'sfForm.js -> saveData()':" + error);
		}
	};
	const addDefaultValueForQuickAddForm = (fieldsForSave) => {
		try {
			if (Array.isArray(fieldsForSave)) {
				let parentObject = getStringParam(parentDetails.parent_object).trim();
				let dataValue = Object.assign({}, sfForm.data[object]);
				let fieldsVal = getArrayParam(dataValue.fields);
				let quickAddFields = null;
				if (openMode === constants.SF_FORM_OPEN_MODE_QUICK) {
					quickAddFields = getArrayParam(dataValue.quickadd_fields);
				} else if (openMode === constants.SF_FORM_OPEN_MODE_CUSTOM) {
					quickAddFields = getArrayParam(dataValue.custom_fields);
					if (
						parentObject === constants.CASES_OBJECT &&
						object === constants.NOTE_OBJECT &&
						(app.selectedModuleName ===
							constants.MODULE_OPERATIONS ||
							app.selectedModuleName === constants.MODULE_HUB)
					) {
						quickAddFields = quickAddFields.filter((f) => {
							return f !== "note_type";
						});

						fieldsVal.forEach((f) => {
							if (
								app.selectedModuleName === constants.MODULE_HUB &&
								(f.name === "author" || f.name === "t_creater")
							) {
								f.default_value = app.me.name;
							} else if (f.name === "note_type") {
								f.default_value = "Case";
							}
						});
					}
				}

				fieldsVal = fieldsVal.filter((f) => {
					if (object === constants.OPPORTUNITIES_OBJECT) {
						return (
							quickAddFields.indexOf(f.name) < 0 &&
							f.name !== "header" &&
							f.name !== "" &&
							f.name !== "probability"
						);
					} else {
						return (
							quickAddFields.indexOf(f.name) < 0 &&
							f.name !== "header" &&
							f.name !== ""
						);
					}
				});

				fieldsVal.forEach((f) => {
					let val = "";
					let valueForSave = null;

					if (
						object === constants.CASES_OBJECT &&
						f.name === "custom_field3"
					) {
						f.default_value = franchiseId;
					}

					if (record != null && record.hasOwnProperty(f.name)) {
						val = getStringParam(record[f.name]);
						valueForSave = record[f.name];
					}

					if (val === "") {
						val = getStringParam(f.default_value).trim();
						valueForSave = f.default_value;
					}

					if (
						(f.field_type === constants.FIELD_TYPE_DATE_TIME ||
							f.field_type === constants.FIELD_TYPE_DATE) &&
						isValidParam(valueForSave)
					) {
						try {
							let momentObj = moment(valueForSave);
							valueForSave = momentObj.format(
								constants.INPUT_DATE_TIME_FORMAT_24
							);
							valueForSave =
								valueForSave === "Invalid date" ? "" : valueForSave;
						} catch (error) {
							console.error(
								"Error in 'sfForm.js -> addDefaultValueForQuickAddForm() -> Faild to parse date.':" +
								error
							);
						}
					}

					if (
						object === constants.APPOINTMENTS &&
						f.field_type === constants.FIELD_TYPE_LIST_INTEGER &&
						f.name === "user_id"
					) {
						let tempList = userlist.data.filter((f) => {
							return f.value === valueForSave;
						});
						valueForSave = tempList.length > 0 ? getIntParam(tempList.id) : 0;
					}

					if (val !== "" && isValidParam(valueForSave)) {
						let tempField = fieldsForSave.filter((r) => {
							return r.name === f.name;
						});
						if (
							tempField.length === 0 ||
							(tempField.length > 0 && isInvalidParam(tempField[0].value))
						) {
							if (f.name === "link_info") {
								valueForSave = linkInfo;
							}
							fieldsForSave.push({
								id: f.id,
								name: f.name,
								value: valueForSave,
							});
						}
					}
				});
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> addDefaultValueForQuickAddForm()':" + error
			);
		}
	};

	const openAsDetailView = (id, isNewRecord) => {
		try {
			let objectName = object;
			let linkToUrl = "/" + objectName + "/detailview/" + id;
			if (isNewRecord || isDetailView === false) {
				let labelName = getRecordTitle();
				let tab = {
					label: labelName,
					object: objectName,
					type: TYPE_DETAIL_VIEW,
					imgName: "",
					url: linkToUrl,
					info: {},
				};
				addTab(tab, true);
			} else {
				updateActiveTab({ url: linkToUrl });
			}
			setRedirect(true);
			setRedirectUrl(linkToUrl);
		} catch (error) {
			console.error("Error in 'sfForm.js -> openAsDetailView()':" + error);
		}
	};

	const updateCalenderDateTimeLine = () => {

		const sObj = document.querySelector(".e-schedule")?.ej2_instances[0];
		if(sObj !== undefined){
			const currentDates = sObj.getCurrentViewDates();
			const currentView = sObj.viewIndex;
			let startDate = currentDates[0];
			if (currentView === 3) {
				startDate = currentDates[15];
			}
			if (startDate !== null && startDate !== undefined) {
				dispatch(setSelectedDate(startDate));
			}
		}
		
	}

	function openAsExpandedMode() {
		let objectName = getStringParam(object);
		let labelName = null;
		let itemLabel = null;
		if (isDetailView) {
			let arrChildObjectInfo = getChildObjectInfo(
				parentDetails["parent_object"],
				objectName
			);
			if (arrChildObjectInfo.length > 0) {
				itemLabel = arrChildObjectInfo[0].title;
				labelName = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
			}
		} else {
			let objectList = getArrayParam(app.objectList);
			if (objectName === constants.PETS_OBJECT) {
				itemLabel = "Pets";
				labelName = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
			} else {
				objectList = objectList.filter((f) => {
					return f.name === objectName;
				});
				itemLabel = objectList.length > 0 ? objectList[0].label : "";
				itemLabel = itemLabel === "" ? getSelectedObjectLabel() : itemLabel;
				labelName = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
				if (object === constants.REPORTED_SALES_OBJECT && detailView.isDetailView) {
					labelName = getLocalizedStrings().label.COMMON.EDIT + " " + itemLabel;
				}
				if (objectName === "salesleads") {
					itemLabel = "Sales Leads";
					labelName = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
				}
			}
		}
		if (object === constants.REPORTED_SALES_OBJECT && detailView.isDetailView) {
			labelName = getLocalizedStrings().label.REPORTED_SALES.EDIT_TAB_LABEL;
		}

		let dataValue = {};
		dataValue = getObjectParam(appContainer.drawerProps.data);
		if (isDetailView) {
			dataValue = { ...parentDetails, ...dataValue };
		}
		dataValue.object = objectName;
		dataValue.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
		dataValue.formValues = fieldProps;
		dataValue.changeModeFrom = 'expandButton';
		appContainer.drawerProps.data = dataValue;
		setchangeLog({ ...changeLog, autoFocusFieldName: '' });
		dispatch(getAppDrawer(true, labelName, constants.NEW, dataValue, null));
	};

	const getRecordTitle = () => {
		let titleLabel = "";
		let projectId = getIntParam(app.me.projectId);
		if (object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT) {
			titleLabel = fieldProps.hasOwnProperty("company") ? getStringParam(fieldProps["company"]?.value).trim() : "";
		} else if (object === constants.CONTACTS_OBJECT) {
			let tempLabel = null;
			let firstNamelabel = fieldProps.hasOwnProperty("first_name")
				? getStringParam(fieldProps["first_name"].value).trim()
				: "";
			let lastNameLabel = fieldProps.hasOwnProperty("last_name")
				? getStringParam(fieldProps["last_name"].value).trim()
				: "";
			if (tempLabel === null || tempLabel === "" || tempLabel === undefined) {
				if (firstNamelabel !== null && firstNamelabel !== "") {
					titleLabel = firstNamelabel;
				}
				if (lastNameLabel !== null && lastNameLabel !== "") {
					if (titleLabel === null || titleLabel === "" || titleLabel === undefined) {
						titleLabel = lastNameLabel;
					} else {
						titleLabel += " " + lastNameLabel;
					}
				}
			}
		} else if (object === constants.OPPORTUNITIES_OBJECT) {
			titleLabel = fieldProps.hasOwnProperty("name")
				? getStringParam(fieldProps["name"].value).trim()
				: "";
		} else if (object === constants.CASES_OBJECT) {
			if (app.selectedModuleName === constants.MODULE_HUB) {
				titleLabel = fieldProps.hasOwnProperty("synopsis")
					? getStringParam(fieldProps["synopsis"].value).trim()
					: "";
			} else {
				titleLabel = fieldProps.hasOwnProperty("problem_description")
					? getStringParam(fieldProps["problem_description"].value).trim()
					: "";
			}
		} else if (
			projectId === 2430 &&
			object === constants.CUSTOM_TABLE1_OBJECT
		) {
			titleLabel = getStringParam(fieldProps["custom_field4"].value).trim();
		} else {
			let fieldsVal = fields.filter((f) => {
				return f.is_title_field;
			});
			let fieldName = fieldsVal.length > 0 ? fieldsVal[0].name : "";
			titleLabel = fieldProps.hasOwnProperty(fieldName)
				? getStringParam(fieldProps[fieldName].value).trim()
				: "";
		}
		if(titleLabel !== undefined){
			titleLabel = titleLabel.trim();
			titleLabel = titleLabel === "" ? getLocalizedStrings().label.COMMON.EMPTY : titleLabel;
		}
		return titleLabel;
	};

	const closeDrawer = (callFrom) => {
		let dataValue = appContainer.customDrawerProps;
		let minimizeList = dataValue.minimizeList;
		let typeValue = dataValue.actionType;
		if (minimizeList.hasOwnProperty(typeValue) && (typeValue == 'Convert' || typeValue == 'new' || typeValue == 'agreement') && minimizeList[typeValue] == true) {
			minimizeList[typeValue] = true;
		}
		else if (minimizeList.hasOwnProperty(typeValue)) {
			minimizeList[typeValue] = false;
		}

		if (minimizeList.hasOwnProperty(typeValue) && (typeValue == 'new') && minimizeList[typeValue] == true && dataValue.isMinimize == false) {
			minimizeList[typeValue] = false;
		}
		if (
			object === constants.NOTE_OBJECT &&
			callFrom !== undefined &&
			callFrom != null &&
			callFrom === "cancel"
		) {
			let prevScrollPosition = detailView.scrollPosition;
			detailView.prevScrollPosition = prevScrollPosition;
		}

		let tab = getActiveTab();

		let info = tab.info;
		if (object === constants.TASKS && isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
			delete info.isUnitsAccount;
			delete info.UnitsAccountProjectId;
		}
		dispatch(getAppDrawer(false, null, null, null, null));
		if (object == 'tasks' && minimizeList.hasOwnProperty(typeValue) && typeValue == 'new' && minimizeList[typeValue] == true) {
			// bypass
		} else {
			dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
		}
		dispatch(minimizeAppCustomDrawer(
			false,
			null,
			null,
			{ isMinimize: false },
			null,
			minimizeList
		));
	};

	const setRequiredFields = (allRequiredFieldsValue, fieldPropsValue , reqval) => {
		allRequiredFieldsValue = isValidParam(allRequiredFieldsValue) ? allRequiredFieldsValue : (changeLog.allRequiredFields || []);		fieldPropsValue = isValidParam(fieldPropsValue) ? fieldPropsValue : fieldProps;
		try {
			// if(reqval !== undefined){
			// 	reqval.forEach((m)=>{
			// 		if(fieldPropsValue[m].value =='' && allRequiredFieldsValue.includes(m) == false){
			// 			allRequiredFieldsValue.push(m)
			// 		}
			// 	})
			// }
			
			allRequiredFieldsValue = allRequiredFieldsValue
				.filter((f) => !(fieldPropsValue[f] && fieldPropsValue[f].value));

				
			if (object == constants.OPPORTUNITIES_OBJECT) {
				let includesProduct = allRequiredFieldsValue.includes("product");
				if (includesProduct) {
					allRequiredFieldsValue.push("product_id");
				}
			setAllRequiredFields(allRequiredFieldsValue);
			}
			let log = changeLog;
			log.allRequiredFields = allRequiredFieldsValue;
			setchangeLog({ ...log });
			setFieldProps(fieldPropsValue);

			handleForceupdateMethod();
		} catch (error) {
			console.error("Error in 'sfForm.js -> setRequiredFields()':" + error);
		}
	};

	function handleNext() {

		try {
			if (app.me.is_inxpress) {
				greenlightValidation(fieldProps, constants.NEXT);
			} else if (!app.me.is_inxpress) {
				saveSFForm(fieldProps, constants.NEXT);
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> handleNext()':" + error);
		}
	};
	const getActionButtonsForUnitTasks = (isUnitOwnerTasks) => {
		let buttons = [];
		let saveStyel = { ...styles.disabled, marginRight: '0px', verticalAlign: 'top' };
		let cancelStyle = { ...styles.disabled, display: 'inline-grid', marginLeft: '6px', marginRight: '0px', lineHeight: '15px' };
		if (recordId === 0) {
			saveStyel = { ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }
			cancelStyle = { ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px', lineHeight: '15px' };
		}
		if (recordId > 0 && isUnitOwnerTasks) {
			cancelStyle = { ...styles.secondaryButton, marginLeft: "6px", verticalAlign: "top" }
			if(app.me.is_franchisee){
				saveStyel = { ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }
			}

		}

		if (changeLog.allRequiredFields != undefined && changeLog.allRequiredFields != null && changeLog.allRequiredFields.length > 0 && !showRequiredFormFields) {
			buttons.push(
				<Button
					key='next'
					onClick={() => handleNext()}
					style={{ ...styles.disabled, marginRight: '0px', verticalAlign: 'top' }}
					disabled='disabled'
				>{getLocalizedStrings().label.COMMON.NEXT}</Button>
			);
		}
		
		else {
			buttons.push(
				<Button
					key='save'
					onClick={() => saveForm()}
					style={saveStyel}
					disabled={(recordId > 0 && isUnitOwnerTasks && app.me.is_franchisee == false ? 'disabled' : recordId == 0 && changeLog.isSavedDisabled ? 'disabled' : '')}
				>{getLocalizedStrings().label.COMMON.SAVE}</Button>
			)
		}

		if (recordId > 0
			&& (object === constants.APPOINTMENTS)) {
			buttons.push(
				<Button
					key='complete'
					onClick={() => completeTaskApp('complete')}
					style={{ ...styles.disabled, marginRight: openMode === constants.SF_FORM_OPEN_MODE_QUICK ? '12px' : '0px', verticalAlign: 'top', marginLeft: '6px' }}
					disabled='disabled'
				>{getLocalizedStrings().label.COMMON.COMPLETE}</Button>
			);
			buttons.push(
				<Button
					key='completeandnew'
					onClick={() => completeTaskApp('completeandnew')}
					style={{ ...styles.disabled, marginRight: openMode == constants.SF_FORM_OPEN_MODE_QUICK ? '12px' : '0px', verticalAlign: 'top', marginLeft: '6px' }}
					disabled='disabled'
				> {getLocalizedStrings().label.COMMON.COMPLETE_New}</Button>
			);
		}
		if (recordId > 0
			&& (object === constants.APPOINTMENTS || object === constants.NOTE_OBJECT || (object === constants.TASKS && app.me.is_franchisor))) {
			buttons.push(
				<Button
					key='delete'
					onClick={() => deleteSFForm()}
					style={{ ...styles.disabled, marginRight: openMode === constants.SF_FORM_OPEN_MODE_QUICK ? '12px' : '0px', verticalAlign: 'top', marginLeft: '6px' }}
					disabled='disabled'
				>{getLocalizedStrings().label.COMMON.DELETE}</Button>
			);
		}
		if (recordId > 0 && object === constants.TASKS && app.me.is_franchisee && unitTaskType.task_type !== "Corrective Action") {
			buttons.push(
				<Button
					key='delete'
					onClick={() => deleteSFForm()}
					style={{ ...styles.secondaryButton, marginRight: openMode === constants.SF_FORM_OPEN_MODE_QUICK ? '12px' : '0px', verticalAlign: 'top', marginLeft: '6px' }}
				>{getLocalizedStrings().label.COMMON.DELETE}</Button>
			);
		}

		let btnCnlView = isValidParam(hideCancel) ? getBooleanParam(hideCancel) : false;
		if (callFrom !== 'queueView') {
			if (!btnCnlView) {
				buttons.push(
					<Button
						key='delete'
						onClick={() => closeDrawer('cancel')}
						style={cancelStyle}
					>{getLocalizedStrings().label.COMMON.CANCEL}</Button>
				);
			}
		}

		if (openMode === constants.SF_FORM_OPEN_MODE_QUICK && app.selectedModuleName !== constants.MODULE_HUB
			&& object !== constants.PAYMENTS_OBJECT && object !== constants.REFUND_OBJECT) {
			buttons.push(
				<Button
					key='expand'
					style={{ ...styles.disabled, display: 'inline-grid', marginLeft: '6px', marginRight: '0px' }}
					onClick={() => openAsExpandedMode()}
					disabled='disabled'
				>{getLocalizedStrings().label.COMMON.EXPAND}</Button>
			);
		}


		return buttons;
	}


	const getActionButtons = (tempLog) => {
		let tempChangeLog = { ...changeLog };
		let objectName = getStringParam(object);
		if (isSaved && objectName === constants.GROUP_OBJECT) {
			if (tempChangeLog.isSavedDisabled) {
				tempChangeLog.isSavedDisabled = false;
			}
		}
		if (unitTaskType?.task_type === "Corrective Action" && object === constants.TASKS && app.me.is_franchisee && !isSavedDisabled) {
			if (tempChangeLog.isSavedDisabled) {
				tempChangeLog.isSavedDisabled = false;
			}
		}
		let buttons = [];
		if (
			object === constants.TASKS &&
			getBooleanParam(getObjectParam(tasksObjParam).isUnitOwnerTasks)
		) {
			buttons = getActionButtonsForUnitTasks(getBooleanParam(getObjectParam(tasksObjParam).isUnitOwnerTasks));
		} else {
			if (!isPreviewStateValue) {
				let tempAllRequiredFields = tempLog.allRequiredFields || [];
				if (tempAllRequiredFields.length > 0 && !showRequiredFormFields) {
					buttons.push(
						<Button
							key="next"
							onClick={() => handleNext()}
							style={{
								...styles.primaryButton,
								marginRight: "0px",
								verticalAlign: "top",
							}}
						>
							{getLocalizedStrings().label.COMMON.NEXT}
						</Button>
					);
				} else {
					buttons.push(
						<Button
							key="save"
							onClick={() => saveForm()}
							style={{
								...styles.primaryButton,
								marginRight: "0px",
								verticalAlign: "top",
								width: getBooleanParam(showRequiredFormFields) && callFrom !== "queueView" ? '25%' : ''
							}}
							disabled={tempChangeLog.isSavedDisabled}
						>
							{getLocalizedStrings().label.COMMON.SAVE}
						</Button>
					);
				}

			}
			if (
				recordId > 0 &&
				object === constants.APPOINTMENTS
			) {
				buttons.push(
					<Button
						key="complete"
						onClick={() => completeTaskApp("complete")}
						style={{
							...styles.secondaryButton,
							marginRight: openMode === constants.SF_FORM_OPEN_MODE_QUICK ? "12px" : "0px",
							verticalAlign: "top",
							marginLeft: "6px",
						}}
					>
						{getLocalizedStrings().label.COMMON.COMPLETE}
					</Button>
				);
				buttons.push(
					<Button
						key="completeandnew"
						onClick={() => completeTaskApp("completeandnew")}
						style={{
							...styles.secondaryButton,
							marginRight: openMode === constants.SF_FORM_OPEN_MODE_QUICK ? "12px" : "0px",
							verticalAlign: "top",
							marginLeft: "6px",
						}}
					>
						{" "}
						{getLocalizedStrings().label.COMMON.COMPLETE_New}
					</Button>
				);
			}

			if (
				recordId > 0 && unitTaskType.task_type !== "Corrective Action" &&
				(object === constants.APPOINTMENTS ||
					object === constants.TASKS ||
					object === constants.NOTE_OBJECT)
			) {
				buttons.push(
					<Button
						key="delete"
						onClick={() => deleteSFForm()}
						style={{
							...styles.secondaryButton,
							marginRight: openMode === constants.SF_FORM_OPEN_MODE_QUICK ? "12px" : "0px",
							verticalAlign: "top",
							marginLeft: "6px",
						}}
					>
						{getLocalizedStrings().label.COMMON.DELETE}
					</Button>
				);
			}

			let btnCnlView = isValidParam(hideCancel)
				? getBooleanParam(hideCancel)
				: false;
			if (callFrom !== "queueView") {
				if (!btnCnlView) {
					buttons.push(
						<Button
							key="close"
							onClick={() => closeDrawer("cancel")}
							style={{
								...styles.secondaryButton,
								marginLeft: "6px",
								marginRight: "0px",
								verticalAlign: "top",
								lineHeight: "18px",
							}}
						>
							{getLocalizedStrings().label.COMMON.CANCEL}
						</Button>
					);

				}
			}

			if (
				openMode === constants.SF_FORM_OPEN_MODE_QUICK && 
				object !== constants.PAYMENTS_OBJECT && object !== constants.REFUND_OBJECT && object !== constants.REPORTED_SALES_OBJECT
			    && showRequiredFormFields == false
			) {
				buttons.push(
					<Button
						key="expand"
						style={{
							...styles.secondaryButton,
							marginLeft: "6px",
							marginRight: "0px",
							verticalAlign: "top",
						}}
						onClick={() => openAsExpandedMode()}
					>
						{getLocalizedStrings().label.COMMON.EXPAND}
					</Button>
				);

				// buttons.push(
				//     <Button
				//         key='customize'
				//         //onClick={saveSFForm}
				//         label="Customize"
				//         style={{ ...styles.secondaryButton, marginLeft: '10px', marginRight: '0px' }}
				//     />
				// );
			}
		}

		return buttons;
	};

	const confirmAndNewTaskApp = () => {
		try {
			let objectName = object;
			let hasPermission = hasAccessPermission(
				objectName,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_CREATE
			);
			if (hasPermission) {
				let dataValue = {
					id: 0,
					object: objectName,
				};

				if (isValidParam(parentDetails)) {
					dataValue["parent_object"] = parentDetails.parent_object;
					dataValue["parent_record_id"] = parentDetails.parent_record_id;
					dataValue["isDetailView"] = isDetailView;
				}
				if (isValidParam(parent_link_info)) {
					dataValue.parent_link_info = parent_link_info;

					let recordDetails = {};
					recordDetails.company = record.company;
					recordDetails.fullname = record.fullname;
					if (record.opportunity_name !== null) {
						recordDetails.opportunity_name = record.opportunity_name;
					} else {
						recordDetails.opportunity_name = "";
					}
					dataValue.recordDetails = recordDetails;
				}else if(objectName === constants.APPOINTMENTS && (parent_link_info == null || parent_link_info == "") ){
					dataValue.parent_link_info = "0_0,0_0";

					let recordDetails = {};
					recordDetails.company = record.company;
					recordDetails.fullname = record.fullname;
					if (record.opportunity_name !== null) {
						recordDetails.opportunity_name = record.opportunity_name;
					} else {
						recordDetails.opportunity_name = "";
					}
					dataValue.recordDetails = recordDetails;
				
				}
				dataValue.isCompleAndNew = true;

				let objName = "";
				if (objectName === constants.APPOINTMENTS) {
					objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
				} else if (objectName === constants.TASKS) {
					objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
				}
				let labelName =
					getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT +
					" " +
					objName;
				dispatch(getAppDrawer(
					true,
					labelName,
					constants.NEW,
					dataValue,
					constants.OTHER
				));
			} else {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.COMMON.ACCESS_DENIED,
					null
				);
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> confirmAndNewTaskApp()':" + error);
		}
	};

	function completeTaskApp(name) {
		try {
			const recordIds = [];
			recordIds[0] = recordId;
			let params = {
				id: recordIds,
				audit_trail_tableIds: getStringParam(
					app.me.audit_trail_table_ids
				),
			};
			let msg = "";
			const response = completeAction(object, params);
			setMounted(false);
			response.then((response) => {
				if (response.status === 0) {
					if (name === "completeandnew") {
						let tempFieldProps = fieldProps;
						if (object == 'appointments' && appContainer.drawerProps.data != null) {
							tempFieldProps['subject'].value = "Followup";
							if (
								object === constants.APPOINTMENTS &&
								app.me.calendar_setup &&
								app.me.calendar_setup.length > 0
							) {
								tempFieldProps['location'].value = app.me.calendar_setup[0].location;
								tempFieldProps['text'].value = app.me.calendar_setup[0].description;
							}
						}
						if (
							object === constants.APPOINTMENTS &&
							tempFieldProps.hasOwnProperty('subject') &&
							tempFieldProps['subject'].value === "Followup" && appointmentSub === ""
						) {
							tempFieldProps['subject'].value = getLocalizedStrings().label.APPOINTMENTS_TASKS.FOLLOWUP;
						} else if (
							object === constants.APPOINTMENTS &&
							tempFieldProps.hasOwnProperty('subject') &&
							tempFieldProps['subject'].value === "Followup" &&
							(appointmentSub && appointmentSub !== "")
						) {
							tempFieldProps['subject'].value = appointmentSub;
						}
						let currentTime = moment().tz(timeZone[app.me.timezone]);
						let remainder = currentTime.minute() % constants.CALENDER_DEFAULT_INTERVAL;
						let start = currentTime.subtract(remainder, "m").format(dateTimeFormat[app.me.date_format]);
						let end = currentTime.add(constants.CALENDER_DEFAULT_INTERVAL, "m").format(dateTimeFormat[app.me.date_format]);
						if (tempFieldProps['subject']) {
							tempFieldProps['subject'].value = "Followup";
						}
						if (tempFieldProps['start_date']) {
							tempFieldProps['start_date'].value = start;
						}
						if (tempFieldProps['end_date']) {
							tempFieldProps['end_date'].value = end;
						}
						if (tempFieldProps['t_visibility']) {
							tempFieldProps['t_visibility'].value = 'Public';
						}
						if (tempFieldProps['event_type']) {
							tempFieldProps['event_type'].value = appContainer?.drawerProps?.data?.event_type ? appContainer.drawerProps.data.event_type : '';
						}
						if (tempFieldProps['t_status']) {
							tempFieldProps['t_status'].value = "Scheduled";
						}
						if (tempFieldProps['assign']) {
							tempFieldProps['assign'].value = app?.me?.name;
						}
						if (tempFieldProps['company']) {
							tempFieldProps['company'].value = record?.company ? record.company : '';
						}
						if (tempFieldProps['fullname']) {
							tempFieldProps['fullname'].value = record?.fullname ? record?.fullname : '';
						}
						if (tempFieldProps['opportunity_name']) {
							tempFieldProps['opportunity_name'].value = '';
						}
						if (tempFieldProps['project_name']) {
							tempFieldProps['project_name'].value = recordDetails?.project_name ? recordDetails.project_name : '';
						}
						if (tempFieldProps['location']) {
							tempFieldProps['location'].value = app.me.calendar_setup[0]?.location ? app.me.calendar_setup[0]?.location : '';
						}
						if (tempFieldProps['text']) {
							tempFieldProps['text'].value = app.me.calendar_setup[0]?.description ? app.me.calendar_setup[0]?.description : '';
						}
						setFieldProps(tempFieldProps);
						let _reminder = [{
							"t_time_type": "minutes",
							"t_reminder_type": "Email",
							"t_time_value": "10"
						}];
						setReminderData(_reminder);
						dispatch(updateSFFormReminderFunc(_reminder));

						let temp = recurring_fields;
						temp.start_date = start;
						temp.endDate = end;
						temp.recurring_type = "daily";
						temp.repeat_every = 1;
						setRecurring_fields(temp);
						setRecurring_enable(false);
						let log = changeLog;
						log.isStateCompleteAndNew = true;;
						setchangeLog({ ...log });
						setMounted(true)
					}
					closeDrawer("completetaskapp");
					if (callFrom === constants.LIST_VIEW && !isDetailView) {
						refreshListView(object, { isDetailView: false });
					} else if (callFrom === constants.COLUMN_VIEW) {
						let dataParams = {
							record_id: data.record_id,
							object: data.parentObject,
							listIndex: data.listIndex,
							cardIndex: data.cardIndex,
							updateCards: data.updateCards,
						};
						refreshColumnView(dataParams);

					} else if (callFrom === "ColumnViewAddTaskApp") {
						let _dataParams = {
							record_id: parentDetails.record_id,
							object: parentDetails.parentObject,
							listIndex: parentDetails.listIndex,
							cardIndex: parentDetails.cardIndex,
							updateCards: parentDetails.updateCards,
						};
						refreshColumnView(_dataParams);

					} else if (callFrom === constants.OTHER) {
						let info = getActiveTabInfo();
						if (isValidParam(info) && isValidParam(info.getTaskPageData)) {
							info.getTaskPageData(object);
						} else {
							dispatch(getTasksRefresh(true));
						}
					}
					else if (callFrom == "DetailView" && isDetailView) {
						dispatch(detailViewIsMounted(false));
						dispatch(getTasksRefresh(true));
						dispatch(refreshDetailViewListViewData(true));
						dispatch(refreshDetailViewData(true));

					}
					else {
						dispatch(getTasksRefresh(true));
						dispatch(refreshDetailViewListViewData(true));
						dispatch(refreshDetailViewData(true));
						dispatch(getCalendarRefresh(true));
						getDataAndProcess();
						refreshListView(object);
					}
					dispatch(getJobCalendarRefresh(true));

					msg =
						object.charAt(0).toUpperCase() +
						object.slice(1, object.length - 1) +
						" " +
						getLocalizedStrings().message.BATCH_ACTION.COMPLETE_SUCCESSFULL;
					dispatch(showCustomSnackBar(
						msg,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					));
					if (name === "completeandnew") {
						confirmAndNewTaskApp();
						dispatch(detailViewIsMounted(false));
						dispatch(isDetailViewSet(false));
						if (callFrom == 'queueView') {
							refreshView();
						}
						setRecurring_enable(false)
					}
					if (name === "complete") {
						dispatch(detailViewIsMounted(false));
						dispatch(isDetailViewSet(false));
						refreshView()
					}
				} else if (response.status !== 0 && response.error.message !== "") {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						response.error.message,
						null
					);
					setMounted(true);
					return false;
				} else {
					msg =
						object.charAt(0).toUpperCase() +
						object.slice(1, object.length - 1) +
						" " +
						getLocalizedStrings().message.BATCH_ACTION.COMPLETE_UNSUCCESSFULL;
					dispatch(showCustomSnackBar(
						msg,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					return false;
				}
			});
		} catch (error) {
			console.error("Error in 'sfForm.js -> completeTaskApp()':" + error);
		}
	};

	const handleReminderBlockUpdate = (dataValue) => {
		sfForm.reminder = dataValue;
		setReminder(dataValue);
		if (callFrom === "queueView" && object == constants.APPOINTMENTS) {
			setIsSavedDisabled(false);
			let tempChangeLog = changeLog;
			tempChangeLog.isSavedDisabled = false;
			setchangeLog({ ...tempChangeLog });
		}
	};

	const handleRepeatCheckBox = (event, isInputChecked) => {
		let startDate = null;
		if (object === constants.APPOINTMENTS) {
			startDate = fieldProps.start_date.value;
		} else if (object === constants.TASKS) {
			startDate = fieldProps.t_dueby.value;
		}
		let dataValue = {
			recurring_data: recurring_fields,
			start_date: startDate,
			recurring_enable: JSON.stringify(recurring_fields) !== "{}",
			recurring_edit: recurring_edit,
			object: object,
		};
		if (isInputChecked) {
			if (!recurring_edit) {
				dispatch(getAppDialog(
					true,
					constants.REPEAT_DIALOG,
					getLocalizedStrings().message.REPEAT_DIALOG.REPEAT,
					getRecurringData,
					dataValue
				));
			} else {

				setRecurring_enable(true);
			}
		} else {
			//  this.setState({ recurring_enable: false, recurring_fields: {} })
			setRecurring_enable(false);
		}
	};

	const handleQuickAddCheckBox = (event, isInputChecked) => {
		try {
			setIsAddToQuickbook(isInputChecked);
		} catch (error) {
			console.error(
				"Error in 'sfForm.js --->handleQuickAddCheckBox()':" + error
			);
		}
	};

	function handleEditRepeat() {
		let startDate = null;
		if (object === constants.APPOINTMENTS) {
			startDate = fieldProps.start_date.value;
		} else if (object === constants.TASKS) {
			startDate = fieldProps.t_dueby.value;
		}

		//let recurringStartDate = moment(this.state.recurring_fields.start_date);
		//let tempStartDate = moment(startDate);
		let temp = recurring_fields;
		temp.start_date = startDate;
		setRecurring_fields(temp);
		let dataValue = {
			recurring_data: recurring_fields,
			start_date: startDate,
			recurring_enable: recurring_enable,
			recurring_edit: recurring_edit,
			object: object,
		};
		dispatch(getAppDialog(
			true,
			constants.REPEAT_DIALOG,
			getLocalizedStrings().message.REPEAT_DIALOG.REPEAT,
			getRecurringData,
			dataValue
		));
	};

	const getRecurringData = (obj) => {
		if (
			recurring_edit &&
			recurring_enable &&
			JSON.stringify(recurring_fields) !==
			JSON.stringify(obj.recurring_details)
		) {
			setRecurringEditConfirmDialog(true);
		}
		setRecurring_fields(obj.recurring_details)

		setRecurring_enable(true);
		dispatch(getAppDialog(
			false,
			constants.REPEAT_DIALOG,
			null,
			null,
			null,
			null
		));
	};

	const setListValueMenuItems = (field) => {
		let hasPermission = false;
		let menuItems = null;
		let fieldName = null;
		let listValues = null;
		let isModifyList = false;
		try {
			if (isValidParam(field)) {
				fieldName = getStringParam(field.name);
				listValues = getArrayParam(field.list_values);
				isModifyList = getBooleanParam(field.is_modify_list);
				hasPermission = hasAccessPermission(
					object,
					constants.SECURITY_LEVEL_TYPE_PERMISSION,
					constants.PERMISSION_NAME_ADD_TO_LIST
				);

				if (object == "reportedsales" && fieldName == 'source') {
					listValues = listValues.filter((f) => f.id != "");
					listValues.push({ id: "Store", value: "Store" });
				}
				if(fieldName === 'sc_source'){
					// listValues = fieldProps?.sc_source?.list_values;
					listValues = sfForm?.sfFromData?.sc_source?.list_values;
					let isEmptyAdded = listValues?.filter((ele) =>  ele.id === -9999);
					if(isEmptyAdded?.length <= 0){
						listValues.unshift({ id: -9999, value: ' ' });
					}
				}
				if (object == constants.CONTACTS_OBJECT && fieldName == 'workflow_name') {
					listValues = listValues.filter((f) => f.id != "");

				}
				if (!field.value && object == "tasks" && unitTaskType.unit_owner) {
					field.value = unitTaskType.unit_owner;
				}
				if (object == "tasks" && fieldName == 't_owner' && getBooleanParam(getObjectParam(tasksObjParam)?.isUnitOwnerTasks && field.value !== '' && appContainer.drawerProps.data.hasOwnProperty('isUnitownerTasks'))) {
					listValues = [{ id: field.value, value: field.value }];

				}
				if (object == "tasks" && fieldName == 't_owner' && getBooleanParam(getObjectParam(tasksObjParam)?.isUnitOwnerTasks == false)) {
					let userListVal = getArrayParam(userlist?.data);
					if (userListVal.length > 0) {
						listValues = [];
						userListVal.map((m) => {
							listValues.push({ id: m.value, value: m.value })
						})
					}
				}			
				if(object === constants.PROJECTS_OBJECT && fieldName === "unit"){
					listValues.unshift({ id: -9999, value: '' });
				}
				menuItems = listValues.map((listValue, listValueIndex) => {
					return (
						<MenuItem
							key={listValue.id === "" ? "-9999" : listValue.id}
							id={listValue.id}
							value={listValue.value}
							primaryText={
								<div
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
										maxWidth: "300px",
									}}
									title={listValue.value}
								>
									{isValidParam(
										getLocalizedStrings().label.DEFAULT_NOTE_TYPES[
										listValue.value
										]
									)
										? getLocalizedStrings().label.DEFAULT_NOTE_TYPES[
										listValue.value
										]
										: listValue.value}
								</div>
							}
							style={styles.popoverMenuItem}
						/>
					);
				});
				if (
					object === constants.CONTACTS_OBJECT &&
					field.name === "t_status"
				) {
					let workflowName = fieldProps.hasOwnProperty("workflow_name") ? fieldProps.workflow_name.value : "";
					if (workflowName !== "") {
						isModifyList = false;
					}
				}

				let userId = getIntParam(app.me.id);

				if (isModifyList && (userId === 1 || hasPermission)) {
					let primaryText =
						fieldName === "source"
							? "---- " +
							getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE +
							" ----"
							: "---- " +
							getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST +
							" ----";
					menuItems.push(
						<MenuItem
							key={"addto_" + fieldName}
							value={primaryText}
							id={"addto_" + fieldName}
							primaryText={primaryText}
							style={styles.popoverMenuItem}
						/>
					);
				}

				if (
					fieldName === "sales_rep" &&
					(object === constants.ACCOUNTS_OBJECT ||
						object === constants.CASES_OBJECT ||
						object === constants.ISSUES_OBJECT )
				) {
					if (
						getStringParam(app.me.t_name) === "Admin" ||
						getBooleanParam(app.me.is_tenant)
					) {
						let primaryText = "Setup Assignment Rule";
						menuItems.push(
							<MenuItem
								key={primaryText}
								value={primaryText}
								id={primaryText}
								primaryText={primaryText}
								style={styles.popoverMenuItem}
							/>
						);
					}

					if (changeLog.isAssignRule) {
						let primaryText = "Use Assignment Rule";
						menuItems.unshift(
							<MenuItem
								key={primaryText}
								value={primaryText}
								id={primaryText}
								primaryText={primaryText}
								style={styles.popoverMenuItem}
							/>
						);
					}
				}
				let temp = listValueMenuItems;
				temp[fieldName] = menuItems;
				setListValueMenuItemsValue(temp);

				handleForceupdateMethod();
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> setListValueMenuItems()':" + error);
		}
		return menuItems;
	};

	const openLookup = (fieldName, isLookupField, lookupFieldName, lookupObject, lookupObjectLabel) => {

		let value = null;
		let moduleAccess = getObjectParam(app.me.module_access);
		let isOperations = moduleAccess[constants.MODULE_OPERATIONS];
		let drawerProps = appContainer.drawerProps;
		if (changeLog.isProjectTasks && isOperations === 1) {
			lookupObject = constants.UNITS_OBJECT;
		}

		if (
			(object === constants.OPPORTUNITIES_OBJECT ||
				object === constants.APPOINTMENTS ||
				object === constants.TASKS) &&
			isValidParam(drawerProps)
		) {
			let info = drawerProps.data;
			let LookUpfield =
				object === constants.OPPORTUNITIES_OBJECT
					? "contact_name"
					: "fullname";
			if (
				info.isDetailView &&
				(drawerProps.actionType === constants.NEW ||
					drawerProps.actionType === constants.EDIT)
			) {
				let field = null;
				if (lookupObject === constants.CONTACTS_OBJECT) {
					field = fieldProps[LookUpfield];
					if (isValidParam(field)) {
						if (Object.keys(field.value).length === 0) {
							field.value = "";
						}
						if (getStringParam(field.value).length > 0) {
							if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
								value = "";
							} else {
								let exp = "(";
								let contactName = getStringParam(field.value);
								if (contactName.includes(exp)) {
									contactName = contactName
										.slice(0, contactName.indexOf(exp))
										.trim();
									value = contactName;
								} else {
									value = contactName;
								}
							}
						} else {
							value = "";
						}
					}
				} else if (lookupObject === constants.ACCOUNTS_OBJECT) {

					field = fieldProps["company"];
					if (isValidParam(field)) {
						if (getStringParam(field.value).length > 0) {
							if (
								getStringParam(field.value) === "(" + constants.EMPTY + ")" ||
								Object.keys(field.value).length === 0
							) {
								value = "";
							} else {
								value = getStringParam(field.value); //company
							}
						}
					}
				} else {
					value = fieldProps[fieldName].value;
				}
			} else {
				if (
					drawerProps.actionType === constants.NEW ||
					drawerProps.actionType === constants.EDIT
				) {
					if (lookupObject === constants.CONTACTS_OBJECT) {
						let field = fieldProps[LookUpfield];
						if (Object.keys(field.value).length === 0) {
							field.value = "";
						}
						if (getStringParam(field.value).length > 0) {
							if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
								value = "";
							} else {
								let exp = "(";
								let contactName = getStringParam(field.value);
								if (contactName.includes(exp)) {
									contactName = contactName
										.slice(0, contactName.indexOf(exp))
										.trim();
									value = contactName;
								} else {
									value = contactName;
								}
							}
						} else {
							value = "";
						}
					} else if (lookupObject === constants.ACCOUNTS_OBJECT) {
						let hasContact = getStringParam(fieldProps[LookUpfield].value).length > 0;
						let hasAccount = getStringParam(fieldProps["company"].value).length > 0;
						if (hasContact) {
							if (!hasAccount) {
								if (getArrayParam(changeLog.lookupInfoState).length > 0) {
									changeLog.lookupInfoState.forEach((m) => {
										if (m.hasOwnProperty("company")) {
											value = getStringParam(m.company);
										}
									});
								}
							} else {
								value = fieldProps["company"].value;
							}
						}
					} else {
						value = fieldProps[fieldName].value;
					}
				}
			}
		} else {
			value = fieldProps[fieldName].value;
		}
		let dataValue = {};
		dataValue.fieldName = getStringParam(fieldName);
		if (
			object === constants.OPPORTUNITIES_OBJECT &&
			fieldName === "product" &&
			lookupFieldName === "product_name"
		) {
			dataValue.searchText = getArrayParam(value);
		} else {
			dataValue.searchText = getStringParam(value);
		}
		if (
			(object === constants.APPOINTMENTS ||
				object === constants.TASKS) &&
			(lookupFieldName === "company" || lookupFieldName === "full_name")
		) {
			dataValue.click_form = "appointmentForm";
		}
		dataValue.isLookupField = getBooleanParam(isLookupField);
		dataValue.lookupFieldName = getStringParam(lookupFieldName);
		dataValue.lookupObject = getStringParam(lookupObject);
		dataValue.object = getStringParam(lookupObject);
		dispatch(getAppDialog(
			true,
			constants.LOOKUP_DIALOG,
			`Find and Link ${lookupObjectLabel}`,
			(info) => getLookupSelectedRecord(info),
			dataValue,
			null
		));

	};

	const getLookupSelectedRecord = (lookupInfo) => {
		try {
			if (isValidParam(lookupInfo)) {
				let tempfieldProps = fieldProps;
				if (
					object === constants.OPPORTUNITIES_OBJECT &&
					getStringParam(lookupInfo.lookup_object_name).toLowerCase() ===
					constants.PRODUCTS_OBJECT
				) {
					setLookupInfo(lookupInfo, tempfieldProps);
				} else {
					setLookupInfo(lookupInfo, tempfieldProps);

					let lookupObject = getStringParam(lookupInfo.lookup_object_name).toLowerCase();
					let lookupObjectId = getIntParam(OBJECT_TABLEID_MAP[lookupObject]);
					let templinkInfoObject = linkInfoObject;
					templinkInfoObject[lookupObjectId] = getIntParam(lookupInfo.record_id);
					setLinkInfo(templinkInfoObject);
					setLinkInfoObject({ ...templinkInfoObject });

					tempfieldProps[lookupInfo.field_name].value = getStringParam(lookupInfo.title);
					setFieldProps(tempfieldProps);
					if (!changeLog.isProjectTasks) {
						getContactInfoForSelectedRecord(
							getIntParam(lookupInfo.record_id)
						);
					}
				}
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> getLookupSelectedRecord()':" + error
			);
		}
		dispatch(getAppDialog(false, null, null, null, null, null));
	};

	const setLookupInfo = (lookupInfo, tempFieldProps) => {

		tempFieldProps = isValidParam(tempFieldProps) ? tempFieldProps : fieldProps;
		try {
			if (isValidParam(lookupInfo)) {
				let lookupObject = getStringParam(lookupInfo.lookup_object_name);
				let fieldName = getStringParam(lookupInfo.field_name);
				let lookupText = getStringParam(lookupInfo.title);
				let lookupFieldName = getStringParam(lookupInfo.lookup_field_name);
				let isLookupField = getBooleanParam(lookupInfo.is_lookup_field);
				let tempChangeLog = changeLog;
				if (
					object === constants.OPPORTUNITIES_OBJECT &&
					lookupObject === constants.PRODUCTS_OBJECT &&
					lookupInfo.hasOwnProperty("arrLookupRecordInfo")
				) {
					let recordIds = "";


					let arrLookupRecordInfo = getArrayParam(
						lookupInfo.arrLookupRecordInfo
					);
					arrLookupRecordInfo.forEach((record) => {
						if (isValidParam(record)) {
							let recordid = getIntParam(record.record_id);
							recordid = recordid.toString();
							recordIds = recordIds === "" ? recordid : recordIds + "," + recordid;
						}
					});
					if (lookupObject !== "" && recordIds !== null) {
						let tempLookupInfo = {
							lookup_field_name: lookupFieldName,
							lookup_object_name: lookupObject,
							record_id: recordIds,
							title: lookupText,
						};

						let arrLookupInfo = getArrayParam(tempChangeLog.lookupInfoState);
						arrLookupInfo = arrLookupInfo.filter((f) => {
							return getStringParam(f.lookup_field_name) !== lookupFieldName;
						});
						arrLookupInfo.push(tempLookupInfo);
						setLookupInfoState(arrLookupInfo);
						tempChangeLog.lookupInfoState = arrLookupInfo;
					}
					if (
						fieldName !== "" &&
						tempFieldProps.hasOwnProperty(fieldName) &&
						lookupFieldName !== ""
					) {
						if (isLookupField) {
							let searchParams = {};
							searchParams.fields = [lookupFieldName];
							searchParams.search_field_name = "id";
							searchParams.search_text = recordIds;
							// searchParams.start_index = 0;
							// searchParams.page_size = 1;
							searchParams.click_form = "opportunity_form";
							let searchPromise = Promise.resolve(
								getListData(lookupObject, searchParams)
							);
							searchPromise.then((response) => {
								let records = isValidParam(response)
									? getArrayParam(response.records)
									: [];
								records = records.map((f, i) => {
									let obj = {};
									obj.id = f.id;
									obj.name = f.product_name;
									return obj;
								});
								tempFieldProps[fieldName].value = records;
								let productRecordIds = "";
								if (records.length > 0) {
									records.forEach((record) => {
										if (isValidParam(record)) {
											let productRecordId = getIntParam(record.id);
											productRecordIds =
												productRecordIds === ""
													? productRecordId
													: productRecordIds + "," + productRecordId;
										}
									});
								}
								tempFieldProps["product_id"].value = productRecordIds;
								setFieldProps(tempFieldProps);
								handleForceupdateMethod();
							});
						}
					}
				} else {

					let lookupRecordId = getIntParam(lookupInfo.record_id);

					if (
						changeLog.isProjectTasks &&
						lookupObject === constants.UNITS_OBJECT
					) {
						lookupObject = constants.ACCOUNTS_OBJECT;
					}
					if (lookupObject !== "" && lookupRecordId > 0) {
						let lookupInfo = {
							lookup_field_name: lookupFieldName,
							lookup_object_name: lookupObject,
							record_id: lookupRecordId,
							title: lookupText,
						};
						let temparrLookupInfo = getArrayParam(tempChangeLog.lookupInfoState);
						temparrLookupInfo = temparrLookupInfo.filter((f) => {
							return getStringParam(f.lookup_field_name) !== lookupFieldName;
						});
						temparrLookupInfo.push(lookupInfo);
						setLookupInfoState(temparrLookupInfo);
						tempChangeLog.lookupInfoState = temparrLookupInfo;
						setchangeLog({ ...tempChangeLog });
						handleForceupdateMethod();
					}

					if (
						fieldName !== "" &&
						tempFieldProps.hasOwnProperty(fieldName) &&
						lookupFieldName !== ""
					) {
						if (isLookupField) {
							if (
								object === constants.CASES_OBJECT
							) {
								let promise = Promise.resolve(getFranchiseInfo(lookupRecordId));
								promise.then((response) => {
									if (isValidParam(response)) {
										let recordVal = getObjectParam(response);
										tempFieldProps[fieldName].value = getStringParam(recordVal[lookupFieldName]);
										setFieldProps(tempFieldProps);
										setFranchiseId(getStringParam(recordVal.franchise_id));
									}
								});
							} else {
								let tempFields = [];
								if (
									app.me.projectId ===
									constants.FARMINPEX_PROJECT_ID &&
									object === constants.CASES_OBJECT &&
									lookupObject === constants.CUSTOM_TABLE1_OBJECT
								) {
									tempFields = [lookupFieldName, "custom_field6"];
								} else if (
									object === constants.OPPORTUNITIES_OBJECT &&
									lookupObject === constants.CONTACTS_OBJECT
								) {
									tempFields = [lookupFieldName, "company"];
								} else if (
									(object === constants.APPOINTMENTS &&
										lookupObject === constants.CONTACTS_OBJECT) ||
									(object === constants.TASKS &&
										lookupObject === constants.CONTACTS_OBJECT)
								) {
									tempFields = [lookupFieldName, "company"];
								} else if (
									object === constants.PAYMENTS_OBJECT &&
									lookupObject === constants.QUOTATION_OBJECT
								) {
									tempFields = [lookupFieldName, "plan_name"];
								} else {
									tempFields = [lookupFieldName];
								}
								let searchParams = {
									fields: tempFields, search_field_name: "id", search_text: lookupRecordId, start_index: 0, page_size: 1, query_name: "All records"
									, query_type: "all"
								};


								let searchPromise = Promise.resolve(
									getListData(lookupObject, searchParams)
								);
								searchPromise.then((response) => {
									let records = isValidParam(response)
										? getArrayParam(response.records)
										: [];
									let recordVal = records.length > 0 ? records[0] : {};
									if (Object.keys(recordVal).length > 0) {
										if (
											(object === constants.OPPORTUNITIES_OBJECT &&
												lookupObject === constants.CONTACTS_OBJECT) ||
											(object === constants.APPOINTMENTS &&
												lookupObject === constants.CONTACTS_OBJECT) ||
											(object === constants.TASKS &&
												lookupObject === constants.CONTACTS_OBJECT)
										) {
											tempFieldProps[fieldName].value = getOpportunityContactName(lookupFieldName, records[0]);
											let tempRecord = records[0];

											if (lookupObject === constants.CONTACTS_OBJECT) {
												let params = {};
												params.parent_object_id = 9;
												params.parent_record_id = lookupRecordId;
												params.object_id = 10;
												let tempCompany =
													getStringParam(tempRecord["company"]).length > 0
														? getStringParam(tempRecord["company"])
														: constants.EMPTY;
												tempFieldProps["company"].value = tempCompany;
												let promise = Promise.resolve(getLinkRecords(params));
												promise.then((response) => {
													if (
														isValidParam(response) &&
														isValidParam(response.records)
													) {
														let records = null;
														records = getArrayParam(response.records);
														if (records.length > 0) {
															let recordValue = null;

															let tempArr = records.filter((f) => {
																return f.company === tempCompany;
															});
															recordValue =
																tempArr.length > 0 ? tempArr[0] : records[0];

															if (isValidParam(recordValue)) {
																let id = getIntParam(recordValue.id);
																let company = getStringParam(recordValue.company);

																//lookupInfo = { lookup_field_name: '', lookup_object_name: contants.ACCOUNTS_OBJECT, record_id: id };
																lookupInfo = {
																	lookup_field_name: "company",
																	lookup_object_name: constants.ACCOUNTS_OBJECT,
																	record_id: id,
																	title: company,
																};

																let arrLookupInfo = getArrayParam(
																	tempChangeLog.lookupInfoState
																);
																arrLookupInfo.push(lookupInfo);

																let parentTableId =
																	OBJECT_TABLEID_MAP[
																	getStringParam(
																		lookupInfo.lookup_object_name
																	)
																	];
																let temp = linkInfoObject;
																temp[parentTableId] = id;
																setLinkInfoObject(temp);

																setLinkInfo(temp);
																setLookupInfoState(arrLookupInfo);
																tempChangeLog.lookupInfoState = arrLookupInfo;
															}
														} else {
															let arrLookupInfo = getArrayParam(
																tempChangeLog.lookupInfoState
															);
															let parentTableId =
																OBJECT_TABLEID_MAP[
																getStringParam(lookupInfo.lookup_object_name)
																];
															arrLookupInfo = arrLookupInfo.filter((f) => {
																let temp;
																if (
																	f.lookup_object_name !==
																	lookupInfo.lookup_object_name
																) {
																	temp = f;
																}
																return temp;
															});
															let temp = linkInfoObject;
															delete temp[
																OBJECT_TABLEID_MAP[
																getStringParam(lookupInfo.lookup_object_name)
																]
															];
															setLinkInfo(temp);
															setLinkInfoObject(temp);

															setLookupInfoState(arrLookupInfo);
															tempChangeLog.lookupInfoState = arrLookupInfo;

														}
													}
												});
											}
											if (
												object === constants.APPOINTMENTS ||
												object === constants.TASKS
											) {
												if (lookupObject === constants.CONTACTS_OBJECT) {
													let params = {};
													params.parent_object_id = 9;
													params.parent_record_id = lookupRecordId;
													params.object_id = 2;

													let promise = Promise.resolve(getLinkRecords(params));
													promise.then((response) => {
														if (
															isValidParam(response) &&
															isValidParam(response.records)
														) {
															let records = null;
															records = getArrayParam(response.records);
															if (records.length > 0) {
																let recordValue = null;
																recordValue = records[0];

																if (isValidParam(recordValue)) {
																	let id = getIntParam(recordValue.id);
																	let opportunity = getStringParam(recordValue.name);

																	lookupInfo = {
																		lookup_field_name: "opportunity_name",
																		lookup_object_name:
																			constants.OPPORTUNITIES_OBJECT,
																		record_id: id,
																		title: opportunity,
																	};

																	let arrLookupInfo = getArrayParam(
																		tempChangeLog.lookupInfoState
																	);
																	arrLookupInfo.push(lookupInfo);

																	let parentTableId =
																		OBJECT_TABLEID_MAP[
																		getStringParam(
																			lookupInfo.lookup_object_name
																		)
																		];
																	let templinkInfoObject = linkInfoObject;
																	templinkInfoObject[parentTableId] = id;
																	setLinkInfoObject(templinkInfoObject);

																	setLinkInfo(templinkInfoObject);
																	setLookupInfoState(arrLookupInfo);
																	tempChangeLog.lookupInfoState = arrLookupInfo;

																}
															} else {
																let arrLookupInfo = getArrayParam(
																	tempChangeLog.lookupInfoState
																);
																let parentTableId =
																	OBJECT_TABLEID_MAP[
																	getStringParam(
																		lookupInfo.lookup_object_name
																	)
																	];
																arrLookupInfo = arrLookupInfo.filter((f) => {
																	let temp;
																	if (
																		f.lookup_object_name !==
																		lookupInfo.lookup_object_name
																	) {
																		temp = f;
																	}
																	return temp;
																});
																let templinkInfoObject = linkInfoObject;
																delete templinkInfoObject[2];
																setLinkInfo(templinkInfoObject);
																setLinkInfoObject(templinkInfoObject);

																setLookupInfoState(arrLookupInfo);
																tempChangeLog.lookupInfoState = arrLookupInfo;
															}
															let tempoppo =
																records.length > 0 &&
																	isValidParam(records[0]) &&
																	getStringParam(records[0].name)
																	? getStringParam(records[0].name)
																	: constants.EMPTY;
															tempFieldProps["opportunity_name"].value = tempoppo;
															setFieldProps(tempFieldProps);
														}
													});
												}
											}
										} else if (
											object === constants.OPPORTUNITIES_OBJECT &&
											lookupObject === constants.ACCOUNTS_OBJECT
										) {
											tempFieldProps[fieldName].value = getStringParam(recordVal[lookupFieldName]);
											let templinkInfoObject = linkInfoObject;
											if (
												!linkInfoObject.hasOwnProperty(
													OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT]
												)
											) {
												templinkInfoObject = updateContactLinkInfoObject(); //constants.CONTACTS_OBJECT
											}
											setLinkInfo(templinkInfoObject);
										} else if (
											object === constants.PAYMENTS_OBJECT &&
											lookupObject === constants.QUOTATION_OBJECT &&
											isValidParam(parentDetails) &&
											getStringParam(parentDetails.parent_object) ===
											constants.JOBS_OBJECT
										) {
											let lookupValue = getStringParam(recordVal[lookupFieldName]);
											invoiceList.push({
												id: lookupRecordId,
												quoteno: lookupValue,
											});

											let menuItems = [];
											invoiceList.forEach((item) => {
												menuItems.push(
													<MenuItem
														key={item.quoteno}
														value={item.quoteno}
														id={item.quoteno}
														style={styles.popoverMenuItem}
													>
														{item.quoteno}
													</MenuItem>
												);
											});
											let templistValueMenuItems = listValueMenuItems;
											templistValueMenuItems["quoteno"] = menuItems;
											setListValueMenuItems(templistValueMenuItems)
											setInvoiceList(invoiceList);
											tempFieldProps[lookupFieldName].value = lookupValue;
										} else if (
											object === constants.PAYMENTS_OBJECT &&
											lookupObject === constants.QUOTATION_OBJECT && app.me.is_franchisee
										) {
											tempFieldProps[fieldName].value = getStringParam(
												recordVal[lookupFieldName]
											);
											tempFieldProps["plan_name"].value = getStringParam(
												recordVal["plan_name"]
											);
											handleForceupdateMethod();
										} else {
											tempFieldProps[fieldName].value = getStringParam(
												recordVal[lookupFieldName]
											);
										}
										if (
											app.me.projectId ===
											constants.FARMINPEX_PROJECT_ID &&
											object === constants.CASES_OBJECT &&
											lookupObject === constants.CUSTOM_TABLE1_OBJECT
										) {
											tempFieldProps["custom_field5"].value =
												getStringParam(recordVal["custom_field6"]);

										}
									} else {
										if (
											object === constants.PAYMENTS_OBJECT &&
											lookupObject === constants.QUOTATION_OBJECT &&
											isValidParam(parentDetails) &&
											getStringParam(parentDetails.parent_object) ===
											constants.JOBS_OBJECT
										) {
											let lookupValue = getStringParam(lookupText);
											invoiceList.push({
												id: lookupRecordId,
												quoteno: lookupValue,
											});
											let menuItems = [];
											invoiceList.forEach((item) => {
												menuItems.push(
													<MenuItem
														key={item.quoteno}
														value={item.quoteno}
														id={item.quoteno}
														style={styles.popoverMenuItem}
													>
														{item.quoteno}
													</MenuItem>
												);
											});

											let templistValueMenuItems = listValueMenuItems;
											templistValueMenuItems["quoteno"] = menuItems;
											setListValueMenuItems(templistValueMenuItems)
											setInvoiceList(invoiceList);
											tempFieldProps[lookupFieldName].value = lookupValue;

										} else {
											if (getStringParam(lookupText) !== "") {
												tempFieldProps[fieldName].value = lookupText;
											}
										}
									}
									setFieldProps(tempFieldProps);
								});
							}
						} else {
							tempFieldProps[fieldName].value = lookupText;
							setFieldProps(tempFieldProps);
						}
					}
				}
				setchangeLog({ ...tempChangeLog });
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> setLookupInfo()':" + error);
		}
	};

	const onCheckedSalesRep = (isNotifySalesRepValue) => {
		setIsNotifySalesRep(isNotifySalesRepValue);
	};
	const onCheckedTextNotifySalesRep = (isTextNotifySalesRepValue) => {
		setIsTextNotifySalesRep(isTextNotifySalesRepValue);
	};
	const onCheckedPinNote = (isPinChecked) => {
		if (isPinChecked === true) {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				"This will replace the existing pinned note if any."
			);
		}
		sfForm.sfFromData = { ...sfForm.sfFromData, isPinNoteChecked: isPinChecked };
		setIsPinNoteChecked(isPinChecked);

	};
	const onCheckedImportantNote = (importantNote) => {
		sfForm.sfFromData = { ...sfForm.sfFromData, isImportantNote: importantNote };
		setIsImportantNote(importantNote);
	};
	const setFlyingNoteTextFn = (event) => {

		setFlyingNoteText(event);
	};

	const setAutoCompleteValueForMultipleSelection = (
		fieldName,
		lookupFieldName,
		lookupObject,
		records
	) => {
		if (
			object === constants.OPPORTUNITIES_OBJECT &&
			lookupObject === constants.PRODUCTS_OBJECT &&
			lookupFieldName === "product_name"
		) {
			let arrLookupRecordInfo = getArrayParam(records);
			let recordIds = "";
			arrLookupRecordInfo.forEach((record) => {
				if (isValidParam(record)) {
					let recordid = getIntParam(record.id);
					recordid = recordid.toString();
					recordIds = recordIds === "" ? recordid : recordIds + "," + recordid;
				}
			});
			if (lookupObject !== "" && recordIds !== null) {
				let lookupInfo = {
					lookup_field_name: lookupFieldName,
					lookup_object_name: lookupObject,
					record_id: recordIds,
				};
				let arrLookupInfo = getArrayParam(changeLog.lookupInfoState);
				arrLookupInfo = arrLookupInfo.filter((f) => {
					return getStringParam(f.lookup_field_name) !== lookupFieldName;
				});
				arrLookupInfo.push(lookupInfo);
				setLookupInfoState(arrLookupInfo);
				let tempChangeLog = changeLog;
				setchangeLog({ ...tempChangeLog, lookupInfoState: arrLookupInfo });
				handleForceupdateMethod();
				let tempfieldProps = fieldProps;

				tempfieldProps[fieldName].value = records.length == 0 ? '' : records;
				tempfieldProps["product_id"].value = recordIds;
				// let autoCompleteDataSource =  this.state.autoCompleteDataSource;
				// autoCompleteDataSource[fieldName] = records;
				setFieldProps(tempfieldProps);
			}
		}
	};
	const setAutoCompleteValueOnchange = (
		fieldName,
		lookupFieldName,
		lookupObject,
		obj
	) => {
		lookupObject = getStringParam(lookupObject);
		lookupFieldName = getStringParam(lookupFieldName);
		fieldName = getStringParam(fieldName);
		if (
			object === constants.OPPORTUNITIES_OBJECT &&
			lookupObject === constants.PRODUCTS_OBJECT
		) {
			setAutoCompleteValueForMultipleSelection(
				fieldName,
				lookupFieldName,
				lookupObject,
				obj
			);
		} else {
			obj = getObjectParam(obj);
			let id = getIntParam(obj.id);
			if (id === -999999) {
				let tempChangeLog = { ...changeLog };
				let name = getStringParam(obj.value);
				tempChangeLog.autoCompleteDataSource[fieldName][0].name = name;
				setAutoCompleteDataSource(tempChangeLog.autoCompleteDataSource);
				if (
					object === constants.OPPORTUNITIES_OBJECT &&
					(lookupObject === constants.ACCOUNTS_OBJECT ||
						lookupObject === constants.CONTACTS_OBJECT)
				) {
					try {
						let params = prepareParams(lookupObject);
						let promise = SFFormActions.saveRecordPromise(lookupObject, params);
						promise.then((response) => {
							if (
								isValidParam(response) &&
								response.hasOwnProperty("data") &&
								response.data.hasOwnProperty("id")
							) {
								let options = [{ id: response.data.id[0], name: name }];
								let tempFieldProps = fieldProps;
								tempFieldProps[fieldName].value = options[0];
								setFieldProps(tempFieldProps);
								tempChangeLog.autoCompleteDataSource[fieldName] = options;
								setAutoCompleteDataSource(tempChangeLog.autoCompleteDataSource);
								setAutoCompleteValue(
									fieldName,
									lookupFieldName,
									lookupObject,
									options[0]
								);
							}
						});
					} catch (error) {
						console.error(
							"Error in 'sfForm.js -> setAutoCompleteValue()':" + error
						);
					}
				}
				setchangeLog(tempChangeLog);
			} else {
				setAutoCompleteValue(
					fieldName,
					lookupFieldName,
					lookupObject,
					obj
				);
			}
		}
	};

	const prepareParams = (objectName) => {
		let dataValue = Object.assign({}, sfForm.data[objectName]);
		dataValue = getObjectParam(dataValue);
		let fieldsVal = [];
		let params = {};
		fieldsVal = dataValue.fields;
		let tempfieldProps = [];
		fieldsVal.forEach((f) => {
			if ((f.field_type === constants.FIELD_TYPE_DATE_TIME || f.field_type === constants.FIELD_TYPE_DATE) &&
				isValidParam(f.default_value) &&
				f.default_value !== ""
			) {
				let currentTime = moment().tz(timeZone[app.me.timezone]);
				currentTime = currentTime.format(
					dateFormat[app.me.date_format]
				);
				let defaultDate = moment(f.default_value).format(
					dateFormat[app.me.date_format]
				);
				if (defaultDate === currentTime) {
					// This condition used for default value : 'CURRENT_DATE+N'
					currentTime = moment().tz(timeZone[app.me.timezone]);
					defaultDate = currentTime.format(
						dateTimeFormat[app.me.date_format]
					);
					f.default_value = defaultDate === "Invalid date" ? "" : defaultDate;
				}
			}
		});
		//fieldsVal = fieldsVal.filter((f) => f.name !== "royalty_percent");
		fieldsVal = fieldsVal.filter((f) => {
			return f.field_type !== constants.FIELD_TYPE_HEADER;
		});
		fieldsVal.forEach((field, index) => {
			let tempfield = {};
			tempfield.id = field.id;
			tempfield.name = field.name;
			tempfield.fieldType = field.field_type;
			if (field.default_value !== "") {
				tempfield.value = field.default_value;
			} else if (field.name === "company") {
				if (objectName === constants.ACCOUNTS_OBJECT) {
					tempfield.value = lookupInput;
				} else {
					tempfield.value = field.default_value;
				}
			} else if (
				field.name === "first_name" &&
				objectName === constants.CONTACTS_OBJECT
			) {
				let tempArr = lookupInput.split(" ");
				if (tempArr.length > 1) {
					tempArr.pop();
					let firstName = "";
					for (let j = 0; j < tempArr.length; j++) {
						let val = tempArr[j];
						if (val === null) {
							val = "";
						}
						if (j !== 0 && (firstName !== null && firstName !== "")) {
							firstName = firstName + " " + val;
						} else {
							firstName = val;
						}
					}
					tempfield.value = firstName;
				} else {
					tempfield.value = tempArr[0];
				}
			} else if (
				field.name === "last_name" &&
				objectName === constants.CONTACTS_OBJECT
			) {
				let tempArr = lookupInput.split(" ");
				if (tempArr.length > 1) {
					tempfield.value = tempArr.pop();
				} else {
					tempfield.value = field.default_value;
				}
			} else if (field.name === "full_name") {
				tempfield.value = lookupInput;
			} else if (field.name === "workflow_name") {
				let listvalues = field.list_values;
				if (listvalues.length > 0) {
					tempfield.value = listvalues[0].value;
				} else {
					tempfield.value = field.default_value;
				}
			} else if (field.name === "workflow_id") {
				if (objectName === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT) {
					let wfField = fieldsVal.filter((f) => f.name === "workflow_name");
					if (wfField.length > 0) {
						let listvalues = wfField[0].list_values;
						if (listvalues.length > 0) {
							tempfield.value = listvalues[0].id;
						} else {
							tempfield.value = 0;
						}
					} else {
						tempfield.value = 0;
					}
				} else {
					tempfield.value = 0;
				}
			} else {
				tempfield.value = field.default_value;
			}
			tempfieldProps.push(tempfield);
		});
		if (tempfieldProps !== null) {
			let tempDateValue = null;

			params.id = 0;
			params.parent_object = "";
			params.parent_record_id = 0;
			params.fields = [];
			params.call_from = "lookUp";

			tempfieldProps.forEach((field, index) => {
				let tempField = {};
				tempField.id = field.id;
				tempField.name = field.name;
				if (field.fieldType === constants.FIELD_TYPE_DATE_TIME) {
					if (typeof field.value === "string") {
						if (getStringParam(field.value) === "") {
							tempDateValue = null;
						} else {
							tempDateValue = moment(field.value);
						}
					} else {
						if (getStringParam(field.value) === "") {
							tempDateValue = null;
						} else {
							tempDateValue = field.value;
						}
					}

					if (isValidParam(tempDateValue)) {
						tempDateValue = tempDateValue.format("YYYY-MM-DD HH:mm:ss");
						tempField.value = tempDateValue;
					} else {
						tempField.value = tempDateValue;
					}
				} else if (field.fieldType === constants.FIELD_TYPE_DATE) {
					if (typeof field.value === "string") {
						if (getStringParam(field.value) === "") {
							tempDateValue = null;
						} else {
							tempDateValue = moment(field.value);
						}
					} else {
						if (getStringParam(field.value) === "") {
							tempDateValue = null;
						} else {
							tempDateValue = field.value;
						}
					}

					if (isValidParam(tempDateValue)) {
						tempDateValue = tempDateValue.format("YYYY-MM-DD");
						tempField.value = tempDateValue;
					} else {
						tempField.value = "";
					}
				} else {
					tempField.value = field.value;
				}

				if (
					objectName === constants.ACCOUNTS_OBJECT &&
					tempField.name === "company" &&
					isValidParam(tempField.value) &&
					getStringParam(tempField.value) === ""
				) {
					let firstName = tempfieldProps.hasOwnProperty("first_name")
						? getStringParam(tempfieldProps["first_name"].value)
						: "";
					let lastName = tempfieldProps.hasOwnProperty("last_name")
						? getStringParam(tempfieldProps["last_name"].value)
						: "";
					let fullName = (firstName + " " + lastName).trim();
					tempField.value = fullName;
				} else {
					params.fields.push(tempField);
				}
			});
			params.lookup_info = changeLog.lookupInfoState;
			if (objectName === constants.ACCOUNTS_OBJECT) {
				let cmpFieldForSave = params.fields.filter((f) => {
					return f.name === "company";
				});
				if (cmpFieldForSave.length === 0) {
					let accFieldDetails = sfForm.data[objectName];
					let accFields = accFieldDetails.fields;
					let cmpFieldInForm = accFieldDetails.fields.filter((f) => {
						return f.name === "company";
					});
					if (cmpFieldInForm.length > 0) {
						let cmpFieldObj = {
							id: cmpFieldInForm[0].id,
							name: cmpFieldInForm[0].name,
							value:
								tempfieldProps["first_name"].value +
								" " +
								tempfieldProps["last_name"].value,
						};
						params.fields.push(cmpFieldObj);
					}
				}
			}
			// params = params;
		}
		return params;
	};
	const setAutoCompleteValue = (fieldName, lookupFieldName, lookupObject, obj) => {

		try {
			lookupObject = getStringParam(lookupObject);
			lookupFieldName = getStringParam(lookupFieldName);
			fieldName = getStringParam(fieldName);
			obj = getObjectParam(obj);
			let id = getIntParam(obj.id);
			let name = getStringParam(obj.name);

			lookupObject =
				fieldName === "first_name" &&
					object === constants.CASES_OBJECT
					? constants.CONTACTS_OBJECT
					: lookupObject;

			let lookupInfo = {
				lookup_object_name: lookupObject,
				lookup_field_name: lookupFieldName,
				record_id: id,
				title: name,
			};
			if (
				object === constants.CASES_OBJECT &&
				app.selectedModuleName === constants.MODULE_OPERATIONS
			) {
				let tempfieldProps = fieldProps;
				tempfieldProps[lookupFieldName].value = getStringParam(
					obj[lookupFieldName]
				);
				setFieldProps(tempfieldProps);
				setFranchiseId(getStringParam(obj.franchise_id));
			}

			setLookupInfo(lookupInfo);


			if (object !== constants.CASES_OBJECT) {
				let parentTableId = OBJECT_TABLEID_MAP[lookupObject];
				let templinkInfoObject = linkInfoObject;
				templinkInfoObject[parentTableId] = id;
				setLinkInfo(templinkInfoObject);
				setLinkInfoObject(templinkInfoObject);

			}

			if (
				fieldName === "first_name" &&
				object === constants.CASES_OBJECT
			) {
				let temp = fieldProps;
				temp["first_name"].value = getStringParam(
					obj.first_name
				).trim();
				temp["last_name"].value = getStringParam(
					obj.last_name
				).trim();
				temp["from_email"].value = getStringParam(
					obj.email
				).trim();
				temp["phone"].value = getStringParam(obj.phone).trim();
				setFieldProps(temp);
			} else {
				getContactInfoForSelectedRecord(id);
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> setAutoCompleteValue()':" + error);
		}
	};

	const filterAutoCompleteSearchText = (searchText, key) => {
		searchText = getStringParam(searchText).toLowerCase();
		key = getStringParam(key).toLowerCase();
		return key.indexOf(searchText) > -1 ? true : false;
	};

	const getContactInfoForSelectedRecord = (recordid) => {
		try {
			if (
				object === constants.APPOINTMENTS ||
				object === constants.TASKS ||
				object === constants.CASES_OBJECT
			) {
				let setMenuItemsAtField = "fullname";
				setMenuItemsAtField =
					object === constants.CASES_OBJECT
						? "first_name"
						: setMenuItemsAtField;

				let menuItems = [];
				let params = {};
				params.object = "accounts";
				params.record_id = recordid;
				params.child_table_id = OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT];
				params.is_get_primary_contact = true;


				let contactName = "";

				let promise = Promise.resolve(getContactInfoForLookup(params));
				if (isValidParam(promise)) {
					promise.then((response) => {
						if (isValidParam(response)) {
							let childRecordArr = getArrayParam(response.records);
							setContactChildRecordArr(response.records);
							sortArrayObjectByProperty(childRecordArr, "value");
							let tempFieldProps = fieldProps;
							if (childRecordArr.length > 0) {
								if (object === constants.CASES_OBJECT) {
									let primaryContact = getObjectParam(response.primary_contact);
									let temp = changeLog;
									temp.contactRecords = childRecordArr;
									setchangeLog({ ...temp });

									if (isValidParam(primaryContact)) {
										let childRecordId = getIntParam(primaryContact.id);
										let firstName = getStringParam(
											primaryContact.first_name
										).trim();
										let lastName = getStringParam(
											primaryContact.last_name
										).trim();
										let email = getStringParam(primaryContact.email);
										let phone = getStringParam(primaryContact.phone);

										tempFieldProps[setMenuItemsAtField].value = firstName;
										tempFieldProps["last_name"].value = lastName;
										tempFieldProps["from_email"].value = email;
										tempFieldProps["phone"].value = phone;

										let lookupInfo = {
											lookup_field_name: "",
											lookup_object_name: constants.CONTACTS_OBJECT,
											record_id: childRecordId,
										};
										setLookupInfo(lookupInfo);
										let tempChangeLog = { ...changeLog };
										tempChangeLog.lookupInfoState = lookupInfo;
										setchangeLog({ ...tempChangeLog });
									}

									let options = [];
									childRecordArr.forEach((obj, i) => {
										obj.name = obj.value;
										options.push(obj);
									});
									tempFieldProps[setMenuItemsAtField].list_values = options;
									let emailList = []
									if (object == 'cases') {
										childRecordArr.map((m) => {
											return emailList.push(m.email)
										})
										if (emailList.length > 0) {
											tempFieldProps["from_email"].list_values = emailList.map(email => ({ id: email, value: email }))
											tempFieldProps["from_email"].field_type = 'list'
											tempFieldProps["from_email"].list_type = 'regular'
											tempFieldProps["from_email"].fieldType = 'list'

										}

										if (emailList.length == 1 && emailList[0] == '') {
											tempFieldProps["from_email"].field_type = 'text'
											tempFieldProps["from_email"].list_type = ''
											tempFieldProps["from_email"].fieldType = 'text'
										}

									}


								} else {
									map(childRecordArr, (childObject, index) => {
										if (isValidParam(childObject)) {
											let childRecordId = getIntParam(childObject.id);
											let fullName = getStringParam(childObject.value).trim();
											let firstName = getStringParam(
												childObject.first_name
											).trim();
											let lastName = getStringParam(
												childObject.last_name
											).trim();
											let email = getStringParam(childObject.email);
											let phone = getStringParam(childObject.phone);

											menuItems.push(
												// {id:childRecordId, name: childRecordId}
												<MenuItem
													key={childRecordId}
													id={childRecordId}
													value={fullName}
													fullName={fullName}
													firstName={firstName}
													lastName={lastName}
													email={email}
													phone={phone}
													style={styles.popoverMenuItem}
												>
													{
														<div
															style={{
																textOverflow: "ellipsis",
																whiteSpace: "nowrap",
																overflow: "hidden",
																maxWidth: "300px",
															}}
															title={fullName}
														>
															{fullName}
														</div>
													}
												</MenuItem>
											);

											if (index === 0) {
												fullName = fullName === "" ? "Empty" : fullName;
												if (childRecordArr.length == 1 && tempFieldProps[setMenuItemsAtField]?.value !== undefined) {
													tempFieldProps[setMenuItemsAtField].value = fullName;
												} else {
													let primaryContact = getObjectParam(response?.primary_contact);
													if (tempFieldProps[setMenuItemsAtField]?.value !== undefined) {
													tempFieldProps[setMenuItemsAtField].value = primaryContact?.full_name;
													}
												}
												let templinkInfoObject = linkInfoObject;
												if (object === constants.TASKS) {
													templinkInfoObject[9] = childRecordId;
													setLocationState(email);
													setLinkInfo(templinkInfoObject);
													setLinkInfoObject({ ...templinkInfoObject });

												} else if (
													object === constants.APPOINTMENTS
												) {
													templinkInfoObject[9] = childRecordId;
													setLocationState(app.me.calendar_setup.length > 0 ? app.me.calendar_setup[0].location : "");
													setLinkInfo(templinkInfoObject);
													setLinkInfoObject(templinkInfoObject);

												}
											}
											if (childObject?.value == tempFieldProps[setMenuItemsAtField]?.value) {
												if (object === constants.TASKS || object === constants.APPOINTMENTS) {
													let _templinkInfoObject = linkInfoObject;
													_templinkInfoObject[9] = childRecordId;
													setLinkInfo(_templinkInfoObject);
													setLinkInfoObject({ ..._templinkInfoObject });
												}
											}
										}
									});
									// this.props.updateAttendee(recordId);
									setAttendeeRecordId(recordid);
									let templistValueMenuItems = listValueMenuItems
									templistValueMenuItems[setMenuItemsAtField] =
										menuItems;
									setListValueMenuItems(templistValueMenuItems);
								}
								let dataVal = Object.assign({}, sfForm.data['cases']);
								let fieldsValue = getArrayParam(dataVal.fields);
								if (childRecordArr.length > 0) {
									if (object == 'cases') {
									
										let _emailList = []
										childRecordArr.map((m) => {
											return _emailList.push(m.email)
										})
										fieldsValue.forEach((f) => {
											if (_emailList.length > 0 && f.name == "from_email") {
												f.list_values = _emailList.map(email => ({ id: email, value: email }))
												if ( f.name == "from_email") {
													f.field_type = 'list';
													f.list_type = "regular";
													f.fieldType = 'list';
												}
												
											}
											if (_emailList.length == 1 && _emailList[0] == ''){											f.field_type = 'text';
												f.list_type = "";
												f.fieldType = 'text';
												f.field_type = 'text';
											}
											
										});

										setListValueMenuItems({
											name: "from_email",
											list_values: tempFieldProps["from_email"]?.list_values,
											is_required_field: true,
										});
										
									}
								
								}
								dataVal.fields = fieldsValue;
								sfForm.data[object] = Object.assign({}, dataVal);
								setFieldProps(tempFieldProps);
							}
						}
					});
				}
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> getContactInfoForSelectedRecord()':" + error
			);
		}
	}
	const handelOnBlur = (fieldName, lookupFieldName, lookupObject) => {
		if (
			object === constants.OPPORTUNITIES_OBJECT &&
			(lookupObject === constants.ACCOUNTS_OBJECT ||
				lookupObject === constants.CONTACTS_OBJECT)
		) {
			let tempChangeLog = { ...changeLog };
			if (lookupInfoState.length === 0) {
				let tempFieldProps = fieldProps;
				tempFieldProps[fieldName].value = "";
				setFieldProps(tempFieldProps);
				tempChangeLog.autoCompleteDataSource[fieldName] = [];
				setAutoCompleteDataSource(tempChangeLog.autoCompleteDataSource);
			} else if (lookupInfoState.length > 0) {
				let lookupobj = lookupInfoState.filter((f) => {
					return f.lookup_object_name === lookupObject;
				});
				if (lookupobj.length === 0) {
					let tempFieldProps = fieldProps;
					tempFieldProps[fieldName].value = ""
					setFieldProps(tempFieldProps);
					tempChangeLog.autoCompleteDataSource[fieldName] = [];
					setAutoCompleteDataSource(tempChangeLog.autoCompleteDataSource);
				} else {
					if (fieldProps[fieldName].value !== lookupobj[0].title) {
						fieldProps[fieldName].value = "";
						tempChangeLog.autoCompleteDataSource[fieldName] = [];
						lookupobj = lookupInfoState.filter((f) => {
							return f.lookup_object_name !== lookupObject;
						});
						setLookupInfoState(lookupobj);
						tempChangeLog.lookupInfoState = lookupobj;
						let templinkInfoObject = linkInfoObject;
						delete templinkInfoObject[OBJECT_TABLEID_MAP[getStringParam(lookupObject)]];
						setLinkInfo(templinkInfoObject);
						setLinkInfoObject(templinkInfoObject);

					}
				}
			}
			setchangeLog({ ...tempChangeLog, lookupInfoState: lookupInfoState });
		}
	};
	const setLookupFieldValue = (
		fieldName,
		lookupFieldName,
		lookupObject,
		input,
	) => {

		input = getStringParam(input);
		let tempFieldProps = fieldProps;

		if (fieldName === "company" && object === constants.CASES_OBJECT &&
			fieldProps[fieldName].value !== input) {
			if (tempFieldProps["first_name"]) {
				tempFieldProps["first_name"].value = "";
			}
			if (tempFieldProps["last_name"]) {
				tempFieldProps["last_name"].value = "";
			}
			if (tempFieldProps["from_email"]) {
				tempFieldProps["from_email"].value = "";
			}
			if (tempFieldProps["phone"]) {
				tempFieldProps["phone"].value = "";
			}
		}

		if (input === 'undefined') {
			if (tempFieldProps[fieldName] !== undefined && tempFieldProps[fieldName] !== null) {
				tempFieldProps[fieldName].value = "";
			}
		} else {
			tempFieldProps[fieldName].value = input;
		}
		let tempChangeLog = { ...changeLog };
		if (input.length > 2) {
			if (
				object === constants.CASES_OBJECT &&
				app.selectedModuleName === constants.MODULE_OPERATIONS
			) {
				let searchObject = getStringParam(lookupObject).toLowerCase();
				if (
					lookupFieldName === "company" &&
					searchObject === constants.ACCOUNTS_OBJECT
				) {
					setAutoCompleteValuesForFranchise(input);
				}
			} else if (
				fieldName === "first_name" &&
				object === constants.CASES_OBJECT
			) {
				let listValues = getArrayParam(
					tempFieldProps[fieldName].list_values
				);
				tempChangeLog.autoCompleteDataSource[fieldName] = listValues.filter((f) => f.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
			} else {
				lookupFieldName = getStringParam(lookupFieldName);
				let params = {};
				params.field_name = lookupFieldName;
				params.object = getStringParam(lookupObject).toLowerCase();
				params.search_text = input;
				if (
					(object === constants.APPOINTMENTS ||
						object === constants.TASKS) &&
					(lookupFieldName === "company" || lookupFieldName === "full_name")
				) {
					params.click_form = "appointmentForm";
				}

				const promise = Promise.resolve(getLookupAutocompleteInfo(params));
				if (isValidParam(promise)) {
					promise.then((response) => {
						let options = getArrayParam(response);
						sortArrayObjectByProperty(options, "name");
						if (options.length > 0) {
							tempChangeLog.autoCompleteDataSource[fieldName] = options;
						} else {
							if (
								object === constants.OPPORTUNITIES_OBJECT &&
								(lookupObject === constants.ACCOUNTS_OBJECT ||
									lookupObject === constants.CONTACTS_OBJECT)
							) {
								let objList = app.objectList;
								let objectArr = objList.filter((f) => {
									return f.name === lookupObject;
								});
								let itemLabel = objectArr.length > 0 ? objectArr[0].label : "";
								let optionName =
									"+ Add '" + input + "' " + "as new " + itemLabel;
								setLookupInput(input.trim());
								options.push({ id: -999999, name: optionName, value: input });
								tempChangeLog.autoCompleteDataSource[fieldName] = options;
							}
						}
						setAutoCompleteDataSource(tempChangeLog.autoCompleteDataSource);
						setchangeLog({ ...tempChangeLog });
						handleForceupdateMethod();
					});
				}
			}
		} else if (input === "") {
			if (
				linkInfoObject.hasOwnProperty(
					OBJECT_TABLEID_MAP[lookupObject]
				)
			) {
				delete linkInfoObject[
					OBJECT_TABLEID_MAP[getStringParam(lookupObject)]
				];
			}
			tempChangeLog.autoCompleteDataSource[fieldName] = [];
		} else {
			tempChangeLog.autoCompleteDataSource[fieldName] = [];
		}
		setFieldProps(tempFieldProps);
		setchangeLog(tempChangeLog);
		setAutoCompleteDataSource(tempChangeLog.autoCompleteDataSource);
	};

	const setAutoCompleteValuesForFranchise = (inputText) => {
		try {
			let params = {};
			params.fields = [
				"company",
				"first_name",
				"last_name",
				"email",
				"phone",
				"franchise_id",
			];
			params.query_name = "accounts";
			params.query_type = "query";
			params.search_field_name = "company";
			params.search_text = inputText;
			let searchPromise = Promise.resolve(
				getListData(constants.ACCOUNTS_OBJECT, params)
			);
			searchPromise.then((response) => {
				if (isValidParam(response)) {
					let records = getArrayParam(response.records);
					let options = records.map((m) => {
						return { ...m, name: m.company };
					});
					sortArrayObjectByProperty(options, "name");
					let tempChangeLog = { ...changeLog };
					tempChangeLog.autoCompleteDataSource["company"] = options;
					setAutoCompleteDataSource(tempChangeLog.autoCompleteDataSource);
					setchangeLog(tempChangeLog);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> setAutoCompleteValuesForFranchise()':" + error
			);
		}
	};

	const handleFlatpkrDateChange = (date, fieldName) => {

		try {
			if (isValidParam(date)) {
				let selectedDate = new Date(date);
				let dateValue = new Date(
					selectedDate.getFullYear(),
					selectedDate.getMonth(),
					selectedDate.getDate(),
					selectedDate.getHours(),
					selectedDate.getMinutes()
				);
				const momentDate = moment(dateValue);
				if (moment !== null && moment !== "") {
					if (
						fieldProps[fieldName].fieldType ===
						constants.FIELD_TYPE_DATE
					) {
						let temp = fieldProps;
						temp[fieldName].value = momentDate.format(
							dateFormat[app.me.date_format]
						);
						setFieldProps(temp);
					} else if (
						fieldProps[fieldName].fieldType ===
						constants.FIELD_TYPE_DATE_TIME
					) {
						let temp = fieldProps;
						temp[fieldName].value = momentDate.format(
							dateTimeFormat[app.me.date_format]
						);
						setFieldProps(temp);

					}
					let tempfieldProps = fieldProps;
					tempfieldProps[fieldName].helperText = "";
					setFieldProps(tempfieldProps);
					if (object === constants.APPOINTMENTS) {
						if (fieldName === "start_date") {


							let tempfieldProps = fieldProps;
							tempfieldProps["end_date"].value = momentDate
								.add(constants.CALENDER_DEFAULT_INTERVAL, "m")
								.format(dateTimeFormat[app.me.date_format]);
							setFieldProps(tempfieldProps);
						}
					} else if (
						object === constants.TIME_OBJECT &&
						(fieldName === "start_time" || fieldName === "end_time")
					) {
						if (fieldProps.start_time && fieldProps.end_time) {
							let seconds = moment(fieldProps["end_time"].value).diff(
								fieldProps["start_time"].value,
								"seconds"
							);
							if (seconds > 0) {
								let minutes = Math.floor(seconds / 60);
								let hours = Math.floor(minutes / 60);
								minutes = minutes - hours * 60;
								seconds = seconds - hours * 60 * 60 - minutes * 60;
								record["actual_work_time"] =
									hours + "Hr " + minutes + "Min " + seconds + "Sec";
								if (fieldProps["actual_work_time"]) {
									// fieldProps['actual_work_time'].value = hours + ":" + minutes + ":" + seconds;
									let tempfieldProps = fieldProps;
									tempfieldProps["actual_work_time"].value =
										hours + "Hr " + minutes + "Min " + seconds + "Sec";
									setFieldProps(tempfieldProps);
								}
							}
						}
					}
				} else {
					let tempfieldProps = fieldProps;
					tempfieldProps[fieldName].value = "";
					setFieldProps(tempfieldProps);
				}
			} else {
				let tempfieldProps = fieldProps;
				tempfieldProps[fieldName].value = "";
				setFieldProps(tempfieldProps);
			}
			let tempfieldProps = fieldProps;
			tempfieldProps[fieldName].helperText = "";
			setFieldProps(tempfieldProps);
			handleForceupdateMethod();
			if (callFrom === "queueView" && object == constants.APPOINTMENTS) {
				isEdited(true);
				setIsSavedDisabled(false);
				let tempChangeLog = changeLog;
				tempChangeLog.isSavedDisabled = false;
				setchangeLog({ ...tempChangeLog });
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> handleFlatpkrDateChange()':" + error
			);
		}
	};

	const unescapeHTML = (html) => {
		var escapeEl = document.createElement("textarea");
		escapeEl.innerHTML = html;
		return escapeEl.textContent;
	};

	const addToThisList = (fieldName) => {

		let value = fieldProps[fieldName].value;
		value = getStringParam(value).trim();
		if (value !== "") {
			if (fieldName === "source") {
				let promise = SFFormActions.checkDuplicateSourceName(value);
				if (isValidParam(promise)) {
					promise.then((response) => {
						saveNewListValue(
							fieldName,
							value,
							getBooleanParam(response)
						);
					});
				}
			} else {
				let field = fields.filter((f) => {
					return f.name === fieldName;
				});
				if (field.length > 0) {
					let listValues = getArrayParam(field[0].list_values);
					let tempValues = listValues.filter((f) => {
						return f.value.toLowerCase() === value.toLowerCase();
					});
					saveNewListValue(
						fieldName,
						value,
						tempValues.length > 0 ? true : false
					);
				}
			}
		} else {
			let tempFields = fields.filter((f) => {
				return f.name === fieldName;
			});
			let fieldValue =
				getIntParam(recordId) > 0 &&
					isValidParam(record)
					? getStringParam(record[fieldName])
					: "";
			fieldValue =
				fieldValue === "" && tempFields.length > 0
					? getStringParam(tempFields[0].default_value)
					: fieldValue;
			let temp = fieldProps;
			temp[fieldName].isAddToThisList = false;
			temp[fieldName].value = fieldValue;
			setFieldProps({ ...temp });
			handleForceupdateMethod();
		}

	};

	const saveNewListValue = (fieldName, value, isExist) => {
		try {
			if (getBooleanParam(isExist)) {
				dispatch(showCustomSnackBar(
					"Value already exists in List",
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				));
			} else if (
				fieldName === "source" ||
				fieldName === "note_type" ||
				fieldName === "event_type"
			) {
				let promise = SFFormActions.saveListFieldName(fieldName, value);
				if (isValidParam(promise)) {
					promise.then((response) => {
						if (isValidParam(response.data)) {
							let fieldObject = [];
							if (fieldName === "source") {
								fieldObject.push(constants.CONTACTS_OBJECT);
								fieldObject.push(constants.ACCOUNTS_OBJECT);
							} else if (fieldName === "note_type") {
								fieldObject.push(constants.NOTE_OBJECT);
							} else if (fieldName === "event_type") {
								fieldObject.push(constants.APPOINTMENTS);
							}
							if (fieldObject.length > 0 && fieldName !== "event_type") {
								fieldObject.forEach((type) => {
									SFFormActions.addSourceOnRedux(type, fieldName, value);
								});
							}
							let temp = fieldProps;
							temp[fieldName].isAddToThisList = false;
							temp[fieldName].value = value;
							setFieldProps(temp);
							let typeValue = [];
							fields.forEach((field) => {
								if (field.name === fieldName) {
									let checkData = [];
									checkData = field.list_values.filter((f) => {
										return f.value.toLowerCase() === value.toLowerCase();
									});
									if (checkData.length === 0) {
										field.list_values.push({ id: value, value: value });
									}
									if (fieldName === "note_type") {
										sortArrayObjectByProperty(field.list_values, "value");
									} else if (fieldName === "event_type") {
										sortArrayObjectByProperty(field.list_values, "value");
										typeValue = field.list_values;
									}
									setListValueMenuItems(field);
								}
							});
							setFields(fields);

							if (
								object === constants.APPOINTMENTS &&
								fieldName === "event_type"
							) {
								let calendarInfo = calendar.calendarInfo;
								let param = {};
								param.object = constants.APPOINTMENTS;
								param.name = "event_type";
								param.list_type = "external";

								let promise = Promise.resolve(
									HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param)
								);

								promise.then((response) => {
									if (isValidParam(response)) {
										let typeValue = getArrayParam(response.record);
										typeValue.sort(function (a, b) {
											if (a.value.toLowerCase() > b.value.toLowerCase()) {
												return 1;
											}
											if (a.value.toLowerCase() < b.value.toLowerCase()) {
												return -1;
											}
											return 0;
										});
										let appTypes = typeValue.map((f, i) => {
											let typeObj = {};
											typeObj.value = f.value;
											typeObj.label = f.value;
											typeObj.color = f.color;
											return typeObj;
										});

										sortArrayObjectByProperty(appTypes, "label");

										calendar.calendarInfo.appTypes = appTypes;
										dispatch(getCalendarRefresh(true));
										dispatch(getJobCalendarRefresh(true));
									}
								});
							}

						}
					});
				}
			} else {
				let field = fields.filter((f) => {
					return f.name === fieldName;
				});
				if (field.length > 0) {
					let values = getArrayParam(field[0].list_values);
					let listValues = [];
					values.forEach((field) => {
						if (field.value !== "") {
							listValues.push(field.value);
						}
					});
					listValues.push(value);
					let promise = SFFormActions.saveListValues(
						object,
						field[0].id,
						listValues
					);
					if (isValidParam(promise)) {
						promise.then((response) => {
							let temp = fieldProps;
							temp[fieldName].isAddToThisList = false;
							temp[fieldName].value = value;
							setFieldProps(temp);
							fields.forEach((field) => {
								if (field.name === fieldName) {
									field.list_values.push({ id: value, value: value });
									setListValueMenuItems(field);
								}
							});
							setFields(fields);

						});
					}
				}
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> saveNewListValue()':" + error);
		}
	};

	const getDropDownComponetForTextField = (field, fieldLabel) => {
		try {
			let temp = fieldProps;
			fieldLabel = getStringParam(fieldLabel);
			if (isValidParam(field)) {
				let searchIconPaddingRight = "-15px";
				let menuItems = getArrayParam(listValueMenuItems[field.name]);
				if (object !== constants.PAYMENTS_OBJECT) {
					let tempVal = fieldProps[field.name].value === "" ? field.label : fieldProps[field.name].value;
					menuItems = menuItems.filter((f) => f.props.fullName === tempVal);
				}

				let defaultValue = (fieldProps[field.name].value === "") ?

					(field.default_value === "" && field.list_values.length > 0) ? field.list_values[0].value : "" :
					fieldProps[field.name].value;
				defaultValue = (object === constants.PAYMENTS_OBJECT && fieldProps[field.name].value === "") ? menuItems.length > 0 ? menuItems[0].props.id : "" : defaultValue;

				temp[field.name].value = defaultValue;
				let _loaded = loaded;
				if (!_loaded) {
					setLoaded(true);
					setFieldProps({ ...temp });
				}

				if (object === constants.PAYMENTS_OBJECT) {
					searchIconPaddingRight = "-13px";
				}
				let className = "sf-fields-" + field.name;
				if (
					field.is_lookup_field &&
					object !== constants.PAYMENTS_OBJECT
				) {
					className = "sf-fields-lookupselect";
				}

				return (
					<div
						style={{
							// display: 'inline-block'
							border: "0px solid green",
							marginBottom: "0px",
							marginTop: "8px",
						}}
					>
						<div style={{ display: "flex", alignItems: 'center' }}>
							<div style={{ ...styles.sf_12 }}>
								<FormControl
									variant="outlined"
									style={{
										minWidth: "100%",
										height: "45px",
										cursor: "pointer",
										// border: '0px solid red',
										// marginTop: '7px',
										// marginBottom: '7px',
										border: "0px solid red",
									}}
								>
									<InputLabel
										style={{
											marginTop: "-5px",
										}}
										id="select-label"
									>
										{fieldLabel}
									</InputLabel>
									<Select
										labelId="select-label"
										label={fieldLabel}
										style={{
											height: "40px",
										}}
										id={field.name}
										key={field.id + field.name}
										className={className}
										onChange={(e) =>
											setFieldValue(field.name, e, e.target.value)
										}
										value={temp[field.name].value}
									>
										{listValueMenuItems[field.name]}
									</Select>
								</FormControl>
							</div>
							{field.is_lookup_field &&
								object !== constants.PAYMENTS_OBJECT && (
									<div style={{ marginLeft: listValueMenuItems[field.name].length > 0 ? "-45px" : '-39px', zIndex: 1 }}>
										<span
											style={{
												display: "inline-block",
												border: "0px solid blue",
												marginTop: "-6px",
												marginRight: searchIconPaddingRight,
											}}
											title={getLocalizedStrings().label.COMMON.SEARCH}
											className="search-field-icon fa fa-search sf-sffrom-search "
											onClick={() => openLookup(
												field.name,
												field.is_lookup_field,
												field.lookup_field_name,
												field.lookup_object,
												field?.label
											)}
										/>
									</div>
								)}
						</div>
					</div>
				);
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> getDropDownComponetForTextField()':" + error
			);
		}
	};

	const isAutoFocus = (fieldName) => {
		try {
			fieldName = getStringParam(fieldName);
			return changeLog.autoFocusFieldName === fieldName;
		} catch (error) {
			console.error("Error in 'sfForm.js -> isAutoFocus()':" + error);
		}
	};

	const getFieldsForRender = () => {
		let fieldsVal = null;
		try {
			fieldsVal = getArrayParam(fields);
			if (fields.length > 0) {
				if (object === constants.TASKS) {
					fieldsVal = fieldsVal.filter((f) => (f.name !== "location"));
				}
				if (isPreview) {
					fieldsVal = fieldsVal.filter((f) => (f.is_hidden_field === false));
					fieldsVal = fields.filter((f) => (f.t_name !== "main_contact_id"));
				}
				fieldsVal = fieldsVal.filter((item, i, ar) => ar.indexOf(item) === i);
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> getFieldsForRender()':" + error);
		}

		return fieldsVal;

	};

	const openAssignmentRuleDrawer = () => {
		let tab = {
			item: object,
			label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
			object: "assignmentrules",
			imgName: "fas fa-cog",
			url: "/setting/module",
			type: TYPE_LIST_VIEW,
		};
		tab.info = { filter: { id: 0, name: "Assignment Rule", type: "" } };
		tab.info.selectedItem = {
			id: 0,
			name: "assignmentrules",
			label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
			type: "menu-item",
			object: "assignmentrules",
		};
		addTab(tab, true);

	};
	const handleTaskStartDateChanges = (value) => {
		try {

		} catch (error) {
			console.error(
				"error in sfForm.js ->'handleTaskStartDateChanges()'" + error
			);
		}
	};



	const getProjectDepTasksListFn = (parentObject) => {
		let tasksListValue = [];
		try {
			let params = {
				record_id: parentDetails.parent_record_id,
				task_id: recordId,
			};
			const promise = Promise.resolve(getProjectDepTasksList(parentObject, params));
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response) && getArrayParam(response).length > 0) {
						let tasks = getArrayParam(response);
						tasks.forEach((task) => {
							let taskObj = {};
							taskObj.value = task.id;
							taskObj.label = task.name;
							taskObj.title = task.name;
							tasksListValue.push(taskObj);
						});
						setTasksList(tasksListValue);
						handleForceupdateMethod();
					}
				});
			}
		} catch (error) {
			console.error("error in sfForm.js ->'getProjectDepTasksList()'" + error);
		}
	};

	const projectDepTaskFields = (taskStartDateFieldChanged) => {
		let depStartDate = (taskStartDateFieldChanged == null || taskStartDateFieldChanged === undefined || taskStartDateFieldChanged === "") ? changeLog.taskStartDateFieldName : taskStartDateFieldChanged;
		let projectTaskField = [
			{ id: 1, name: "days_after", type: "integer" },
			{ id: 2, name: "task_start_date", type: constants.FIELD_TYPE_DATE_TIME },
			{ id: 3, name: "t_dep_task_id", type: "text" },
		];
		try {
			if (isValidParam(depStartDate)) {
				projectTaskField = projectTaskField.filter((f) => {
					let temp;
					if (depStartDate === "Task Dependent") {
						return f.name === "days_after" || f.name === "t_dep_task_id";
					} else if (depStartDate === "Date") {
						return f.name === "task_start_date";
					}
					return temp;
				});
				if (isValidParam(projectTaskField) && projectTaskField.length > 0) {
					projectTaskField.forEach((field) => {
						let taskObj = {};
						if (
							field.name === "days_after" ||
							field.name === "task_start_date" ||
							field.name === "t_dep_task_id"
						) {
							taskObj.id = field.id;
							taskObj.label = getMultiLingualLabel(field.name);
							taskObj.name = field.name;
							taskObj.fieldType = field.type;
							if (field.name === "days_after") {
								taskObj.value = "";
							} else if (field.name === "task_start_date") {
								let currentTime = moment().tz(
									timeZone[app.me.timezone]
								);
								let defaultDate = currentTime.format(
									dateTimeFormat[app.me.date_format]
								);
								taskObj.value = defaultDate;
							}
						}
						if (isValidParam(taskObj) && Object.keys(taskObj).length > 0) {
							let tempFieldProps = fieldProps;
							tempFieldProps[field.name] = taskObj;
							taskObj.field_type = field.type;
							setFieldProps(tempFieldProps);
							if (
								field.name === "days_after" ||
								field.name === "t_dep_task_id"
							) {
								taskObj.is_hidden_field = false;
								taskObj.is_required_field = true;
							}
							fields.push(taskObj);
							setFields(fields);

						}
					});
				}
			}
		} catch (error) {
			console.error("error in sfForm.js ->'projectDepTaskFields()'" + error);
		}
	};

	const createProjectDepTaskFields = (fieldName) => {
		let element = [];
		let tempFields = fields;
		let tempFieldProps = fieldProps;
		try {
			if (isValidParam(fieldName)) {
				if (fieldName === "Task Dependent") {
					if (!isValidParam(tempFieldProps.days_after)) {
						projectDepTaskFields();
					}
					if (isValidParam(tempFieldProps.task_start_date)) {
						delete tempFieldProps.task_start_date;
						tempFields = tempFields.filter(
							(f) => f.name !== "task_start_date"
						);
					}
					let tempDafFormFieldContainerStyle = {
						...styles.sfFormFieldContainer,
					};
					let selectTaskStyle = {};
					if (
						isValidParam(tempFieldProps.days_after) &&
						isValidParam(tempFieldProps["days_after"].helperText) &&
						tempFieldProps["days_after"].helperText !== ""
					) {
						tempDafFormFieldContainerStyle = {
							...tempDafFormFieldContainerStyle,
							border: "1px solid #f75723",
						};
					}
					if (
						isValidParam(tempFieldProps.t_dep_task_id) &&
						isValidParam(tempFieldProps["t_dep_task_id"].helperText) &&
						tempFieldProps["t_dep_task_id"].helperText !== ""
					) {
						selectTaskStyle = { border: "1px solid #f75723" };
					}

					element.push(
						<div
							className="col-sm-12 col-two"
							id={"days-after-field"}
							key={"days-after-field"}
						>
							<FormControl
								style={{ width: "108%", marginLeft: '-12px' }}
								className="test"
								noValidate
								autoComplete="off"
							>
								<TextField
									variant="outlined"
									key={"days-after-field"}
									label={
										getMultiLingualLabel("days_after") +
										getLocalizedStrings().label.COMMON.REQUIRED
									}
									fullWidth={true}
									name={getMultiLingualLabel("days_after")}
									value={tempFieldProps["days_after"].value}
									title={tempFieldProps["days_after"].value}
									onChange={(e) =>
										setFieldValue("days_after", e, e.target.value)
									}
									// style={tempDafFormFieldContainerStyle}
									className={"sf-fields-bg"}
									autoComplete="new-password"
									margin="dense"
									size='small'
								/>
							</FormControl>
						</div>
					);
					element.push(
						<div
							className="col-sm-12 col-two"
							id={"task-field"}
							key={"task-field"}
						>
							<div style={{ marginBottom: "12px" }}>
								<FormControl
									style={{ width: "100%", height: "40px" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									{tasksList && (
										<Autocomplete
											value={tempFieldProps["t_dep_task_id"].value}
											id={"multi-select"}
											key={"multi-select"}
											options={tasksList}
											onChange={(event, newValue) =>
												setFieldValue("t_dep_task_id", event, newValue)
											}
											getOptionLabel={(option) => option.label}
											style={{ ...selectTaskStyle, width: 327, marginTop: 4 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label={
														getLocalizedStrings().label.APPOINTMENTS_TASKS
															.DEP_TASK_NAME +
														getLocalizedStrings().label.COMMON.REQUIRED
													}
													variant="outlined"
													disableClearable={false}
													size='small'
													className={"sf-fields-bg"}
												/>
											)}
										/>
									)}
								</FormControl>
							</div>
						</div>
					);
				} else if (fieldName === "Date") {
					let currentTime = moment().tz(timeZone[app.me.timezone]);
					let defaultDate = currentTime.format(
						dateTimeFormat[app.me.date_format]
					);
					let dateValue = null;
					if (!isValidParam(tempFieldProps.task_start_date)) {
						projectDepTaskFields();
					}
					if (
						isValidParam(tempFieldProps["task_start_date"].value) &&
						tempFieldProps["task_start_date"].value !== ""
					) {
						let momentObj = moment(
							tempFieldProps["task_start_date"].value
						);
						dateValue = momentObj.format(
							dateTimeFormat[app.me.date_format]
						);
					}
					if (isValidParam(tempFieldProps.days_after)) {
						delete tempFieldProps.days_after;
						tempFields = tempFields.filter(
							(f) => f.name !== "days_after" && f.name !== "t_dep_task_id"
						);
						setFields(tempFields);

					}
					let HHFormat = new RegExp("HH");
					let datePickerOptions = {
						enableTime: true,
						noCalendar: false,
						dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
						minuteIncrement: 1,
						static: true,
						time_24hr: HHFormat.test(app.me.date_format)
							? true
							: false,
					};
					element.push(
						<div
							className="col-sm-12 col-two"
							id={"task-date-field"}
							key={"task-date-field"}
						>
							<div className={"sf-form-field label-top"} id="dateholder">
								<PopOver
									id={"task_start_date"}
									key={"task_start_date"}
									name={"task_start_date"}
									btnType={
										tempFieldProps["task_start_date"].field_type ===
											constants.FIELD_TYPE_DATE_TIME
											? "dateTime"
											: "date"
									}
									buttonStyle={{ fontSize: "16px" }}
									containerStyle={{ lineHeight: 1, marginTop: '15px', marginLeft: '-15px', width: '109%' }}
									buttonLabel={getLocalizedStrings().label.CALENDAR.DATE}
									onChange={() => handleFlatpkrDateChange()}
									value={
										tempFieldProps["task_start_date"].value === ""
											? defaultDate
											: dateValue
									}
									innerLabelStyle={{ padding: "8 5 2 5", fontSize: "15px" }}
									height={40}
									options={datePickerOptions}
								/>
							</div>
						</div>
					);
				}
			}
		} catch (error) {
			console.error(
				"error in sfForm.js ->'createProjectDepTaskFields()'" + error
			);
		}

		return element;
	};

	const projectTasksFilter = (fieldsVal) => {
		try {
			if (isValidParam(changeLog.taskStartDateFieldName)) {
				fieldsVal = fieldsVal.filter((f) =>
					(f.name !== "task_start_date" && f.name !== "days_after" && f.name !== "t_dep_task_id")
				);
			}
		} catch (error) {
			console.error("error in sfForm.js ->'projectTasksFilter()'" + error);
		}
		return fieldsVal;
	};

	const focusElementByName = (fieldName) => {
		let inputElement = null;
		let fieldId = null;
		try {
			fieldName = getStringParam(fieldName);
			fieldId = "field-ref-id-" + fieldName;
			inputElement = document.getElementById(fieldId);
			if (
				fieldName !== "" &&
				isValidParam(inputElement) &&
				inputElement !== null
			) {
				inputElement.focus();
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> focusElementByName()':" + error);
		}
	};

	const getOpportunityContactName = (lookupFieldName, recordValue) => {
		let contactName = "";
		try {
			if (isValidParam(recordValue) && getStringParam(lookupFieldName).length > 0) {
				let company =
					getStringParam(recordValue["company"]).length > 0
						? getStringParam(recordValue["company"])
						: constants.EMPTY;

				contactName = recordValue[lookupFieldName];
				//contactName = (getStringParam(contactName).length > 0) ? contactName + " " + recordValue['last_name'] : recordValue['last_name'];
				if (
					object !== constants.APPOINTMENTS &&
					object !== constants.TASKS
				) {
					contactName = contactName + " " + "(" + company + ")";
				}

				changeLog.lookupInfoState.forEach((f, i) => {
					if (f.lookup_object_name === constants.CONTACTS_OBJECT) {
						//f.last_name = getStringParam(recordValue['last_name']);
						f.company = getStringParam(recordValue["company"]);
					}
				});
			}
		} catch (error) {
			console.log(
				"Error in 'sfForm.js -> getOpportunityContactName()':" + error
			);
		}
		return contactName;
	};

	const rearrangeFields = (fieldsVal) => {
		try {
			let i = 0;
			if (isValidParam(fieldsVal) && getArrayParam(fieldsVal).length > 0) {
				if (
					fieldsVal.indexOf("workflow_name") === i &&
					fieldsVal.indexOf("t_status") === i + 1
				) {
					let workflowName = fieldsVal.splice(0, 1)[0];
					let status = fieldsVal.splice(0, 1)[0];
					fieldsVal.splice(2, 0, workflowName);
					fieldsVal.splice(3, 0, status);
				}
			}
		} catch (error) {
			console.log("Error in 'sfForm.js -> rearrangeFields()':" + error);
		}
		return fieldsVal;
	};

	const setOppotunityContactName = (fieldPropsValue) => {
		let value = "";
		try {
			fieldPropsValue = isValidParam(fieldPropsValue)
				? fieldPropsValue
				: fieldProps;
			if (
				isValidParam(fieldPropsValue) &&
				isValidParam(record) &&
				(record.hasOwnProperty("first_name") ||
					record.hasOwnProperty("last_name"))
			) {
				value =
					getStringParam(record.first_name).length > 0
						? getStringParam(record.first_name)
						: "";
				if (value.length > 0) {
					if (getStringParam(record.last_name).length > 0) {
						value = value + " " + getStringParam(record.last_name);
					}
				} else {
					value =
						getStringParam(record.last_name).length > 0
							? getStringParam(record.last_name)
							: "";
				}
				if (value === "") {
					//value = "(" + constants.EMPTY + ")";
				}
				fieldPropsValue["contact_name"].value = value;
			}
			setFieldProps(fieldPropsValue);
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> setOppotunityContactName()':" + error
			);
		}
	};
	const attachedFiles = (files) => {
		setAttchedTaskFiles(files);
	}
	const checkDuplicateProduct = (objectName, params, recordid) => {
		try {
			let productName = isValidParam(fieldProps["product_name"])
				? getStringParam(fieldProps["product_name"].value)
					.trim()
					.toLowerCase()
				: "";
			let paramObject = {};
			paramObject.product_name = getStringParam(productName);
			if (appContainer.drawerProps.actionType === constants.COPY) {
				paramObject.record_id =
					getIntParam(params.id) === 0 ? getIntParam(params.id) : 0;
			} else {
				paramObject.record_id = getIntParam(recordid);
			}
			let promise = checkDuplicateProductName(paramObject);
			promise.then((response) => {
				if (isValidParam(response)) {
					let isDuplicateProduct = getBooleanParam(response.is_duplicate);
					if (isDuplicateProduct) {
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.PRODUCTS.DUPLICATE_PRODUCT,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						));
						if (object === constants.PRODUCTS_OBJECT) {
							setIsSavedDisabled(false);
							let tempChangeLog = { ...changeLog };
							tempChangeLog.isSavedDisabled = false;
							setchangeLog(tempChangeLog);
						}
						return false;
					} else {
						processSaveData(objectName, params, recordid);
					}
				}
			});
		} catch (error) {
			console.error("Error in 'sfForm.js -> checkDuplicateProduct()':" + error);
		}
	};

	const getCustomLookupField = (f) => {
		let field = {};
		try {
			field = { ...f };

			field.col_span = 1;
			field.default_value = "";
			field.is_lookup_field = true;
			field.field_type = constants.FIELD_TYPE_TEXT;
		} catch (error) {
			console.log("Error in 'sfForm.js -> checkDuplicateProduct()':" + error);
		}
		return field;
	};

	const removeHiddenFields = (fieldsVal) => {
		fieldsVal = getArrayParam(fieldsVal);
		if (fieldsVal.length > 0) {
			fieldsVal = fieldsVal.filter((f) => {
				let temp;
				if (
					getStringParam(f.name) === "_1" ||
					getStringParam(f.name) === "_2"
				) {
					temp = fieldsVal;
				}
				return temp;
			});
		}
	};

	const addTemporaryFields = (fieldsVal, openModeValue) => {
		try {
			openModeValue = getStringParam(openModeValue);
			let f1 = {
				id: 0,
				name: "company",
				label: "Account Name",
				lookup_object: constants.ACCOUNTS_OBJECT,
				lookup_field_name: "company",
				is_required_field: false,
				value: null,
			};
			let f2 = {
				id: 0,
				name: "contact_name",
				label: "Contact Name",
				lookup_object: constants.CONTACTS_OBJECT,
				lookup_field_name: "full_name",
				is_required_field: true,
				value: null,
			};
			let fldArr = [];
			fldArr.push(f1);
			fldArr.push(f2);

			fldArr.forEach((f, i) => {
				f = getCustomLookupField(f);
				if (isValidParam(f)) {
					if (openModeValue === constants.SF_FORM_OPEN_MODE_EXPANDED) {
						fieldsVal.splice(1, 0, f);
					} else {
						fieldsVal.unshift(f);
					}
				}
			});
			return fieldsVal;
		} catch (error) {
			console.log("Error in 'sfForm.js -> addTemporaryFields()':" + error);
		}
	};

	const updateContactLinkInfoObject = () => {
		let tableId = 0;
		let recordid = 0;
		let temp = linkInfoObject;
		try {
			let linkRecords = getStringParam(fieldProps["link_info"].value)
				.trim()
				.split(",");
			linkRecords = getArrayParam(linkRecords);
			linkRecords = linkRecords.filter((f) => {
				let temp;
				if (f.indexOf("9_") !== -1) {
					temp = f;
				}
				return temp;
			});
			if (getArrayParam(linkRecords).length > 0) {
				let tableIdRecord = getArrayParam(linkRecords[0].split("_"));
				tableId = tableIdRecord[0];
				recordid = tableIdRecord[1];
				temp[tableId] = recordid;
				return temp;
			}
		} catch (error) {
			console.log(
				"Error in 'sfForm.js -> updateContactLinkInfoObject()':" + error
			);
		}
	};

	const updateDnD = (dataValue) => {
		try {
			let tempFieldProps = fieldProps;
			if (dataValue !== null) {
				if (dataValue.type === constants.APPOINTMENTS) {
					if (tempFieldProps.hasOwnProperty("start_date")) {
						tempFieldProps["start_date"].value = dataValue.start_date;
					}
					if (tempFieldProps.hasOwnProperty("end_date")) {
						tempFieldProps["end_date"].value = dataValue.end_date;
					}
				} else {
					if (tempFieldProps.hasOwnProperty("t_dueby")) {
						tempFieldProps["t_dueby"].value = dataValue.t_dueby;
					}
				}
				setFieldProps(tempFieldProps);
			}
		} catch (error) {
			console.log("Error in 'sfForm.js -> updateDnD()':" + error);
		}
	};

	const updateRefresh = (isRefresh) => {
		setIsAppTaskCalendarRefresh(isRefresh);
	};

	const setChildAccountLookupInfo = () => {
		try {
			let parentObject = getStringParam(parentDetails.parent_object).trim();
			let parentRecordId = getIntParam(parentDetails.parent_record_id);
			let childObject = constants.ACCOUNTS_OBJECT;
			let fieldName = "company";
			let value = getStringParam(fieldProps[fieldName].value);
			let field = fields.find((f) => {
				return f.lookup_object === childObject;
			});
			if (isValidParam(field)) {
				let params = {};
				params.parent_object = parentObject;
				params.parent_record_id = parentRecordId;
				params.lookup_table_id = getIntParam(OBJECT_TABLEID_MAP[childObject]);
				params.lookup_field_id = getIntParam(field.t_tablefldid);
				params.field_value = value;
				let promise = Promise.resolve(getLookupRecordInfo(params));

				if (isValidParam(promise)) {
					promise.then((response) => {
						let lookupInfo = response;
						let lookupTableId = getStringParam(lookupInfo.table_id).trim();
						let lookupRecordId = getIntParam(lookupInfo.record_id);
						if (lookupTableId > 0 && lookupRecordId > 0) {
							let temp = linkInfoObject;
							temp[lookupTableId] = lookupRecordId;
							setLinkInfo(temp);
							setLinkInfoObject(temp);

						}
					});
				}
			}
		} catch (error) {
			console.log(
				"Error in 'sfForm.js -> setChildAccountLookupInfo()':" + error
			);
		}
	};

	const generateLookupInfoList = () => {
		let tempLookupArr = [];
		try {
			let linkMap = linkInfoObject;
			Object.keys(linkMap).forEach((key) => {
				let lookupObj = {};
				lookupObj.lookup_object_name = TABLEID_OBJECT_MAP[getIntParam(key)];
				lookupObj.record_id = getIntParam(linkMap[getIntParam(key)]);
				tempLookupArr.push(lookupObj);
			});
		} catch (error) {
			console.log("Error in 'sfForm.js -> generateLookupInfoList()':" + error);
		}
		return tempLookupArr;
	};

	const removeFromQueue = (recordid, deleteType) => {
		let nextRecordIndex = 0;
		let newIdx = 0;
		try {
			if (recordid > 0) {
				let dataValue = getObjectParam(queueList.data);
				let queueListValue = getArrayParam(dataValue.records);
				let queueObj = queueListValue.filter((obj) => {
					return obj.id === parseInt(recordid);
				});
				let index;
				if (deleteType !== undefined && deleteType == "all" && object == 'appointments') {
					let deleteRecName = queueObj[0].title
					let _indx = queueListValue.find((f) => {
						if (f.title !== deleteRecName) {
							return f.idx
						}

					})
					index = isValidParam(_indx) ? _indx.idx - 1 : -1;
					let objectName = getStringParam(object);
					if (queueListValue.length > 1) {
						if (index >= 0) {
							if (index + 1 < queueListValue.length) {
								nextRecordIndex = index + 1;
								newIdx = 0;
								// open next records for detailview
							} else {
								nextRecordIndex = index - 1;
								newIdx = nextRecordIndex;
								// get the last record  for detailview
							}

							let prevObj = queueListValue[nextRecordIndex];
							let preId =
								isValidParam(prevObj) && prevObj !== null
									? getIntParam(prevObj.id)
									: 0;
							if (preId > 0) {
								let total_count = getIntParam(dataValue.total_count);
								let removeCount = _indx.idx - queueObj[0].idx;
								total_count = total_count - removeCount;
								removeRecurringRecordFromRedux(objectName, queueObj[0].title, total_count);

								let titleLabel = getStringParam(prevObj.title);
								queueList.data["openedRecordInQueueId"] = preId;
								queueList.data["openedRecordInQueueIndex"] = newIdx;
								queueList.data["openedRecordInQueuelabel"] = titleLabel;
								if (objectName === constants.TASKS) {

									let linkInfoValue = prevObj.linkInfo;
									if (linkInfoValue !== null && linkInfoValue !== undefined) {
										let linkInfoArr = linkInfoValue.split(",");
										let selectedObjId = getIntParam(
											OBJECT_TABLEID_MAP[
											queueList.data.selecteddrpdwnObj
											]
										);
										linkInfoArr.forEach((item, i) => {
											let ObjRecordArr = item.split("_");
											let tableId = getIntParam(ObjRecordArr[0].trim());
											if (selectedObjId === tableId) {
												preId = getIntParam(ObjRecordArr[1]);
												objectName = queueList.data.selecteddrpdwnObj;
											}
										});
									}
								}
								openDetailviewQueueTab(
									objectName,
									titleLabel,
									preId,
									newIdx,
									total_count
								);
								dispatch(refreshDetailViewListViewData(true, recordId));
								let urlParams = {
									object: objectName,
									recordId: preId
								}

								dispatch(getDetailviewData(urlParams));
								refreshDeatilsView();

							}
						}
					}
				}

				else if (deleteType !== undefined && deleteType == "following" && object == 'appointments') {
					index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
					let _objectName = getStringParam(object);
					let followingRecord = queueListValue.filter((obj) => {
						return obj.idx === queueObj[0].idx + 1;
					});
					let followingRecid = followingRecord[0].id
					if (queueListValue.length > 1) {
						if (index >= 0) {
							if (index + 1 < queueListValue.length) {
								nextRecordIndex = followingRecord[0].idx + 1;
								newIdx = queueObj[0].idx;
								// open next records for detailview
							} else {
								nextRecordIndex = index - 1;
								newIdx = nextRecordIndex;
								// get the last record  for detailview
							}

							let _prevObj = queueListValue[nextRecordIndex];
							let _preId =
								isValidParam(_prevObj) && _prevObj !== null
									? getIntParam(_prevObj.id)
									: 0;
							if (_preId > 0) {
								let _total_count = getIntParam(dataValue.total_count);
								_total_count = _total_count - 2;
								removeFollowingRecRecordFromRedux(_objectName, recordid, followingRecid, _total_count);

								let _titleLabel = getStringParam(_prevObj.title);
								queueList.data["openedRecordInQueueId"] = _preId;
								queueList.data["openedRecordInQueueIndex"] = newIdx;
								queueList.data["openedRecordInQueuelabel"] = _titleLabel;
								if (_objectName === constants.TASKS) {

									let linkInfoValue = _prevObj.linkInfo;
									if (linkInfoValue !== null && linkInfoValue !== undefined) {
										let linkInfoArr = linkInfoValue.split(",");
										let selectedObjId = getIntParam(
											OBJECT_TABLEID_MAP[
											queueList.data.selecteddrpdwnObj
											]
										);
										linkInfoArr.forEach((item, i) => {
											let ObjRecordArr = item.split("_");
											let tableId = getIntParam(ObjRecordArr[0].trim());
											if (selectedObjId === tableId) {
												_preId = getIntParam(ObjRecordArr[1]);
												_objectName = queueList.data.selecteddrpdwnObj;
											}
										});
									}
								}
								openDetailviewQueueTab(
									_objectName,
									_titleLabel,
									_preId,
									newIdx,
									_total_count
								);
								dispatch(refreshDetailViewListViewData(true, recordId));
								let urlParams = {
									object: _objectName,
									recordId: _preId
								}

								dispatch(getDetailviewData(urlParams));
								refreshDeatilsView();

							}
						}
					} else {
						let activeTab = tabManagerActiveTab();
						if (isValidParam(activeTab)) {
							closeTab(activeTab.item, activeTab.index);
						}
					}
				}
				else {
					index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
					let objectName = getStringParam(object);
					if (queueListValue.length > 1) {
						if (index >= 0) {
							if (index + 1 < queueListValue.length) {
								nextRecordIndex = index + 1;
								newIdx = index;
								// open next records for detailview
							} else {
								nextRecordIndex = index - 1;
								newIdx = nextRecordIndex;
								// get the last record  for detailview
							}

							let prevObj = queueListValue[nextRecordIndex];
							let preId =
								isValidParam(prevObj) && prevObj !== null
									? getIntParam(prevObj.id)
									: 0;
							if (preId > 0) {
								let total_count = getIntParam(dataValue.total_count);
								total_count = total_count - 1;
								removeRecordFromRedux(objectName, recordid, total_count);

								let titleLabel = getStringParam(prevObj.title);
								queueList.data["openedRecordInQueueId"] = preId;
								queueList.data["openedRecordInQueueIndex"] = newIdx;
								queueList.data["openedRecordInQueuelabel"] = titleLabel;
								if (objectName === constants.TASKS) {

									let linkInfoValue = prevObj.linkInfo;
									if (linkInfoValue !== null && linkInfoValue !== undefined) {
										let linkInfoArr = linkInfoValue.split(",");
										let selectedObjId = getIntParam(
											OBJECT_TABLEID_MAP[
											queueList.data.selecteddrpdwnObj
											]
										);
										linkInfoArr.forEach((item, i) => {
											let ObjRecordArr = item.split("_");
											let tableId = getIntParam(ObjRecordArr[0].trim());
											if (selectedObjId === tableId) {
												preId = getIntParam(ObjRecordArr[1]);
												objectName = queueList.data.selecteddrpdwnObj;
											}
										});
									}
								}
								openDetailviewQueueTab(
									objectName,
									titleLabel,
									preId,
									newIdx,
									total_count
								);
								dispatch(refreshDetailViewListViewData(true, recordId));
								let urlParams = {
									object: objectName,
									recordId: preId
								}

								dispatch(getDetailviewData(urlParams));
								refreshDeatilsView();

							}
						}
					} else {
						let activeTab = tabManagerActiveTab();
						if (isValidParam(activeTab)) {
							closeTab(activeTab.item, activeTab.index);
						}
					}
				}

			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> removeFromQueue()':" + error
			);
		}
	};
	const refreshDeatilsView = () => {
		dispatch(detailViewIsMounted(false));
		dispatch(isDetailViewSet(false));
		dispatch(refreshDetailViewData(true));
	};
	const dateDiffInDays = (date1, date2) => {
		// round to the nearest whole number
		return Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
	};
	const getAppointmentCalendar = () => {
		let dataValue = null;
		let calendar = null;
		try {
			if (object === constants.APPOINTMENTS) {
				dataValue = getObjectParam(fieldProps["start_date"]).value;
			} else if (object === constants.TASKS) {
				dataValue = getObjectParam(fieldProps["t_dueby"]).value;

			}
			calendar = (
				<div
					style={{
						width: "48%",
						padding: "0px",
						float: "right",
						marginLeft: "5px",
						paddingTop: 10,
					}}
				>
					<AppTaskCalendar
						object={object}
						record={fieldProps}
						id={recordId}
						updateDnD={(v) => updateDnD(v)}
						dt={dataValue}
					/>
				</div>
			);
		} catch (error) {
			console.error(
				"Error in 'sfFrom.js -> getAppointmentCalendar()':" + error
			);
		}
		return calendar;
	};
	const getQuoteList = (param) => {

		let invoicelist = [];
		let promise = SFFormActions.getQuoteList(param);
		promise.then((response) => {
			if (isValidParam(response)) {
				invoicelist = getArrayParam(response);
				let menuItems = [];
				if (invoicelist.length > 0) {
					invoicelist.forEach((item) => {
						menuItems.push(
							<MenuItem
								key={item.quoteno}
								value={item.quoteno}
								id={item.quoteno}
								style={styles.popoverMenuItem}
							>
								{item.quoteno}
							</MenuItem>
						);
					});
				}
				let temp = listValueMenuItems;
				temp["quoteno"] = menuItems;
				setListValueMenuItems(temp);
				setInvoiceList(invoicelist);
			}
		});
	};
	const truncateString = (value, limit, fName) => {
		let _typeof = typeof value;
		if (_typeof == "string") {
			if (value.length <= limit) {
				return value;
			} else {
				return value.slice(0, limit) + "...";
			}
		} else {
			return value;
		}

		// else if(object === constants.OPPORTUNITIES_OBJECT && (fName === 'contact_name' || fName === 'company' || fName === 'product')){
		// 	return value.slice(0,limit);
		// }
		// else{
		// 	if(Object.keys(value).length > 0 )
		// 	return value.slice(0,limit) + "...";
		// }

	}
	const openAttachmentPopover = (event) => {
		event.preventDefault();
		setIsOpenAttachmentPopover(true);
		setAnchorEl(event.currentTarget);
	}
	const closeAttachmentPopover = () => {
		setIsOpenAttachmentPopover(false);
	};

	const onClickUploadAttachment = (value, event) => {
		let _existingFiles = [];
		let _files = getArrayParam(attachFiles);

		if (attachmentDetails != null) {
			_existingFiles = attachmentDetails.attached_files
		} else if (existingFiles.length == 0) {
			_existingFiles = [..._files]
		}
		else {
			_existingFiles = [...existingFiles]
		}
		if (value == "Computer") {
			setIsOpenAttachmentPopover(false);
			let data = new Object();
			data.fullWidth = true;
			data.maxWidth = 'md';
			data.callFrom = 'TaskForm';
			data.existingFiles = _existingFiles;
			data.duplicateFiles = duplicateFiles;
			data.object = constants.TASKS;
			dispatch(getAppDialog(true, constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE, setAttachFilesFn, data, null));

		}

	}
	const setAttachFilesFn = (files, duplicateFileName, duplicateFileExsts) => {
		if (duplicateFileExsts === true) {
			var uniqueResultArray;
			if (isValidParam(attachFiles)) {
				uniqueResultArray = attachFiles.filter(function (objOne) {
					return !files.some(function (objTwo) {
						return objOne.name == objTwo.name;
					});
				});
			}

			files = getArrayParam(files);
			if (files.length > 0) {
				setAttachFiles([...uniqueResultArray, ...files]);

			}
		}
		else {
			files = getArrayParam(files);
			if (files.length > 0) {
				let temp = [...attachFiles, ...files];
				setAttachFiles(temp);

			}
		}

	}
	const getAttachmetFiles = () => {
		let _files = getArrayParam(attachFiles);
		let ul = null;
		let li = _files.map((f, index) => {
			return <li key={f.name + index}><span style={{
				float: 'left', textAlign: 'left', overflow: 'hidden',
				textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'
			}} title={f.fileName}> {f.name} </span>
				&nbsp;
				<i className="fa fa-times" aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }} onClick={() => onclickRemoveFile(index)} ></i>
			</li>


		});
		if (li != null && li.length > 0) {
			ul = <ul>{li}</ul>;
			//setExistingFiles([..._files]);

		}
		return ul;
	}
	const onclickRemoveFile = (index) => {
		let removeItem = attachFiles[index];
		let file = attachFiles.filter(f => {
			return f.name !== removeItem.name;
		});
		setAttachFiles(file)
	}
	const downloadAs = (item) => {
		if (item.uid > 0 || (item.urlS3 != null && item.urlS3 != undefined)) {
			let tabInfo = getActiveTabInfo();
			let isUnitsAccount = false;
			let UnitsAccountProjectId = 0;
			if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
				isUnitsAccount = tabInfo.isUnitsAccount;
				UnitsAccountProjectId = tabInfo.UnitsAccountProjectId;
			}
			let url = '';
			var formData = new FormData();
			let documentName = null;
			let documentType = '';
			let fileparams = {}
			url = endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT;
			documentName = item.name;

			if (UnitsAccountProjectId > 0) {
				fileparams = { 'id': item.uid, 'url': item.hasOwnProperty('urlS3') && item.urlS3 != null ? item.urlS3 : "", 'isUnitsAccount': isUnitsAccount, 'UnitsAccountProjectId': UnitsAccountProjectId };
			} else {
				fileparams = { 'id': item.uid, 'url': item.hasOwnProperty('urlS3') && item.urlS3 != null ? item.urlS3 : "" };
			}

			formData.append('input_param', JSON.stringify(fileparams));
			documentName = getStringParam(documentName);
			documentType = documentName.split('.').pop();
			if (documentName != '') {
				HTTPClient.postFormDataDownloadFile(url, formData, documentName);
			}
		} else {
			Axios.get(item.urlS3, {
				headers: {
					"Content-Type": "application/octet-stream"
				},
				responseType: "blob"
			})
				.then(response => {

					const a = document.createElement("a");
					const url = window.URL.createObjectURL(response.data);
					a.href = url;
					a.download = item.name;
					a.click();
				})
				.catch(err => {
					console.log("error", err);
				});
		}

	};
	const createAttachmentInfo = () => {
		let element = [];
		try {
			let _attachmentDetails = isValidParam(attachmentDetails) ? getArrayParam(attachmentDetails.attached_files) : [];
			let attachmentUrls = isValidParam(attachmentDetails) ? getArrayParam(attachmentDetails.urlS3) : [];
			let currentfiles = attachFiles;
			// var uniqueResultArray = _attachmentDetails.filter(function (objOne) {
			//     return !currentfiles.some(function (objTwo) {
			//         return objOne.name == objTwo.name;
			//     });
			// });
			// let allUniqueFiles = [...uniqueResultArray];
			if (isValidParam(_attachmentDetails)) {
				if (isValidParam(_attachmentDetails) && getArrayParam(_attachmentDetails).length > 0) {

					getArrayParam(_attachmentDetails).forEach(item => {
						element.push(
							<div style={{ height: '30px', marginTop: '10px' }}>
								<button style={{
									background: 'none!important',
									border: 'none',
									padding: '0!important',
									/*optional*/
									fontFamily: 'arial, sans-serif',
									/*input has OS specific font-family*/
									color: '#069',
									textDecoration: 'underline',
									cursor: 'pointer',
									maxWidth: '200px',
									// border: '1px solid red',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden'
								}} onClick={() => downloadAs(item)}>{item.name}</button>
								{/* <a href={item.urlS3} title={item.name} download={item.name}
                                    // target="_blank"
                                    style={{ paddingLeft: '5px', maxWidth: '300px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>
                                    {item.name}
                                </a> */}
							</div>
						);
					});
				}
				if (isValidParam(attachmentUrls) && getArrayParam(attachmentUrls).length > 0) {
					element.push(
						<div style={{ padding: '10 4 10 10', fontSize: 15, color: '#616161', backgroundColor: '#e3e6e8', marginTop: '11px' }}>
							{getLocalizedStrings().label.APPOINTMENTS_TASKS.LINKS}
						</div>
					);
					getArrayParam(attachmentUrls).forEach(item => {
						let hrefName = '';
						if ((item.indexOf("http:") != -1) || (item.indexOf("https:") != -1)) {
							hrefName = item;
						} else {
							hrefName = 'http://' + item;
						}
						element.push(
							<div style={{ height: '30px', paddingTop: '5px' }}>

								<a href={hrefName} title={item}
									download={item}
									target="_blank"
									rel="noreferrer"
									style={{ paddingLeft: '5px', maxWidth: '348px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>
									{item}
								</a>
							</div>
						);
					});
				}
			}
		} catch (error) {
			console.error("error in CustomForm.js --> 'createAttachmentInfo'" + error);
		}
		return element;
	}
	const getSelectedValueAutocompleteByFieldName = (typeValue, field) => {
		let valueFieldProp = fieldProps[field.name];
		let value = null;
		if (!typeValue) {
			value = valueFieldProp !== null && valueFieldProp !== undefined ? valueFieldProp.value : {};
		} else {
			value = valueFieldProp !== null && valueFieldProp !== undefined && valueFieldProp !== "" ? getArrayParam(valueFieldProp.value) : [];
		}
		let fName = field.name;
		value = truncateString(value, 43, fName);
		return value;
	};

	let isCustom = false;
	let contentHeight = window.innerHeight - 100;
	let top = (contentHeight - 10) / 2;
	if (redirect) {
		return <Navigate to={redirectUrl} />;
	}
	let isRefund = false;
	if (paymentType === "Refund") {
		isRefund = true;
	}

	let fieldsValue = getFieldsForRender();
	if (changeLog.isProjectTasks) {
		fieldsValue = projectTasksFilter(fieldsValue);
	}

	let rowComponents = null;
	let component = null;
	let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
	let sfFormFieldTextAreaStyle = { marginTop: "10px", height: "90%" };
	let sfFormFieldStyle = {
		width: "100%",
		backgroundColor: "#fff",
		border: "1px solid #dddddd",
		marginBottom: "12px",
	};
	if (object === constants.TASKS || object === constants.APPOINTMENTS) {
		sfFormFieldTextAreaStyle = {
			marginTop: "10px",
			height: "107px",
			resize: "none",
		};
		sfFormFieldStyle = {
			width: "100%",
			maxHeight: "120px",
			backgroundColor: "#fff",
			border: "1px solid #dddddd",
			marginBottom: "12px",
		};
	}

	let memoFieldRowSize = 3;
	if (object === constants.NOTE_OBJECT) {
		memoFieldRowSize = 21;
	}
	let appTaskStyle = { width: "100%" };
	let containerStyle = {
		...styles.sfFormContainer,
		width: constants.SF_FORM_OPEN_MODE_EXPANDED ? "92.2%" : "93%",
		paddingLeft: "15px",
		height: '0%',
		// height: constants.SF_FORM_OPEN_MODE_EXPANDED ? "97.4%" : "100%",
	};
	let searchIconpaddingRight = "-24px";
	let isRepeatCheckBox = false;
	if (object === constants.APPOINTMENTS || object === constants.TASKS) {
		containerStyle = {
			...styles.sfFormContainer,
			width: "420px",
			paddingRight: 10,
			float: "left",
			marginTop: "17px",
			height: object === constants.APPOINTMENTS && isDetailView ? '' : '100%',
		};
		if (callFrom !== "queueView") {
			appTaskStyle = { width: "48%", float: "left", marginLeft: "10px" };
			containerStyle = { ...styles.sfFormContainer, width: "94%", height: '0%' };
		}

		isRepeatCheckBox = true;
		if (
			object === constants.TASKS &&
			isValidParam(parentDetails) &&
			!isWorkflowTask &&
			parentDetails.parent_object === constants.PROJECTS_OBJECT
		) {
			isRepeatCheckBox = false;
		}
	}


	let tempLog = changeLog;
	if (mounted && getBooleanParam(showRequiredFormFields)) {
		return (
			<div>
				<div style={{ padding: "0px 25px" }}>
					{getLocalizedStrings().message.REQUIRED_FORM.FIELD_FILL_MESG +
						getObjectLabelByObject(object) +
						getLocalizedStrings().message.REQUIRED_FORM.CAN_BE_SUBMITTED}
				</div>
				<RequiredForms
					ref={requiredFormsRefs}
					object={object}
					openMode={constants.SF_FORM_OPEN_MODE_QUICK}
					recordId={recordId}
					requiredFields={changeLog.allRequiredFields}
					callFrom={constants.SF_FORM}
					isDetailView={isDetailView}
					record={requiredRecord}
					allFields ={fieldProps}
					getRequiredFormFieldsData = {getRequiredFormFieldsData}
				/>
				{callFrom !== "queueView" && (
					<div
						style={{
							...containerStyle,
							height: "10px",
							paddingBottom: "10em",
						}}
					>
						<div className="row">
							<div
								className="col-sm-12"
								style={{
									textAlign: "right",
									marginTop: "10px",
									marginBottom: "20px",
								}}
							>
								<div
									className="row"
									style={{ marginRight: "16px", whiteSpace: "nowrap", float: getBooleanParam(showRequiredFormFields) && callFrom !== "queueView" ? 'right' : '' }}
								>
									{getActionButtons(tempLog)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
	let parentObject = isValidParam(parentDetails) ? getStringParam(parentDetails.parent_object) : "";
	let isFranchise = getStringParam(app.selectedModuleName) === constants.MODULE_HUB ? true : false;
	return mounted ? (
		<>
			{(callFrom === "queueView") && (
				<div
					style={{
						...styles.row,
						paddingTop: 23,
						marginBottom: 10,
						backgroundColor: "#f5f5f5",
					}}
				>
					<div
						style={{
							...styles.sf_12,
							textAlign: "right",
							marginBottom: "12px",
						}}
					>
						{getActionButtons(tempLog)}
					</div>
				</div>
			)}
			<div>
				{openMode === constants.SF_FORM_OPEN_MODE_EXPANDED && (
					<div>
						<div
							style={{
								textAlign: "right",
								marginTop: "20px",
								width: "92%",
								paddingRight: "10px",
							}}
						>
							{getActionButtons(tempLog)}
						</div>
					</div>
				)}
			</div>

			<div style={containerStyle}>
				{(openMode === constants.SF_FORM_OPEN_MODE_CUSTOM && (object === constants.APPOINTMENTS || object === constants.TASKS || object === constants.NOTE_OBJECT) && callFrom !== "queueView") && (
					<div>
						<div style={{ textAlign: "right", marginBottom: "20px" }}>
							{getActionButtons(tempLog)}
						</div>
					</div>
				)}
				{callFrom !== "queueView" && object === constants.NOTE_OBJECT && (parentObject !== constants.CASES_OBJECT || (parentObject === constants.CASES_OBJECT && !isFranchise)) && (
					<div
						style={{
							float: "left",
							display: "inline-flex",
							marginBottom: "-45px",
							marginLeft: '50%',
						}}
					>
						<div>
							<div>
								<FormControlLabel
									control={
										<ToggleSwitch
											color="default"
											key="pin_note"
											name="pin_note"
											checked={isPinNoteChecked}
											value={isPinNoteChecked}
											onChange={(e) => onCheckedPinNote(e.target.checked)}
											defaultChecked={false}
										/>
									}
									label={getLocalizedStrings().label.COMMON.PIN}
									labelposition="right"
									labelstyle={{
										color: "#717171",
										padding: "0px",
										width: "auto",
										fontWeight: "normal",
									}}
								/>
							</div>
						</div>

						<div>
							<div style={{ marginLeft: 22 }}>
								<FormControlLabel
									control={
										<ToggleSwitch
											color="default"
											key="important_note"
											name="important_note"
											checked={isImportantNote}
											value={isImportantNote}
											onChange={(e) => onCheckedImportantNote(e.target.checked)}
											defaultChecked={false}
										/>
									}
									label={getLocalizedStrings().label.COMMON.STAR}
									labelposition="right"
									labelstyle={{
										color: "#717171",
										padding: "0px",
										width: "auto",
										fontWeight: "normal",
									}}
								/>
							</div>
						</div>
					</div>
				)}

				<div style={appTaskStyle}>
					{
						fieldsValue?.map((field, index) => {
							if (rowComponents === null) {
								rowComponents = [];
							}
							component = null;
							let fieldLabel = null;
							let defaultDate = null;
							fieldLabel = getMultiLingualLabel(field.label);
							let disableLookUp = null;
                            if(object === constants.CASES_OBJECT && app.me.is_franchisee 
								&& app.selectedModuleName === constants.MODULE_HUB && field.name === "company"){
                                disableLookUp = true;
                            }
							if (field.is_required_field) {
								fieldLabel = fieldLabel + getLocalizedStrings().label.COMMON.REQUIRED;
							}

							if (field.field_type === constants.FIELD_TYPE_HEADER) {
								component = (
									<div
										style={{
											display: "flex",
											height: "30px",
											width: "100%",
											fontSize: "24px",
											color: "#555",
											marginBottom: "12px",
											marginTop: '10px'
										}}
										id={field.name}
										key={field.id + field.name}
									>
										{getLocalizedStrings().label.COMMON.hasOwnProperty(fieldLabel)
											? getLocalizedStrings().label.COMMON[fieldLabel]
											: fieldLabel}
									</div>
								);
							}

							if (
								field.field_type === constants.FIELD_TYPE_TEXT &&
								((field.is_lookup_field &&
									object !== constants.PAYMENTS_OBJECT) ||
									(field.name === "first_name" &&
										object === constants.CASES_OBJECT))
							) {
								let typeValue = object === constants.OPPORTUNITIES_OBJECT && field.name === "product";
								let value = !field.is_readonly && getSelectedValueAutocompleteByFieldName(typeValue, field);
								if(value == null && (field.label == "Contact Name" || field.label == "Account Name") && object =='appointments'){
									value ='';
								}
								let isDiabledField;
								if (typeValue && value.length === 0) {
									isDiabledField = true
								} else {
									isDiabledField = isPreviewStateValue;
								}
								let autocompleteDisabled = object === constants.OPPORTUNITIES_OBJECT;
								if (autocompleteDisabled) {
									let autocompleteElement = document.getElementById("sf-autocomplete-multiple-textfield-product");
									if (autocompleteElement) {
										if (value.length > 0) {
											autocompleteElement.style.display = "none";
										}
										else {
											autocompleteElement.style.display = "initial";
										}

									}
								}
								let fieldUId = "sf-field-div-" + field.name.replace(/ /g, '-');
								component = (
									<div key={field.name + field.id} id={fieldUId}>
										{value !== null && (
											<Autocomplete
												style={{ width: "100%" }}
												disableClearable
												freeSolo
												multiple={typeValue}
												filterOptions={(x) => x}
												getOptionLabel={(option) =>
													typeof option === "string" ? option : option.name
												}
												id={"sf-autocomplete-multiple-textfield-" + field.name}
												disabled={isDiabledField}
												options={
													isValidParam(changeLog.autoCompleteDataSource[field.name]) && changeLog.autoCompleteDataSource[field.name]
														? changeLog.autoCompleteDataSource[field.name] : []
												}
												value={value}
												renderTags={(value, getTagProps) =>
													value.map((option, index) => (
														<Chip
															style={{ maxWidth: (document.getElementById(fieldUId)?.offsetWidth || 300) - 50 }}
															label={option.name}
															title={option.name}
															size='small'
															{...getTagProps({ index })}
														/>
													))
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label={fieldLabel}
														InputLabelProps={{
															style: {
																textOverflow: "ellipsis",
																whiteSpace: "nowrap",
																overflow: "hidden",
																width: "96%",
															},
														}}
														className={"input-text-autocomplete-" + field.name}
														disabled={isPreviewStateValue || disableLookUp}
														variant="outlined"
														fullWidth
														margin="dense"
														size='small'
													/>
												)}
												onInputChange={(e, value) => setLookupFieldValue(
													field.name,
													field.lookup_field_name,
													field.lookup_object,
													value
												)}
												onChange={(e, newValue) =>
													setAutoCompleteValueOnchange(
														field.name,
														field.lookup_field_name,
														field.lookup_object,
														newValue
													)
												}
												onBlur={() =>
													handelOnBlur(
														field.name,
														field.lookup_field_name,
														field.lookup_object
													)
												}
											/>
										)}
									</div>
								);


							} else if (
								field.field_type === constants.FIELD_TYPE_TEXT &&
								field.is_lookup_field &&
								object === constants.PAYMENTS_OBJECT &&
								parentObject === constants.JOBS_OBJECT
							) {
								component = getDropDownComponetForTextField(field, fieldLabel);
							} else if (
								(field.field_type === constants.FIELD_TYPE_TEXT &&
									!field.is_hidden_field) ||
								(field.field_type === constants.FIELD_TYPE_INTEGER &&
									field.name !== "workflow_id" &&
									!field.is_hidden_field) ||
								(field.field_type === constants.FIELD_TYPE_FLOAT &&
									!field.is_hidden_field) ||
								field.field_type === constants.FIELD_TYPE_URL ||
								(field.field_type === constants.FIELD_TYPE_LIST &&
									field.name === "workflow_name" &&
									(recordId > 0 ||
										openMode ===
										constants.SF_FORM_OPEN_MODE_EXPANDED))
							) {
								let isReadOnly = field.is_readonly;
								if (
									field.field_type === constants.FIELD_TYPE_LIST &&
									field.name === "workflow_name" &&
									(recordId > 0 ||
										openMode ===
										constants.SF_FORM_OPEN_MODE_EXPANDED)
								) {
									isReadOnly = true;
								}
								if (isReadOnly) {
									//*
									isReadOnly = true;
								}
								let tempsfFormFieldContainerStyle = sfFormFieldContainerStyle;
								if (
									fieldProps[field.name] !== undefined &&
									isValidParam(fieldProps[field.name].helperText) &&
									fieldProps[field.name].helperText !== ""
								) {
									tempsfFormFieldContainerStyle = {
										...sfFormFieldContainerStyle,
										border: "1px solid #f75723",
									};
								}
								let acData = null;
								if (field.name === "synopsis" || field.name === "project_name") {
									acData = "off";
								} else {
									acData = "new-password";
								}

								if (field.name === "payment_value" &&
									(fieldProps["payment_type"].value === "Credit Card" || fieldProps["payment_type"].value === "ACH")
								) {
									let iconDiv;
									if (isPaymentConnected) {
										if (getIntParam(appContainer.drawerProps.data.id) > 0) {
											iconDiv = <div></div>;
										} else {
											iconDiv = (
												<div
													onClick={() => {
														if (!paymentButtonDisabled)
															handleCreditCardPayment();
													}}
													style={{
														marginTop: "4px",
														paddingLeft: "10px",
														cursor: "pointer",
													}}
												>
													{sfForm.checkoutWithMoneris && <MonerisCheckout />}
													<Tooltip title={fieldProps["payment_type"].value === "ACH" ? "Collect Payment" : "Process Credit Card"} placement="right-end">
														<i
															aria-hidden="true"
															className="far fa-credit-card fa-3x"
															data-tip={fieldProps["payment_type"].value === "ACH" ? "Collect Payment" : "Process Credit Card"}
														></i>
													</Tooltip>
												</div>
											);
										}
									} else {
										iconDiv = <div></div>;
									}

								}
								component = (
									<FormControl
										style={{ width: "100%" }}
										className="test"
										noValidate
										autoComplete="off"
									>
										<TextField
											variant="outlined"
											key={field.id}
											error={
												fieldProps[field.name] !== null &&
												fieldProps[field.name] !== undefined &&
												fieldProps[field.name].helperText !== null &&
												fieldProps[field.name].helperText !== ""
											}
											disabled={isReadOnly}
											label={fieldLabel}
											InputLabelProps={{
												style: {
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
													width: "96%",
												},
											}}
											fullWidth={true}
											name={field.name}
											value={
												fieldProps[field.name] !== null &&
													fieldProps[field.name] !== undefined
													? fieldProps[field.name].value
													: ""
											}
											onChange={(e) =>
												setFieldValue(field.name, e, e.target.value)
											}
											onBlur={() => setFieldValueOnBlur(field.name)}
											className={
												field.is_lookup_field ? "form-field-search-icon" : ""
											}
											inputprops={{
												maxLength:
													fieldProps[field.name] !== null &&
														fieldProps[field.name] !== undefined
														? fieldProps[field.name].field_length
														: 512,
												style: {
													cursor: isReadOnly ? "not-allowed" : "auto",
												},
											}}
											autoFocus={isAutoFocus(field.name)}
											//ref={"field-ref-" + field.name}
											id={"field-ref-id-" + field.name}
											autoComplete={acData}
											margin="dense"
											size="small"
										/>
									</FormControl>
								);
							} else if (field.field_type === constants.FIELD_TYPE_LIST) {
								if (
									field.list_type === constants.LIST_FIELD_TYPE_REGULAR ||
									field.list_type === constants.LIST_FIELD_TYPE_USER ||
									field.list_type === constants.LIST_FIELD_TYPE_EXTERNAL
								) {
									if (
										fieldProps[field.name] !== null &&
										fieldProps[field.name] !== undefined &&
										getBooleanParam(fieldProps[field.name].isAddToThisList)
									) {
										let tempsfFormFieldContainerStyle = sfFormFieldContainerStyle;
										if (
											fieldProps[field.name] !== null &&
											fieldProps[field.name] !== undefined &&
											isValidParam(fieldProps[field.name].helperText) &&
											fieldProps[field.name].helperText !== ""
										) {
											tempsfFormFieldContainerStyle = {
												...sfFormFieldContainerStyle,
												border: "1px solid #f75723",
											};
										}
										component = (
											<FormControl
												style={{ width: object === constants.NOTE_OBJECT ? '40%' : '100%' }}
												className="test"
												noValidate
												autoComplete="off"
											>
												<TextField
													variant="outlined"
													key={field.id}
													disabled={field.is_readonly}
													label={fieldLabel}
													InputLabelProps={{
														style: {
															textOverflow: "ellipsis",
															whiteSpace: "nowrap",
															overflow: "hidden",
															width: "96%",
														},
													}}
													error={
														fieldProps[field.name] !== null &&
														fieldProps[field.name] !== undefined &&
														fieldProps[field.name].helperText !== null &&
														fieldProps[field.name].helperText !== ""
													}
													fullWidth={true}
													name={field.name}
													value={fieldProps[field.name] ? fieldProps[field.name].value : ""}
													onChange={(e) =>
														setFieldValue(field.name, e, e.target.value)
													}
													onBlur={() => addToThisList(field.name)}
													style={tempsfFormFieldContainerStyle}
													className={
														field.is_lookup_field ? "form-field-search-icon" : ""
													}
													inputprops={{
														maxLength:
															fieldProps[field.name] !== null &&
																fieldProps[field.name] !== undefined
																? fieldProps[field.name].field_length
																: 512,
													}}
													autoFocus={isAutoFocus(field.name)}
													//ref={"field-ref-" + field.name}
													id={"field-ref-id-" + field.name}
													autoComplete="new-password"
													margin="dense"
													size="small"
												/>
											</FormControl>
										);
									} else if (field.name === 't_name' && object === constants.TASKS) {
										let autocompleteStyle = styles.sfFormFieldContainer;
										if (
											fieldProps[field.name] !== null &&
											fieldProps[field.name] !== undefined &&
											isValidParam(fieldProps[field.name].helperText) &&
											fieldProps[field.name].helperText !== ""
										) {
											autocompleteStyle = {
												...autocompleteStyle,
												border: "1px solid #f75723",
												cursor: "pointer",
											};
										}
										component = (
											<div
												style={{
													height: "50px",
													border: "0px solid green",
													marginTop: object === constants.NOTE_OBJECT ? "-9px" :  "",
													width: object === constants.NOTE_OBJECT ? "40%" : "100%",
												}}
											>
												<Autocomplete
													value={fieldProps[field.name] !== null &&
														fieldProps[field.name] !== undefined &&
														isValidParam(
															getLocalizedStrings().label.DEFAULT_NOTE_TYPES[
															fieldProps[field.name].value
															]
														)
														? getLocalizedStrings().label.DEFAULT_NOTE_TYPES[
														fieldProps[field.name].value
														]
														: fieldProps[field.name] !== null &&
															fieldProps[field.name] !== undefined
															? fieldProps[field.name].value
															:
															""}
													onChange={(event, newValue) =>
														setFieldValue("t_name", event, newValue)
													}
													filterOptions={(options, params) => {

														const { inputValue } = params;
														let filtered = options;
														if (inputValue !== '') {
															filtered = options.filter(function (el) {
																return el.title.includes(inputValue);
															});
														}

														// Suggest the creation of a new value
														const isExisting = options.some((option) => inputValue === option.title);
														// if (inputValue !== '' && !isExisting) {
														// 	filtered.push({
														// 		inputValue,
														// 		title: `Add "${inputValue}"`,
														// 	});
														// }

														return filtered;
													}}
													onBlur={(event, newValue) =>
														setFieldValueOnBlur("t_name", event, newValue)
													}
													selectOnFocus
													clearOnBlur
													handleHomeEndKeys
													id="free-solo-with-text-demo"
													options={taskNameList}
													getOptionLabel={(option) => {
														// Value selected with enter, right from the input
														if (typeof option === 'string') {
															return option;
														}
														// Add "xxx" option created dynamically
														if (option.inputValue) {
															return option.inputValue;
														}
														// Regular option
														return option.title;
													}}
													renderOption={(props, option) => <li {...props}>{option.title}</li>}
													style={{ width: "100%" }}

													renderInput={(params) => (
														<TextField
															{...params}
															label={fieldLabel}
															variant="outlined"
															disableClearable={false}
															disabled={field.is_readonly}
															fullWidth={true}
															margin="dense"
															size="small"
															className={"sf-fields-bg"}
															error={
																fieldProps[field.name] !== null &&
																fieldProps[field.name] !== undefined &&
																fieldProps[field.name].helperText !== null &&
																fieldProps[field.name].helperText !== ""
															}

															InputLabelProps={{
																style: {
																	textOverflow: "ellipsis",
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	width: "90%",
																},
															}}
														/>
													)}
												/>
											</div>
										);

									} else {
										let isReadonlyField = field.is_readonly;
										if (
											openMode === constants.SF_FORM_OPEN_MODE_QUICK &&
											(field.name === "workflow_name" || field.name === "t_status")
										) {
											isReadonlyField = false;
										}
										if (
											object === constants.CONTACTS_OBJECT &&
											openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
											field.name === "t_status"
										) {
											isReadonlyField = true;
										}
										if (paymentType === "Refund" && field.name === "payment_type") {
											isReadonlyField = true;
										}
										let _taskObjParam = getObjectParam(tasksObjParam)
										if (
											(((getBooleanParam(_taskObjParam.isFranchiseRecord) && getStringParam(_taskObjParam.selectOpt) == constants.UNIT_TASKS)) ||
												((getBooleanParam(isUnitask) && getStringParam(_taskObjParam.selectOpt) == constants.TASKS))) && field.name == 't_owner') {
											isReadonlyField = true;
										}
										if ((object === constants.CONTACTS_OBJECT || object === constants.PARTNERS_OBJECT)  && field.name == 'shared_units') {
											isReadonlyField = true;
										}

										let autocompleteStyle = styles.sfFormFieldContainer;
										if (
											fieldProps[field.name] !== null &&
											fieldProps[field.name] !== undefined &&
											isValidParam(fieldProps[field.name].helperText) &&
											fieldProps[field.name].helperText !== ""
										) {
											autocompleteStyle = {
												...autocompleteStyle,
												border: "1px solid #f75723",
												cursor: "pointer",
											};
										}
										let menuItems = listValueMenuItems[field.name];
										if (paymentType !== "Refund" && field.name === "payment_type") {
											menuItems = menuItems.filter((obj) => {
												return obj.props.id !== "Refund";
											});
										}
										if (field.name === 'workflow_name' && menuItems.length > 0) {
											if ((app.me.is_franchisee || app.me.is_franchisor) && object === constants.ACCOUNTS_OBJECT) {
												menuItems = menuItems.filter(e => (e.props.value !== 'Onboarding' && e.props.value !== 'Franchisee'));
											}
										}
										if (field.name === 'workflow_name' && menuItems.length > 0) {
											if ((app.me.is_franchisee || app.me.is_franchisor) && object === constants.ACCOUNTS_OBJECT) {
												menuItems = menuItems.filter(e => (e.props.value !== 'Onboarding' && e.props.value !== 'Franchisee'));
											}
										}
										if(object === constants.CASES_OBJECT && app.me.is_franchisee && app.selectedModuleName === constants.MODULE_HUB && field.name === "sales_rep"){
                                            isReadonlyField = true;
                                        }
										component = (
											<div
												style={{
													height: "50px",
													border: "0px solid green",
													marginTop:   object === constants.NOTE_OBJECT && field.name == "t_visibility" ? '2px' : object === constants.NOTE_OBJECT ? "-9px" : "",
													width: object === constants.NOTE_OBJECT ? "40%" : "100%",
												}}
											>
												<SFAutoComplete
													key={"sf-auto-complete" + field.id}
													name={field.name}
													isLookup={field.is_lookup_field}
													label={fieldLabel}
													error={
														fieldProps[field.name] !== null &&
														fieldProps[field.name] !== undefined &&
														fieldProps[field.name].helperText !== null &&
														fieldProps[field.name].helperText !== ""
													}
													menuItems={menuItems}
													disabled={isPreview || isReadonlyField}
													onChange={(event, value) =>
														setFieldValue(field.name, event, value)
													}
													value={
														fieldProps[field.name] !== null &&
															fieldProps[field.name] !== undefined &&
															isValidParam(
																getLocalizedStrings().label.DEFAULT_NOTE_TYPES[
																fieldProps[field.name].value
																]
															)
															? getLocalizedStrings().label.DEFAULT_NOTE_TYPES[
															fieldProps[field.name].value
															]
															: fieldProps[field.name] !== null &&
																fieldProps[field.name] !== undefined
																? fieldProps[field.name].value
																: ""
													}
													inputprops={{
														maxLength:
															fieldProps[field.name] !== null &&
																fieldProps[field.name] !== undefined
																? fieldProps[field.name].field_length
																: 512,
														style: {
															cursor: isReadonlyField ? "not-allowed" : "pointer",
														},
													}}
													onBlur={() => setFieldValueOnBlur(field.name)}
													underlineFocusStyle={styles.textField.underlineFocus}
													inputStyle={styles.textField.input}
													menuItemStyle={styles.popoverMenuItem}
													style={{ ...autocompleteStyle, width: "100%" }}
													autoFocus={isAutoFocus(field.name)}
													//	ref={"field-ref-" + field.name}
													id={field.id}
													isFullHeight={false}
													isModifyList={field.is_modify_list}
													fieldType={field.field_type}
												/>
											</div>
										);
									}
								} else if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
									let tempsfFormFieldContainerStyle = sfFormFieldContainerStyle;
									if (
										fieldProps[field.name] &&
										isValidParam(fieldProps[field.name].helperText) &&
										fieldProps[field.name].helperText !== ""
									) {
										tempsfFormFieldContainerStyle = {
											...sfFormFieldContainerStyle,
											border: "1px solid #f75723",
										};
									}
									let multiValue = [];
									if (
										fieldProps[field.name] &&
										fieldProps[field.name].selectedListValues &&
										fieldProps[field.name].selectedListValues !== ""
									) {
										if (fieldProps[field.name].selectedListValues.indexOf(",") > -1) {
											multiValue = fieldProps[field.name].selectedListValues.split(",");
										} else {
											multiValue = (fieldProps[field.name].selectedListValues);
										}
									}
									sortArrayObjectByProperty(field.list_values, "value");
									component = (
										<FormControl
											variant="outlined"
											style={{ width: "100%", marginTop: "8px",
												maxWidth: field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT && openMode === constants.SF_FORM_OPEN_MODE_QUICK  && "440px" }}
											size='small'
											disabled={isPreview ? true : !!field.is_readonly}
										>
											<InputLabel
												id="sf-sfformresults-simple-select-outlined-label"
												className="sf-sfformresults"
											>
												{fieldLabel}
											</InputLabel>
											<Select
												key={field.id}
												id={"id" + field.id}
												multiple
												label={fieldLabel}
												value={multiValue}
												onChange={(e) => setMultiSelectValue(e, field.name)}
												className={"sf-fields-bg"}
												renderValue={(selected) => selected.join(", ")}
												variant="outlined"
												error={
													fieldProps[field.name] !== null &&
													fieldProps[field.name] !== undefined &&
													fieldProps[field.name].helperText !== null &&
													fieldProps[field.name].helperText !== ""
												}
												autoFocus={isAutoFocus(field.id)}
											>
												{field.list_values.map((listValue, listValueIndex) => {
													return (
														<MenuItem
															id={listValue.id}
															key={listValue.id}
															value={listValue.value}
															title={listValue.value}
														>
															<Checkbox
																checked={
																	fieldProps[field.name] !== null &&
																	fieldProps[field.name] !== undefined &&
																	fieldProps[field.name].selectedListValues &&
																	fieldProps[field.name].selectedListValues.indexOf(listValue.value) > -1
																}
															/>
															<ListItemText primary={listValue.value} />
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									);
								}
							} else if (
								field.field_type === constants.FIELD_TYPE_LIST_INTEGER &&
								field.list_type === constants.LIST_FIELD_TYPE_REGULAR
							) {
								let isReadonlyField = field.is_readonly;
								let autocompleteStyle = styles.sfFormFieldContainer;
								if (
									fieldProps[field.name] !== null &&
									fieldProps[field.name] !== undefined &&
									isValidParam(fieldProps[field.name].helperText) &&
									fieldProps[field.name].helperText !== ""
								) {
									autocompleteStyle = {
										...autocompleteStyle,
										border: "1px solid #f75723",
									};
								}
								sortArrayObjectByProperty(field.list_values, "value");
								let menuListItems = [];
								field.list_values.forEach((listValue, listValueIndex) => {
									let menuItem = { props: listValue };
									menuListItems.push(menuItem);
								});
								let isShowMarginBottom = true;
								if (object === constants.TASKS && field.name === "t_priority") {
									autocompleteStyle = {
										border: "0px",
										paddingTop: "3px",
										paddingRight: "0px",
										width: "45%",
										top: "9px",
									};
									isShowMarginBottom = false;
								}
								component = (
									<SFAutoComplete
										key={"sf-auto-complete" + field.id}
										name={field.name}
										isLookup={field.is_lookup_field}
										label={fieldLabel}
										menuItems={menuListItems}
										error={
											fieldProps[field.name] !== null &&
											fieldProps[field.name] !== undefined &&
											fieldProps[field.name].helperText !== null &&
											fieldProps[field.name].helperText !== ""
										}
										disabled={
											isPreview ? true : isReadonlyField
										}
										onChange={(event, value) =>
											setFieldValue(field.name, event, value)
										}
										value={fieldProps[field.name] ? fieldProps[field.name].value : ""}
										inputprops={{
											maxLength: fieldProps[field.name] ? fieldProps[field.name].field_length : 10,
										}}
										onBlur={() => setFieldValueOnBlur(field.name)}
										inputStyle={styles.textField.input}
										menuItemStyle={styles.popoverMenuItem}
										style={autocompleteStyle}
										autoFocus={isAutoFocus(field.name)}
										//ref={"field-ref-" + field.name}
										id={field.id}
										isFullHeight={false}
										isModifyList={field.is_modify_list}
										autoComplete="new-password"
										fieldType={field.field_type}
										isShowMarginBottom={isShowMarginBottom}
									/>
								);
							} else if (
								field.field_type === constants.FIELD_TYPE_DATE_TIME ||
								field.field_type === constants.FIELD_TYPE_DATE
							) {
								let dateTimeFormatValue = null;
								if (field.field_type === constants.FIELD_TYPE_DATE_TIME) {
									dateTimeFormatValue =
										dateTimeFormatFlatPicker[app.me.date_format];
								} else if (field.field_type === constants.FIELD_TYPE_DATE) {
									dateTimeFormatValue =
										dateFormatFlatPicker[app.me.date_format];
								}

								let HHFormat = new RegExp("HH");

								let datePickerOptions = {
									enableTime: field.is_readonly ? false : field.field_type === constants.FIELD_TYPE_DATE_TIME,
									noCalendar: !!field.is_readonly,
									dateFormat: dateTimeFormatValue,
									minuteIncrement: 1,
									static: true,
									time_24hr: HHFormat.test(app.me.date_format),
								};

								let flatpickerStyle = {
									paddingLeft: "7px",
									marginBottom: "12px",
									height: "42px",
									width: "100%",
									border: "1px solid #dddddd",
									backgroundColor: "#ffffff",
									cursor: field.is_readonly ? "not-allowed" : "default",
								};
								if (
									fieldProps[field.name] !== null &&
									fieldProps[field.name] !== undefined &&
									isValidParam(fieldProps[field.name].helperText) &&
									fieldProps[field.name].helperText !== ""
								) {
									flatpickerStyle = {
										...flatpickerStyle,
										border: "1px solid #f75723",
									};
								}

								let dateClass = "dateTimeDiv";
								if (callFrom === "queueView") {
									dateClass = "dateTimeQueueDiv";
								}
								let isReadOnly = field.is_readonly;
								if (isReadOnly) {
									isReadOnly = true;
								}

								let iconDiv = null;
								if (
									field.name === "payment_date" &&
									(fieldProps["payment_type"].value ===
										"Credit Card" ||
										fieldProps["payment_type"].value === "ACH")
								) {
									if (isPaymentConnected) {
										if (getIntParam(appContainer.drawerProps.data.id) > 0) {
											iconDiv = <div></div>;
										} else {
											iconDiv = (
												<div
													onClick={() => {
														if (!paymentButtonDisabled)
															handleCreditCardPayment();
													}}
													style={{
														marginTop: "8px",
														paddingLeft: "10px",
														cursor: "pointer",
														display: "inline-block",
													}}
												>
													  {sfForm.checkoutWithMoneris && <MonerisCheckout />}
														<Tooltip title={fieldProps["payment_type"].value === "ACH" ? "Collect Payment" : "Process Credit Card"} placement="right-end">
														<i
															aria-hidden="true"
															className="far fa-credit-card fa-3x"
															data-tip={fieldProps["payment_type"].value === "ACH" ? "Collect Payment" : "Process Credit Card"}
														></i>
													</Tooltip>
												</div>
											);
										}
									}
								}
								component = (
									<div style={{ display: "flex" }}>
										<div
											style={{
												marginTop: "9px",
												width: "100%",
											}}
											className={
												"sf-form-field " + dateClass + "-" + field.name
											}
											id="dateholder"
										>
											<PopOver
												id={field.name}
												key={field.name}
												name={field.name}
												btnType={
													field.field_type === constants.FIELD_TYPE_DATE_TIME
														? "dateTime"
														: "date"
												}
												buttonOuterLabelStyle={isReadOnly ? { backgroundColor: '#fff', fontSize: 12, lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontWeight: 500, position: 'absolute' } : null}
												buttonStyle={{ fontSize: "16px" }}
												containerStyle={{ lineHeight: object === constants.OPPORTUNITIES_OBJECT ? '' : 1 }}
												buttonLabel={fieldLabel}
												onChange={(date, fldName) => handleFlatpkrDateChange(date, fldName)}
												value={
													fieldProps[field.name] !== null &&
														fieldProps[field.name] !== undefined &&
														fieldProps[field.name].value === ""
														? defaultDate
														: fieldProps[field.name] !== null &&
															fieldProps[field.name] !== undefined
															? fieldProps[field.name].value
															: ""
												}
												innerLabelStyle={{
													padding: "8 5 2 5",
													fontSize: "15px",
													cursor: isReadOnly ? "not-allowed" : "pointer",
													color: isReadOnly ? "#C6C6C6" : "default",
												}}
												height={40}
												options={datePickerOptions}
												disable={isReadOnly}
											/>
										</div>
										<div> {iconDiv}</div>
									</div>
								);
							} else if (field.field_type === constants.FIELD_TYPE_MEMO) {
								let tempsfFormFieldStyle = sfFormFieldStyle;
								if (
									fieldProps[field.name] !== null &&
									fieldProps[field.name] !== undefined &&
									isValidParam(fieldProps[field.name].helperText) &&
									fieldProps[field.name].helperText !== ""
								) {
									tempsfFormFieldStyle = {
										...sfFormFieldStyle,
										border: "1px solid #f75723",
									};
								}
								let isReadOnly = field.is_readonly; //*
								if (isReadOnly) {
									isReadOnly = true;
								}

								// need to comment or custom feild
								component = (
									<FormControl
										style={{
											width: "100%",
											border: "0px",
											marginTop: "8px",
											marginRight: "20px",
										}}
										className="sf-textarea"
										noValidate
										autoComplete="off"
									>
										<TextField
											variant="outlined"
											key={field.id}
											type="textarea"
											multiline={true}
											minRows={memoFieldRowSize}
											label={fieldLabel}
											InputLabelProps={{
												style: {
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
													width: "96%",
												},
											}}
											error={
												fieldProps[field.name] &&
												fieldProps[field.name].helperText &&
												fieldProps[field.name].helperText !== ""
											}
											disabled={isReadOnly}
											onChange={(e) =>
												setFieldValue(field.name, e, e.target.value)
											}
											onKeyDown={handleKeyPress}
											// style={{...tempsfFormFieldStyle, height: '386px'}}
											style={{ height: "auto", marginBottom: "5px" }}
											value={
												fieldProps[field.name] ? unescapeHTML(fieldProps[field.name].value) : ""
											}
											inputProps={{
												maxLength:
													field.name != null && field.name != undefined && field.name == 't_description'
														? 2048 : fieldProps[field.name] !== null &&
															fieldProps[field.name] !== undefined
															? fieldProps[field.name].field_length
															: 512,
											}}
											autoFocus={isAutoFocus(field.name)}
											//ref={"field-ref-" + field.name}
											ref={ref => ref && ref.focus()}
											onFocus={object == "note" && field.name == "note_text" ? (e) => e.currentTarget.setSelectionRange(0, 0)
												: (e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
											id={"field-ref-id-" + field.name}
											autoComplete="new-password"
											className={"sf-fields-bg"}
										/>
									</FormControl>
								);
							}
							if (
								openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
								field.col_span === 2 &&
								field.field_type === constants.FIELD_TYPE_HEADER
							) {
								component = (
									<div id={field.name} key={field.name}>
										{component}
									</div>
								);
							} else if (
								openMode === constants.SF_FORM_OPEN_MODE_QUICK ||
								(openMode === constants.SF_FORM_OPEN_MODE_EXPANDED && field.col_span === 2) ||
								object === constants.APPOINTMENTS ||
								object === constants.TASKS ||
								object === constants.NOTE_OBJECT
							) {
								let positionStyle = {};
								if (object === constants.APPOINTMENTS) {
									if (field.name === "start_date") {
										positionStyle = {
											width: "48%",
											top: "9px",
										};
										if (callFrom === "queueView") {
											positionStyle = {
												width: "50%",
												top: "9px",
											};
										}
									}
									if (field.name === "end_date") {
										if (callFrom === "queueView") {
											positionStyle = {
												position: "absolute",
												top: "144px",
												left:
													object === constants.APPOINTMENTS
														? "16%"
														: "18.5%",
												width:
													object === constants.APPOINTMENTS
														? "13%"
														: "13.7%",
											};
										} else {
											positionStyle = {
												position: "absolute",
												left: "25.8%",
												width: "21.5%",
												top: "181px",
											};
										}
									}
								} else if (object === constants.PAYMENTS_OBJECT) {
									searchIconpaddingRight = "-13px";
								} else if (object === constants.TASKS) {
									if (field.name === "t_dueby") {
										positionStyle = {
											// position: 'relative',
											top: "11px",
										};
									}
								}
								let fieldRightPadding = "0px";
								if (
									openMode ===
									constants.SF_FORM_OPEN_MODE_EXPANDED &&
									field.col_span === 2
								) {
									fieldRightPadding = "20px";
								}
								let mainDivStyle = { ...styles.sf_12 };
								let divStyleData = {
									...positionStyle,
									border: "0px",
									paddingTop: "3px",
									paddingRight: fieldRightPadding,
								};
								if (
									object === constants.TASKS &&
									field.name === "task_type"
								) {
									let tempMarginTop = '';
									if (changeLog.taskStartDateFieldName === 'Task Dependent') {
										tempMarginTop = '13.5%'
									} else if (changeLog.isProjectTasks) {
										tempMarginTop = '4px'
									}
									divStyleData = {
										position: "absolute",
										left: "25.8%",
										width: "21.2%",
										top: changeLog.isShowCustomTaskType
											? "450px"
											: isCustom
												? "291px"
												: "285px",
										border: "0px",
										paddingTop: "3px",
										paddingRight: "0px",
										marginTop: tempMarginTop
									};
								}
								if (
									object === constants.TASKS &&
									field.name === "t_priority"
								) {
									if (field.field_type === "list-integer-type") {
										isCustom = true;
										mainDivStyle = { width: "100%" };
									} else {
										mainDivStyle = { width: "45%" };
									}
									divStyleData = {
										border: "0px",
										paddingTop: "3px",
										paddingRight: "0px",
										width: "100%",
										top: "9px",
									};
								}

								component = (

									<div style={divStyleData} id={field.name} key={field.name}>
										{
											(field.name !== "fullname" || (field.name === "fullname" &&
												!isValidParam(listValueMenuItems[field.name]))) &&
											(
												<div
													style={{
														display:
															(openMode === constants.SF_FORM_OPEN_MODE_EXPANDED) &&
																(field.col_span === 2) ? "" : "flex",
														alignItems: object === constants.OPPORTUNITIES_OBJECT && field.name === "product" ? '' : 'center'
													}}
												>
													<div style={mainDivStyle}>{component}</div>
													{field.is_lookup_field && (
														<div style={{
															marginLeft: object ===
																constants.PAYMENTS_OBJECT
																? "-10px"
																: "-33px", zIndex: 1
														}}>
															<span
																style={{
																	display: "inline-block",
																	border: "0px",
																	width:
																		object ===
																			constants.PAYMENTS_OBJECT
																			? "10%"
																			: "",
																	marginTop: object === constants.OPPORTUNITIES_OBJECT && field.name === "product" ? "20px" : "2px",
																	marginRight: searchIconpaddingRight,
																	marginLeft:
																		object ===
																			constants.PAYMENTS_OBJECT
																			? "-37px"
																			: "",
																}}
																title={getLocalizedStrings().label.COMMON.SEARCH}
																className="search-field-icon fa fa-search sf-sffrom-search "
																onClick={disableLookUp ? () => {} : () => openLookup(
                                                                    field.name,
                                                                    field.is_lookup_field,
                                                                    field.lookup_field_name,
                                                                    field.lookup_object,
                                                                    field?.label
                                                                )}
															/>
														</div>
													)}
												</div>
											)}

										{/**** START:   Appoinments/Tasks fullName/ Cases first_name */}

										{(field.name === "fullname" && listValueMenuItems[field.name] !== null && listValueMenuItems[field.name] !== undefined) &&
											getDropDownComponetForTextField(field, fieldLabel)}
										{/**** END:   Appoinments/Tasks fullName */}
									</div>
								);
							} else if (
								(openMode === constants.SF_FORM_OPEN_MODE_EXPANDED && field.col_span === 1) ||
								getStringParam(openMode).trim() === constants.SF_FORM_OPEN_MODE_CUSTOM
							) {
								let fieldMargin = "0px";
								if (
									openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
									field.col_span === 1 &&
									field.name === "campaign_type"
								) {
									fieldMargin = "8px";
								}
								let tempPaddingTop = '18px';
								if(object !== constants.OPPORTUNITIES_OBJECT){
									if (field.name === "product" && fieldProps["product_id"].value != "" && fieldProps["product_id"].value != null && fieldProps["product_id"].value != undefined) {
										tempPaddingTop = '24px'
									}
								}
								component = (
									<div
										style={{
											...styles.sf_6,
											float: "left",
											paddingRight: "20px",
										}}
										id={field.name}
										key={field.name}
									>
										<div style={{ display: "flex", marginTop: fieldMargin }}>
											<div style={{ ...styles.sf_12 }}>{component}</div>
											{field.is_lookup_field && (
												<div
													style={{
														marginLeft: "-33px",
														paddingTop: tempPaddingTop,
														zIndex: 1,
													}}
												>
													<span
														style={{
															pointerEvents: isPreview
																? "none"
																: "auto",
														}}
														className="search-field-icon fa fa-search sf-search-icon-margin-right-expand"
														onClick={disableLookUp ? () => {} : () => openLookup(
                                                                    field.name,
                                                                    field.is_lookup_field,
                                                                    field.lookup_field_name,
                                                                    field.lookup_object,
                                                                    field?.label
                                                                )}
													/>
												</div>
											)}
										</div>
									</div>
								);
							}

							if (
								(field.name !== "workflow_id" &&
									field.name !== "workflow_name" &&
									!field.is_hidden_field &&
									field.name !== "link_info") ||
								(field.name === "workflow_name" && isWorkflowShow) ||
								(field.name === "workflow_name" &&
									openMode === constants.SF_FORM_OPEN_MODE_EXPANDED)
							) {
								rowComponents.push(component);
							}
							if (
								isValidParam(changeLog.taskStartDateFieldName) &&
								field.name === "dep_start_date" &&
								object === constants.TASKS
							) {

								let taskDateField = createProjectDepTaskFields(changeLog.taskStartDateFieldName);
								rowComponents.push(taskDateField);
							}
							if (
								openMode === constants.SF_FORM_OPEN_MODE_QUICK ||
								openMode === constants.SF_FORM_OPEN_MODE_CUSTOM ||
								field.col_span === 2 ||
								(field.col_span === 1 && (rowComponents.length === 2 || fields.length === index + 1))
							) {
								if (
									(object === constants.APPOINTMENTS &&
										isRepeatCheckBox &&
										field.name === "end_date") ||
									(object === constants.TASKS &&
										isRepeatCheckBox &&
										field.name === "t_dueby")
								) {
									let repeatCheckBox = (
										<div
											style={{
												...styles.sf_12,
												marginTop: "10px",
												display: "flex",
											}}
										>
											<div
												className="repeatChkBox"
												style={{ width: "8%", float: "left" }}
											>
												<FormControlLabel
													control={
														<Checkbox
															color="default"
															checked={recurring_enable}
															value={recurring_enable}
															onChange={(e, ischecked) => handleRepeatCheckBox(e, ischecked)}
														/>
													}
													labelposition="left"
													labelstyle={{
														color: "#717171",
														padding: "0px",
														width: "auto",
														fontWeight: "normal",
													}}
													style={{ height: "auto" }}
												/>
											</div>
											<div
												style={{
													width: "18%",
													paddingTop: "9px",
													marginLeft: "8px",
												}}
											>
												{getLocalizedStrings().label.APPOINTMENTS_TASKS.REPEAT}
											</div>
											<div
												style={{
													width: "12%",
													marginLeft: "14px",
													marginTop: "9px",
												}}
											>
												{recurring_enable && (
													<span
														style={{ color: "#6495ED", cursor: "pointer" }}
														onClick={() => handleEditRepeat()}
													>
														<u>{getLocalizedStrings().label.COMMON.EDIT}</u>
													</span>
												)}
											</div>
										</div>
									);
									component = (
										<div>
											<div style={{ ...styles.sfrow }}> {rowComponents} </div>
											<div style={{ ...styles.sfrow }}> {repeatCheckBox} </div>
										</div>
									);
								} else if (
									(showReminder &&
										object === constants.APPOINTMENTS &&
										reminderBlock &&
										field.name === "assign") ||
									(object === constants.TASKS &&
										reminderBlock &&
										field.name === "t_owner")
								) {
									let reminderBlock = (
										<ReminderBlock
											onUpdate={(dataVal) => handleReminderBlockUpdate(dataVal)}
											reminders={sfForm.reminder}
										/>
									);
									component = (
										<div
											style={{
												marginRight: "-15px",
												marginLeft: "-15px",
												width: "100%",
											}}
										>
											{rowComponents}
											{reminderBlock}
										</div>
									);
								} else {
									component = (
										<div key={index} style={{ ...styles.sfrow }}>
											{" "}
											{rowComponents}{" "}
										</div>
									);
									if (
										openMode === constants.SF_FORM_OPEN_MODE_EXPANDED
									) {
										component = (
											<div
												key={index}
												style={{ ...styles.sfrow, display: "table" }}
											>{rowComponents}</div>
										);
									}
								}
								rowComponents = null;
								return <div key={field.name + field.id}>{component}</div>;
							}
						})
					}
					{openMode === constants.SF_FORM_OPEN_MODE_QUICK
						&& (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)
						&& (
						<div style={{ ...styles.sfrow }}>
							<div style={{ marginTop: "5px" }}>
								{!getBooleanParam(isAddToGroupList) ? (
									<SFAutoComplete
										key={"sf-auto-complete-group"}
										id={1012102}
										name="groups"
										label="Add to Group"
										itemValues={stateGroups}
										onChange={(e, value) => setGroup(e, value)}
										value={changeLog.selectedGroup}
										inputprops={{ maxLength: 20 }}
										inputStyle={styles.textField.input}
										menuItemStyle={styles.popoverMenuItem}
										style={{ ...styles.sfFormFieldContainer, marginBottom: 0 }}
										isFullHeight={true}
									/>
								) : (
									<FormControl
										style={{ width: "100%" }}
										className="test"
										noValidate
										autoComplete="off"
									>
										<TextField
											variant="outlined"
											label={getLocalizedStrings().label.GROUP.GROUP_NAME}
											inputStyle={styles.textField.input}
											fullWidth={true}
											name="Group"
											value={changeLog.selectedGroup}
											onChange={(e) => changeAddToGroup(e)}
											onBlur={(e) => addToThisGroupList(e)}
											className={"sf-fields-bg"}
											autoFocus={true}
											autoComplete="new-password"
											margin="dense"
											size="small"
										/>
									</FormControl>
								)}
							</div>
						</div>
						)}
					{openMode === constants.SF_FORM_OPEN_MODE_QUICK &&
						object !== constants.REPORTED_SALES_OBJECT && (
							<div style={{ ...styles.sfrow }}>
								<div style={{ ...styles.sf_12 }}>
									<FormControl
										style={{
											width: "100%",
											marginBottom: "10px",
											marginTop: "12px",
										}}
										className="test"
										noValidate
										autoComplete="off"
									>
										<TextField
											variant="outlined"
											key="flyingNoteText"
											type="textarea"
											multiline={true}
											minRows={3}
											label={getLocalizedStrings().label.COMMON.NOTE}
											textareastyle={sfFormFieldTextAreaStyle}
											onChange={(e) => setFlyingNoteTextFn(e.target.value)}
											value={flyingNoteText}
											autoFocus={changeLog.autoFocusFieldName === ""}
											autoComplete="new-password"
											className={"sf-fields-bg"}
										/>
									</FormControl>
								</div>
							</div>
						)}
					{(openMode === constants.SF_FORM_OPEN_MODE_QUICK || openMode === constants.SF_FORM_OPEN_MODE_EXPANDED)
						&& object !== constants.COMPLIANCE_OBJECT
						&& object !== constants.REFUND_OBJECT && object !== constants.PAYMENTS_OBJECT && object !== constants.REPORTED_SALES_OBJECT
						&& object !== constants.INVENTORY_OBJECT && object !== constants.PRODUCTS_OBJECT && object !== constants.ROYALTY_OBJECT && object !== constants.GROUP_OBJECT && object !== constants.SOLUTIONS_OBJECT && object !== constants.TIME_OBJECT
						&& object !== constants.PETS_OBJECT && (app.selectedModuleName !== constants.MODULE_HUB || object !== constants.CASES_OBJECT) &&
						(
							<div style={{ ...styles.sfrow }}>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												color="default"
												key="sales_rep"
												name="sales_rep"
												checked={isNotifySalesRep}
												value={isNotifySalesRep}
												onChange={(e) =>
													onCheckedSalesRep(e.target.checked)
												}
											/>
										}
										label={notifySalesRepLabel}
										labelposition="right"
										labelstyle={{
											color: "#717171",
											padding: "0px",
											width: "auto",
											fontWeight: "normal",
										}}
									/>
								</div>
								{app.me.phone_text_provider && app.me.phone_text_provider != constants.NONE && app.me.notify_text_salesrep
									&& object == constants.ACCOUNTS_OBJECT && <div>
										<FormControlLabel
											control={
												<Checkbox
													color="default"
													key="notify_text_sales_rep"
													name="notify_text_sales_rep"
													checked={isTextNotifySalesRep}
													value={isTextNotifySalesRep}
													onChange={(e) =>
														onCheckedTextNotifySalesRep(e.target.checked)
													}
												/>
											}
											label={textNotifySalesRepLabel}
											labelposition="right"
											labelstyle={{
												color: "#717171",
												padding: "0px",
												width: "auto",
												fontWeight: "normal",
											}}
										/>
									</div>}
							</div>
						)}
					{(object === constants.PAYMENTS_OBJECT && !isRefund && (
						(app.me.xero_integration && app.me.xero_integration.is_xero
							&& app.me.xero_integration.xero_integration_type === "MANUALLY"
							&& app.me.xero_integration.is_xero_payment
						) || app.me.is_qbsetup)) && (
							<div style={{ ...styles.row, marginTop: "5px" }}>
								<div style={{ ...styles.sf_12 }}>
									<div
										className="repeatChkBox"
										style={{ width: "8%", float: "left" }}
									>
										<FormControlLabel
											control={
												<Checkbox
													color="default"
													checked={isAddToQuickbook}
													value={isAddToQuickbook}
													onChange={handleQuickAddCheckBox}
												/>
											}
											labelstyle={{
												color: "#717171",
												padding: "0px",
												width: "auto",
												fontWeight: "normal",
											}}
											style={{ height: "auto" }}
										/>
									</div>
									<div
										style={{ float: "left", marginTop: "11px", fontSize: '15px' }}
									>
										{app.me.xero_integration &&
											app.me.xero_integration.is_xero &&
											app.me.xero_integration
												.xero_integration_type === "MANUALLY" &&
											app.me.xero_integration.is_xero_payment
											? getLocalizedStrings().label.COMMON.PUSH_TO_XERO
											: getLocalizedStrings().label.COMMON.PUSH_TO_QB}
									</div>

								</div>
							</div>
						)}
					{(object === constants.APPOINTMENTS || object === constants.TASKS) && (
						<div style={{ ...styles.sfrow }}>
							<div style={{ ...styles.sf_12 }}>

								<CustomForm
									object={object}
									openMode={openMode}
									id={recordId}
									isDetailView={isDetailView}
									parentDetails={parentDetails}
									attendeeRecordId={attendeeRecordId}
									updateAttendee={(selectedAttendees) => setAttendeesDataArr(selectedAttendees)}
									updateNoteData={(noteData) => setNoteDataObj(noteData)}
									callFrom={callFrom}
									isUnitOwner={isUnitOwner}
									attachedFiles={attachedFiles}
									isUnitownerTasks={appContainer.drawerProps !== null && appContainer.drawerProps.data !== null && appContainer.drawerProps.hasOwnProperty('data') && appContainer.drawerProps.data.hasOwnProperty('isUnitownerTasks') ? getBooleanParam(appContainer.drawerProps.data.isUnitownerTasks) : false}
									tasksObjParam={tasksObjParam}
									showAttachment={appContainer.drawerProps !== null && appContainer.drawerProps?.hasOwnProperty('data') && appContainer.drawerProps.data?.hasOwnProperty('showAttachmentLink') ? getBooleanParam(appContainer.drawerProps.data.showAttachmentLink) : true}
								/>
							</div>
						</div>
					)}
				</div>
				{
					(object === constants.APPOINTMENTS || object === constants.TASKS)
					&& callFrom !== "queueView"
					&& getAppointmentCalendar()
				}
				{object == "reportedsales" && app.me.is_franchisee == true &&
					<div>
						<div>
							<div style={{ width: 'auto', fontSize: '15px', marginTop: '17px' }}>
								<a herf="" onClick={(event) => openAttachmentPopover(event)} style={{ cursor: 'pointer' }}><i className="fa fa-paperclip" aria-hidden="true"></i><span> {getLocalizedStrings().label.MAIL.ATTACH}</span></a>
								<i className="fa fa-angle-down" aria-hidden="true" onClick={(event) => openAttachmentPopover(event)} style={{ paddingLeft: '7px', cursor: 'pointer' }}></i>
								<Menu
									open={isOpenAttachmentPopover}
									anchorEl={anchorEl}
									onClose={() => closeAttachmentPopover()}
									style={{ width: '100%' }}
								>
									<MenuItem onClick={() => onClickUploadAttachment('Computer')} value='Computer' style={styles.popoverMenuItem}>{getLocalizedStrings().label.MAIL.COMPUTER}</MenuItem>
								</Menu>

							</div>
						</div>
						<div>
							<div style={{ padding: '10 4 10 10', fontSize: 15, color: '#616161', backgroundColor: '#e3e6e8', marginTop: '20px' }} >
								Attachment
							</div>
							<div className="row" style={{ marginTop: '10px' }}>
								{(attachFiles?.length > 0) &&
									<div style={{ marginLeft: '-20px' }}>{getAttachmetFiles()}</div>}
							</div>
						</div>
					</div>}
				{object == constants.REPORTED_SALES_OBJECT && app.me.is_franchisor == true && app.me.is_franchisee == false && attachmentDetails?.attached_files.length > 0 &&
					<div style={{ padding: '10 4 10 10', fontSize: 15, color: '#616161', backgroundColor: '#e3e6e8', marginTop: '20px', marginLeft: '-12px', width: '100%' }} >
						Attachment
					</div>
				}

				{object == constants.REPORTED_SALES_OBJECT && createAttachmentInfo()}
				{callFrom !== "queueView" && (
					<div>
						<div
							style={{
								width: (object === constants.APPOINTMENTS || object === constants.TASKS) && callFrom !== "queueView" ? "100%" : "97%",
								textAlign: "right",
								float: "left",
							}}
						>
							<div
								style={{
									marginRight: "5px",
									whiteSpace: "nowrap",
									marginTop: "50px",
									marginBottom: appContainer.customDrawerProps?.isMinimize == true ? "50px" : "20px",
								}}
							>
								{getActionButtons(tempLog)}
							</div>
						</div>
					</div>
				)}
			</div>
			{callFrom === "queueView" && (
				<div
					style={{
						width: "57%",
						padding: "0px",
						marginTop: "-10px",
						float: "right",
					}}
				>
					<div
						style={{
							marginLeft: "30px",
							marginRight: "10px",
							marginTop: "20px",
						}}
					>
						<AppTaskQueueChild
							object={object}
							openMode={openMode}
							id={recordId}
							isDetailView={isDetailView}
							parentDetails={parentDetails}
							attendeeRecordId={attendeeRecordId}
							updateAttendee={(selectedAttendees) => setAttendeesDataArr(selectedAttendees)}
							updateNoteData={(noteData) => setNoteDataObj(noteData)}
							linkRecord={JSON.stringify(appTaskFieldProps)}
							updateLinkInfo={updateLinkInfo}
						/>
					</div>
				</div>
			)}
		</>
	) : (
		<div>
			{(object !== constants.APPOINTMENTS && object !== constants.TASKS) ? (
				<div style={{ width: "100%", height: contentHeight }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "48%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress size={30} style={{ marginTop: "3", marginLeft: "3" }} />
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}

export default SFForm;