 const label = {
    MODULE: {
        Sales: '销售',
        Marketing: '市场',
        Service: '服务',
        Operations: '操作',
        HUB: '枢纽',
        Management:'管理',
    },
    MODULE_ITEMS: {
        Reports: '报告',
        Dashboard: '仪表板',
        'Marketing Templates': '营销电子邮件模板',
        'Broadcast Campaigns': '广播营销活动',
        'Automation Designer': '自动化设计器',
        'Email Marketing': '电子邮件营销',
        'Lead Capture': '获客',
        'Landing Pages': '登录页',
        'Web Forms': 'Web表单',
        'Automated Campaigns': '自动的营销活动',
        'Social Marketing': '关系营销',
        'Website Tracking': '网站跟踪',
        'Website Analytics Setup': '网站分析设置',
        'Ab Campaigns': 'Ab 营销活动',
        'Opportunities': '商机',
        'Marketing Reports': '报告',
        'Touch Campaigns': '触摸广告系列',
        'A/B Campaigns': 'A / B广告系列',
        'Email Blast': '电子邮件爆炸',
        Activities: '活动',
        Conversion: '转变',
        Campaigns: '广告活动',
        Custom: '习惯',
        Sales: '销售',
        Marketing: '营销',
        Offers: '商情',
        Coupons: '优惠券',
        'Coupons Claim Report': '报告',
        ASSETS_DISTRIBUTION: '资产分配',
        USEFUL_LINKS: '有用的链接',
        Royalties: '版税 - 遗产',
        COMPLIANCE_REPORT: "遵守",
    },
    COMMON: {
        PUSH_NO_CONFIRM: '推送無需確認',
        UNIT_CRM_UPDATE_STAGES:'更新阶段',
        UNIT_CRM_UPDATE_TASKS:'更新任务',
        SELECT_AN_ACCOUNT:'选择单位',
        ADD_TO_PLAN:'添加到计划',
        LOGIN_TO_BASE:'登录基地',
        PLUS_BRANCH: '+分公司',
        FRENCHISEE_COMPANY_NAME:'选择加盟商公司名称',
        ADD: '添加',
        ADD_NEW_APPOINTMENT: '添加新',
        SHOW: '显示',
        HIDE: '隐藏',
        REFRESH: '刷新',
        EDIT: '编辑',
        SAVE: '保存',
        CANCEL: '取消',
        TEST: '测试',
        ENTER_TEXT: '输入文本',
        APPLY: '应用',
        AND: '和',
        EMPTY: '空',
        EXPAND: '展开',
        DELETE: '删除',
        REQUIRED: ' （必需）',
        AUTO_SEARCH_TEXT: '相同的文本, 不同的值',
        NOTE: '日志',
        SEARCH: '搜索',
        OK: '好',
        SEND_MAIL: '发送电子邮件',
        ASSIGN: '分配',
        SELECT_A: '选择一个 ',
        SELECT_MORE: '选择一个或多个 ',
        CLOSE: '关闭',
        CREATE: '创建',
        WITH: '与',
        CONVERT: '已转换',
        SEARCH_PLACEHOLDER: '键入并按 enter 键进行搜索',
        COPY: '复制',
        MORE: '更',
        CLICK_TO_SEARCH: '点击这里搜索',
        PREVIEW: '预览',
        BACK: '返回',
        CONFIRM_REQUEST: '确认请求',
        SELECT: '选择',
        ADVANCED: '高级的',
        ADVANCED_SEARCH: '高级搜索',
        RESULTS_FOR: '的结果',
        SETTINGS: '设置',
        SIGN_OUT: '注销',
        BACK_TO_YOUR_ACCOUNT: '返回您的帐户',
        BACK_TO_LIST: '回到名单上',
        BACK_TO_COLUMN_VIEW: '返回列视图。',
        MERGE: '合并',
        DISPLAY_DIFFERENT_FIELDS: '* 仅显示不同的字段。',
        WORKFLOW: '流程',
        STATUS: '状态',
        RECORD: '记录',
        MERGED_RECORD: '合并记录',
        EXPORT: '导出',
        CTR_DETAILS_FOR: '点击率详细信息',
        URL: 'URL',
        COUNT: '计数',
        CLICK_FOR_KANBAN_VIEW: '单击此处使用Kanban视图',
        CLICK_FOR_LIST_VIEW: '单击此处使用列表视图',
        INSERT: '插入',
        CATEGORIES: '类别',
        REMOVE: '移除',
        REPLY: '回复',
        REPLY_ALL: '回复全部',
        FORWARD: '向前',
        WORKFLOW_NAME: '工作流名称',
        CONVERTING: '转换',
        TO: '至',
        CURRENT_WORKFLOW: '当前工作流',
        CHANGE_WORKFLOW: '更改工作流',
        UPDATE: '更新',
        STATUS_TO_OPTIONAL: '状态为 (可选)',
        MANAGE: '管理',
        ADD_PERSONAL_NOTE_OPTIONAL: '向您的请求添加个人备注 (可选)',
        CONVERTED: '已转换',
        SELECT_USER: '选择一个用户',
        COMING_SOON: '即将推出',
        IMPORT: '导入',
        NO: '否',//'没有',
        FOUND: '发现',
        NOTHING_FOUND: '找不到任何用',
        DOT: '。',
        UNDER_PROCESSING: '正在处理，即将推出 ',
        FIND: '找',
        LOADING: '加载',
        NAME: '名称',
        CLEAR_ALL: '全部清除',
        CLEAR: '清除',
        SHARE: '分享',
        ADD_TO_GROUP: '添加到组',
        MAP: '映射',
        AUDIT: '更改日志',
        OPEN_AUDIT_FORM: '审计',
        TRAIL: '落后',
        ADD_NOTES: '添加备注',
        TO_EXCEL: '到 Excel',
        PUSH: '推送',
        RESCHEDULE: '改期',
        PRINT: '打印',
        COMPLETE: '完成',
        COMPLETE_New: '完整和新',
        SEND_EMAIL: '发送电子邮件',
        SEND_SMS: '发送短信',
        CONVERT_TO_ACCOUNTS: '转换为帐户',
        CONNECT: '连接',
        DISCONNECT: '断开',
        DOWNLOAD: '下载',
        BATCH_EDIT: '批量编辑',
        BATCH_CONVERT: '批量转换',
        INACTIVE: '非活动',
        ACTIVE: '活性',
        RESTORE: '恢复',
        PROFILE: '配置',
        TYPE_TO_SEARCH: '键入此处搜索',
        CONVERT_AND_ADD_OPP: '转换和添加商机',
        SEND_VERIFICATION_EMAIL: '发送验证电子邮件',
        THESE: '这些',
        ALL_APPOINTMENTS: '所有预约',
        ALL_TASKS: '任务',
        CONTINUE: '继续',
        ALL_OPPORTUNITIES: '所有商机',
        EDIT_NOTES: '编辑备注',
        RUN: 'Run',
        INTEGRATION_TITLE: '集成',
        UNSUBSCRIBE: '取消订阅',
        MY_INTEGRATIONS: '集成',
        NOTIFY: '通知 ',
        HELP: '帮助',
        PUBLISH: '发布',
        UNPUBLISH: '取消发布',
        CALL_SCRIPT: '调用脚本',
        DOCUSIGN: '发送DocuSign',
        DOCUSIGN_INTEGRATION: '发送DocuSign',
        ALL: '所有',
        USER: '用户',
        SYSTEM: '系统',
        MY: '我',
        TODAYS: "今天的",
        YES: '是',
        FUTURE: '未来',
        OVERDUE: '过期的',
        TOMORROWS: "明天的",
        TOMORROW: "明天",
        TODAY: '今天',
        CONNECTED_AS: '连接为',
        SALESREP: '销售代表',
        EVERYONE: '每个人',
        NOTE_TYPE: '注释类型',
        TEXT: '文本',
        FOR: '为',
        MODULE_SETTING: '设置',
        TEMPLATE: {
            LIBRARY_TEMPLATE: '库模板',
            USER_TEMPLATE: '用户模板',
        },
        OF: '的',
        'System Fields': '系统字段',
        'Custom Fields': '自定义字段',
        'Additional Information': '其他信息',
        'Contact Details': '联络详情',
        'Phone and Email Information': '电话和电子邮件信息',
        'Sales rep': '销售代表',
        DONE: '完成',
        OPEN: '打开',
        IMPORT_CSV_SOURCE_FIELD_HEADER: '我会从 CSV 文件映射我的源',
        ADD_NEW_SOURCE: '添加新源',
        ADD_TO_THIS_LIST: '添加到此列表',
        TYPE_SOMETHING: '键入内容',
        'Opportunity Details': '商机详情',
        'Company Details': '公司详细信息',
        'Contact Information': '联系信息',
        'Case Information': '案例信息',
        'Solution Details': '解决方案详细信息',
        'Project Details': '项目详细信息',
        'Issue Details': '问题详细信息',
        'Assigned to': '分配给',
        'Sales person': '销售人员',
        EMAIL_TO: '发邮件给',
        RECENT_SEARCHES: '最近的搜索',
        RECENT_RECORDS: '最近的记录',
        'Activities': '活动',
        'Conversions': '转换',
        ASSETS: '资产',
        ENABLE_MAILING: '启用电子邮件发送',
        DISABLE_MAILING: '禁用电子邮件发送',
        CLICK_TO_REMOVE: '单击此处删除',
        PIN: '销',
        UNPIN: '取消固定',
        RENAME: '重 命名',
        DEACTIVATE: '停用',
        ACTIVATE: '启用',
        COUPONS: '优惠券',
        ADD_FOLDER: '添加文件夹',
        ADD_FILE: '添加文件',
        SELECT_CONTACTS: '选择联系人',
        PREFERENCES: '喜好',
        NOTIFICATION_REMINDERS: '通知和提醒',
        LOCK_STAGES: '锁定阶段',
        LOCK_TASKS: '锁定任务',
        SEND_BATCH_SMS: '发送批处理短信',
        SEND_TEXT_MESSAGE: '发短信',
        VERIFY_EMAIL: '验证电子邮件',
        SELECT_UNIT_NAME: '选择单位名称',
        NEWS: '新闻',
        CLOSE_CASE: '关闭案例',
        CUSTOMIZE: '自定义',
        VIEW_MODE: '查看模式',
        LIST: '清单',
        COLUMN: '柱',
        SETUP_SCHEDULING_PAGES: '设置计划页面',
        SAVE_RUN: '保存 + 运行',
        BATCH_CONVERT_TO_ACCOUNTS: '批量转换为帐户',
        NEXT: 'Next',
        ADD_QUICKBOOKS: 'Add to Quickbooks',
        ITEM_FROM_QB: 'FROM Quickbooks',
        PUSH_TO_QB: 'Add to Quickbooksspre',
        ADD_NOTE: '添加注释',
        ADD_NOTE_CONVERT: '添加注释并转换',
        OBJECT_NAME: '对象名称',
        GREENLIGHT_EXPORT_DOWNLOAD: '處理與下載',
        PREVIOUS: '以前',
        VIEW: '视图',
        MENU: '菜单',
        NO_DUPLICATE: '无重复',
        ONGOING: '进行中',
        ONGOING_ONBOARDING: '持续的入职',
        SYNC_NOW: 'Sync Now',
        'Generate Invoice': '生成发票',
        'Add Payment': '添加付款',
        'Print' : '打印',
        MOBILE_MENU: 'Mobile Menu',
        PORTAL_MENU: 'Portal Menu',
        TO_QUICKBOOKS:'到 Quickbooks',
        CONVERT_INVOICE: '转换为发票',
        RECEIVE : '收到',
        ACCEPT : '接受',
        CONFIRM: '确认',
        FULFILL: '实现',
        SHIP: '船',
        ADD_JOBS : '添加工作',
        ROYALTY_SETUP: '版税设置',
        SAVE_AND_SCHEDULE:'保存和安排',
        UNLINK: '取消链接',
        TO_XMS: '到 XMS',
        SEQUENTIAL:"顺序",
        STAR: '星星',
        PIN :'别针',
        CONVERT: '转变',
        SHARE_WITH : '与某人分享',
        FRANCHISOR: '特许人',
        FRANCHISE: '特许经营',
        ALL_PHONE_NO: '所有电话号码',
        BRING_YOUR_OWN:'BYO（自带）号码',
        LEASE_NUMBER:'租约号码',
        ADD_NUMBERS:'添加号码',
        ADD_CREDITS:'添加学分',
        CALL_MINUTES:'通话分钟和',
        TEXT_CREDIT:'剩余短信积分',
        LEASE_A_NO:'租用号码',
        TOLL_FREE:'免费电话',
        LOCAL:'当地的',
        COUNTRY:'国家',
        SURVEY_SETUP : '调查设置',
        THANKS_SURVEY:'竖起大拇指调查-感谢表',
        REVIEW_WEBSITE_REQUIRED:'检查网站的 URL（必填）',
        THUMBS_UP_TITLE:'竖起大拇指标题',
        THUMBS_UP_DESC:'竖起大拇指说明',
        YES_BUTTON:'是按钮',
        NO_BUTTON:'无按钮',
        DAYS: '天）',
        LOCATION_ALERT:'位置已经存在！',
        TASK_NAME:'任务名称',
        DESC:'描述',
        UNIT_CRM: '单位 CRM 设置',
        UNIT_CRM_LABEL: '单位 CRM 管理员可以：',
        UNIT_CRM_CUSTOMIZE_FORMS: '自定义表单',
        UNIT_CRM_CUSTOMIZE_WORKFLOW: '自定义工作流程',
        ACTION: '行动：',
        INVOICE_ALL : '全部发票',
        EMAIL_ALL:'通过电子邮件发送全部',
        POST_ALL: '发布全部',
        REPORT_FIELDS: '报告字段：',
        COMPLIANCE_NOTIFICATION:'合规通知',
        COMPLIANCE_LABEL:'输入电子邮件地址，以便在加盟商不合规时通知 FR',
        ONBOARDING_NOTIFICATION:'入职通知',
        EMAIL:'电子邮件',
        HELPDESK_NOTIFICATION:'服务台通知',
        LMS_SETUP:'学习管理系统设置',
        LMS_LABEL:'LMS 帐户（必填）',
        PLUS_BASE: '+底座',
        UNITBASE:'单位基地'
    },
    NOTE:{
        ALL_NOTES:'所有笔记',
        ALL_TYPES:'所有类型',        
    },
    HOME: {
        MY_FILTERS: '我的过滤器',
        GROUPS: '组',
        ADD_NEW: '添加新的',
        RECENT: '最近',
        HOME: '首页',
        CLICK_TO_REFRESH: '刷新',
        RECENTLY_VIEWED: '最近浏览过的',
        FILTERS: '过滤器',
        ADD_GROUP: '添加组',
        NOTIFICATIONS: '通知',
        WHATS_NEW: '最新消息',
        COLLAPSE: '崩溃',
        EXPAND: '展开',
        ADD_FILTER: '添加筛选器',
        CLICK_TO_FIND_GROUP: '单击此处查找组',
        CLICK_TO_FILTER_GROUP: '过滤您的群组',
        CLICK_TO_FIND_FILTER: '单击此处查找筛选器',
        FORECASTS: '预测',

        //not found in doc
        ADD_FIELD: '单击此处将字段添加到筛选器中',
        FITER_SETUP: '筛选器设置',
        ENTER_BY_HAND: '手动输入',
        IMPORT: '导入',
        VIEW: '视图',
        DELETE: '删除',
        CLICK_TO_FIND_REPORT: '单击此处查找报告',

    },
    CAMPAIGNS: {
        NAME_REQUIRED: '市场活动名称 (必填)',
        GROUP: '组',
        CONTACT_FILTER: '联系人筛选器',
        ACCOUNT_FILTER: '帐户筛选器',
        FROM_NAME_REQUIRED: '发件人名称 (必填)',
        VIEW_AS_WEB_PAGE: '作为网页查看',
        SUBJECT_REQUIRED: '主题 (必填)',
        DAYS: '天',
        EXECUTE_NOW: '立即执行',
        TEST: '测试',
        SCHEDULED_FOR: '计划于',
        EXECUTED_ON: '执行于',
        EXECUTING: '执行',
        ABORTED: '中止',
        SCHEDULE: '计划',
        CLICK_URL: '单击 URL',
        SELECT_GROUP: '最多可选择三组',
        SELECT_CONTACT_FILTER: '从联系人中选择筛选器',
        SELECT_ACCOUNT_FILTER: '从帐户中选择筛选器',
        CHANGE_TEMPLATE: '更改模板',
        WITHOUT_AB_TEST: '无 a/b 测试',
        WITH_AB_TEST: '使用 a/b 测试',
        WITH_AUTOMATION: '自动化广告系列',
        WITHOUT_AUTOMATION: '自动触发营销（旧版）',
        SEND_ON_WEEK_DAY: '不要在周末發送（週六、週日）',
        RESEND_AFTER: '重新发送间隔',
        RESEND_ALL: '重新发送所有',
        FROM_EMAIL_REQUIRED: '发件人电子邮件 (必填)',
        CAMPAIGN_TYPE_OPTIONAL: '营销活动类型 (可选)',
        START_DATE_REQUIRED: '开始日期 (必填)',
        SENT: '已发送',
        OPENED: '已打开',
        UNOPENED: '未打开',
        CLICKED: '已点击',
        BOUNCED: '已拒收',
        UNSUBSCRIBED: '已取消订阅',
        SPAM: '垃圾邮件',
        DROPPED: '已投递',
        TYPE_HERE: '请在此处键入。',
        ADD_SIGNATURE: '添加签名',
        INSERT_IMAGE: '插入图像',
        PERSONALIZE: '个性化',
        TEMPLATE_DIALOG_TITLE: '选择模板',

        // Campaign Status
        'All': '所有',
        'Active': '活性',
        'Running': '运行',
        'Stopped': '停止',

        'New': '新',
        'Queued': '已进入队列',
        'Executed': '已执行',
        OPEN: '已打开',
        BOUNCE: '拒绝',
        SPAMREPORT: '垃圾邮件报告',
        'Halted': '暂停',
        'Auto Response': '自动响应',
        'Marketing Template': '营销模板',
        'Newsletter': '通讯',
        'Greetings': '问候',
        'Inactive': '非活动',
        'Executed - No Winner': '执行-无赢者',
        'Sent to Winner': '发送给赢者',
        CAMPAIGN_NAME: '营销活动名称',
        CAMPAIGN_TYPE: '营销活动类型',
        SUBJECT: '主题',
        MANAGE: '管理',
        FROM_EMAIL: '从电子邮件',
        SELECT_A_FILTER:'从中选择一个过滤器 ',
        BETWEEN: '之间',
        WEEKDAYS_ONLY: '平日仅限'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: '个性化主题',
        FROM_EMAIL_REQUIRED: '发件人电子邮件 (必填)',
        EMAIL_CAMPAIGN_REPORT: '电子邮件市场活动报告',
        SCHEDULE_BROADCAST: '有计划的广播',
        RESCHEDULE_BROADCAST: '重新计划广播',
        EXECUTE_BROADCAST: '执行广播',
        ADD_GROUP: '添加组',
        CATEGORY: '类别',
        TEMPLATE: '模板',
        PERFORM_AB_TESTING: '执行 a/b 测试',
        VERIFY_EMAIL: '验证您的电子邮件地址',
        VERIFY_EMAIL_ADDRESS: '在此输入您的电子邮件地址',
        VERIFY_EMAIL_ADDRESS_OPTION: '验证其他电子邮件地址',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: '编辑广播营销',
        BATCH_EDIT: '批量编辑',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: '复制广播营销',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: '创建新的广播营销',
        All_BROADCASTS: '所有广播营销',
        BROADCASTS: '广播营销',
        ADD_SIGNATURE: '添加签名',
        BROADCAST_INFO: '广播信息',
        DESIGN: '设计',
        DRAG_AND_DROP: '拖放',
        LEGACY: '遗产',
        EMAIL_BUILDER: '电子邮件生成器',
        QUALIFIED_CONTACTS: '查看收件人列表',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: '对象范围依据',
        AB_TEST_DISTRIBUTION: 'a/b 测试分布',
        MIN_MARGIN_PERCENT: '最小赢取利率%',
        A_PERCENT: 'A%',
        B_PERCENT: 'B%',
        AB_CAMPAIGN_REPORT: 'a/b 活动报告',
        //not found in doc
        AB_SEND_REST: '使用赢者发送给其余部分',
        AB_SELECT_WINNER_BY: '选择赢者依据',
        VERIFY_EMAIL_ADDRESS_OPTION: '验证其他电子邮件地址',
        EMAIL_VIEWS: '电子邮件视图',
        EMAIL_CLICKS: '电子邮件点击',
        AB_CAMPAIGN_EDIT_TAB_LABEL: '编辑 a/b 营销活动',
        BATCH_EDIT: '批量编辑',
        AB_CAMPAIGN_COPY_TAB_LABEL: '复制 a/b 营销活动',
        AB_CAMPAIGN_CREATE_TAB_LABEL: '创建新的 a/b 营销活动',
        ALL_AB_CAMPAIGNS: '所有 a/b 营销活动',
        AB_TESTS: 'a/b 测试',
        MESSAGE_A: '消息 A',
        MESSAGE_B: '消息 B',
        AFTER: '后',
        SCHEDULE_AB_CAMPAIGN: '计划 a/b 营销活动',
        WINNER_BASEDON_A: '赢者 (基于消息 A)',
        WINNER_BASEDON_B: '赢者 (基于消息 B)'
    },
    CALENDAR: {
        CALENDAR_TASKS: '日历/任务',
        CALENDAR_ONLY: '日历',
        CALENDARS: '所有人',
        SHOW_OPTION_1: '取消',
        SHOW_OPTION_2: '完成',
        SHOW_OPTION_3: '计划',
        TODAY: '今天',
        BACK: '返回',
        NEXT: '下一页',
        DAY: '天',
        WEEK: '周',
        MONTH: '月',
        AGENDA: '议程',
        DATE: '日期',
        TIME: '时间',
        EVENT: '事件',
        ALL_DAY: '全天',
        WORK_WEEK: '工作周',
        YESTERDAY: '昨天',
        TOMMOROW: '明天',
        SHOW_MORE: '',
        TYPE: '类型',
        MY_CALENDAR_SETTING: '我的日历设置',
        DISPLAY:'展示',
        ACCOUNT_NAME:'帐户名称',
        JOB_NAME : '职位名称',
        COLOR_CODE: '色标',

    },
    GROUP: {
        GROUP_NAME: '组名称',
        DELETE_RECORD: '删除记录',
        USED_IN: '用于',
        REMOVE_FROM: '移自',
        SELECT_RECORDS: '所选记录',
        ALL_RECORDS: '所有记录',
        SELECT_OPTIONS: '选择选项',
        GROUP_FILTER: '选择组筛选器',
        ALL: '所有',
        ACTIVE: '活性',
        INACTIVE: '非活动',
        DETAILS: '细节',
        'Active': '活性',
        'Inactive': '非活动',
        'New': '新',
        'scheduled': '已计划',
        'Queued': '已进入队列',
        'Executed': '已执行',
        'Running': '运行',
        'Halted': '暂停'
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: '任务',
        PERSONALIZE_OPTION_1: '过期的',
        PERSONALIZE_OPTION_2: '今天',
        PERSONALIZE_OPTION_3: '未来',
        PERSONALIZE_OPTION_4: '明天',
        PERSONALIZE_SORT_1: '日期',
        PERSONALIZE_SORT_2: '优先',
        TASKS_FOR: '任务',
        SORT_BY: '排序方式',
        COMPLETE_THIS_APPOINTMENT: '完成此预约',
        COMPLETE_THIS_TASK: '完成此任务',
        NEXT_DAY: '第二天',
        NEXT_WEEK: '下星期',
        COMPLETE: '完成',
        DELETE: '删除',
        TASKS: '任务',
        APPOINTMENTS: '预约',
        ADD_NOTE: '添加备注 (可选):',
        NEXT_MONTH: '下个月',
        DAYS: '天',
        SPECIFIC_DATE: '特定日期',
        RESCHEDULE: '重新安排',
        RESCHEDULE_FOR: '重新安排:',
        REPEAT: '重复',
        DAY_OF_THE_MONTH: '月份中的某一天',
        DAY_OF_THE_WEEK: '一周中的某一天',
        REPEAT_TYPE: '重复类型',
        REPEAT_ON: '重复于',
        REPEAT_BY: '重复依据',
        START_DATE: '开始日期',
        END_DATE: '结束日期',
        REPEAT_EVERY: '重复周期',
        REMOVE_REMINDER: '删除提醒',
        ONLY_THIS: '只有这',
        FOLLOWING: '之后',
        ALL: '所有',
        THIS:'这',
        THIS_AND_FOLLOWING:'这和以下',
        ONLY_THIS_RECORD: '只有此记录',
        FOLLOWING_RECORDS: '以下记录',
        ALL_RECORDS: '所有记录',
        ADD_A_NOTE: '添加备注',
        COMPLETE_TASK_OPTIONAL: '完成待处理的任务',
        COMPLETE_TASK_OPTIONAL_OBJ: '完成待处理的任务/电子邮件/短信',
        CREATE_NEXT_TASKS: '创建下一个任务',
        CREATE_NEXT_TASKS_OBJ: '创建下一个任务/电子邮件/短信',
        PENDING_TASKS: '待完成任务',
        SEARCH_BY_NAME: '按名称搜索',
        INVITE_GUEST: '邀请客人',
        ATTENDEES: '与会者',
        SALES_REP: '销售代表',

        //not found in doc
        ADD_REMINDER: '添加提醒',
        'Email': '电子邮件',
        'Pop-up': '弹出窗口',
        'minutes': '分钟',
        'hours': '小时',
        'days': '天',
        'weeks': '星期',
        DAILY: '每天',
        WEEKLY: '每周',
        MONTHLY: '每月',
        YEARLY: '每年',
        DAYS_S: '天',
        WEEKS: '星期',
        MONTHS: '月',
        YEARS: '年',
        APPOINTMENT: '约会',
        TASK: '任务',
        SUBJECT: '主题',
        ASSIGN_TO: '分配给',
        COMPANY: '公司',
        CONTACT_NAME: '联系人姓名',
        OUTLOOK_SYNC: 'Outlook 同步',
        GOOGLE_SYNC: '谷歌同步',
        'Scheduled': '已计划',
        'Completed': '已完成 ',
        'Cancelled': '已取消',
        'Pending': '等待中',
        TASK_NAME: '任务名称',
        DUE_BY: '由于',
        DESCRIPTION: '描述',
        OWNER: '所有者',
        FOLLOWUP: '跟进',
        'All': '所有',
        'Empty': '空',
        'No': '没有',
        'Yes': '是',
        'Everyone': '大家',
        'Just me': '只有我',
        ALL_TASK_COMPLETED: '这些任务已经完成。',
        'duration': '持续时间',
        'days_after': '几天后',
        DEP_TASK_NAME: '任务名称',
        TASK_COMPLETED: '任务完成（％）',
        APPOINTMENTS_AND_TASKS: '预约与任务',
        APPOINTMENTS_ONLY: '仅限约会',
        TASKS_ONLY: '仅限任务',
        EDIT: '编辑',
        SENDNOW: '现在发送',
        OPPORTUNITY_NAME: '商机名称',
        SELECT_USER: '选择用户',
        SELECT_STATUS: '选择状态',
        VISITS: '来访',
        UNIT_OWNER_TASKS: '单位任务',
        COMPLETE_UNIT_TASK_OPTIONAL: '完成待定任务的单位所有者',
        UNIT_TASKS: '该股的任务',
        DOCUMENTS: '文件',
        LINKS: '链接',
        TASK_SETTINGS: '任务设置',
        APPOINTMENT_SETTINGS: '预约设置',
    },
    ATTACHMENT: {
        UPLOAD: '上传',
        MY_COMPUTER: '我的电脑',
        BROWSE: '浏览',
        DOWNLOAD: '文件下载',
        ATTACH_FILE: '附加文件',
        UPLOADING: '上传',
        NO_IMAGE_FOUND: '未找到图像',
        DOWNLOAD_RECORDING: '下载记录',
        ATTACHMENT: '附件',
        ADD_AN_ATTACHMENT: '添加附件'

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: '单击此处折叠',
        CLICK_TO_EXPAND: '单击此处展开',
        SHOW_TOTAL: '显示合计',
        SHOW_PICTURE: '显示图片',
        DESCENDING: '降序',
        SORT_BY: '排序依据',
        SELECT_COLUMN_FOR_VIEW: '选择此视图的字段',
        SELECT_COLUMN_FOR_FILTER_VIEW: '选择用于筛选结果的列',
        ADD_AND_REORDER: '添加并重新排序',
        FIELDS: '字段',
        CLICK_TO_ADD_MORE_FIELDS: '(单击此框可添加更多字段。拖动并重新排序字段以更改其位置。)',
        SELECT_FIELDS: '选择字段',
        MODIFY_FIELD: '修改字段',
        EXPORT_SELECTED_RECORDS: '导出所选记录',
        EXPORT_ALL_RECORDS: '导出所有记录',
        LINK_EXISTING: '链接现有',
        SET_UP: '建立',
        GROUP_BY: '分组依据',
        FIND_BY_FIELD: '查找',
        FILTER_TYPE: {
            DEFAULT_FILTERS: '默认筛选器',
            SHARED_FILTERS: '共享的筛选器'
        },
        COLUMN_VIEW_SETUP_INFO: '此列视图仅显示前五个字段。列表视图显示所有字段。',
    },
    DETAIL_VIEW: {
        SHOW_LABELS: '显示标签',
        SELECT_OPTIONS: '选择选项',
        CLICK_TO_ADD_MORE_FIELDS: '单击此框可添加更多字段。拖动并重新排序字段以更改它们的顺序。您可以选择最多50字段。',
        SETUP_FIELDS: '设置字段',
        ADD_OR_REMOVE_FROM_LIST: '从列表中添加/删除字段',
        CLICK_TO_KNOW_MORE: '点击了解更多',
        CLICK_TO_ADD_CHILD_OBJECTS: '单击此框可添加更多子对象。拖动并重新排序对象以更改其位置。',
        SETUP_CHILD_TABS: '设置子选项卡',
        SHOW_LABELS_AGAINST_FIELD: '选择此选项可根据字段显示标签。',
        CUSTOMIZE_CHILD: '自定义子项',
        REFUND_CHILD_LABEL: '退款',
        //not found in doc 
        GROUPS: '组',
        MANAGE: '管理',
        ADD_TO_GROUP: '添加到组',
        DATA_TIP_1: '这将为 ',
        DATA_TIP_2: '这将为任务创建任务/电子邮件/文本消息。 ',
        DATA_TIP_3: ' 阶段',

        HIGHLIGHT_LABEL_OPPORTUNITY: '機會$',
        HIGHLIGHT_LABEL_FORECAST: '預測$',
        HIGHLIGHT_LABEL_CLOSING_ON: '收盤',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: '機會時代',
        HIGHLIGHT_LABEL_DAYS: '天',

        CHILD_SETUP_SELECT_CHILD_LABEL: '單擊此處將子對象添加到設置中。',
        NO_SETUP_OPTION: '沒有'

    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: '定义关系',
        RELATE: '关联',
        COMPANY: '公司',
        FULL_NAME: '全名',
        EMAIL: '电子邮件'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: '自动触发营销汇总',
        INTERVAL: '持续时间',
        NOTE_TYPE: '注释类型',
        TEXT_REQUIRED: '文本 (必填)',
        TASK_NAME_REQUIRED: '任务名称 (必需)',
        OWNER_REQUIRED: '所有者 (必需)',
        DUE_BY_REQUIRED: '截止 (必填)',
        DAY_AFTER_REQUIRED: '重发间隔（必须）',
        EMAIL: '电子邮件',
        POP_UP: '弹出窗口',
        MINUTES: '分钟',
        HOURS: '小时',
        DAYS: '天',
        WEEKS: '星期',
        REMINDER: '提醒',
        GROUP_REQUIRED: '选择组 (必需)',
        ADD_TO_GROUP: '添加到组',
        MOVE_TO_GROUP: '移动到组',
        REMOVE_FROM_GROUP: '从组中删除',
        STATUS_REQUIRED: '状态 (必填)',
        WORKFLOW_REQUIRED: '工作流 (必需)',
        CHOOSE_ANOTHER_FIELD: '选择其他字段',
        CHOOSE: '选择',
        FIELD_FOR: '一个字段, 用于',
        CAMPAIGN_NAME: '市场活动名称 (必填)',
        START: '开始',
        STOP: '停止',
        REPORT: '报告',
        RESUME: '恢复',
        SELECT_PAGE: '选择一个页面',
        ENTER_WEB_PAGE_LINK_OPTIONAL: '输入链接的 URL (可选)。',
        ENTER_WEB_PAGE_LINK: '输入网页链接。',
        CLICK_TO_VIEW: '点击这里查看',
        CLICK_TO_EDIT: '点击这里编辑',
        DRAG_TO_MOVE: '拖动以移动',
        DRAG_TO_CONNECT: '拖动以连接',
        CLICK_TO_REMOVE: '单击此处删除',
        CLICK_TO_SEE_REPORT: '单击此处查看报告',
        CLICK_TO_ADD_MSG: '单击此处添加新邮件',
        NO: '否',
        YES: '是',
        CLICK_TO_SELECT_USERS: '单击此处选择用户',
        SALES_REP: '销售代表',
        USERS: '用户',
        CAMPAIGN_SENDER: '市场活动发件人',
        SCORE: '得分',
        START_WITH: '添加',//'Start with'
        DECISIONS: '判断依据',
        ACTIONS: '行动',
        LINK_CLICKED: '链接已点击？',
        SCORE_REACHED: '分数已达到？',
        TOUCH_CAMPAIGN: '自动触发营销活动',
        WEB_FORM: 'Web表单',
        LANDING_PAGE: '登录页',
        WEBSITE_VISITED: '网站访问',
        CHANGE_GROUP: '更改组',
        ADD_NOTE: '添加备注',
        NOTIFY: '通知',
        ADD_TASK: '添加任务',
        FIELD_UPDATED: '更新字段',
        CHOOSE_TEMPLATE: '选择一个模板',
        USE_THIS_TEMPLATE: '使用此模板',
        WAIT: '等待',
        NAME: "名称",
        TARGET_GROUPS: '目标组',
        TARGET_FILTER: '目标过滤器',
        RESEND_AFTER: '重新发送间隔',
        START_DATE: '开始日期',
        SUBJECT: '主题',
        TEXT: '文本',
        TASK_NAME: '任务名称',
        ASSIGN_TO: '分配给',
        FIELD_LABEL: '字段标签',
        FIELD_VALUE: '字段值',
        LINK: '链接',
        SEND_ON_ANY_TIME: '发送: 任何时间',
        SEND_ON_BUSINESS_DAY: '发送: 仅在工作日内',
        CONVERT_TO: '转换为',
        QUALIFIER: '限定条件',
        OBJECT: '对象',
        NOTIFY_SENDER: '通知发件人',
        NOTIFY_SALES_REP: '通知销售代表',
        NOTIFY_SENDER_SALESREP: '通知发件人，通知销售代表',
        NOTIFY_USERS: '通知用户',
        TEST: '测试',
        VIEW_AS_WEBPAGE_INFO: '此选项将在电子邮件顶部添加一个链接以将电子邮件作为网页打开',
        LINK_CLICK_INFORMATION: '如果您保留此处空白, 将跟踪电子邮件中的所有链接。',
        GROUP_NAME: '新组名称',
        SELECT_USER: '分配给',
        REMIND_BEFORE: '之前提醒',
        INTERVAL_REQUIRED: '间隔 (必需)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: '验证其他电子邮件地址',
        'Touch Campaign': '自动触发营销活动',
        'Link Clicked': '链接已单击',
        'Link Clicked?': '链接已点击？',
        'Email Opened': '已打开电子邮件',
        'Email Opened?': '已打开电子邮件?',
        'Unsubscribed': '已取消订阅',
        'Score Reached': '分数达到',
        //'Send One-off Email': '发送一次性电子邮件',
        'Send Email': '发送电子邮件',
        'Change Group': '更改组',
        'Add Note': '添加备注',
        'Notify': '通知',
        'Add Task': '添加任务',
        'Field Updated': '字段已更新',
        'Assign': '分配',
        'Converted': '已转换',
        'Start': '开始',
        'Add Touch Message': '添加触发环节信息',
        'Web Form': 'Web表单',
        'Website Visit': '网站访问',
        'Landing Page': '登录页',
        'Wait': '等待',
        WAIT_FOR_DAYS: '天',
        'Simple email nurturing': '简单的电子邮件推进',
        'Capture leads using a web form and nurture': '通过 web 表单捕获潜在顾客并推进',
        'Track web page visit and nurture': '跟踪网页访问并推进 ',
        'Capture leads from a landing page and nurture': '从登录页面捕获潜在客户并推进',
        'Start with touch campaign': '从自动触发营销开始',
        'Start with web form': '从Web表单开始',
        'Start with tracking website visit': '从追踪网站访问开始',
        'Start with landing page': '从登录页开始',
        COPY_OF: '复制自 ',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: '新的自动营销活动',
        TARGET_BY: '对象范围依据',
        'Hours': '小时',
        'Hour(s)': '小时 ',
        'day(s)': '日(次)',
        'hour(s)': '小时',
        ALL_AUTOMATED_CAMPAIGNS: '所有的自动营销活动',
        MESSAGE: '消息',
        SELECT_A_CAMPAIGN: '请选择一个广告系列',
        ADD_SIGNATURE: '添加签名',
        BIRTHDAY_CAMPAIGN: '生日活动',
        'Add Birthday Message': '添加生日消息',
        'Start with Birthday Campaign': '从生日活动开始',
        'SMS Replied': '短信回复',
        'Send SMS': '发送短信',

        'Touch Campaign Automation': '触摸Campaign Automation',
        'Birthday Campaign Automation': '生日活动自动化',
        'Web Form Automation': 'Web表单自动化',
        'Landing Page Automation': '登陆页面自动化',

        SEND_SMS: '发送短信',
        SMS_REPLY: '短信回复？',
        BIRTHDAY_CAMPAIGN_REPORT: '生日活动报告',
        START_IMMEDIATELY: '立即',
        START_SCHEDULE: '时间表',
        SCHEDULE_ON_TEXT: '预定开始',
        SCHEDULE_DIALOG_TITLE: '选择计划日期和时间',
        FROM_NUMBER: '来自号码',
        REPLY: '回复',
        NOT_CONVERTED: '未转换'
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: '所有的自动触发营销',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: '个性化主题',
        NAME_REQUIRED: '名字(必填)',
        STATUS: '状态',
        SUBJECT_REQUIRED: '主题 (必填)',
        ACTIVE: '活性',
        INACTIVE: '非活动',
        TEST: '测试',
        SELECT_TEMPLATE: '开始输入以选择模板',
        TYPE_HERE: '请在此处键入。.',
        ADD_SIGNATURE: '添加签名',
        INSERT_IMAGE: '插入图像',
        PERSONALIZE: '个性化',
        SELECT_FROM_LIBRARY: '从库中选择',
        USE_THIS_DESIGN: '使用此设计',
        DEAR: '亲爱的',
        EXPAND_EDITOR: '全屏',
        SHARE: '同时用于营销和销售电子邮件',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: '创建营销电子邮件模板',
        COPY_OF: '的复制',
        BATCH_EDIT: '批量编辑',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: '编辑营销电子邮件模板',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: '复制营销电子邮件模板',
        CATEGORY: '类别',
        LAYOUT: '布局',
        'Auto Response': '自动响应',
        'Marketing Template': '营销模板',
        'Newsletter': '通讯',
        'Greetings': '问候',
        CHOOSE_EMAIL_BUILDER: '选择电子邮件生成器',
        DRAG_AND_DROP_BUILDER: '使用新的拖放生成器',
        LEGACY_BUILDER: '使用旧生成器',
        TEMPLATE_INFO: '模板信息',
        DESIGN: '设计',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: '个性化主题',
        NAME_REQUIRED: '名字(必填)',
        STATUS: '状态',
        SUBJECT_REQUIRED: '主题 (必填)',
        ACTIVE: '活性',
        INACTIVE: '非活动',
        TEST: '测试',
        TYPE_HERE: '请在此处键入。',
        ADD_SIGNATURE: '添加签名',
        INSERT_IMAGE: '插入图像',
        PERSONALIZE: '个性化',
        DEAR: '亲爱的',
        SALES_TEMPLATE_CREATE_TAB_LABEL: '创建销售电子邮件模板',
        SALES_TEMPLATE_EDIT_TAB_LABEL: '编辑销售电子邮件模板',
        SALES_TEMPLATE_COPY_TAB_LABEL: '复制销售电子邮件模板',
        COPY_OF: '复制_的',
        SALES_TEMPLATES: '销售电子邮件模板',
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: '个性化主题',
        NAME_REQUIRED: '名字(必填)',
        STATUS: '状态',
        SUBJECT_REQUIRED: '主题 (必填)',
        ACTIVE: '活性',
        INACTIVE: '非活动',
        TEST: '测试',
        TYPE_HERE: '请在此处键入。',
        ADD_SIGNATURE: '添加签名',
        INSERT_IMAGE: '插入图像',
        PERSONALIZE: '个性化',
        DEAR: '亲爱的',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: '创建协议模板',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: '编辑协议模板',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: '复制协议模板',
        COPY_OF: '复制_的',
        AGREEMENT_TEMPLATES: '协议模板',
        ADD_SIGNATURE_1: '签名 1',
        ADD_SIGNATURE_2: '签名 2',
        ADD_SIGNATURE_3: '签名 3',
        ADD_SIGNATURE_4: '签名 4',
        ADD_SIGNATURE_5: '签名 5',
        COVER_LETTER: '求职信',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: '编辑短信模板',
        SMS_TEMPLATE_COPY_TAB_LABEL: '复制总和模板',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: '输入您的域名 (去除 http 或 www)',
        GENERATE_TRACKING_CODE: '生成跟踪代码',
        TRACKING_CODE: '您的跟踪代码',
        COPY_TO_CLIPBOARD: '复制到剪贴板',
        DONE: '完成'

    },
    WEB_FORM: {
        SELECT_FORM: '选择一个窗体',
        WEB_FORM_CREATE_TAB_LABEL: '创建 Web表单',
    },
    MAIL: {
        EMAIL_FOR_TESTING: '输入电子邮件进行测试',
        SEND_A_TEST_EMAIL: '发送测试邮件',
        TYPE_HERE: '请在此处键入。',
        INSERT_IMAGE: '插入图像',
        ADD_SIGNATURE: '添加签名',
        PERSONALIZE: '个性化',
        FROM: '来自',
        SEND_MAIL: '发送电子邮件',
        PERSONALIZE_SUBJECT: '个性化主题',
        SUBJECT: '主题',
        COMPUTER: '从计算机',
        LIBRARY: '从库',
        RESCHEDULE: '改期',
        TO: '至',
        ATTACH: '附加文件',
        CC: 'CC',
        WHEN: '什么时候',
        SENDING_TEST_MAIL: '发送测试电子邮件...',
        SCHEDULE: '时间表',
        SCHEDULE_DIALOG_TITLE: '安排日期和时间',
        SEND_LATER: '稍后发送',
        SEND: '发送',
        RECIPIENTS: '收件者',
        SENDING: '正在发送...'
    },
    SMS: {
        TO: '至',
        HEN: '什么时候',
        FROM: '来自',
        FROM_NUMBER: '从数字',
        SEND_A_TEST_SMS: '发送测试短信',
        TO_NUMBER: '数字',
        STATUS: '状态',
        ENTER_NUMBER :'输入测试号码'
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: '潜在客户评分历史记录',
        SCORE: '得分',
        DATE: '日期',
        ACTION: '行动',
        USER: '用户',
        DETAILS: '细节',
        ACTION_NAME: '行动名称',
        SCORE_DETAILS: '分数详细信息',
        BY: '由',
        FROM: '来自',
        LINK: '链接',
        CLICKED: '已点击',
        EMAIL: '电子邮件',
        OPENED: '已打开',
        UNSUBSCRIBED: '已取消订阅',
        CONVERTED_FROM: '转换自',
        TO: '至',
        IN_OPPORTUNITIES: '在机遇',
        ADDED_TO: '添加到',
        REMOVED_FROM: '移自',
        UPDATED_TO: '更新至',
        WEBFORM: 'Web表单',
        SUBMITTED: '已提交',
        VISITED: '已访问',
        EXPIRED: '已过期',
        'Imported': '导入',
        'Created': '创建',
        'Converted to Account': '转换为帐户',
        'Created from Web form': '从Web表单创建',
        'Created from Landing page': '从登陆页面创建',
        'Created from Zapier': '由Zapier创建',
        'Created from Outlook': '从Outlook创建',
        'Created from Outlook (Client)': '从Outlook（客户端）创建',
        'Created from G Suite': '由G Suite创建',
        'Created from Office 365': '从Office 365创建',
        'Created from Facebook/twitter etc': '从Facebook / Twitter等创建',
        'Pushed': '推送',
        'Created from API': '从API创建',
        'Inline/Quick updated': '内联/快速更新',
        'Updated': '更新',
        'Batch Updated': '批量更新',
        'Workflow Applied': '工作流程应用',
        'Converted': '已转换',
        'Assigned': '分配',
        'Deleted': '删除',
        'Restored': '恢复',
        'Merged': '合并的',
        'Main Contact updated': '主要联系人更新',
        'Shared': '共享',

        'Link Clicked': '链接点击',
        'Email Opened': '电子邮件已打开',
        'Unsubscribed': '退订',
        'Web Form Submitted': 'Web表单已提交',
        'Removed from Group': '从群组中删除',
        'Field Updated': '现场更新',
        'Added to Group': '添加到组',
        'Website Visited': '网站访问',
        'Has Business Email': '有业务电子邮件',
        'Clicked Link': '点击链接',
        'Opened Email': '打开电子邮件',
        ACCOUNT_SCORE: '您的帐户满分是',
        CONTACT_SCORE: '您的联系人满分是'
    },
    DASHBOARD: {
        TODAY: '今天',
        YESTERDAY: '昨天',
        THIS_WEEK: '本周',
        THIS_MONTH: '本月',
        THIS_QUARTER: '本季度',
        THIS_YEAR: '今年',
        LAST_WEEK: '上个星期',
        LAST_MONTH: '上个月',
        LAST_QUARTER: '上一季度',
        LAST_YEAR: '去年',
        CUSTOM: '自 定义',

        //Kanban View
        NEXT_MONTH: '下个月',
        NEXT_QUARTER: '下一季度',
        NEXT_THREE_MONTHS: '未来三月',
        NEXT_TWO_MONTHS: '未来两个月',

        SALES_DASHBOARD: '销售仪表板',
        MARKETING_DASHBOARD: '营销仪表板',
        // SALES_MARKETING_DASHBOARD: '销售和市场营销仪表板',
        REPOSITION_GADGET: '单击并拖动以重新定位此小工具',
        'Top Sources By Lead Volume': '按潜在客户数量为依据的来源排序',
        'Top Sources By Lead Conversion': '按潜在客户转化率为依据的来源排序',
        'Marketing Email Summary': '营销电子邮件摘要',
        'Top Marketing Emails by CTR': '以点击率为依据的营销邮件排序',
        'Top Web Pages': '热门网页排序',
        'Lead Pipeline': '潜在客户渠道排序',
        'Opportunity Pipeline': '商机渠道排序',
        'Sales Leaderboard': '销售排行榜',

        'Status': '状态',
        'Count': '计数',
        'Subject': '主题',
        // 'Click-Through Rate(CTR)': '',
        'Website Name': '网站名称',
        'Forecast': '预测',
        'Sales person': '销售人员',
        'Closed': '关闭',

        'Activities': '活动',
        'Accounts': '账户矩阵',
        'Opportunities': '机会矩阵',
        'Users': '用户',
        'Appointment': '委任',
        'Dialed': '拨',
        'Email': '电子邮件',
        'LVM': 'LVM',
        'Note': '注意',
        'Spoke': '说话',
        'Task': '任务',
        'Transferred': '转移',

        'Qualified': '合格',
        'Presentation': '表示',
        'Proposal': '建议',
        'Negotiation': '谈判',
        'Close': '关闭',

        'Lead': '导致',
        'Prospect': '前景',
        'Customer': '客户',
    },
    LOGIN: {
        LOG_IN: '登录',
        EMAIL_ADDRESS: '电子邮件地址',
        PASSWORD: '密码',
        REMEMBER_ME: '提醒我'
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: '另存为草稿',
        POST_NOW: '立即发布',
        CONNECT: '连接'

    },
    DOCUMENT_LIBRARY: {
        COMPUTER: '计算机',
        LIBRARY: '库',
        IMAGES: '图像',
        STOCK_IMAGES: '库存图片',
        DOCUMENTS: '文件',
        SEARCH: '搜索...',
        UPLOAD_DOCUMENT: '上传文档',
        NAME: '名称',
        TITLE: '标题',
        TITLE_REQUIRED: '标题（必填）',
        LINK_REQUIRED: '链接（必填）',
        TYPE: '类型',
        OWNER: '所有者',
        ALL: '所有',
        NO_IMAGE_FOUND: '未找到图像',
        UPLOAD_DIALOG_TITLE: '选择要上载的文档',
        SHARED_WITH: '跟...一起分享',
        UNITS: '单位',
        UNIT: '单元',
        UNIT_PUSH: '单元:',
        RENAME_FOLDER: '重命名文件夹',
        CREATE_FOLDER: '创建文件夹',
        NEW_FOLDER: '新文件夹',
        ASSETS_NOT_FOUND: '未找到文件夹和文件。',
        'Folder': '文件夹',
        'File': '文件',
        IMAGE: '图像',
        DOCUMENT_AND_FILE: '文档和文件',
    },
    HEADER: {
        SALES_TEMPLATE: '销售电子邮件模板',
        CALENDAR_TASKS: '日历/任务',
        ACCOUNT: '帐户',
        SALES_REPORT: '销售报告',
        ACCOUNTS: '帐户',
        ACCOUNTS_WITHOUT_GROUP: '没有组的帐户',
        MY_ACCOUNT: '我的帐户',
        TODAYS_ACCCOUNTS: '今日账户',
        CONTACTS: '联系人',
        CONTACTS_WITHOUT_ACCOUNT: '没有帐户的联系人',
        CONTACTS_WITHOUT_ACCOUNTS: '无帐户的联系人',
        CONTACTS_WITHOUT_GROUPS: '没有组的联系人',
        MY_CONTACTS: '我的联系人',
        ACCOUNTS_WITHOUT_CONTACT: '没有联系人的帐户',
        TODAYS_CONTACTS: '今日联系人',

        ALL_APPOINMENTS: '所有预约',
        MY_APPOINMENTS: '我的预约',
        MY_COMPLETED_APPOINMENTS: '我完成的预约',
        MY_MISSED_APPOINMENTS: '我错过的预约',
        TODAYS_APPOINMENTS: '今天的预约',

        ALL_TASKS: '所有任务',
        MY_COMPLETED_TASKS: '我完成的任务',
        MY_FUTURE_TASKS: '我未来的任务',
        MY_PENDING_TASKS: '我待完成的任务',
        MY_TODAYS_TASKS: '我今天的任务',
        ONBOARDING: '新手上路',
        SALES_PIPELINE:'销售渠道报告',
        COUPONS_CLAIM_REPORTS: '优惠券索赔报告',
        'Cases': '案子',
        'Projects': '项目',
        'Issues': '问题',
        'Solutions': '解决方案',
        'Contacts': '联系人',
        'Accounts': '帐户',
        'Opportunities': '商机',
        'Appointments': '预约',
        'Tasks': '任务',
        'Onboarding': '新手上路',
        'Units': '单位',
        'Assets': '资产',
        'Activities': '活动',
        'Conversions': '转换',
        'Campaigns': '广告活动',
        'Reports': '报告',
        'Sales_templates ': '销售模板 ',
        'Dashboard': '仪表板',
        'Sales Leads': '销售线索',
        'Recycle Bin': '帐户',
        REPORT: '报告',
        TASKS_PAGE: '任务',
        'Products': '產品',
    },
    ADVANCE_SEARCH: {
        ADVANCE_SEARCH: '高级搜索',
        ADD_FIELD:'搜索另一个字段'
    },
    IMPORT: {
        GROUP: '组 (必需)',
        WORKFLOW: '工作流 (必需)',
        STATUS: '状态 (必填)',
        SOURCE: '源 (必需)',
        PRIMARY_SOURCE:'主要来源',
        SECONDARY_SOURCE:'次要来源',
        IMPORT: '导入',
        LINK_USING: '链接使用',
        LINK_WITH: '链接到',
        APPLY_WORKFLOW: '应用工作流',
        AUTO_MAP: '自动映射',
        USE_SAVED_MAPPING: '使用保存的映射',
        SAVE_MAPPING: '保存映射',
        DELETE_MAPPING: '删除映射',
        DONOT_CHECK_DUPLICATE: '不检查重复项',
        CHECK_DUPLICATE: '检查重复项',
        CHECK_DUPLICATE_BY: '检查重复项依据',
        DONOT_IMPORT: '不导入',
        MARK_DUPLICATE: '导入并标记为重复',
        UPDATE: '更新',
        //not found in doc
        STEP_1: '选择来源',
        STEP_2: '映射字段和导入',
        FINISH: '完成',
        NEXT: '下一页',
        MAP_CSV_COLUMNS: '将 CSV 列映射到 CRM',
        MAP_XLS_COLUMNS: '将 XLS 列映射到 CRM',
        MAP_XLSX_COLUMNS: '将 XLSX 列映射到 CRM',
        MAP_TO_CRM_FIELD: '映射到 CRM 字段',
        COLUMN: '列',
        ADD_NEW_GROUP: '添加新组',
        WORKFLOW_OPTIONAL: '工作流 （ 可选 ）',
        FILE_MUST_CSV: '文件必须采用 CSV 格式。',
        SKIP: '跳过',
        MERGE: '合并',
        CHECK_DUPLICATE_USING: '使用重复检查',
    },
    REPORT: {
        START_DATE: '开始日期',
        END_DATE: '结束日期',
        REPORT_ON: '报告',
        REFRESH: '刷新',
        VIEW: '视图',
        PRINT: '打印',
        SALES_REP: '销售代表',
        DATE_RANGE: '日期范围',
        SALES_REPORT: '销售报告',
        SERVICE_REPORT: '服务报告',
        'Activity Report': '活动汇总报告',
        'Conversion Report': '销售成交汇总报告',
        'Call Report': '电话呼叫汇总报告',
        'Pipeline Report': '渠道汇总报告',
        'Customer Activation Report': '客户激活汇总报告',
        'Customer Service Type Report': '客户服务类型汇总报告',
        'Customer Assessment Report': '客户评估汇总报告',
        'Customer Leads Assessment Report': '客户潜在客户汇总报告',
        'Customer Status Report': '客户状态汇总报告',
        'Sales Pipeline Report': '销售渠道汇总报告',
        'Days in Franchise Workflow Report': '特许经营工作流程汇总报告',
        'Franchise Workflow Report': '特许经营工作流程报告',
        'Franchise Sales Pipeline Report': '特许经营销售渠道汇部报告',
        'Franchise Employees Report': '特许经营员工汇总报告',
        'Franchise Last Contact Report': '特许经营最近联系人汇总报告',
        'InXpress User Baseline Report': 'InXpress 用户管道汇总报告',
        'Franchise User Report': '特许经营用户汇总报告',
        'Sales Summary Report': '销售摘要报告',
        ACTIVITY_MATRIX: '活动矩阵',
        'ACTIVITY_TYPE': '活动类型',
        'Service Reports': '服务报告',
        'Project Reports': '项目汇总报告',
        'CRM Activity by Account': '依帐户为依据的 CRM 活动',
        'Activity by Franchise': '依特许经营商为依据的活动',
        'Franchise Activity Report': '特许经营活动报告',
        'Issue Reports': '问题报告',
        'Customer Service and Sales Rep Listing': '客户服务和销售代表列表',
        GO: '去',
        'CRM Account Details': 'CRM 帐户详细信息',
        'Activity by Coach': '以教练为依据的活动',
        'Franchise Conversion Report': '特许经营成效率汇总',
        'Tasks Report': '任務報告',
        'Email Report': '電郵報告',
        'SMS Report': '短信报告',
        'Orthotic Report': '矯正報告',
        'Greenlight Export': 'Greenlight出口',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: '帐户',
            OPPORTUNITIES: '商机'
        },
        'Count': '计数',
        'Grand Total': '总计',
        ACTIVITIES_BY_TYPE: '以类型为依据的活动',
        ACTIVITIES_BY_SALES_REP: '以销售代表为依据的活动',
        ALL_CONVERSIONS_BY_STATUS: '以状态为依据的所有转换',
        CUSTOMERS_BY_SOURCE: '以来源为依据的客户',
        DEALS_BY_SALES_PER: '销售代表的管道',
        DEALS_BY_STAGES: '以阶段为依据的交易',
        TOTAL_CALLS_BY_SALES_REP: '以销售代表为依据的总呼叫',
        TOTAL_CALL_DURATION_BY_SALES_REP: '以销售代表为依据的总呼叫时间',
        EXCEL: 'Excel',
        MATRIX_BY_STATUS: '以状态为依据的矩阵',
        MATRIX_BY_SOURCE: '以来源为依据的矩阵',
        'Status': '状态',
        'Source': '来源',
        '5 Ways Report': '5要素汇总',
        'Forecast Report': '预测报告',
        CASES_BY_STATUS: '以状态为依据的案子',
        CASES_BY_PRIORITY: '以优先级为依据的案子',
        PROJECTS_BY_STATUS: '以状态为依据的项目',
        PROJECTS_BY_TYPE: '以类型为依据的项目',
        ISSUES_BY_STATUS: '以状态为依据的问题',
        ISSUES_BY_PRIORITY: '以优先级为依据的问题',
        SUPPORT_MATRIX: '支持矩阵',
        CUSTOMER_BY_CASES: '以案子为依据的客户',
        PROJECT_MATRIX: '项目矩阵',
        ISSUE_MATRIX: '问题矩阵',
        SUB_TOTAL: '小计',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': '分配给',
            'Total count': '总计数',
            'Company': '公司',
            'Assigned To': '分配给',
        },
        COUPONS_NAME: '选择优惠券',
        PRODUCT_SELECT: '选择产品',
        AVAILABLE_REPORTS: '可用报告',
        WORKFLOW_STAGES: '阶段',
        CHART_TYPE: '图表类型',
        SHOW: '节目',
        VALUE: '价值',
        COUNT: '数数',
        DEALS_BY_SALES_REP: '销售代表的管道',
        PIPELINE:'管道',
    },
    FILTER: {
        NAME_REQUIRED: '筛选器名称 (必需)',
        REMOVE_CONDITION: '移除条件',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'关联对象的标准',
        //not found in doc
        NEXT_BUTTON_TEXT: '下一页',
        PREVIOUS_BUTTON_TEXT: '前',
        AND: '和',
        OR: '或',
        ADD_ANOTHER: '添加另一个',
        ADD_CONDITION: '添加条件',
        COPY_FILTER: '副本的 ',
        FROM_DATE: '开始日期',
        TO_DATE: '结束日期',

        CONTAINS: '包含',
        DOES_NOT_CONTAINS: '不包含',
        IS_EQUAL_TO: '等于',
        IS_NOT_EQUAL_TO: '不等于',
        IS_EMPTY: '为空',

        IS_GREATER_THAN: '大于',
        IS_GREATER_THAN_OR_EQUAL_TO: '大于或等于',
        IS_LESS_THAN: '小于',
        IS_LESS_THAN_OR_EQUAL_TO: '小于或等于',
        'Contacts': '联系人',
        'Contacts without Groups': '没有组的联系人',
        'My Contacts': '我的联系人',
        'Contacts without Account': '没有帐户的联系人',
        'Todays Contacts': '今日联系人',
        'Contacts without Accounts': '没有帐户的联系人',
        'All records': '所有记录',
        'Added this Month': '汇总该月',
        'Added this Week': '汇总该周',
        'My Opportunities': '我的商业机会',
        'Opportunities': '商机',
        'Accounts': '帐户',
        'Accounts without Contact': '没有联系的帐户',
        'Accounts without Groups': '没有组的帐户',
        'My Accounts': '我的帐户',
        'Todays Accounts': '今日账户',
        'Cases': '案例',
        'Projects': '项目',
        'Issues': '问题',
        'Accounts without Contacts': '没有联系人的帐户',
    },
    SOURCE: {
        SOURCE_NAME: '源名称',
        PRIMARY_SOURCE:'主要来源',
        SECONDARY_SOURCE:'次要来源',
    },
    INXPRESS_REPORT: {
        COUNTRY_SELECT: '选择国家/地区',
        FRANCHISE_SELECT: '选择特许经营商',
        WORKFLOW_SELECT: '选择工作流',
        START_DATE: '开始日期',
        NO_RECORDS_FOUND: '找不到记录。',
        'Account': '帐户',
        // 'Company Name': 'Company Name',
        'Sales Rep': '销售代表',
        'Workflow': '流程',
        'Status': '状态',
        REGION_SELECT: '选择区域',
    },
    KANBAN_REPORT: {
        WORKFLOW: '流程',
        USER: '用户',
        DURATION: '持续时间',
        SORT_BY: '排序依据',
        REPORT_BY: '报告自',
        'Forecast amount': '预测金额',
        'Close date': '关闭日期',
        'Sales rep': '销售代表',
        'Status': '状态',
        'Convert': '转换',
        'Edit': '编辑',
        'Delete': '删除',
        'opportunities': '商机',
        'Probability': '概率',
    },
    APP: {
        CURRENT: '我会使用新版本',
        LEGACY: '我会使用旧版本',
        HEADING: '请选择 Soffront 版本',
        SUB_TITLE: '您的组织可以访问我们的旧版本。您想使用它而不是新版本吗？'
    },
    OTHER_VIEW: {
        SUBSCRIBE_LABEL: '订阅'
    },
    MY_INTEGRATION: {
        CONNECTING: '连接',
        DISCONNECTING: '断开',
        SYNC_EMAIL: '同步电子邮件',
        SYNC_CONTACT: '同步联系人',
        SYNC_CALENDAR: '同步日历',
        APPEND_COMPANY: '追加公司名称',
        APPEND_CONTACT: '追加联系人姓名',
        CALENDAR: '日历',
        SERVICE: '服务',
        INTEGRATION_HEADER: '电子邮件和日历集成',
        PHONE_INTEGRATION: '电话集成',
        MY_INTEGRATIONS: '集成',
        CONTACT_FILTER: '联系人筛选器',
        RINGCENTRAL_HEADER: '呼叫中心',
        SMS_INTEGRATION: '短信',
        QUICKBOOKS: 'QuickBooks',
        XERO:'Xero',
        INTEGRATE:'整合',
        SELECT_INTEGRATION_ITEMS:'选择要集成的项目',
        INVOICES:'发票',
        PAYMENTS:'付款',
        MANNUALLY:'手动（CRM > Xero）',
        AUTOMATICALLY:'自动（Xero > CRM）',
        ACCOUNTING_INTEGRATION_LABEL: '与',
        ACCOUNTING_INTEGRATION: '会计整合',
        QUICKBOOKS_INTEGRATION: 'QuickBooks 集成',
        EVENTBRITE_INTEGRATION: 'Eventbrite 集成',
        PAYMENT_INTEGRATION: '支付整合',
        SELECT_A: '选择一个',
        CRM_CONTACT_FILTER_SELECT: '选择 CRM 联系人筛选器',
        CONTACT_GROUP_NOT_FIND: '找不到联系人组。请点击',
        HERE: '这里',
        TO_TRY_AGAIN: '再试一次。',
        CONTACT_FILTER_NOT_FIND: '找不到任何联系人筛选器。',
        SEND_SALES_EMAILS_USING: '使用发送销售电子邮件',
    },
    PROFILE_DETAILS: {
        PROFILE_DETAILS_LABEL: '轮廓',
        PERSONAL_INFORMATION: '个人信息',
        USERNAME_LABEL: '这将作为用户名。',
        CHANGE_PASSWORD: '更改密码',
        MANAGE_NOTIFICATIONS: '管理通知',
        EMAIL_SIGNATURE: '电子邮件签名',
        TASKS: '任务',
        APPOINTMENTS: '预约',
        TASK_EMAIL: '任务电子邮件',
        TASK_POPUP: '任务弹出窗口',
        APPOINTMENT_EMAIL: '预约电邮',
        APPOINTMENT_POPUP: '预约弹出窗口',
        ON: '打开',
        OFF: '关闭',
        CHANGE_PHOTO: '更改照片',
        NEW_PASSWORD: '新密码',
        CONFIRM_PASSWORD: '确认新密码',
        NAME_REQUIRED: '名字(必填)',
        EMAIL_REQUIRED: '电子邮件 (必填)',
        PHONE: '电话',
        TIME_ZONE_REQUIRED: '时区 (必需)',
        DATE_FORMAT: '日期格式',
        LANGUAGE: '语言',
        USER_COLOR: '用户颜色',
    },

    DETAIL_VIEW_CHILD_NOTES: {
        ALL: '所有',
        USER: '用户',
        SYSTEM: '系统',
        MY: '我',
        ATTACHMENT_INFO: '附件信息',
    },
    DOCUSIGN: {
        CONNECT_BUTTON: '连接',
        SAVE_BUTTON: '保存',
        CANCEL_BUTTON: '取消',
        USERNAME: '用户名 (必填)',
        PASSWORD: '密码 (必填)',
        INTREGATOR_KEY: '集成密钥 (必需)',
        SELECT_TEMPLATE: '选择模板',
        SEND: '发送',
        DISCONNECT_BUTTON: '断开',
        DOCUSIGN_SETUP_HEADER: 'DocuSign 集成',
        DOCUSIGN_CONNECT_TITLE: '连接到您的 DocuSign 帐户',
        ADD_NOTE: '添加备注 (可选)。',
    },
    TWILIO: {
        CALL: '呼叫',
        VERIFY: '验证',
        DISCONNECT: '断开',
        SHOW_SCRIPTS: '显示脚本',
        HIDE_SCRIPTS: '隐藏脚本',
        CALL_SETUP: '呼叫设置',
        CALL_SCRIPT: '调用脚本',
        CALL_SCRIPT_COPY_TAB_LABEL: '复制调用脚本',
        COPY_OF: '备份',
        ADD_NUMBERS: '添加呼出电话号码',
        OUTBOUND_PHONE_NUMBERS: '呼出电话号码',
        ADD_ANOTHER_NUMBER: '添加另一个号码',
        CALL_RECORDING: '通话记录',
        PHONE_CREDITS: '您的手机余额',
        PURCHASE_CREDITS_YOU_HAVE: '你有',
        PURCHASE_CREDITS_MINUTES_LEFT: '还剩几分钟',
        PURCHASE_CREDITS_MORE: '购买更多时长。',
        CALLING: '呼叫',
        RECORD_ALL_CALL: '记录所有呼叫',
        VERIFIED: '验证',
        CLICK_TO_CALL: '我已经准备好接受电话了',
        ADDITIONAL_INFO: '顯示附加信息',
        ADDITIONAL_INFO_TITLE: '附加信息',
        SELECT_FIELD:'选择字段（最多 3 个字段）'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: '脚本名称 (必需)',
        ADD_DETAILS: '脚本详细信息 (必需)',
        WORKFLOW_NAME: '工作流名称',
        CALL_SCRIPTS_EDIT_TAB_LABEL: '编辑呼叫脚本',
    },
    TWILIO_SMS: {
        ZIP_CODE: '邮政编码',
        AREA_CODE: '区号',
        ADD_TO_ACCOUNT: '加',
        SMS_CREDITS: '您的短信余额',
        SMS_CREDITS_LEFT: '剩余短信额度。',
        SEND: '发送',
        NO_NUMBER_PURCHASED: '你还没电话号码，使用 "查找" 选项购买电话号码。购买后, 您将能够使用该号码对外发送消息。',
        FIND_PHONE_NUMBER: '按邮政编码或电话区号查找电话号码',
        FIND_PHONE_NUMBER_BY_AREA_CODE: '按区号查找电话号码',
        SEND_SMS: '发送文本消息',
        YOUR_MESSAGE: '您的消息',
        OR: '或',
        PHONE_NUMBER: '电话号码',
        PHONE: '电话',
        AVAILABLE_TO: '可用于',
        USER_NAME: '用户名',
        USED_BY: '使用者',
        UNIT_NAME: '单位名称',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: '连接',
        LABEL: '绑定您的 QuickBooks 在线帐户'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: '所有布局',
        LEFT_SIDEBAR_LAYOUT: '左侧边栏布局',
        RIGHT_SIDEBAR_LAYOUT: '右侧边栏布局',
        HORIZONTAL_LAYOUT: '水平布局',
        MULTIPLE_COLUMN_LAYOUT: '多列布局'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: '连接',
        SELECT_ORGANISATIONS: '同步来自该组织的事件',
        EVENTBRITE_CONNECT_TITLE: '绑定您的Eventbrite账户',
        SELECT: '选择',
        EVENTBRITE_TOGGLE_TITLE: '在同步过程中, 如果在 CRM 中找不到则作为新联系人添加',
        EVENTBRITE_MAP_FIELDS: '映射字段',
        CRM_FIELDS_TITLE: 'CRM 联系人字段',
        EVENTBRITE_FIELDS_TITLE: 'Eventbrite字段',
    },
    SIDE_NAV: {
        APPOINTMENTS: '日历/任务',
        DASHBOARD: '仪表板',
        INBOX :'收件箱',
        COMPLIANCE:'遵守',
        REPORTS: '报告',
        TEMPLATES:'模板',
        PRODUCTS:'产品',
        ORDERS:'命令',
        JOBS:'工作',
        'LEAD CAPTURE':'捕获线索',
        Opportunitie:'管道',
        COUPONS:'优惠券',
        'EMAIL MARKETING':'电子邮件营销',
        'LISTING/REVIEWS':'列表/评论',
        'LIBRARY - MARKETING':'图书馆 - 营销',
        ROYALTY:'特许权使用费',
        HELPDESK:'帮助台',
        SCHEDULER:'调度程序',
        PIPELINE:'管道',
        POS:'采购订单',
        SOS:'求救',
        'CUSTOM TABLE 3':'定制表3',
        INVENTORY:'',
        SALES_TEMPLATES: '销售电子邮件模板',
        SOCIAL_MARKETING: '关系营销',
        WEBSITE_TRACKING: '网站分析',
        SOLUTIONS: '解决方案',
        CONTACTS: '联系人',
        ACCOUNTS: '帐户',
        OPPORTUNITIES: '商机',
        CASES: '案例',
        PROJECTS: '项目',
        ISSUES: '问题',
        ONBOARDING: '上门服务',
        UNITS: '单位',
        ASSESTS: '资产',
        OPERATIONS_ACTIVITIES: '活动',
        OPERATIONS_CONVERSIONS: '转换',
        OPERATIONS_CAMPAIGNS: '广告活动',
        OPERATIONS_REPORTS: '报告',
        LEAD_CAPTURE: '铅捕获',
        EMAIL_MARKETING: '电子邮件营销',
        'CALENDAR/TASKS': '日历/任务',
        'SALES REPORTS': '报告',
        'SALES TEMPLATES': '销售模板',
        'SOCIAL MARKETING': '社会营销',
        'ONLINE REPUTATION': '在线声誉',
        DASHBOARDS: '仪表板',
        'SALES LEADS': '销售线索',
        'USEFUL LINKS': '有用的链接',
        HUB: '枢纽',
        LIBRARY: '图书馆',
        COMMUNITY: '社区',
        TRAINING: '训练',
        NEWS: '新闻',
        SCROLL_TO_RIGHT: '向右滚动',
        SCROLL_TO_LEFT: '向左滚动',
    },
    NO_RECORD: {
        'Solutions': '解决方案',
        'Appointments': '预约',
        'Tasks': '任务',
        'Groups': '组',
        'Notes': '日志',
        'Attachments': '附件',
        'Marketing-templates': '市场营销-模板',
        'Sales-templates': '销售-模板',
        'Web-forms': 'Web 表单',
        'Products': '产品',
        'Broadcast-campaigns': '广播活动',
        'Ab-campaigns': 'Ab 营销活动',
        'Automation-designer': '自动化-设计器',
        'Projects': '项目',
        'Invoices': '发票',
        'Iouch-campaigns': '自动触发营销活动',
        'Events': '事件',
        'Sms-templates': '短信模板',

        'Emails': '电子邮件',
        'Relationships': '关系',
        'Templates': '模板',

        'Cases': '案例',
        'Contacts': '联系人',
        'Accounts': '帐户',
        'Opportunities': '商机',
        'Issues': '问题',
        'Salesleads': '销售线索',
        'Source': '资源',
        'Users': '用户',
        'Securitylevel': '安全级别',
        'Workflows': '工作流程',
        'Relations': '关系',
        'Notetypes': '注意类型',
        'Campaigntypes': '活动类型',
        'Emailintegration': '電子郵件別名',
        'Library': '图书馆',
        'Recyclebin': '帐户',
        'Sms': '短信',
        'Assignmentrules': '作业规则',
        'Units': '单位',
        'Orderitems': '订购项目',
        'Claims': '索偿',
        'Payments': '付款',

    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: '报告时间表',
        DAY_OF_THE_MONTH: '月份中的某一天',
        DAY_OF_THE_WEEK: '一周中的某一天',
        REPEAT_TYPE: '重复类型 (必需)',
        REPEAT_ON: '重复于',
        REPEAT_BY: '重复',
        START_DATE: '开始日期 (必填)',
        END_DATE: '结束日期',
        REPEAT_EVERY: '重复每个 (必需)',
        SEND_TO: '发送至 (必填)',
        SCHEDULE: '计划',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': '名称',
            'Status': '状态',
            'Created By': '由...制作',
            'Created On': '创建于',
            'Updated By': '更新者',
            'Updated On': '更新了',
        },
        APPOINTMENT: {
            'Subject': '主题',
            'Company': '公司',
            'Status': '状态',
            'Contact': '联系',
            'Contact Name': '联系人姓名',
            'Start date': '开始日期',
            'End date': '结束日期',
            'Assign to': '分配给',
            'Visible to': '可见',
            'Outlook Sync': 'Outlook同步',
            'Google Sync': 'Google Sync',
            'Text': '文本',
            'Creator': '创造者',
            'Created on': '创建于',
            'Updated by': '更新者',
            'Updated on': '更新了',
        },
        TASKS: {
            'Task Name': '任务名称',
            'Company': '公司',
            'Status': '状态',
            'Previous Task': '上一个任务',
            'Complete Date': '完成日期',
            'Owner': '所有者',
            'Due By': '到期',
            'Contact Name': '联系人姓名',
            'Description': '描述',
            'Updated on': '更新了',
            'Contact': '联系',
            'Start Time': '开始时间',
            'End Time': '时间结束',
            'Item': '项目',
            'Billable': '计费',
        },
        BROADCAST: {
            'Broadcast Name': '广播名称',
            'Status': '状态',
            'Run Date': '运行日期',
            'Owner': '业主',
            'Created On': '创建于',
            'Updated On': '更新了',
            'Target': '目标',
            'From email': '来自电邮',
            'From name': '来自姓名',
            'Updated By': '更新者',
            'Created By': '创建者'
        },

        AB_CAMPAIGN: {
            'Campaign Name': '营销活动名称',
            'Created By': '创建者',
            'Created On': '创建于',
            'Run Date': '运行日期',
            'Status': '状态',
            'Target Filter': '目标过滤器',
            'Updated By': '更新者',
            'Updated On': '更新了'
        },
        SALES_TEMPLATE: {
            'Name': '名字',
            'Category': '类别',
            'Subject': '主题',
            'Status': '状态',
            'Owner': '所有者',
            'Created On': '创建于',
            'Updated By': '更新者',
            'Updated On': '更新时间',
        },
        MARKETING_TEMPLATE: {
            'Name': '名字',
            'Category': '类别',
            'Subject': '主题',
            'Status': '状态',
            'Owner': '所有者',
            'Created On': '创建于',
            'Updated By': '更新者',
            'Updated On': '更新时间',
        },
        WEB_FORM: {
            'Name': '名字',
            'Table Name': '表名',
            'Group': '组',
            'Source': '来源',
            'Status': '状态',
            'Assign To': '分配给',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': '营销活动名称',
            'Status': '状态',
            'Owner': '所有者',
            'Target': '目标',
            'From email': '从电子邮件',
            'Created On': '创建于',
            'Created By': '创建者',
            'From name': '从名称',
            'Updated On': '更新时间',
            'Updated By': '更新者',
            'Resend All': '重新发送所有',
            'Resend After': '重新发送间隔',
        },
        ACCOUNTS: {
            'Status': '状态',
        },
        CONTACTS: {
            'Status': '状态',
        },
        OPPORTUNITIES: {
            'Status': '状态',
        },
        PROJECTS: {
            'Status': '状态',
        },
        ISSUES: {
            'Status': '状态',
        },
        CASES: {
            'Status': '状态',
        },
        SOLUTIONS: {
            'Status': '状态',
        },
        SALES_LEADS: {
            'Status': '状态',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': '约定',
        'Dialed': '拨',
        'Email': '电子邮件',
        'LVM': 'LVM',
        'Note': '日志',
        'Spoke': '辐',
        'Task': '任务',
        'Transferred': '转移',
    },
    VIEW_TOGGLE: {
        LIST: '名单',
        COLUMN: '列',
        LIST_TITLE: '切换到列表视图',
        COLUMN_TITLE: '切换到列视图',
        TIMELINE: '时间表',
        TIMELINE_TITLE: '切换到时间线视图',
        DETAILS: '细节',
        DETAILS_TITLE: '切换到详细信息视图',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: '电子邮件营销报告',
        MY_CAMPAIGNS: '我的广告系列',
        MY_LOCATION_CAMPAIGNS: '我的单位的活动',
        RECENT_CAMPAIGNS: '最近的广告系列',
        BROADCAST_REPORT: '广播活动报告',
        TOUCH_REPORT: '触摸市场活动报告'
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: '推送到',
        DOCUMENT_NOT_FOUND: '找不到文件。'
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: '申请新报告'

    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: '电子邮件广告系列',
        BROADCAST_REPORT: '广播活动',
        TOUCH_REPORT: '触摸广告系列'
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: '任务报告'
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: '電郵報告',
        ERROR_REASON: '原因'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: '短信报告'
    },
    BATCH_SMS: {
        MESSAGE: '你的信息',
        SEND: '发送',
        CHARS: ' 字符',
        SCHEDULE: '时间表',
        SCHEDULE_SMS: '安排短信',
        SEND_LATER: '稍后发送'
    },
    SETTING_MENU: {
        MARKETING: '营销',
        SECURITY: '用户和安全',
        DATA: '数据',
        CUSTOMIZATION: '定制',
        INTEGRATION: '积分',
        COMPANY: '公司',
        WORKFLOW_RULES:'工作流程和规则',
        LISTS: '列表',
        FRANCHISE: '特许经营',
        COMPANY_SCHEDULER: '公司调度员'
    },
    MARKETING_SETTING: {
        'SOURCE': '来源',
        'LIBRARY': '图书馆',
        'LEAD_SCORE': '领先分数',
    },
    SECURITY_SETTING: {
        'USERS': '用户',
        'SECURITY_LEVELS': '安全级别'
    },
    DATA_SETTING: {
        'EXPORT': '出口',
        'RECYCLE_BIN': '回收站',
        'FIND_DUPLICATES': '查找重复项'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': '更改徽标',
        'DATA_BASE': '数据库',
        'WORKFLOWS': '工作流程',
        'RELATIONS': '关系',
        'AUTO_FILL_VALUES': '自动填充值',
        'NOTE_TYPES': '任务和注释类型',
        'CAMPAIGN_TYPES': '活动类型',
        'CORPORATE_CURRENCY': '公司货币',
        'HUB_SETTING': '中心',
        'COMPANY_PROFILE': '公司简介',
        'WHITE_LABEL': '白色标签',
        'MENU': '菜单',
        'DESKTOP_MENU': '桌面菜单',
        'MOBILE_MENU': '移动菜单',
        'PHONE_NUMBERS': '电话号码',
        'DESIGN_MENUS': '设计菜单',
        'MENU_NAME': '菜单名称',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook（桌面）',
        'GENERATE_API': '生成API',
        'EMAIL_INTEGRATION': '电子邮件集成'
    },
    SETTINGS: {
        ADDED: '添加',
        EDIT: '更新',
        SUCCESSFULL: '顺利。',
        'SOURCE': '资源',
        'NOTETYPES': '任务和注释类型',
        'CAMPAIGNTYPES': '广告系列类型',
        'CORPORATECURRENCY': '公司货币',
        'RELATIONS': '关系',
        'LIBRARY': '图书馆',
        'USERS': '用户',
        'SECURITYLEVEL': '安全级别',
        'WORKFLOWS': '工作流程',
        'EMAILINTEGRATION': '電子郵件別名',
        'RECYCLEBIN': '回收站',
        'ASSIGNMENTRULES': '分配规则',
        'APPOINTMENTTYPES': '约会类型',
        'ROLLUP': '汇总规则',
        'SUBSCRIPTION': '订阅',
        'UPGRADE': '升级认购',
        'BUYPRODUCT': '购买产品与培训',
        'BUYPROSERVICE': '购买专业服务',
        'BILLING': '开票和发票明细',
        'CANCELSUB': '取消订阅',
        'SUBDETAIL': '订阅详细信息',
        'PURCHESEMAILCREDIT': '购买电子邮件学分',
        'PURCHESPHONECREDIT': '购买手机学分',
        'PURCHESSMSCREDIT': '购买短信学分',
        'PURCHESCARDCREDIT': '购买卡扫描积分',
        'PURCHESCONTACTCREDIT': '购买更多联系方式',
        'PURCHESTRANINGCREDIT': '购买培训',
        'PURCHESMERKETINGSERVICE': '购买营销服务',
        'PURCHESPROFESSONALSERVICE': '购买专业服务',
        'SETUP': '我的设置',
        SHOW_LOGO: '显示徽标',
        SHOW_SIGNOUT_LINK: '显示注销链接',
        THEME_COLOR:'主题色',
        LOGO_COLOR :'标志背景',        
        AUDIT_QUESTIONS: '审核清单',
        LMS_USERS: '学习管理系统用户',
        AUDIT_CHECKLISTS:'审计清单',
        WEBHOOKS: '网络钩子',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: '选择公司货币'
    },
    UNSUBSCRIBE: {
        EMAILS: '电子邮件',
        TEXTS: '文本',
        BOTHS: '都'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: '中心名称',
        USEFUL_LINK: '有用的链接',
        CASES: '案例',
        SETUP_NAME: 'HUB设置',
        NEWS: '新闻',
        LIBRARY: '图书馆',
        COMMUNITY: '社区',
        TRAINING: '训练',
        HYPER_LINK: '超链接',
        SET: '组',
    },
    UNITS: {
        TASK_DETAILS: '任务细节',
        'Unit': '单位',
        'Contact Name': '联系人姓名',
        'Done': '完成',
        'Open': '打开',
        'Tasks Name': '任务名称',
        'Assigned To': '分配给',
        'Due By': '到期',
        'Status': '状态',
        UNIT_DETAILS_LABEL: '單位詳情',
        FIRST_NAME: '名（必填）',
        LAST_NAME: '姓（必填）',
        PASSWORD: '密碼 (需要)',
        RETYPE_PASSWORD: '重新輸入密碼（必填）',
        EMAIL: '電子郵件（必填）',
        PHONE: '電話（必填）',
        COMPANY: '公司（必填）',
        TIME_ZONE_SELECT: '時區',
        COUNTRY_SELECT: '國家',
        STATE_SELECT: '州',
        NO_UNIT_TASKS: '单位所有者没有可用的任务。',
        NO_ACCOUNT_TASKS: '帐户没有可用的任务。'
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: '设置您的第一条规则',
        ASSIGNMENT_RULE: '分配规则',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: '创建分配规则',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: '编辑分配规则',
        NAME_REQUIRED: '名称 (必填)',
        ASSIGNMENT_RULES: '分配规则',
        STATUS: '地位',
        SETUP_RULE: '设置分配规则',
        USE_RULE: '使用分配规则',
        TABLE_NAME: '表名',
        SELECT_UNIT_WORKFLOW: '选择单位工作流程',
        WORKFLOW_NAME: '工作流程名称',
    },
    COUPONS: {
        PREFIX: '字首',
        'Coupon Name': '优惠券名称',
        'Discount Value': '折扣价值',
        EXPIRATION_DATE: '截止日期',
        PODUCT_SERVICE: '产品/服务（可选）',

    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: '优惠券索赔报告',
        COUPONS_REPORT: '報告'
    },
    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: '管道报告',
        SALES_SUMMARY_REPORT: '销售摘要报告'
    },
    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: '警报和通知',
        NOTIFY_ME: '通知我何时...',
        REMIND_ME: '之前提醒我...',
        NOTIFY_EMAIL_OPEN: '我的电子邮件已打开',
        NOTIFY_EMAIL_CLICK: '我的电子邮件被点击',
        NOTIFY_TEXT_REPLY: '我的文本已回复',
        REMIND_UPCOMING_APPOINTMENT: '即将进行的约会',
        REMIND_UPCOMING_TASK: '即将完成的任务',
        ADD_NEW_REMINDER: '添加新提醒',
        REMIND: '提醒',
        DAY_BEFORE: '前一天',
        MY_WEB_FORM_SUBMITTTED: '我的 Web 表单已提交',
        NO_DATE_FIELD: '没有可用日期字段',
    },
    EMAIL_INTEGRATION: {
        EMAIL: '电子邮件别名',
    },
    NEWS: {
        HEADER: '新闻',
        STATUS: {
            DRAFT: '草案',
            PUBLISHED: '发布时间'
        },
        EDIT_TAB_LABEL: '编辑新闻',
        ADD_TAB_LABEL: '添加新闻',
        CATEGORY_REQUIRED: '类别（必填）',
        STATUS_REQUIRED: '状态（必填）',
        TITLE_REQUIRED: '标题（必填）',
        SUMMARY_REQUIRED: '摘要（必填）',
        EXTERNAL_LINK_REQUIRED: '外部链接（必填）',
        UPLOAD_IMAGE: '上传图片',
        EXTERNAL_LINK: '外部链接',
        ENTER_DETAILS: '输入细节'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: '有用的链接',
        CATEGORY: '类别',
        LINK: '链接',
        TITLE: '标题',
        NO_RECORD_FOUND: '未找到有用的链接。',
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: '矯正報告'
    },
    REPORTS: {
        NO_REPORTS_FOUND: '未找到报告。',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: '选择家长',
        SELECT_CHILD: '选择一个孩子',
        PARENT_FIELDS: '父对象字段',
        CHILD_FIELDS: '子对象字段',
    },
    LANDING_PAGE: {
        LANDING_PAGE: '登陆页面',
        LANDING_PAGES: '登陆页面',
        NAME_REQUIRED: '名称（必填）',
        URL_REQUIRED: '网址（必填）',
        LANDING_PAGE_FORMS: '表单生成器',
        EDIT_LANDING_PAGE: '编辑登陆页面',
        COPY_LANDING_PAGE: '复制着陆页'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: '查找重复项',
        SELECT_OBJECT: '选择对象',
        SELECT_FIELD: '选择字段',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: '预约计划人',
        APPOINTMENT_INFO: '预约信息',
        AVAILABILITY:'可用性',
        APPOINTMENT_TYPES:'预约类型',
        MY_AVAILABILITY: '我的可用性',
        SCHEDULER_LINK: '调度程序链接',
        INTAKE_FORM: '取走表格',
        APPOINTMENT_NAME: '预约名称（必需）',
        LOCATION: '位置',
        TASKS:'任务',
        UNIT_CRM:'单位 Crm',
        NOTIFICATION_SETUP:'通知设置',
        DURATION: '时间',
        MIN_ADVANCE_NOTICE: '最低预支通知 ',
        DESCRIPTION: '描述',
        COPY_TO_CLIPBOARD: '复制到剪贴板',
        SCHEDULER_LINK_TITLE: '我的约会计划员链接',
        SCHEDULING_PAGE: '预约时间表',
        ADD_BUTTON: '添加新计划',
        MY_SCHEDULAR_LINK: '我的日程安排链接',
        MY_APPOINTMENT_TYPE: '我的预约类型',
        APPOINTMENT_TYPE: '预约类型',
        DEFINE_YOUR_INTAKE_FORM: '定义您的入学表格',
        REQUIRED: '需要',
        ADD_ANOTHER_FIELD: '添加另一个字段',
        SUBMIT_BUTTON_LABEL: '提交按钮标签',
        CHOOSE_A_STATUS_FOR_LEAD: '选择从计划程序添加的销售线索的状态',
        DEFINE_YOUR_THANK_YOU_MESSAGE: '定义您的感谢信息',
        THANK_YOU_MESSAGE: '谢谢留言',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: '关系矩阵',
        REPORT_SAVE_LABEL: '保存自定義',
        REPORT_NAME_LABEL: '自定義報告名稱',
        REPORT_NAME_TEXTFIELD_LABEL: '報告名稱',
        ROW_FIELD_LABEL: '行字段',
        COL_FIELD_LABEL: '欄位欄位',
        DATE_FIELD_LABEL: '日期欄',
        PDF_LABEL_DURATION: '持續時間',
        RELATION_FIELD_LABEL: '關係',
        SAVE_CUSTOMIZATION_LABEL: '保存自定義保存自定義',
        RUN_BUTTON_LABEL: '跑'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: '上传您的徽标',
        WEBSITE: '网站',
        BUSINESS_NAME: '企业名称',
        BUSINESS_ADDRESS: '企业地址',
        BUSINESS_PHONE: '商务电话',
        BUSINESS_EMAIL: '商务电子邮件',
        CORPORATE_CURRENCY: '公司货币'
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: '出口',
        FILE_NAME_REQUIRED: '文件名（必填）',
        PARENT_OBJECT_REQUIRED: '父对象名称（必填）',
        FILE_TYPE_REQUIRED: '文件类型（必填）',
        QUERY_NAME_REQUIRED: '查询名称（必填）',
        SELECTED_FIELDS_FOR_PARENT: '父项的选定字段',
        AVAILABLE_FIELDS_FOR_PARENT: '父项的可用字段',
        CHILD_OBJECT_NAME: '子对象名称',
        AVAILABLE_FIELDS_FOR_CHILD: '儿童可用栏位',
        SELECTED_FIELDS_FOR_CHILD: '儿童的选定字段',
        EXPORT_BUTTON: '出口',
        DOWNLOAD_BUTTON: '下载文件',
        ADD: '加',
        ADD_ALL: '都加进去',
        REMOVE: '去掉',
        REMOVE_ALL: '移除所有'
    },
    QUEUE: {
        QUEUE: '队列',
        PREVIOUS: '队列中的上一个',
        NEXT: '队列中的下一个',
        REMOVE: '从队列中删除',
        CLOSE: '退出队列',
    },
    SECURITY_LEVEL: {
        ACCESS: '访问',
        DESCRIPTION: '描述',
        VIEW: '视图',
        PERMISSIONS: '权限',
        OPERATIONS: '运作方式',
        ADMINISTRATION: '行政',
        ASSIGNED_MENU: '分配菜单'
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: '子对象',
        PARENT_OBJECT_NAME: '父对象',
        CHILD_FIELD_NAME: '子字段名称',
        PARENT_FIELD_NAME: '父字段名称',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: '版税报告',
        ROYALTY_PERCENT: '版税百分比',
        SHOW: '显示',
        ALL_INVOICES: '所有发票',
        PAID_INVOICE_ONLY: '仅付费发票',
        SETUP_TITLE: '版税设置',
        PAID_INVOICES: '已付发票',
        USE: '使用',
        FIXED: '固定百分比',
        VARIABLE: '可变百分比',
        TOTAL_INVOICES: '总发票',
        TOTAL_PAYMENTS: '总付款',
        CALCULATE: '计算',
        WEEKLY: '每周',
        MONTHLY: '每月',
        WEEK_TYPE: '周类型',
        MON_TO_FRI: '周一星期一',
        MON_TO_SUN: '周一太阳',
        ADD_INVOICES: '添加发票',
        TOTAL_Reported_Sales: '总报告的销售额',
        YEAR: '年',
        MARKETING_FEE: '营销费',
        TECH_FEE: '技术费用',
        PERCENT: '百分比',
        FIXED_VALUE: '固定的',
        WITH: '费用',
        WITH_OUT: '没有费用',
        Reported_Sales_Franchisee: '报告的销售额 - 特许经营商',
        Reported_Sales_Franchisor: '报告的销售额 - 特许人',
        Reported_Sales_ThirdParty: '报告的销售额 - 第三方',
    },
    POWER_CALLING: {
      NOTE_AND_CALL_SCRIPTS: '注释和通话脚本',
      SEND: '发送',
      LOG_CALL:'通话记录',
      CALLER_ID: '来电显示',
      END_CALL: '结束通话',
      MOBILE: '移动的',
      PHONE: '电话',
      HOME_PHONE:'家庭电话',
      NEXT: '下一个',
      COMPLETE: '完全的',
      OF: '的',
      POWER_DIALER: '电源拨号器'
    },
    LEAD_SCORE_SETUP:{ 
        SCORES: '分数',
        NO_SCORES_AVAILABLE: '没有可用的动作。',
        OBJECT: '目的',
        GROUPS:'团体',
        WORKFLOW_NAME: '工作流程名称',
        CONVERTED_FROM:'转换自',
        CONVERTED_TO: '转换成',
        FIELDS_NAME: '栏位名称',
        POINTS: '点数',
        EXPIRES_BY: '到期日',
        APPLY_TO_EXISTING_SCORE: '*有效期将追溯适用于您现有的分数。',
        GROUP_ALREADY_REMOVE: '组已被删除。 保存前请进行修改。',
        FIELD_ALREADY_REMOVE: '字段已删除。 保存前请进行修改。',
        FIELD_VALUE_ALREADY_REMOVE:'字段值已删除。 保存前请进行修改。',
        AND_ABOVE: '以上',
        LESS_THAN: '少于',
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: '重复规则',
        DUPLICATE_RULE_HEAD_LABEL: '检查重复使用',
        SELECT_OBJECT_LABEL: '选择对象',
        ACTIVE: '积极的',
        DEFAULT: '默认',
        ONE_FIELD: '一个场',
        TWO_FIELD: '两个字段',
        THREE_FIELD: '三个字段',
        FIELD_ONE: '字段1',
        FIELD_TWO: '现场2',
        FIELD_THREE: '现场3',
        SELECT_FIELD_LABEL: '选择字段',
        CHECK_DUPLICATES: '重复检查',
        SELECT_RULES: '选择规则',
    },
    JOBS:{
        RESCHEDULE: '改期',
        OMW:'奥姆',
        INVOICE:'发票',
        PAY:'支付'
    },
    TASK_REPORT:{
        TITLE:'任务报告',
        UNITS:'单位',
        NO_TASK_FOUND:'未找到任何任务',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'任务报告详细信息',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: '在这个问题中至少应该有2个选择',
        SINGLE_RECORD: '只允许一次记录一次。',
        AUDIT_QUESTIONS: "审核清单",
        GIVE_FEEDBACK:'给予反馈',
        NEW_QUESTION: '新问题',
        TEXT: '短篇文章',
        COMMENT: '长篇文章',
        LIST: '单项选择（下拉）',
        CHECKBOX: '多项选择（复选框）',
        QUESTION: '题',
        ADD_NEW: '添新',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: '请选择1条编辑。',
        MESSAGE_DELETE: '你确定要删除吗？',
        SELECTED_RECORDS: '选择的记录？',
        YOUR_ANSWER: "你的答案",
        SUBMITTING: '提交......',
        SAVE_CATEGORY:'保存类别',
        SUBMIT_AUDIT:'提交审核',
        SUBMIT: '提交',
        SCORE: '分数',
        REQUIRED: '必需的',
        CHOICE: '选择',
        FIELD_REQUIRED: '此字段是必需的 *',
        NEW_CHOICE: '新选择',
        FREE_FORM: '自由形式',
        SCORE_HELP_TOOLTIP: "分配点或分数以答案。分数将加起来计算审计的总分数。",
        SURVEY_LINK: "审计/调查链接",
        NEXT_CATEGORY:'下一分类',
        GENERATE:'产生',
        FIELD_AUDIT:'现场审核（由特许人审核）',
        SELF_AUDIT:'自我审计（由加盟商审计）',
        GENERATE_AUDIT_LINK:'选择清单',
        REQUIRES_PHOTO: '需要照片',
        ENABLE_NOTE: '启用注释',
        ADD_PHOTO: '照片',
        UPDATE_PHOTO: '更新',
        NOTE: '添加注释',
        PHOTO_REQUIRED: '需有照片 *',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: '审计答案',
        QUESTION:'题',
        SCORE: '分数',
        ANSWER: '回答',
        SUBMIT_CORRECTIVE_ACTIONS:'提交纠正措施',
        REQUEST_CORRECTIVE_ACTIONS:'请求采取纠正措施',
        INSERT_PHOTO:'插入照片',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'类别',
        MIN_FAILURE_COUNT:'最小失败次数',
    },
    AUDIT_CATEGORY:{
        ADD_NEW_CATEGORY:'添加新类别',
        MANAGE_CATEGORY: '管理类别',
        CATEGORIES:'类别',
        CATEGORY_QUESTIONS_LABLE:' 分类问题',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: '合规报告',
    },
    SALES_AND_REFUND: {
        PERIOD:'时期',
        START_DATE:'开始日期',
        END_DATE :'结束日期',
        BASED_ON :'基于',
        SALES_AND_REFUND_TITLE:'销售及退款',
        SALES_AND_REFUND_DETAIL:'销售和退款详情',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'销售和退款报告详细信息',
        START_DATE:'开始日期',
        END_DATE:'结束日期',
        BASED_ON:'基于',
        NO_RECORD_EXPORT: '没有找到记录'
    },
    AUDIT_CHECKLISTS:{
        TITLE:'新的审计清单',
        EDIT_TITLE:'编辑审计清单',
    },
    UNIT_CRM:{
        MY_SETTINGS:'我的设置',
        COMPANY:'公司',
        USER_AND_SECURITY:'用户和安全',
        SURVEYS: '调查',
        WORKFLOW_RULES_SECTION:'工作流程和规则部分',
        EMAIL_ALIASES:'电子邮件别名',
        CUSTOMIZATION:'定制',
        LISTS:'列表',
        DATA:'数据',
        COMPANY_SCHEDULER:'公司调度员',
        FRANCHISE:'特许经营',
        INTEGRATION:'一体化',
        SUBSCRIPTION:'订阅',
        CAMPAIGNS:'活动',
        BOARDCAST_CAMPAIGNS :'广播活动',
        AUTOMATED_CAMPAIGNS:'自动化活动',
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'版税报告的销售细目',
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: '用于通知',
        REMOVE_NOTIFICATION_TOOLTIP: '删除通知',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: '管理单位的电话号码、短信积分和通话分钟数',
    },
}
export default label