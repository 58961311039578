import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import { styles } from "../../services/constants/styles";
import { ClickAwayListener } from "@mui/material";
import type { FC } from "react";
import _ from "lodash";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { getBooleanParam } from "../../services/helper/parameterVerifier";

const generateUniqueId = (length: number): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
const getArrayWithId = (arr: any[]) =>
  arr.map((el, index) => ({
    ...el,
    id: el.id || el.value || index,
  }));

const Autocomplete: FC<{
  label?: any;
  selectId: string;
  options: any[];
  getOptionLabel?: any;
  isClearable?: boolean;
  value: any;
  placeholder?: string | null;
  onChange: any;
  disabled?: any;
  style: any;
  labelProps: any;
  inputProps: any;
}> = React.memo(function Autocomplete({
  label,
  selectId,
  options,
  getOptionLabel,
  isClearable,
  value,
  placeholder,
  onChange,
  disabled = false,
  style = {},
  labelProps = { style: {} },
  inputProps = { style: { fontSize: 'inherit' } }
}) {
  const [optionList, setOptionList] = React.useState<object[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [searchString, setSearchString] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState("");
  const selectRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<any>(null);
  const open = Boolean(anchorEl);
  const autoCompleteRootRef = React.useRef<HTMLDivElement>(null);
  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);
  const popperRef = React.useRef<HTMLDivElement | null>(null);
  const autoCompleteBaseRef = React.useRef<HTMLDivElement | null>(null);

  const uniqueId = generateUniqueId(10);

  React.useLayoutEffect(() => {
    const autoFocus = getBooleanParam(inputProps?.autoFocus);
    if (autoFocus) {
      handleFocus();
      setAnchorEl(autoCompleteRootRef.current);
    }
  }, [inputProps?.autoFocus]);


  React.useEffect(() => {
    setDefaultValue(
      value
        ? getOptionLabel
          ? getOptionLabel(value)
          : value.label ?? value
        : ""
    );
    let _setOptionList = getArrayWithId(options);
    const _index = _setOptionList.findIndex((f) => f.value === value?.value);
    setSelectedIndex(_index);
    setOptionList([..._setOptionList]);
  }, [options, value]);

  const handleClose = () => {
    setInputValue("");
    setSearchString("");
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: any) => {
    try {
      if (inputRef) {
        let __value = item
          ? getOptionLabel
            ? getOptionLabel(item)
            : item.label ?? item
          : null;
        setDefaultValue(__value);
        handleBlur();
        handleClose();
        if (onChange) {
          onChange(inputRef, item);
        }
      }
    } catch (error) {
      console.error(
        "Error on autoComplete.tsx >> handleMenuItemClick(): " + error
      );
    }
  };

  const clearValue = (e: any) => {
    try {
      setDefaultValue("");
      setInputValue("");
      setSearchString("");
      onChange(e, null);
    } catch (error) {
      console.error("Error on autoComplete.tsx >> clearValue(): " + error);
    }
  };

  const togglePopper = React.useCallback(() => {
    if (disabled) return;
  
    if (anchorEl) {
      handleBlur();
      setAnchorEl(null);
    } else {
      handleFocus();
      setTimeout(() => setAnchorEl(autoCompleteBaseRef.current), 0);
    }
  }, [anchorEl, disabled]);
  
  
  const setSearchValue = (e: any) => {
    try {
      setSearchString(e.target.value);
    } catch (error) {
      console.error("Error on autoComplete.tsx >> setSearchValue(): " + error);
    }
  };

  const handleFocus = () => {
    if (disabled) {
      return;
    }
    inputRef.current?.focus();
    setIsFocused(true);
  };

  const handleBlur = () => {
    inputRef?.current?.blur();
    setIsFocused(false);
  };

  const focusOutSearchField = React.useCallback((e: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(e.target as Node) &&
      autoCompleteBaseRef.current &&
      !autoCompleteBaseRef.current.contains(e.target as Node)
    ) {
      handleBlur();
      handleClose();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousedown", focusOutSearchField);
    return () => document.removeEventListener("mousedown", focusOutSearchField);
  }, [focusOutSearchField]);

  React.useEffect(() => {
    // Attach the event listener when the component is mounted
    document.addEventListener("click", focusOutSearchField);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", focusOutSearchField);
    };
  }, []);

  const SearchForObjectsWithName = (items: any[], query: string) => {
    query = query.trim().toLowerCase();
    return items.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(query)
      )
    );
  };

  const setInputValue = (value: string) => {
    try {
      inputRef.current.value = value;
    } catch (error) {
      console.error("Error on autoComplete.tsx >> setInputValue(): " + error);
    }
  };


  const dataSearchList = React.useMemo(() => {
    let _dataSearchList: any = [...optionList];
    let _searchString = searchString;
    if (_searchString.replace(/\s/g, "") !== "") {
      _dataSearchList = SearchForObjectsWithName(
        _dataSearchList,
        _searchString
      );
    }
    return _dataSearchList;
  }, [optionList, searchString]);

  const handleKeyDown = (e: any) => {
    try {
      let _index = selectedIndex;
      let height = 40;
      if (e.keyCode === 38) {
        _index = _index - 1;
        if (_index < 0) {
          _index = dataSearchList.length;
        }
        setSelectedIndex(_index);
        height = _index * height;

        if (scrollableDivRef.current) {
          scrollableDivRef.current.scrollTop = height;
        }
      } else if (e.keyCode === 40) {
        _index = _index + 1;
        if (_index > dataSearchList.length) {
          _index = 0;
        }
        setSelectedIndex(_index);
        height = _index * height;
        if (scrollableDivRef.current) {
          scrollableDivRef.current.scrollTop = height;
        }
      } else if (e.keyCode === 13) {
        if (dataSearchList.length > 0) {
          let _value = dataSearchList[selectedIndex];
          if (_value) {
            handleMenuItemClick(_value);
          }
        }
      }
    } catch (error) {
      console.error("Error on autoComplete.tsx >> handleKeyDown(): " + error);
    }
  };

  let width = 100;
  let height = window.innerHeight;
  if (selectRef.current) {
    width = selectRef.current.offsetWidth;
  }
  height = window.innerHeight;
  const tempElement = selectRef.current;
  let pos = tempElement?.getBoundingClientRect();
  let eliminator = (pos?.y || 0) + (pos?.height || 0);
  let maxHeight = height - eliminator - 20;
  let heightFromTop = (pos?.y || 0) - 100;
  let charWidth = searchString.length * 8;
  charWidth = charWidth > 0 ? charWidth + 10 : 2;

  const buttonOuterLabelStyle = {
    backgroundColor: "#fefefe",
    height: 3,
    lineHeight: 0,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    fontWeight: 500,
    color: disabled ? "#aeaeae" : isFocused ? "#0b87ea" : isHovered ? "#000" : "#717171",
    position: "absolute",
    top: isFocused ? "-2px" : "-1px",
    left: "5px",
  };

  return (
    <div
      id={`auto-complete-${uniqueId}`}
      className="sf-auto-complete-root"
      ref={autoCompleteRootRef}
      style={Object.assign({ borderRadius: "4px" }, inputProps?.native ? { margin: 0 } : {})}
    >
      <div
        ref={autoCompleteBaseRef}
        className="auto-complete-area"
        style={{
          ...style,
          width: "100%",
          border: inputProps?.native ? 'none' : isFocused
            ? "2px solid #0b87ea"
            : isHovered
              ? "1px solid #000"
              : "1px solid #aeaeae",
          borderRadius: inputProps?.native ? 0 : "4px",
          background: inputProps?.native ? 'inherit' : "#fff",
        }}
        onMouseEnter={() => {
          if (disabled) {
            return;
          }
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <div style={{ marginTop: "0px", position: "relative" }}>
          {(defaultValue || isFocused) && label && (
            <label style={{ ...buttonOuterLabelStyle, ...labelProps.style } as any}>{label}</label>
          )}
          <div id={selectId || "auto-complete"} ref={selectRef} style={{ display: "flex" }}>
            <span
              style={{
                width: '100%', maxWidth: width - 30, textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden', padding: inputProps?.native && !isFocused ? '0px 2px' : "8.5px 14px"
              }}
              onClick={togglePopper}
            >
              <span style={{ display: "inline-flex" }}>
                <input
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  id="react-select-2-input"
                  spellCheck="false"
                  tabIndex={0}
                  type="text"
                  aria-autocomplete="list"
                  ref={inputRef}
                  className="auto-complete-input"
                  onBlur={(e) => focusOutSearchField(e as unknown as MouseEvent)}
                  onChange={(event) => setSearchValue(event)}
                  onKeyDown={handleKeyDown}
                  value={searchString}
                  style={{
                    boxSizing: "content-box",
                    width: charWidth,
                    maxWidth: width - 50,
                    background: "0px center",
                    border: 0,
                    fontSize: "inherit",
                    opacity: 1,
                    outline: 0,
                    padding: 0,
                    color: "#000",
                    ...inputProps.style,
                  }}
                />
                {defaultValue.length == 0 && searchString.length == 0 && (
                  <span
                    style={{
                      color: "#717171",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {placeholder}
                  </span>
                )}
              </span>
              {!isFocused && (
                <span style={{ color: disabled ? "#aeaeae" : defaultValue ? "#000" : "#717171" }} title={defaultValue ? defaultValue : ''}>
                  {defaultValue ? defaultValue : label}
                </span>
              )}
            </span>
            <span style={{ display: "flex" }}>
              {isClearable && (
                <span
                  onClick={clearValue}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginTop: "-5px",
                    alignItems: "center",
                  }}
                >
                  x
                </span>
              )}
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {open ? <ArrowDropUpIcon titleAccess="Close" onClick={togglePopper} /> : <ArrowDropDownIcon titleAccess="Open" onClick={togglePopper} />}
              </span>
            </span>
          </div>
          <ClickAwayListener onClickAway={(e) => focusOutSearchField(e as unknown as MouseEvent)}>
            <Popper
              open={open}
              anchorEl={autoCompleteRootRef.current}
              placement="bottom"
              sx={{ ...styles.popperStyle, width: width, borderRadius: "4px" }}
            >
              <div
                ref={popperRef}
                className="auto-complete-poper-container"
                style={{ margin: "0" }}
              >
                <div
                  className={`auto-complete-poper-${uniqueId}`}
                  style={{
                    width: width,
                    background: "#fff",
                    overflow: "auto",
                    maxHeight: maxHeight > 300 ? 300 : maxHeight < 100 ? heightFromTop : maxHeight,
                    borderRadius: "4px",
                  }}
                >
                  <div style={{ width: width - 8 }}>
                    <div
                      id="auto-complete-poper-items-wrap"
                      ref={scrollableDivRef}
                    >
                      <AutocompleteMenuItems
                        menuItems={dataSearchList}
                        selectedIndex={selectedIndex}
                        getOptionLabel={getOptionLabel}
                        handleMenuItemClick={handleMenuItemClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Popper>
          </ClickAwayListener>
        </div>
      </div>
    </div>
  );
});

const AutocompleteMenuItems: FC<{
  menuItems: any[];
  selectedIndex: any;
  getOptionLabel: (value: any) => void;
  handleMenuItemClick: (value: any) => void;
}> = React.memo(function AutocompleteMenuItem({
  menuItems,
  selectedIndex,
  getOptionLabel,
  handleMenuItemClick,
}) {
  if (menuItems.length === 0) {
    return (<MenuItem
      disabled
      style={{ minHeight: 35 }}
    >
      No Options
    </MenuItem>)
  }
  return (
    <>
      {menuItems.map((item: any, index: number) => (
        <MenuItem
          key={index}
          selected={index == selectedIndex}
          style={{ minHeight: 35 }}
          onClick={() => handleMenuItemClick(item)}
          title={getOptionLabel ? getOptionLabel(item) : item.label ?? item}
        >
          {getOptionLabel ? getOptionLabel(item) : item.label ?? item}
        </MenuItem>
      ))}
    </>
  );
});

export default Autocomplete;
