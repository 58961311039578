 const label = {
    MODULE: {
        Sales: 'Der Umsatz',
        Marketing: 'Marketing',
        Service: 'Bedienung',
        Operations: 'Operationen',
        HUB: 'NABE',
    },
    MODULE_ITEMS: {
        Reports: 'Berichte',
        Dashboard: 'Instrumententafel',
        'Marketing Templates': 'Marketing-E-Mail-Vorlagen',
        'Broadcast Campaigns': 'Broadcast-Kampagnen',
        'Automation Designer': 'Automatisierungsdesigner',
        'Email Marketing': 'Email Marketing',
        'Lead Capture': 'Lead Capture',
        'Landing Pages': 'Startseiten',
        'Web Forms': 'Web Forms',
        'Automated Campaigns': 'Automatisierte Kampagnen',
        'Social Marketing': 'Soziales Marketing',
        'Website Tracking': 'Website-Tracking',
        'Website Analytics Setup': 'Website Analytics Setup',
        'Ab Campaigns': 'Ab Kampagnen',
        'Opportunities': 'Chancen',
        'Marketing Reports': 'Berichte',
        'Touch Campaigns': 'Berühren Sie Kampagnen',
        'A/B Campaigns': 'A / B-Kampagnen',
        'Email Blast': 'E-Mail-Explosion',
        Activities: 'Aktivitäten',
        Conversion: 'Umwandlung',
        Campaigns: 'Kampagnen',
        Custom: 'Benutzerdefiniert',
        Sales: 'Der Umsatz',
        Marketing: 'Marketing',
        Offers: 'Bietet an',
        Coupons: 'Gutscheine',
        'Coupons Claim Report': 'Bericht',
        ASSETS_DISTRIBUTION: 'Asset Distribution',
        USEFUL_LINKS: 'Nützliche Links',
        Royalties: 'Lizenzgebühren – Vermächtnis',
        COMPLIANCE_REPORT: "Konformität",
    },
    COMMON: {
        PUSH_NO_CONFIRM: 'Ohne Bestätigung drücken',
        UNIT_CRM_UPDATE_STAGES:'Aktualisierungsstufen',
        UNIT_CRM_UPDATE_TASKS:'Aufgaben aktualisieren',
        FRENCHISEE_COMPANY_NAME:'Wählen Sie den Namen eines Franchisenehmerunternehmens aus',
        SELECT_AN_ACCOUNT:'Wählen Sie eine Einheit aus',
        ADD_TO_PLAN:'Zum Plan hinzufügen',
        LOGIN_TO_BASE:'Melden Sie sich bei Base an',
        PLUS_BRANCH: '+Zweig',
        ADD: 'Hinzufügen',
        ADD_NEW_APPOINTMENT: 'Fügen Sie eine neue hinzu',
        SHOW: 'Show',
        HIDE: 'Ausblenden',
        REFRESH: 'Aktualisierung',
        EDIT: 'Bearbeiten',
        SAVE: 'sparen',
        TEST: 'prüfen',
        CANCEL: 'Stornieren',
        ENTER_TEXT: 'Text eingeben',
        APPLY: 'Anwenden',
        AND: 'und',
        EMPTY: 'Leer',
        EXPAND: 'Erweitern',
        DELETE: 'Löschen',
        REQUIRED: '(erforderlich)',
        AUTO_SEARCH_TEXT: 'Gleicher Text, unterschiedliche Werte',
        NOTE: 'Hinweis',
        SEARCH: 'Suche',
        OK: 'In Ordnung',
        SEND_MAIL: 'E-Mail senden',
        ASSIGN: 'Zuordnen',
        SELECT_A: 'Wählen Sie a',
        SELECT_MORE: 'Wählen Sie eine oder mehrere aus',
        CLOSE: 'Schließen',
        CREATE: 'Erstellen',
        WITH: 'mit',
        CONVERT: 'Konvertieren',
        SEARCH_PLACEHOLDER: 'Geben Sie ein und drücken Sie die Eingabetaste, um zu suchen',
        COPY: 'Kopieren',
        MORE: 'Mehr',
        CLICK_TO_SEARCH: 'Klicken Sie hier, um zu suchen',
        PREVIEW: 'Vorschau',
        BACK: 'Zurück',
        CONFIRM_REQUEST: 'Anfrage bestätigen',
        SELECT: 'Wählen',
        ADVANCED: 'Fortgeschrittene',
        ADVANCED_SEARCH: 'Erweiterte Suche',
        RESULTS_FOR: 'Ergebnisse für',
        SETTINGS: 'die Einstellungen',
        SIGN_OUT: 'Ausloggen',
        BACK_TO_YOUR_ACCOUNT: 'Zurück zu Ihrem Konto',
        BACK_TO_LIST: 'Gehen Sie zurück zur Liste.',
        BACK_TO_COLUMN_VIEW: 'Kehren Sie zur Spaltenansicht zurück.',
        MERGE: 'Verschmelzen',
        DISPLAY_DIFFERENT_FIELDS: '* Nur Felder anzeigen, die unterschiedlich sind.',
        WORKFLOW: 'Arbeitsablauf',
        STATUS: 'Status',
        RECORD: 'Aufzeichnung',
        MERGED_RECORD: 'Zusammengeführter Datensatz',
        EXPORT: 'Export',
        CTR_DETAILS_FOR: 'CTR-Details für',
        URL: 'URL',
        COUNT: 'Anzahl',
        CLICK_FOR_KANBAN_VIEW: 'Säule',
        CLICK_FOR_LIST_VIEW: 'Liste',
        INSERT: 'Einfügen',
        CATEGORIES: 'Kategorien',
        REMOVE: 'Entfernen',
        REPLY: 'Antworten',
        REPLY_ALL: 'Allen antworten',
        FORWARD: 'Nach vorne',
        WORKFLOW_NAME: 'Workflow-Name',
        CONVERTING: 'Konvertieren',
        TO: 'zu',
        CURRENT_WORKFLOW: 'Aktueller Workflow',
        CHANGE_WORKFLOW: 'Workflow ändern',
        UPDATE: 'Aktualisieren',
        STATUS_TO_OPTIONAL: 'Status bis (optional)',
        MANAGE: 'Verwalten',
        ADD_PERSONAL_NOTE_OPTIONAL: 'Fügen Sie Ihrer Anfrage eine persönliche Notiz hinzu (optional)',
        CONVERTED: 'Umgewandelt',
        SELECT_USER: 'Wählen Sie einen Benutzer aus',
        COMING_SOON: 'Demnächst',
        IMPORT: 'Importieren',
        NO: 'Nein',
        FOUND: 'gefunden',
        NOTHING_FOUND: 'Nichts gefunden für',
        DOT: '',
        UNDER_PROCESSING: 'In Bearbeitung in Kürze',
        FIND: 'Finden',
        LOADING: 'Wird geladen',
        NAME: 'Name',
        CLEAR_ALL: 'Alles löschen',
        CLEAR: 'klar',
        SHARE: 'Aktie',
        ADD_TO_GROUP: 'Zur Gruppe hinzufügen',
        MAP: 'Karte',
        AUDIT: 'Änderungsprotokoll',
        OPEN_AUDIT_FORM: 'Prüfung',
        TRAIL: 'Weg',
        ADD_NOTES: 'Notizen hinzufügen',
        TO_EXCEL: 'Nach Excel',
        PUSH: 'drücken',
        RESCHEDULE: 'Reschedule',
        PRINT: 'Print',
        COMPLETE: 'Komplett',
        COMPLETE_New: 'Komplett & Neu',
        SEND_EMAIL: 'E-Mail senden',
        SEND_EMAIL_ATTACHMENT: 'Senden Sie einen Anhang per E-Mail',
        SEND_SMS: 'SMS senden',
        CONVERT_TO_ACCOUNTS: 'In Konten konvertieren',
        CONNECT: 'Verbinden',
        DISCONNECT: 'Trennen',
        DOWNLOAD: 'Herunterladen',
        BATCH_EDIT: 'Batch-Bearbeitung',
        BATCH_CONVERT: 'Batch-Konvertierung',
        INACTIVE: 'Inaktiv',
        ACTIVE: 'Aktiv',
        RESTORE: 'Wiederherstellen',
        PROFILE: 'Profil',
        TYPE_TO_SEARCH: 'Schreibe hier um zu suchen',
        CONVERT_AND_ADD_OPP: 'Opportunity konvertieren und hinzufügen',
        SEND_VERIFICATION_EMAIL: 'Senden Sie E-Mail-Verifizierung',
        THESE: 'diese',
        ALL_APPOINTMENTS: 'Alle Termine',
        ALL_TASKS: 'Aufgaben',
        CONTINUE: 'Fortsetzen',
        ALL_OPPORTUNITIES: 'Alle Möglichkeiten',
        EDIT_NOTES: 'Notizen bearbeiten',
        RUN: 'Lauf',
        INTEGRATION_TITLE: 'Integration',
        UNSUBSCRIBE: 'Abmelden',
        MY_INTEGRATIONS: 'Integrationen',
        NOTIFY: 'Benachrichtigen',
        HELP: 'Hilfe',
        PUBLISH: 'Veröffentlichen',
        UNPUBLISH: 'Nicht veröffentlichen',
        CALL_SCRIPT: 'Skripte aufrufen',
        DOCUSIGN: 'Senden Sie DocuSign',
        DOCUSIGN_INTEGRATION: 'Senden Sie DocuSign',
        ALL: 'Alle',
        USER: 'Nutzer',
        SYSTEM: 'System',
        MY: 'Meine',
        TODAYS: 'Heute',
        YES: 'Ja',
        FUTURE: 'Zukunft',
        OVERDUE: 'Überfällig',
        TOMORROWS: 'Morgen',
        TOMORROW: 'Morgen',
        TODAY: 'Heute',
        CONNECTED_AS: 'Verbunden als',
        SALESREP: 'Vertriebsmitarbeiter',
        EVERYONE: 'Jeder',
        NOTE_TYPE: 'Notentyp',
        TEXT: 'Text',
        FOR: 'zum',
        MODULE_SETTING: 'die Einstellungen',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'Bibliotheksvorlage',
            USER_TEMPLATE: 'Benutzervorlage',
        },
        OF: 'von',
        'System Fields': 'Systemfelder',
        'Custom Fields': 'Benutzerdefinierte Felder',
        'Additional Information': 'Additional Information',
        'Contact Details': 'Kontaktdetails',
        'Phone and Email Information': 'Telefon- und E-Mail-Informationen',
        'Sales rep': 'Vertriebsmitarbeiter',
        DONE: 'Getan',
        OPEN: 'Öffnen',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'Ich würde meine Quellen aus der CSV-Datei zuordnen',
        ADD_NEW_SOURCE: 'Fügen Sie eine neue Quelle hinzu',
        ADD_TO_THIS_LIST: 'Zu dieser Liste hinzufügen',
        TYPE_SOMETHING: 'Schreibe etwas',
        'Opportunity Details': 'Opportunity-Details',
        'Company Details': 'Firmendetails',
        'Contact Information': 'Kontakt Informationen',
        'Case Information': 'Fallinformationen',
        'Solution Details': 'Lösungsdetails',
        'Project Details': 'Projekt Details',
        'Issue Details': 'Problemdetails',
        'Assigned to': 'Zugewiesen an',
        'Sales person': 'Verkäufer',
        EMAIL_TO: 'E-Mail an',
        RECENT_SEARCHES: 'Letzte Suchanfragen',
        RECENT_RECORDS: 'Aktuelle Rekorde',
        ENABLE_MAILING: 'E-Mail-Versand aktivieren',
        ASSETS: 'Bibliothek',
        DISABLE_MAILING: 'E-Mail-Versand deaktivieren',
        CLICK_TO_REMOVE: 'Klicken Sie hier, um zu entfernen',
        PIN: 'Stift',
        UNPIN: 'Lösen Sie den Stift',
        RENAME: 'Umbenennen',
        DEACTIVATE: 'Deaktivieren',
        ACTIVATE: 'aktivieren Sie',
        COUPONS: 'Gutscheine',
        ADD_FOLDER: 'Ordner hinzufügen',
        ADD_FILE: 'Datei hinzufügen',
        SELECT_CONTACTS: 'Wählen Sie Kontakte',
        NOTIFICATION_REMINDERS: 'Benachrichtigungen und Erinnerungen',
        PREFERENCES: 'Einstellungen',
        LOCK_STAGES: 'Stufen sperren',
        LOCK_TASKS: 'Aufgaben sperren',
        SEND_BATCH_SMS: 'Batch-Textnachricht senden',
        SEND_TEXT_MESSAGE: 'Sende Textnachricht',
        VERIFY_EMAIL: 'E-Mail bestätigen',
        SELECT_UNIT_NAME: 'Wählen Sie den Einheitennamen',
        NEWS: 'Nachrichten',
        CLOSE_CASE: 'Fall schließen',
        CUSTOMIZE: 'Anpassen',
        VIEW_MODE: 'ANSICHTMODUS',
        VIEW_MODE1: 'Ansichtsmodus',
        LIST: 'Liste',
        COLUMN: 'Säule',
        SETUP_SCHEDULING_PAGES: 'Einrichten von Planungsseiten',
        SCHEDULER_LINK: 'Scheduler Link',
        SAVE_RUN: 'SPEICHERN & LAUFEN',
        BATCH_CONVERT_TO_ACCOUNTS: 'Stapelkonvertierung in Konten',
        NEXT: 'Nächster',
        ADD_QUICKBOOKS: 'Zu Quickbooks hinzufügen',
        ITEM_FROM_QB: 'AUS Quickbooks',
        PUSH_TO_QB: 'Zu Quickbooks hinzufügen',
        ADD_NOTE: 'Notiz hinzufügen',
        ADD_NOTE_CONVERT: 'Notiz hinzufügen und konvertieren',
        OBJECT_NAME: 'Objektname',
        GREENLIGHT_EXPORT_DOWNLOAD: 'Verarbeiten & Herunterladen',
        PREVIOUS: 'Bisherige',
        VIEW: 'Aussicht',
        MENU: 'Speisekarte',
        NO_DUPLICATE: 'Kein Duplikat',
        ONGOING: 'Laufend',
        ONGOING_ONBOARDING: 'Laufendes Onboarding',
        SYNC_NOW: 'Sync Now',
        'Generate Invoice': 'Rechnung erstellen',
        'Add Payment': 'Zahlung hinzufügen',
        'Print' : 'Drucken',
        MOBILE_MENU: 'Mobile Menu',
        PORTAL_MENU: 'Portal Menu',
        TO_QUICKBOOKS:'Zu Quickbooks',
        CONVERT_INVOICE: 'In Rechnung umwandeln',
        RECEIVE : 'Erhalten',
        ACCEPT : 'Akzeptieren',
        CONFIRM: 'Bestätigen',
        FULFILL: 'Erfüllen',
        SHIP: 'Schiff',
        ADD_JOBS : 'Jobs hinzufügen',
        ROYALTY_SETUP: 'Lizenz-Setup',
        SAVE_AND_SCHEDULE:'Speichern & planen',
        UNLINK: 'Verknüpfung aufheben',
        TO_XMS: 'Zu XMS',
        SEQUENTIAL:"Sequentiell",
        STAR: 'Stern',
        PIN :'Stift',
        CONVERT: 'Konvertieren',
        SHARE_WITH : 'Teilen mit',
        FRANCHISOR: 'Franchisegeber',
        FRANCHISE: 'Franchise',
        ALL_PHONE_NO: 'Alle Telefonnummern',
        BRING_YOUR_OWN:'BYO-Nummer (Bring Your Own).',
        LEASE_NUMBER:'Mietvertragsnummer',
        ADD_NUMBERS:'ZAHLEN HINZUFÜGEN',
        ADD_CREDITS:'KREDITE HINZUFÜGEN',
        CALL_MINUTES:'Anrufprotokolle und',
        TEXT_CREDIT:'verbleibendes Textguthaben',
        LEASE_A_NO:'Mieten Sie eine Nummer',
        TOLL_FREE:'Zollfrei',
        LOCAL:'Lokal',
        COUNTRY:'Land',
        SURVEY_SETUP : 'Umfrage-Setup',
        THANKS_SURVEY:'Daumen hoch Umfrage-Dankeschön-Formular',
        REVIEW_WEBSITE_REQUIRED:'Überprüfen Sie die URL der Website (erforderlich)',
        THUMBS_UP_TITLE:'Daumen hoch, Titel',
        THUMBS_UP_DESC:'Daumen hoch Beschreibung',
        YES_BUTTON:'Ja-Taste',
        NO_BUTTON:'Kein Knopf',
        DAYS: 'Tage)',
        LOCATION_ALERT:'Standort existiert bereits!',
        TASK_NAME:'Aufgabennname',
        DESC:'Beschreibung',
        UNIT_CRM: 'Einheit CRM-Setup',
        UNIT_CRM_LABEL: 'Unit-CRM-Administratoren können das:',
        UNIT_CRM_CUSTOMIZE_FORMS: 'Passen Sie Formulare an',
        UNIT_CRM_CUSTOMIZE_WORKFLOW: 'Passen Sie Arbeitsabläufe an',
        ACTION: 'Aktionen:',
        INVOICE_ALL : 'Alles in Rechnung stellen',
        EMAIL_ALL:'Alle per E-Mail versenden',
        POST_ALL: 'Alle posten',
        REPORT_FIELDS: 'Berichtsfelder:',
        COMPLIANCE_NOTIFICATION:'Compliance-Benachrichtigung',
        COMPLIANCE_LABEL:'Geben Sie die E-Mail-Adresse ein, um FR zu benachrichtigen, wenn ein Franchisenehmer gegen die Vorschriften verstößt',
        ONBOARDING_NOTIFICATION:'Onboarding-Benachrichtigung',
        EMAIL:'Email',
        HELPDESK_NOTIFICATION:'Helpdesk-Benachrichtigung',
        LMS_SETUP:'LMS-Setup',
        LMS_LABEL:'LMS-Konto (erforderlich)',
        PLUS_BASE: '+Basis',
        UNITBASE:'Einheitenbasis'
    },
    NOTE:{
        ALL_NOTES:'Alle Notizen',
        ALL_TYPES:'Alle Arten',        
    },
    HOME: {
        MY_FILTERS: 'Meine Filter',
        GROUPS: 'Gruppen',
        ADD_NEW: 'Neue hinzufügen',
        RECENT: 'Kürzlich',
        HOME: 'Zuhause',
        CLICK_TO_REFRESH: 'Aktualisierung',
        RECENTLY_VIEWED: 'zuletzt angesehen',
        FILTERS: 'Filter',
        ADD_GROUP: 'Gruppe hinzufügen',
        NOTIFICATIONS: 'Benachrichtigungen',
        WHATS_NEW: "Was gibt's Neues",
        COLLAPSE: 'Zusammenbruch',
        EXPAND: 'Erweitern',
        ADD_FILTER: 'Filter hinzufügen',
        CLICK_TO_FIND_GROUP: 'Klicken Sie hier, um eine Gruppe zu finden',
        CLICK_TO_FILTER_GROUP: 'Filtern Sie Ihre Gruppen',
        CLICK_TO_FIND_FILTER: 'Klicken Sie hier, um einen Filter zu finden',
        FORECASTS: 'Prognosen',
        SALES_REPORT: 'Verkaufsberichte',

        //not found in doc
        ADD_FIELD: 'Klicken Sie hier, um dem Filter ein Feld hinzuzufügen',
        FITER_SETUP: 'Filter-Setup',
        ENTER_BY_HAND: 'Von Hand eingeben',
        IMPORT: 'Importieren',
        VIEW: 'Aussicht',
        DELETE: 'Löschen',
        CLICK_TO_FIND_REPORT: 'Klicken Sie hier, um einen Bericht zu finden',

    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'Kampagnenname (erforderlich)',
        GROUP: 'Gruppe',
        CONTACT_FILTER: 'Kontaktfilter',
        ACCOUNT_FILTER: 'Kontofilter',
        FROM_NAME_REQUIRED: 'Vom Namen (erforderlich)',
        VIEW_AS_WEB_PAGE: 'Als Webseite anzeigen',
        SUBJECT_REQUIRED: 'Betreff (erforderlich)',
        DAYS: 'Tag(e)',
        EXECUTE_NOW: 'Jetzt ausführen',
        TEST: 'Prüfung',
        SCHEDULED_FOR: 'Eingetragen für',
        EXECUTED_ON: 'Ausgeführt auf',
        EXECUTING: 'Ausführen',
        ABORTED: 'Abgebrochen',
        SCHEDULE: 'Zeitplan',
        CLICK_URL: 'Klicken Sie auf URL',
        SELECT_GROUP: 'Wählen Sie bis zu drei Gruppen aus',
        SELECT_CONTACT_FILTER: 'Wählen Sie einen Filter aus dem Kontakt',
        SELECT_ACCOUNT_FILTER: 'Wählen Sie einen Filter aus dem Konto',
        CHANGE_TEMPLATE: 'Vorlage ändern',
        WITHOUT_AB_TEST: 'Ohne a / b Test',
        WITH_AB_TEST: 'Mit a / b Test',
        WITH_AUTOMATION: 'Automatisierte Kampagnen',
        WITHOUT_AUTOMATION: 'Touch-Kampagnen (Vermächtnis)',
        SEND_ON_WEEK_DAY: 'Kein Versand am Wochenende (Sa, So)',
        RESEND_AFTER: 'Nachher erneut senden',
        RESEND_ALL: 'Alle erneut senden',
        FROM_EMAIL_REQUIRED: 'Aus E-Mail (erforderlich)',
        CAMPAIGN_TYPE_OPTIONAL: 'Kampagnentyp (optional)',
        CAMPAIGN_TYPE: 'Kampagnentyp',
        START_DATE_REQUIRED: 'Startdatum (erforderlich)',
        SENT: 'Geschickt',
        OPENED: 'Geöffnet',
        UNOPENED: 'Unopened',
        CLICKED: 'Klicken',
        BOUNCED: 'Abprallte',
        UNSUBSCRIBED: 'Abgemeldet',
        SPAM: 'Spam',
        DROPPED: 'Fallen gelassen',
        TYPE_HERE: 'Geben Sie hier ein.',
        ADD_SIGNATURE: 'Unterschrift hinzufügen',
        INSERT_IMAGE: 'Bild einfügen',
        PERSONALIZE: 'Personifizieren',
        TEMPLATE_DIALOG_TITLE: 'Wählen Sie eine Vorlage',

        // Campaign Status
        'All': 'Alle',
        'Active': 'Aktiv',
        'Running': 'Laufen',
        'Stopped': 'Gestoppt',

        'New': 'Neu',
        'Queued': 'In Warteschlange',
        'Executed': 'Hingerichtet',
        OPEN: 'Öffnen',
        BOUNCE: 'Prallen',
        SPAMREPORT: 'Spamreport',
        'Halted': 'Halted',
        'Auto Response': 'Automatische Antwort',
        'Marketing Template': 'Marketing-Vorlage',
        'Newsletter': 'Newsletter',
        'Greetings': 'Schöne Grüße',
        'Inactive': 'Inaktiv',
        'Executed - No Winner': 'Ausgeführt - Kein Sieger',
        'Sent to Winner': 'Sent to Winner',
        ADD_CAMPAIGN_TYPE: 'Fügen Sie einen neuen Kampagnentyp',
        CAMPAIGN_NAME: 'Kampagnenname',
        SUBJECT: 'Gegenstand',
        MANAGE: 'Verwalten',
        FROM_EMAIL: 'Aus der Email',
        SELECT_A_FILTER:'Wählen Sie einen Filter aus ',
        BETWEEN: 'Zwischen',
        WEEKDAYS_ONLY: 'Nur Wochentage'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: 'Personalisieren Thema',
        FROM_EMAIL_REQUIRED: 'Von E-Mail (erforderlich)',
        EMAIL_CAMPAIGN_REPORT: 'Eine E-Mail Campaign Report',
        SCHEDULE_BROADCAST: 'Zeitplan Live Übertragung',
        RESCHEDULE_BROADCAST: 'Reschedule Live Übertragung',
        EXECUTE_BROADCAST: 'Führen Sie Live Übertragung',
        ADD_GROUP: 'Gruppe hinzufügen',
        CATEGORY: 'Kategorie',
        TEMPLATE: 'Vorlage',
        PERFORM_AB_TESTING: 'Führen Sie A / B-Tests',
        VERIFY_EMAIL: 'Bestätige deine Email-Adresse',
        VERIFY_EMAIL_ADDRESS: 'Geben Sie einfach Ihre E-Mail-Adresse hier',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Überprüfen Sie eine andere E-Mail-Adresse',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Bearbeiten Broadcast-Kampagne',
        BATCH_EDIT: 'Stapelbearbeitung',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Kopieren Broadcast-Kampagne',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Erstellen Sie Broadcast-Kampagne',
        All_BROADCASTS: 'alle Übertragungen',
        BROADCASTS: 'Broadcasts',
        ADD_SIGNATURE: 'hinzufügen Unterschrift',
        BROADCAST_INFO: 'B Roa d oder st Info',
        DESIGN: 'Design',
        DRAG_AND_DROP: 'Drag-and-Drop',
        LEGACY: 'Erbe',
        EMAIL_BUILDER: 'Eine E-Mail Builder',
        QUALIFIED_CONTACTS: 'Auf der Empfängerliste',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'Ziel von',
        AB_TEST_DISTRIBUTION: 'A / B-Test Verteilung',
        MIN_MARGIN_PERCENT: 'Min Gewinnspanne%',
        A_PERCENT: 'A%',
        B_PERCENT: 'B%',
        AB_CAMPAIGN_REPORT: 'A / B Campaign Report',
        //not found in doc
        AB_SEND_REST: 'Senden Sie die Ruhe der Gewinner Verwendung',
        AB_SELECT_WINNER_BY: 'Wählen Sieger durch',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Überprüfen Sie eine andere E-Mail-Adresse',
        EMAIL_VIEWS: 'E-Mail-Ansichten',
        EMAIL_CLICKS: 'E-Mail-Klicks',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'Edit A / B-Kampagne',
        BATCH_EDIT: 'Stapelbearbeitung',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'Kopieren A / B-Kampagne',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'Erstellen A / B-Kampagne',
        ALL_AB_CAMPAIGNS: 'Alle AB-Kampagnen',
        AB_TESTS: 'A / B-Tests',
        MESSAGE_A: 'Nachricht A',
        MESSAGE_B: 'Nachricht B',
        AFTER: 'nach',
        SCHEDULE_AB_CAMPAIGN: 'Schedule A / B-Kampagne',
        WINNER_BASEDON_A: 'Winner (basierend auf der Nachricht A)',
        WINNER_BASEDON_B: 'Winner (basierend auf der Nachricht B)',
    },
    CALENDAR: {
        CALENDAR_TASKS: 'Kalender / Aufgaben',
        CALENDAR_ONLY: 'Kalender',
        CALENDARS: 'Kalender für',
        SHOW_OPTION_1: 'Abgesagt',
        SHOW_OPTION_2: 'Abgeschlossen',
        SHOW_OPTION_3: 'Geplant',
        TODAY: 'Heute',
        BACK: 'Zurück',
        NEXT: 'Nächster',
        DAY: 'Tag',
        WEEK: 'Woche',
        MONTH: 'Monat',
        AGENDA: 'Agenda',
        DATE: 'Datum',
        TIME: 'Zeit',
        EVENT: 'Veranstaltung',
        ALL_DAY: 'den ganzen Tag',
        WORK_WEEK: 'Arbeitswoche',
        YESTERDAY: 'gestern',
        TOMMOROW: 'Morgen',
        SHOW_MORE: '',
        TYPE: 'Art',
        MY_CALENDAR_SETTING: 'Meine Kalendereinstellungen',
        DISPLAY:'Anzeige',
        ACCOUNT_NAME:'Kontobezeichnung',
        JOB_NAME : 'Berufsbezeichnung',
        COLOR_CODE: 'Farbcode',

    },
    GROUP: {
        GROUP_NAME: 'Gruppenname',
        DELETE_RECORD: 'Aufzeichnung löschen',
        USED_IN: 'Benutzt in',
        REMOVE_FROM: 'Entfernen von',
        SELECT_RECORDS: 'ausgewählte Datensätze',
        ALL_RECORDS: 'Alle Einträge',
        SELECT_OPTIONS: 'wähle Optionen',
        GROUP_FILTER: 'Wählen Sie Gruppenfilter',
        ALL: 'Alle',
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv',
        DETAILS: 'Einzelheiten',
        'Active': 'Aktiv',
        'Inactive': 'Inaktiv',
        'New': 'Neu',
        'scheduled': 'geplant',
        'Queued': 'In Warteschlange',
        'Executed': 'Hingerichtet',
        'Running': 'Laufen',
        'Halted': 'Halted',
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'Aufgaben',
        PERSONALIZE_OPTION_1: 'Überfällig',
        PERSONALIZE_OPTION_2: 'Heute',
        PERSONALIZE_OPTION_3: 'Zukunft',
        PERSONALIZE_OPTION_4: 'Morgen',
        PERSONALIZE_SORT_1: 'Datum',
        PERSONALIZE_SORT_2: 'Priorität',
        TASKS_FOR: 'Aufgaben für',
        SORT_BY: 'Sortieren nach',
        COMPLETE_THIS_APPOINTMENT: 'Füllen Sie diesen Termin',
        COMPLETE_THIS_TASK: 'Führen Sie diese Aufgabe',
        NEXT_DAY: 'Nächster Tag',
        NEXT_WEEK: 'Nächste Woche',
        COMPLETE: 'Komplett',
        DELETE: 'Löschen',
        TASKS: 'Aufgaben',
        APPOINTMENTS: 'Ausstattung',
        ADD_NOTE: 'Anmerkung hinzufügen (optional):',
        NEXT_MONTH: 'Nächsten Monat',
        DAYS: 'Tage',
        SPECIFIC_DATE: 'Bestimmtes Datum',
        RESCHEDULE: 'Reschedule',
        RESCHEDULE_FOR: '#VALUE!',
        REPEAT: 'Wiederholen',
        DAY_OF_THE_MONTH: 'Tag des Monats',
        DAY_OF_THE_WEEK: 'Wochentag',
        REPEAT_TYPE: 'Wiederholen Typ',
        REPEAT_ON: 'wiederholen Sie auf',
        REPEAT_BY: 'Wiederholen von',
        START_DATE: 'Anfangsdatum',
        END_DATE: 'Endtermin',
        REPEAT_EVERY: 'Wiederhole jeden',
        REMOVE_REMINDER: 'entfernen Erinnerung',
        ONLY_THIS: 'Nur das',
        FOLLOWING: 'folgende',
        ALL: 'Alle',
        THIS:'Diese',
        THIS_AND_FOLLOWING:'Dies und folgendes',
        ONLY_THIS_RECORD: 'Nur diese Bilanz',
        FOLLOWING_RECORDS: 'Nach Aufzeichnungen',
        ALL_RECORDS: 'alle US-Records',
        ADD_A_NOTE: 'Füg ein Notiz hinzu',
        COMPLETE_TASK_OPTIONAL: 'Vollständige anstehende Aufgaben',
        COMPLETE_TASK_OPTIONAL_OBJ: 'Komplette anstehende Aufgaben / E-Mails / SMS-Nachrichten',
        CREATE_NEXT_TASKS: 'Erstellen nächste Aufgaben',
        CREATE_NEXT_TASKS_OBJ: 'Erstellen nächste Aufgaben / E-Mails / SMS-Nachrichten',
        PENDING_TASKS: 'Ausstehende Aufgaben',
        SEARCH_BY_NAME: 'Suche mit Name',
        INVITE_GUEST: 'Gäste einladen',
        ATTENDEES: 'Die Teilnehmer',
        SALES_REP: 'Vertriebsmitarbeiter',

        //not found in doc
        ADD_REMINDER: 'Fügen Sie eine Erinnerung',
        'Email': 'Email',
        'Pop-up': 'Aufpoppen',
        'minutes': 'Protokoll',
        'hours': 'Std',
        'days': 'Tage',
        'weeks': 'Wochen',
        DAILY: 'Täglich',
        WEEKLY: 'Wöchentlich',
        MONTHLY: 'Monatlich',
        YEARLY: 'Jährlich',
        DAYS_S: 'Tage',
        WEEKS: 'Wochen',
        MONTHS: 'Monate',
        YEARS: 'Jahre',
        APPOINTMENT: 'Geplanter Termin',
        TASK: 'Aufgabe',
        SUBJECT: 'Gegenstand',
        ASSIGN_TO: 'Zuweisen',
        COMPANY: 'Unternehmen',
        CONTACT_NAME: 'Kontaktname',
        OUTLOOK_SYNC: 'Outlook Sync',
        GOOGLE_SYNC: 'Google Sync',
        'Scheduled': 'Geplant',
        'Completed': 'Abgeschlossen',
        'Cancelled': 'Abgesagt',
        'Pending': 'steht aus',
        TASK_NAME: 'Aufgabennname',
        DUE_BY: 'aufgrund von',
        DESCRIPTION: 'Beschreibung',
        OWNER: 'Inhaber',
        FOLLOWUP: 'Nachverfolgen',
        'All': 'Alle',
        'Empty': 'Leer',
        'No': 'Nein',
        'Yes': 'Ja',
        'Everyone': 'Jeder',
        'Just me': 'Nur ich',
        ALL_TASK_COMPLETED: 'Diese Aufgaben wurden bereits abgeschlossen.',
        'duration': 'Dauer',
        'days_after': 'Tage später',
        DEP_TASK_NAME: 'Aufgabennname',
        TASK_COMPLETED: 'Aufgabe erledigt(%)',
        APPOINTMENTS_AND_TASKS: 'Termine und Aufgaben',
        APPOINTMENTS_ONLY: 'Termine Nur',
        TASKS_ONLY: 'Aufgaben Nur',
        EDIT: 'Bearbeiten',
        TASKS_CANCEL: 'Diese Aufgaben werden gelöscht',
        SENDNOW: 'Sende jetzt',
        OPPORTUNITY_NAME: 'Opportunity-Name',
        SELECT_USER: 'Wähle den Benutzer',
        SELECT_STATUS: 'Wählen Sie Status',
        VISITS: 'Besuche',
        UNIT_OWNER_TASKS: 'Aufgaben der Einheit',
        COMPLETE_UNIT_TASK_OPTIONAL: 'Ausstehende Aufgaben des Einheitenbesitzers abschließen',
        UNIT_TASKS: 'Aufgabe für die Einheit',
        DOCUMENTS: 'Unterlagen',
        LINKS: 'Links',
        TASK_SETTINGS: 'Aufgabeneinstellungen',
        APPOINTMENT_SETTINGS: 'Termineinstellungen',
    },
    ATTACHMENT: {
        UPLOAD: 'Hochladen',
        MY_COMPUTER: 'Mein Computer',
        BROWSE: 'Durchsuche',
        DOWNLOAD: 'Datei download',
        ATTACH_FILE: 'Eine Datei anhängen',
        UPLOADING: 'Hochladen',
        NO_IMAGE_FOUND: 'Kein Bild gefunden',
        DOWNLOAD_RECORDING: 'Laden Sie die Aufnahme',
        ATTACHMENT: 'Anhang',
        ADD_AN_ATTACHMENT: 'In einer Anlage',
    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'Klicken Sie hier zum Einsturz',
        CLICK_TO_EXPAND: 'Klicken Sie hier, erweitern',
        SHOW_TOTAL: 'Show Gesamt',
        SHOW_PICTURE: 'Bild zeigen',
        DESCENDING: 'Absteigend',
        SORT_BY: 'Sortieren nach',
        SELECT_COLUMN_FOR_VIEW: 'Wählen Sie die Felder für diese Ansicht',
        SELECT_COLUMN_FOR_FILTER_VIEW: 'Wählen Sie Spalten für die Filterergebnisse',
        ADD_AND_REORDER: 'Hinzufügen und Neuordnungs',
        FIELDS: 'Felder',
        CLICK_TO_ADD_MORE_FIELDS: '(Klicken Sie auf dieses Feld, um weitere Felder hinzuzufügen. Ziehen und neu ordnet die Felder ihre Position zu ändern.)',
        SELECT_FIELDS: 'Felder auswählen',
        MODIFY_FIELD: 'Ändern Feld',
        EXPORT_SELECTED_RECORDS: 'Export ausgewählter Datensätze',
        EXPORT_ALL_RECORDS: 'Exportieren Sie alle Datensätze',
        LINK_EXISTING: 'Link Bestehende',
        SET_UP: 'Konfiguration',
        GROUP_BY: 'Gruppiere nach',
        FIND_BY_FIELD: 'Suchen Sie nach',
        FILTER_TYPE: {
            DEFAULT_FILTERS: 'Standardfilter',
            SHARED_FILTERS: 'Gemeinschafts-Filter',
        },
        COLUMN_VIEW_SETUP_INFO: 'Diese Spalte Ansicht werden nur die ersten fünf Felder. Die Listenansicht zeigt alle Felder aus.',
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'Labels anzeigen',
        SELECT_OPTIONS: 'wähle Optionen',
        CLICK_TO_ADD_MORE_FIELDS: 'Klicken Sie auf dieses Feld, um weitere Felder hinzuzufügen. Ziehen und Neuordnungs der Felder ändern, um ihre order.You maximal 50 Felder auswählen kann.',
        SETUP_FIELDS: 'Setup-Felder',
        ADD_OR_REMOVE_FROM_LIST: 'Hinzufügen / Entfernen Felder aus dieser Liste',
        CLICK_TO_KNOW_MORE: 'Klicken Sie um mehr zu erfahren',
        CLICK_TO_ADD_CHILD_OBJECTS: 'Klicken Sie auf dieses Feld, mehr Kind-Objekte hinzuzufügen. Ziehen und Neuordnungs der Objekte ihre Position zu ändern.',
        SETUP_CHILD_TABS: 'Untergeordnete Registerkarten einrichten',
        SHOW_LABELS_AGAINST_FIELD: 'Wählen Sie diese Etiketten zu zeigen, gegen die Felder aus.',
        CUSTOMIZE_CHILD: 'Anpassen Kind',
        REFUND_CHILD_LABEL: 'Erstattung',
        //not found in doc 
        GROUPS: 'Gruppen',
        MANAGE: 'Verwalten',
        ADD_TO_GROUP: 'Zur Gruppe hinzufügen',
        DATA_TIP_1: 'Dies würde erstellen, die Aufgaben für die',
        DATA_TIP_2: 'Dies würde die Aufgaben / E-Mails / SMS-Nachrichten für das Erstellen',
        DATA_TIP_3: 'Bühne',

        //opportunity info 
        HIGHLIGHT_LABEL_OPPORTUNITY: 'Gelegenheit $',
        HIGHLIGHT_LABEL_FORECAST: 'Prognose $',
        HIGHLIGHT_LABEL_CLOSING_ON: 'Schließen On',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: 'Gelegenheit Alter',
        HIGHLIGHT_LABEL_DAYS: 'Tage',

        CHILD_SETUP_SELECT_CHILD_LABEL: 'Klicken Sie hier ein untergeordnetes Objekt zu dem Satz zu addieren.',
        NO_SETUP_OPTION: 'Keiner',
    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'definieren Beziehung',
        RELATE: 'Sich beziehen',
        COMPANY: 'Unternehmen',
        FULL_NAME: 'Vollständiger Name',
        EMAIL: 'Email',
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: 'Touch Campaign Report',
        INTERVAL: 'Nach',
        NOTE_TYPE: 'Notizart',
        TEXT_REQUIRED: 'Hinweistext (erforderlich)',
        TASK_NAME_REQUIRED: 'Task-Name (erforderlich)',
        OWNER_REQUIRED: 'Besitzer (erforderlich)',
        DUE_BY_REQUIRED: 'Aufgrund von (erforderlich)',
        DAY_AFTER_REQUIRED: 'Tage nach (erforderlich)',
        EMAIL: 'Email',
        POP_UP: 'Aufpoppen',
        MINUTES: 'Protokoll',
        HOURS: 'Std',
        DAYS: 'Tage',
        WEEKS: 'Wochen',
        REMINDER: 'Erinnerung',
        GROUP_REQUIRED: 'Gruppe auswählen (erforderlich)',
        ADD_TO_GROUP: 'Zur Gruppe hinzufügen',
        MOVE_TO_GROUP: 'In Gruppe verschieben',
        REMOVE_FROM_GROUP: 'Aus der Gruppe entfernen',
        STATUS_REQUIRED: 'Status (erforderlich)',
        WORKFLOW_REQUIRED: 'Workflow (erforderlich)',
        CHOOSE_ANOTHER_FIELD: 'Wählen Sie ein anderes Feld',
        CHOOSE: 'Wählen',
        FIELD_FOR: 'ein Feld für',
        CAMPAIGN_NAME: 'Kampagne Name (erforderlich)',
        START: 'Start',
        STOP: 'Halt',
        REPORT: 'Bericht',
        RESUME: 'Weitermachen',
        SELECT_PAGE: 'Wählen Sie eine Seite',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'Geben Sie die URL des Links (optional).',
        ENTER_WEB_PAGE_LINK: 'Geben Sie die Webseiten-Link.',
        CLICK_TO_VIEW: 'Klicke hier, um anzusehen',
        CLICK_TO_EDIT: 'Hier klicken, um zu bearbeiten',
        DRAG_TO_MOVE: 'Ziehen zum bewegen',
        DRAG_TO_CONNECT: 'Ziehen Sie den Anschluss',
        CLICK_TO_REMOVE: 'Klicken Sie hier, zu entfernen',
        CLICK_TO_SEE_REPORT: 'Klicken Sie hier, um Bericht zu sehen',
        CLICK_TO_ADD_MSG: 'Klicken Sie auf Neue Nachricht hinzufügen',
        NO: 'Nein',
        YES: 'Ja',
        CLICK_TO_SELECT_USERS: 'Klicken Sie hier Benutzer auswählen',
        SALES_REP: 'Vertriebsmitarbeiter',
        USERS: 'Benutzer',
        CAMPAIGN_SENDER: 'Kampagne Sender',
        SCORE: 'Ergebnis',
        START_WITH: 'Hinzufügen',
        DECISIONS: 'Entscheidungen',
        ACTIONS: 'Aktionen',
        LINK_CLICKED: 'Link geklickt haben?',
        SCORE_REACHED: 'Punktzahl erreicht?',
        TOUCH_CAMPAIGN: 'Touch-Kampagne',
        WEB_FORM: 'Web-Formular',
        LANDING_PAGE: 'Landing-Page',
        WEBSITE_VISITED: 'Website besucht',
        CHANGE_GROUP: 'Change Group',
        ADD_NOTE: 'Notiz hinzufügen',
        NOTIFY: 'Benachrichtigen',
        ADD_TASK: 'Aufgabe hinzufügen',
        FIELD_UPDATED: 'Update Fields',
        CHOOSE_TEMPLATE: 'Wählen Sie eine Vorlage',
        USE_THIS_TEMPLATE: 'Verwenden Sie diese Vorlage',
        WAIT: 'Warten',
        NAME: 'Name',
        TARGET_GROUPS: 'Zielgruppen)',
        TARGET_FILTER: 'Ziel Filter',
        RESEND_AFTER: 'Erneut senden Nach',
        START_DATE: 'Anfangsdatum',
        SUBJECT: 'Gegenstand',
        TEXT: 'Text',
        TASK_NAME: 'Aufgabennname',
        ASSIGN_TO: 'Zuweisen',
        FIELD_LABEL: 'Feldbezeichner',
        FIELD_VALUE: 'Feldwert',
        LINK: 'Verknüpfung',
        SEND_ON_ANY_TIME: 'Jederzeit',
        SEND_ON_BUSINESS_DAY: 'Nur an Werktagen',
        CONVERT_TO: 'Konvertieren zu',
        QUALIFIER: 'Kennzeichner',
        OBJECT: 'Objekt',
        NOTIFY_SENDER: 'Benachrichtigen Sender',
        NOTIFY_SALES_REP: 'Benachrichtigen Sales Representative',
        NOTIFY_SENDER_SALESREP: 'Benachrichtigen Sie Sender, benachrichtigen Sales Representative',
        NOTIFY_USERS: 'Benutzer benachrichtigen',
        TEST: 'Prüfung',
        VIEW_AS_WEBPAGE_INFO: 'Diese Option wird ein Link am Anfang der E-Mail hinzufügen, die E-Mail als Webseite zu öffnen',
        LINK_CLICK_INFORMATION: 'Wenn Sie dieses Feld leer lassen, werden alle Links in der E-Mail verfolgt werden.',
        GROUP_NAME: 'Neuer Gruppenname',
        SELECT_USER: 'Zuweisen',
        REMIND_BEFORE: 'erinnern Sie vor',
        INTERVAL_REQUIRED: 'Intervall (erforderlich)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Überprüfen Sie eine andere E-Mail-Adresse',
        'Touch Campaign': 'Touch-Kampagne',
        'Link Clicked': 'Link geklickt haben',
        'Link Clicked?': 'Link geklickt haben?',
        'Email Opened': 'E-Mail geöffnet',
        'Email Opened?': 'E-Mail geöffnet',
        'Unsubscribed': 'unsubscribed',
        'Score Reached': 'Ergebnis erreicht',
        //'Send One-off Email': 'Send One-off Email',
        'Send Email': 'E-Mail senden',
        'Change Group': 'Gruppe ändern',
        'Add Note': 'Notiz hinzufügen',
        'Notify': 'Benachrichtigen',
        'Add Task': 'Aufgabe hinzufügen',
        'Update Fields': 'Update Fields',
        'Assign': 'Zuordnen',
        'Convert': 'Konvertieren',
        'Start': 'Start',
        'Add Touch Message': 'In Touch-Nachricht',
        'Web Form': 'Web-Formular',
        'Website Visit': 'Website besuchen',
        'Landing Page': 'Landing-Page',
        'Wait': 'Warten',
        WAIT_FOR_DAYS: 'Tage)',
        'Simple email nurturing': 'Einfach E-Mail-Pflege',
        'Capture leads using a web form and nurture': 'Capture führt mit einem Web-Formular und Pflege',
        'Track web page visit and nurture': 'Track Webseite besuchen und zu pflegen',
        'Capture leads from a landing page and nurture': 'Capture führt von einer Zielseite und Pflege',
        'Start with tracking website visit': 'Beginnen Sie mit Tracking-Website-Besuch',

        COPY_OF: 'Kopie von',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Neue automatisierte Kampagne',
        TARGET_BY: 'Ziel von',
        'Hours': 'Std',
        'Hour(s)': 'Std)',
        'day(s)': 'Tage)',
        'hour(s)': 'Std)',
        ALL_AUTOMATED_CAMPAIGNS: 'Alle automatisierten Kampagnen',
        MESSAGE: 'Botschaft',
        SELECT_A_CAMPAIGN: 'Bitte wählen Sie eine Kampagne',
        ADD_SIGNATURE: 'hinzufügen Unterschrift',
        BIRTHDAY_CAMPAIGN: 'Geburtstags-Kampagne',
        'Add Birthday Message': 'In Geburtstag Nachricht',
        'Start with Birthday Campaign': 'Beginnen Sie mit Geburtstags-Kampagne',
        'SMS Replied': 'SMS Antwortete',
        'Send SMS': 'SMS senden',
        PET_BIRTHDAY_CAMPAIGN: 'Pet Geburtstag Kampagne',

        'Touch Campaign Automation': 'Touch-Kampagne Automation',
        'Birthday Campaign Automation': 'Geburtstags-Kampagne Automation',
        'Web Form Automation': 'Webformular Automation',
        'Landing Page Automation': 'Landing-Page Automation',
        'Pet Birthday Greetings': 'Pet-Geburtstags-Grüße',


        SEND_SMS: 'SMS senden',
        SMS_REPLY: 'SMS Antwortete?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Geburtstag Campaign Report',
        START_IMMEDIATELY: 'Sofort',
        START_SCHEDULE: 'Zeitplan',
        SCHEDULE_ON_TEXT: 'Voraussichtlich am Start',
        SCHEDULE_DIALOG_TITLE: 'Wählen Sie Zeitplan Datum und Uhrzeit',
        FROM_NUMBER: 'von Nummer',
        REPLY: 'Antworten',
        NOT_CONVERTED: 'nicht umgewandelte',
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'Alle Touch-Kampagnen',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalisieren Thema',
        NAME_REQUIRED: 'Name erforderlich)',
        STATUS: 'Status',
        SUBJECT_REQUIRED: 'Betreff (erforderlich)',
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv',
        TEST: 'Prüfung',
        SELECT_TEMPLATE: 'Starten Sie die Eingabe eine Vorlage auswählen',
        TYPE_HERE: 'Geben Sie hier.',
        ADD_SIGNATURE: 'hinzufügen Unterschrift',
        INSERT_IMAGE: 'Bild einfügen',
        PERSONALIZE: 'Personifizieren',
        SELECT_FROM_LIBRARY: 'Wählen Sie aus der Bibliothek',
        USE_THIS_DESIGN: 'Verwenden Sie mit diesem Design',
        DEAR: 'sehr geehrter',
        EXPAND_EDITOR: 'Ganzer Bildschirm',
        SHARE: 'Verwenden Sie sowohl für Marketing und Vertrieb von E-Mails',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'Erstellen Marketing E-Mail-Vorlage',
        COPY_OF: 'Kopie von',
        BATCH_EDIT: 'Stapelbearbeitung',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'Bearbeiten Marketing E-Mail-Vorlage',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: 'Kopieren Marketing E-Mail-Vorlage',
        CATEGORY: 'Kategorie',
        LAYOUT: 'Layout',
        'Auto Response': 'Automatische Antwort',
        'Marketing Template': 'Marketing-Vorlage',
        'Newsletter': 'Newsletter',
        'Greetings': 'Schöne Grüße',
        CHOOSE_EMAIL_BUILDER: 'Wählen Sie eine E-Mail Builder',
        DRAG_AND_DROP_BUILDER: 'Verwenden Sie die neuen Drag-and-Drop-Builder',
        LEGACY_BUILDER: 'Verwenden Sie den Legacy-Builder',
        TEMPLATE_INFO: 'Template Info',
        DESIGN: 'Design',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalisieren Thema',
        NAME_REQUIRED: 'Name erforderlich)',
        STATUS: 'Status',
        SUBJECT_REQUIRED: 'Betreff (erforderlich)',
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv',
        TEST: 'Prüfung',
        TYPE_HERE: 'Geben Sie hier.',
        ADD_SIGNATURE: 'hinzufügen Unterschrift',
        INSERT_IMAGE: 'Bild einfügen',
        PERSONALIZE: 'Personifizieren',
        DEAR: 'sehr geehrter',
        SALES_TEMPLATE_CREATE_TAB_LABEL: 'Erstellen Sie Sales E-Mail-Vorlage',
        SALES_TEMPLATE_EDIT_TAB_LABEL: 'Bearbeiten Sales E-Mail-Vorlage',
        SALES_TEMPLATE_COPY_TAB_LABEL: 'Kopieren Sales E-Mail-Vorlage',
        COPY_OF: 'Kopie von',
        SALES_TEMPLATES: 'Sales E-Mail-Vorlagen',
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalisieren Thema',
        NAME_REQUIRED: 'Name erforderlich)',
        STATUS: 'Status',
        // SUBJECT_REQUIRED: 'Subject (required)',
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv',
        TEST: 'Prüfung',
        TYPE_HERE: 'Geben Sie hier.',
        ADD_SIGNATURE: 'hinzufügen Unterschrift',
        INSERT_IMAGE: 'Bild einfügen',
        PERSONALIZE: 'Personifizieren',
        DEAR: 'sehr geehrter',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: 'Erstellen Abkommen Vorlage',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: 'Bearbeiten Abkommen Vorlage',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: 'Kopieren Vereinbarung Vorlage',
        COPY_OF: 'Kopie von',
        AGREEMENT_TEMPLATES: 'Abkommen Vorlagen',
        ADD_SIGNATURE_1: 'Unterschrift 1',
        ADD_SIGNATURE_2: 'Unterschrift 2',
        ADD_SIGNATURE_3: 'Unterschrift 3',
        ADD_SIGNATURE_4: 'Unterschrift 4',
        ADD_SIGNATURE_5: 'Unterschrift 5',
        COVER_LETTER: 'Motivationsschreiben',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'Bearbeiten SMS-Vorlage',
        SMS_TEMPLATE_COPY_TAB_LABEL: 'Summenvorlagen kopieren',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'Geben Sie Ihren Domain-Namen (schließen http oder www)',
        GENERATE_TRACKING_CODE: 'Generieren Sie Tracking-Code',
        TRACKING_CODE: 'Ihr Tracking-Code',
        COPY_TO_CLIPBOARD: 'In die Zwischenablage kopieren',
        DONE: 'Getan',
    },
    WEB_FORM: {
        SELECT_FORM: 'Wählen Sie ein Formular',
        WEB_FORM_CREATE_TAB_LABEL: 'Erstellen von Web-Formulare',
    },
    MAIL: {
        EMAIL_FOR_TESTING: 'Geben Sie E-Mail für die Prüfung',
        SEND_A_TEST_EMAIL: 'Senden Sie eine Test-Mail',
        TYPE_HERE: 'Geben Sie hier.',
        INSERT_IMAGE: 'Bild einfügen',
        ADD_SIGNATURE: 'hinzufügen Unterschrift',
        PERSONALIZE: 'Personifizieren',
        FROM: 'Von',
        SEND_MAIL: 'E-Mail senden',
        PERSONALIZE_SUBJECT: 'Personalisieren Thema',
        SUBJECT: 'Gegenstand',
        COMPUTER: 'von Computer',
        LIBRARY: 'aus Bibliothek',
        RESCHEDULE: 'Reschedule',
        TO: 'Zu',
        ATTACH: 'Eine Datei anhängen',
        CC: 'CC',
        WHEN: 'Wann',
        SENDING_TEST_MAIL: 'Senden einer Test-E-Mail ...',
        SCHEDULE: 'Zeitplan',
        SCHEDULE_DIALOG_TITLE: 'Planen Sie ein Datum und eine Uhrzeit',
        SEND_LATER: 'SENDE SPÄTER',
        SEND: 'Senden',
        RECIPIENTS: 'Empfänger',
        SENDING: 'Senden ...',
    },
    SMS: {
        TO: 'Zu',
        WHEN: 'Wann',
        FROM: 'Von',
        FROM_NUMBER: 'von Nummer',
        SEND_A_TEST_SMS: 'Senden Sie eine Test-SMS',
        TO_NUMBER: 'zu Nummer',
        STATUS: 'Status',
        ENTER_NUMBER :'Geben Sie die Nummer zum Testen ein'
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: 'Lead-Score Geschichte',
        SCORE: 'Ergebnis',
        DATE: 'Datum',
        ACTION: 'Aktion',
        USER: 'Nutzer',
        DETAILS: 'Einzelheiten',
        ACTION_NAME: 'Aktionsname',
        SCORE_DETAILS: 'Ergebnis-Details',
        BY: 'durch',
        FROM: 'von',
        LINK: 'Verknüpfung',
        CLICKED: 'klickten',
        EMAIL: 'Email',
        OPENED: 'geöffnet',
        UNSUBSCRIBED: 'unsubscribed',
        CONVERTED_FROM: 'Umgerechnet aus',
        TO: 'zu',
        IN_OPPORTUNITIES: 'in Opportunities',
        ADDED_TO: 'Hinzugefügt zu',
        REMOVED_FROM: 'Entfernt von',
        UPDATED_TO: 'aktualisiert',
        WEBFORM: 'Web-Formular',
        SUBMITTED: 'eingereicht',
        VISITED: 'hat besucht',
        EXPIRED: 'Abgelaufen',
        'Imported': 'Importiert',
        'Created': 'Erstellt',
        'Converted to Account': 'Umgerechnet auf Konto',
        'Created from Web form': 'Erstellt von Web-Formular',
        'Created from Landing page': 'Erstellt von Landing Seite',
        'Created from Zapier': 'Erstellt von Zapier',
        'Created from Outlook': 'Erstellt von Outlook',
        'Created from Outlook (Client)': 'Erstellt von Outlook (Client)',
        'Created from G Suite': 'Erstellt von G Suite',
        'Created from Office 365': 'Erstellt von Office 365',
        'Created from Facebook/twitter etc': 'Erstellt von Facebook / Twitter etc',
        'Pushed': 'gedrückt',
        'Created from API': 'Erstellt von API',
        'Inline/Quick updated': 'Inline / Schnell aktualisiert',
        'Updated': 'Aktualisiert',
        'Batch Updated': 'Batch aktualisiert',
        'Workflow Applied': 'Workflow Applied',
        'Converted': 'Umgewandelt',
        'Assigned': 'Assigned',
        'Deleted': 'Gelöscht',
        'Restored': 'Restauriert',
        'Merged': 'Zusammengeführt',
        'Main Contact updated': 'Haupt Kontakt aktualisiert',
        'Shared': 'Geteilt',

        'Link Clicked': 'Link geklickt haben',
        'Email Opened': 'E-Mail geöffnet',
        'Unsubscribed': 'unsubscribed',
        'Web Form Submitted': 'Webformular',
        'Removed from Group': 'Entfernt von Gruppe',
        'Field Updated': 'Feld Aktualisiert',
        'Added to Group': 'Hinzu Gruppe',
        'Website Visited': 'Website besucht',
        'Has Business Email': 'Hat Geschäftliche E-Mail',
        'Clicked Link': 'Clicked Verbindung',
        'Opened Email': 'Eröffnet Email',
        ACCOUNT_SCORE: 'Ihre perfekte Punktzahl für Konten ist  ',
        CONTACT_SCORE: 'Ihr perfektes Ergebnis für Kontakte ist  '
    },
    DASHBOARD: {
        TODAY: 'Heute',
        YESTERDAY: 'Gestern',
        THIS_WEEK: 'In dieser Woche',
        THIS_MONTH: 'Diesen Monat',
        THIS_QUARTER: 'Dieses Quartal',
        THIS_YEAR: 'Dieses Jahr',
        LAST_WEEK: 'Letzte Woche',
        LAST_MONTH: 'Letzten Monat',
        LAST_QUARTER: 'Letztes Vierteljahr',
        LAST_YEAR: 'Vergangenes Jahr',
        CUSTOM: 'Benutzerdefiniert',

        //Kanban View
        NEXT_MONTH: 'Nächsten Monat',
        NEXT_QUARTER: 'Nächstes Quartal',
        NEXT_THREE_MONTHS: 'Die nächsten drei Monate',
        NEXT_TWO_MONTHS: 'Die nächsten zwei Monate',

        SALES_DASHBOARD: 'Der Umsatz-Dashboard',
        MARKETING_DASHBOARD: 'Marketing-Dashboard',
        // SALES_MARKETING_DASHBOARD: 'Sales and Marketing Dashboard',
        REPOSITION_GADGET: 'Klicken und ziehen Sie diesen Gadget zu positionieren',
        'Top Sources By Lead Volume': 'Top Quellen von Lead Volume',
        'Top Sources By Lead Conversion': 'Top Quellen von Lead-Konvertierung',
        'Marketing Email Summary': 'Marketing E-Mail Zusammenfassung',
        'Top Marketing Emails by CTR': 'Top-Marketing-E-Mails von CTR',
        'Top Web Pages': 'Top-Webseiten',
        'Lead Pipeline': 'Lead-Pipeline',
        'Opportunity Pipeline': 'Opportunity-Pipeline',
        'Sales Leaderboard': 'Der Umsatz Leader',

        'Status': 'Status',
        'Count': 'Anzahl',
        'Subject': 'Gegenstand',
        'Click-Through Rate(CTR)': 'Click-Through-Rate (CTR)',
        'Website Name': 'Webseiten-Name',
        'Forecast': 'Prognose',
        'Sales person': 'Verkäufer',
        'Closed': 'Geschlossen',

        'Activities': 'Aktivitäten',
        'Accounts': 'Konten Matrix',
        'Opportunities': 'Chancen Matrix',
        'Users': 'Benutzer',
        'Appointment': 'Geplanter Termin',
        'Dialed': 'Gewählt',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'Hinweis',
        'Spoke': 'Gesprochen',
        'Task': 'Aufgabe',
        'Transferred': 'übertragen',

        'Qualified': 'Qualifiziert',
        'Presentation': 'Präsentation',
        'Proposal': 'Vorschlag',
        'Negotiation': 'Verhandlung',
        'Close': 'Schließen',

        'Lead': 'Führen',
        'Prospect': 'Aussicht',
        'Customer': 'Kunde',
    },
    LOGIN: {
        LOG_IN: 'Anmeldung',
        EMAIL_ADDRESS: 'E-Mail-Addresse',
        PASSWORD: 'Passwort',
        REMEMBER_ME: 'Behalte mich in Erinnerung',
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: 'Als Entwurf speichern',
        POST_NOW: 'Post Jetzt',
        CONNECT: 'Verbinden',
    },
    DOCUMENT_LIBRARY: {
        COMPUTER: 'Computer',
        LIBRARY: 'Bibliothek',
        IMAGES: 'Bilder',
        STOCK_IMAGES: 'Stock Bilder',
        DOCUMENTS: 'Unterlagen',
        SEARCH: 'Suche...',
        UPLOAD_DOCUMENT: 'Dokument hochladen',
        NAME: 'Name',
        TITLE:'Titel',
        TITLE_REQUIRED: 'Titel (erforderlich)',
        LINK_REQUIRED: 'Link (erforderlich)',
        TYPE: 'Art',
        OWNER: 'Inhaber',
        ALL: 'Alle',
        NO_IMAGE_FOUND: 'Kein Bild gefunden',
        UPLOAD_DIALOG_TITLE: 'Wählen Sie ein Dokument hochladen',
        SHARED_WITH: 'geteilt mit',
        UNITS: 'Einheiten',
        UNIT: 'Einheit',
        UNIT_PUSH: 'Einheit:',
        RENAME_FOLDER: 'Ordner umbenennen',
        CREATE_FOLDER: 'Ordner erstellen',
        NEW_FOLDER: 'Neuer Ordner',
        ASSETS_NOT_FOUND: 'Kein Ordner und keine Datei gefunden.',
        'Folder': 'Mappe',
        'File': 'Datei',
        IMAGE: 'Bild',
        DOCUMENT_AND_FILE: 'Dokumente und Dateien',
    },
    HEADER: {
        SALES_TEMPLATE: 'Sales E-Mail-Vorlage',
        CALENDAR_TASKS: 'Kalender / Aufgaben',
        ACCOUNT: 'Konto',
        SALES_REPORT: 'Verkaufsbericht',
        ACCOUNTS: 'Konten',
        ACCOUNTS_WITHOUT_GROUP: 'Konten ohne Gruppen',
        MY_ACCOUNT: 'Meine Accounts',
        TODAYS_ACCCOUNTS: 'Todays Accounts',
        CONTACTS: 'Impressum',
        CONTACTS_WITHOUT_ACCOUNT: 'Kontakte ohne Konto',
        CONTACTS_WITHOUT_ACCOUNTS: 'Kontakte ohne Accounts',
        CONTACTS_WITHOUT_GROUPS: 'Kontakte ohne Gruppen',
        MY_CONTACTS: 'Meine Kontakte',
        ACCOUNTS_WITHOUT_CONTACT: 'Konten ohne Kontakt',
        TODAYS_CONTACTS: 'Todays Impressum',
        ALL_APPOINMENTS: 'Alle Termine',
        MY_APPOINMENTS: 'Meine Termine',
        MY_COMPLETED_APPOINMENTS: 'Meine Completed Termine',
        MY_MISSED_APPOINMENTS: 'Mein geplatzte Termine',
        TODAYS_APPOINMENTS: 'Todays Termine',
        ALL_TASKS: 'Alle Aufgaben',
        MY_COMPLETED_TASKS: 'Meine Erledigte Aufgaben',
        MY_FUTURE_TASKS: 'Meine Zukunftsaufgaben',
        MY_PENDING_TASKS: 'Aufgaben Meine Pending',
        MY_TODAYS_TASKS: 'Meine heutigen Aufgaben',
        ONBOARDING: 'Onboarding',
        SALES_PIPELINE:'Vertriebspipeline-Bericht',
        COUPONS_CLAIM_REPORTS: 'Coupon Ansprüche Bericht',
        'Cases': 'Fälle',
        'Projects': 'Projekte',
        'Issues': 'Probleme',
        'Solutions': 'Lösungen',
        'Contacts': 'Impressum',
        'Accounts': 'Konten',
        'Opportunities': 'Chancen',
        'Appointments': 'Ausstattung',
        'Tasks': 'Aufgaben',
        'Onboarding': 'Onboarding',
        'Units': 'Einheiten',
        'Assets': 'Bibliothek',
        'Activities': 'Aktivitäten',
        'Conversions': 'Conversions',
        'Campaigns': 'Kampagnen',
        'Reports': 'Berichte',
        'Sales_templates ': 'Verkaufsvorlagen',
        'Dashboard': 'Instrumententafel',
        'Sales Leads': 'Verkaufsführend',
        'Recycle Bin': 'Konten',
        'News': 'Nachrichten',
        REPORTS: 'Berichte',
        REPORT: 'Bericht',
        TASKS_PAGE: 'Aufgaben',
        'Products': 'Produkte',
    },
    ADVANCE_SEARCH: {
        ADVANCE_SEARCH: 'Erweiterte Suche',
        ADD_FIELD:'Anderes Feld durchsuchen'
    },
    IMPORT: {
        GROUP: 'Gruppe (Erforderlich)',
        WORKFLOW: 'Workflow (Erforderlich)',
        STATUS: 'Status (Erforderlich)',
        SOURCE: 'Quelle (Erforderlich)',
        PRIMARY_SOURCE:'Primäre Quelle',
        SECONDARY_SOURCE:'Sekundärquelle',
        IMPORT: 'Importieren',
        LINK_USING: 'Link mithilfe',
        LINK_WITH: 'Verbinden mit',
        APPLY_WORKFLOW: 'Bewerben Workflow-',
        AUTO_MAP: 'Auto Karte',
        USE_SAVED_MAPPING: 'Use Saved Mapping',
        SAVE_MAPPING: 'Speichern Mapping',
        DELETE_MAPPING: 'Delete Mapping',
        DONOT_CHECK_DUPLICATE: 'Überprüfen Sie nicht Duplikate',
        CHECK_DUPLICATE: 'überprüfen Sie Duplikate',
        CHECK_DUPLICATE_BY: 'Überprüfen Sie Duplikate von',
        DONOT_IMPORT: 'Nicht importieren',
        MARK_DUPLICATE: 'Import und markiert als Duplikat',
        UPDATE: 'Aktualisieren',
        //not found in doc
        STEP_1: 'Quelle auswählen',
        STEP_2: 'Karte Felder & Import',
        FINISH: 'Fertig',
        NEXT: 'Nächster',
        MAP_CSV_COLUMNS: 'Karte CSV-Spalten zu CRM',
        MAP_XLS_COLUMNS: 'Karte XLS Spalten CRM',
        MAP_XLSX_COLUMNS: 'Karte XLSX Spalten CRM',
        MAP_TO_CRM_FIELD: 'Karte zu CRM Field',
        COLUMN: 'Säule',
        ADD_NEW_GROUP: 'Fügen Sie eine neue Gruppe',
        WORKFLOW_OPTIONAL: 'Workflow (Optional)',
        FILE_MUST_CSV: 'Die Datei muss im CSV-Format vorliegen.',
        SKIP: 'Überspringen',
        MERGE: 'Verschmelzen',
        CHECK_DUPLICATE_USING: 'Überprüfen Sie Duplikate mit',
    },
    REPORT: {
        START_DATE: 'Anfangsdatum',
        END_DATE: 'Endtermin',
        REPORT_ON: 'Berichten',
        REFRESH: 'Aktualisierung',
        VIEW: 'Aussicht',
        PRINT: 'Drucken',
        SALES_REP: 'Vertriebsmitarbeiter',
        DATE_RANGE: 'Datumsbereich',
        SALES_REPORT: 'Verkaufsberichte',
        SERVICE_REPORT: 'Serviceberichte',
        'Activity Report': 'Aktivitätsbericht',
        'Conversion Report': 'Umwandlungsbericht',
        'Call Report': 'Anrufbericht',
        'Pipeline Report': 'Pipeline Report',
        'Customer Activation Report': 'Kundenaktivierung Bericht',
        'Customer Service Type Report': 'Kundenservice Typ-Bericht',
        'Customer Assessment Report': 'Kunden Assessment Report',
        'Customer Leads Assessment Report': 'Kunden Leads Assessment Report',
        'Customer Status Report': 'Kundenstatusbericht',
        'Sales Pipeline Report': 'Verkaufspipeline-Report',
        'Days in Franchise Workflow Report': 'Franchise Workflow-Bericht',
        'Franchise Workflow Report': 'Franchise Workflow-Bericht',
        'Franchise Sales Pipeline Report': 'Franchise-Vertrieb Pipeline Report',
        'Franchise Employees Report': 'Franchise Mitarbeiter Bericht',
        'Franchise Last Contact Report': 'Franchise Letzter Kontakt Bericht',
        'InXpress User Baseline Report': 'InXpress Benutzerbasisbericht',
        'Franchise User Report': 'Franchise Anwenderbericht',
        'Sales Summary Report': 'Der Umsatz Summary Report',
        ACTIVITY_MATRIX: 'Aktivität Matrix',
        'ACTIVITY_TYPE': 'Leistungsart',
        'Service Reports': 'Serviceberichte',
        'Project Reports': 'Projektberichte',
        'CRM Activity by Account': 'CRM-Aktivität nach Konto',
        'Activity by Franchise': 'Aktivität von Franchise',
        'Franchise Activity Report': 'Franchise Tätigkeitsbericht',
        'Issue Reports': 'Ausgabe Berichte',
        'Customer Service and Sales Rep Listing': 'Customer Service und Sales Rep Listing',
        GO: 'Gehen',
        'CRM Account Details': 'CRM Kontodetails',
        'Activity by Coach': 'Aktivität von Coach',
        'Franchise Conversion Report': 'Franchise Umwandlungsbericht',
        'Task Report': 'Aufgabe Bericht',
        'Email Report': 'E-Mail-Bericht',
        'SMS Report': 'SMS Bericht',
        'Orthotic Report': 'Orthesen Bericht',
        'Greenlight Export': 'Greenlight Export',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'Konten',
            OPPORTUNITIES: 'Chancen',
        },
        'Count': 'Anzahl',
        'Grand Total': 'Gesamtsumme',
        ACTIVITIES_BY_TYPE: 'Aktivitäten nach Typ',
        ACTIVITIES_BY_SALES_REP: 'Aktivitäten von Sales Rep',
        ALL_CONVERSIONS_BY_STATUS: 'Alle Conversions nach Status',
        CUSTOMERS_BY_SOURCE: 'Kunden von Quelle',
        DEALS_BY_SALES_PER: 'Pipeline durch Vertriebsmitarbeiter',
        DEALS_BY_STAGES: 'Angebote von Stages',
        TOTAL_CALLS_BY_SALES_REP: 'Anrufe insgesamt von Sales Rep',
        TOTAL_CALL_DURATION_BY_SALES_REP: 'Gesamtgesprächsdauer von Sales Rep',
        EXCEL: 'übertreffen',
        MATRIX_BY_STATUS: 'Matrix nach Status',
        MATRIX_BY_SOURCE: 'Matrix von Quelle',
        'Status': 'Status',
        'Source': 'Quelle',
        '5 Ways Report': '5 Wege Bericht',
        'Forecast Report': 'Prognosebericht',
        CASES_BY_STATUS: 'Fälle nach Status',
        CASES_BY_PRIORITY: 'Fälle von Priority',
        PROJECTS_BY_STATUS: 'Projekte nach Status',
        PROJECTS_BY_TYPE: 'Projekte nach Typ',
        ISSUES_BY_STATUS: 'Probleme nach Status',
        ISSUES_BY_PRIORITY: 'Probleme nach Priorität',
        SUPPORT_MATRIX: 'Support-Matrix',
        CUSTOMER_BY_CASES: 'Kunden von Hüllen',
        PROJECT_MATRIX: 'Projekt Matrix',
        ISSUE_MATRIX: 'Ausgabe Matrix',
        SUB_TOTAL: 'Zwischensumme',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'Zugewiesen an',
            'Total count': 'Komplette Anzahl',
            'Company': 'Unternehmen',
            'Assigned To': 'Zugewiesen an',
        },
        WORKFLOW_SELECT: 'Wählen Sie Workflow-',
        COUPONS_NAME: 'Wählen Sie Gutscheine',
        PRODUCT_SELECT: 'Ausgewähltes Produkt',
        AVAILABLE_REPORTS: 'Verfügbare Berichte',
        WORKFLOW_STAGES: 'Stufen',
        CHART_TYPE: 'Diagramm Typ',
        SHOW: 'Zeigen',
        VALUE: 'Wert',
        COUNT: 'Zählen',
        DEALS_BY_SALES_REP: 'Pipeline durch Vertriebsmitarbeiter',
        PIPELINE:'Pipeline',
    },
    FILTER: {
        NAME_REQUIRED: 'Filtername(erforderlich)',
        REMOVE_CONDITION: 'entfernen Zustand',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'Kriterien für zugehörige Objekte',

        //not found in doc
        NEXT_BUTTON_TEXT: 'Nächster',
        PREVIOUS_BUTTON_TEXT: 'Bisherige',
        AND: 'Und',
        OR: 'Oder',
        ADD_ANOTHER: 'Neue hinzufügen',
        ADD_CONDITION: 'Bedingung hinzufügen',
        COPY_FILTER: 'Kopie von',
        FROM_DATE: 'Ab Datum',
        TO_DATE: 'Miteinander ausgehen',

        CONTAINS: 'enthält',
        DOES_NOT_CONTAINS: 'nicht enthält',
        IS_EQUAL_TO: 'entspricht',
        IS_NOT_EQUAL_TO: 'ist ungleich zu',
        IS_EMPTY: 'ist leer',

        IS_GREATER_THAN: 'ist größer als',
        IS_GREATER_THAN_OR_EQUAL_TO: 'größer als oder gleich',
        IS_LESS_THAN: 'ist weniger als',
        IS_LESS_THAN_OR_EQUAL_TO: 'kleiner als oder gleich',
        'Contacts': 'Impressum',
        'Contacts without Groups': 'Kontakte ohne Gruppen',
        'My Contacts': 'Meine Kontakte',
        'Contacts without Account': 'Kontakte ohne Konto',
        'Todays Contacts': 'Todays Impressum',
        'Contacts without Accounts': 'Kontakte ohne Accounts',
        'All records': 'Alle Einträge',
        'Added this Month': 'in diesem Monat hinzugefügt',
        'Added this Week': 'Diese Woche!',
        'My Opportunities': 'Meine Chancen',
        'Opportunities': 'Chancen',
        'Accounts': 'Konten',
        'Accounts without Contact': 'Konten ohne Kontakt',
        'Accounts without Groups': 'Konten ohne Gruppen',
        'My Accounts': 'Meine Accounts',
        'Todays Accounts': 'Todays Accounts',
        'Cases': 'Fälle',
        'Projects': 'Projekte',
        'Issues': 'Probleme',
        'Accounts without Contacts': 'Konten ohne Kontakte',
    },
    SOURCE: {
        SOURCE_NAME: 'Quellenname',
        PRIMARY_SOURCE:'Primäre Quelle',
        SECONDARY_SOURCE:'Sekundärquelle',
    },
    INXPRESS_REPORT: {
        COUNTRY_SELECT: 'Land auswählen',
        FRANCHISE_SELECT: 'Wählen Sie Franchise',
        WORKFLOW_SELECT: 'Wählen Sie Workflow-',
        START_DATE: 'Anfangsdatum',
        NO_RECORDS_FOUND: 'Keine Aufzeichnungen gefunden.',
        'Account': 'Konto',
        'Sales Rep': 'Vertriebsmitarbeiter',
        'Workflow': 'Arbeitsablauf',
        'Status': 'Status',
        REGION_SELECT: 'Region wählen',
    },
    KANBAN_REPORT: {
        WORKFLOW: 'Arbeitsablauf',
        USER: 'Nutzer',
        DURATION: 'Dauer',
        SORT_BY: 'Sortieren nach',
        REPORT_BY: 'Gemeldet von',
        'Forecast amount': 'Prognose Menge',
        'Close date': 'Abschlussdatum',
        'Sales rep': 'Vertriebsmitarbeiter',
        'Status': 'Status',
        'Convert': 'Konvertieren',
        'Edit': 'Bearbeiten',
        'Delete': 'Löschen',
        'opportunities': 'Chancen',
        'Probability': 'Wahrscheinlichkeit',
    },
    APP: {
        CURRENT: 'Ich würde die neue Version verwenden',
        LEGACY: 'Ich würde die ältere Version verwenden',
        HEADING: 'Bitte wählen Sie eine Soffront Version',
        SUB_TITLE: 'Ihre Organisation hat Zugriff auf unsere Legacy-Version. Möchten Sie es verwenden, anstatt der neuen Version?',
    },
    OTHER_VIEW: {
        SUBSCRIBE_LABEL: 'Abonnieren',
    },
    MY_INTEGRATION: {
        CONNECTING: 'Anschließen',
        DISCONNECTING: 'Trennen',
        SYNC_EMAIL: 'Synchronisieren von E-Mails',
        SYNC_CONTACT: 'Kontakte synchronisieren',
        SYNC_CALENDAR: 'Synchronisieren Kalender',
        APPEND_COMPANY: 'Anfügen Firmenname',
        APPEND_CONTACT: 'Anfügen Kontakt Name',
        CALENDAR: 'Kalender',
        SERVICE: 'Bedienung',
        INTEGRATION_HEADER: 'E-Mail- und Kalender-Integration',
        PHONE_INTEGRATION: 'Telefon-Integration',
        MY_INTEGRATIONS: 'Integrationen',
        CONTACT_FILTER: 'Kontakt Filter',
        RINGCENTRAL_HEADER: 'Ringcentral',
        SMS_INTEGRATION: 'SMS-Integration',
        QUICKBOOKS: 'QuickBooks',
        XERO:'Xero',
        INTEGRATE:'integrieren',
        SELECT_INTEGRATION_ITEMS:'Wählen Sie das zu integrierende Element aus',
        INVOICES:'Rechnungen',
        PAYMENTS:'Zahlungen',
        MANNUALLY:'Manuell (CRM > Xero)',
        AUTOMATICALLY:'Automatisch (Xero > CRM)',
        ACCOUNTING_INTEGRATION_LABEL: 'Integrieren mit',
        ACCOUNTING_INTEGRATION: 'Buchhaltungsintegration',
        QUICKBOOKS_INTEGRATION: 'Quickbooks Integration',
        EVENTBRITE_INTEGRATION: 'Eventbrite Integration',
        SELECT_A: 'Wählen Sie ein',
        CRM_CONTACT_FILTER_SELECT: 'Wählen Sie eine CRM-Kontakt Filter',
        CONTACT_GROUP_NOT_FIND: 'Kann nicht Kontakt group.Please Klick',
        HERE: 'Hier',
        TO_TRY_AGAIN: 'nochmal versuchen.',
        CONTACT_FILTER_NOT_FIND: 'Kann keinen Kontakt Filter finden.',
        SEND_SALES_EMAILS_USING: 'Senden Sie Verkaufs-E-Mails mit',
    },
    PROFILE_DETAILS: {
        PROFILE_DETAILS_LABEL: 'Profil',
        PERSONAL_INFORMATION: 'Persönliche Informationen',
        USERNAME_LABEL: 'Dies wird als Benutzername verwendet werden.',
        CHANGE_PASSWORD: 'Passwort ändern',
        MANAGE_NOTIFICATIONS: 'verwalten Benachrichtigungen',
        EMAIL_SIGNATURE: 'E-Mail Signatur',
        TASKS: 'Aufgaben',
        APPOINTMENTS: 'Ausstattung',
        TASK_EMAIL: 'Aufgabe Email',
        TASK_POPUP: 'Aufgabe Pop up',
        APPOINTMENT_EMAIL: 'Termine E-Mail',
        APPOINTMENT_POPUP: 'Termine Pop Up',
        ON: 'Auf',
        OFF: 'aus',
        CHANGE_PHOTO: 'ändern Foto',
        NEW_PASSWORD: 'Neues Passwort',
        CONFIRM_PASSWORD: 'Bestätige neues Passwort',
        NAME_REQUIRED: 'Name erforderlich)',
        EMAIL_REQUIRED: 'Email (erforderlich)',
        PHONE: 'Telefon',
        TIME_ZONE_REQUIRED: 'Zeitzone (erforderlich)',
        DATE_FORMAT: 'Datumsformat',
        LANGUAGE: 'Sprache',
        USER_COLOR: 'Benutzerfarbe',
    },
    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'Alle',
        USER: 'Nutzer',
        SYSTEM: 'System',
        MY: 'Meine',
        ATTACHMENT_INFO: 'Befestigung Info',
    },
    DOCUSIGN: {
        CONNECT_BUTTON: 'Verbinden',
        SAVE_BUTTON: 'sparen',
        CANCEL_BUTTON: 'Stornieren',
        USERNAME: 'Benutzername (erforderlich)',
        PASSWORD: 'Passwort erforderlich)',
        INTREGATOR_KEY: 'Integrator Key (erforderlich)',
        SELECT_TEMPLATE: 'Vorlage auswählen',
        SEND: 'Senden',
        DISCONNECT_BUTTON: 'Trennen',
        DOCUSIGN_SETUP_HEADER: 'DocuSign Integration',
        DOCUSIGN_CONNECT_TITLE: 'Eine Verbindung zu Ihrem Konto DocuSign',
        ADD_NOTE: 'Fügen Sie einen Kommentar (optional).',
    },
    TWILIO: {
        CALL: 'Anruf',
        VERIFY: 'Überprüfen',
        DISCONNECT: 'Trennen',
        SHOW_SCRIPTS: 'Show Scripts',
        HIDE_SCRIPTS: 'verstecken Scripts',
        CALL_SETUP: 'Rufaufbau',
        CALL_SCRIPT: 'Skript aufrufen',
        CALL_SCRIPT_COPY_TAB_LABEL: 'Anrufskript kopieren',
        COPY_OF: 'Kopie von',
        ADD_NUMBERS: 'Fügen Sie Ihre Outbound-Telefonnummern',
        OUTBOUND_PHONE_NUMBERS: 'Outbound Telefonnummern',
        ADD_ANOTHER_NUMBER: 'Fügen Sie eine andere Nummer',
        CALL_RECORDING: 'Anrufaufzeichnung',
        PHONE_CREDITS: 'Ihr Telefon Credits',
        PURCHASE_CREDITS_YOU_HAVE: 'Du hast',
        PURCHASE_CREDITS_MINUTES_LEFT: 'Minuten übrig.',
        PURCHASE_CREDITS_MORE: 'Kauf mehr Credits.',
        CALLING: 'Berufung',
        RECORD_ALL_CALL: 'Notieren Sie alle Anrufe',
        VERIFIED: 'Verifiziert',
        CLICK_TO_CALL: 'Ich bin bereit, den Anruf anzunehmen',
        SCRIPTS: 'Scripts',
        VOICE_MAILS: 'VMs',
        ADDITIONAL_INFO: 'Zusätzliche Informationen anzeigen',
        ADDITIONAL_INFO_TITLE: 'Zusätzliche Information',
        SELECT_FIELD:'Felder auswählen (maximal 3 Felder)'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'Script Name (erforderlich)',
        ADD_DETAILS: 'Script-Details (erforderlich)',
        WORKFLOW_NAME: 'Workflow-Name',
        CALL_SCRIPTS_EDIT_TAB_LABEL: 'Bearbeiten Anruf Scripts',
    },
    TWILIO_SMS: {
        ZIP_CODE: 'Postleitzahl',
        AREA_CODE: 'Vorwahl',
        ADD_TO_ACCOUNT: 'Hinzufügen',
        SMS_CREDITS: 'Ihre SMS-Credits',
        SMS_CREDITS_LEFT: 'SMS-Guthaben links.',
        SEND: 'Senden',
        NO_NUMBER_PURCHASED: 'Sie don\'t haben alle Telefonnummern noch. Verwenden Sie die Option Suchen nach Telefonnummern zu erwerben. Nach dem Kauf können Sie Nachrichten mit den Zahlen senden.',
        FIND_PHONE_NUMBER: 'Suchen nach Telefonnummern von Postleitzahl oder Telefon Vorwahl',
        FIND_PHONE_NUMBER_BY_AREA_CODE: 'Suchen nach Telefonnummern von Vorwahl',
        SEND_SMS: 'Sende Textnachricht',
        YOUR_MESSAGE: 'Deine Nachricht',
        OR: 'ODER',
        PHONE_NUMBER: 'Telefonnummer',
        AVAILABLE_TO: 'Verfügbar für',
        USER_NAME: 'Nutzername',
        USED_BY: 'Benutzertyp',
        UNIT_NAME: 'Einheitenname',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: 'Verbinden',
        LABEL: 'Eine Verbindung zu Ihrem Konto Quickbooks Online',
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'alle Layouts',
        LEFT_SIDEBAR_LAYOUT: 'Left Sidebar-Layout',
        RIGHT_SIDEBAR_LAYOUT: 'Rechtses Sidebar-Layout',
        HORIZONTAL_LAYOUT: 'Horizontales Layout',
        MULTIPLE_COLUMN_LAYOUT: 'Mehrere Spaltenlayout',
    },
    EVENTBRITE: {
        CONNECT_BUTTON: 'Verbinden',
        SELECT_ORGANISATIONS: 'Synchronisieren Ereignisse aus dieser Organisation',
        EVENTBRITE_CONNECT_TITLE: 'Eine Verbindung zu Ihrem Konto Eventbrite',
        SELECT: 'Wählen',
        EVENTBRITE_TOGGLE_TITLE: 'Während der Synchronisierung neue Kontakte hinzufügen, wenn nicht in CRM gefunden',
        EVENTBRITE_MAP_FIELDS: 'Karte Felder',
        CRM_FIELDS_TITLE: 'CRM Kontaktfelder',
        EVENTBRITE_FIELDS_TITLE: 'Eventbrite Felder',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'Kalender / Aufgaben',
        DASHBOARD: 'Instrumententafel',
        REPORTS: 'Berichte',
        SALES_TEMPLATES: 'Sales E-Mail-Vorlagen',
        SOCIAL_MARKETING: 'Social Marketing',
        WEBSITE_TRACKING: 'Website Analytics',
        SOLUTIONS: 'Lösungen',
        CONTACTS: 'Impressum',
        ACCOUNTS: 'Konten',
        OPPORTUNITIES: 'Chancen',
        PRODUCTS: 'Produkte',
        CASES: 'Fälle',
        PROJECTS: 'Projekte',
        ISSUES: 'Probleme',
        ONBOARDING: 'Onboarding',
        UNITS: 'Einheiten',
        ASSESTS: 'Bibliothek',
        OPERATIONS_ACTIVITIES: 'Aktivitäten',
        OPERATIONS_CONVERSIONS: 'Conversions',
        OPERATIONS_CAMPAIGNS: 'Kampagnen',
        OPERATIONS_REPORTS: 'Berichte',
        LEAD_CAPTURE: 'Lead Capture',
        EMAIL_MARKETING: 'E-Mail Marketing',
        'CALENDAR/TASKS': 'Kalender / Aufgaben',
        'SALES REPORTS': 'Berichte',
        'SALES TEMPLATES': 'Verkaufsvorlagen',
        'SOCIAL MARKETING': 'Social Marketing',
        'ONLINE REPUTATION': 'Online-Ruf',
        DASHBOARDS: 'Armaturenbretter',
        'SALES LEADS': 'Verkaufsführend',
        'USEFUL LINKS': 'Nützliche Links',
        NEWS: 'Nachrichten',
        LIBRARY: 'Bibliothek',
        COMMUNITY: 'Gemeinschaft',
        TRAINING: 'Ausbildung',
        SCROLL_TO_RIGHT: 'Scrolle nach rechts',
        SCROLL_TO_LEFT: 'Scrollen Sie nach links',
        Royalties: 'Lizenzgebühren',
        INBOX: 'Posteingang',
    },
    NO_RECORD: {
        'Solutions': 'Lösungen',
        'Appointments': 'Ausstattung',
        'Tasks': 'Aufgaben',
        'Groups': 'Gruppen',
        'Notes': 'Anmerkungen',
        'Attachments': 'Anhänge',
        'Marketing-templates': 'Marketing-Vorlagen',
        'Sales-templates': 'Sales-Vorlagen',
        'Web-forms': 'Web-Formulare',
        'Products': 'Produkte',
        'Broadcast-campaigns': 'Broadcast-Kampagnen',
        'Ab-campaigns': 'Ab-Kampagnen',
        'Automation-designer': 'Automation-Designer',
        'Projects': 'Projekte',
        'Invoices': 'Rechnungen',
        'Iouch-campaigns': 'Touch-Kampagnen',
        'Events': 'Veranstaltungen',
        'Sms-templates': 'SMS-Vorlagen',
        'Emails': 'E-Mails',
        'Relationships': 'Beziehungen',
        'Templates': 'Vorlagen',
        'Cases': 'Fälle',
        'Contacts': 'Impressum',
        'Accounts': 'Konten',
        'Opportunities': 'Chancen',
        'Issues': 'Probleme',
        'Activities': 'Aktivitäten',
        'Conversions': 'Conversions',
        'Salesleads': 'Verkaufsführend',
        'Source': 'Quelle',
        'Users': 'Benutzer',
        'Securitylevel': 'Sicherheitsstufen',
        'Workflows': 'Workflows',
        'Relations': 'Verhältnis',
        'Notetypes': 'Hinweis Arten',
        'Appointmenttypes': 'Terminarten',
        'Campaigntypes': 'Kampagnentypen',
        'Emailintegration': 'E-Mail-Aliasse',
        'Library': 'Bibliothek',
        'Recyclebin': 'Konten',
        'Sms': 'SMS',
        'Assignmentrules': 'Zuweisungsregeln',
        'Units': 'Einheiten',
        'Orderitems': 'Auftragspositionen',
        'Landing-pages-froala': 'Startseiten',
        'Claims': 'Ansprüche',
        'Payments': 'Zahlungen',

    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'Report Schedule',
        DAY_OF_THE_MONTH: 'Tag des Monats',
        DAY_OF_THE_WEEK: 'Wochentag',
        REPEAT_TYPE: 'Wiederholungstyp (Erforderlich)',
        REPEAT_ON: 'wiederholen Sie auf',
        REPEAT_BY: 'Wiederholen von',
        START_DATE: 'Startdatum (Erforderlich)',
        END_DATE: 'Endtermin',
        REPEAT_EVERY: 'Wiederholen alle (Erforderlich)',
        SEND_TO: 'Senden an (Pflichtfeld)',
        SCHEDULE: 'Zeitplan',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': 'Name',
            'Status': 'Status',
            'Created By': 'Erstellt von',
            'Created On': 'Erstellt am',
            'Updated By': 'Aktualisiert von',
            'Updated On': 'Aktualisiert am',
        },
        APPOINTMENT: {
            'Subject': 'Gegenstand',
            'Company': 'Unternehmen',
            'Status': 'Status',
            'Contact': 'Kontakt',
            'Contact Name': 'Kontaktname',
            'Start date': 'Anfangsdatum',
            'End date': 'Endtermin',
            'Assign to': 'Zuweisen',
            'Visible to': 'Sichtbar für',
            'Outlook Sync': 'Outlook Sync',
            'Google Sync': 'Google Sync',
            'Text': 'Text',
            'Creator': 'Schöpfer',
            'Created on': 'Erstellt am',
            'Updated by': 'aktualisiert von',
            'Updated on': 'Aktualisiert am',
        },
        TASKS: {
            'Task Name': 'Aufgabennname',
            'Company': 'Unternehmen',
            'Status': 'Status',
            'Previous Task': 'Vorherige Aufgabe',
            'Complete Date': 'Vollständiges Datum',
            'Owner': 'Inhaber',
            'Due By': 'aufgrund von',
            'Contact Name': 'Kontaktname',
            'Description': 'Beschreibung',
            'Updated on': 'Aktualisiert am',
            'Contact': 'Kontakt',
            'Start Time': 'Startzeit',
            'End Time': 'Endzeit',
            'Item': 'Artikel',
            'Billable': 'abrechenbaren',
        },
        BROADCAST: {
            'Broadcast Name': 'Broadcast-Name',
            'Status': 'Status',
            'Run Date': 'Run Datum',
            'Owner': 'Inhaber',
            'Created On': 'Erstellt am',
            'Updated On': 'Aktualisiert am',
            'Target': 'Ziel',
            'From email': 'Aus der Email',
            'From name': 'Von Namen',
            'Updated By': 'Aktualisiert von',
            'Created By': 'Erstellt von',
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'Kampagnenname',
            'Created By': 'Erstellt von',
            'Created On': 'Erstellt am',
            'Run Date': 'Run Datum',
            'Status': 'Status',
            'Target Filter': 'Ziel Filter',
            'Updated By': 'Aktualisiert von',
            'Updated On': 'Aktualisiert am',
        },
        SALES_TEMPLATE: {
            'Name': 'Name',
            'Category': 'Kategorie',
            'Subject': 'Gegenstand',
            'Status': 'Status',
            'Owner': 'Inhaber',
            'Created On': 'Erstellt am',
            'Updated By': 'Aktualisiert von',
            'Updated On': 'Aktualisiert am',
        },
        MARKETING_TEMPLATE: {
            'Name': 'Name',
            'Category': 'Kategorie',
            'Subject': 'Gegenstand',
            'Status': 'Status',
            'Owner': 'Inhaber',
            'Created On': 'Erstellt am',
            'Updated By': 'Aktualisiert von',
            'Updated On': 'Aktualisiert am',
        },
        WEB_FORM: {
            'Name': 'Name',
            'Table Name': 'Tabellenname',
            'Group': 'Gruppe',
            'Source': 'Quelle',
            'Status': 'Status',
            'Assign To': 'Zuweisen',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'Kampagnenname',
            'Status': 'Status',
            'Owner': 'Inhaber',
            'Target': 'Ziel',
            'From email': 'Aus der Email',
            'Created On': 'Erstellt am',
            'Created By': 'Erstellt von',
            'From name': 'Von Namen',
            'Updated On': 'Aktualisiert am',
            'Updated By': 'Aktualisiert von',
            'Resend All': 'Erneut senden Alle',
            'Resend After': 'Erneut senden Nach',
        },
        ACCOUNTS: {
            'Status': 'Status',
        },
        CONTACTS: {
            'Status': 'Status',
        },
        OPPORTUNITIES: {
            'Status': 'Status',
        },
        PROJECTS: {
            'Status': 'Status',
        },
        ISSUES: {
            'Status': 'Status',
        },
        CASES: {
            'Status': 'Status',
        },
        SOLUTIONS: {
            'Status': 'Status',
        },
        SALES_LEADS: {
            'Status': 'Status',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': 'Geplanter Termin',
        'Dialed': 'Gewählt',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'Hinweis',
        'Spoke': 'Gesprochen',
        'Task': 'Aufgabe',
        'Transferred': 'übertragen',
    },
    VIEW_TOGGLE: {
        LIST: 'Liste',
        COLUMN: 'Säulen',
        LIST_TITLE: 'Zur Listenansicht',
        COLUMN_TITLE: 'Wechseln Sie in der Spaltenansicht',
        TIMELINE: 'Zeitleiste',
        TIMELINE_TITLE: 'Wechseln Sie in der Timeline-Ansicht',
        DETAILS: 'Einzelheiten',
        DETAILS_TITLE: 'Wechseln Sie auf Details anzeigen',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: 'E-Mail Marketing Report',
        MY_CAMPAIGNS: 'Meine Kampagnen',
        MY_LOCATION_CAMPAIGNS: 'My Unit Kampagnen',
        RECENT_CAMPAIGNS: 'Aktuelle Kampagnen',
        BROADCAST_REPORT: 'Broadcast Campaign Report',
        TOUCH_REPORT: 'Touch Campaign Report',
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: 'Push-to',
        DOCUMENT_NOT_FOUND: 'Kein Dokument gefunden.',
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: 'Fordern Sie einen neuen Bericht',
    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: 'E-Mail-Kampagnen',
        BROADCAST_REPORT: 'Broadcast-Kampagnen',
        TOUCH_REPORT: 'Touch-Kampagnen',
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: 'Aufgabe Bericht',
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: 'Email Report',
        ERROR_REASON: 'Reason'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: 'SMS Bericht',
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: 'Orthesen Bericht',
    },
    BATCH_SMS: {
        MESSAGE: 'Deine Nachricht',
        SEND: 'Senden',
        CHARS: 'Zeichen',
        SCHEDULE: 'Zeitplan',
        SCHEDULE_SMS: 'Schedule SMS',
        SEND_LATER: 'Sende später',
    },
    SETTING_MENU: {
        MARKETING: 'Marketing',
        SECURITY: 'Benutzer und Sicherheit',
        DATA: 'Daten',
        CUSTOMIZATION: 'Anpassung',
        INTEGRATION: 'Integration',
        COMPANY: 'Gesellschaft',
        WORKFLOW_RULES:'Arbeitsabläufe & Regeln',
        LISTS: 'Listen',
        FRANCHISE: 'Franchise',
        COMPANY_SCHEDULER: 'Firmenplaner'
    },
    MARKETING_SETTING: {
        'SOURCE': 'Quellen',
        'LIBRARY': 'Bibliothek',
        'LEAD_SCORE': 'Lead-Scores',
    },
    SECURITY_SETTING: {
        'USERS': 'Benutzer',
        'SECURITY_LEVELS': 'Sicherheitsstufen',
    },
    DATA_SETTING: {
        'EXPORT': 'Export',
        'RECYCLE_BIN': 'Papierkorb',
        'FIND_DUPLICATES': 'finden Sie Dubletten',
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'Logo ändern',
        'DATA_BASE': 'Datenbank',
        'WORKFLOWS': 'Workflows',
        'RELATIONS': 'Verhältnis',
        'AUTO_FILL_VALUES': 'Auto-Fill Werte',
        'NOTE_TYPES': 'Aufgaben- und Notiztypen',
        'CAMPAIGN_TYPES': 'Kampagnentypen',
        'CORPORATE_CURRENCY': 'Corporate Währung',
        'HUB_SETTING': 'NABE',
        'COMPANY_PROFILE': 'Firmenprofil',
        'WHITE_LABEL': 'Weißes Etikett',
        'API_KEY': 'API Key',
        'RE_API_KEY': 'Re generieren API Key',
        'GENERATED_ON': 'Generiert am',
        'UPDATED_ON': 'Aktualisiert am',
        'MENU': 'Speisekarte',
        'DESKTOP_MENU': 'Desktopmenü',
        'MOBILE_MENU': 'Mobiles Menü',
        'PHONE_NUMBERS': 'Telefonnummern',
        'DESIGN_MENUS': 'Menü-Design',
        'MENU_NAME': 'Menüname',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook (Desktop)',
        'GENERATE_API': 'generieren API',
        'EMAIL_INTEGRATION': 'E-Mail-Integration',
    },
    SETTINGS: {
        ADDED: 'hinzugefügt',
        EDIT: 'Aktualisiert',
        SUCCESSFULL: 'Erfolgreich.',
        'SOURCE': 'Quelle',
        'NOTETYPES': 'Aufgaben- und Notiztypen',
        'CAMPAIGNTYPES': 'Kampagnenart',
        'CORPORATECURRENCY': 'Corporate Währung',
        'RELATIONS': 'Beziehung',
        'LIBRARY': 'Bibliothek',
        'USERS': 'Benutzer',
        'SECURITYLEVEL': 'Sicherheitsstufen',
        'WORKFLOWS': 'Workflows',
        'EMAILINTEGRATION': 'E-Mail-Aliasse',
        'RECYCLEBIN': 'Papierkorb',
        'ASSIGNMENTRULES': 'Zuweisungsregeln',
        'APPOINTMENTTYPES': 'Termin Art',
        'ROLLUP': 'Roll-Up-Regeln',
        'SUBSCRIPTION': 'Abonnement',
        'UPGRADE': 'Upgrade-Abonnement',
        'BUYPRODUCT': 'Kaufen Produkte und Schulungen',
        'BUYPROSERVICE': 'Kaufen Dienstleistungen',
        'BILLING': 'Abrechnung und Rechnungsdetails',
        'CANCELSUB': 'Abonnement beenden',
        'SUBDETAIL': 'Abonnementdetails',
        'PURCHESEMAILCREDIT': 'Kaufen E-Mail Impressum',
        'PURCHESPHONECREDIT': 'Credits Kauf Telefon',
        'PURCHESSMSCREDIT': 'Kauf SMS Credits',
        'PURCHESCARDCREDIT': 'Purchase Card Scanning Credits',
        'PURCHESCONTACTCREDIT': 'Kauf Weitere Kontakte',
        'PURCHESTRANINGCREDIT': 'Kauf Schulung',
        'PURCHESMERKETINGSERVICE': 'Kaufen Marketing Services',
        'PURCHESPROFESSONALSERVICE': 'Kaufen Dienstleistungen',
        'SETUP': 'Meine Einstellungen',
        SHOW_LOGO: 'Logo anzeigen',
        SHOW_SIGNOUT_LINK: 'Link zum Abmelden anzeigen',
        THEME_COLOR:'Thema Farbe',
        LOGO_COLOR :'Logo-Hintergrund',
        AUDIT_QUESTIONS: 'Audit-Checkliste',
        LMS_USERS: 'LMS-Benutzer',
        AUDIT_CHECKLISTS:'Audit-Checklisten',
        WEBHOOKS: 'Webhooks',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: 'Wählen Sie eine Unternehmenswährung',
    },
    UNSUBSCRIBE: {
        EMAILS: 'E-Mails',
        TEXTS: 'Texte',
        BOTHS: 'Beide',
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'Hub-Name',
        USEFUL_LINK: 'Nützliche Links',
        CASES: 'Fälle',
        SETUP_NAME: 'HUB-Setup',
        NEWS: 'Nachrichten',
        LIBRARY: 'Bibliothek',
        COMMUNITY: 'Gemeinschaft',
        TRAINING: 'Ausbildung',
        HYPER_LINK: 'Hyperlink',
        SET: 'einstellen',
        MANNUAL: 'mannual',
    },
    UNITS: {
        TASK_DETAILS: 'Aufgaben-Details',
        'Unit': 'Einheit',
        'Contact Name': 'Kontaktname',
        'Done': 'Getan',
        'Open': 'Öffnen',
        'Tasks Name': 'Aufgaben-Name',
        'Assigned To': 'Zugewiesen an',
        'Due By': 'aufgrund von',
        'Status': 'Status',
        UNIT_DETAILS_LABEL: 'Stück Details',
        FIRST_NAME: 'Vorname (erforderlich)',
        LAST_NAME: 'Nachname (erforderlich)',
        PASSWORD: 'Passwort (erforderlich)',
        RETYPE_PASSWORD: 'Passwort wiederholen (erforderlich)',
        EMAIL: 'Email (erforderlich)',
        PHONE: 'Telefon (erforderlich)',
        COMPANY: 'Company (erforderlich)',
        TIME_ZONE_SELECT: 'Zeitzone',
        COUNTRY_SELECT: 'Land',
        STATE_SELECT: 'Zustand',
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: 'Richten Sie Ihre erste Regel',
        ASSIGNMENT_RULE: 'Zuordnungsregel',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: 'Zuordnung anlegen Regel',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: 'Zuordnung bearbeiten Rule',
        NAME_REQUIRED: 'Name erforderlich)',
        ASSIGNMENT_RULES: 'Zuweisungsregeln',
        STATUS: 'Status',
        SETUP_RULE: 'Setup-Zuordnungsregel',
        USE_RULE: 'Verwenden Zuordnungsregel',
        TABLE_NAME: 'Tabellenname',
        SELECT_UNIT_WORKFLOW: 'Auswahleinheit Workflow',
        WORKFLOW_NAME: 'Workflowname',
    },
    COUPONS: {
        PREFIX: 'Präfix',
        'Coupon Name': 'Coupon-Name',
        'Discount Value': 'Rabatt Wert',
        EXPIRATION_DATE: 'Haltbarkeitsdatum',
        PODUCT_SERVICE: 'Produkt / Dienstleistung (optional)',

    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'Coupon Ansprüche Bericht',
        COUPONS_REPORT: 'Bericht',
    },

    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'Warnungen und Benachrichtigungen',
        NOTIFY_ME: 'Benachrichtigen Sie mich, wenn ...',
        REMIND_ME: 'Remind Me Vor ...',
        NOTIFY_EMAIL_OPEN: 'Meine E-Mail geöffnet wird',
        NOTIFY_EMAIL_CLICK: 'Meine E-Mail geklickt',
        NOTIFY_TEXT_REPLY: 'Mein Text ist antwortete',
        REMIND_UPCOMING_APPOINTMENT: 'Die nächsten Termine',
        REMIND_UPCOMING_TASK: 'Die nächsten Aufgaben',
        ADD_NEW_REMINDER: 'Fügen Sie eine neue Erinnerung',
        REMIND: 'Erinnern',
        DAY_BEFORE: 'Tage zuvor',
        MY_WEB_FORM_SUBMITTTED: 'Mein Web-Formular eingereicht',
        NO_DATE_FIELD: 'Keine verfügbaren Datumsfelder',
    },

    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'Pipeline Report',
        SALES_SUMMARY_REPORT: 'Der Umsatz Summary Report',
    },
    EMAIL_INTEGRATION: {
        EMAIL: 'E-Mail-Aliasse',
    },
    NEWS: {
        HEADER: 'Nachrichten',
        STATUS: {
            DRAFT: 'Entwurf',
            PUBLISHED: 'Veröffentlicht',
        },
        EDIT_TAB_LABEL: 'Bearbeiten Nachrichten',
        ADD_TAB_LABEL: 'News hinzufügen',
        CATEGORY_REQUIRED: 'Kategorie (erforderlich)',
        STATUS_REQUIRED: 'Status (erforderlich)',
        TITLE_REQUIRED: 'Titel (erforderlich)',
        SUMMARY_REQUIRED: 'Zusammenfassung (erforderlich)',
        EXTERNAL_LINK_REQUIRED: 'Externer Link (erforderlich)',
        UPLOAD_IMAGE: 'Lade ein Bild hoch',
        EXTERNAL_LINK: 'externer Link',
        ENTER_DETAILS: 'Geben Sie die Details',
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: 'Nützliche Links',
        CATEGORY: 'Kategorie',
        LINK: 'Verknüpfung',
        TITLE: 'Titel',
        NO_RECORD_FOUND: 'Keine Nützliche Links gefunden.',
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'Kein Bericht gefunden.',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: 'Wählen Sie ein Elternteil',
        SELECT_CHILD: 'Wählen Sie ein Kind',
        PARENT_FIELDS: 'Übergeordnete Objektfelder',
        CHILD_FIELDS: 'Kinderobjektfelder',
        SELECT_PARENT_FIELD: 'Wählen Sie eine übergeordnete Feld',
        SELECT_CHILD_FIELD: 'Wählen Sie ein Kind Feld',
    },
    WORKFLOW_AUTOMATION: {
        TOUCH_CAMPAIGN_REPORT: 'Touch Campaign Report',
        INTERVAL: 'Nach',
        NOTE_TYPE: 'Notizart',
        TEXT_REQUIRED: 'Hinweistext (erforderlich)',
        TASK_NAME_REQUIRED: 'Task-Name (erforderlich)',
        OWNER_REQUIRED: 'Besitzer (erforderlich)',
        DUE_BY_REQUIRED: 'Aufgrund von (erforderlich)',
        DAY_AFTER_REQUIRED: 'Tage nach (erforderlich)',
        EMAIL: 'Email',
        POP_UP: 'Aufpoppen',
        MINUTES: 'Protokoll',
        HOURS: 'Std',
        DAYS: 'Tage',
        WEEKS: 'Wochen',
        REMINDER: 'Erinnerung',
        GROUP_REQUIRED: 'Gruppe auswählen (erforderlich)',
        ADD_TO_GROUP: 'Zur Gruppe hinzufügen',
        MOVE_TO_GROUP: 'In Gruppe verschieben',
        REMOVE_FROM_GROUP: 'Aus der Gruppe entfernen',
        STATUS_REQUIRED: 'Status (erforderlich)',
        WORKFLOW_REQUIRED: 'Workflow (erforderlich)',
        CHOOSE_ANOTHER_FIELD: 'Wählen Sie ein anderes Feld',
        CHOOSE: 'Wählen',
        FIELD_FOR: 'ein Feld für',
        CAMPAIGN_NAME: 'Kampagne Name (erforderlich)',
        START: 'Start',
        STOP: 'Halt',
        REPORT: 'Bericht',
        RESUME: 'Weitermachen',
        SELECT_PAGE: 'Wählen Sie eine Seite',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'Geben Sie die URL des Links (optional).',
        ENTER_WEB_PAGE_LINK: 'Geben Sie die Webseiten-Link.',
        CLICK_TO_VIEW: 'Klicke hier, um anzusehen',
        CLICK_TO_EDIT: 'Hier klicken, um zu bearbeiten',
        DRAG_TO_MOVE: 'Ziehen zum bewegen',
        DRAG_TO_CONNECT: 'Ziehen Sie den Anschluss',
        CLICK_TO_REMOVE: 'Klicken Sie hier zu entfernen',
        CLICK_TO_SEE_REPORT: 'Klicken Sie hier um Bericht zu sehen',
        CLICK_TO_ADD_MSG: 'Klicken Sie auf Neue Nachricht hinzufügen',
        NO: 'Nein',
        YES: 'Ja',
        CLICK_TO_SELECT_USERS: 'Klicken Sie hier Benutzer auswählen',
        SALES_REP: 'Vertriebsmitarbeiter',
        USERS: 'Benutzer',
        CAMPAIGN_SENDER: 'Kampagne Sender',
        SCORE: 'Ergebnis',
        START_WITH: 'Hinzufügen',
        DECISIONS: 'Entscheidungen',
        ACTIONS: 'Aktionen',
        LINK_CLICKED: 'Link geklickt haben?',
        SCORE_REACHED: 'Punktzahl erreicht?',
        TOUCH_CAMPAIGN: 'Touch-Kampagne',
        WEB_FORM: 'Web-Formular',
        LANDING_PAGE: 'Landing-Page',
        WEBSITE_VISITED: 'Website besucht',
        CHANGE_GROUP: 'Gruppe ändern',
        ADD_NOTE: 'Notiz hinzufügen',
        NOTIFY: 'Benachrichtigen',
        ADD_TASK: 'Aufgabe hinzufügen',
        FIELD_UPDATED: 'Update Fields',
        CHOOSE_TEMPLATE: 'Wählen Sie eine Vorlage',
        USE_THIS_TEMPLATE: 'Verwenden Sie diese Vorlage',
        WAIT: 'Warten',
        NAME: 'Name',
        TARGET_GROUPS: 'Zielgruppen)',
        TARGET_FILTER: 'Ziel Filter',
        RESEND_AFTER: 'Erneut senden Nach',
        START_DATE: 'Anfangsdatum',
        SUBJECT: 'Gegenstand',
        TEXT: 'Text',
        TASK_NAME: 'Aufgabennname',
        ASSIGN_TO: 'Zuweisen',
        FIELD_LABEL: 'Feldbezeichner',
        FIELD_VALUE: 'Feldwert',
        LINK: 'Verknüpfung',
        SEND_ON_ANY_TIME: 'Jederzeit',
        SEND_ON_BUSINESS_DAY: 'Nur an Werktagen',
        CONVERT_TO: 'Konvertieren zu',
        QUALIFIER: 'Kennzeichner',
        OBJECT: 'Objekt',
        NOTIFY_SENDER: 'Benachrichtigen Sender',
        NOTIFY_SALES_REP: 'Benachrichtigen Sales Representative',
        NOTIFY_SENDER_SALESREP: 'Benachrichtigen Sie Sender, benachrichtigen Sales Representative',
        NOTIFY_USERS: 'Benutzer benachrichtigen',
        TEST: 'Prüfung',
        VIEW_AS_WEBPAGE_INFO: 'Diese Option wird ein Link am Anfang der E-Mail hinzufügen, die E-Mail als Webseite zu öffnen',
        LINK_CLICK_INFORMATION: 'Wenn Sie dieses Feld leer lassen, werden alle Links in der E-Mail verfolgt werden.',
        GROUP_NAME: 'Neuer Gruppenname',
        SELECT_USER: 'Zuweisen',
        REMIND_BEFORE: 'erinnern Sie vor',
        INTERVAL_REQUIRED: 'Intervall (erforderlich)',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Überprüfen Sie eine andere E-Mail-Adresse',
        'Touch Campaign': 'Touch-Kampagne',
        'Required Tasks Done': 'Erforderliche Aufgaben erledigt',
        'Not Converted': 'nicht konvertiert',
        'Link Clicked': 'Link geklickt haben',
        'Link Clicked?': 'Link geklickt haben?',
        'Email Opened': 'E-Mail geöffnet',
        'Email Opened?': 'E-Mail geöffnet',
        'Unsubscribed': 'unsubscribed',
        'Score Reached': 'Ergebnis erreicht',
        'Send Email': 'E-Mail senden',
        'Change Group': 'Gruppe ändern',
        'Add Note': 'Notiz hinzufügen',
        'Notify': 'Benachrichtigen',
        'Add Task': 'Aufgabe hinzufügen',
        'Update Fields': 'Update Fields',
        'Assign': 'Zuordnen',
        'Convert': 'Konvertieren',
        'Start': 'Start',
        'Add Touch Message': 'In Touch-Nachricht',
        'Web Form': 'Web-Formular',
        'Website Visit': 'Website besuchen',
        'Landing Page': 'Landing-Page',
        'Wait': 'Warten',
        WAIT_FOR_DAYS: 'Tage)',
        'Simple email nurturing': 'Einfach E-Mail-Pflege',
        'Capture leads using a web form and nurture': 'Capture führt mit einem Web-Formular und Pflege',
        'Track web page visit and nurture': 'Track Webseite besuchen und zu pflegen',
        'Capture leads from a landing page and nurture': 'Capture führt von einer Zielseite und Pflege',
        'Start with tracking website visit': 'Beginnen Sie mit Tracking-Website-Besuch',
        COPY_OF: 'Kopie von',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Neue automatisierte Kampagne',
        TARGET_BY: 'Ziel von',
        'Hours': 'Std',
        'Hour(s)': 'Std',
        'day(s)': 'Tag(e)',
        'hour(s)': 'Std',
        ALL_AUTOMATED_CAMPAIGNS: 'Alle automatisierten Kampagnen',
        MESSAGE: 'Botschaft',
        SELECT_A_CAMPAIGN: 'Bitte wählen Sie eine Kampagne',
        ADD_SIGNATURE: 'hinzufügen Unterschrift',
        BIRTHDAY_CAMPAIGN: 'Geburtstags-Kampagne',
        'Add Birthday Message': 'In Geburtstag Nachricht',
        'Start with Birthday Campaign': 'Beginnen Sie mit Geburtstags-Kampagne',
        'SMS Replied': 'SMS Antwortete',
        'Send SMS': 'SMS senden',
        PET_BIRTHDAY_CAMPAIGN: 'Pet Geburtstag Kampagne',
        'Touch Campaign Automation': 'Touch-Kampagne Automation',
        'Birthday Campaign Automation': 'Geburtstags-Kampagne Automation',
        'Web Form Automation': 'Webformular Automation',
        'Landing Page Automation': 'Landing-Page Automation',
        'Pet Birthday Greetings': 'Pet-Geburtstags-Grüße',
        SEND_SMS: 'SMS senden',
        SMS_REPLY: 'SMS Antwortete?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Geburtstag Campaign Report',
        START_IMMEDIATELY: 'Sofort',
        START_SCHEDULE: 'Zeitplan',
        SCHEDULE_ON_TEXT: 'Voraussichtlich am Start',
        SCHEDULE_DIALOG_TITLE: 'Wählen Sie Zeitplan Datum und Uhrzeit',
        FROM_NUMBER: 'von Nummer',
        REPLY: 'Antworten',
        NOT_CONVERTED: 'Wenn nicht conveted mit in',
        DAYS_AFTER: 'Tage später',
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'Landing-Page',
        LANDING_PAGES: 'Startseiten',
        NAME_REQUIRED: 'Name erforderlich)',
        URL_REQUIRED: 'URL (erforderlich)',
        LANDING_PAGE_FORMS: 'Form Builder',
        EDIT_LANDING_PAGE: 'Bearbeiten Landing-Page',
        COPY_LANDING_PAGE: 'Kopieren Landing-Page',
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: 'finden Sie Dubletten',
        SELECT_OBJECT: 'Objekt auswählen',
        SELECT_FIELD: 'Wählen Sie Feld',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: 'Terminplaner',
        APPOINTMENT_INFO: 'Termin Info',
        AVAILABILITY:'Verfügbarkeit',
        APPOINTMENT_TYPES:'Terminarten',
        MY_AVAILABILITY: 'Meine Verfügbarkeit',
        SCHEDULER_LINK: 'Zeitplaner-Link',
        INTAKE_FORM: 'Aufnahmeformular',
        APPOINTMENT_NAME: 'Termin Name (erforderlich)',
        LOCATION: 'Ort',
        TASKS:'Aufgaben',
        UNIT_CRM:'Einheit Crm',
        NOTIFICATION_SETUP:'Benachrichtigungseinrichtung',
        DURATION: 'Dauer',
        MIN_ADVANCE_NOTICE: 'Mindest Vorankündigung',
        DESCRIPTION: 'Beschreibung',
        COPY_TO_CLIPBOARD: 'Kopieren meiner Zwischenablage',
        SCHEDULER_LINK_TITLE: 'Mein Terminplaner Link',
        SCHEDULING_PAGE: 'Terminplaner',
        ADD_BUTTON: 'Fügen Sie einen neuen Zeitplan',
        MY_SCHEDULAR_LINK: 'Mein Scheduler Link',
        MY_APPOINTMENT_TYPE: 'Mein Termintyp',
        APPOINTMENT_TYPE: 'Termintyp',
        DEFINE_YOUR_INTAKE_FORM: 'Definieren Sie Ihr Aufnahmeformular',
        REQUIRED: 'Erforderlich',
        ADD_ANOTHER_FIELD: 'Fügen Sie ein weiteres Feld hinzu',
        SUBMIT_BUTTON_LABEL: 'Senden Sie Button Label',
        CHOOSE_A_STATUS_FOR_LEAD: 'Wählen Sie im Scheduler einen Status für die hinzugefügten Leads',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'Definieren Sie Ihre Dankesnachricht',
        THANK_YOU_MESSAGE: 'Danke Nachricht',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: 'Beziehungsmatrix',
        REPORT_SAVE_LABEL: 'Speichern Customization',
        REPORT_NAME_LABEL: 'Benutzerdefinierte Berichtsnamen',
        REPORT_NAME_TEXTFIELD_LABEL: 'Berichtsname',
        ROW_FIELD_LABEL: 'Zeilenfeld',
        COL_FIELD_LABEL: 'Spalte Feld',
        DATE_FIELD_LABEL: 'Datumsfeld',
        PDF_LABEL_DURATION: 'Dauer',
        RELATION_FIELD_LABEL: 'Beziehung',
        SAVE_CUSTOMIZATION_LABEL: 'Speichern Anpassung',
        RUN_BUTTON_LABEL: 'Lauf',
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'Laden Sie Ihr Logo',
        WEBSITE: 'Webseite',
        BUSINESS_NAME: 'Business Name',
        BUSINESS_ADDRESS: 'Geschäftsadresse',
        BUSINESS_PHONE: 'Geschäftstelefon',
        BUSINESS_EMAIL: 'Geschäftliche E-Mail',
        CORPORATE_CURRENCY: 'Corporate Währung',
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: 'Export',
        FILE_NAME_REQUIRED: 'Datei-Name (erforderlich)',
        PARENT_OBJECT_REQUIRED: 'Übergeordnetes Objekt Name (erforderlich)',
        FILE_TYPE_REQUIRED: 'Dateityp (erforderlich)',
        QUERY_NAME_REQUIRED: 'Abfrage Name (erforderlich)',
        SELECTED_FIELDS_FOR_PARENT: 'Ausgewählte Felder für Eltern',
        AVAILABLE_FIELDS_FOR_PARENT: 'Verfügbare Felder für Eltern',
        CHILD_OBJECT_NAME: 'Child Object Name',
        AVAILABLE_FIELDS_FOR_CHILD: 'Verfügbare Felder für Kind',
        SELECTED_FIELDS_FOR_CHILD: 'Ausgewählte Felder für Kind',
        EXPORT_BUTTON: 'Export',
        DOWNLOAD_BUTTON: 'Download-Datei',
        ADD: 'Hinzufügen',
        ADD_ALL: 'Füge alle Hinzu',
        REMOVE: 'Entfernen',
        REMOVE_ALL: 'Alles entfernen',
    },
    QUEUE: {
        QUEUE: 'Warteschlange',
        PREVIOUS: 'Zurück in der Warteschlange',
        NEXT: 'Als nächstes wird in der Warteschlange',
        REMOVE: 'Entfernen aus der Warteschlange',
        CLOSE: 'Verlassen Sie die Warteschlange',
    },
    SECURITY_LEVEL: {
        ACCESS: 'Zugriff',
        DESCRIPTION: 'Beschreibung',
        VIEW: 'Aussicht',
        PERMISSIONS: 'Berechtigungen',
        OPERATIONS: 'Geschäftstätigkeit',
        ADMINISTRATION: 'Verwaltung',
        ASSIGNED_MENU: 'Zugeordnete Menü',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: 'Untergeordnetes Objekt',
        PARENT_OBJECT_NAME: 'Übergeordnetes Objekt',
        CHILD_FIELD_NAME: 'Untergeordneter Feldname',
        PARENT_FIELD_NAME: 'Übergeordnetes Feldname',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'Lizenzbericht',
        ROYALTY_PERCENT: 'Lizenzgebühren %',
        SHOW: 'Zeigen',
        ALL_INVOICES: 'Alle Rechnungen',
        PAID_INVOICE_ONLY: 'Nur bezahlte Rechnungen',
        SETUP_TITLE: 'Einrichtung von Lizenzgebühren',
        PAID_INVOICES: 'Bezahlte Rechnungen',
        USE: 'Verwenden',
        FIXED: 'Feste %',
        VARIABLE: 'Variable %',
        TOTAL_INVOICES: 'Gesamtrechnungen.',
        TOTAL_PAYMENTS: 'Gesamtzahlungen',
        CALCULATE: 'Berechnung',
        WEEKLY: 'Wöchentlich',
        MONTHLY: 'Monatlich',
        WEEK_TYPE: 'Wochentyp',
        MON_TO_FRI: 'Mo - Fr.',
        MON_TO_SUN: 'Mo - Sun.',
        ADD_INVOICES: 'Rechnung hinzufügen',
        TOTAL_Reported_Sales: 'Gesamtzahl der gemeldeten Umsätze',
        YEAR: 'Jahr',
        MARKETING_FEE: 'Marketinggebühr',
        TECH_FEE: 'Technologiegebühr',
        PERCENT: 'Prozentsatz',
        FIXED_VALUE: 'Fest',
        WITH: 'Mit Gebühren',
        WITH_OUT: 'Ohne Gebühren',
        Reported_Sales_Franchisee: 'Gemeldete Verkäufe - Franchisenehmer',
        Reported_Sales_Franchisor: 'Gemeldete Verkäufe - Franchisegeber',
        Reported_Sales_ThirdParty: 'Gemeldete Verkäufe - Drittanbieter',
    },
    POWER_CALLING: {
        NOTE_AND_CALL_SCRIPTS: 'Notiz- und Aufrufskripte',
        SEND: 'SENDEN',
        LOG_CALL: 'Anruf protokollieren',
        CALLER_ID: 'Anruferidentifikation',
        END_CALL: 'Anruf beenden',
        MOBILE: 'Handy, Mobiltelefon',
        PHONE: 'Telefon',
        HOME_PHONE:'Festnetztelefon',
        NEXT: 'Nächster',
        COMPLETE: 'Komplett',
        OF: 'von',
        POWER_DIALER: 'Power Dialer'
    },
    LEAD_SCORE_SETUP:{ 
        SCORES: 'Scores',
        NO_SCORES_AVAILABLE: 'Es sind keine Aktionen verfügbar.',
        OBJECT: 'Objekt',
        GROUPS:'Gruppen',
        WORKFLOW_NAME: 'Workflow-Name',
        CONVERTED_FROM:'Konvertiert von',
        CONVERTED_TO: 'Konvertiert zu',
        FIELDS_NAME: 'Feldname',
        POINTS: 'Punkte',
        EXPIRES_BY: 'Läuft ab bis',
        APPLY_TO_EXISTING_SCORE: '* Das Ablaufdatum gilt rückwirkend für Ihre vorhandenen Ergebnisse.',
        GROUP_ALREADY_REMOVE: 'Gruppe bereits entfernt. Bitte vor dem Speichern ändern.',
        FIELD_ALREADY_REMOVE: 'Feld bereits entfernt. Bitte vor dem Speichern ändern.',
        FIELD_VALUE_ALREADY_REMOVE:'Feldwert bereits entfernt. Bitte vor dem Speichern ändern.',
        AND_ABOVE: 'und darüber',
        LESS_THAN: 'Weniger als',
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: 'doppelte Regeln',
        DUPLICATE_RULE_HEAD_LABEL: 'Überprüfen Sie Dubletten Verwendung',
        SELECT_OBJECT_LABEL: 'Objekt auswählen',
        ACTIVE: 'Aktiv',
        DEFAULT: 'Standard',
        ONE_FIELD: 'Ein Feld',
        TWO_FIELD: 'zwei Felder',
        THREE_FIELD: 'drei Felder',
        FIELD_ONE: 'Feld 1',
        FIELD_TWO: 'Feld 2',
        FIELD_THREE: 'Feld 3',
        SELECT_FIELD_LABEL: 'Wählen Sie ein Feld',
        CHECK_DUPLICATES: 'überprüfen Sie Dubletten',
        SELECT_RULES: 'Wählen Sie einen Regel',
    },
    JOBS:{
        RESCHEDULE: 'Umplanen',
        OMW:'omw',
        INVOICE:'Rechnung',
        PAY:'Zahlen'
    },
    TASK_REPORT:{
        TITLE:'Aufgabenbericht',
        UNITS:'Einheiten',
        NO_TASK_FOUND:'Keine Aufgaben gefunden',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'Aufgabenberichtdetails',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'Es sollte mindestens 2 Möglichkeiten in dieser Frage sein',
        SINGLE_RECORD: 'Nur 1 Datensatz darf sich gleichzeitig bewegen.',
        AUDIT_QUESTIONS: 'Audit-Checkliste',
        GIVE_FEEDBACK:'Feedback geben',
        NEW_QUESTION: 'Neue frage.',
        TEXT: 'Kurzer Text',
        COMMENT: 'Langer Text',
        LIST: 'Einzelauswahl (Dropdown)',
        CHECKBOX: 'Multiple-Wahl (Kontrollkästchen)',
        QUESTION: 'Frage',
        ADD_NEW: 'Neue hinzufügen',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: 'Bitte wählen Sie 1 Datensatz, um zu bearbeiten.',
        MESSAGE_DELETE: 'Wollen Sie sicher löschen?',
        SELECTED_RECORDS: 'Ausgewählte Datensätze (en)?',
        YOUR_ANSWER: "Ihre Antwort",
        SUBMITTING: 'Senden ...',
        SAVE_CATEGORY:'Kategorie speichern',
        SUBMIT_AUDIT:'Audit einreichen',
        SUBMIT: 'einreichen',
        SCORE: 'Punktzahl',
        REQUIRED: 'Erforderlich',
        CHOICE: 'Auswahl',
        FIELD_REQUIRED: 'Dieses Feld wird benötigt *',
        NEW_CHOICE: 'Neue Wahl',
        FREE_FORM: 'Freies Formular',
        SCORE_HELP_TOOLTIP: "Weisen Sie Punkte oder Punkte zu, um Antworten zuzuordnen. Die Partituren summieren sich zur Berechnung der Gesamtpunktzahl für die Prüfung.",
        SURVEY_LINK: 'Audit / Umfrage-Link',
        NEXT_CATEGORY:'Nächste Kategorie',
        GENERATE:'Generieren',
        FIELD_AUDIT:'Feldaudit (vom Franchisegeber geprüft)',
        SELF_AUDIT:'Selbstprüfung (durch den Franchisenehmer geprüft)',
        GENERATE_AUDIT_LINK:'Wählen Sie eine Checkliste',
        REQUIRES_PHOTO: 'Erfordert Foto',
        ENABLE_NOTE: 'Notiz aktivieren',
        ADD_PHOTO: 'Foto',
        UPDATE_PHOTO: 'Aktualisieren',
        NOTE: 'Notiz hinzufügen',
        PHOTO_REQUIRED: 'Foto ist erforderlich *',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: 'Audit-Antwort',
        QUESTION:'Frage',
        SCORE: 'Punktzahl',
        ANSWER: 'Antworten',
        SUBMIT_CORRECTIVE_ACTIONS:'Senden Sie Korrekturmaßnahmen',
        REQUEST_CORRECTIVE_ACTIONS:'Fordern Sie Korrekturmaßnahmen an',
        INSERT_PHOTO:'Foto einfügen',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'Kategorie',
        MIN_FAILURE_COUNT:'Min. Fehleranzahl',
    },
    AUDIT_CATEGORY:{
        ADD_NEW_CATEGORY:'Neue Kategorie hinzufügen',
        MANAGE_CATEGORY: 'Kategorien verwalten',
        CATEGORIES:'Kategorien',
        CATEGORY_QUESTIONS_LABLE:'Kategorie Fragen',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'Compliance Bericht',
    },
    SALES_AND_REFUND: {
        PERIOD:'Zeitraum',
        START_DATE:'Startdatum',
        END_DATE :'Endtermin',
        BASED_ON :'Bezogen auf',
        SALES_AND_REFUND_TITLE:'Verkauf und Rückerstattung',
        SALES_AND_REFUND_DETAIL:'Verkaufs- und Rückerstattungsdetails',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'Details zum Verkaufs- und Rückerstattungsbericht',
        START_DATE:'Startdatum',
        END_DATE:'Endtermin',
        BASED_ON:'Bezogen auf',
        NO_RECORD_EXPORT: 'Kein Eintrag gefunden'
    },
    AUDIT_CHECKLISTS:{
        TITLE:'Neue Audit-Checkliste',
        EDIT_TITLE:'Audit-Checkliste bearbeiten',
    },
    UNIT_CRM:{
        MY_SETTINGS:'Meine Einstellungen',
        COMPANY:'Unternehmen',
        USER_AND_SECURITY:'Benutzer und Sicherheit',
        SURVEYS: 'Umfragen',
        WORKFLOW_RULES_SECTION:'Abschnitt „Workflows und Regeln“',
        EMAIL_ALIASES:'E-Mail-Aliasse',
        CUSTOMIZATION:'Anpassung',
        LISTS:'Listen',
        DATA:'Daten',
        COMPANY_SCHEDULER:'Firmenplaner',
        FRANCHISE:'Franchise',
        INTEGRATION:'Integration',
        SUBSCRIPTION:'Abonnement',
        CAMPAIGNS:'Kampagnen',
        BOARDCAST_CAMPAIGNS :'Broadcast-Kampagnen',
        AUTOMATED_CAMPAIGNS:'Automatisierte Kampagnen',
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'Drilldown der gemeldeten Lizenzgebühren',
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: 'Zur Benachrichtigung verwenden',
        REMOVE_NOTIFICATION_TOOLTIP: 'Zur Benachrichtigung entfernen',    
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: 'Verwalten Sie die Telefonnummern, SMS-Guthaben und Gesprächsminuten der Einheit',
    },
}
export default label