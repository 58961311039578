import React, { useEffect } from "react";
import { FormControl, Select, MenuItem, InputLabel, Icon } from '@mui/material';
import { isValidParam, getStringParam, getIntParam, getArrayParam, getObjectParam, makeValidAppUrl, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import { dateFormatFlatPicker, dateFormat, sortArrayObjectByPropertyForCurrency, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import PopOver from './PopOver';


const PERIOD_ITEMS = ["Today", "Yesterday", "This Week", "This Month", "This Quarter", "This Year", "Last Week", "Last Month", "Last Quarter", "Last Year", "Custom"];



const DateField = () => {
  const appState = useSelector((state) => state.app);
  const pipelinedata = useSelector((state) => { return state.salesPipelineReport });
  const { period, startDate, endDate } = pipelinedata;


  useEffect(() => {
    if(!period && !startDate && !endDate){
    const period = "This Year";
    const startDate = moment().startOf('year').format(dateFormat[appState.me.date_format]);
    const endDate = moment().endOf('year').format(dateFormat[appState.me.date_format]);
    dispatch({ type: "SET_PERIOD", payload: period });
    dispatch({ type: "SET_STARTDATE", payload: startDate });
    dispatch({ type: "SET_ENDDATE", payload: endDate });
    }
    else{
    dispatch({ type: "SET_PERIOD", payload: period });
    dispatch({ type: "SET_STARTDATE", payload: startDate });
    dispatch({ type: "SET_ENDDATE", payload: endDate });
    }
  }, [])


  const dispatch = useDispatch();

  const handleFlatpkrDateChange = (date, field) => {
    try {
      if (isValidParam(date)) {
        let selectedDate = new Date(date);
        let dateValue = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        );
        const momentDate = moment(dateValue);
        if (field === 'start_date') {
          dispatch({ type: "SET_STARTDATE", payload: momentDate.format(dateFormat[appState.me.date_format])});
        }
        if (field === 'end_date') {
          dispatch({ type: "SET_ENDDATE", payload: momentDate.format(dateFormat[appState.me.date_format])});
        }
      } else {
        if (field === 'start_date') {
          dispatch({ type: "SET_STARTDATE", payload: "" });;
        }
        if (field === 'end_date') {
          dispatch({ type: "SET_ENDDATE", payload: "" });
        }
      }
    } catch (error) {
      console.error(
        "Error in 'RoyaltyReportV2.js -> handleFlatpkrDateChange()':" + error
      );
    }
  }

  const handlePeriodChange = (event) => {
    const { value } = event.target;
    if (value.trim()) {
      let m = moment();
      let startDate = '';
      let endDate = '';
      switch (value.trim().toLocaleLowerCase()) {
        case 'custom':
          break;
        case 'today':
          startDate = m.startOf('day').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('day').format(dateFormat[appState.me.date_format]);
          break;
        case 'yesterday':
          m.subtract(1, 'days');
          startDate = m.startOf('day').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('day').format(dateFormat[appState.me.date_format]);
          break;
        case 'this week':
          startDate = m.startOf('week').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('week').format(dateFormat[appState.me.date_format]);
          break;
        case 'this month':
          startDate = m.startOf('month').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('month').format(dateFormat[appState.me.date_format]);
          break;
        case 'this quarter':
          startDate = m.startOf('quarter').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('quarter').format(dateFormat[appState.me.date_format]);
          break;
        case 'this year':
          startDate = m.startOf('year').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('year').format(dateFormat[appState.me.date_format]);
          break;
        case 'last week':
          m.subtract(1, 'weeks');
          startDate = m.startOf('week').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('week').format(dateFormat[appState.me.date_format]);
          break;
        case 'last month':
          m.subtract(1, 'months');
          startDate = m.startOf('month').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('month').format(dateFormat[appState.me.date_format]);
          break;

        case 'last year':
          m.subtract(1, 'years');
          startDate = m.startOf('year').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('year').format(dateFormat[appState.me.date_format]);
          break;

        case 'last quarter':
          m.subtract(1, 'quarters');
          startDate = m.startOf('quarter').format(dateFormat[appState.me.date_format]);
          endDate = m.endOf('quarter').format(dateFormat[appState.me.date_format]);
          break;

        default: break;
      }
      dispatch({ type: "SET_STARTDATE", payload: startDate});
      dispatch({ type: "SET_ENDDATE", payload: endDate});
    }
    dispatch({ type: "SET_PERIOD", payload: value });
  }
  let datePickerOptions = {
    enableTime: false,
    dateFormat: dateFormatFlatPicker[appState.me.date_format],
    minuteIncrement: 1,
    static: true
  }
  return (
    <>
      <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '14rem', marginLeft: '8px' }}>
        <FormControl variant="outlined" style={{}} className='rrfc-select'>
          <InputLabel id="sf-salesandrefund-simple-select-timeline-outlined-label" style={{ marginTop: period?.length > 0 ? '3px' : 0, background: '#ffffff', padding: 1 }} className="sf-salesandrefund">Date Range</InputLabel>
          <Select
            key="timeline"
            name="timeline"
            labelId='timeline-label'
            id='SalesandrefundPeriod'
            style={{ width: '14rem', height: 230 }}
            value={period}
            size="small"
            className={"sf-select-height"}
            label={getLocalizedStrings().label.SALES_AND_REFUND.PERIOD}
            onChange={handlePeriodChange}
          >
            {
              PERIOD_ITEMS.map(item => <MenuItem key={item} value={item} style={styles.popoverMenuItem} >{item}</MenuItem>)
            }
          </Select>
        </FormControl>
      </div>
      <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '14rem' }} >
        <div
          style={{ marginLeft: '15px' }}
          className={"sf-form-field dateTimeDiv-start_date"}
          id="dateholder"
        >
          <PopOver
            id="SalesandrefundDate"
            key={"start_date"}
            name={"start_date"}
            btnType={"date"}
            buttonStyle={{ fontSize: "16px" }}
            containerStyle={{ lineHeight: 1, width: '14rem' }}
            buttonLabel={getLocalizedStrings().label.SALES_AND_REFUND.START_DATE}
            onChange={(date) => handleFlatpkrDateChange(date, "start_date")}
            innerLabelStyle={{
              padding: "14 5 2 5",
              fontSize: "15px",
            }}
            value={startDate}
            height={47}
            options={datePickerOptions}
            disable={!period || period === '' || period.toLocaleLowerCase() !== 'custom'}
          />
        </div>
      </div>
      <div className="col-md-1" style={{ display: 'flex', width: '14rem', marginLeft: '15px' }} >
        <div
          style={{}}
          className={"sf-form-field dateTimeDiv-end_date"}
          id="dateholder2"
        >
          <PopOver
            id="SalesandrefundDate1"
            key={"end_date"}
            name={"end_date"}
            btnType={"date"}
            buttonStyle={{ fontSize: "16px" }}
            containerStyle={{ lineHeight: 1, width: '14rem', marginLeft: '0px' }}
            buttonLabel={getLocalizedStrings().label.SALES_AND_REFUND.END_DATE}
            onChange={(date) => handleFlatpkrDateChange(date, "end_date")}
            innerLabelStyle={{
              padding: "14 5 2 5",
              fontSize: "15px",
            }}
            height={47}
            options={{ ...datePickerOptions, minDate: startDate }}
            value={endDate}
            disable={!period || period === '' || period.toLocaleLowerCase() !== 'custom'}
          />
        </div>
      </div>
    </>
  );
};

export default DateField;

