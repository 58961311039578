import React, { useState, useEffect } from 'react';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getMergeField } from '../../../services/actions/smsAction';
import SFAutoComplete from '../components/sfAutoComplete';
import { hasAccessPermission } from '../../../services/helper/common';
import PopOver from '../components/PopOver';
import { useDispatch, useSelector } from "react-redux";
import * as sfDialogs from '../components/sfDialogs';
import { getIntParam, getStringParam, getArrayParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { merge_fields } from '../../../services/helper/utils';



const SmsTemplate = ({ id, type,data, object, closeDrawer}) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);

    const [template, setTemplate] = useState({
        name: '',
        sms_message: '',
        id: id | 0,
        status: 'Active',
    });
    const [mounted, setMounted] = useState(false);
    const [buttonVisible, setButtonVisible] = useState('inline-block');
    const [cursorposition, setCursorposition] = useState(0);
    const [smslength, setSmslength] = useState('0');
    const [isOpenAttachmentPopover,setIsOpenAttachmentPopover] = useState(null);
    const [isOpen,setIsOpen] = useState(false);
    const [attachFiles, setAttachFiles] = useState([]);
    const [mergeFields, setMergeFields] = useState(null);
    const [statusArr, setstatusArr] = useState([
        (<MenuItem key={'Active'} value={'Active'} id={'Active'} style={styles.popoverMenuItem}>{getLocalizedStrings().label.SALES_TEMPLATE.ACTIVE}</MenuItem>),
        (<MenuItem key={'Inactive'} value={'Inactive'} id={'Inactive'} style={styles.popoverMenuItem}>{getLocalizedStrings().label.SALES_TEMPLATE.INACTIVE}</MenuItem>)
    ]);
    const [dummyData,setDummyData]  = useState(false);



    useEffect(() => {
        loadSmsTemplateData(id);
    }, []);

    const loadSmsTemplateData = (id) => {
        if (id !== null && id !== undefined && id !== 0) {
            var response = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.EDIT + '/' + id, null));
            response.then((response) => {
                let tempTemplate = template;
                tempTemplate.name = response.name;
                tempTemplate.status = response.status;
                tempTemplate.sms_message = response.sms_message;
                tempTemplate.id = response.id;
                if (response.hasOwnProperty("image_object") && 
                    response.image_object !== null && response.image_object !== "") {
                    let attach = [];
                    let tempAttach = [];
                    attach = response.image_object.split('###');
                    if (attach.length > 0) {
                        attach.forEach(element => {
                            let obj = {};
                            let elm = element.split('@#@');
                            obj.name = elm[0];
                            obj.src = elm[1];
                            obj.id = elm[2];
                            tempAttach.push(obj);
                        });
                    }
                    setAttachFiles(tempAttach);
                }
                setSmslength(response.sms_message.length);
                setButtonVisible('inline-block');

                if (type === "copy") {
                    let tempTemplate = template;
                    tempTemplate.id = 0;
                    tempTemplate.name = getLocalizedStrings().label.SALES_TEMPLATE.COPY_OF + '_' + tempTemplate.name;
                    setButtonVisible('none');
                }
                setTemplate(tempTemplate);
            });
        } else {
            let tempTemplate = template;
            tempTemplate.name = '';
            tempTemplate.sms_message = '';
            tempTemplate.id = 0;
            setTemplate(tempTemplate);
            setSmslength(template.sms_message.length)    
            setButtonVisible('none');
        }
        getMergeFieldObject();
    }

    const getMergeFieldObject = () => {
        try {
            let params = {};
            let promise = Promise.resolve(getMergeField(constants.CONTACTS_OBJECT, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        setMergeFields(response);
                        setMounted(true);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'SmsTemplate.js -> getMergeFieldObject()':" + error);
        }

    }

    const handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        let tempTemplate = template;
        if (name === 'sms_message') {
            setSmslength(value.length)
        }
        setCursorposition(event.target.selectionStart);
        if (name === 'Name') {            
            setTemplate({...tempTemplate,name:value});
        } else {
            setTemplate({...tempTemplate,sms_message:value});
        }
      
    };

    const handleStatusChange = ( event, value) => {
        let temptemplate = template;

       setTemplate({...temptemplate,status:value});
    };

    const saveTemplate = () => {
        if (!validateForm()) {
            return false;
        }

        //let template = this.state.template;

        let params = {
            "id": template.id,
            "name": template.name,
            "sms_message": template.sms_message,
            "status": template.status
        }
        let imgObj = {};
        let tempAttachFiles = getArrayParam(attachFiles);

        if (tempAttachFiles.length > 0) {
            let tempAttach = '';
            tempAttachFiles.forEach((file, index) => {
                if (index === 0) {
                    tempAttach = file.name + '@#@' + file.src + '@#@' + file.id;
                } else {
                    tempAttach = tempAttach + '###' + file.name + '@#@' + file.src + '@#@' + file.id;
                }
            })
            imgObj = {
                'image_object': tempAttach
            }
            Object.assign(params, imgObj)
        }
        var response = Promise.resolve(HTTPClient.post(endPoints.SMS_TEMPLATE.SAVE, params));
        response.then((response) => {
            if (response.status === 0) {
                if (params.id === 0) {
                    let tempTemplate = template;
                    tempTemplate.id = response.data.id[0];
                    setTemplate(tempTemplate);
                    setButtonVisible('none');
                }
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                setMounted(true);
                setButtonVisible('inline-block');
                dispatch(getAppDrawer(false, null, null, null, null));
                refreshListView(constants.SMS_TEMPLATE_OBJECT);
            }
            else if (response.status === -1 && response.error.message !== "") {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.SALES_TEMPLATE.TEMPLATE_NAME_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));
                setMounted(true);
                setButtonVisible('none');
            }
        });

    }

    const testSmsTemplate = () => {
        let phoneTextProvider = app.me.phone_text_provider ?? constants.NONE;
        if (phoneTextProvider != null && phoneTextProvider !== constants.NONE) {
            let params = {};
            params.sms_message = template.sms_message;
            params.id = template.id;
            params.name = template.name;
            dispatch(
                getAppDialog(true, constants.SMS_MESSAGE_DIALOG, null, null, params, null)
            );
        } else {
            sfDialogs.alert(
                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_PROVIDER,
                null,
            );
        }
    }

    const validateForm = () => {
        let isValid = true;
        if (template.name.trim() === '') {
            isValid = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.SALES_TEMPLATE.NAME_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (template.sms_message.trim() === '') {
            isValid = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_TEMPLATE.BLANK_MESSAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
        return isValid;
    }

    const getActionButtons = () => {
        let buttons = [];
        let hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_TEMPLATES);
        let saveBtnStyle = hasPermission ? { ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top',width:'auto' } : { ...styles.primaryButton, marginRight: '0px', width:'auto',verticalAlign: 'top', backgroundColor: '#ffc5b3', cursor: 'not-allowed' };
        let testBtnStyle = hasPermission ? { ...styles.secondaryButton, marginRight: '0px',marginLeft:'5px', verticalAlign: 'top',width:'auto' } : { ...styles.primaryButton, marginRight: '0px', width:'auto',verticalAlign: 'top', backgroundColor: '#ffc5b3', cursor: 'not-allowed' };
        let tempTemplate = template;
        buttons.push(
            <Button
                key='save'
                onClick={()=>saveTemplate()}
                style={saveBtnStyle}
                disabled={hasPermission ? false : true}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
        );
        if(tempTemplate!= null && tempTemplate.hasOwnProperty("id") && tempTemplate.id > 0){
            buttons.push(
                <Button
                    key='test'
                    onClick={()=>testSmsTemplate()}
                    style={testBtnStyle}
                    disabled={hasPermission ? false : true}
                >{getLocalizedStrings().label.COMMON.TEST}</Button>
            );
        }
        buttons.push(
            <Button
                key='cancel'
                onClick={()=>closeDrawer()}
                style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px' }}
            > {getLocalizedStrings().label.COMMON.CANCEL}</Button>
        );

        return buttons;
    }

    const openfields = () => {
        let element = null;
        
        let fieldItems = [...merge_fields, ...mergeFields];
        try {
            let popOverList = [];
            fieldItems.forEach((item, index) => {
                if (isValidParam(item)) {
                    if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                        let data = {};
                        data.label = Object.values(item)[0];
                        data.value = Object.keys(item)[0];
                        popOverList.push(data);
                    }
                }
            });
            element = <span title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}>
                <span style={{ marginRight: 5, maxWidth: 80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                    <PopOver
                        id={'smsTemplate'}
                        key={'smsTemplate'}
                        name={'smsTemplate'}
                        title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}
                        buttonEndIcon={"settings,keyboard_arrow_down"}
                        buttonStyle={{ color: '#7b7272', marginTop: '0px' }}
                        labelposition={"before"}
                        buttonLabel={""}
                        options={popOverList}
                        onclickAction={(value)=>menuOnChange(value)}
                        isShowIcon={true}
                        buttonLineOrigin={[28, 145]}
                    />
                </span>
            </span>
        } catch (error) {
            console.error("Error in 'SmsTemplate.js -> openfields()':" + error);
        }
        return element
    }

    const menuOnChange = (value) => {
        try {
            var eventSelection = document.getElementById('sms_message-text-box');
            let start = eventSelection.selectionStart,
            end = eventSelection.selectionEnd;
            value = getStringParam(template.sms_message).substring(0, start) + ' ${' + getStringParam(value) + '} ' + getStringParam(template.sms_message).substring(end)
            setSmslength(value.length);
            let tempTemplate = template;
            tempTemplate.sms_message = value;
            setTemplate(tempTemplate);
            setIsOpen(false);
        } catch (error) {
            console.error("Error in 'SmsTemplate.js ->  menuOnChange()':" + error);
        }
    }

    const openAttachment = () => {
        try {
            setIsOpenAttachmentPopover(false);
            let data = {
                contentStyle: { width: '80%', maxWidth: 'none' },
                fullWidth: true,
                maxWidth: 'md',
            }
            data.callFrom = constants.SEND_SMS;
            data.files = getArrayParam(attachFiles);
            dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.ASSETMENU_DIALOG_TITLE, (sendData) => {
              // Check for duplicates before adding
              const isDuplicate = attachFiles.some(file => file.id == sendData.id);
              
              if (isDuplicate) {
                  sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,"Duplicate files are not allowed.", null);
              } else {
                  let attachFile = {
                      name: sendData.name,
                      src: sendData.src,
                      id: sendData.id,
                  };
                  setAttachFiles([...attachFiles, attachFile]);
                  dispatch(getAppDialog(false, null, null, null, null, null));
                  setDummyData(true);
              }
            }, data, constants.SEND_SMS));
        } catch (error) {
            console.error("Error in 'SmsTemplate.js -> openAttachment()':" + error);
        }
    }

    const getAttachmentFiles = () => {
        let files = getArrayParam(attachFiles);
        let ul = null;
            let li = files.map((f, index) => {
                return <li key={f.name + index}><span title={f.name} style={{
                    float: 'left', textAlign: 'left', overflow: 'hidden',
                    textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'
                }}> {f.name} </span>
                    &nbsp;  <i className="fa fa-times" title={getLocalizedStrings().label.COMMON.DELETE} aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }} onClick={() => {
                        // attachFiles.splice(index, 1);
                        // setAttachFiles({...attachFiles});
                        let tempAttachFiles = attachFiles;
                        tempAttachFiles.splice(index, 1);
                        dispatch(getAppDialog(false, null, null, null, null, null));
                        setAttachFiles(tempAttachFiles);
                    }} ></i>
                </li>
            });
            if (li !== null && li.length > 0) {
                ul = <ul>{li}</ul>;
            }
        return ul;
    }
    let _getAttachmentFiles = getAttachmentFiles();
    let tempAttachFiles = getArrayParam(attachFiles);
    let height = window.innerHeight - 200;
    let top = (height - 10) / 2
    if (!mounted) {
        return (
            <div style={{ width: '100%', height: height }} >
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                        <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={styles.workAreaContainer}>
            <div style={{ paddingLeft: '12px', paddingRight: '12px' }}>
                <div>
                    <div style={{ paddingTop: '20px' }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.SALES_TEMPLATE.NAME_REQUIRED}
                                        fullWidth={true}
                                        name="Name"
                                        className={"sf-fields-bg"}
                                        value={template.name}
                                        onChange={(e)=>handleChange(e)}
                                        inputprops={{ maxlength: '64' }}
                                        autoFocus
                                        autoComplete='new-password'
                                        margin='dense'
                                        size='small'
                                    /></FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-two" style={{height:'51px'}}>
                                <SFAutoComplete
                                    key={'sf-auto-complete-status'}
                                    id={'status'}
                                    name={'status'}
                                    floatingLabelText={getLocalizedStrings().label.SALES_TEMPLATE.STATUS}
                                    menuItems={statusArr}
                                    disabled={false}
                                    className={"sf-fields-bg"}
                                    onChange={(event, value)=>handleStatusChange(event, value)}
                                    value={template.status}
                                    underlineFocusStyle={styles.textField.underlineFocus}
                                    floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
                                    inputStyle={styles.textField.input}
                                    menuItemStyle={styles.popoverMenuItem}
                                    style={styles.sfFormFieldContainer}
                                    isFullHeight={false}
                                />
                            </div>
                        </div>
                        <div style={{ height: '40px', marginTop:'6px', marginRight:'10px' }}>
                            <div style={{ float: "right", height: 'auto' }}>
                                {openfields()}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <FormControl style={{ width: '24rem', marginTop: '-13px' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        name="sms_message"
                                        id='sms_message-text-box'
                                        className={"sf-fields-bg"}
                                        key='flyingNoteText'
                                        type='textarea'
                                        multiline={true}
                                        rows={4}
                                        label={getLocalizedStrings().label.TWILIO_SMS.YOUR_MESSAGE + getLocalizedStrings().label.COMMON.REQUIRED}
                                        onChange={(e)=>handleChange(e)}
                                        value={template.sms_message}
                                        autoComplete="new-password"
                                        margin='dense'
                                    /></FormControl>

                            </div>
                        </div>
                        <div style={{ height: '30px', marginLeft:'14px' }}>
                            <span>{smslength}{getLocalizedStrings().label.BATCH_SMS.CHARS}</span>
                            <div style={{ marginTop: '10px', float: "right", height: 'auto' ,marginRight:'10px'}}>
                                {(app.me.tenant_country === 'USA' || app.me.tenant_country === 'Canada') &&
                                    <span
                                        onClick={()=>openAttachment()}
                                        style={{ cursor: 'pointer', padding: '10px'}}
                                        title={getLocalizedStrings().message.SMS_INTEGRATION.IMAGE_SELECT}><i className="fa fa-file-image" style={{ fontSize: '20px' }} ></i>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 sf-fields-bg' style={{ backgroundColor: '#f5f5f5' }}>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px' }}>
                                {tempAttachFiles.length > 0 &&
                                    <span className="row" style={{ textAlign:"right" }}>
                                        {_getAttachmentFiles}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 sf-fields-bg" style={{ textAlign: 'right', marginTop: '5px', marginBottom: '20px', backgroundColor: '#f5f5f5' }}>
                            <div className="row" style={{ marginRight: '0px', whiteSpace: 'nowrap',display:'flex',float:'right' }}>{getActionButtons()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmsTemplate;